import type {TRootState} from "types";

export const createLearnToRankInProgressSelector = (state: TRootState) =>
	state.learn_to_rank.create_in_progress;

export const learnToRankDataSelector = (state: TRootState) => state.learn_to_rank.data;

export const recordLearnToRankInProgressSelector = (state: TRootState) =>
	state.learn_to_rank.record_in_progress;

export const lastRecordAtSelector = (state: TRootState) => state.learn_to_rank.last_record_at;

export const learnToRankCountSelector = (state: TRootState) => state.learn_to_rank.count;

export const learnToRankTasksSelector = (state: TRootState) => state.learn_to_rank.tasks;

export const getLearnToRankInProgressSelector = (state: TRootState) =>
	state.learn_to_rank.getting_in_progress;
