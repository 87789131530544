import HttpClient from "api/index";
import {
	TFitToBriefCriteriaForm,
	TUpdateFitToBriefCriteriaForm,
} from "api/projectApi/candidateAssessment/types";

class CandidateAssessment extends HttpClient {
	async getProjectBriefCriteria(projectId: number) {
		return await this.doGet(`/projects/${projectId}/brief-criteria`);
	}

	async createProjectBriefCriteria(
		projectId: number,
		formData: TFitToBriefCriteriaForm,
		config = {},
	) {
		return await this.doPost(`/projects/${projectId}/brief-criteria`, formData, config);
	}

	async updateProjectBriefCriteria(
		projectId: number,
		criteriaId: number,
		formData: TFitToBriefCriteriaForm,
		config = {},
	) {
		return await this.doPatch(
			`/projects/${projectId}/brief-criteria/${criteriaId}`,
			formData,
			config,
		);
	}

	async deleteProjectBriefCriteria(projectId: number, criteriaId: number) {
		return await this.doDelete(`/projects/${projectId}/brief-criteria/${criteriaId}`);
	}

	async updateCandidateProjectBriefCriteria(
		projectId: number,
		candidateID: number,
		FitToBriefID: number,
		formData: TUpdateFitToBriefCriteriaForm,
		config = {},
	) {
		return await this.doPatch(
			`/projects/${projectId}/candidates/${candidateID}/fit-to-brief/${FitToBriefID}`,
			formData,
			config,
		);
	}
	async getCandidateProjectBriefInfo(projectId: number, candidateID: number, config = {}) {
		return await this.doGet(
			`/projects/${projectId}/candidates/${candidateID}/fit-to-brief`,
			config,
		);
	}
}

const candidateAssessment = new CandidateAssessment();

export default candidateAssessment;
