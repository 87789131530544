import HttpClient from "api";
import {TGetMarketMaps} from "api/marketMapApi/types";
import {AxiosRequestConfig} from "axios";

class MapXMarketMapsApi extends HttpClient {
	async getMarketMap(id: number | string) {
		return this.doGet(`/market-maps/${id}`);
	}

	async getMarketMaps({page, per_page = 20, created_by, name}: TGetMarketMaps) {
		const params: TGetMarketMaps = {page, per_page, created_by, name};

		const queryParts: string[] = [];
		Object.keys(params).forEach((key: string) => {
			const value = params[key as keyof TGetMarketMaps];
			if (value != null) {
				queryParts.push(
					`${encodeURIComponent(key)}=${encodeURIComponent(value.toString())}`,
				);
			}
		});
		const filterQuery = queryParts.length > 0 ? `?${queryParts.join("&")}` : "";

		return this.doGet(`/market-maps${filterQuery}`);
	}

	async searchMarketMaps(
		{name = "", page = 1, per_page = 40}: TGetMarketMaps,
		config?: AxiosRequestConfig,
	) {
		let filterQuery = `?page=${page}&per_page=${per_page}`;

		if (name !== "") {
			filterQuery = `${filterQuery}&name=${name}`;
		}

		return this.doGet(`/market-maps${filterQuery}`, config);
	}

	async createMarketMap(data: unknown) {
		return this.doPost("/market-maps", data);
	}

	async updateMarketMap(id: number, data: unknown) {
		return this.doPatch(`/market-maps/${id}`, data);
	}

	async deleteMarketMap(id: number) {
		return this.doDelete(`/market-maps/${id}`);
	}
}

const mapXMarketMapsApi = new MapXMarketMapsApi();

export default mapXMarketMapsApi;
