import type {FC} from "react";

import type {IconProps} from "./types";

const InValidField: FC<IconProps> = ({className = ""}) => (
	<svg
		width="16"
		height="16"
		fill="none"
		viewBox="0 0 16 16"
		className={className}
		xmlns="http://www.w3.org/2000/svg"
	>
		<mask
			x="0"
			y="0"
			width="16"
			height="16"
			id="mask0_1720_45629"
			maskUnits="userSpaceOnUse"
			style={{maskType: "alpha"}}
		>
			<rect width="16" height="16" fill="#D9D9D9" />
		</mask>
		<g mask="url(#mask0_1720_45629)">
			<path
				fill="#D94222"
				d="M8.00212 14.6668C7.07989 14.6668 6.21323 14.4917 5.40212 14.1415C4.591 13.7917 3.88545 13.3168 3.28545 12.7168C2.68545 12.1168 2.21056 11.4113 1.86078 10.6002C1.51056 9.78905 1.33545 8.92238 1.33545 8.00016C1.33545 7.07794 1.51056 6.21127 1.86078 5.40016C2.21056 4.58905 2.68545 3.8835 3.28545 3.2835C3.88545 2.6835 4.591 2.20838 5.40212 1.85816C6.21323 1.50838 7.07989 1.3335 8.00212 1.3335C8.92434 1.3335 9.791 1.50838 10.6021 1.85816C11.4132 2.20838 12.1188 2.6835 12.7188 3.2835C13.3188 3.8835 13.7937 4.58905 14.1434 5.40016C14.4937 6.21127 14.6688 7.07794 14.6688 8.00016C14.6688 8.92238 14.4937 9.78905 14.1434 10.6002C13.7937 11.4113 13.3188 12.1168 12.7188 12.7168C12.1188 13.3168 11.4132 13.7917 10.6021 14.1415C9.791 14.4917 8.92434 14.6668 8.00212 14.6668Z"
			/>
			<rect x="4" y="7" width="8" height="2" rx="1" fill="white" />
		</g>
	</svg>
);

export default InValidField;
