import type {FC} from "react";

import type {IconProps} from "./types";

const ShareIcon: FC<IconProps> = ({className = ""}) => (
	<svg
		width="20"
		height="20"
		fill="none"
		viewBox="0 0 20 20"
		className={className}
		xmlns="http://www.w3.org/2000/svg"
	>
		<mask
			x="0"
			y="0"
			width="20"
			height="20"
			id="mask0_19_12029"
			style={{maskType: "alpha"}}
			maskUnits="userSpaceOnUse"
		>
			<rect width="20" height="20" fill="#D9D9D9" />
		</mask>
		<g mask="url(#mask0_19_12029)">
			<path
				fill="#FCF7F0"
				d="M5.4941 19C5.08137 19 4.72917 18.8531 4.4375 18.5594C4.14583 18.2656 4 17.9125 4 17.5V8.5C4 8.0875 4.14687 7.73438 4.44062 7.44063C4.73437 7.14688 5.0875 7 5.5 7H8V8.5H5.5V17.5H14.5V8.5H12V7H14.5C14.9167 7 15.2708 7.14688 15.5625 7.44063C15.8542 7.73438 16 8.0875 16 8.5V17.5C16 17.9125 15.853 18.2656 15.5591 18.5594C15.2652 18.8531 14.9119 19 14.4992 19H5.4941ZM9.25 13V3.875L8.0625 5.0625L7 4L10 1L13 4L11.9375 5.0625L10.75 3.875V13H9.25Z"
			/>
		</g>
	</svg>
);

export default ShareIcon;
