import type {FC} from "react";
import {PieChart, Pie, Cell} from "recharts";

import {chartData, COLORS} from "./utils";
import type {TRoundChartProps} from "./types";
import styles from "./RoundCustomCharts.module.scss";

const RoundChart: FC<TRoundChartProps> = ({percent}) => {
	const data = chartData(percent);

	return (
		<div className={styles.round} data-testid="pie-round-chart">
			<PieChart width={120} height={120}>
				<Pie
					data={data}
					dataKey="value"
					innerRadius={57}
					outerRadius={60}
					cornerRadius={50}
				>
					{data.map((_, idx) => (
						<Cell key={idx} fill={COLORS[idx % COLORS.length]} />
					))}
				</Pie>
			</PieChart>
			<span className={styles.round__value}>{percent}%</span>
		</div>
	);
};

export default RoundChart;
