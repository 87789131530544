import classNames from "classnames";

import type {ExpandableArrowProps} from "./types";

import css from "./expandableArrow.module.scss";
import {ChevronIcon} from "assets/icons";

function ExpandableArrow({onClick, rotated = false, className, ...rest}: ExpandableArrowProps) {
	return (
		<div
			className={classNames(css.arrow, css.arrowWrapper, className)}
			onClick={onClick}
			{...rest}
		>
			<ChevronIcon
				className={classNames(css.arrow, {
					[css.rotated]: !rotated,
				})}
				width={16}
				height={16}
				color={rotated ? "#CBC5C2" : "#837D7C"}
			/>
		</div>
	);
}

export default ExpandableArrow;
