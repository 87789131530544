const MailIcon = () => {
	return (
		<svg
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<mask
				id="mask0_946_13701"
				style={{maskType: "alpha"}}
				maskUnits="userSpaceOnUse"
				x="0"
				y="0"
				width="20"
				height="20"
			>
				<rect width="20" height="20" fill="#D9D9D9" />
			</mask>
			<g mask="url(#mask0_946_13701)">
				<path
					d="M3.5 16C3.0875 16 2.73438 15.853 2.44063 15.5591C2.14688 15.2652 2 14.9119 2 14.4992V5.4941C2 5.08137 2.14688 4.72917 2.44063 4.4375C2.73438 4.14583 3.0875 4 3.5 4H16.5C16.9125 4 17.2656 4.14696 17.5594 4.44087C17.8531 4.73479 18 5.08811 18 5.50083V14.5059C18 14.9186 17.8531 15.2708 17.5594 15.5625C17.2656 15.8542 16.9125 16 16.5 16H3.5ZM10 11L3.5 7.27083V14.5H16.5V7.27083L10 11ZM10 9.22917L16.5 5.5H3.5L10 9.22917ZM3.5 7.27083V5.5V14.5V7.27083Z"
					fill="#4E5555"
				/>
			</g>
		</svg>
	);
};

export default MailIcon;
