import {useMemo} from "react";
import classNames from "classnames";

import type {TArrowButtonProps} from "./types";

import css from "./arrowButton.module.scss";

import colors from "styles/themes.module.scss";
import {LineBottomArrowIcon, LineLeftArrowIcon, LineRightArrowIcon} from "assets/icons";
import {Loader} from "components";

const ArrowButton = ({
	children,
	position,
	active = false,
	loading = false,
	...rest
}: TArrowButtonProps) => {
	const arrowIcon = useMemo(() => {
		switch (position) {
			case "left":
				return <LineLeftArrowIcon />;
			case "right":
				return <LineRightArrowIcon />;
			case "down":
			default:
				return <LineBottomArrowIcon />;
		}
	}, [position]);

	return (
		<div className={css.container}>
			{loading && (
				<Loader
					customStyle={{marginRight: "16px"}}
					height={24}
					width={24}
					type="TailSpin"
					color={colors.loaderDotColor}
				/>
			)}
			<button className={classNames(css.button, {[css.active]: active})} {...rest}>
				{arrowIcon}
				{children}
			</button>
		</div>
	);
};

export default ArrowButton;
