import React from "react";
import {BiErrorCircle} from "react-icons/bi";
import ImportReportToolTip from "mapx-components/Modals/SearchByLinkedInModal/Report/Tooltip/importReportTooltip";

const LinkedInBulkImportReportToolTip = (props: {
	reason?: string;
	status: string;
	url: string;
	color: string;
}) => {
	return (
		<div>
			<span data-tooltip-id={props.url}>
				<BiErrorCircle color={props.color} />
			</span>
			<ImportReportToolTip reason={props.reason} status={props.status} url={props.url} />
		</div>
	);
};
export default LinkedInBulkImportReportToolTip;
