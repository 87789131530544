import ContentLoader from "react-content-loader";
import SkeletonLoaderCardAsList from "components/Plugins/SkeletonLoaderCardAsList";

const ResultsBlockLoader = () => {
	return (
		<div
			style={{
				display: "flex",
				flexDirection: window.screen.width > 1024 ? "row" : "column",
				width: "100%",
				gap: 30,
			}}
		>
			<div
				style={{
					background: "white",
					width: window.screen.width > 1024 ? "30%" : "100%",
					padding: "25px 10px 25px 30px",
					height: "auto",
					filter: "drop-shadow(0px" + " 25px 40px rgba(79, 75, 74, 0.08))",
				}}
			>
				<ContentLoader
					speed={2}
					height={650}
					width={"100%"}
					backgroundColor="#EEEDEC"
					foregroundColor="#e6e6e6"
				>
					<rect x="0.9" y="1" width="90%" height="30" />
					<rect x="0.9" y="41" width="80%" height="20" />
					<rect x="0.9" y="71" width="85%" height="20" />
					<rect x="0.9" y="101" width="90%" height="30" />
					<rect x="0.9" y="141" width="90%" height="30" />
					<rect x="0.9" y="185" width="90%" height="10" />

					<rect x="0.9" y="206" width="95%" height="40" />
					<rect x="0.9" y="260" width="65%" height="25" />
					<rect x="0.9" y="290" width="85%" height="45" />
					<rect x="0.9" y="350" width="75%" height="25" />
					<rect x="0.9" y="390" width="95%" height="35" />
					<rect x="0.9" y="450" width="95%" height="15" />
					<rect x="0.9" y="470" width="95%" height="25" />

					<rect x="0.9" y="525" width="90%" height="50" />
					<rect x="0.9" y="590" width="95%" height="50" />
				</ContentLoader>
			</div>

			<div style={{width: window.screen.width > 1024 ? "70%" : "100%"}}>
				<SkeletonLoaderCardAsList count={3} />
			</div>
		</div>
	);
};

export default ResultsBlockLoader;
