import type {TDefaultOption} from "./types";

export const defaultOptions: TDefaultOption[] = [
	{
		prefix: "Seniority",
		name: "is inaccurate",
		id: 0,
		is_checked: false,
		message: "Wrong Seniority",
	},
	{
		prefix: "Job Function",
		name: "is inaccurate",
		id: 1,
		is_checked: false,
		message: "Wrong Job Functions",
	},
	{
		prefix: "Specialism",
		name: "is inaccurate",
		id: 2,
		is_checked: false,
		message: "Wrong Specialisms",
	},
	// {
	// 	prefix: "Job Title/company",
	// 	name: "is inaccurate",
	// 	id: 3,
	// 	is_checked: false,
	// 	message: "Wrong Job Title or CompanyDetails",
	// },
];
