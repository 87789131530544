import type {FC, PropsWithChildren} from "react";
import {Provider} from "react-redux";
import {AnyAction, Store} from "redux";
import configureMockStore from "redux-mock-store";
import thunk, {ThunkDispatch} from "redux-thunk";

import type {TRootState} from "types";

const middleWares = [thunk];

export const createMockStore = configureMockStore<
	TRootState,
	ThunkDispatch<TRootState, void, AnyAction>
>(middleWares);

export const getWithReduxWrapper =
	(store: Store<TRootState>): FC =>
	// eslint-disable-next-line react/display-name
	({children}: PropsWithChildren) =>
		<Provider store={store}>{children}</Provider>;
