import React, {useRef, useState} from "react";
import {useHistory} from "react-router-dom";
import {BiDotsVerticalRounded} from "react-icons/bi";
import css from "./projectCard.module.scss";
import colors from "styles/themes.module.scss";
import {Button, Loader} from "components";
import useOnClickOutside from "hooks/useOnClickOutside";
import customConfirmAlert from "helpers/customConfirmAlert";
import {deleteProject, getProjectList} from "store/mapx/project-list/projectListAsyncActions";
import {
	deletingProjectInProgressSelector,
	projectPaginationSelector,
} from "store/mapx/project-list/projectListSelectors";
import {clearAPWorkflowState} from "store/mapx/additional-profiles/additionalProfilesActions";
import {TProjectCardType} from "mapx-components/Cards/ProjectCard/types";
import {useAppDispatch, useAppSelector} from "hooks";
import {hasAccessToExtendedPagesSelector, userSelector} from "store/mapx/user/userSelectors";
import {STProject} from "api/projectApi/types";
import {getTargetListCompanies} from "store/mapx/target-list/targetListAsyncActions";
import {getTargetListCandidates} from "store/mapx/target-list/targetListCandidatesAsyncActions";
import {
	editProjectModal,
	setProject,
	setProjectForm,
} from "store/mapx/project-list/projectListActions";
import ProjectDiversityPercentageBar from "./diversityPercentageBar";
import {convertTimeStampToDate} from "helpers/string";
import {FaCaretRight} from "react-icons/fa";

export default function ProjectCard({project, locations}: TProjectCardType) {
	const dispatch = useAppDispatch();

	const [activeMenu, setActiveMenu] = useState(false);

	const history = useHistory();

	const loading = useAppSelector(deletingProjectInProgressSelector);

	const hasAccessToExtendedPages = useAppSelector(hasAccessToExtendedPagesSelector);

	const projectPageInfo = useAppSelector(projectPaginationSelector);

	const user = useAppSelector(userSelector);

	const handleItemClick = (selectedProject: STProject) => {
		// dispatch(clearTargetListItems());
		dispatch(clearAPWorkflowState());
		dispatch(setProject(selectedProject));
		dispatch(getTargetListCompanies());
		dispatch(getTargetListCandidates());
		history.push(`/project/${selectedProject.id}`);
	};

	const handleSearchClick = async (selectedProject: STProject) => {
		dispatch(clearAPWorkflowState());
		dispatch(setProject(selectedProject));
		dispatch(getTargetListCompanies());
		dispatch(getTargetListCandidates());
		setActiveMenu(false);
		history.push(`/candidates-filter-search`);
	};

	const handleEditProjectClick = (project: STProject) => {
		setActiveMenu(false);

		dispatch(editProjectModal(true));

		const formData = {
			id: project.id,
			name: project.name,
			location: project.location ? locations.find((l) => l.id === project.location) : null,
			users:
				project.users && project.users?.length > 0
					? [...project.users].filter((u) => u.id !== user.user_id)
					: [],
		};

		dispatch(setProjectForm(formData));
	};

	const handleDeleteProjectClick = (projectId: number) => {
		customConfirmAlert({
			yesButtonLabel: "Yes",
			noButtonLabel: "No",
			title: "Project Deletion Warning!",
			message: `You may have saved companies and candidates in the project which will be lost once you remove this project. Are you sure about deleting the project?`,
			handlePressYes: () => {
				setActiveMenu(false);
				dispatch(deleteProject(projectId));

				if (projectPageInfo.page === 1) {
					setTimeout(() => {
						dispatch(getProjectList());
					}, 500);
				}
			},
			afterClose: () => {
				setActiveMenu(false);
			},
		});
	};

	const toggleMenu = () => {
		setActiveMenu(!activeMenu);
	};

	const optionsRef = useRef(null);

	useOnClickOutside(optionsRef, () => setActiveMenu(false));

	return (
		<div className={css.project}>
			<span className={css.projectContent}>
				<div className={css.textContainer}>
					<div className={css.projectInfo}>
						<h2 onClick={() => handleItemClick(project)}>
							{project?.name ?? "Unnamed Project"}
						</h2>
						<div className={css.projectStatsContainer}>
							<div className={css.projectStats}>
								<div className={css.statItemWithBorderRight}>
									{project.candidates_number > 0
										? project.candidates_number
										: "0"}{" "}
									People
								</div>
								<div className={css.statItemWithBorderRight}>
									<span className={css.logoContainer}>
										{project.companies !== null && project.companies?.length ? (
											<>
												{[...project.companies]
													.splice(0, 4)
													.map((company, userIndex) => (
														<img
															key={userIndex}
															alt={company.name}
															src={
																company.logo_url ??
																"https://storage.googleapis.com/nebula-static/logos100x100/empty_logo.png"
															}
															className={css.logo}
															style={{
																zIndex: userIndex * 10 + 10,
																marginLeft: userIndex * 15,
															}}
														/>
													))}
											</>
										) : (
											<span
												style={{width: "fit-content", whiteSpace: "nowrap"}}
											>
												0 companies
											</span>
										)}
									</span>
									<p className={css.companyCount}>
										{project.companies_number > 0 && project.companies_number}
									</p>
								</div>
								<div className={css.statItem}>
									<ProjectDiversityPercentageBar
										percentage={project.diversity_percent}
									/>
								</div>
							</div>

							<div className={css.projectStats}>
								{/*<div className={css.dateInfo}>*/}
								{/*	<span style={{color: "#868D8D"}}>Created:</span>{" "}*/}
								{/*	{convertTimeStampToDate(project.created_date)}*/}
								{/*</div>*/}
								<div className={css.dateInfo}>
									<span style={{color: "#868D8D"}}>Last Updated:</span>{" "}
									{convertTimeStampToDate(project.last_modified_date)}
								</div>
								<Button
									className={css.openProjectButton}
									onClick={() => handleItemClick(project)}
								>
									<span>Open Project</span>{" "}
									<FaCaretRight color={"#0C5850"} size="15px" />
								</Button>
							</div>
						</div>
					</div>

					<div ref={optionsRef}>
						<span className={activeMenu ? css.active : css.hidden}>
							<div className={css.projectMenu}>
								{hasAccessToExtendedPages && (
									<div
										className={css.menuItem}
										onClick={() => handleSearchClick(project)}
									>
										Edit Search
									</div>
								)}

								<div
									className={css.menuItem}
									onClick={() => handleEditProjectClick(project)}
								>
									Edit Project
								</div>

								<div
									className={css.menuItem}
									onClick={() => handleDeleteProjectClick(project.id)}
								>
									Remove Project
								</div>
							</div>
						</span>

						{loading ? (
							<Loader
								height={16}
								width={16}
								type="ThreeDots"
								color={colors.loaderDotColor}
							/>
						) : (
							<BiDotsVerticalRounded
								size={24}
								className={css.menuDots}
								onClick={toggleMenu}
							/>
						)}
					</div>
				</div>
			</span>
		</div>
	);
}
