import {type FC, useEffect, Fragment, useMemo, useCallback} from "react";

import {useAppDispatch, useAppSelector} from "hooks";
import {
	creditsUsedKeySelector,
	organizationTableDataSelector,
	organizationsListOrderingSelector,
} from "store/mapx/organisation/organisationSelectors";
import {getLicenseTypes} from "store/mapx/user/userAsyncAction";
import {setOrganizationListOrdering} from "store/mapx/organisation/organisationActions";
import {getOrganizationTableData} from "store/mapx/organisation/organisationAsyncFunctions";

import TableHeader from "../TableHeader";
import type {TTableHeader} from "../types";
import OrganizationItem from "../OrganizationItem";
import {IOrganizationItem} from "../OrganizationItem/types";
import {creditsUsedData} from "../TableHeader/TableHeaderStep";

import {getUpdatedOrderingData} from "../utils";
import styles from "./OrganizationTable.module.scss";

const OrganizationTable: FC = () => {
	const dispatch = useAppDispatch();

	const creditsUsedKey = useAppSelector(creditsUsedKeySelector);
	const organizations = useAppSelector(organizationTableDataSelector);
	const organizationListOrdering = useAppSelector(organizationsListOrderingSelector);

	useEffect(() => {
		const getLicenseTypeHandler = async () => {
			await dispatch(getLicenseTypes());
		};

		getLicenseTypeHandler();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleOrganizationsSort = useCallback(
		(itemKey: string) => {
			const updatedOrdering = getUpdatedOrderingData(organizationListOrdering, itemKey);

			dispatch(setOrganizationListOrdering(updatedOrdering));

			dispatch(getOrganizationTableData());
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[organizationListOrdering],
	);

	const stepList: TTableHeader[] = useMemo(() => {
		const detectedKey = creditsUsedData.find((item) => item.key === creditsUsedKey);

		return [
			{
				id: 0,
				itemKey: "name",
				text: "Organization Name",
			},
			{
				id: 1,
				itemKey: "is_active",
				text: "Status",
			},
			{
				id: 2,
				itemKey: "created_at",
				text: "Created",
			},
			{
				id: 3,
				isDropdown: true,
				text: detectedKey?.label || creditsUsedData[0].label,
			},
			{
				id: 4,
				itemKey: "users_count",
				text: "Total User",
			},
		];
	}, [creditsUsedKey]);

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const transformOrganization = organizations?.map((item: any) => {
		return {
			...item,
			organisation_name: item?.name,
			total_users: item?.users_count,
			credits_used: item.credits_used ? item.credits_used[creditsUsedKey] : "-",
		};
	});

	return (
		<div className={styles.container}>
			<Fragment>
				<TableHeader stepList={stepList} onSort={handleOrganizationsSort} />

				{transformOrganization?.map((item: IOrganizationItem) => (
					<OrganizationItem item={item} key={item.id} />
				))}
			</Fragment>
		</div>
	);
};

export default OrganizationTable;
