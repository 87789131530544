import classNames from "classnames";
import {UpgradeNowCard} from "components";
import {Feedback} from "components/Plugins/Feedback";
import config from "config";
import {Mixpanel} from "helpers/mixpanel";
import {generateExperienceTable, seniorityExperienceTimeInYears} from "./seniorityCalculations";

import useEventMonitor from "hooks/mapx/useEventMonitor";
import useUserBehaviorTrackHook from "hooks/mapx/useUserBehaviorTrackHook";
import {ActionAltButton, CandidateExperience, CandidateInformation} from "mapx-components";
import CandidateExpertise from "mapx-components/Cards/CandidateCard/CandidateExpertise";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import ReactGA from "react-ga4";
import {hotjar} from "react-hotjar";
import {useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {candidateFiltersSelector} from "store/mapx/filter/filterSelectors";
import {
	candidatesTargetListSelector,
	targetListIDSelector,
} from "store/mapx/target-list/targetListSelectors";
import {userEmailSelector} from "store/mapx/user/userSelectors";
import styles from "./candidateCard.module.scss";
import {TCandidateCardProps, TExpSum} from "mapx-components/Cards/CandidateCard/types";
import RefreshProfile from "mapx-components/Cards/CandidateCard/RefreshProfile";
import AdditionalProfileActions from "mapx-components/AdditionalProfileActions";
import {RecommendedIcon} from "assets/icons";
import {BiPhone} from "react-icons/bi";
import {useAppDispatch, useAppSelector} from "hooks";
import {generateCandidatesContactDetails} from "store/mapx/project/generateCandidateContactDetailsAsyncActions";
import CandidateSummary from "../CandidateSummary";
import {projectSelector} from "store/mapx/project-list/projectListSelectors";
import CandidateContactDetails from "../CandidateContactDetails";
import {isCandidateContactDetailsGenerating} from "store/mapx/project/generateCandidateContactDetailsSelectors";

export default function CandidateCard({
	candidate,
	isRecommended,
	searchType,
	shouldTrack = false,
	position = null,
	displayExperience = true,
	displayDealExperience = false,
	disabledTags = false,
	fromQuickView = false,
	detailed = false,
	limitSeniorityByFilter = false,
	printRequested = false,
	upgradeView = false,
}: TCandidateCardProps) {
	const dispatch = useAppDispatch();

	const {trackStart, trackComplete, itemEventDetails, setEvent, setTargetListId} =
		useUserBehaviorTrackHook({
			itemType: "candidate",
			itemId: candidate.id,
			source: searchType,
			isRecommended,
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			position,
		});

	const containerRef = useEventMonitor({shouldTrack, itemEventDetails});

	const targetListID = useSelector(targetListIDSelector);

	const project = useSelector(projectSelector);

	const userEmail = useSelector(userEmailSelector);

	const positions = useMemo(() => candidate.positions || [], [candidate]);

	const targetListCandidates = useAppSelector(candidatesTargetListSelector);

	const candidateInfoFromTL = useMemo(() => {
		return targetListCandidates.find((tc: {id: number}) => tc.id === candidate.id);
	}, [targetListCandidates, candidate.id]);

	const trackEventReport = useCallback(
		(type: string) => {
			if (shouldTrack) {
				setTargetListId(targetListID);
				setEvent(type);
			}
		},
		[setEvent, setTargetListId, shouldTrack, targetListID],
	);

	const [expSumList, setExpSumList] = useState<TExpSum[]>([]);

	const filters = useSelector(candidateFiltersSelector);

	// const rejectIconComponent = <RejectMinusIcon/>;

	const history = useHistory();

	useEffect(() => {
		if (positions) {
			const filteredPositions = seniorityExperienceTimeInYears(
				positions,
				limitSeniorityByFilter,
				filters.current_seniority,
			);

			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			setExpSumList(generateExperienceTable(filteredPositions));
		}
	}, [positions, candidate, limitSeniorityByFilter, filters]);

	useEffect(() => {
		try {
			if (detailed || fromQuickView) {
				const name = candidate?.full_name || candidate?.name;
				const pageTitle = `${name} | ${config.pageTitlePostFix}`;

				window.document.title = pageTitle;

				ReactGA.send({
					hitType: "pageview",
					page: window.location.pathname,
					title: pageTitle,
				});

				if (hotjar.initialized()) {
					hotjar.stateChange(window.location.pathname);
				}

				Mixpanel.track(`Candidate Profile Viewed`, {
					name: `${name}`,
					pageTitle: `${name}`,
					url: window.location.pathname,
					distinct_id: userEmail,
				});
			}
		} catch (e) {
			console.log(e);
		}
	}, [candidate, detailed, fromQuickView, userEmail]);

	const candidateExperiences = useMemo(() => {
		return candidate && positions !== undefined && Array.isArray(positions)
			? searchType === "Profile" || fromQuickView
				? positions
				: positions.slice(0, 3)
			: [];
	}, [candidate, fromQuickView, positions, searchType]);

	const isCandidateDetailsPage = useMemo(() => searchType === "Profile", [searchType]);

	const candidateContactDetailsGenerationInProgress = useSelector((state) =>
		isCandidateContactDetailsGenerating(state, candidate),
	);

	const handleGenerateContactButtonClick = useCallback(async () => {
		await dispatch(generateCandidatesContactDetails([candidate?.id]));
	}, [dispatch, candidate?.id]);

	return (
		<div
			onMouseEnter={trackStart}
			onMouseLeave={trackComplete}
			ref={containerRef}
			className={classNames(
				styles.candidate,
				{[styles.recommended]: isRecommended},
				{
					[styles.candidateWithRoundButton]: isCandidateDetailsPage && displayExperience,
				},
			)}
		>
			{upgradeView && <UpgradeNowCard />}

			<div className={classNames({[styles.upgradeView]: upgradeView})}>
				{/*{isRecommended && (*/}
				{/*	<span className={styles.enhanceIcon}>*/}
				{/*		<EnhanceIcon />*/}
				{/*	</span>*/}
				{/*)}*/}

				{isRecommended && (
					<span className={styles.recommendedIcon}>
						<RecommendedIcon />
					</span>
				)}

				<div className={styles.infoBlock}>
					<div className={styles.leftContainer}>
						{candidate && (
							<CandidateInformation
								avatar={candidate.avatar_url}
								candidate={candidate}
								isRecommended={isRecommended}
								position={position}
								from={searchType}
								detailed={detailed}
								fromQuickView={fromQuickView}
								history={history}
							/>
						)}
					</div>
					<div className={styles.rightWrapper}>
						{candidate.linkedin_url !== null && !!candidate.full_name && (
							<RefreshProfile
								candidate_id={candidate.id}
								full_name={candidate.full_name}
								linkedin_url={candidate.linkedin_url}
							/>
						)}

						{
							// this condition used for not showing it on the pdf
							!printRequested && (
								<Feedback
									candidateId={candidate.id}
									is_data_flagged_wrong={candidate?.is_data_flagged_wrong}
									quickView={fromQuickView}
									opposite={!(expSumList.length > 0)}
									type={searchType}
								/>
							)
						}

						{/* Seniority Experience Table */}
						{expSumList.length > 0 && (
							<div className={styles.rightContainer}>
								<div className={styles.experienceContainer}>
									<span className={styles.experienceTitle}>Experience</span>
									{expSumList.map((item) => {
										return (
											<div
												key={item.name}
												className={classNames(styles.experience)}
											>
												<span className={styles.experienceName}>
													{item.name}
												</span>
												<span className={styles.experienceDuration}>
													{item.total}{" "}
													{item.total > 1 ? " years" : " year"}
												</span>
											</div>
										);
									})}
								</div>
							</div>
						)}
					</div>
				</div>

				<CandidateExpertise
					disabledTags={disabledTags}
					printRequested={printRequested}
					positions={positions}
					trackEventReport={trackEventReport}
				/>

				{project && (
					<CandidateSummary
						id={candidate.id}
						summary={candidate.summary ?? candidateInfoFromTL?.summary}
						expanded={true}
					/>
				)}
				<div
					className={classNames(styles.contactDetails, {
						[styles.extraMargin]: candidate?.contact_details === null,
					})}
				>
					<CandidateContactDetails
						contactDetails={candidate?.contact_details}
						loading={candidateContactDetailsGenerationInProgress}
					/>
				</div>

				<div className={styles.actionBlockForQuickViewProject}>
					<ActionAltButton
						disabled={candidate?.contact_details !== null}
						key={"Generate Contact Details"}
						customClass={styles.generateContactDetails}
						iconComponent={<BiPhone color={"#4E5555"} />}
						handleClick={handleGenerateContactButtonClick}
						title={
							candidateContactDetailsGenerationInProgress
								? "Generating Contact Details..."
								: "Generate Contact Details"
						}
						loading={candidateContactDetailsGenerationInProgress}
					/>
					<AdditionalProfileActions
						searchType={searchType}
						candidate={candidate}
						isAdditionalProfileList={false}
						isQuickView={fromQuickView}
						isRecommended={candidate.is_recommended}
					/>
				</div>

				{displayExperience && candidateExperiences && candidateExperiences?.length > 0 && (
					<CandidateExperience
						key={candidate.id}
						positions={candidateExperiences}
						searchType={searchType}
						fromQuickView={fromQuickView}
						isRecommended={isRecommended}
						candidateId={candidate?.id}
						position={position}
						displayDealExperience={displayDealExperience}
						// printRequested={printRequested}
					/>
				)}
			</div>
		</div>
	);
}
