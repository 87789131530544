import {MainWrapper, SearchInput} from "mapx-components";

import styles from "./flaggedCandidate.module.scss";
import React, {ChangeEvent, useCallback, useEffect, useMemo, useState} from "react";
import {TFlaggedCandidate, TOrderingTypes, TPagination, TSortMap} from "./types";
import Table from "components/Table";
import {EyeIcon, TwoWaySortIcon} from "assets/icons";
import {useHistory} from "react-router-dom";
import {useDebounce} from "hooks/useDebounce";
import {useAppDispatch, useOpenLink} from "hooks";
import {getFlaggedCandidatesResults} from "store/mapx/candidate/candidateAsyncActions";
import {getStandardDateFormatFromIso} from "../../helpers/date";
import FlaggedCandidatesSkeletonLoader from "./FlaggedCandidatesSkeletonLoader";
import useInfiniteScroll from "react-infinite-scroll-hook";
import classNames from "classnames";

const PER_PAGE = 20;

const FlaggedCandidates = () => {
	const openLink = useOpenLink();

	const [flaggedCandidates, setFlaggedCandidates] = useState<TFlaggedCandidate[]>([]);

	const [paginationInfo, setPaginationInfo] = useState<TPagination | null>(null);

	const [ordering, setOrdering] = useState<TSortMap>({
		name: "asc",
	});

	const [query, setQuery] = useState<string>("");

	const [loading, setLoading] = useState<boolean>(false);

	const [paginateLoading, setPaginateLoading] = useState<boolean>(false);

	const debouncedQuery = useDebounce(query, 800);

	const history = useHistory();

	const dispatch = useAppDispatch();

	const handleSortClick = useCallback(
		(key: TOrderingTypes) => {
			if (ordering[key] && ordering[key] === "asc") {
				setOrdering({[key]: "desc"});
			} else {
				setOrdering({[key]: "asc"});
			}
		},
		[ordering],
	);

	const queryString = useMemo(() => {
		let query;

		const sortKey = Object.keys(ordering)[0]; // "sort key"

		const sortValue = Object.values(ordering)[0]; // "sort value = asc / desc"

		if (sortKey) {
			query = `ordering=${sortValue === "asc" ? sortKey : "-" + sortKey}`;
		}

		if (debouncedQuery) {
			query = query ? `${query}&name=${debouncedQuery}` : `name=${debouncedQuery}`;
		}

		return query ? `?${query}` : "";
	}, [debouncedQuery, ordering]);

	const handleSearchInputChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
		setQuery(e.target.value);
	}, []);

	const fetchFlaggedCandidatesResultsWithParams = useCallback(
		async (page = 1) => {
			let query;

			if (queryString) {
				query = `${queryString}&per_page=${PER_PAGE}`;
			} else {
				query = `?per_pag=${PER_PAGE}`;
			}

			query = `${query}&page=${page}`;

			const response = await dispatch(getFlaggedCandidatesResults(query));

			if (page === 1) {
				setFlaggedCandidates(response.data);
			} else {
				setFlaggedCandidates((prevData) => [...prevData, ...response.data]);
			}

			setPaginationInfo(response.pagination);
		},
		[dispatch, queryString],
	);

	useEffect(() => {
		setLoading(true);

		fetchFlaggedCandidatesResultsWithParams().finally(() => setLoading(false));
	}, [fetchFlaggedCandidatesResultsWithParams, queryString]);

	/**
	 * Pagination Logic
	 */
	const [sentryRef] = useInfiniteScroll({
		loading: paginateLoading,
		hasNextPage: paginationInfo !== null && paginationInfo?.pages > paginationInfo?.page,
		onLoadMore: async () => {
			setPaginateLoading(true);
			await fetchFlaggedCandidatesResultsWithParams(
				paginationInfo ? paginationInfo?.page + 1 : 1,
			).finally(() => setPaginateLoading(false));
		},
		// rootMargin: "0px 0px 400px 0px",
		disabled: paginationInfo === null,
		delayInMs: 300,
	});

	const handleViewClick = useCallback(
		(event: React.MouseEvent<HTMLDivElement>, candidateId: number) => {
			if (event.ctrlKey || event.metaKey || event.ctrlKey || event.altKey) {
				const link = `/candidate/${candidateId}`;
				openLink(event, link, true);
			} else {
				const link = `${window.location.pathname}/candidate/${candidateId}`;
				history.push(link);
			}
		},
		[history, openLink],
	);

	const rows = useMemo(
		() =>
			!loading
				? flaggedCandidates.map((data: TFlaggedCandidate) => {
						const {id, candidate, type, status, created_at, last_modified_at} = data;

						return {
							id,
							cells: [
								<div
									style={{cursor: "pointer"}}
									onClick={(e) => handleViewClick(e, candidate.id)}
									className={styles.tableCell}
									key={id}
								>
									{`${candidate.name}`}
								</div>,
								<div className={styles.tableCell} key={id}>
									{type}
								</div>,
								<div className={styles.tableCell} key={id}>
									{getStandardDateFormatFromIso(created_at)}
								</div>,
								<div className={styles.tableCell} key={id}>
									{getStandardDateFormatFromIso(last_modified_at)}
								</div>,
								<div className={styles.tableCell} key={id}>
									<div
										className={classNames(styles.status, {
											[styles.open]: status === "Open",
											[styles.updated]: status === "Updated",
											[styles.resolved]: status === "Resolved",
										})}
									>
										<span className={styles.point} />
										{status}
									</div>
								</div>,
								<div
									style={{cursor: "pointer"}}
									className={styles.tableCell}
									onClick={(e) => handleViewClick(e, candidate.id)}
									key={id}
								>
									<EyeIcon />
								</div>,
							],
						};
				  })
				: [],
		[flaggedCandidates, handleViewClick, loading],
	);

	const headers = useMemo(
		() => [
			{
				element: `Profile Name`,
				sort: () => handleSortClick("name"),
				order: ordering["name"],
				style: {width: "344px"},
			},
			{
				element: "Flagged",
				sort: () => handleSortClick("type"),
				order: ordering["type"],
				style: {width: "280px"},
			},
			{
				element: "Created at",
				sort: () => handleSortClick("created_at"),
				order: ordering["created_at"],
				style: {width: "144px"},
			},
			{
				element: "Last Updated",
				sort: () => handleSortClick("last_modified_at"),
				order: ordering["last_modified_at"],
				style: {width: "144px"},
			},
			{
				element: "Status",
				sort: () => handleSortClick("status"),
				order: ordering["status"],
				style: {width: "200px"},
			},
			{
				element: "",
				style: {width: "40px"},
			},
		],
		[handleSortClick, ordering],
	);

	const SortIconComponent = <TwoWaySortIcon />;

	return (
		<MainWrapper>
			<div className={styles.container}>
				<div className={styles.header}>Flagged Profiles</div>

				<div className={styles.tableContainer}>
					<div className={styles.tableTitleContainer}>
						<div className={styles.title}>Profile Status</div>

						<div className={styles.searchInputWrapper}>
							<SearchInput
								onChange={handleSearchInputChange}
								placeholder="Search a Profile"
								type="text"
								errorText={undefined}
								errorClass={undefined}
							/>
						</div>
					</div>

					<div className={styles.tableWrapper}>
						<Table
							// eslint-disable-next-line @typescript-eslint/ban-ts-comment
							// @ts-ignore
							headers={headers}
							rows={rows}
							tableCustomClass={styles.table}
							tableHeaderCustomClass={styles.header}
							tableRowCustomClass={styles.tableRow}
							SortIconComponent={SortIconComponent}
						/>

						{!paginateLoading &&
							paginationInfo !== null &&
							paginationInfo?.pages > paginationInfo?.page && (
								<div
									ref={sentryRef}
									key="flagged_candidate"
									style={{width: "100%", height: "20px"}}
								/>
							)}

						{(loading || paginateLoading) && <FlaggedCandidatesSkeletonLoader />}
					</div>
				</div>
			</div>
		</MainWrapper>
	);
};

export default FlaggedCandidates;
