import type {FC} from "react";
import classNames from "classnames";

import type {TBadgeProps} from "./types";
import styles from "./badge.module.scss";

const Badge: FC<TBadgeProps> = ({count, className = ""}) => (
	<span className={classNames(styles.badge, {[className]: className})}>{count}</span>
);

export default Badge;
