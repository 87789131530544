import React from "react";

const Cross = (props) => (
	<svg
		width={16}
		height={16}
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		role="img"
		{...props}
	>
		<path
			d="m12 4-8 8M4 4l8 8"
			stroke="#808080"
			strokeWidth={2}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);

export default Cross;
