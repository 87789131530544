import type {FC} from "react";

import type {IconProps} from "./types";

const Rule2: FC<IconProps> = ({fill = "url(#paint0_linear_1686_29959)", className = ""}) => (
	<svg
		width="80"
		height="74"
		fill="none"
		viewBox="0 0 80 74"
		className={className}
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fill={fill}
			d="M18.6292 66.6725C1.71315 56.906 -4.1267 35.3518 5.5855 18.5298C15.2977 1.70774 50.9885 -5.95701 66.8303 8.50084C82.6723 22.9588 82.7116 43.6791 72.9994 60.5011C63.2872 77.3231 35.5453 76.4391 18.6292 66.6725Z"
		/>
		<defs>
			<linearGradient
				x2="12"
				x1="48.5"
				y2="73.001"
				y1="2.50104"
				id="paint0_linear_1686_29959"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#23786D" />
				<stop offset="1" stopColor="#2A688A" />
			</linearGradient>
		</defs>
	</svg>
);

export default Rule2;
