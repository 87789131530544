import type {IconProps} from "./types";

const ToastWarningIcon = (props: IconProps) => {
	return (
		<svg
			width={24}
			height={24}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<mask
				id="prefix__a"
				style={{
					maskType: "alpha",
				}}
				maskUnits="userSpaceOnUse"
				x={0}
				y={0}
				width={24}
				height={24}
			>
				<path fill="#D9D9D9" d="M0 0h24v24H0z" />
			</mask>
			<g mask="url(#prefix__a)">
				<path
					d="M1 21L12 2l11 19H1zm11-3c.283 0 .52-.096.713-.288A.968.968 0 0013 17a.968.968 0 00-.287-.712A.968.968 0 0012 16a.968.968 0 00-.713.288A.968.968 0 0011 17c0 .283.096.52.287.712.192.192.43.288.713.288zm-1-3h2v-5h-2v5z"
					fill="#F5A300"
				/>
			</g>
		</svg>
	);
};

export default ToastWarningIcon;
