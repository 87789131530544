import React, {useCallback, useEffect, useMemo, useState} from "react";

import styles from "./styles.module.scss";
import {useSelector} from "react-redux";
import {selectedCandidatesSelector} from "store/mapx/filter/filterSelectors";
import {ActionAltButton, ProjectCandidateCard, CandidatesSkeletonLoader} from "mapx-components";
import {LazyLoadComponent} from "react-lazy-load-image-component";
import {SkeletonLoaderCardAsList} from "components";
import {useAppDispatch} from "hooks";
import {
	importLinkedInCandidateInProgressSelector,
	similarCandidatesInProgressSelector,
	similarCandidatesSelector,
} from "store/mapx/candidate/candidateSelectors";
import {SICandidate} from "api/candidateApi/types";
import classNames from "classnames";
import {ArrowDown} from "assets/icons";
import axios, {CancelTokenSource} from "axios";
import {getSimilarCandidatesData} from "store/mapx/candidate/candidateAsyncActions";

// eslint-disable-next-line
let similarCandidateCancelToken: CancelTokenSource;

const DisplaySimilarProfiles = () => {
	const dispatch = useAppDispatch();

	const [requestCancelled, setRequestCancelled] = useState(false);

	const [displayAllExperience, setDisplayAllExperience] = useState<boolean>(false);

	const similarCandidatesInProgress = useSelector(similarCandidatesInProgressSelector);

	const selectedCandidates = useSelector(selectedCandidatesSelector);

	// const selectedCandidates = useSelector(candidateLinkedInUrlSelector);

	const similarCandidates = useSelector(similarCandidatesSelector);

	const importingLinkedInCandidateInProgress = useSelector(
		importLinkedInCandidateInProgressSelector,
	);

	const candidatesQueryString = useMemo(() => {
		return selectedCandidates?.length > 0
			? `?candidate_id=${selectedCandidates.map((c: SICandidate) => c.id).join(",")}`
			: null;
	}, [selectedCandidates]);

	const fetchResults = useCallback(
		async (parameter: string): Promise<string> => {
			if (typeof similarCandidateCancelToken !== typeof undefined) {
				similarCandidateCancelToken.cancel("Operation canceled due to new request.");
			}

			//Save the cancel token for the current request
			similarCandidateCancelToken = axios.CancelToken.source();

			const config = {cancelToken: similarCandidateCancelToken.token};

			const response = await dispatch(getSimilarCandidatesData(parameter, false, config));

			if (response && response.cancelled) {
				setRequestCancelled(true);

				return "cancelled";
			} else {
				setRequestCancelled(false);

				return "success";
			}
		},
		[dispatch],
	);

	const candidates = useMemo(() => {
		return [...selectedCandidates, ...similarCandidates];
	}, [selectedCandidates, similarCandidates]);

	useEffect(() => {
		if (
			!importingLinkedInCandidateInProgress &&
			candidatesQueryString &&
			!similarCandidates?.length
		) {
			fetchResults(candidatesQueryString);
		}
	}, [
		candidatesQueryString,
		fetchResults,
		importingLinkedInCandidateInProgress,
		similarCandidates,
	]);

	const candidatesFetchInProgress = useMemo(
		() => similarCandidatesInProgress || requestCancelled,
		[similarCandidatesInProgress, requestCancelled],
	);

	return (
		<div className={styles.resultsWrapper}>
			{candidates?.length > 0 && (
				<div className={styles.headerSectionWrapper}>
					<div className={styles.actionButtonsRight}>
						<ActionAltButton
							key={"expand"}
							customClass={classNames(styles.toggleExpandButton, {
								[styles.reverse]: displayAllExperience,
							})}
							iconComponent={<ArrowDown />}
							handleClick={() => setDisplayAllExperience((p) => !p)}
							title={displayAllExperience ? "Collapse All" : "Expand All"}
						/>

						<div className={styles.count}>Results: {100}</div>
					</div>

					<div className={styles.actionButtonsLeft}>
						<div />
					</div>
				</div>
			)}

			{importingLinkedInCandidateInProgress && (
				<CandidatesSkeletonLoader count={1} isLinkedIn={true} />
			)}

			<LazyLoadComponent>
				{candidates?.map((candidate: SICandidate, index: number) => {
					return (
						<ProjectCandidateCard
							key={`${candidate.id}${index}`}
							candidate={candidate}
							searchType="People Search"
							displayAllExperience={displayAllExperience}
						/>
					);
				})}
			</LazyLoadComponent>

			{candidatesFetchInProgress && <SkeletonLoaderCardAsList count={5} />}
		</div>
	);
};

export default DisplaySimilarProfiles;
