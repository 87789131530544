import React, {type ChangeEvent, useCallback, useEffect, useMemo, useState} from "react";
import {useAppDispatch, useAppSelector, useModalHook} from "hooks";
import {Loader, ModalHeader, ModalSeparator, TextInput, ToastContent} from "components";
import {TFitToBriefModalProps} from "mapx-components/Modals/FitToBriefModal/types";
import styles from "./styles.module.scss";
import ModalFormHeaderInformation from "mapx-components/Modals/ModalFormHeaderInformation";
import FitToBriefLargeIcon from "mapx-components/Modals/FitToBriefModal/Icon/fitToBriefLargeIcon";
import SelectDropdown from "components/Inputs/SelectDropdown";
import {STCompany} from "api/companyApi/types";
import placeHolderImage from "assets/images/avatar-icon.png";
import useCompanySearchService from "services/useCompanySearchService";
import {projectSelector} from "store/mapx/project-list/projectListSelectors";
import {STProject} from "api/projectApi/types";
import {Accordion, ActionAltButton, SearchAutogrowInput} from "mapx-components/index";
import classNames from "classnames";
import {PlusIcon} from "assets/icons";
import TrashIcon from "mapx-components/Modals/FitToBriefModal/Icon/trashIcon";
import FooterButtons from "mapx-components/Modals/FooterButtons";
import {
	handleCandidateScoringBackgroundProcess,
	handleCriteriaDeleteForAssessment,
	handleCriteriaSaveForAssessment,
} from "store/mapx/project/projectAssessmentAsyncActions";
import {STFitToBriefCriteria} from "api/projectApi/candidateAssessment/types";
import {toast} from "react-toastify";
import ContentLoader from "react-content-loader";
import {setActiveAccordionOnFilter} from "store/mapx/filter/filterActions";
import {customConfirmAlert} from "helpers";
import {filterArrayByKeys} from "helpers/array";
import {setAPFilter} from "store/mapx/additional-profiles/additionalProfilesActions";
import {updateProject} from "store/mapx/project-list/projectWorkflow";

const FitToBriefModal = ({
	modalDisplay,
	setModalDisplay,
	briefCriteria,
	loadingBrief,
}: TFitToBriefModalProps) => {
	const project: STProject = useAppSelector(projectSelector);

	const dispatch = useAppDispatch();

	const [updatingCriteria, setUpdatingCriteria] = useState(false);

	const [loadingCriteriaItems, setLoadingCriteriaItems] = useState<Nullable<number[]>>([]);

	const [deletingCriteria, setDeletingCriteria] = useState<Nullable<number>>(null);

	const [loading, setLoading] = useState(false);

	const [jobTitle, setJobTitle] = useState("");

	const [company, setCompany] = useState<Nullable<STCompany>>(null);

	const [fitToBriefCriteria, setFitToBriefCriteria] = useState<STFitToBriefCriteria[]>([
		{
			priority: 1,
			name: "",
			description: "",
		},
	]);

	const {loadingCompanies, fetchCompanies} = useCompanySearchService();

	const {modalIsOpen, customStyles, Modal} = useModalHook(
		{
			content: {
				maxWidth: "792px",
				borderRadius: "4px",
				width: "99%",
				boxShadow: "0px 25px 40px -10px rgba(79, 75, 74, 0.08)",
			},
		},
		modalDisplay,
	);

	const createNewCriteria = () => {
		setFitToBriefCriteria((prev) => [
			...prev,
			{priority: prev.length + 1, name: "", description: ""},
		]);

		dispatch(setActiveAccordionOnFilter(`${fitToBriefCriteria.length + 1}. Criteria`));
	};

	const addOrUpdateCriteriaToTheList = useCallback(
		async (toBeUpdatedCriteria: STFitToBriefCriteria[]) => {
			setUpdatingCriteria(true);
			dispatch(setActiveAccordionOnFilter(null));

			const promises = toBeUpdatedCriteria.map((briefCriteriaItem) =>
				dispatch(handleCriteriaSaveForAssessment(briefCriteriaItem)),
			);

			try {
				const responses = await Promise.all(promises);

				const allSuccessful = responses.every((response) => response === "success");

				if (!allSuccessful) {
					toast.error(ToastContent, {
						data: {title: "Some criteria changes were unable to save!"},
					});
				}
			} catch (error) {
				console.error("Error in updating criteria: ", error);
				toast.error(ToastContent, {
					data: {title: "An unexpected error occurred while updating criteria!"},
				});
			} finally {
				setUpdatingCriteria(false);
			}
		},
		[dispatch],
	);

	const isCriteriaButtonDisabled = useMemo(() => {
		return fitToBriefCriteria.some((item) => item.name === "" || item.description === "");
	}, [fitToBriefCriteria]);

	useEffect(() => {
		if (briefCriteria && briefCriteria?.length > 0 && !updatingCriteria && !loading) {
			setFitToBriefCriteria(briefCriteria);
		}
	}, [briefCriteria, updatingCriteria, loading]);

	useEffect(() => {
		if (project && !modalDisplay) {
			if (project.company && !updatingCriteria && !deletingCriteria && !company)
				setCompany(project.company);

			if (project.target_job_title && !updatingCriteria && !deletingCriteria && !jobTitle)
				setJobTitle(project.target_job_title);
		}
	}, [project, jobTitle, company, deletingCriteria, modalDisplay, updatingCriteria]);

	const handleModalHide = useCallback(() => {
		setModalDisplay(false);

		setJobTitle(project?.target_job_title ?? "");
		setCompany(project?.company ?? null);
	}, [setModalDisplay, project]);

	const handleJobTitleChange = useCallback(
		(e: ChangeEvent<HTMLInputElement>) => setJobTitle(e.target.value),
		[],
	);

	const handleCriteriaInputChange = (
		e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
		index: number,
		attribute: "name" | "description",
	) => {
		e.preventDefault();

		const inputValue = e.target.value;

		setFitToBriefCriteria((prev) => {
			const currentItem = {...prev[index], [attribute]: inputValue};

			return [...prev.slice(0, index), currentItem, ...prev.slice(index + 1)];
		});
	};

	const handleTrashClick = useCallback(
		async (index: number) => {
			const item = briefCriteria[index];

			if (item?.id) {
				customConfirmAlert({
					title: `Delete "${item?.name || ""}" criteria`,
					message: `Are you sure you want to delete this criteria?`,
					handlePressYes: async () => {
						if (item?.id) {
							setDeletingCriteria(item.id);
							await dispatch(handleCriteriaDeleteForAssessment(item.id));
							setFitToBriefCriteria((prev) => prev.filter((_, i) => i !== index));
							setDeletingCriteria(null);
						}
					},
				});
			} else {
				setFitToBriefCriteria((prev) => prev.filter((_, i) => i !== index));
			}
		},
		[briefCriteria, dispatch],
	);

	const startFitToBriefScoring = useCallback(async () => {
		setLoading(true);

		const formData = {
			name: project.name,
			company: company,
			target_job_title: jobTitle,
		};

		await dispatch(updateProject(project.id, formData));

		const toBeUpdatedCriteria = filterArrayByKeys(fitToBriefCriteria, briefCriteria, [
			"name",
			"description",
			"priority",
		]);

		if (toBeUpdatedCriteria?.length > 0) {
			setLoadingCriteriaItems(toBeUpdatedCriteria.map((item) => item.priority));

			await addOrUpdateCriteriaToTheList(toBeUpdatedCriteria);

			setLoadingCriteriaItems([]);
		}

		await dispatch(handleCandidateScoringBackgroundProcess());
		dispatch(setAPFilter({attribute: "ordering", value: "-fit_to_brief_score"}));

		setLoading(false);
		setModalDisplay(false);
	}, [
		company,
		dispatch,
		jobTitle,
		project,
		setModalDisplay,
		briefCriteria,
		fitToBriefCriteria,
		addOrUpdateCriteriaToTheList,
	]);

	const isStartToFitToBriefDisabled = useMemo(() => {
		if (!company || !jobTitle) {
			return true;
		}

		for (let i = 0; i < fitToBriefCriteria.length; i++) {
			const item = fitToBriefCriteria[i];

			if (i === 0 && (item.name === "" || item.description === "")) return true;

			if (
				i === fitToBriefCriteria.length - 1 &&
				!item?.id &&
				item.name === "" &&
				item.description === ""
			) {
				return false;
			}
		}

		return false;
	}, [company, fitToBriefCriteria, jobTitle]);

	const LOADER_ICON = (
		<Loader
			height={18}
			width={18}
			customStyle={{paddingLeft: "10px"}}
			type="TailSpin"
			color={"#837D7C"}
		/>
	);

	return (
		<Modal
			contentLabel="Modal"
			isOpen={modalIsOpen}
			onRequestClose={handleModalHide}
			style={customStyles}
			title="Fit To Brief"
			shouldCloseOnOverlayClick={false}
		>
			<ModalHeader title="Fit To Brief" onClose={handleModalHide} />

			<ModalSeparator />

			<div className={styles.fitToBriefForm}>
				<ModalFormHeaderInformation
					icon={<FitToBriefLargeIcon />}
					title={"Fit to Brief"}
					description={
						"AI will assess the people on your project and score them against your hiring criteria."
					}
				/>

				<div className={styles.inputsRow}>
					<div className={styles.inputContainer} data-testid="client-company">
						<label htmlFor="client company">Hiring Company*</label>

						<SelectDropdown
							aria-label={"company"}
							inputId={"company"}
							name={"company"}
							cacheOptions
							isAsync={true}
							value={company}
							onChange={setCompany}
							loadOptions={fetchCompanies}
							showClearIcon={!loadingCompanies}
							isClearable={true}
							hideIndicator={true}
							isLoading={loadingCompanies}
							placeholder={"Which company is this role for?"}
							noOptionsMessage={"Search by company name"}
							noResultsText={
								"No company found with your query, try to refine your query"
							}
							formatOptionLabel={(company: STCompany) => (
								<div className={styles.dropdownItem}>
									<img
										src={company.logo_url || placeHolderImage}
										alt={company.name}
									/>
									<span>{company.name}</span>
								</div>
							)}
						/>
					</div>

					<TextInput
						label="Job title*"
						onChange={handleJobTitleChange}
						type="text"
						value={jobTitle}
						placeholder={"Enter job title"}
					/>
				</div>

				{loadingBrief && (
					<div className={styles.criteriaList}>
						<ContentLoader
							style={{marginTop: 10}}
							speed={2}
							height={130}
							width={"100%"}
							backgroundColor="#EEEDEC"
							foregroundColor="#e6e6e6"
						>
							<rect x="1" y="8.8" width="99%" height="22" />
							<rect x="1" y="38.8" width="96%" height="22" />
							<rect x="1" y="68.8" width="98%" height="22" />
							<rect x="1" y="98.8" width="91%" height="22" />
						</ContentLoader>
					</div>
				)}

				{!loadingBrief && (
					<div className={styles.criteriaList}>
						<span className={styles.info}>Change and order the criteria below</span>

						{fitToBriefCriteria.map((item, index) => (
							<div key={index} className={styles.criteriaListItem}>
								<span className={styles.criteriaName}>{item.name}</span>

								<Accordion
									customClass={styles.accordion}
									titleClass={styles.accordionTitle}
									title={`${index + 1}. Criteria`}
									subtitle={
										<div className={styles.accordionSubtitle}>
											{item?.id &&
												loadingCriteriaItems?.includes(item.id) &&
												LOADER_ICON}

											{!item?.id &&
												loadingCriteriaItems?.includes(item.priority) &&
												LOADER_ICON}

											{index > 0 && deletingCriteria !== item.id && (
												<span
													className={classNames({
														[styles.disabled]:
															deletingCriteria || updatingCriteria,
													})}
												>
													<TrashIcon
														onClick={() =>
															!deletingCriteria &&
															!updatingCriteria &&
															handleTrashClick(index)
														}
													/>
												</span>
											)}

											{deletingCriteria === item.id && LOADER_ICON}
										</div>
									}
								>
									<div className={styles.accordionContent}>
										<TextInput
											key={index}
											inputWrapper={styles.bitToBriefInput}
											label="Criteria*"
											onChange={(e) =>
												handleCriteriaInputChange(e, index, "name")
											}
											type="text"
											value={item.name}
											placeholder={"Enter Criteria"}
											rightIcon={`${item.name.length}/40`}
											maxLength={40}
										/>

										<div className={styles.inputContainer}>
											<label htmlFor="description">Description*</label>

											<SearchAutogrowInput
												key={index}
												value={item.description}
												onChange={(e) =>
													handleCriteriaInputChange(
														e,
														index,
														"description",
													)
												}
												maxHeight={150}
												disabled={false}
											/>
										</div>
									</div>
								</Accordion>
							</div>
						))}

						<ActionAltButton
							key={"addCriteria"}
							customClass={classNames(styles.addCriteria, {
								[styles.disabled]: isCriteriaButtonDisabled || updatingCriteria,
							})}
							disabled={isCriteriaButtonDisabled || updatingCriteria}
							iconComponent={
								<PlusIcon
									color={isCriteriaButtonDisabled ? "#b0b5b4" : "#493C70"}
								/>
							}
							handleClick={createNewCriteria}
							title={updatingCriteria ? "Updating Criteria List" : "Add Criteria"}
							loading={updatingCriteria}
						/>
					</div>
				)}
			</div>

			<FooterButtons
				actionButtonLabel={"Start Fit to Brief Scoring"}
				handleActionButtonClick={startFitToBriefScoring}
				isActionButtonDisabled={isStartToFitToBriefDisabled}
				isLoading={loading}
			/>
		</Modal>
	);
};

export default FitToBriefModal;
