import placeHolderImage from "assets/images/avatar-icon.png";
import React, {useMemo} from "react";
import css from "./styles.module.scss";

const PersonItem = ({candidate, onClick = undefined}) => {
	if (!candidate) {
		return null;
	}

	const allExp = candidate?.experience || candidate?.positions;

	const currentJob = useMemo(() => {
		if (allExp) {
			const currentEmployment =
				allExp.find((val) => !val.end_date) || allExp.find((val) => val.is_current);

			if (!currentEmployment && Array.isArray(allExp) && allExp?.length > 0) {
				return allExp[0];
			} else {
				return currentEmployment;
			}
		} else {
			return {
				jobTitle: "",
			};
		}
	}, [allExp]);

	const handleClick = () => {
		if (onClick) {
			onClick(candidate);
		}
	};

	const fullName = candidate?.full_name || `${candidate.first_name} ${candidate.last_name}`;

	const companyName = candidate?.company_name || currentJob?.company?.name;

	const companyLogo = candidate?.company_logo || currentJob?.company?.logo_url;

	const jobTitle = candidate?.job_title || currentJob?.job_title;

	return (
		<div className={css.personContainer} onClick={handleClick}>
			<div className={css.image}>
				<img
					src={candidate.avatar_url || placeHolderImage}
					onError={(e) => {
						e.currentTarget.src = placeHolderImage;
					}}
					alt={fullName}
				/>
			</div>

			<div className={css.info}>
				<h4>{fullName}</h4>
				{companyName && (
					<div style={{display: "flex", flexDirection: "column", alignContent: "center"}}>
						<span>{jobTitle} </span>
						<span className={css.companyName}>
							<img
								style={{width: 22, height: 22, borderRadius: 2}}
								src={companyLogo || placeHolderImage}
								onError={(e) => {
									e.currentTarget.src = placeHolderImage;
								}}
								alt={companyName}
							/>{" "}
							{companyName}
						</span>
					</div>
				)}
			</div>
		</div>
	);
};

function Suggestion({index, children}) {
	return (
		<li className={css.suggestionItem} key={index}>
			{children}
		</li>
	);
}

PersonItem.Suggestion = Suggestion;

export default PersonItem;
