import {useSelector} from "react-redux";
import {getAllGenerateSummaryCandidateIdsForCurrentProjectSelector} from "../store/mapx/project/generateCandidateSummarySelectors";
import {useEffect} from "react";
import {checkCandidateSummaryGeneratedStatus} from "../store/mapx/project/generateCandidateSummaryAsyncActions";
import {useAppDispatch, useAppSelector} from "./index";
import {targetListIDSelector} from "../store/mapx/target-list/targetListSelectors";

/* eslint-disable  @typescript-eslint/no-explicit-any */
let candidateIdsIntervalId = {} as any;
const useCandidateSummaryInstantDisplay = () => {
	const dispatch = useAppDispatch();

	const targetListID = useAppSelector(targetListIDSelector);

	const allCandidateIdsInProgress = useSelector((state) =>
		getAllGenerateSummaryCandidateIdsForCurrentProjectSelector(state, targetListID),
	);

	/**
	 * Here on the state we have kept the information for all candidate summary generate request by project
	 * Then We watch the candidate ids for the project and send a request to check if the summary is generated
	 * if generated then we remove the specific id from the watch list and update the info on the candidate list
	 */

	useEffect(() => {
		if (
			allCandidateIdsInProgress &&
			Array.isArray(allCandidateIdsInProgress) &&
			allCandidateIdsInProgress?.length > 0
		) {
			candidateIdsIntervalId = setInterval(async () => {
				await dispatch(checkCandidateSummaryGeneratedStatus(allCandidateIdsInProgress));
			}, 5 * 1000);

			setTimeout(() => {
				clearInterval(candidateIdsIntervalId);
			}, 60 * 30 * 1000); // it will clear after 30 min
		}

		if (allCandidateIdsInProgress?.length === 0) {
			clearInterval(candidateIdsIntervalId);
		}

		return () => {
			if (candidateIdsIntervalId) {
				clearInterval(candidateIdsIntervalId);
			}
		};
	}, [allCandidateIdsInProgress, dispatch]);
};

export default useCandidateSummaryInstantDisplay;
