import {FC, Fragment} from "react";

import PieChartSkeleton from "./PieChartSkeleton";
import type {TEmptyOrLoadingChartProps} from "./types";
import styles from "./PieChart.module.scss";

const EmptyOrLoadingChart: FC<TEmptyOrLoadingChartProps> = ({isLoading}) => (
	<Fragment>
		{isLoading ? (
			<div className={styles.container__loader}>
				<PieChartSkeleton />
			</div>
		) : (
			<p className={styles.container__wrapper__is_empty}>No Data Found</p>
		)}
	</Fragment>
);

export default EmptyOrLoadingChart;
