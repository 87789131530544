import {useId, useMemo} from "react";
import classNames from "classnames";
import {Tooltip} from "react-tooltip";

import type {FC, MouseEvent} from "react";

import colors from "styles/themes.module.scss";
import {EMPTY_COMPANY_LOGO} from "mapx-constants";
import {Loader, CompanyTooltip} from "components";
import {truncateSentenceEnd} from "helpers/string";
import {MinusSquareFilled, PlusSquare} from "assets/icons";
import {useAppDispatch, useAppSelector, useOpenLink} from "hooks";
import {
	targetListCompaniesSelector,
	isCompanyBeingAddedToTargetListSelector,
	isCompanyBeingRemovedFromTargetListSelector,
} from "store/mapx/target-list/targetListSelectors";
import {
	addCompanyToTargetList,
	removeCompanyFromTargetList,
} from "store/mapx/target-list/targetListAsyncActions";

import type {TCompanyCardProps} from "./types";
import styles from "./frequentlyHiredFrom.module.scss";

const CompanyCard: FC<TCompanyCardProps> = ({company, isTooltipSelect = true}) => {
	const id = useId();
	const openLink = useOpenLink();
	const dispatch = useAppDispatch();

	const targetListCompanies = useAppSelector(targetListCompaniesSelector);

	const inTargetList = useMemo(
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		() => new Set(targetListCompanies.map((company) => company.id)),
		[targetListCompanies],
	);

	const isBeingAdded = useAppSelector((state) =>
		isCompanyBeingAddedToTargetListSelector(state, company.id),
	);

	const isBeingRemoved = useAppSelector((state) =>
		isCompanyBeingRemovedFromTargetListSelector(state, company.id),
	);

	const handleAddClick = (companyId: string) => {
		dispatch(addCompanyToTargetList(companyId));
	};

	const handleRemoveClick = (companyId: string) => {
		dispatch(removeCompanyFromTargetList(companyId));
	};

	const handleItemClick = (event: MouseEvent, companyId: string) => {
		const link = `/company/${companyId}`;
		openLink(event, link);
	};

	const isSelectedItem = inTargetList.has(company.id);

	return company ? (
		<>
			<div className={styles.company} data-tooltip-id={id}>
				<div className={styles.top}>
					<div className={styles.left} onClick={(e) => handleItemClick(e, company.id)}>
						<img
							alt={company.name}
							className={styles.logo}
							loading="lazy"
							src={company.logo_url || EMPTY_COMPANY_LOGO}
							onError={(e) => {
								(e.target as HTMLImageElement).src = EMPTY_COMPANY_LOGO;
							}}
						/>
					</div>

					<div className={styles.right}>
						<div
							className={styles.name}
							onClick={(event) => handleItemClick(event, company.id)}
						>
							{company.name}
						</div>
						<div className={styles.industry}>{company.industry?.name}</div>
					</div>
				</div>

				{!isTooltipSelect && (
					<div
						className={classNames(styles.company__select, {
							[styles.company__select_active]: isSelectedItem,
						})}
					>
						{isBeingAdded || isBeingRemoved ? (
							<Loader
								height={24}
								width={24}
								type="TailSpin"
								color={colors.loaderDotColor}
							/>
						) : (
							<>
								{isSelectedItem ? (
									<div
										className={styles.remove}
										onClick={() => handleRemoveClick(company.id)}
									>
										<MinusSquareFilled />
									</div>
								) : (
									<div
										className={styles.add}
										onClick={() => handleAddClick(company.id)}
									>
										<PlusSquare />
									</div>
								)}
							</>
						)}
					</div>
				)}

				<div className={styles.bottom}>
					{truncateSentenceEnd(company.description, 120, 220)}
				</div>
			</div>

			<Tooltip id={id} place="right" className={styles.tooltip} clickable delayShow={500}>
				<CompanyTooltip isTooltipSelect={isTooltipSelect} company={company} />
			</Tooltip>
		</>
	) : null;
};

export default CompanyCard;
