const Check = () => (
	<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
		<mask
			id="mask0_398_11800"
			style={{maskType: "alpha"}}
			maskUnits="userSpaceOnUse"
			x="0"
			y="0"
			width="40"
			height="40"
		>
			<rect width="40" height="40" fill="#D9D9D9" />
		</mask>
		<g mask="url(#mask0_398_11800)">
			<path
				d="M17.6673 27.6666L29.4173 15.9166L27.084 13.5833L17.6673 22.9999L12.9173 18.2499L10.584 20.5833L17.6673 27.6666ZM20.0007 36.6666C17.6951 36.6666 15.5284 36.2288 13.5007 35.3533C11.4729 34.4788 9.70898 33.2916 8.20898 31.7916C6.70898 30.2916 5.52176 28.5277 4.64732 26.4999C3.77176 24.4721 3.33398 22.3055 3.33398 19.9999C3.33398 17.6944 3.77176 15.5277 4.64732 13.4999C5.52176 11.4721 6.70898 9.70825 8.20898 8.20825C9.70898 6.70825 11.4729 5.52047 13.5007 4.64492C15.5284 3.77047 17.6951 3.33325 20.0007 3.33325C22.3062 3.33325 24.4729 3.77047 26.5007 4.64492C28.5284 5.52047 30.2923 6.70825 31.7923 8.20825C33.2923 9.70825 34.4795 11.4721 35.354 13.4999C36.2295 15.5277 36.6673 17.6944 36.6673 19.9999C36.6673 22.3055 36.2295 24.4721 35.354 26.4999C34.4795 28.5277 33.2923 30.2916 31.7923 31.7916C30.2923 33.2916 28.5284 34.4788 26.5007 35.3533C24.4729 36.2288 22.3062 36.6666 20.0007 36.6666Z"
				fill="#3C6662"
			/>
		</g>
	</svg>
);

export default Check;
