import React, {useMemo} from "react";
import {GeneralButton, MainWrapper} from "mapx-components";
import styles from "./styles.module.scss";
import classNames from "classnames";
import {Link, Route, Switch, useLocation} from "react-router-dom";
import {InfoIcon} from "assets/icons";
import {useSelector} from "react-redux";
import {useAppSelector} from "hooks";
import {hasAccessToExtendedPagesSelector} from "store/mapx/user/userSelectors";
import {companiesPaginationInfoSelector} from "store/mapx/company/companySelectors";
import pageTitleForCompaniesSearch from "mapx-pages/CompanySearch/pageTitle";
import MarketMaps from "./MarketMaps";
import CompanyFilterSearch from "mapx-pages/CompanySearch/CompanyFilterSearch";

const TAB_OPTIONS = [
	{
		name: "Company Search",
		path: "/companies",
		premium: true,
	},
	{
		name: "Target Lists",
		path: "/market-maps",
		premium: true,
	},
];

const CompanySearch = () => {
	const {pathname} = useLocation();

	const filterSearchPagination = useSelector(companiesPaginationInfoSelector);

	const hasAccessToPremium = useAppSelector(hasAccessToExtendedPagesSelector);

	// PAGE TITLE UPDATE
	pageTitleForCompaniesSearch();

	const companiesResultCount = useMemo(() => {
		if (pathname === "/companies") {
			if (!filterSearchPagination) return 0;

			if (filterSearchPagination.count > 10000) return "10,000+";

			if (filterSearchPagination.count > 0) {
				return filterSearchPagination.count;
			}
		}

		return 0;
	}, [filterSearchPagination, pathname]);

	const howToUseButtonUrl = useMemo(() => {
		return (
			process.env?.REACT_APP_DWS_WEB_TUTORIAL_URL ??
			"https://26144956.hs-sites-eu1.com/en/knowledge-base"
		);
	}, []);

	return (
		<MainWrapper>
			<div className={styles.searchHeaderSection}>
				<div className={styles.tabs}>
					{TAB_OPTIONS.filter((t) => !t.premium || (t.premium && hasAccessToPremium)).map(
						(option) => (
							<Link
								key={option.path}
								className={classNames(styles.tabTitle, {
									[styles.activeTab]: pathname === option.path,
								})}
								to={option.path}
							>
								{option.name}
								{pathname === option.path && companiesResultCount !== 0 && (
									<span className={styles.count}>{companiesResultCount}</span>
								)}
							</Link>
						),
					)}
				</div>

				<div className={styles.buttons}>
					{howToUseButtonUrl && (
						<GeneralButton
							title="How to Use"
							icon={<InfoIcon color={"#4E5555"} />}
							onClick={() => window.open(howToUseButtonUrl, "_blank")}
							className={styles.howToUse}
						/>
					)}

					{/*<GeneralButton*/}
					{/*	title="Enhance"*/}
					{/*	icon={<MagicWandIcon color={"#0C5850"} />}*/}
					{/*	onClick={() => noop()}*/}
					{/*	className={styles.enhanceButton}*/}
					{/*/>*/}
				</div>
			</div>

			<div className={styles.tabContent}>
				<Switch>
					<Route
						path={["/companies"]}
						render={() => (
							<CompanyFilterSearch companiesResultCount={companiesResultCount} />
						)}
						exact={true}
						// private={true}
					/>

					<Route
						path={["/market-maps"]}
						render={() => <MarketMaps />}
						exact={true}
						// private={true}
					/>
				</Switch>
			</div>
		</MainWrapper>
	);
};

export default CompanySearch;
