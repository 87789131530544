import {useMemo, useEffect, forwardRef, useCallback, useImperativeHandle} from "react";
import classNames from "classnames";
import {isEqual, isUndefined, noop} from "lodash";
import {yupResolver} from "@hookform/resolvers/yup";
import {useForm, DefaultValues, FieldValues} from "react-hook-form";

import {usePrevious} from "hooks";
import {Button, Message} from "components";

import FormInput from "../FormInput";
import CheckboxRow from "../CheckboxRow";

import type {TField, TFormProps, TFormRefProps} from "./types";
import styles from "./Form.module.scss";

const Form = forwardRef<TFormRefProps, TFormProps>(
	(
		{
			to,
			loading,
			success,
			onSubmit,
			submitText,
			inputError,
			className = "",
			btnClasses = "",
			setInputError = noop,
			form: {fields, schema},
			showErrorTooltip = false,
		},
		ref,
	) => {
		const defaultValues = useMemo(
			() =>
				fields.reduce<DefaultValues<FieldValues>>((acc, curVal) => {
					const {defaultValue, name} = curVal;

					if (!isUndefined(defaultValue) && name) {
						acc[name] = defaultValue;
					}

					return acc;
				}, {}),
			[fields],
		);

		const {
			watch,
			control,
			trigger,
			register,
			setFocus,
			setValue,
			clearErrors,
			handleSubmit,
			formState: {errors, isValid},
		} = useForm({
			defaultValues,
			mode: "onTouched",
			reValidateMode: "onChange",
			resolver: yupResolver(schema),
		});
		const currentFormValues = watch();
		const prevFormValues = usePrevious(currentFormValues);

		const buttonDisabled = !isValid || loading;

		const formClasses = classNames(styles.container, {
			[className]: className,
		});
		const disabledButtonClasses = classNames(styles.container__button, {
			[btnClasses]: btnClasses,
		});

		const renderField = useCallback(
			(name: string, {...rest}: TField) => {
				const commonProps = {
					key: name,
					...rest,
					...register(name),
					error: errors[name]?.message,
				};

				const watchFields =
					watch().password || watch().newPassword || watch().confirmPassword;

				switch (rest.type) {
					case "checkbox":
						return <CheckboxRow key={name} inputProps={commonProps} />;

					default:
						return (
							<FormInput
								to={to}
								control={control}
								trigger={trigger}
								setValue={setValue}
								className={styles.input}
								clearErrors={clearErrors}
								passwordWatches={watchFields}
								showErrorTooltip={showErrorTooltip}
								{...commonProps}
							/>
						);
				}
			},
			// eslint-disable-next-line react-hooks/exhaustive-deps
			[errors, to, control, register, setValue],
		);

		const renderFields = useCallback(() => {
			const fieldsGrouped: Record<string, JSX.Element[]> = {};

			// Group fields by 'group' property
			fields.forEach(({name, ...rest}) => {
				const fieldGroup = rest.group || "default"; // Use 'default' group for fields without a group

				if (!fieldsGrouped[fieldGroup]) fieldsGrouped[fieldGroup] = [];

				fieldsGrouped[fieldGroup].push(renderField(String(name), rest));
			});

			// Render fields, wrapping grouped fields under a single div
			return Object.entries(fieldsGrouped).map(([group, groupFields]) =>
				group === "default" ? (
					groupFields
				) : (
					<div key={group} className={group}>
						{groupFields}
					</div>
				),
			);
		}, [fields, renderField]);

		const onSubmitFailed = useCallback(() => {
			setFocus(fields[0].name as string);
		}, [fields, setFocus]);

		useEffect(() => {
			if (!isEqual(prevFormValues, currentFormValues) && inputError) {
				setInputError("");
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [currentFormValues, inputError, prevFormValues]);

		useImperativeHandle(
			ref,
			() => ({
				onSubmitFailed,
			}),
			[onSubmitFailed],
		);

		return (
			<form data-testid="form" className={formClasses} onSubmit={handleSubmit(onSubmit)}>
				<Message
					message={success || inputError}
					className={styles.container__message}
					level={success ? "success" : "error"}
				/>

				{renderFields()}

				<Button
					type="submit"
					loading={loading}
					disabled={buttonDisabled}
					data-testid="form/submit-button"
					className={disabledButtonClasses}
				>
					{submitText}
				</Button>
			</form>
		);
	},
);

Form.displayName = "Form";

export default Form;
