import styles from "../styles.module.scss";
import React from "react";
import {useAppSelector} from "hooks";
import {
	activeProjectResultsTabContentSelector,
	activeSearchRequestSelector,
} from "store/mapx/additional-profiles/additionalProfilesSelectors";
import SearchRequestContentBlock from "./SearchRequestContentBlock";
import {TProjectsResultsTabContentTypes} from "mapx-components/SearchRequestResults/FilterBlock/types";

const ContentBlock = () => {
	const activeContent: TProjectsResultsTabContentTypes = useAppSelector(
		activeProjectResultsTabContentSelector,
	);

	const activeSearchRequest = useAppSelector(activeSearchRequestSelector);

	return (
		<div className={styles.contentWrapper}>
			{activeContent === "search_results" &&
				activeSearchRequest !== null &&
				activeSearchRequest?.type && (
					<SearchRequestContentBlock searchRequest={activeSearchRequest} />
				)}
		</div>
	);
};

export default ContentBlock;
