import {Dispatch} from "redux";
import {TAppDispatch, TRootState} from "types";
import {
	TCompanyRevenuePosition,
	TCompanyRevenueRangeItem,
} from "mapx-components/Filters/CompanyRevenueSearchFilter/types";
import {setAPMultipleFilters} from "store/mapx/additional-profiles/additionalProfilesActions";
import {
	apCandidateCompanyRevenueByPositionSelector,
	apCompanyRevenueFilterKeyByPosition,
} from "store/mapx/additional-profiles/apCompanyRevenueFilterSelectors";

export const setCompanyRevenueForAPCandidate =
	({
		position,
		revenueItem,
	}: {
		position: TCompanyRevenuePosition;
		revenueItem: TCompanyRevenueRangeItem;
	}) =>
	async (dispatch: Dispatch<TAppDispatch>, getState: () => TRootState) => {
		const state = getState();

		const filterKey = apCompanyRevenueFilterKeyByPosition(state, position);

		const idsOnState = apCandidateCompanyRevenueByPositionSelector(state, position);

		const index = idsOnState.indexOf(revenueItem.id);

		const alreadySelected = index !== -1;

		const nextIds = alreadySelected
			? [...idsOnState.slice(0, index), ...idsOnState.slice(index + 1)]
			: [...idsOnState, revenueItem.id];

		dispatch(
			setAPMultipleFilters({
				[filterKey]: nextIds,
			}),
		);
	};

export const moveRevenueForAPCandidate =
	({
		from,
		to,
		ids,
	}: {
		from: TCompanyRevenuePosition;
		to: TCompanyRevenuePosition;
		ids: string[];
	}) =>
	async (dispatch: Dispatch<TAppDispatch>, getState: () => TRootState) => {
		const idsSet = new Set(ids);

		const state = getState();

		const fromFilterKey = apCompanyRevenueFilterKeyByPosition(state, from);

		const toFilterKey = apCompanyRevenueFilterKeyByPosition(state, to);

		const fromIds = apCandidateCompanyRevenueByPositionSelector(state, from);

		const toIds = apCandidateCompanyRevenueByPositionSelector(state, to);

		dispatch(
			setAPMultipleFilters({
				[fromFilterKey]: fromIds.filter((id: string) => !idsSet.has(id)),
				[toFilterKey]: [
					...toIds.filter((id: string) => !idsSet.has(id)), // prevent duplication
					...fromIds.filter((id: string) => idsSet.has(id)),
				],
			}),
		);
	};
