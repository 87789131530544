import {useId} from "react";
import {noop} from "lodash";
import classNames from "classnames";
import {Tooltip} from "react-tooltip";

import type {FC, MouseEvent} from "react";

import colors from "styles/themes.module.scss";
import {EMPTY_COMPANY_LOGO} from "mapx-constants";
import {Loader, CompanyTooltip} from "components";
import {useAppSelector, useOpenLink} from "hooks";
import {truncateSentenceEnd} from "helpers/string";
import {MinusSquareFilled, PlusSquare} from "assets/icons";
import {
	isCompanyBeingAddedToTargetListSelector,
	isCompanyBeingRemovedFromTargetListSelector,
} from "store/mapx/target-list/targetListSelectors";

import type {TCompanyCard} from "./types";
import styles from "./CompanyCard.module.scss";

const CompanyCard: FC<TCompanyCard> = ({
	company,
	isSelectedItem,
	isTooltipSelect,
	handleAddClick = noop,
	handleRemoveClick = noop,
}) => {
	const id = useId();
	const openLink = useOpenLink();
	const isBeingAdded = useAppSelector((state) =>
		isCompanyBeingAddedToTargetListSelector(state, company?.id),
	);
	const isBeingRemoved = useAppSelector((state) =>
		isCompanyBeingRemovedFromTargetListSelector(state, company?.id),
	);

	const handleItemClick = (event: MouseEvent, companyId: string) => {
		const link = `/company/${companyId}`;
		openLink(event, link);
	};

	return (
		<>
			<div key={company?.id} data-tooltip-id={id} className={styles.company}>
				<div className={styles.top}>
					<div className={styles.left} onClick={(e) => handleItemClick(e, company?.id)}>
						<img
							loading="lazy"
							alt={company?.name}
							className={styles.logo}
							src={company?.logo_url || EMPTY_COMPANY_LOGO}
							onError={(e) => {
								(e.target as HTMLImageElement).src = EMPTY_COMPANY_LOGO;
							}}
						/>
					</div>
					<div className={styles.right}>
						<div
							role="button"
							className={styles.name}
							onClick={(event) => handleItemClick(event, company?.id)}
						>
							{company?.name}
						</div>
						<div className={styles.industry}>{company?.industry?.name}</div>
					</div>
				</div>
				{!isTooltipSelect && (
					<div
						className={classNames(styles.company__select, {
							[styles.company__select_active]: isSelectedItem,
						})}
					>
						{isBeingAdded || isBeingRemoved ? (
							<Loader
								width={24}
								height={24}
								type="TailSpin"
								color={colors.loaderDotColor}
							/>
						) : (
							<>
								{isSelectedItem ? (
									<div
										role="button"
										className={styles.remove}
										onClick={handleRemoveClick}
										data-testid="remove-button"
									>
										<MinusSquareFilled />
									</div>
								) : (
									<div
										role="button"
										className={styles.add}
										onClick={handleAddClick}
										data-testid="add-button"
									>
										<PlusSquare />
									</div>
								)}
							</>
						)}
					</div>
				)}

				<div className={styles.bottom}>
					{truncateSentenceEnd(company?.description, 120, 220)}
				</div>
			</div>

			<Tooltip id={id} place="right" className={styles.tooltip} clickable delayShow={500}>
				<CompanyTooltip company={company} isTooltipSelect={isTooltipSelect} />
			</Tooltip>
		</>
	);
};

export default CompanyCard;
