const minutesToDate = (
	minutes: number,
): {years: number; months: number; days: number; hours: number; remainingMinutes: number} => {
	const yearInMillisecond = 31556952000;
	const monthInMillisecond = 2629746000;
	const dayInMillisecond = 86400000;
	const hourInMillisecond = 3600000;

	const years = Math.floor(minutes / (yearInMillisecond / 60000));
	minutes %= yearInMillisecond / 60000;

	const months = Math.floor(minutes / (monthInMillisecond / 60000));
	minutes %= monthInMillisecond / 60000;

	const days = Math.floor(minutes / (dayInMillisecond / 60000));
	minutes %= dayInMillisecond / 60000;

	const hours = Math.floor(minutes / (hourInMillisecond / 60000));
	const remainingMinutes = Math.floor(minutes % (hourInMillisecond / 60000));

	return {years, months, days, hours, remainingMinutes};
};

export const renderMintConvertValue = (minutes: number) => {
	const convertingObjectFromMinute = minutesToDate(minutes);
	const {years, months, days, hours, remainingMinutes} = convertingObjectFromMinute;

	const isYears = years !== 0 ? `${years}y ` : "";
	const isMonths = months !== 0 ? `${months}month ` : "";
	const isDays = days !== 0 ? `${days}d ` : "";
	const isHours = hours !== 0 ? `${hours}h ` : "";
	const isMinute = remainingMinutes !== 0 ? `${remainingMinutes}m` : "";

	return `${isYears}${isMonths}${isDays}${isHours}${isMinute}`;
};

export const minutesToHour = (minutes: number) => {
	const hourInMinutes = 60;

	return Math.floor(minutes / hourInMinutes);
};

export const validateHourByMinutes = (minutes: number) => {
	const hours = minutesToHour(minutes);

	return hours <= 5;
};
