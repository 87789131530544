import type {FC} from "react";

import type {TFormInputErrorsProps} from "./types";
import styles from "./FormInput.module.scss";

const FormInputErrors: FC<TFormInputErrorsProps> = ({error, inputError}) => (
	<>
		{error || inputError ? (
			<>
				{error && !inputError && (
					<span className={styles.container__is_valid}>{String(error)}</span>
				)}

				{inputError && (
					<span data-testid="form-input/error" className={styles.container__error}>
						{inputError}
					</span>
				)}
			</>
		) : (
			<></>
		)}
	</>
);

export default FormInputErrors;
