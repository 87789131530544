import {combineReducers} from "redux";
import MapXLinkedInBulkImportReducers from "store/mapx/linkedin-bulk-import/linkedInBulkImportReducers";
import refreshCandidateReducer from "store/mapx/refresh-candidate/refreshCandidatesReducer";
import MapXAPReducers from "./mapx/additional-profiles/additionalProfilesReducers";
import MapXCandidateReducers from "./mapx/candidate/candidateReducers";
import MapXCompanyReducers from "./mapx/company/companyReducers";
import MapXEventTrackingReducers from "./mapx/events/eventTrackingReducers";
import MapXFilterReducers from "./mapx/filter/filterReducers";
import MapXLearnToRankReducers from "./mapx/learn-to-rank/learnToRankReducers";
import marketMapReducer from "./mapx/market-map/marketMapReducers";
import MapXOrganisationReducers from "./mapx/organisation/organisationReducers";
import MapXPaymentReducers from "./mapx/payment/paymentReducers";
import MapxProjectListReducer from "./mapx/project-list/projectListReducer";
import MapxProjectReducer from "./mapx/project/projectReducer";
import MapXSearchReducers from "./mapx/search/searchReducers";
import MapXTargetListReducers from "./mapx/target-list/targetListReducers";
import MapXUserReducers from "./mapx/user/userReducers";
import backgroundTaskReducer from "./mapx/background-tasks/backgroundTaskReducer";
import layoutReducer from "./mapx/layout/layoutReducers";

const appReducer = combineReducers({
	candidate: MapXCandidateReducers,
	linkedinImport: MapXLinkedInBulkImportReducers,
	backgroundTask: backgroundTaskReducer,
	refresh_candidate: refreshCandidateReducer,
	company: MapXCompanyReducers,
	events: MapXEventTrackingReducers,
	filter: MapXFilterReducers,
	search: MapXSearchReducers,
	// targetListApi: MapXTargetReducers,
	targetList: MapXTargetListReducers,
	projectList: MapxProjectListReducer,
	user: MapXUserReducers,
	mapxProject: MapxProjectReducer,
	additional_profiles: MapXAPReducers,
	organisation: MapXOrganisationReducers,
	learn_to_rank: MapXLearnToRankReducers,
	market_map: marketMapReducer,
	payment: MapXPaymentReducers,
	layout: layoutReducer,
});

const rootReducer = (state, action) => {
	// if (action.type === LOGOUT) {
	// 	state = undefined;
	// }

	return appReducer(state, action);
};

export default rootReducer;
