import type {IconProps} from "./types";

const CheckCircleInvertedIcon = (props: IconProps) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={20}
		height={20}
		fill="none"
		viewBox="0 0 20 20"
		{...props}
	>
		<path
			fill="#0C5850"
			d="m8.938 13 4.958-4.938L12.833 7l-3.896 3.875-1.77-1.75-1.063 1.063L8.937 13ZM10 18a7.796 7.796 0 0 1-3.104-.625 8.064 8.064 0 0 1-2.552-1.719 8.063 8.063 0 0 1-1.719-2.552A7.797 7.797 0 0 1 2 10c0-1.111.208-2.15.625-3.115a8.064 8.064 0 0 1 4.27-4.26A7.797 7.797 0 0 1 10 2c1.111 0 2.15.208 3.115.625a8.095 8.095 0 0 1 4.26 4.26C17.792 7.851 18 8.89 18 10a7.797 7.797 0 0 1-.625 3.104 8.063 8.063 0 0 1-4.26 4.271A7.774 7.774 0 0 1 10 18Z"
		/>
	</svg>
);

export default CheckCircleInvertedIcon;
