import type {FC} from "react";
import {useMemo} from "react";
import classNames from "classnames";

import {Form} from "components";
import {Logo} from "assets/icons";

import LoginCover from "./LoginCover";

import type {IAuthViewProps} from "./types";
import styles from "./AuthView.module.scss";
import RegisterCover from "containers/Auth/RegisterCover";

const AuthView: FC<IAuthViewProps> = ({
	to,
	form,
	title,
	error,
	success,
	loading,
	onSubmit,
	submitText,
	description,
	btnClasses = "",
	coverDescription,
	showErrorTooltip,
	formContainerClasses = "",
}) => {
	const isSignUpPage = useMemo(
		() =>
			title === "Create an Account" ||
			title === "Last Step" ||
			title === "Sign up today" ||
			title === "Check your inbox",
		[title],
	);

	return (
		<div className={styles.container}>
			<div
				className={classNames(styles.container__login, {
					[formContainerClasses]: formContainerClasses,
				})}
			>
				<div className={styles.container__login__wrapper}>
					<Logo className={styles.container__login_icon} />

					<h1 className={styles.container__login__title}>{title}</h1>

					<div className={styles.container__login__description}>{description}</div>

					{form && onSubmit && submitText && (
						<Form
							to={to}
							form={form}
							success={success}
							loading={loading}
							inputError={error}
							onSubmit={onSubmit}
							submitText={submitText}
							btnClasses={btnClasses}
							showErrorTooltip={showErrorTooltip}
						/>
					)}
				</div>
			</div>

			{isSignUpPage && <RegisterCover />}

			{!isSignUpPage && <LoginCover coverDescription={coverDescription} />}
		</div>
	);
};

export default AuthView;
