import type {FC} from "react";

import type {IconProps} from "./types";

const SelectAllIcon: FC<IconProps> = ({width = 16, height = 16, color = "#5A5A5A"}) => (
	<svg
		width={width}
		height={height}
		viewBox={`0 0 ${width} ${height}`}
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M5.938 10L10.896 5.062L9.833 4L5.938 7.875L4.167 6.125L3.104 7.188L5.938 10ZM1.5 14C1.08333 14 0.729333 13.854 0.438 13.562C0.146 13.2707 0 12.9167 0 12.5V1.5C0 1.08333 0.146 0.729333 0.438 0.438C0.729333 0.146 1.08333 0 1.5 0H12.5C12.9167 0 13.2707 0.146 13.562 0.438C13.854 0.729333 14 1.08333 14 1.5V12.5C14 12.9167 13.854 13.2707 13.562 13.562C13.2707 13.854 12.9167 14 12.5 14H1.5Z"
			fill={color}
		/>
	</svg>
);

export default SelectAllIcon;
