import {useCallback, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {ToastContent} from "components";
import {
	importLinkedInProfileInfoSelector,
	isLinkedInProfileImportIsInProgress,
} from "store/mapx/candidate/candidateSelectors";
import {toast} from "react-toastify";
import {
	importLinkedInCandidateInProgress,
	resetFilteredCandidates,
	setImportLinkedInProfileInfo,
	setSelectedCandidatesDone,
} from "store/mapx/candidate/candidateActions";
import {getImportLinkedinCandidateInfoById} from "store/mapx/candidate/linkedInProfileImportAsyncActions";
import {removeDuplicateObjectFromArray} from "helpers/filterHandlers";
import {
	SICandidate,
	TLinkedInCandidateImport,
	TLinkedinUrlImportStatus,
} from "api/candidateApi/types";

/* eslint-disable  @typescript-eslint/no-explicit-any */
let intervalId = {} as any;

const useImportLinkedInProfileProgressWatcher = () => {
	const history = useHistory();

	const importLinkedInProfileInfo = useSelector(importLinkedInProfileInfoSelector);

	const isImporting = useSelector(isLinkedInProfileImportIsInProgress);

	const dispatch = useDispatch();

	const importWatcher = useCallback(
		async (response: TLinkedInCandidateImport, autoClose: number | false) => {
			const candidate = response?.candidate;

			if (response?.status === "Imported" && candidate) {
				toast.success(ToastContent, {
					autoClose: autoClose,
					closeOnClick: false, // because close option available on the notification
					data: {
						title: candidate.name,
						description: (closeToast: () => void, linkColor: any) => (
							<>
								Search is completed.{" "}
								<span
									className="toast-link"
									style={{color: linkColor}}
									onClick={() => {
										history.push(`/candidate/${candidate.id}`);
										closeToast();
									}}
								>
									Click here
								</span>{" "}
								to see the profile.
							</>
						),
					},
				});
			} else if (response?.error) {
				toast.info(ToastContent, {
					autoClose: autoClose,
					closeOnClick: true,
					data: {
						title:
							"CandidateDetails Import: " + response.message ||
							"Something went wrong!",
					},
				});
			}
		},
		[history],
	);

	const isImportInProgress = (status: TLinkedinUrlImportStatus) => {
		return status === "Created" || status === "In Progress" || status === "Scraped";
	};

	/**
	 * Here on the state we have kept the information while user searched on people search page
	 * We check the active import state for the search
	 * Then we watch the import status
	 * if import is success, then we add candidate to search list
	 * if import is failed, then we show notification with reason
	 */

	const addCandidateToTheSearchList = useCallback(
		(candidate: SICandidate) => {
			candidate.searched_by_linkedin = true;

			const updatedSelectedCandidates = removeDuplicateObjectFromArray([candidate]) || [];

			dispatch(setSelectedCandidatesDone(updatedSelectedCandidates));

			dispatch(resetFilteredCandidates());
		},
		[dispatch],
	);

	useEffect(() => {
		const inProgress = isImportInProgress(importLinkedInProfileInfo?.status);

		if (isImporting && inProgress) {
			intervalId = setInterval(async () => {
				const response = await dispatch(
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					getImportLinkedinCandidateInfoById(importLinkedInProfileInfo.id),
				);

				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				await importWatcher(response, false);

				if (response?.status === "Imported" && response?.candidate !== null) {
					clearInterval(intervalId);

					addCandidateToTheSearchList(response.candidate);

					dispatch(setImportLinkedInProfileInfo(null));

					dispatch(importLinkedInCandidateInProgress(false));
				}
			}, 10 * 1000);

			setTimeout(() => {
				clearInterval(intervalId);
				dispatch(setImportLinkedInProfileInfo(null));
				dispatch(importLinkedInCandidateInProgress(false));

				toast.info(ToastContent, {
					autoClose: 3000,
					closeOnClick: true,
					data: {
						title: "This profile has been sent for manual import. If it is a valid URL, it will be visible on MapX within 12 hours",
					},
				});
			}, 1000 * 60 * 30); // it will clear after 30 Minutes
		}

		return () => {
			if (intervalId) {
				clearInterval(intervalId);
			}
		};
	}, [
		importLinkedInProfileInfo,
		dispatch,
		importWatcher,
		isImporting,
		addCandidateToTheSearchList,
	]);
};

export default useImportLinkedInProfileProgressWatcher;
