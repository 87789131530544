import {useAppSelector} from "hooks";
import {useCallback} from "react";
import {companiesOrgChartFiltersSelector} from "store/mapx/company/companySelectors";
import JobFunctionSearchFilter from "./jobFunctionSearchFilter";
import {TOrgChartFilterProps} from "../types";

function JobFunctionFilterForOrgChart({
	onSelectChange,
}: {
	onSelectChange: TOrgChartFilterProps["onSelectChange"];
}) {
	const filtersInState = useAppSelector(companiesOrgChartFiltersSelector);

	const selectedSpecialismIds = filtersInState?.current_specialisms;

	const handleResetClick = useCallback(() => {
		onSelectChange("current_job_functions", null);
		onSelectChange("current_specialisms", null);
	}, [onSelectChange]);

	return (
		<JobFunctionSearchFilter
			handleResetClick={handleResetClick}
			handleSpecialismChange={onSelectChange}
			selectedSpecialismIds={selectedSpecialismIds}
			handleOnChange={onSelectChange}
		/>
	);
}

export default JobFunctionFilterForOrgChart;
