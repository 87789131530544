import {useEffect, useState} from "react";
import type {Dispatch, SetStateAction} from "react";

const useSearchDebounce = (
	delay = 500,
	initialValue = "",
): [string, Dispatch<SetStateAction<string>>] => {
	const [search, setSearch] = useState<string>(initialValue);
	const [searchQuery, setSearchQuery] = useState<string>(initialValue);

	useEffect(() => {
		const delayFn = setTimeout(() => setSearch(searchQuery), delay);

		return () => clearTimeout(delayFn);
	}, [searchQuery, delay]);

	return [search, setSearchQuery];
};

export default useSearchDebounce;
