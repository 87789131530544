import type {AxiosRequestConfig} from "axios";

import HttpClient from "api/index";

import {buildQueryParameter} from "helpers/filterHandlers";
import {
	TCompanyMapRequestBody,
	TFilterCompanyPayload,
	TOrganizationalChartParams,
	TReportParams,
} from "api/companyApi/types";

class MapXCompanyApi extends HttpClient {
	async getCompany(companyID: string) {
		return this.doGet(`/companies/${companyID}`);
	}

	async getCompanyLLMSuggestions(description: string, config: AxiosRequestConfig) {
		const payload = {
			user_input: description,
		};

		return this.doPost(`/get-company-llm-suggestions`, payload, config);
	}

	async getCompanyByName(name: string, config: AxiosRequestConfig) {
		const payload = {
			filters: {
				name,
			},
			pagination: {
				page: 1,
				per_page: 40,
			},
		};

		return this.doPost(`/companies`, payload, config);
	}

	async getCompanies(pageNumber = 1, config = {}) {
		return this.doGet(`/companies?page=${pageNumber}&per_page=20`, config);
	}

	async fetchCompanies(page: number, config = {}) {
		const apiPayload = {
			pagination: {
				page: page,
				per_page: 20,
			},
		};

		return this.doPost(`/companies`, apiPayload, config);
	}

	async fetchCompaniesByFilter(payload: TFilterCompanyPayload, config = {}) {
		return this.doPost(`/companies`, payload, config);
	}

	async getCompaniesByFilter(filterQuery: string, config = {}) {
		return this.doGet(`/companies${filterQuery}`, config);
	}

	async getSimilarCompanies(filterQuery: string, perPage = 3, config: AxiosRequestConfig) {
		filterQuery = filterQuery ? filterQuery + `&per_page=${perPage}` : `?per_page=${perPage}`;

		return this.doGet(`/mapx/similar-companies${filterQuery}`, config);
	}

	async getCompanyMapFunctionHistory(companyId: string, config?: AxiosRequestConfig) {
		return this.doGet(`/map-company-requests`, {
			...config,
			params: {
				company_id: companyId,
				per_page: 20,
			},
		});
	}

	async saveCompanyMapRequest(formBody: TCompanyMapRequestBody, config?: AxiosRequestConfig) {
		return this.doPost(`/map-company-requests`, formBody, config);
	}

	async getCompanyMapFunctionById(requestId: string, config?: AxiosRequestConfig) {
		return this.doGet(`/map-company-requests/${requestId}`, config);
	}

	async getCompaniesRevenueDistribution(params: string) {
		return this.doGet(`/mapx/companies-revenue-distribution${params}`);
	}

	async getCompaniesSizeDistribution(params: string) {
		return this.doGet(`/mapx/companies-size-distribution${params}`);
	}

	async getCompanyEthnicDiversity(
		companyId: string,
		params: TReportParams = {},
		config?: AxiosRequestConfig,
	) {
		const paramObj = {
			...params,
			company_id: parseInt(companyId),
		};

		const modifiedParams = buildQueryParameter(paramObj) || "";

		return this.doGet(`/mapx/ethnic-diversity-report${modifiedParams}`, config);
	}

	async getCompanyGenderDiversity(
		companyId: string,
		params: TReportParams = {},
		config?: AxiosRequestConfig,
	) {
		const paramObj = {
			...params,
			company_id: parseInt(companyId),
		};

		const modifiedParams = buildQueryParameter(paramObj) || "";

		return this.doGet(`/mapx/gender-diversity-report${modifiedParams}`, config);
	}

	async getCompanyTrendStats(
		companyId: string,
		params: TReportParams = {},
		config?: AxiosRequestConfig,
	) {
		const paramObj = {
			...params,
		};

		const modifiedParams = buildQueryParameter(paramObj) || "";

		return this.doGet(`/mapx/companies/${companyId}/stats${modifiedParams}`, config);
	}

	async getCompanyOrganizationalChart(
		companyId: string,
		params: TReportParams = {},
		config?: AxiosRequestConfig,
	) {
		const organizationChartParams: TOrganizationalChartParams = {...params};

		const paramObj = {
			...organizationChartParams,
		};

		if (paramObj?.regions && paramObj?.regions?.length > 0) {
			delete paramObj.regions;
		}

		if (paramObj && !("page" in paramObj)) {
			paramObj.page = 1;
			paramObj.per_page = 40;
		} else if (paramObj && "page" in paramObj) {
			paramObj.page = 1; // even though its page 1, BE skipped 1st 40
			paramObj.per_page = 1000;
		}

		const modifiedParams = buildQueryParameter(paramObj) || "";

		return this.doGet(
			`/mapx/companies/${companyId}/organisation-chart${modifiedParams}`,
			config,
		);
	}

	async getCompanyFrequentlyHiredFrom(
		companyId: string,
		page = 1,
		params: TReportParams = {},
		config?: AxiosRequestConfig,
	) {
		const paramObj = {
			...params,
			page,
			per_page: 9,
		};

		const modifiedParams = buildQueryParameter(paramObj) || "";

		return this.doGet(
			`/mapx/companies/${companyId}/frequently-hired-from${modifiedParams}`,
			config,
		);
	}

	async getCompanyIndustryBackground(
		companyId: string,
		params: TReportParams = {},
		config?: AxiosRequestConfig,
	) {
		const paramObj = {
			...params,
		};

		const modifiedParams = buildQueryParameter(paramObj) || "";

		return this.doGet(
			`/mapx/companies/${companyId}/industries-background${modifiedParams}`,
			config,
		);
	}

	async getCompanyFunctionalBackground(
		companyId: string,
		params: TReportParams = {},
		config?: AxiosRequestConfig,
	) {
		const paramObj = {
			...params,
			company_id: parseInt(companyId),
		};
		const modifiedParams = buildQueryParameter(paramObj) || "";

		return this.doGet(`/mapx/specialism-positions-report${modifiedParams}`, config);
	}
}

const mapXCompanyApi = new MapXCompanyApi();

export default mapXCompanyApi;
