import {useAppDispatch, useAppSelector} from "hooks";
import {Accordion} from "mapx-components";
import {useCallback} from "react";
import {
	setMultipleFilterForCandidates,
	setPeopleSearchCompanyTypeFilterConfig,
} from "store/mapx/filter/filterActions";
import {filterPositionOptionsSelector} from "store/mapx/filter/filterSelectors";
import {TabSelect} from "components";
import {TCompanyTypeFilterProps} from "containers/Filters/PeopleFilters/CompanyTypeFilter/types";
import CompanyTypeSearchFilter from "mapx-components/Filters/CandidateCompanyTypeSearchFilter";
import {
	allCompanyTypesCountSelector,
	candidateCompanyTypesByPositionSelector,
	companyTypeCandidatePositionSelector,
	companyTypeFilterConfigSelector,
	selectedAnyCompanyTypesSelector,
	selectedCurrentCompanyTypesSelector,
	selectedPreviousCompanyTypesSelector,
} from "store/mapx/filter/companyTypeFilterSelectors";
import {TCompanyTypePosition} from "mapx-components/Filters/CandidateCompanyTypeSearchFilter/types";
import {
	clearCompanyTypeByPositionForCandidate,
	moveCompanyTypeForCandidate,
	setCompanyTypeForCandidate,
} from "store/mapx/filter/companyTypeFilterAsyncActions";
import type {TKeywordPosition} from "api/filterOptionsApi/KeywordApi/types";

function CompanyTypeFilter({disabled, displayLock = false}: TCompanyTypeFilterProps) {
	const dispatch = useAppDispatch();

	const filterConfig = useAppSelector(companyTypeFilterConfigSelector);

	const position = useAppSelector<TCompanyTypePosition>(companyTypeCandidatePositionSelector);

	const filterPositionOptions = useAppSelector(filterPositionOptionsSelector);

	const count = useAppSelector(allCompanyTypesCountSelector);

	const anyTags = useAppSelector(selectedAnyCompanyTypesSelector);

	const currentTags = useAppSelector(selectedCurrentCompanyTypesSelector);

	const previousTags = useAppSelector(selectedPreviousCompanyTypesSelector);

	const selectedItems = useAppSelector((state) =>
		candidateCompanyTypesByPositionSelector(state, position),
	);

	const handlePositionChange = useCallback(
		(updatedPositionValue: string) => {
			dispatch(
				setPeopleSearchCompanyTypeFilterConfig({
					...filterConfig,
					active_position: updatedPositionValue,
				}),
			);
		},
		[dispatch, filterConfig],
	);

	const handleChange = useCallback(
		(id: string) => {
			dispatch(setCompanyTypeForCandidate({id, position}));
		},
		[dispatch, position],
	);

	const moveTagPosition = useCallback(
		(value: string, fromPosition: TCompanyTypePosition, toPosition: TCompanyTypePosition) => {
			dispatch(
				moveCompanyTypeForCandidate({
					from: fromPosition,
					to: toPosition,
					id: value,
				}),
			);
		},
		[dispatch],
	);

	const handleTagClick = useCallback(
		(id: string, position: TKeywordPosition) => {
			dispatch(
				setCompanyTypeForCandidate({
					position,
					id,
				}),
			);
		},
		[dispatch],
	);

	const handleResetClick = useCallback(() => {
		dispatch(
			setMultipleFilterForCandidates({
				company_type: [],
				current_company_type: [],
				previous_company_type: [],
			}),
		);
	}, [dispatch]);

	const handleClearTagClickByPosition = useCallback(
		(position: TCompanyTypePosition) => {
			dispatch(clearCompanyTypeByPositionForCandidate(position));
		},
		[dispatch],
	);

	return (
		<Accordion
			title="Company Type"
			subtitle={count}
			disabled={disabled}
			displayLock={displayLock}
		>
			<TabSelect.LabelContainer label="Position">
				<TabSelect
					selected={position}
					onTabChange={handlePositionChange}
					options={filterPositionOptions}
				/>
			</TabSelect.LabelContainer>

			<CompanyTypeSearchFilter
				selectedCompanyTypes={selectedItems}
				handleResetClick={handleResetClick}
				handleOnChange={handleChange}
				handleTagClick={handleTagClick}
				displayResetFilterOption={count > 0}
				moveCompanyTypeTagPosition={moveTagPosition}
				selectedCompanyTypeTags={{
					current: currentTags,
					previous: previousTags,
					any: anyTags,
				}}
				clearTagsByPosition={handleClearTagClickByPosition}
			/>
		</Accordion>
	);
}

export default CompanyTypeFilter;
