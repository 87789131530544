import {noop} from "lodash";
import type {FC} from "react";

import type {IconProps} from "./types";

const RecycleBin: FC<IconProps> = ({onClick = noop, className = ""}) => (
	<svg
		width="20"
		height="20"
		fill="none"
		role="button"
		onClick={onClick}
		viewBox="0 0 20 20"
		className={className}
		xmlns="http://www.w3.org/2000/svg"
	>
		<mask id="mask0_306_9471" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
			<rect width="20" height="20" fill="#D9D9D9" />
		</mask>
		<g mask="url(#mask0_306_9471)">
			<path
				fill="#CBC5C2"
				d="M6.5 17C6.08333 17 5.72933 16.854 5.438 16.562C5.146 16.2707 5 15.9167 5 15.5V5.5H4V4H8V3H12V4H16V5.5H15V15.5C15 15.9167 14.854 16.2707 14.562 16.562C14.2707 16.854 13.9167 17 13.5 17H6.5ZM13.5 5.5H6.5V15.5H13.5V5.5ZM8 14H9.5V7H8V14ZM10.5 14H12V7H10.5V14Z"
			/>
		</g>
	</svg>
);

export default RecycleBin;
