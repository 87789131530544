import {
	STLinkedinUrlImports,
	TGenerateContactDetailsApiPayload,
	TGenerateSummariesApiPayload,
} from "api/candidateApi/types";

import HttpClient from "api/index";
import {
	ICandidateByIdsApiRequestPayload,
	ICandidateSearchApiRequestPayload,
} from "api/candidateApi/form";

class CandidateAPI extends HttpClient {
	async getCandidate(candidateID: number) {
		return this.doGet(`/candidates/${candidateID}`);
	}

	async getCandidatesByName(payload: ICandidateSearchApiRequestPayload, config = {}) {
		return this.doPost(`/get-candidates`, payload, config);
	}

	async getCandidatesForPeopleSearch(pageNumber: number, config = {}) {
		const payload: ICandidateSearchApiRequestPayload = {
			pagination: {
				page: pageNumber,
			},
		};

		return this.doPost(`/get-candidates`, payload, config);
	}

	async getFilteredCandidatesForPeopleSearch(
		payload: ICandidateSearchApiRequestPayload,
		config = {},
	) {
		return this.doPost(`/get-candidates`, payload, config);
	}

	async getCandidatesByFilter(payload: ICandidateSearchApiRequestPayload, config = {}) {
		return this.doPost(`/get-candidates`, payload, config);
	}

	async getCandidatesByIds(payload: ICandidateByIdsApiRequestPayload, config = {}) {
		return this.doPost(`/get-candidates`, payload, config);
	}

	async fetchSimilarCandidatesBySearchedProfile(
		filterQuery: string,
		page = 1,
		perPage = 20,
		// eslint-disable-next-line @typescript-eslint/ban-types
		config: {} = {},
	) {
		filterQuery = `${filterQuery}&page=${page}&per_page=${perPage}`;

		return this.doGet(`/mapx/similar-candidates${filterQuery}`, config);
	}

	async reportIssue(candidateId: number | string, message: string) {
		return this.doPost(`/candidates/${candidateId}/flags`, {flag_type: message});
	}

	async createLinkedinUrlImportRequest(formBody: STLinkedinUrlImports, config = {}) {
		return this.doPost(`/linkedin-url-imports`, formBody, config);
	}

	async getLinkedinUrlImportById(id: number, config = {}) {
		return this.doGet(`/linkedin-url-imports/${id}`, config);
	}

	async getFlaggedCandidates(params: string, config = {}) {
		return this.doGet(`/flagged-candidates${params}`, config);
	}

	async generateSummaries(apiPayload: TGenerateSummariesApiPayload) {
		return this.doPost(`/generate-summaries`, apiPayload);
	}

	async generateContactDetails(apiPayload: TGenerateContactDetailsApiPayload) {
		return this.doPost(`/get-contact-details`, apiPayload);
	}
}

const mapXCandidateApi = new CandidateAPI();

export default mapXCandidateApi;
