import type {Dispatch} from "redux";

import mapXLearnToRankApi from "api/learnToRankApi";

import {
	createLearnToRankDone,
	createLearnToRankFail,
	createLearnToRankInit,
	getLearnToRankDone,
	getLearnToRankFail,
	getLearnToRankInit,
	recordLearnToRankDone,
	recordLearnToRankFail,
	recordLearnToRankInit,
} from "./learnToRankActions";

export const createLearnToRank = () => async (dispatch: Dispatch) => {
	try {
		dispatch(createLearnToRankInit());

		const {data} = await mapXLearnToRankApi.createLearnToRank();

		dispatch(createLearnToRankDone(data));
	} catch (e) {
		dispatch(createLearnToRankFail(e));
	}
};

export const recordLearnToRank =
	(learnToRankTaskID: number, chosenCandidateID: number | null) => async (dispatch: Dispatch) => {
		try {
			dispatch(recordLearnToRankInit());

			await mapXLearnToRankApi.recordLearnToRank(learnToRankTaskID, chosenCandidateID);

			dispatch(recordLearnToRankDone());
		} catch (e) {
			dispatch(recordLearnToRankFail(e));
		}
	};

export const getLearnToRanks = () => async (dispatch: Dispatch) => {
	try {
		dispatch(getLearnToRankInit());

		const {data} = await mapXLearnToRankApi.getLearnToRanks();

		dispatch(getLearnToRankDone(data));
	} catch (e) {
		dispatch(getLearnToRankFail(e));
	}
};
