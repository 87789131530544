import {SET_SIDEBAR_EXTENDED} from "../mapXActionTypes";

export const layoutInitialState = {
	sidebarExpanded: false,
};

const layoutReducer = (state = layoutInitialState, action) => {
	switch (action.type) {
		case SET_SIDEBAR_EXTENDED:
			return {
				...state,
				sidebarExpanded: action.payload,
			};
		default:
			return state;
	}
};

export default layoutReducer;
