import {FC} from "react";
import {IconProps} from "./types";

type TFlagProp = {isFlagged: boolean};

const Flag: FC<IconProps & TFlagProp> = ({isFlagged = false}) => {
	return isFlagged ? (
		<svg
			width="32"
			height="32"
			viewBox="0 0 32 32"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect x="0.5" y="0.5" width="31" height="31" rx="3.5" fill="white" stroke="#D1CDCC" />
			<mask
				id="mask0_1644_28841"
				style={{maskType: "alpha"}}
				maskUnits="userSpaceOnUse"
				x="6"
				y="6"
				width="20"
				height="20"
			>
				<rect x="6" y="6" width="20" height="20" fill="#D9D9D9" />
			</mask>
			<g mask="url(#mask0_1644_28841)">
				<path
					d="M10.166 23.4999V9.33325H17.666L17.9993 10.9999H22.666V19.3333H16.8327L16.4993 17.6666H11.8327V23.4999H10.166Z"
					fill="#3C6662"
				/>
			</g>
		</svg>
	) : (
		<svg
			width="32"
			height="32"
			viewBox="0 0 32 32"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect x="0.5" y="0.5" width="31" height="31" rx="3.5" fill="white" stroke={"#D1CDCC"} />
			<mask
				id="mask0_398_11835"
				style={{maskType: "alpha"}}
				maskUnits="userSpaceOnUse"
				x="6"
				y="6"
				width="20"
				height="20"
			>
				<rect x="6" y="6" width="20" height="20" fill={"#D9D9D9"} />
			</mask>
			<g mask="url(#mask0_398_11835)">
				<path
					d="M10.166 23.4999V9.33325H17.666L17.9993 10.9999H22.666V19.3333H16.8327L16.4993 17.6666H11.8327V23.4999H10.166ZM18.2077 17.6666H20.9993V12.6666H16.6243L16.291 10.9999H11.8327V15.9999H17.8743L18.2077 17.6666Z"
					fill={"#D1CDCC"}
				/>
			</g>
		</svg>
	);
};

export default Flag;
