import HttpClient from "api/index";

class MapxIndustryApi extends HttpClient {
	async getIndustries(config = {}) {
		return this.doGet("/industries", config);
	}
}

const mapxIndustryApi = new MapxIndustryApi();

export default mapxIndustryApi;
