import {createSelector} from "reselect";
import {jobFunctionOptionsSelector} from "store/mapx/search/searchSelectors";
import {TRootState} from "types";
import {
	STJobFunction,
	STSpecialism,
	TJobFunctionPosition,
	TJobFunctionPositionConnectivityLogic,
	TSpecialismTag,
} from "api/filterOptionsApi/JobFunctionApi/types";

export const apJobFunctionFilterConfigSelector = (state: TRootState) =>
	state.additional_profiles.candidate_candidate_job_function_filter_config;

export const apJobFunctionCandidatePositionSelector = (state: TRootState) =>
	state.additional_profiles.candidate_job_function_filter_config.active_position;

export const apCurrentPositionConnectivityLogicSelector = (state: TRootState) =>
	state.additional_profiles.candidate_job_function_filter_config
		.active_current_position_connect_logic;

export const apPreviousPositionConnectivityLogicSelector = (state: TRootState) =>
	state.additional_profiles.candidate_job_function_filter_config
		.active_previous_position_connect_logic;

export const apAnyPositionConnectivityLogicSelector = (state: TRootState) =>
	state.additional_profiles.candidate_job_function_filter_config
		.active_any_position_connect_logic;

export const apCurrentAndSpecialismsSelector = (state: TRootState) =>
	state.additional_profiles.ap_filters.current_specialisms_and;
export const apCurrentOrSpecialismsSelector = (state: TRootState) =>
	state.additional_profiles.ap_filters.current_specialisms_or;
export const apCurrentNotSpecialismsSelector = (state: TRootState) =>
	state.additional_profiles.ap_filters.current_specialisms_not;

export const apPreviousAndSpecialismsSelector = (state: TRootState) =>
	state.additional_profiles.ap_filters.previous_specialisms_and;
export const apPreviousOrSpecialismsSelector = (state: TRootState) =>
	state.additional_profiles.ap_filters.previous_specialisms_or;
export const apPreviousNotSpecialismsSelector = (state: TRootState) =>
	state.additional_profiles.ap_filters.previous_specialisms_not;

export const apAnyAndSpecialismsSelector = (state: TRootState) =>
	state.additional_profiles.ap_filters.specialisms_and;
export const apAnyOrSpecialismsSelector = (state: TRootState) =>
	state.additional_profiles.ap_filters.specialisms_or;
export const apAnyNotSpecialismsSelector = (state: TRootState) =>
	state.additional_profiles.ap_filters.specialisms_not;

const getJobFunctionPosition = (_: TRootState, position: TJobFunctionPosition) => position;
const getJobFunctionPositionConnectLogic = (
	_: TRootState,
	__: TRootState,
	logic: TJobFunctionPositionConnectivityLogic,
) => logic;

export const apSpecialismsByPositionSelector = createSelector(
	[
		apCurrentAndSpecialismsSelector,
		apCurrentOrSpecialismsSelector,
		apCurrentNotSpecialismsSelector,
		apPreviousAndSpecialismsSelector,
		apPreviousOrSpecialismsSelector,
		apPreviousNotSpecialismsSelector,
		apAnyAndSpecialismsSelector,
		apAnyOrSpecialismsSelector,
		apAnyNotSpecialismsSelector,
		getJobFunctionPosition,
		getJobFunctionPositionConnectLogic,
	],
	(
		currentAndSpecialisms,
		currentOrSpecialisms,
		currentNotSpecialisms,
		previousAndSpecialisms,
		previousOrSpecialisms,
		previousNotSpecialisms,
		anyAndSpecialisms,
		anyOrSpecialisms,
		anyNotSpecialisms,
		position,
		logic,
	) => {
		if (position === "current" && logic === "and") {
			return currentAndSpecialisms || [];
		} else if (position === "current" && logic === "or") {
			return currentOrSpecialisms || [];
		} else if (position === "current" && logic === "not") {
			return currentNotSpecialisms || [];
		} else if (position === "previous" && logic === "and") {
			return previousAndSpecialisms || [];
		} else if (position === "previous" && logic === "or") {
			return previousOrSpecialisms || [];
		} else if (position === "previous" && logic === "not") {
			return previousNotSpecialisms || [];
		} else if (position === "any" && logic === "and") {
			return anyAndSpecialisms || [];
		} else if (position === "any" && logic === "or") {
			return anyOrSpecialisms || [];
		} else {
			return anyNotSpecialisms || [];
		}
	},
);

export const apJobFunctionSelectionTreeSelector = createSelector(
	apSpecialismsByPositionSelector,
	jobFunctionOptionsSelector,
	(specialisms, options: []) => {
		const speFunc: Map<number, number> = new Map();
		const tree: Record<number, number[]> = {};

		options.forEach((o: STJobFunction) => {
			o.specialisms.forEach((s) => {
				if (specialisms.includes(s.id)) {
					speFunc.set(s.id, o.id);
				}
			});
		});

		specialisms.forEach((id: number) => {
			const functionId = speFunc.get(id);

			if (functionId !== undefined) {
				tree[functionId] = [...(tree[functionId] || []), id].sort();
			}
		});

		return tree;
	},
);

export const apCurrentJobFunctionTagsSelector = createSelector(
	[
		apCurrentAndSpecialismsSelector,
		apCurrentOrSpecialismsSelector,
		apCurrentNotSpecialismsSelector,
		jobFunctionOptionsSelector,
	],
	(andSpec: [], orSpec: [], notSpec: [], options: []) => {
		const current: TSpecialismTag[] = [];
		const specialisms = [...andSpec, ...orSpec, ...notSpec];
		const set: Set<number> = new Set(specialisms);

		options.forEach((o: STJobFunction) => {
			o.specialisms.forEach((s: STSpecialism) => {
				if (set.has(s.id)) {
					current.push({
						id: s.id,
						name: s.name,
						type: "specialism",
						jobFunctionId: o.id,
						jobFunctionName: o.name,
					});
				}
			});
		});

		return current;
	},
);
export const apPreviousJobFunctionTagsSelector = createSelector(
	[
		apPreviousAndSpecialismsSelector,
		apPreviousOrSpecialismsSelector,
		apPreviousNotSpecialismsSelector,
		jobFunctionOptionsSelector,
	],
	(andSpecialisms: [], orSpecialisms: [], notSpecialisms: [], options: []) => {
		const previous: TSpecialismTag[] = [];

		// it's okay to merge these 3 selection for now, as only one of the current position have value;
		const specialisms = [...andSpecialisms, ...orSpecialisms, ...notSpecialisms];

		const set: Set<number> = new Set(specialisms);

		options.forEach((o: STJobFunction) => {
			o.specialisms.forEach((s: STSpecialism) => {
				if (set.has(s.id)) {
					previous.push({
						id: s.id,
						name: s.name,
						type: "specialism",
						jobFunctionId: o.id,
						jobFunctionName: o.name,
					});
				}
			});
		});

		return previous;
	},
);
export const apAnyJobFunctionTagsSelector = createSelector(
	[
		apAnyAndSpecialismsSelector,
		apAnyOrSpecialismsSelector,
		apAnyNotSpecialismsSelector,
		jobFunctionOptionsSelector,
	],
	(andSpecialisms: [], orSpecialisms: [], notSpecialisms: [], options: []) => {
		const any: TSpecialismTag[] = [];
		const specialisms = [...andSpecialisms, ...orSpecialisms, ...notSpecialisms];
		const set: Set<number> = new Set(specialisms);

		options.forEach((o: STJobFunction) => {
			o.specialisms.forEach((s: STSpecialism) => {
				if (set.has(s.id)) {
					any.push({
						id: s.id,
						name: s.name,
						type: "specialism",
						jobFunctionId: o.id,
						jobFunctionName: o.name,
					});
				}
			});
		});

		return any;
	},
);

export const apSpecialismFilterKeyByPosition = createSelector(
	getJobFunctionPosition,
	getJobFunctionPositionConnectLogic,
	(position, logic) => {
		if (position === "current" && logic === "and") {
			return "current_specialisms_and";
		} else if (position === "current" && logic === "or") {
			return "current_specialisms_or";
		} else if (position === "current" && logic === "not") {
			return "current_specialisms_not";
		} else if (position === "previous" && logic === "and") {
			return "previous_specialisms_and";
		} else if (position === "previous" && logic === "or") {
			return "previous_specialisms_or";
		} else if (position === "previous" && logic === "not") {
			return "previous_specialisms_not";
		} else if (position === "any" && logic === "and") {
			return "specialisms_and";
		} else if (position === "any" && logic === "or") {
			return "specialisms_or";
		} else {
			return "specialisms_not";
		}
	},
);

export const apAllSpecialismsCountSelector = createSelector(
	[
		apCurrentAndSpecialismsSelector,
		apCurrentOrSpecialismsSelector,
		apCurrentNotSpecialismsSelector,
		apPreviousAndSpecialismsSelector,
		apPreviousOrSpecialismsSelector,
		apPreviousNotSpecialismsSelector,
		apAnyAndSpecialismsSelector,
		apAnyOrSpecialismsSelector,
		apAnyNotSpecialismsSelector,
	],
	(
		currentAndSpecialisms,
		currentOrSpecialisms,
		currentNotSpecialisms,
		previousAndSpecialisms,
		previousOrSpecialisms,
		previousNotSpecialisms,
		anyAndSpecialisms,
		anyOrSpecialisms,
		anyNotSpecialisms,
	) =>
		currentAndSpecialisms.length +
		currentOrSpecialisms.length +
		currentNotSpecialisms.length +
		previousAndSpecialisms.length +
		previousOrSpecialisms.length +
		previousNotSpecialisms.length +
		anyAndSpecialisms.length +
		anyOrSpecialisms.length +
		anyNotSpecialisms.length,
);

export const apAllSpecialismIdsSelector = createSelector(
	[
		apCurrentAndSpecialismsSelector,
		apCurrentOrSpecialismsSelector,
		apCurrentNotSpecialismsSelector,
		apPreviousAndSpecialismsSelector,
		apPreviousOrSpecialismsSelector,
		apPreviousNotSpecialismsSelector,
		apAnyAndSpecialismsSelector,
		apAnyOrSpecialismsSelector,
		apAnyNotSpecialismsSelector,
	],
	(
		currentAndSpecialisms,
		currentOrSpecialisms,
		currentNotSpecialisms,
		previousAndSpecialisms,
		previousOrSpecialisms,
		previousNotSpecialisms,
		anyAndSpecialisms,
		anyOrSpecialisms,
		anyNotSpecialisms,
	) => [
		...currentAndSpecialisms,
		...currentOrSpecialisms,
		...currentNotSpecialisms,
		...previousAndSpecialisms,
		...previousOrSpecialisms,
		...previousNotSpecialisms,
		...anyAndSpecialisms,
		...anyOrSpecialisms,
		...anyNotSpecialisms,
	],
);
