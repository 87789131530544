import {setMultipleFilterForCandidates} from "store/mapx/filter/filterActions";
import {Dispatch} from "redux";
import {TAppDispatch, TRootState} from "types";
import {
	candidateCompanyEventSizeSelector,
	candidateCompanyEventTypesSelector,
} from "./DealExperienceFilterSelectors";
import {
	TCandidateCompanyEventSizeItem,
	TCandidateCompanyEventTypeItem,
} from "mapx-components/Filters/DealExperienceSearchFilter/types";

export const setCompanyEventSizeFilterForCandidate =
	({eventSizeItem}: {eventSizeItem: TCandidateCompanyEventSizeItem}) =>
	async (dispatch: Dispatch<TAppDispatch>, getState: () => TRootState) => {
		const state = getState();

		const idsOnState = candidateCompanyEventSizeSelector(state);

		const index = idsOnState.indexOf(eventSizeItem.id);

		const alreadySelected = index !== -1;

		const nextIds = alreadySelected
			? [...idsOnState.slice(0, index), ...idsOnState.slice(index + 1)]
			: [...idsOnState, eventSizeItem.id];

		dispatch(
			setMultipleFilterForCandidates({
				company_events: {
					...state.filter.candidate_filters.company_events,
					size: nextIds,
				},
			}),
		);
	};

export const setCompanyEventTypesFilterForCandidate =
	({eventItem}: {eventItem: TCandidateCompanyEventTypeItem}) =>
	async (dispatch: Dispatch<TAppDispatch>, getState: () => TRootState) => {
		const state = getState();

		const valuesOnState = candidateCompanyEventTypesSelector(state);

		const index = valuesOnState.indexOf(eventItem.name);

		const alreadySelected = index !== -1;

		const nextValues = alreadySelected
			? [...valuesOnState.slice(0, index), ...valuesOnState.slice(index + 1)]
			: [...valuesOnState, eventItem.name];

		dispatch(
			setMultipleFilterForCandidates({
				company_events: {
					...state.filter.candidate_filters.company_events,
					types: nextValues,
				},
			}),
		);
	};
