import type {IconProps} from "./types";

const LightBulbIcon = (props: IconProps) => (
	<svg
		width="20"
		height="20"
		viewBox="0 0 20 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<mask
			id="mask0_32_102792"
			style={{maskType: "alpha"}}
			maskUnits="userSpaceOnUse"
			x="0"
			y="0"
			width="20"
			height="20"
		>
			<rect width="20" height="20" fill="#D9D9D9" />
		</mask>
		<g mask="url(#mask0_32_102792)">
			<path
				d="M9.99558 18C9.58186 18 9.22917 17.8531 8.9375 17.5594C8.64583 17.2656 8.5 16.9125 8.5 16.5H11.5C11.5 16.9167 11.3527 17.2708 11.0581 17.5625C10.7635 17.8542 10.4093 18 9.99558 18ZM7 15.5V14H13V15.5H7ZM6.6875 13C5.82639 12.4722 5.16319 11.7604 4.69792 10.8646C4.23264 9.96875 4 9.01389 4 8C4 6.33333 4.58333 4.91667 5.75 3.75C6.91667 2.58333 8.33333 2 10 2C11.6667 2 13.0833 2.58333 14.25 3.75C15.4167 4.91667 16 6.33333 16 8C16 9.01389 15.7674 9.96875 15.3021 10.8646C14.8368 11.7604 14.1736 12.4722 13.3125 13H6.6875ZM7.16667 11.5H12.8333C13.3611 11.0694 13.7708 10.5486 14.0625 9.9375C14.3542 9.32639 14.5 8.68056 14.5 8C14.5 6.74546 14.0641 5.68182 13.1923 4.80908C12.3205 3.93636 11.258 3.5 10.0048 3.5C8.7516 3.5 7.6875 3.93636 6.8125 4.80908C5.9375 5.68182 5.5 6.74546 5.5 8C5.5 8.68056 5.64583 9.32639 5.9375 9.9375C6.22917 10.5486 6.63889 11.0694 7.16667 11.5Z"
				fill="#4E5555"
			/>
		</g>
	</svg>
);

export default LightBulbIcon;
