import {setMultipleFilterForCandidates} from "store/mapx/filter/filterActions";
import {TIndustryPosition} from "api/filterOptionsApi/IndustryApi/types";
import {Dispatch} from "redux";
import {TAppDispatch, TRootState} from "types";
import {
	candidateIndustriesByPositionSelector,
	industriesFilterKeyByPosition,
} from "store/mapx/filter/industriesFilterSelectors";

export const moveIndustriesForCandidate =
	({from, to, ids}: {from: TIndustryPosition; to: TIndustryPosition; ids: number[]}) =>
	async (dispatch: Dispatch<TAppDispatch>, getState: () => TRootState) => {
		const idsSet = new Set(ids);

		const state = getState();

		const fromIndustriesFilterKey = industriesFilterKeyByPosition(state, from);

		const toIndustriesFilterKey = industriesFilterKeyByPosition(state, to);

		const fromIndustries = candidateIndustriesByPositionSelector(state, from);

		const toIndustries = candidateIndustriesByPositionSelector(state, to);

		dispatch(
			setMultipleFilterForCandidates({
				[fromIndustriesFilterKey]: fromIndustries.filter((id: number) => !idsSet.has(id)),
				[toIndustriesFilterKey]: [
					...toIndustries.filter((id: number) => !idsSet.has(id)), // prevent duplication
					...fromIndustries.filter((id: number) => idsSet.has(id)),
				],
			}),
		);
	};
export const setIndustryForCandidate =
	({position, nextIds}: {position: TIndustryPosition; nextIds: number[]}) =>
	async (dispatch: Dispatch<TAppDispatch>, getState: () => TRootState) => {
		const state = getState();

		const industryFilterKey = industriesFilterKeyByPosition(state, position);

		dispatch(setMultipleFilterForCandidates({[industryFilterKey]: nextIds}));
	};
