import {type FC, useCallback} from "react";

import {useAppDispatch, useAppSelector} from "hooks";
import {Accordion, IndustrySearchFilter} from "mapx-components";
import {setFilterForCompanies} from "store/mapx/filter/filterActions";
import {companyIndustriesSelector} from "store/mapx/filter/filterSelectors";

const IndustryFilter: FC = () => {
	const dispatch = useAppDispatch();

	const selectedIndustries = useAppSelector(companyIndustriesSelector);

	const handleChange = useCallback(
		(industryIdsToBeSet: number[]) => {
			dispatch(setFilterForCompanies({type: "industries", values: industryIdsToBeSet}));
		},
		[dispatch],
	);

	const handleResetClick = useCallback(() => {
		dispatch(setFilterForCompanies({type: "industries", values: []}));
	}, [dispatch]);

	return (
		<Accordion title="Industry" subtitle={selectedIndustries.length}>
			<IndustrySearchFilter
				selectedIndustries={selectedIndustries}
				handleResetClick={handleResetClick}
				handleOnChange={handleChange}
			/>
		</Accordion>
	);
};

export default IndustryFilter;
