import {
	useRef,
	type FC,
	Fragment,
	useState,
	useEffect,
	useCallback,
	type SyntheticEvent,
	type BaseSyntheticEvent,
} from "react";
import {noop} from "lodash";
import classNames from "classnames";
import {differenceInDays} from "date-fns";

import {
	ChangePassword,
	RemoveUserIcon,
	ProjectEditIcon,
	TransferOwnership,
	ThreeDotsHorizontal,
} from "assets/icons";
import {
	EditUserModal,
	DeleteUserModal,
	ResetPasswordModal,
	TransferOwnershipModal,
	OrganizationActionsDropDown,
} from "mapx-components";
import {
	userSelector,
	iseEditedUserSelector,
	resetPassProgressSelector,
	deleteUserInProgressSelector,
	userTransferProgressSelector,
} from "store/mapx/user/userSelectors";
import {
	userUpdate,
	verifyEmailAccount,
	usersTransferOwnership,
} from "store/mapx/user/userAsyncAction";
import {
	organisationsSelector,
	creditsUsedKeySelector,
} from "store/mapx/organisation/organisationSelectors";
import {useAppDispatch, useAppSelector, useOutsideClick} from "hooks";
import {getProjectList} from "store/mapx/project-list/projectListAsyncActions";
import type {TLicenseType} from "mapx-components/Modals/CreateUserModal/types";
import {companyLicenseTypesSelector} from "store/mapx/company/companySelectors";
import type {TSelectWithActionOptions} from "components/Inputs/SelectWithAction/types";

import type {TUserDataProp} from "../types";

import {initialState} from "./utils";
import type {IChildeItemProps, TInitialState} from "./types";
import styles from "./ChildeItem.module.scss";

const ChildeItem: FC<IChildeItemProps> = ({
	item,
	usersCount,
	orgUserData,
	stepList: propsStepList,
	removeUserInCompanyHandler,
	closeDropdownHandler = noop,
}) => {
	const ref = useRef<HTMLDivElement | null>(null);
	const dispatch = useAppDispatch();
	const creditsUserKey = useAppSelector(
		creditsUsedKeySelector,
	) as keyof typeof stepList.credits_used;
	const userInfo = useAppSelector(userSelector);
	const iseEditedUser = useAppSelector(iseEditedUserSelector);
	const organisations = useAppSelector(organisationsSelector);
	const getLicenseTypeSelect = useAppSelector(companyLicenseTypesSelector);
	const resetPassInProgressSelector = useAppSelector(resetPassProgressSelector);
	const removeUserInProgressSelector = useAppSelector(deleteUserInProgressSelector);
	const userTransferInProgressSelector = useAppSelector(userTransferProgressSelector);

	const [stepList, setStepList] = useState(propsStepList);

	const transformLicenseTypeData = getLicenseTypeSelect?.map((item: TLicenseType) => {
		return {
			label: item?.name,
			value: item?.id,
		};
	});

	const freeTrialDefaultValue = stepList?.license?.expires_at
		? differenceInDays(
				new Date(stepList?.license?.expires_at),
				new Date(stepList?.license?.starts_at),
		  )
		: 7;

	const moreThanOneUsers = usersCount !== 1;

	const rows = [
		stepList?.full_name,
		stepList?.email,
		stepList?.license,
		stepList?.joined_at,
		stepList?.license?.expires_at || "-",
		stepList?.credits_used
			? stepList?.credits_used[creditsUserKey] || stepList?.credits_used?.all_time
			: "-",
	];

	const [emailError, setEmailError] = useState<boolean>(true);
	const [emailVal, setEmailVal] = useState<string>(stepList?.email);
	const [modalState, setModalState] = useState<TInitialState>(initialState);
	const [selectedUser, setSelectedUser] = useState<TSelectWithActionOptions>({
		value: null,
		label: null,
	});
	const [licenseType, setLicenseType] = useState<TSelectWithActionOptions>({
		value: stepList?.license?.type?.id,
		label: stepList?.license?.type?.name,
	});
	const [selectedOrg, setSelectedOrg] = useState<TSelectWithActionOptions>({
		value: item?.id,
		label: item?.organisation_name,
	});

	const wrongTransfer = stepList?.id !== selectedUser?.value;

	const transferTo = orgUserData.find((item: TUserDataProp) => item?.id === selectedUser?.value);

	const onToggle = useCallback(
		(key: keyof TInitialState, value: boolean) => {
			if (value) {
				closeDropdownHandler();
			}
			setModalState((prevState) => ({
				...prevState,
				[key]: value,
			}));

			if (!modalState.showEditUser) {
				setEmailVal(stepList.email);
				setEmailError(true);
			}
			if (!modalState.ownershipModal) {
				setSelectedUser({value: null, label: null});
			}
			if (!modalState.showEditUser) {
				setSelectedOrg({
					value: item?.id,
					label: item?.organisation_name,
				});
				setLicenseType({
					value: stepList?.license?.type?.id,
					label: stepList?.license?.type?.name,
				});
			}
		},
		[
			item?.id,
			stepList.email,
			closeDropdownHandler,
			item?.organisation_name,
			modalState.showEditUser,
			modalState.ownershipModal,
			stepList?.license?.type?.id,
			stepList?.license?.type?.name,
		],
	);

	useEffect(() => {
		if (emailVal) {
			const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
			setEmailError(pattern.test(emailVal));
		}
	}, [emailVal]);

	const resetPassHandler = async () => {
		await dispatch(
			verifyEmailAccount({
				email: stepList?.email,
			}),
		);
	};

	const emailOnChange = (e: BaseSyntheticEvent) => {
		setEmailVal(e.target.value);
	};

	const editSubmitHandler = async (e: SyntheticEvent, license_duration_days: number) => {
		e.preventDefault();

		const isFreeTrial = licenseType.label === "Prospect";

		const res = await dispatch(
			userUpdate(
				stepList?.id,
				{
					email: emailVal,
					last_name: stepList?.last_name,
					job_title: stepList?.job_title,
					first_name: stepList?.first_name,
					license_type_id: licenseType?.value || 4,
					license_duration_days: isFreeTrial ? license_duration_days : null,
					organisation_id: selectedOrg?.value || stepList?.organisation?.id,
				},
				item,
			),
		);

		if (res) {
			setStepList({...res, full_name: `${res.first_name} ${res.last_name}`});
		}
	};

	const submitTransferHandler = (e: SyntheticEvent) => {
		e.preventDefault();

		if (wrongTransfer) {
			if (moreThanOneUsers) {
				dispatch(
					usersTransferOwnership({
						user_from: stepList?.id,
						user_to: selectedUser?.value,
					}),
				);
			}

			if (
				stepList?.full_name === userInfo?.firstName ||
				transferTo?.first_name === userInfo?.firstName
			) {
				dispatch(getProjectList());
			}
		}
	};

	const modalData = [
		{
			id: 0,
			label: "Edit User",
			Icon: ProjectEditIcon,
			onClick: () => onToggle("showEditUser", true),
		},
		{
			id: 1,
			Icon: ChangePassword,
			label: "Reset Password",
			onClick: () => onToggle("showResetPass", true),
		},
		{
			id: 2,
			Icon: TransferOwnership,
			isDisabled: !moreThanOneUsers,
			label: "Move All Project Ownership",
			onClick: () => onToggle("ownershipModal", true),
		},
		{
			id: 3,
			isRemove: true,
			Icon: RemoveUserIcon,
			label: "Remove User",
			onClick: () => onToggle("removeUser", true),
		},
	];

	const renderSteps = rows.map((row, idx) => {
		const variantValue = () => {
			switch (row) {
				case stepList?.license:
					return stepList?.license?.type.name || "-";

				case stepList?.end_date:
					return stepList?.end_date || "-";

				default:
					return `${row}`;
			}
		};

		return (
			<div
				key={`${row}-${idx}`}
				title={`${variantValue()}`}
				className={classNames(styles.wrapper__btn, {
					[styles.wrapper__btn_right]: idx === 5,
					[styles.wrapper__btn_center]: idx === 4,
				})}
			>
				<p
					className={classNames(styles.wrapper__btn_text, {
						[styles.wrapper__btn_text_cut]: stepList?.email,
					})}
				>
					{variantValue()}
				</p>
			</div>
		);
	});

	useOutsideClick(ref, () => onToggle("showSettings", false));

	return (
		<Fragment>
			<div className={styles.wrapper}>
				<div className={styles.wrapper__container}>{renderSteps}</div>

				<div
					ref={ref}
					role="button"
					className={styles.wrapper__three}
					onClick={() => onToggle("showSettings", !modalState.showSettings)}
				>
					<ThreeDotsHorizontal className={styles.wrapper__three_dot} />

					{modalState.showSettings && (
						<OrganizationActionsDropDown
							data={modalData}
							className={styles.wrapper__three_modal}
						/>
					)}
				</div>
			</div>

			<EditUserModal
				email={emailVal}
				emailError={emailError}
				loading={iseEditedUser}
				licenseType={licenseType}
				selectedOrg={selectedOrg}
				onSubmit={editSubmitHandler}
				organisations={organisations}
				emailOnChange={emailOnChange}
				setSelectedOrg={setSelectedOrg}
				setLicenseType={setLicenseType}
				isOpen={modalState.showEditUser}
				license={transformLicenseTypeData}
				freeTrialDefaultValue={freeTrialDefaultValue}
				setIsOpen={(bool) => onToggle("showEditUser", bool)}
				selectedLicenseType={{
					value: stepList?.license?.type?.id,
					label: stepList?.license?.type?.name,
				}}
			/>

			<ResetPasswordModal
				email={emailVal}
				isOpen={modalState.showResetPass}
				resetPassHandler={resetPassHandler}
				loading={resetPassInProgressSelector}
				setIsOpen={(bool) => onToggle("showResetPass", bool)}
			/>

			<TransferOwnershipModal
				options={orgUserData}
				disabled={!wrongTransfer}
				selectedUser={selectedUser}
				onSubmit={submitTransferHandler}
				setSelectedUser={setSelectedUser}
				transferName={stepList?.full_name}
				isOpen={modalState.ownershipModal}
				loading={userTransferInProgressSelector}
				setIsOpen={(bool) => onToggle("ownershipModal", bool)}
			/>

			<DeleteUserModal
				submitText="Delete"
				isOpen={modalState.removeUser}
				loading={removeUserInProgressSelector}
				setIsOpen={(bool) => onToggle("removeUser", bool)}
				titleText={`Would you like to delete ”${stepList?.full_name}”?`}
				deleteHandler={() => removeUserInCompanyHandler(stepList?.id as number)}
			/>
		</Fragment>
	);
};

export default ChildeItem;
