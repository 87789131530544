import HttpClient from "api/index";
import {STCandidateDecisionData} from "api/projectApi/profileRequestApi/types";

class MapXProfileRequest extends HttpClient {
	async getProfileRequestsById(profileRequestId: string | number, config = {}) {
		return this.doGet(`/profile-requests/${profileRequestId}`, config);
	}

	async modifyAdditionalProfileStatus(
		projectId: string | number,
		candidateId: string | number,
		data: STCandidateDecisionData,
		config = {},
	) {
		return this.doPatch(
			`/projects/${projectId}/additional-profiles/${candidateId}`,
			data,
			config,
		);
	}

	async rejectCandidatesOnSearchRequests(
		projectId: string | number,
		queryParams: string,
		data: STCandidateDecisionData,
		config = {},
	) {
		return this.doPatch(
			`/projects/${projectId}/additional-profiles${queryParams}`,
			data,
			config,
		);
	}
}

const mapxProfileRequestsApi = new MapXProfileRequest();

export default mapxProfileRequestsApi;
