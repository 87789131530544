export const COLORS = ["#61bf93", "#ededed"];

export const chartData = (percent: number) => {
	const unActivePart = 100 - percent;

	return [
		{name: "name", value: percent},
		{name: "name", value: unActivePart},
	];
};
