import {toast} from "react-toastify";

import type {Dispatch} from "redux";

import {ToastContent} from "components";
import type {TRootState} from "types";

import mapXMarketMapsApi from "api/marketMapApi";

import {
	addToMarketMapModal,
	createMarketMapDone,
	createMarketMapInit,
	deleteMarketMapDone,
	deleteMarketMapFail,
	deleteMarketMapInit,
	downloadMarketMapsPngDone,
	downloadMarketMapsPngFail,
	downloadMarketMapsPngInit,
	getMarketMapsDone,
	getMarketMapsFail,
	getMarketMapsForModalDone,
	getMarketMapsForModalFail,
	getMarketMapsForModalInit,
	getMarketMapsInit,
	updateMarketMapDone,
	updateMarketMapFail,
	updateMarketMapInit,
} from "./marketMapActions";
import {createMarketMapFail} from "./marketMapActions";
import {
	marketMapCreatedBySelector,
	marketMapModalNameSelector,
	marketMapNameSelector,
	marketMapsModalPaginationSelector,
	marketMapsPaginationSelector,
} from "./marketMapSelectors";
import axios from "axios";

export const getMarketMaps =
	(name = "", createdBy: ("self" | "organisation" | "mapx")[] = []) =>
	async (dispatch: Dispatch, getState: () => TRootState) => {
		try {
			const state = getState();
			const existingName = marketMapNameSelector(state);
			const existingCreatedBy = marketMapCreatedBySelector(state);

			const nameChanged = name !== existingName;
			const createdByChanged =
				JSON.stringify(existingCreatedBy.sort()) !== JSON.stringify(createdBy.sort());

			// If "name" or "created_by" changed, we should start from the first page.
			const pagination =
				nameChanged || createdByChanged
					? {page: 0, pages: 1}
					: marketMapsPaginationSelector(state);

			console.log(nameChanged, name);

			if (pagination.pages <= pagination.page) {
				// dispatch(getMarketMapsDone({pagination, results: []}, name, createdBy));

				return;
			}

			dispatch(getMarketMapsInit());

			const payload = {
				page: ((pagination?.page || 0) as number) + 1,
				name,
				created_by: createdBy,
			};

			// Remove falsy values
			Object.keys(payload).forEach((k) => {
				const key = k as keyof typeof payload;
				if (!payload[key]) delete payload[key];
				if (
					Array.isArray(payload[key]) &&
					(payload[key] as ("self" | "organisation" | "mapx")[]).length === 0
				) {
					delete payload[key];
				}
			});

			const {data} = await mapXMarketMapsApi.getMarketMaps(payload);

			dispatch(getMarketMapsDone(data, name, createdBy));
		} catch (e) {
			dispatch(getMarketMapsFail(e));
		}
	};

export const getMarketMapsForModal =
	(name = "") =>
	async (dispatch: Dispatch, getState: () => TRootState) => {
		try {
			const state = getState();
			const existingName = marketMapModalNameSelector(state);

			const nameChanged = name !== existingName;

			const pagination = nameChanged
				? {page: 0, pages: 1}
				: marketMapsModalPaginationSelector(state);

			if (pagination.pages <= pagination.page) {
				// dispatch(getMarketMapsForModalDone({pagination, results: []}, name));

				return;
			}

			dispatch(getMarketMapsForModalInit());

			const payload = {
				page: ((pagination?.page || 0) as number) + 1,
				name,
			};

			// Remove falsy values
			Object.keys(payload).forEach((k) => {
				const key = k as keyof typeof payload;
				if (!payload[key]) delete payload[key];
			});

			const {data} = await mapXMarketMapsApi.getMarketMaps(payload);

			dispatch(getMarketMapsForModalDone(data, name));
		} catch (e) {
			dispatch(getMarketMapsForModalFail(e));
		}
	};

export const updateMarketMap =
	(id: number, name: string, isGlobal: boolean, companyIds: number[], description?: string) =>
	async (dispatch: Dispatch) => {
		try {
			dispatch(updateMarketMapInit());

			const payload = {
				company_ids: companyIds,
				description,
				is_global: isGlobal,
				name,
			};

			const resp = await mapXMarketMapsApi.updateMarketMap(id, payload);

			if (resp.status === 200) {
				dispatch(updateMarketMapDone(resp.data));
				dispatch(addToMarketMapModal(false));
			}
			if (resp.status === 409) {
				dispatch(
					updateMarketMapFail({
						message: "Such name already exists. Try another?",
						status: 409,
					}),
				);
			}
		} catch (e) {
			dispatch(updateMarketMapFail(e));
		}
	};

export const createMarketMap =
	(name: string, isGlobal: boolean, companyIds: number[], description?: string) =>
	async (dispatch: Dispatch) => {
		try {
			dispatch(createMarketMapInit());

			const payload = {
				company_ids: companyIds,
				description,
				is_global: isGlobal,
				name,
			};

			if (!description) {
				delete payload.description;
			}

			const resp = await mapXMarketMapsApi.createMarketMap(payload);

			if (resp.status === 201) {
				dispatch(createMarketMapDone(resp.data));
			}
			if (resp.status === 409) {
				dispatch(
					createMarketMapFail({
						message: "Such name already exists. Try another?",
						status: 409,
					}),
				);
			}
		} catch (e) {
			dispatch(createMarketMapFail(e));
		}
	};

export const deleteMarketMap = (id: number) => async (dispatch: Dispatch) => {
	try {
		dispatch(deleteMarketMapInit(id));

		await mapXMarketMapsApi.deleteMarketMap(id);

		dispatch(deleteMarketMapDone(id));
	} catch (e) {
		dispatch(deleteMarketMapFail(id, e));
	}
};

export const downloadMarketMapsPng = (id: number, name: string) => async (dispatch: Dispatch) => {
	try {
		dispatch(downloadMarketMapsPngInit());

		const savedToken = window.localStorage.getItem("token");
		const expiredTime = localStorage.getItem("expires_in")
			? parseInt(localStorage.getItem("expires_in") as string, 10)
			: null;

		if (expiredTime && expiredTime <= new Date().getTime()) {
			dispatch(downloadMarketMapsPngFail("Error downloading image: Token is expired"));

			toast.error(ToastContent, {
				data: {title: "Token is expired", description: "Refresh the page and try again."},
			});

			return;
		}

		const isDev = process.env.NODE_ENV === "development";
		const url = new URL(window.location.href);
		if (isDev) {
			url.port = "8080";
		}
		url.pathname = "/png";
		url.searchParams.append(
			"url",
			`${isDev ? "http://" : "https://"}${
				window.location.host
			}/market-maps/${id}/${savedToken}`,
		);
		url.searchParams.append("elementId", "market-map-png");

		const res = await axios.get(url.toString(), {responseType: "arraybuffer"});

		if (res.status === 200) {
			const downloadUrl = window.URL.createObjectURL(new Blob([res.data]));
			const link = document.createElement("a");
			link.href = downloadUrl;
			link.setAttribute("download", `${name}.png`);
			document.body.appendChild(link);
			link.click();

			dispatch(downloadMarketMapsPngDone());
		} else {
			dispatch(downloadMarketMapsPngFail(`Error downloading image: ${res.status}`));

			toast.error(ToastContent, {data: {title: "Cannot download the image"}});
		}
	} catch (e) {
		dispatch(downloadMarketMapsPngFail(e));

		toast.error(ToastContent, {data: {title: "Cannot download the image"}});
	}
};
