import type {FC} from "react";

import type {IconProps} from "./types";

const TransferOwnership: FC<IconProps> = ({className = ""}) => (
	<svg
		width="24"
		height="24"
		fill="none"
		viewBox="0 0 24 24"
		className={className}
		xmlns="http://www.w3.org/2000/svg"
	>
		<mask
			x="0"
			y="0"
			width="24"
			height="24"
			id="mask0_2524_29978"
			maskUnits="userSpaceOnUse"
			style={{maskType: "alpha"}}
		>
			<rect width="24" height="24" fill="#D9D9D9" />
		</mask>
		<g mask="url(#mask0_2524_29978)">
			<path
				fill="#404040"
				d="M11.975 17.025L16 13L11.975 8.975L10.575 10.375L12.2 12H8V14H12.2L10.575 15.625L11.975 17.025ZM4 20C3.45 20 2.97917 19.8042 2.5875 19.4125C2.19583 19.0208 2 18.55 2 18V6C2 5.45 2.19583 4.97917 2.5875 4.5875C2.97917 4.19583 3.45 4 4 4H10L12 6H20C20.55 6 21.0208 6.19583 21.4125 6.5875C21.8042 6.97917 22 7.45 22 8V18C22 18.55 21.8042 19.0208 21.4125 19.4125C21.0208 19.8042 20.55 20 20 20H4ZM4 6V18H20V8H11.175L9.175 6H4Z"
			/>
		</g>
	</svg>
);

export default TransferOwnership;
