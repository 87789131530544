import type {FC} from "react";

import type {IconProps} from "./types";

const ArrowDown: FC<IconProps> = ({
	width = 16,
	height = 16,
	className = "",
	stroke = "#0C5850",
	...rest
}) => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 24 24"
		className={className && className}
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...rest}
		id="arrow-down-icon"
	>
		<mask
			id="mask0_137_4434"
			style={{maskType: "alpha"}}
			maskUnits="userSpaceOnUse"
			x="0"
			y="0"
			width="24"
			height="24"
		>
			<rect width="24" height="24" fill="#D9D9D9" />
		</mask>
		<g mask="url(#mask0_137_4434)">
			<path
				d="M12 15.55L5.80005 9.37499L7.40005 7.77499L12 12.375L16.6 7.77499L18.2 9.37499L12 15.55Z"
				fill={stroke}
			/>
		</g>
	</svg>
);

export default ArrowDown;
