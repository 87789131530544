import type {IconProps} from "./types";

const NotchArrowLeft = (props: IconProps) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={20}
		height={20}
		fill="none"
		viewBox="0 0 20 20"
		{...props}
	>
		<mask
			id="a"
			width={20}
			height={20}
			x={0}
			y={0}
			maskUnits="userSpaceOnUse"
			style={{
				maskType: "alpha",
			}}
		>
			<path fill="#D9D9D9" d="M20 0H0v20h20z" />
		</mask>
		<g mask="url(#a)">
			<path fill="#404040" d="m10 15-2.542-4.25H18v-1.5H7.458L10 5l-8 5 8 5Z" />
		</g>
	</svg>
);

export default NotchArrowLeft;
