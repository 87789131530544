import type {FC} from "react";
import classNames from "classnames";
import ContentLoader from "react-content-loader";
import {HelpIcon} from "assets/icons";

import type {TLayoutHeaderTitleData} from "./types";
import styles from "../HomeStyles.module.scss";

const LayoutHeaderTitleItem: FC<TLayoutHeaderTitleData> = ({
	Icon,
	loading,
	isTextIcon,
	text = "",
	count = "",
	className = "",
	slashCount = "",
	dataTooltipId = "",
}) => (
	<div className={classNames(styles.content__header__right__item, {[className]: className})}>
		<div className={classNames({[styles.is_show]: !loading, [styles.is_not_show]: loading})}>
			<p className={styles.content__header__right__item__title}>
				{text}{" "}
				{isTextIcon && (
					<HelpIcon
						dataTooltipId={dataTooltipId}
						className={styles.content__header__right__item__title_icon}
					/>
				)}
			</p>
			<div className={styles.content__header__right__item__info}>
				{/*<Icon  />*/}
				<div className={styles.content__header__right__item__info_icon}>{Icon}</div>
				<span
					data-testid="statistic-value"
					className={styles.content__header__right__item__info_count}
				>
					{count ? count : 0}
					{slashCount ? (
						<span className={styles.content__header__right__item__info_count_slash}>
							{slashCount}
						</span>
					) : null}
				</span>
			</div>
		</div>

		<ContentLoader
			speed={2}
			height={66}
			width={130}
			className={classNames({[styles.is_show]: loading, [styles.is_not_show]: !loading})}
			viewBox="0 0 130 66"
			backgroundColor="#DCE7E7"
			foregroundColor="#F9F9F9"
		>
			<rect x="0" y="0" width="130px" height="66px" />
		</ContentLoader>
	</div>
);

export default LayoutHeaderTitleItem;
