import mapXCandidateApi from "api/candidateApi";
import {candidateCompanyDateRangeBand} from "api/candidateApi/candidateCompanyDateRangeBand";
import candidateCompanyEventSizeBand from "api/candidateApi/candidateCompanyEventSizeBand.json";

import mapXCompanyApi from "api/companyApi";
import companyRevenueBand from "api/companyApi/companyRevenueBand.json";
import companySizeBand from "api/companyApi/companySizeBand.json";
import companyTypeOptions from "api/companyApi/companyType.json";
import candidateCompanyEventsApi from "api/filterOptionsApi/CandidateCompanyEventsApi";
import MapxIndexApi from "api/filterOptionsApi/CompanyIndexApi";
import MapxCountryApi from "api/filterOptionsApi/CountryApi";
import MapxIndustryApi from "api/filterOptionsApi/IndustryApi";
import mapxJobFunctionsApi from "api/filterOptionsApi/JobFunctionApi";
import mapxKeywordApi from "api/filterOptionsApi/KeywordApi";
import mapxSpecialismApi from "api/filterOptionsApi/SpecialismApi";
import mapxSpecialityApi from "api/filterOptionsApi/SpecialityApi";
import axios from "axios";
import {buildQueryParameter, removeDuplicatesFromArray} from "helpers/filterHandlers";
import {successResponse} from "helpers/map";
import {attachAllChildAndParentIdsOnObjectTreeOfArray} from "helpers/TreeModification";
import {uniqBy} from "lodash";
import {
	companySpecialtiesAndSelector,
	companySpecialtiesNotSelector,
	companySpecialtiesOrSelector,
} from "store/mapx/filter/filterSelectors";
import {
	getCandidateCompanyDateRangeSuccess,
	getCandidateCompanyEventsFetch,
	getCandidateCompanyEventSizeRangeSuccess,
	getCandidateCompanyEventsSuccess,
	getCompanyHeadcountRangeSuccess,
	getCompanyRevenueRangeSuccess,
	getCompanyTypeSuccess,
	getCountriesFetch,
	getCountriesSuccess,
	getIndexesFetch,
	getIndexesSuccess,
	getIndustriesFetch,
	getIndustriesSuccess,
	getJobFunctionsFetch,
	getJobFunctionsSuccess,
	getKeywordsFetch,
	getKeywordsSuccess,
	getLanguageFetch,
	getLanguageSuccess,
	getSearchDataFailed,
	getSearchDataFetch,
	getSearchDataSuccess,
	getSenioritySuccess,
	getSpecialismsSuccess,
	getSpecialitiesFetch,
	getSpecialitiesSuccess,
} from "./searchActions";
import mapxLanguageApi from "../../../api/filterOptionsApi/LanguageApi";

const cancelTokens = {};

export const getSearchData =
	({value, filterType, config = {}, page = 1, perPage}) =>
	async (dispatch, getState) => {
		if (value === "") {
			return;
		}

		const {
			searchedData: {results, companiesPagination, candidatesPagination},
		} = getState().search;
		const {cancelCompaniesToken, cancelCandidatesToken} = config;

		dispatch(getSearchDataFetch());

		const companiesConfig = {
			cancelToken: cancelCompaniesToken,
		};
		const candidatesConfig = {
			cancelToken: cancelCandidatesToken,
		};

		let searchData = {
			results: [],
			companiesPagination: null,
			candidatesPagination: null,
		};

		try {
			if (filterType === "companies") {
				const companiesApiPayload = {
					filters: {
						name: value,
					},
					pagination: {
						page,
						per_page: perPage,
					},
				};

				const {data, companiesDataPagination} = await getSearchResult(
					companiesApiPayload,
					companiesConfig,
					"companies",
				);
				const isCompaniesPaginatedData =
					companiesDataPagination?.page !== companiesPagination?.page;
				const companiesPaginatedData = isCompaniesPaginatedData ? results : [];

				searchData.results = [...companiesPaginatedData, ...data];
				searchData.companiesPagination = companiesDataPagination;
			} else if (filterType === "candidates") {
				const payload = {
					filters: {
						full_name: value,
					},
					pagination: {
						page,
						per_page: perPage,
					},
				};

				const {data: candidatesData, candidatesDataPagination} = await getSearchResult(
					payload,
					candidatesConfig,
					"candidates",
				);
				const isCandidatesPaginatedData =
					candidatesDataPagination?.page !== candidatesPagination?.page;
				const candidatesPaginatedData = isCandidatesPaginatedData ? results : [];

				searchData.results = [...candidatesPaginatedData, ...candidatesData];
				searchData.candidatesPagination = candidatesDataPagination;
			} else if (filterType === "all") {
				const candidatesApiPayload = {
					filters: {
						full_name: value,
					},
					pagination: {
						page,
						per_page: perPage,
					},
				};

				const companiesApiPayload = {
					filters: {
						name: value,
					},
					pagination: {
						page,
						per_page: perPage,
					},
				};

				const [companiesSearchReq, candidatesSearchReq] = await Promise.allSettled([
					getSearchResult(companiesApiPayload, companiesConfig, "companies"),
					getSearchResult(candidatesApiPayload, candidatesConfig, "candidates"),
				]);

				if (
					companiesSearchReq?.status === "fulfilled" &&
					candidatesSearchReq?.status === "fulfilled"
				) {
					const companiesSearchData = companiesSearchReq.value;
					const candidatesSearchData = candidatesSearchReq.value;

					const isCompaniesPaginatedData =
						companiesSearchData.companiesDataPagination?.page !==
						companiesPagination?.page;
					const isCandidatesPaginatedData =
						candidatesSearchData.candidatesDataPagination?.page !==
						candidatesPagination?.page;
					const paginatedData =
						(isCompaniesPaginatedData || isCandidatesPaginatedData) && perPage !== 10
							? results
							: [];

					searchData.results = [
						...paginatedData,
						...companiesSearchData.data,
						...candidatesSearchData.data,
					];
					searchData.companiesPagination = companiesSearchData.companiesDataPagination;
					searchData.candidatesPagination = candidatesSearchData.candidatesDataPagination;
				}
			}

			searchData.results = uniqBy(searchData.results, "id");

			dispatch(getSearchDataSuccess(searchData));

			return searchData;
		} catch (error) {
			dispatch(getSearchDataFailed({error}));

			return [];
		}
	};

const getSearchResult = async (query, config, type = "companies") => {
	let companiesData = {
		data: [],
		pagination: null,
	};
	let candidatesData = {
		data: [],
		pagination: null,
	};

	if (type === "companies") {
		try {
			//sometime request get cancelled if user continues to type more
			const companiesResponse = await mapXCompanyApi.fetchCompaniesByFilter(query, config);

			if (companiesResponse && companiesResponse.status === 200) {
				companiesData = {
					data: companiesResponse.data.results.map((item) => ({
						...item,
						searchType: "company",
					})),
					pagination: companiesResponse.data.pagination,
				};
			}
		} catch (e) {
			console.log("search: error from companies api call", e);
		}
	} else if (type === "candidates") {
		try {
			const candidatesResponse = await mapXCandidateApi.getCandidatesByFilter(query, config);

			if (candidatesResponse && candidatesResponse.status === 200) {
				candidatesData = {
					data: candidatesResponse.data.results.map((item) => ({
						...item,
						searchType: "candidates",
					})),
					pagination: candidatesResponse.data.pagination,
				};
			}
		} catch (e) {
			console.log("search: error from companies api call", e);
		}
	}

	try {
		return {
			data: [
				...companiesData.data.map((item) => ({
					...item,
					searchType: "company",
				})),
				...candidatesData.data.map((item) => ({
					...item,
					searchType: "candidates",
				})),
			],
			companiesDataPagination: companiesData.pagination,
			candidatesDataPagination: candidatesData.pagination,
		};
	} catch (e) {
		console.log("search: error on searching result.", e);

		return e;
	}
};

export const getIndustries = () => async (dispatch) => {
	const requestKey = `GET_RECOMMENDED_INDUSTRIES`;

	if (requestKey in cancelTokens) {
		cancelTokens[requestKey].cancel("Operation canceled due to new request.");
	}

	cancelTokens[requestKey] = axios.CancelToken.source();

	const config = {
		cancelToken: cancelTokens[requestKey].token,
	};

	dispatch(getIndustriesFetch());

	let response;

	try {
		response = await MapxIndustryApi.getIndustries(config);
	} catch (error) {
		response = {error: error.data};
	}

	if (response?.status === 200) {
		const results = response.data.results;
		const convertedResult = attachAllChildAndParentIdsOnObjectTreeOfArray(results);

		dispatch(getIndustriesSuccess(convertedResult));

		return convertedResult;
	} else {
		const error = response;

		return {error};
	}
};

export const getCountries = () => async (dispatch) => {
	dispatch(getCountriesFetch());
	let response;

	try {
		response = await MapxCountryApi.getCountries();
	} catch (error) {
		response = {error: error.data};
	}

	if (response?.status === 200) {
		const results = response.data.results;

		let countryByContinent = new Map();

		results.forEach((country) => {
			if (countryByContinent.has(country.region)) {
				const data = countryByContinent.get(country.region);
				countryByContinent.set(country.region, {
					...data,
					countries: [...data.countries, country],
				});
			} else {
				countryByContinent.set(country.region, {
					id: country.region,
					name: country.region,
					countries: [country],
				});
			}
		});

		countryByContinent = Array.from(countryByContinent.values()).sort((a, b) =>
			a.id.localeCompare(b.id),
		);

		dispatch(getCountriesSuccess(countryByContinent));

		return countryByContinent;
	} else {
		const error = response;

		return {error};
	}
};

export const getIndexes = () => async (dispatch) => {
	dispatch(getIndexesFetch());
	let response;

	try {
		response = await MapxIndexApi.getIndexes();
	} catch (error) {
		response = {error: error.data};
	}

	if (response?.status === 200) {
		const results = response.data.results;

		dispatch(getIndexesSuccess(results));

		return results;
	} else {
		const error = response;

		return {error};
	}
};

export const getCompanyHeadcountRanges = () => async (dispatch) => {
	const data = companySizeBand;

	dispatch(getCompanyHeadcountRangeSuccess(data));
};

export const getCompanyRevenueRanges = () => async (dispatch) => {
	const data = companyRevenueBand;

	dispatch(getCompanyRevenueRangeSuccess(data));
};

export const getCandidateCompanyEventRanges = () => async (dispatch) => {
	const data = candidateCompanyEventSizeBand;

	dispatch(getCandidateCompanyEventSizeRangeSuccess(data));
};

export const getCandidateCompanyDateRanges = () => async (dispatch) => {
	dispatch(getCandidateCompanyDateRangeSuccess(candidateCompanyDateRangeBand));
};

export const getSeniorityList = () => async (dispatch) => {
	const data = [
		{id: "Board", name: "Board", premium: true},
		{id: "ExCo", name: "ExCo", premium: true},
		{id: "C-Level", name: "C-Level", premium: true},
		{id: "C-Level-1", name: "C-Level-1", premium: false},
		{id: "C-Level-2", name: "C-Level-2", premium: false},
		{id: "C-Level-3", name: "C-Level-3", premium: false},
		{id: "Other", name: "Other", premium: false},
	];
	dispatch(getSenioritySuccess(data));
};

export const getCompanyTypeList = () => async (dispatch) => {
	dispatch(getCompanyTypeSuccess(companyTypeOptions));
};

export const getLanguageList = () => async (dispatch) => {
	dispatch(getLanguageSuccess(companyTypeOptions));
};

export const getJobFunctions = () => async (dispatch) => {
	const requestKey = `GET_JOB_FUNCTIONS`;

	if (requestKey in cancelTokens) {
		cancelTokens[requestKey].cancel("Operation canceled due to new request.");
	}

	cancelTokens[requestKey] = axios.CancelToken.source();

	const config = {
		cancelToken: cancelTokens[requestKey].token,
	};

	dispatch(getJobFunctionsFetch());
	let response;

	try {
		response = await mapxJobFunctionsApi.get(config);
	} catch (error) {
		response = {error: error.data};
	}

	if (response?.status === 200) {
		const results = response.data.results;

		dispatch(getJobFunctionsSuccess(results));

		return results;
	} else {
		const error = response;

		return {error};
	}
};

export const getSpecialities =
	(searchTerm = "", pageNumber = 1) =>
	async (dispatch, getState) => {
		const requestKey = `GET_RECOMMENDED_SPECIALTIES}`;

		if (requestKey in cancelTokens) {
			cancelTokens[requestKey].cancel("Operation canceled due to new request.");
		}

		cancelTokens[requestKey] = axios.CancelToken.source();

		const config = {
			cancelToken: cancelTokens[requestKey].token,
		};

		const state = getState();

		const {company_filters} = state.filter;
		const filters = {...company_filters};

		const and = companySpecialtiesAndSelector(state);
		const or = companySpecialtiesOrSelector(state);
		const not = companySpecialtiesNotSelector(state);

		if (and?.length > 0 || or?.length > 0 || not?.length > 0) {
			filters.selected_specialties = removeDuplicatesFromArray([...and, ...or, ...not]);
			delete filters.specialties;
			delete filters.specialties_not;
			delete filters.specialties_and;
			delete filters.specialties_or;
		}

		let queryParam = buildQueryParameter(filters);

		dispatch(getSpecialitiesFetch());
		let response;

		try {
			if (searchTerm !== "" || queryParam) {
				if (searchTerm !== "" && queryParam == null) {
					queryParam = `?name=${searchTerm}`;
				} else if (searchTerm !== "" && queryParam != null) {
					queryParam = `${queryParam}&name=${searchTerm}`; // cause default query param attaches "?"
				}

				response = await mapxSpecialityApi.searchSpecialities(
					queryParam,
					pageNumber,
					config,
				);
			} else if (searchTerm === "" && queryParam == null) {
				response = await mapxSpecialityApi.getSpecialities(pageNumber, config);
			}
		} catch (error) {
			response = {error: error.data};
		}

		if (successResponse(response, 200)) {
			const results = response.data.results;

			let shouldAddMore = false;

			if (pageNumber > 1) {
				shouldAddMore = true;
			}

			dispatch(
				getSpecialitiesSuccess({
					results,
					shouldAddMore,
					selectedSpecialities: filters.selected_specialties || [],
				}),
			);

			if (response.data.pagination.pages === pageNumber) {
				return {
					stopPaginate: true,
				};
			} else {
				return {
					stopPaginate: false,
				};
			}
		} else {
			const error = response;

			return {error, stopPaginate: false};
		}
	};

export const getSpecialisms = (searchTerm, pageNumber) => async (dispatch) => {
	dispatch(getSpecialitiesFetch());
	let response;

	try {
		if (searchTerm !== "") {
			response = await mapxSpecialismApi.searchSpecialisms(searchTerm, pageNumber);
		} else if (searchTerm === "") {
			response = await mapxSpecialismApi.getSpecialisms(pageNumber, {});
		}
	} catch (error) {
		response = {error: error.data};
	}

	if (response?.status === 200) {
		const results = response.data.results;
		let shouldAddMore = false;

		if (pageNumber > 1) {
			shouldAddMore = true;
		}

		dispatch(getSpecialismsSuccess({results, shouldAddMore}));

		if (response?.data.pagination.pages === pageNumber) {
			return {
				stopPaginate: true,
			};
		} else {
			return {
				stopPaginate: false,
			};
		}
	} else {
		const error = response;

		return {error, stopPaginate: false};
	}
};

export const setKeywordInfoOnState =
	(response, pageNumber, type = "profiles") =>
	(dispatch) => {
		if (response?.status === 200) {
			const results = response.data.results;
			let shouldAddMore = false;

			if (pageNumber > 1) {
				shouldAddMore = true;
			}

			dispatch(
				getKeywordsSuccess({
					results,
					pagination: response.data.pagination,
					shouldAddMore,
					selectedKeywords: [],
					type,
				}),
			);

			if (response.data.pagination.pages === pageNumber) {
				return {
					stopPaginate: true,
				};
			} else {
				return {
					stopPaginate: false,
				};
			}
		} else {
			const error = response;

			return {error, stopPaginate: false};
		}
	};

export const getKeywordsList =
	(searchTerm = "", pageNumber = 1) =>
	async (dispatch) => {
		// const state = getState();
		// const filters = {...candidateFiltersSelector(state)};
		//
		// if (filters.keywords) {
		// 	filters.selected_keywords = [...filters.keywords];
		// 	delete filters.keywords;
		// }
		//
		// let queryParam = buildQueryParameter(filters);

		const requestKey = "GET_KEYWORDS_LIST";

		if (cancelTokens[requestKey]) {
			cancelTokens[requestKey].cancel("Operation canceled due to new request.");
		}

		cancelTokens[requestKey] = axios.CancelToken.source();

		const config = {
			cancelToken: cancelTokens[requestKey].token,
		};

		dispatch(getKeywordsFetch());
		let response;

		try {
			let queryParam;

			if (searchTerm !== "") {
				queryParam = `?search=${searchTerm}`;

				response = await mapxKeywordApi.searchKeywords(queryParam, pageNumber, config);
			} else {
				response = await mapxKeywordApi.getKeywords(pageNumber, config);
			}
		} catch (error) {
			response = {error: error.data};
		}

		dispatch(setKeywordInfoOnState(response, pageNumber, "profiles"));
	};

// Candidate company events

export const setCandidateCompanyEventsInfoOnState = (response, pageNumber) => (dispatch) => {
	if (response?.status === 200) {
		const results = response.data.results;
		let shouldAddMore = false;

		if (pageNumber > 1) {
			shouldAddMore = true;
		}

		dispatch(
			getCandidateCompanyEventsSuccess({
				results,
				pagination: response.data.pagination,
				shouldAddMore,
				selectedCandidateCompanyEvents: [],
			}),
		);

		if (response.data.pagination.pages === pageNumber) {
			return {
				stopPaginate: true,
			};
		} else {
			return {
				stopPaginate: false,
			};
		}
	} else {
		const error = response;

		return {error, stopPaginate: false};
	}
};

export const getCandidateCompanyEventsList =
	(searchTerm = "", pageNumber = 1) =>
	async (dispatch) => {
		const requestKey = "GET_CANDIDATE_COMPANY_EVENTS_LIST";

		if (cancelTokens[requestKey]) {
			cancelTokens[requestKey].cancel("Operation canceled due to new request.");
		}

		cancelTokens[requestKey] = axios.CancelToken.source();

		const config = {
			cancelToken: cancelTokens[requestKey].token,
		};

		dispatch(getCandidateCompanyEventsFetch());
		let response;

		try {
			let queryParam;

			if (searchTerm !== "") {
				queryParam = `?search=${searchTerm}`;

				response = await candidateCompanyEventsApi.searchCandidateCompanyEvents(
					queryParam,
					pageNumber,
					config,
				);
			} else {
				response = await candidateCompanyEventsApi.getCandidateCompanyEvents(
					pageNumber,
					config,
				);
			}
		} catch (error) {
			response = {error: error.data};
		}

		dispatch(setCandidateCompanyEventsInfoOnState(response, pageNumber));
	};

export const getLanguage = () => async (dispatch) => {
	const requestKey = `GET_LANGUAGES`;

	if (requestKey in cancelTokens) {
		cancelTokens[requestKey].cancel("Operation canceled due to new request.");
	}

	cancelTokens[requestKey] = axios.CancelToken.source();

	const config = {
		cancelToken: cancelTokens[requestKey].token,
	};

	dispatch(getLanguageFetch());
	let response;

	try {
		response = await mapxLanguageApi.get(config);
	} catch (error) {
		response = {error: error.data};
	}

	if (response?.status === 200) {
		const results = response.data.results.map((name, index) => ({
			id: index,
			name: name,
		}));

		dispatch(getLanguageSuccess(results));

		return results;
	} else {
		const error = response;

		return {error};
	}
};
