import type {FC} from "react";
import classNames from "classnames";

import {FeatureBlockArrow} from "assets/icons";

import type {IOrganizationActionsDropDownProps} from "./types";
import styles from "./OrganizationActionsDropDown.module.scss";

const OrganizationActionsDropDown: FC<IOrganizationActionsDropDownProps> = ({
	data,
	onChange,
	className = "",
}) => {
	const renderData = data.map(({label, Icon, isDisabled, key, onClick, id, isRemove}) => {
		const clickHandler = () => {
			if (!isDisabled) {
				onClick?.();
				onChange?.(key as string);
			}
		};

		return (
			<div
				role="button"
				onClick={clickHandler}
				className={classNames(styles.wrapper__item, {
					[styles.wrapper__item_disabled]: isDisabled,
				})}
				key={id}
			>
				{Icon && <Icon className={styles.wrapper__item_icon} />}
				<p
					className={classNames(styles.wrapper__item_label, {
						[styles.wrapper__item_label__remove]: isRemove,
					})}
				>
					{label}
				</p>
			</div>
		);
	});

	return (
		<div className={classNames(styles.wrapper, {[className]: className})}>
			<FeatureBlockArrow className={styles.wrapper__arrow} />
			{renderData}
		</div>
	);
};

export default OrganizationActionsDropDown;
