import {type FC, useState} from "react";
import classNames from "classnames";

import type {TMoreButtonProps} from "./types";
import styles from "./moreButton.module.scss";

const MoreButton: FC<TMoreButtonProps> = ({handleClick, count}) => {
	const [hide, setHide] = useState(false);

	const buttonClass = classNames(styles.moreButton, {
		[styles.hide]: hide,
	});

	const onClick = () => {
		setHide(true);
		setTimeout(() => {
			handleClick();
		}, 300);
	};

	return (
		<button className={buttonClass} onClick={onClick}>
			{`+ ${count}`}
		</button>
	);
};

export default MoreButton;
