import {useEffect} from "react";
import {useHistory, useLocation} from "react-router-dom";

import type {FC} from "react";
import type {TResetPassword} from "./types";

import {useOnAuth} from "hooks";
import {resetPassForm} from "mapx-constants";
import {AuthView} from "components";

const ResetPassword: FC<TResetPassword> = () => {
	const history = useHistory();
	const search = useLocation().search;
	const verificationCode = new URLSearchParams(search).get("code");

	const {loading, error, onSubmit} = useOnAuth({
		history: history,
		type: "reset-password",
		redirectUrl: "/login",
	});

	useEffect(() => {
		if (verificationCode === null) {
			history.push("/login");
		}
	}, [history, verificationCode]);

	return (
		<AuthView
			error={error}
			showErrorTooltip
			form={resetPassForm}
			loading={loading}
			submitText="Reset Password"
			onSubmit={onSubmit}
			title="Create New Password"
			to="/report/login"
			description="Please choose your new password."
		/>
	);
};

export default ResetPassword;
