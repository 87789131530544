import type {FC} from "react";

import type {IconProps} from "./types";

const ProjectEditIcon: FC<IconProps> = ({
	className = "",
	color = "#C1B1F",
	height = 24,
	width = 24,
}) => (
	<svg
		width={width}
		height={height}
		fill="none"
		viewBox="0 0 24 24"
		className={className}
		xmlns="http://www.w3.org/2000/svg"
	>
		<mask
			x="0"
			y="0"
			width={width}
			height={height}
			id="mask0_605_9332"
			maskUnits="userSpaceOnUse"
			style={{maskType: "alpha"}}
		>
			<rect width={width} height={height} fill="#D9D9D9" />
		</mask>
		<g mask="url(#mask0_605_9332)">
			<path
				fill={color}
				d="M5 19H6.4L15.025 10.375L13.625 8.975L5 17.6V19ZM19.3 8.925L15.05 4.725L16.45 3.325C16.8333 2.94167 17.3043 2.75 17.863 2.75C18.421 2.75 18.8917 2.94167 19.275 3.325L20.675 4.725C21.0583 5.10833 21.2583 5.571 21.275 6.113C21.2917 6.65433 21.1083 7.11667 20.725 7.5L19.3 8.925ZM17.85 10.4L7.25 21H3V16.75L13.6 6.15L17.85 10.4Z"
			/>
		</g>
	</svg>
);

export default ProjectEditIcon;
