import HttpClient from "api/index";

class MapXLearnToRankApi extends HttpClient {
	createLearnToRank() {
		return this.doGet("/learn-to-rank-tasks/next");
	}

	recordLearnToRank(learnToRankTaskID: number, chosenCandidateID: number | null) {
		return this.doPost("/learn-to-rank-answers", {
			chosen_candidate_id: chosenCandidateID,
			learn_to_rank_task_id: learnToRankTaskID,
		});
	}

	getLearnToRanks() {
		return this.doGet("/learn-to-rank-answers", {is_completed: true, page: 1, per_page: 1});
	}
}

const mapXLearnToRankApi = new MapXLearnToRankApi();

export default mapXLearnToRankApi;
