import {
	FC,
	useRef,
	Fragment,
	useState,
	useEffect,
	ChangeEvent,
	SyntheticEvent,
	KeyboardEventHandler,
} from "react";
import {isEmpty} from "lodash";

import {Button, TextInput} from "components";
// import UploadIcon from "assets/Icons/Upload";
// import DownloadIcon from "assets/Icons/Download";
import {useLockBodyScroll, useOutsideClick} from "hooks";
import SelectWithAction from "components/Inputs/SelectWithAction";

import type {ICreateUserModalProps} from "./types";
import styles from "./CreateUserModal.module.scss";

const CreateUserModal: FC<ICreateUserModalProps> = ({
	isOpen,
	loading,
	license,
	onChange,
	onSubmit,
	setIsOpen,
	licenseType,
	setLicenseType,
	organizationName,
	organizationEmail,
}) => {
	const ref = useRef<HTMLDivElement | null>(null);
	const [freeTrialVal, setFreeTrialVal] = useState<number>(7);

	const isFreeTrial = licenseType ? licenseType.label === "Prospect" : false;

	const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
	const isEmailValid = organizationEmail && pattern.test(organizationEmail);

	const closedModalHandler = () => {
		setIsOpen(false);
	};

	const createUserHandler = (e: SyntheticEvent) => {
		e.preventDefault();
		onSubmit?.(e, freeTrialVal);
	};

	const removeHandler = () => {
		if (setLicenseType) {
			setLicenseType({
				label: null,
				value: null,
			});
		}
	};

	const handleFreeTrialChange = (e: ChangeEvent<HTMLInputElement>) => {
		if (freeTrialVal > 0) {
			if (Number(e.target.value)) {
				setFreeTrialVal(Number(e.target.value));
			}
		}
	};

	const handleFreeTrialKeyDown: KeyboardEventHandler = (evt) =>
		["e", "E", "+", "-", "."].includes(evt.key) && evt.preventDefault();

	useEffect(() => {
		if (!loading) {
			setIsOpen(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loading]);

	useLockBodyScroll(isOpen);
	useOutsideClick(ref, closedModalHandler);

	return (
		<Fragment>
			{!!isOpen && (
				<div className={styles.container}>
					<div className={styles.wrapper} ref={ref}>
						<p className={styles.wrapper__title}>Create a User</p>
						<form
							onSubmit={createUserHandler}
							className={styles.wrapper__content}
							data-testid="create-user-modal-form"
						>
							<div className={styles.wrapper__content_item}>
								<p className={styles.wrapper__content_item_title}>Organization</p>

								<p className={styles.wrapper__content_item_name}>
									{organizationName}
								</p>
							</div>

							<div className={styles.wrapper__content_item}>
								<p className={styles.wrapper__content_item_title}>E-Mail</p>

								<TextInput
									type="email"
									autoComplete="on"
									onChange={onChange}
									placeholder="Email"
									value={organizationEmail}
									data-testid="create-user-modal-email"
								/>
							</div>
							{licenseType && setLicenseType && license && (
								<div className={styles.wrapper__content_item}>
									<p className={styles.wrapper__content_item_title}>User Type</p>

									<SelectWithAction
										selected={licenseType}
										placeholder="License Type"
										actionClick={removeHandler}
										setSelected={setLicenseType}
										isSelected={!licenseType?.value}
										className={styles.inviteUser__license}
										action={
											licenseType?.value ? (
												<p role="button" className={styles.remove_all}>
													Remove
												</p>
											) : (
												<div />
											)
										}
										options={license}
									/>
								</div>
							)}

							{isFreeTrial && (
								<div className={styles.wrapper__content_item}>
									<p className={styles.wrapper__content_item_title}>
										Free Trial Duration
									</p>

									<p className={styles.wrapper__content_item__free}>
										<input
											min={1}
											type="number"
											defaultValue={freeTrialVal}
											onChange={handleFreeTrialChange}
											onKeyDown={handleFreeTrialKeyDown}
											className={styles.wrapper__content_item__free_trial}
										/>
										<span
											className={styles.wrapper__content_item__free_remainder}
										>
											days
										</span>
									</p>
								</div>
							)}

							{/* @TODO: Uncomment after getting BE functionality */}
							{/* <div className={styles.wrapper__content_dividing}>
									<p className={styles.wrapper__content_dividing_text}>or</p>
								</div>
								<div className={styles.wrapper__content_item}>
									<p className={styles.wrapper__content_item_title}>Upload CSV file</p>
									<div className={styles.wrapper__content_item_upload}>
										<TextInput
											placeholder="Document path..."
											customClass={styles.wrapper__content_item_upload_input}
										/>
										<label
											role="button"
											className={styles.wrapper__content_item_upload_button}
										>
											<div className={styles.wrapper__content_item_upload_button_content}>
												<UploadIcon />
												<span>Upload</span>
											</div>
											<input type="file" />
										</label>
									</div>
								</div>
								<div className={styles.wrapper__content_item} role="button">
									<div className={styles.wrapper__content_item_download}>
										<DownloadIcon />
										<span>Download the CSV Template</span>
									</div>
								</div> */}

							<Button
								type="submit"
								loading={loading}
								className={styles.wrapper__content_button}
								disabled={!isEmailValid || isEmpty(organizationEmail)}
							>
								Create User
							</Button>
						</form>
					</div>
				</div>
			)}
		</Fragment>
	);
};

export default CreateUserModal;
