import {FC, useRef, SyntheticEvent, useEffect, Fragment} from "react";

import {Button, Switch, TextInput} from "components";
import {useLockBodyScroll, useOutsideClick} from "hooks";

import type {TCreateOrganizationModalProps} from "./types";
import styles from "./CreateOrganizationModal.module.scss";

const CreateOrganizationModal: FC<TCreateOrganizationModalProps> = ({
	title,
	label,
	toggle,
	isOpen,
	onSubmit,
	loading,
	onChange,
	setIsOpen,
	setToggle,
	inputName,
	isEditing,
	organizationName,
	btnTitle = "Create Organization",
}) => {
	const ref = useRef<HTMLDivElement | null>(null);

	useEffect(() => {
		if (!loading && organizationName && isOpen) {
			setIsOpen(false);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loading]);

	useLockBodyScroll(isOpen);
	useOutsideClick(ref, () => setIsOpen(false));

	const onSubmitHandler = (e: SyntheticEvent) => {
		e.preventDefault();

		if (organizationName) {
			onSubmit?.(e);
		}
	};

	return (
		<Fragment>
			{isOpen && (
				<div className={styles.container}>
					<div ref={ref} className={styles.wrapper}>
						<p className={styles.wrapper__title}>{title}</p>

						<form
							onSubmit={onSubmitHandler}
							data-testid="create-org-form"
							className={styles.wrapper__form}
						>
							{label && (
								<p
									data-testid="create-org-form-label"
									className={styles.wrapper__form_label}
								>
									{label}
								</p>
							)}

							<TextInput
								name={inputName}
								onChange={onChange}
								value={organizationName}
								placeholder="Organization Name"
								data-testid="create-org-form-input"
							/>

							{isEditing && (
								<div className={styles.wrapper__form_status}>
									<p className={styles.wrapper__form_status_title}>Set Status</p>
									<div className={styles.wrapper__form_status__toggle}>
										<Switch checked={!!toggle} onChange={setToggle} />

										<p className={styles.wrapper__form_status__toggle_text}>
											{toggle ? "Active" : "De active"}
										</p>
									</div>
								</div>
							)}

							<Button
								type="submit"
								loading={loading}
								disabled={!organizationName?.trim()}
								// disabled={isOrgName}
								data-testid="create-org-form-button"
								className={styles.wrapper__form_button}
							>
								{btnTitle}
							</Button>
						</form>
					</div>
				</div>
			)}
		</Fragment>
	);
};

export default CreateOrganizationModal;
