import type {FC} from "react";

import type {IconProps} from "./types";

const Monetization: FC<IconProps> = (props) => (
	<svg
		fill="none"
		height={24}
		viewBox="0 0 24 24"
		width={24}
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<mask
			id="prefix__a"
			style={{
				maskType: "alpha",
			}}
			maskUnits="userSpaceOnUse"
			x={0}
			y={0}
			width={24}
			height={24}
		>
			<path fill="#D9D9D9" d="M0 0h24v24H0z" />
		</mask>
		<g mask="url(#prefix__a)">
			<path
				d="M11.1 19h1.75v-1.25c.833-.15 1.55-.475 2.15-.975.6-.5.9-1.242.9-2.225 0-.7-.2-1.342-.6-1.925-.4-.583-1.2-1.092-2.4-1.525-1-.333-1.692-.625-2.075-.875-.383-.25-.575-.592-.575-1.025 0-.433.154-.775.463-1.025.308-.25.754-.375 1.337-.375.533 0 .95.13 1.25.388.3.258.517.579.65.962l1.6-.65a3.35 3.35 0 00-1.012-1.525C14.046 6.542 13.5 6.3 12.9 6.25V5h-1.75v1.25c-.833.183-1.483.55-1.95 1.1-.467.55-.7 1.167-.7 1.85 0 .783.23 1.417.688 1.9.458.483 1.179.9 2.162 1.25 1.05.383 1.78.725 2.188 1.025.408.3.612.692.612 1.175 0 .55-.196.954-.588 1.212-.391.259-.862.388-1.412.388-.55 0-1.037-.171-1.462-.513-.425-.341-.738-.854-.938-1.537l-1.65.65c.233.8.596 1.446 1.088 1.938.491.491 1.129.829 1.912 1.012V19zm.9 3a9.733 9.733 0 01-3.9-.788 10.092 10.092 0 01-3.175-2.137c-.9-.9-1.612-1.958-2.137-3.175A9.733 9.733 0 012 12c0-1.383.263-2.683.788-3.9a10.092 10.092 0 012.137-3.175c.9-.9 1.958-1.613 3.175-2.138A9.743 9.743 0 0112 2c1.383 0 2.683.262 3.9.787a10.105 10.105 0 013.175 2.138c.9.9 1.612 1.958 2.137 3.175A9.733 9.733 0 0122 12a9.733 9.733 0 01-.788 3.9 10.092 10.092 0 01-2.137 3.175c-.9.9-1.958 1.612-3.175 2.137A9.733 9.733 0 0112 22zm0-2c2.233 0 4.125-.775 5.675-2.325C19.225 16.125 20 14.233 20 12c0-2.233-.775-4.125-2.325-5.675C16.125 4.775 14.233 4 12 4c-2.233 0-4.125.775-5.675 2.325C4.775 7.875 4 9.767 4 12c0 2.233.775 4.125 2.325 5.675C7.875 19.225 9.767 20 12 20z"
				fill="#404040"
			/>
		</g>
	</svg>
);

export default Monetization;
