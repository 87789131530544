import {useCallback, useEffect} from "react";
import {useSelector} from "react-redux";
import {ToastContent} from "components";
import {toast} from "react-toastify";
import {getImportLinkedinCandidateStatus} from "store/mapx/candidate/linkedInProfileImportAsyncActions";
import {
	TCandidateImportRequestResponse,
	TLinkedInCandidateImport,
	TLinkedinUrlImportStatus,
} from "api/candidateApi/types";
import {useAppDispatch, useAppSelector} from "hooks/index";
import {
	currentRefreshLinkedInProfilesScrapeInfoSelector,
	refreshLinkedInCandidatesInProgressSelector,
} from "store/mapx/refresh-candidate/refreshCandidateSelectors";
import {clearRefreshLinkedInProfileScrapeInfo} from "store/mapx/refresh-candidate/refreshCandidateActions";
import {isEmptyObject} from "helpers/filterHandlers";
import {removeTrailingSlash} from "helpers/string";

/* eslint-disable  @typescript-eslint/no-explicit-any */
const intervalId = {} as any;

const useRefreshLinkedInProfileProgressWatcher = () => {
	const currentRefreshLinkedInProfileScrapeInfo: {[x: string]: TCandidateImportRequestResponse} =
		useSelector(currentRefreshLinkedInProfilesScrapeInfoSelector);

	const currentlyImportingList = useAppSelector(refreshLinkedInCandidatesInProgressSelector);

	const dispatch = useAppDispatch();

	const refreshWatcher = useCallback(
		async (response: TLinkedInCandidateImport, linkedInUrl: string) => {
			const candidate = response?.candidate;

			if (response?.status === "Imported" && candidate) {
				dispatch(clearRefreshLinkedInProfileScrapeInfo(removeTrailingSlash(linkedInUrl)));

				toast.success(ToastContent, {
					autoClose: false,
					closeOnClick: false, // because close option available on the notification
					data: {
						title: `Refreshed ${candidate.full_name}'s profile`,
						description: (closeToast: () => void, linkColor: any) => (
							<>
								{/* eslint-disable-next-line react/no-unescaped-entities */}
								We'll also manually check their profile, so if you don't see any
								changes straight away,{" "}
								<span
									className="toast-link"
									style={{color: linkColor}}
									onClick={() => {
										window.open(`/candidate/${candidate.id}`, "_blank");
										closeToast();
									}}
								>
									check back
								</span>
								{" again in 12 hours."}
							</>
						),
					},
				});
			} else if (response?.error) {
				toast.info(ToastContent, {
					autoClose: false,
					closeOnClick: true,
					data: {
						title:
							"CandidateDetails Import: " + response.message ||
							"Something went wrong!",
					},
				});

				dispatch(clearRefreshLinkedInProfileScrapeInfo(removeTrailingSlash(linkedInUrl)));
			}
		},
		[dispatch],
	);

	const isRefreshInProgress = (status: TLinkedinUrlImportStatus) => {
		return status === "Created" || status === "In Progress" || status === "Scraped";
	};

	/**
	 * Here on the state we have kept the information while user clicked on refresh icon on candidate profile
	 * We check the active refresh state for the search
	 * Then we watch the refresh status
	 * if refresh is success, then we redirect user to candidate page
	 * if import is failed, then we show notification with reason
	 */

	useEffect(() => {
		if (
			currentRefreshLinkedInProfileScrapeInfo &&
			!isEmptyObject(currentRefreshLinkedInProfileScrapeInfo)
		) {
			for (const [linkedInUrl, data] of Object.entries(
				currentRefreshLinkedInProfileScrapeInfo,
			)) {
				const scrapeInfo: TCandidateImportRequestResponse = data;
				const isImporting: {[x: string]: boolean} = currentlyImportingList[linkedInUrl];
				const inProgress = isRefreshInProgress(scrapeInfo?.status);

				if (isImporting && inProgress) {
					intervalId[linkedInUrl] = setInterval(async () => {
						const response = await dispatch(
							getImportLinkedinCandidateStatus(scrapeInfo.id),
						);

						await refreshWatcher(response, linkedInUrl);

						if (response?.status === "Imported" && response?.candidate !== null) {
							clearInterval(intervalId[linkedInUrl]);
						}
					}, 10 * 1000);

					setTimeout(() => {
						clearInterval(intervalId[linkedInUrl]);
					}, 60 * 30 * 1000); // it will clear after 30 min
				}
			}
		}

		return () => {
			for (const [key] of Object.entries(intervalId)) {
				clearInterval(intervalId[key]);
			}
		};
	}, [currentRefreshLinkedInProfileScrapeInfo, currentlyImportingList, dispatch, refreshWatcher]);
};

export default useRefreshLinkedInProfileProgressWatcher;
