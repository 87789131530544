import {type FC, Fragment} from "react";

import {Button} from "components";
import {ChevronIcon, IconFilter} from "assets/icons";

import {filterData} from "./utils";
import {EOnboardingTooltipSteps, type TTooltipNavigationProps} from "./types";
import styles from "./OnboardingTooltip.module.scss";

const OnboardingFilterComponent: FC<TTooltipNavigationProps> = ({step}) => (
	<Fragment>
		{step === EOnboardingTooltipSteps.Fourth && (
			<div className={styles.wrapper__content__horizontal__left}>
				<div className={styles.wrapper__content__horizontal__left__head}>
					<IconFilter />
					<p className={styles.wrapper__content__horizontal__left__head_text}>Filters</p>
				</div>

				<div className={styles.wrapper__content__horizontal__left__filters}>
					{filterData.map(({name, id}) => (
						<Button
							key={id}
							defaultBtnStyle
							RightIcon={ChevronIcon}
							className={styles.wrapper__content__horizontal__left__filters_item}
						>
							{name}
						</Button>
					))}
				</div>
			</div>
		)}
	</Fragment>
);

export default OnboardingFilterComponent;
