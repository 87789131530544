import css from "mapx-components/Layouts/Header/header.module.scss";

export default function CompanyMappingProcessCompleteMessage({closeToast, data}) {
	const {id} = data;

	const handleClick = () => {
		closeToast();

		window.location.href = `/company/${id}?mapping=completed`;
	};

	return (
		<div className={css.toastBlock}>
			<div className={css.toastTitle}>Company Mapping</div>

			<div className={css.toastDescription} onClick={handleClick}>
				Your company mapping request has been completed.{" "}
				<span className={css.toastLink}>Click here</span> to see the updated organisation
				chart.
			</div>
		</div>
	);
}
