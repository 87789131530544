import type {FC} from "react";

import type {IconProps} from "./types";

const NavigationArrowIcon: FC<IconProps> = ({className = ""}) => (
	<svg
		width="20"
		height="20"
		fill="none"
		viewBox="0 0 20 20"
		className={className}
		xmlns="http://www.w3.org/2000/svg"
	>
		<mask
			x="0"
			y="0"
			width="20"
			height="20"
			id="mask0_19_7868"
			maskUnits="userSpaceOnUse"
			style={{maskType: "alpha"}}
		>
			<rect width="20" height="20" fill="#D9D9D9" />
		</mask>
		<g mask="url(#mask0_19_7868)">
			<path
				fill="#EEEDEC"
				d="M12.5 15.5L11.4375 14.4375L15.125 10.75H2V9.25H15.125L11.4375 5.5625L12.5 4.5L18 10L12.5 15.5Z"
			/>
		</g>
	</svg>
);

export default NavigationArrowIcon;
