import {useCallback, useEffect, useState} from "react";
import {useSelector} from "react-redux";

import {CreatePerson} from "assets/icons";
import {Button} from "components";
import {useAppDispatch} from "hooks";
import {
	getInvitedUsersForAdmin,
	getOrganisationUsersForAdmin,
} from "store/mapx/organisation/organisationAsyncFunctions";
import {getUpdatedOrderingData} from "mapx-pages/Organizations/OrganizationList/utils";
import {
	getInvitedUsersInProgressSelector,
	invitedUsersSelector,
	orgUsersListProgressSelector,
	orgUsersListSelector,
} from "store/mapx/organisation/organisationSelectors";
import OrganizationTableSkeleton from "mapx-pages/Organizations/OrganizationList/OrganizationItem/OrganizationTableSkeleton";
import type {TUserTable} from "mapx-pages/Organisation/types";

import styles from "./UserTable.module.scss";
import User from "./User";
import SubHeader from "./SubHeader";
import InvitedUser from "./InvitedUser";

const UserTable = ({displayCreateUserModal}: TUserTable) => {
	const dispatch = useAppDispatch();

	const [orgUserDataOrdering, setOrgUserDataOrdering] = useState<string[]>([]);

	const users = useSelector(orgUsersListSelector);
	const invitedUsers = useSelector(invitedUsersSelector);

	const loadingUsers = useSelector(orgUsersListProgressSelector);
	const loadingInvitedUsers = useSelector(getInvitedUsersInProgressSelector);

	const handleOrgUsersSort = useCallback(
		async (itemKey: string) => {
			const updatedOrdering = getUpdatedOrderingData(orgUserDataOrdering, itemKey);

			setOrgUserDataOrdering(updatedOrdering);

			if (itemKey === "email" || itemKey === "license_type") {
				dispatch(
					getInvitedUsersForAdmin(
						updatedOrdering.filter(
							(updatedOrderingItem) =>
								updatedOrderingItem.includes("email") ||
								updatedOrderingItem.includes("license_type"),
						),
					),
				);
			}

			dispatch(getOrganisationUsersForAdmin(updatedOrdering));
		},
		[dispatch, orgUserDataOrdering],
	);

	useEffect(() => {
		dispatch(getInvitedUsersForAdmin());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className={styles.container}>
			{loadingUsers || loadingInvitedUsers ? (
				<OrganizationTableSkeleton />
			) : (
				<>
					<SubHeader
						onSort={handleOrgUsersSort}
						orgUserDataOrdering={orgUserDataOrdering}
					/>
					{users.length === 0 && invitedUsers.length === 0 ? (
						<div className={styles.empty}>
							<p className={styles.text}>
								There are currently no users in this organisation.
							</p>
							<Button
								LeftIcon={CreatePerson}
								className={styles.btn}
								onClick={displayCreateUserModal}
							>
								Add User
							</Button>
						</div>
					) : (
						<>
							{/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
							{users.map((user: any) => (
								<User key={user.id} usersCount={users.length} user={user} />
							))}
							{/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
							{invitedUsers.map((invitedUser: any) => (
								<InvitedUser key={invitedUser.id} user={invitedUser} />
							))}
						</>
					)}
				</>
			)}
		</div>
	);
};

export default UserTable;
