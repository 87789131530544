import {object, string} from "yup";

import type {Field, TForm} from "types";
import {EyeShowIcon, EyeHideIcon} from "assets/icons";

const fields: Field[] = [
	{
		type: "text",
		name: "email",
		label: "E-Mail",
		placeholder: "Email",
	},
	{
		type: "password",
		name: "password",
		label: "Password",
		placeholder: "Password",
		RightIcon: EyeHideIcon,
		RightToggledIcon: EyeShowIcon,
		rightLabel: "Forgot Password?",
	},
];

const schema = object().shape({
	email: string()
		.required("This field is required.")
		.email("You have entered an invalid e-mail address!"),
	password: string()
		.required("This is a required field")
		.min(8, "Password min length should be 8"),
});

const loginForm: TForm = {
	fields,
	schema,
};

export default loginForm;
