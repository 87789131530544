import {object, string} from "yup";

import type {Field, TForm} from "types";

const fields: Field[] = [
	{
		type: "email",
		name: "email",
		label: "E-Mail",
		placeholder: "Email",
	},
];

const schema = object().shape({
	email: string()
		.required("This field is required.")
		.email("You have entered an invalid e-mail address!"),
});

const forgotPassForm: TForm = {
	fields,
	schema,
};

export default forgotPassForm;
