import type {FC} from "react";

import type {IconProps} from "./types";

const AddToProjectIcon: FC<IconProps> = ({className = ""}) => (
	<svg
		width="20"
		height="20"
		viewBox="0 0 20 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		className={className}
	>
		<mask
			id="mask0_383_1689"
			maskUnits="userSpaceOnUse"
			x="0"
			y="0"
			width="20"
			height="20"
			style={{maskType: "alpha"}}
		>
			<rect width="24" height="24" fill="#D9D9D9" />
		</mask>
		<g mask="url(#mask0_383_1689)">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M2.44063 16.5594C2.73438 16.8531 3.0875 17 3.5 17H10.3414C10.1734 16.5246 10.0636 16.0218 10.0205 15.5H3.5V12.25H7.5V14H10.083C10.1713 13.4736 10.3281 12.9704 10.544 12.5H9V10.5H11V11.6822C11.4022 11.0774 11.9111 10.5497 12.5 10.126V9H7.5V10.75H3.5V6.5H16.5V9.02054C17.0218 9.06358 17.5246 9.1734 18 9.34141V6.5C18 6.0875 17.8531 5.73438 17.5594 5.44063C17.2656 5.14688 16.9125 5 16.5 5H14V3.5C14 3.0875 13.8531 2.73438 13.5594 2.44063C13.2656 2.14688 12.9125 2 12.5 2H7.5C7.0875 2 6.73438 2.14583 6.44063 2.4375C6.14688 2.72917 6 3.08187 6 3.49562V5H3.5C3.0875 5 2.73438 5.14688 2.44063 5.44063C2.14688 5.73438 2 6.0875 2 6.5V15.5C2 15.9125 2.14688 16.2656 2.44063 16.5594ZM12.5 5H7.5V3.5H12.5V5Z"
				fill="#1C1B1F"
			/>
			<path
				d="M15.1624 19V15.911H12V14.2609H15.1624V11H16.8376V14.2609H20V15.911H16.8376V19H15.1624Z"
				fill="#1C1B1F"
			/>
		</g>
	</svg>
);

export default AddToProjectIcon;
