const Triangle = () => (
	<svg width="11" height="7" viewBox="0 0 11 7" fill="none" xmlns="http://www.w3.org/2000/svg">
		<mask id="path-1-inside-1_398_11666" fill="white">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M6.92736 1C6.15756 -0.333333 4.23306 -0.333335 3.46326 0.999999L-0.00083971 7L10.3915 7L6.92736 1Z"
			/>
		</mask>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M6.92736 1C6.15756 -0.333333 4.23306 -0.333335 3.46326 0.999999L-0.00083971 7L10.3915 7L6.92736 1Z"
			fill="white"
		/>
		<path
			d="M3.46326 0.999999L4.32929 1.5V1.5L3.46326 0.999999ZM6.92736 1L6.06134 1.5V1.5L6.92736 1ZM-0.00083971 7L-0.866865 6.5L-1.73289 8L-0.000839894 8L-0.00083971 7ZM10.3915 7V8H12.1235L11.2575 6.5L10.3915 7ZM4.32929 1.5C4.71419 0.833333 5.67644 0.833332 6.06134 1.5L7.79339 0.5C6.63869 -1.5 3.75194 -1.5 2.59724 0.499999L4.32929 1.5ZM0.865186 7.5L4.32929 1.5L2.59724 0.499999L-0.866865 6.5L0.865186 7.5ZM-0.000839894 8L10.3915 8V6L-0.000839527 6L-0.000839894 8ZM6.06134 1.5L9.52544 7.5L11.2575 6.5L7.79339 0.5L6.06134 1.5Z"
			fill="#EDEDED"
			mask="url(#path-1-inside-1_398_11666)"
		/>
	</svg>
);

export default Triangle;
