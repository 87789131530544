import {specialismFilterKeyByPosition} from "store/mapx/filter/jobFunctionFilterSelectors";
import {setMultipleFilterForCandidates} from "store/mapx/filter/filterActions";
import {Dispatch} from "redux";
import {TAppDispatch, TRootState} from "types";
import {
	TJobFunctionPosition,
	TJobFunctionPositionConnectivityLogic,
} from "api/filterOptionsApi/JobFunctionApi/types";
import {getArrayDifference} from "helpers/array";

export const updateSelectedIdsByJobFunctionPositionAfterSelection =
	(selectedIds: number[], position: TJobFunctionPosition) =>
	(dispatch: Dispatch<TAppDispatch>, getState: () => TRootState) => {
		const state = getState();

		const logic =
			state.filter.candidate_job_function_filter_config[
				`active_${position}_position_connect_logic`
			];

		const specialismFilterKey = specialismFilterKeyByPosition(state, position, logic);

		const specialismIdsBySelectedPosition: number[] =
			state.filter.candidate_filters[specialismFilterKey];

		const updatedIds = getArrayDifference(specialismIdsBySelectedPosition, selectedIds);

		return {
			[specialismFilterKey]: updatedIds,
		};
	};

export const setSpecialismForCandidate =
	({
		position,
		logic,
		id,
	}: {
		position: TJobFunctionPosition;
		logic: TJobFunctionPositionConnectivityLogic;
		id: number;
	}) =>
	(dispatch: Dispatch<TAppDispatch>, getState: () => TRootState) => {
		const state = getState();

		const specialismFilterKey = specialismFilterKeyByPosition(state, position, logic);

		const specialisms: number[] = state.filter.candidate_filters[specialismFilterKey];

		const index = specialisms.indexOf(id);

		const isSelectedAlready = index !== -1;

		const nextSpecialisms = isSelectedAlready
			? [...specialisms.slice(0, index), ...specialisms.slice(index + 1)]
			: [...specialisms, id];

		const payload = {
			[specialismFilterKey]: nextSpecialisms,
		};

		// const positionOptions: TJobFunctionPosition[] = ["current", "previous", "any"];
		//
		// const filteredPositionOptions = positionOptions.filter((option) => option !== position);
		//
		// for (const filteredPosition of filteredPositionOptions) {
		// 	const filteredPositionUpdatedPayload = dispatch(
		// 		updateSelectedIdsByJobFunctionPositionAfterSelection(
		// 			nextSpecialisms,
		// 			filteredPosition,
		// 		),
		// 	);
		//
		// 	payload = {...payload, ...filteredPositionUpdatedPayload};
		// }

		dispatch(setMultipleFilterForCandidates(payload));
	};

export const setBulkSpecialismsForCandidates =
	(position: TJobFunctionPosition, logic: TJobFunctionPositionConnectivityLogic, ids: number[]) =>
	(dispatch: Dispatch<TAppDispatch>, getState: () => TRootState) => {
		const state = getState();

		const specialismFilterKey = specialismFilterKeyByPosition(state, position, logic);

		const payload = {
			[specialismFilterKey]: ids,
		};

		// const positionOptions: TJobFunctionPosition[] = ["current", "previous", "any"];
		//
		// const filteredPositionOptions = positionOptions.filter((option) => option !== position);
		//
		// for (const filteredPosition of filteredPositionOptions) {
		// 	const filteredPositionUpdatedPayload = dispatch(
		// 		updateSelectedIdsByJobFunctionPositionAfterSelection(ids, filteredPosition),
		// 	);
		//
		// 	payload = {...payload, ...filteredPositionUpdatedPayload};
		// }

		dispatch(setMultipleFilterForCandidates(payload));
	};

export const switchSpecialismsConnectorForCandidatePosition =
	({
		position,
		fromLogic,
		toLogic,
	}: {
		position: TJobFunctionPosition;
		fromLogic: TJobFunctionPositionConnectivityLogic;
		toLogic: TJobFunctionPositionConnectivityLogic;
	}) =>
	(dispatch: Dispatch<TAppDispatch>, getState: () => TRootState) => {
		const state = getState();

		const fromSpecialismFilterKey = specialismFilterKeyByPosition(state, position, fromLogic);

		const toSpecialismFilterKey = specialismFilterKeyByPosition(state, position, toLogic);

		const specialismIds: number[] = state.filter.candidate_filters[fromSpecialismFilterKey];

		dispatch(
			setMultipleFilterForCandidates({
				[fromSpecialismFilterKey]: [],
				[toSpecialismFilterKey]: specialismIds,
			}),
		);
	};

export const moveSpecialismForCandidate =
	({
		from,
		to,
		fromLogic,
		toLogic,
		id,
	}: {
		from: TJobFunctionPosition;
		to: TJobFunctionPosition;
		fromLogic: TJobFunctionPositionConnectivityLogic;
		toLogic: TJobFunctionPositionConnectivityLogic;
		id: number;
	}) =>
	(dispatch: Dispatch<TAppDispatch>, getState: () => TRootState) => {
		const state = getState();

		const fromSpecialismFilterKey = specialismFilterKeyByPosition(state, from, fromLogic);

		const toSpecialismFilterKey = specialismFilterKeyByPosition(state, to, toLogic);

		const fromSpecialisms: number[] = state.filter.candidate_filters[fromSpecialismFilterKey];

		const toSpecialisms: number[] = state.filter.candidate_filters[toSpecialismFilterKey];

		const nextFromSpecialisms = [...fromSpecialisms].filter((i) => i !== id);

		const nextToSpecialisms = [id, ...toSpecialisms];

		dispatch(
			setMultipleFilterForCandidates({
				[fromSpecialismFilterKey]: nextFromSpecialisms,
				[toSpecialismFilterKey]: nextToSpecialisms,
			}),
		);
	};

export const clearSpecialismsByPositionForCandidate =
	(position: TJobFunctionPosition) => (dispatch: Dispatch<TAppDispatch>) => {
		let payload;

		switch (position) {
			case "current":
				payload = {
					current_specialisms_and: [],
					current_specialisms_or: [],
					current_specialisms_not: [],
				};
				break;
			case "previous":
				payload = {
					previous_specialisms_and: [],
					previous_specialisms_or: [],
					previous_specialisms_not: [],
				};
				break;
			default:
				payload = {
					specialisms_and: [],
					specialisms_or: [],
					specialisms_not: [],
				};
		}

		dispatch(setMultipleFilterForCandidates(payload));
	};
