import type {FC} from "react";

import type {IconProps} from "./types";

const MagicIcon: FC<IconProps> = ({className = ""}) => (
	<svg
		width="20"
		height="20"
		viewBox="0 0 20 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		className={className}
	>
		<mask
			id="mask0_44_84867"
			style={{maskType: "alpha"}}
			maskUnits="userSpaceOnUse"
			x="0"
			y="0"
			width="20"
			height="20"
		>
			<rect width="20" height="20" fill="#D9D9D9" />
		</mask>
		<g mask="url(#mask0_44_84867)">
			<path
				d="M8 11.4167L8.75 9.77083L10.3958 9L8.75 8.25L8 6.60417L7.22917 8.25L5.58333 9L7.22917 9.77083L8 11.4167ZM8 15L6.10417 10.8958L2 9L6.10417 7.125L8 3L9.875 7.125L14 9L9.875 10.8958L8 15ZM15 17L14.0417 14.9583L12 14L14.0417 13.0625L15 11L15.9375 13.0625L18 14L15.9375 14.9583L15 17Z"
				fill="#868D8D"
			/>
		</g>
	</svg>
);

export default MagicIcon;
