import axios from "axios";
import {getJobTitlesFetch, getJobTitlesFetchCompleted} from "store/mapx/search/searchActions";
import mapxJobTitleApi from "api/filterOptionsApi/JobTitleApi";
import {TAppDispatch} from "types";
import {Dispatch} from "redux";
import {
	setPRFilter,
	toggleJobTitleSuggestionLoading,
} from "store/mapx/additional-profiles/additionalProfilesActions";
import {successResponse} from "helpers/map";
import {
	STJobTitleClassification,
	STLLMJobTitleSuggestionPayload,
} from "api/filterOptionsApi/JobTitleApi/types";
import {removeDuplicatesFromArray} from "helpers/filterHandlers";

/* eslint-disable  @typescript-eslint/no-explicit-any */
const cancelTokens = {} as any;

export const getJobTitles =
	(searchTerm: string, companyId?: Nullable<number>) => async (dispatch: TAppDispatch) => {
		try {
			const requestKey = "GET_JOB_TITLES";

			if (cancelTokens[requestKey]) {
				cancelTokens[requestKey].cancel("Operation canceled due to new request.");
			}

			cancelTokens[requestKey] = axios.CancelToken.source();

			const config = {
				cancelToken: cancelTokens[requestKey].token,
			};

			dispatch(getJobTitlesFetch());

			const payload: STLLMJobTitleSuggestionPayload = {
				job_title: searchTerm,
			};

			if (companyId) {
				payload.company_id = companyId;
			}

			const response = await mapxJobTitleApi.getLLMJobTitleSuggestions(payload, config);

			if (response === undefined) {
				return undefined;
			}

			if (successResponse(response, 200)) {
				dispatch(getJobTitlesFetchCompleted());

				return response.data;
			} else {
				dispatch(getJobTitlesFetchCompleted());

				return [];
			}
		} catch (e) {
			dispatch(getJobTitlesFetchCompleted());

			console.log(`Error from job title suggestion request: ${e}`);

			return [];
		}
	};
export const getJobTitlesSuggestionForProfileRequest =
	(candidateId: number, onlySuggestedJobTitles: boolean) =>
	async (dispatch: Dispatch<TAppDispatch>) => {
		const requestKey = `PR_JOB_TITLE_SUGGESTIONS`;

		if (requestKey in cancelTokens) {
			cancelTokens[requestKey].cancel("Operation canceled due to new request.");
		}

		cancelTokens[requestKey] = axios.CancelToken.source();

		const config = {
			cancelToken: cancelTokens[requestKey].token,
		};

		try {
			dispatch(toggleJobTitleSuggestionLoading(true));

			const response = await mapxJobTitleApi.suggestion([candidateId], config);

			if (successResponse(response, 200)) {
				const jobTitles: string[] = response.data;

				if (!onlySuggestedJobTitles) {
					const selectedJobTitles = jobTitles.slice(0, 5); // takes 1st 5
					dispatch(setPRFilter({type: "selected_job_titles", values: selectedJobTitles}));
				}

				const suggestedJobTitles = jobTitles.slice(5); // skips 1st 5, takes rest of the items

				dispatch(setPRFilter({type: "job_title_suggestions", values: suggestedJobTitles}));
			}

			dispatch(toggleJobTitleSuggestionLoading(false));

			return response && "status" in response ? response?.status : 500;
		} catch (e) {
			console.log(`Error from job title suggestion request: ${e}`);
		}
	};

export const getJobTitleClassification = (jobTitles: string[]) => async () => {
	try {
		const response = await mapxJobTitleApi.classifications({
			job_titles: jobTitles,
		});

		if (successResponse(response, 200)) {
			const jobClassifications: STJobTitleClassification[] = response.data;

			let jobTitleSuggestions: string[] = [];
			let senioritySuggestions: string[] = [];
			let specsSuggestions: number[] = [];

			jobClassifications.forEach((jc) => {
				if (jc.top_seniority) {
					senioritySuggestions = [...senioritySuggestions, jc.top_seniority];
				}
				specsSuggestions = [...specsSuggestions, ...jc.top_specialisms.map((s) => s.id)];
				jobTitleSuggestions = [...jobTitleSuggestions, jc.job_title];
			});

			return {
				job_titles: removeDuplicatesFromArray(jobTitleSuggestions),
				specialisms: removeDuplicatesFromArray(specsSuggestions),
				current_seniority: removeDuplicatesFromArray(senioritySuggestions),
			};
		}
	} catch (e) {
		console.log(`Error from job title suggestion request ${e?.toString()}`);
	}

	return {
		job_titles: [],
		specialisms: [],
		current_seniority: [],
	};
};
