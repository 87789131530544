import React, {FC} from "react";
import styles from "./projectCard.module.scss";
import {TProjectDiversityPercentageBarProps} from "./types";

const ProjectDiversityPercentageBar: FC<TProjectDiversityPercentageBarProps> = ({percentage}) => {
	return (
		<div className={styles.diversity_stat}>
			<span>Diversity {percentage ?? 0}%</span>
			<div className={styles.progressBar}>
				<div className={styles.progress} style={{width: `${percentage ?? 0}%`}}></div>
			</div>
		</div>
	);
};

export default ProjectDiversityPercentageBar;
