import React from "react";
import styles from "./styles.module.scss";
import ContentLoader from "react-content-loader";

const SkeletonLoader = ({count = 4}) => {
	return (
		<div className={styles.skeletonContainer} role={"project-checklist-loader"}>
			{Array.from({length: count}, (_, idx) => (
				<div className={styles.skeletonCard} key={idx}>
					<ContentLoader
						speed={2}
						height={`120px`}
						width={"100%"}
						backgroundColor="#DCE7E7"
						foregroundColor="#F9F9F9"
					>
						<circle cx="10" cy="10" r="8" />
						<rect x="40" y="2" width="90" height="20" />
						<rect x="40" y="32" width="70%" height="17" />
						<rect x="40" y="62" width="80%" height="17" />
						<rect x="40" y="92" width="50%" height="17" />
					</ContentLoader>
				</div>
			))}
		</div>
	);
};

export default SkeletonLoader;
