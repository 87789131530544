import {useCallback} from "react";
import {useSelector} from "react-redux";

import {ArrowCircleUpIcon, OrganisatioSettingsHeaderSkeleton} from "assets/icons";
import {useAppDispatch} from "hooks";
import {toHumanReadablePrice} from "helpers/string";
import {GeneralButton} from "mapx-components";
import {
	userOrganisationRoleSelector,
	userOrganisationSelector,
	userSelector,
} from "store/mapx/user/userSelectors";
import {contactAdminModal, pricingTableModal} from "store/mapx/payment/paymentActions";
import {
	getInvitedUsersInProgressSelector,
	orgPlanDistributionSelector,
	orgUsersListProgressSelector,
	organisationAdminSelector,
	organisationHasPaidPlanSelector,
	organisationSeatsSelector,
	seatsInProgressSelector,
} from "store/mapx/organisation/organisationSelectors";
import {openCustomerPortalInProgressSelector} from "store/mapx/payment/paymentSelectors";
import {openCustomerPortal} from "store/mapx/payment/paymentAsyncActions";
import {getLicenseTypeInProgressSelector} from "store/mapx/company/companySelectors";

import styles from "./OrganisationInfo.module.scss";

const OrganisationInfo = () => {
	const dispatch = useAppDispatch();

	const admin = useSelector(organisationAdminSelector);
	const user = useSelector(userSelector);
	const organisation = useSelector(userOrganisationSelector);
	const organisationRole = useSelector(userOrganisationRoleSelector);

	const seatsDistribution = useSelector(orgPlanDistributionSelector);
	const seats = useSelector(organisationSeatsSelector);
	const openingCustomerPortal = useSelector(openCustomerPortalInProgressSelector);

	const loadingSeats = useSelector(seatsInProgressSelector);
	const loadingLicenseTypes = useSelector(getLicenseTypeInProgressSelector);
	const loadingUsers = useSelector(orgUsersListProgressSelector);
	const loadingInvitedUsers = useSelector(getInvitedUsersInProgressSelector);

	const organisationHasPaidPlan = useSelector(organisationHasPaidPlanSelector);

	const handleUpgradeClick = useCallback(() => {
		if (admin == null || organisationRole === "Admin") {
			dispatch(pricingTableModal(true));
		} else {
			dispatch(contactAdminModal(true));
		}
	}, [admin, dispatch, organisationRole]);

	const handleManagePlanClick = useCallback(() => {
		dispatch(openCustomerPortal());
	}, [dispatch]);

	return (
		<>
			<div className={styles.container}>
				<div className={styles.left}>
					<h1 className={styles.title}>{organisation.name}</h1>
					<div className={styles.subtitle}>Organisation</div>
				</div>
				<div className={styles.right}>
					{loadingSeats || loadingLicenseTypes || loadingUsers || loadingInvitedUsers ? (
						<OrganisatioSettingsHeaderSkeleton />
					) : (
						<>
							{organisationRole === "Admin" ? (
								<>
									<div className={styles.plans}>
										<div className={styles.title}>Your Plans</div>
										<div className={styles.licenses}>
											{seatsDistribution.map(
												(sd) =>
													sd.license_type !== "None" && (
														<div
															key={sd.license_type}
															className={styles.license}
														>
															<div className={styles.licenseType}>
																{sd.license_type}
															</div>
															<div className={styles.distribution}>
																{sd.license_type === "Starter" ||
																sd.license_type === "Prospect" ? (
																	<>{sd.used}</>
																) : (
																	<>{`${sd.used}/${sd.total}`}</>
																)}
															</div>
														</div>
													),
											)}
										</div>
									</div>
									{(seats.cost.monthly > 0 || seats.cost.yearly > 0) && (
										<>
											<div className={styles.verticalLine} />
											<div className={styles.payment}>
												<div className={styles.title}>Payment</div>
												<div className={styles.costs}>
													{seats.cost.monthly > 0 && (
														<div className={styles.cost}>
															<span className={styles.amount}>
																{toHumanReadablePrice(
																	seats.cost.monthly,
																	"USD",
																)}
															</span>
															<span className={styles.period}>
																/mo.
															</span>
														</div>
													)}
													{seats.cost.yearly > 0 && (
														<div className={styles.cost}>
															<div className={styles.amount}>
																{seats.cost.yearly}
															</div>
															<div className={styles.period}>
																/yr.
															</div>
														</div>
													)}
												</div>
											</div>
										</>
									)}
									<div className={styles.buttons}>
										{organisationHasPaidPlan ? (
											<GeneralButton
												title="Manage Plan"
												handleClick={handleManagePlanClick}
												loadingSave={openingCustomerPortal}
											/>
										) : (
											<GeneralButton
												customClass={styles.upgradeButton}
												icon={
													<ArrowCircleUpIcon
														width={24}
														height={24}
														color="white"
													/>
												}
												title="Upgrade to"
												onClick={handleUpgradeClick}
											>
												<span className={styles.plus}>PLUS</span>
											</GeneralButton>
										)}
									</div>
								</>
							) : (
								<>
									<div className={styles.plans}>
										<div className={styles.title}>Your Plan</div>
										<div className={styles.licenses}>
											<div className={styles.license}>
												<div className={styles.licenseType}>
													{user?.license?.type?.name || "Starter"}
												</div>
											</div>
										</div>
									</div>
									{admin == null && (
										<div className={styles.buttons}>
											<GeneralButton
												customClass={styles.upgradeButton}
												icon={
													<ArrowCircleUpIcon
														width={24}
														height={24}
														color="white"
													/>
												}
												title="Upgrade to"
												onClick={handleUpgradeClick}
											>
												<span className={styles.plus}>PLUS</span>
											</GeneralButton>
										</div>
									)}
								</>
							)}
						</>
					)}
				</div>
			</div>
		</>
	);
};

export default OrganisationInfo;
