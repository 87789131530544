import {useState, type FC} from "react";
import classNames from "classnames";
import {useHistory} from "react-router-dom";

import {GeneralButton} from "mapx-components";
import {EnhanceIcon, SearchIcon} from "assets/icons";
import {useAppDispatch, useLockBodyScroll} from "hooks";
import {setOnboardingStep} from "store/mapx/project/projectActions";
import {EOnboardingTooltipSteps} from "mapx-components/OnboardingTooltip/types";

import type {TStepBoardingType} from "../types";

import styles from "./OnboardingHome.module.scss";

const OnboardingFakeSearch: FC<TStepBoardingType> = ({step}) => {
	const history = useHistory();
	const dispatch = useAppDispatch();

	const [isCreateText, setIsCreateText] = useState<boolean>(false);
	const isRenderSearch: boolean = step === EOnboardingTooltipSteps.Search;

	const createTextHandler = () => {
		setIsCreateText(true);
	};

	const changeOnboardingHandler = () => {
		if (isCreateText) {
			dispatch(setOnboardingStep("increment"));

			history.push("/onboarding/projects");
		}
	};

	useLockBodyScroll(isRenderSearch);

	return (
		<div className={classNames(styles.search, {[styles.search__show]: isRenderSearch})}>
			<p className={styles.search__content} role="button" onClick={createTextHandler}>
				<SearchIcon /> {isCreateText && "Ideas for Google’s next CTO"}
			</p>

			<GeneralButton
				title="Search"
				onClick={changeOnboardingHandler}
				customClass={styles.search__button}
				icon={<EnhanceIcon color="white" />}
			/>
		</div>
	);
};

export default OnboardingFakeSearch;
