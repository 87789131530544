import {FC, MouseEvent, useCallback, useEffect, useMemo} from "react";
import {noop} from "lodash";

import {HeaderDropDown} from "components";
import {customConfirmAlert} from "helpers";
import {useAppDispatch, useAppSelector} from "hooks";
import {HeaderProjectIcon, MarketMapsIcon, PreviewIcon, RecycleBinLine} from "assets/icons";
import type {TFeatureProp} from "components/HeaderDropDown/types";
import {projectSelector} from "store/mapx/project-list/projectListSelectors";
import {
	getTargetListCompanies,
	removeCompaniesFromTargetList,
	removeCompanyFromTargetList,
} from "store/mapx/target-list/targetListAsyncActions";
import {
	removingCompaniesFromTargetListInProgressSelector,
	targetCompaniesPaginationSelector,
	targetListCompaniesSelector,
	targetListCompanyInProgressSelector,
	targetListsInProgressSelector,
	targetListsSelector,
} from "store/mapx/target-list/targetListSelectors";
import {
	hasAccessToMarketMapsSelector,
	isSuperUserSelector,
	userLicenseTypeNameSelector,
} from "store/mapx/user/userSelectors";
import {addFromMarketMapModal} from "store/mapx/market-map/marketMapActions";

import type {TSavedCompanyProp} from "./types";

import styles from "../header.module.scss";
import {STCompany} from "api/companyApi/types";
import {setProjectTabIndex} from "store/mapx/project-list/projectListActions";
import {useHistory} from "react-router-dom";
import ProjectTabs from "mapx-pages/Project/ProjectTabs";

const SavedCompany: FC<TSavedCompanyProp> = ({className}) => {
	const dispatch = useAppDispatch();

	const history = useHistory();

	const project = useAppSelector(projectSelector);

	const targetLists = useAppSelector(targetListsSelector);

	const companies = useAppSelector(targetListCompaniesSelector);

	const targetCompaniesPagination = useAppSelector(targetCompaniesPaginationSelector);

	const userLicenseTypeName = useAppSelector(userLicenseTypeNameSelector);

	const loadingTargetLists = useAppSelector(targetListsInProgressSelector);

	const isSuperUSer = useAppSelector(isSuperUserSelector);

	const hasAccessToMarketMaps = useAppSelector(hasAccessToMarketMapsSelector);

	const loadingCompanyTargetList = useAppSelector(targetListCompanyInProgressSelector);

	const removingCompanies = useAppSelector(removingCompaniesFromTargetListInProgressSelector);

	const isStarterOrPlusUser: boolean =
		userLicenseTypeName === "Starter" || (userLicenseTypeName === "Plus" && !isSuperUSer);

	const loading = useMemo(
		() => loadingCompanyTargetList || removingCompanies || loadingTargetLists,
		[loadingCompanyTargetList, loadingTargetLists, removingCompanies],
	);

	const handleRemoveAllClick = useCallback(() => {
		customConfirmAlert({
			yesButtonLabel: "Yes",
			noButtonLabel: "No",
			title: "CompanyDetails Deletion Warning!",
			message: `Are you sure about deleting all companies from the Target List?`,
			handlePressYes: () => {
				dispatch(removeCompaniesFromTargetList());
			},
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleAddFromMarketMapClick = useCallback(() => {
		dispatch(addFromMarketMapModal(true, {}));
	}, [dispatch]);

	const handleTrashClick = (event: MouseEvent<SVGElement>, companyID: number) => {
		event.stopPropagation();
		event.preventDefault();

		const company = companies?.find((c: STCompany) => c.id === companyID);

		customConfirmAlert({
			yesButtonLabel: "Yes",
			noButtonLabel: "No",
			title: "CompanyDetails Deletion Warning!",
			message: `Are you sure about deleting "${company.name}" from the Target List?`,
			handlePressYes: () => {
				dispatch(removeCompanyFromTargetList(companyID));
			},
		});
	};

	const handleSeeAllCompaniesClick = useCallback(() => {
		if (project?.id) {
			dispatch(setProjectTabIndex(ProjectTabs.savedCompaniesTabIndex));
			history.push(`/project/${project.id}`);
		}
	}, [dispatch, history, project?.id]);

	const featureUserList: TFeatureProp[] = useMemo(
		() => [
			hasAccessToMarketMaps
				? {
						id: 3,
						deleteBtn: false,
						icon: MarketMapsIcon,
						btnClassName: isStarterOrPlusUser ? styles.starter : "",
						text: "Add from Target Lists",
						onClick: !isStarterOrPlusUser ? handleAddFromMarketMapClick : noop,
				  }
				: {
						id: "EmptyItem",
				  },
			targetCompaniesPagination?.count > 200
				? {
						id: 11,
						deleteBtn: false,
						icon: PreviewIcon,
						btnClassName: isStarterOrPlusUser ? styles.starter : "",
						text: "See All Companies",
						onClick: handleSeeAllCompaniesClick,
				  }
				: {
						id: "EmptyItem",
				  },
			Number(!!targetCompaniesPagination?.count) === 0
				? {
						id: "EmptyData",
						text: "companies",
				  }
				: {
						id: "EmptyItem",
				  },
			Number(!!targetCompaniesPagination?.count) > 0
				? {
						id: 2,
						deleteBtn: true,
						icon: RecycleBinLine,
						text: "Remove All Companies",
						onClick: handleRemoveAllClick,
				  }
				: {id: "EmptyItem"},
		],
		[
			targetCompaniesPagination?.count,
			isStarterOrPlusUser,
			handleSeeAllCompaniesClick,
			hasAccessToMarketMaps,
			handleAddFromMarketMapClick,
			handleRemoveAllClick,
		],
	);

	const displayedCompanies = useMemo(() => {
		return companies.slice(0, 50);
	}, [companies]);

	useEffect(() => {
		if (
			targetLists.length > 0 &&
			!loadingCompanyTargetList &&
			window.location.pathname !== "/candidates"
		) {
			dispatch(getTargetListCompanies());
		}
	}, [dispatch, project?.id, targetLists]); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<HeaderDropDown
			groupRight
			deleteItem
			onHoverTooltip
			loading={loading}
			pathName="company"
			className={className}
			scrollList={displayedCompanies}
			title={targetCompaniesPagination?.count ?? 0}
			LeftIcon={HeaderProjectIcon}
			listClasses={styles.list__width}
			featureUserList={featureUserList}
			handleTrashClick={handleTrashClick}
		/>
	);
};

export default SavedCompany;
