import type {IconProps} from "./types";

const ToastCloseIcon = ({color = "#003B66", ...rest}: IconProps) => {
	return (
		<svg
			width={20}
			height={20}
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...rest}
		>
			<mask
				id="prefix__a"
				style={{
					maskType: "alpha",
				}}
				maskUnits="userSpaceOnUse"
				x={0}
				y={0}
				width={20}
				height={20}
			>
				<path fill="#D9D9D9" d="M0 0h20v20H0z" />
			</mask>
			<g mask="url(#prefix__a)">
				<path
					d="M7.063 14L10 11.062 12.938 14 14 12.937 11.062 10 14 7.062 12.937 6 10 8.938 7.062 6 6 7.063 8.938 10 6 12.938 7.063 14zM10 18a7.796 7.796 0 01-3.104-.625 8.064 8.064 0 01-2.552-1.719 8.063 8.063 0 01-1.719-2.552A7.797 7.797 0 012 10c0-1.111.208-2.15.625-3.115a8.064 8.064 0 014.27-4.26A7.797 7.797 0 0110 2c1.111 0 2.15.208 3.115.625a8.095 8.095 0 014.26 4.26C17.792 7.851 18 8.89 18 10a7.797 7.797 0 01-.625 3.104 8.063 8.063 0 01-4.26 4.271A7.774 7.774 0 0110 18z"
					fill={color}
				/>
			</g>
		</svg>
	);
};

export default ToastCloseIcon;
