import {useCallback, useEffect, useMemo, useState} from "react";
import axios from "axios";
import {filter} from "lodash";
import {getSearchData} from "store/mapx/search/searchAsyncActions";
import {getSearchDataSuccess} from "store/mapx/search/searchActions";
import {searchedDataSelector} from "store/mapx/search/searchSelectors";

import usePrevious from "../usePrevious";
import useAppDispatch from "../useAppDispatch";
import useAppSelector from "../useAppSelector";
import useSearchDebounce from "../useSearchDebounce";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
let cancelCompaniesToken: any;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
let cancelCandidatesToken: any;

const useCompanyPeopleSearch = () => {
	const dispatch = useAppDispatch();

	const {results: searchData} = useAppSelector(searchedDataSelector);

	const [searchTerm, setSearchTerm] = useSearchDebounce(800);

	const [isLoading, setIsLoading] = useState<boolean>(false);

	const [activeCategory, setActiveCategory] = useState<number>(0);

	const prevSearchTerm = usePrevious<string>(searchTerm);

	const companies = useMemo(
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		() => filter(searchData, (item: any) => item.searchType === "company"),
		[searchData],
	);
	const candidates = useMemo(
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		() => filter(searchData, (item: any) => item.searchType === "candidates"),
		[searchData],
	);

	const cancelTokens = () => {
		if (typeof cancelCompaniesToken !== typeof undefined) {
			cancelCompaniesToken.cancel("Companies get canceled due to new request.");
		}
		if (typeof cancelCandidatesToken !== typeof undefined) {
			cancelCandidatesToken.cancel("Candidates get canceled due to new request.");
		}
	};

	useEffect(() => {
		if (searchTerm !== prevSearchTerm) {
			dispatch(
				getSearchDataSuccess({
					results: [],
					companiesPagination: null,
					candidatesPagination: null,
				}),
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchTerm]);

	useEffect(() => {
		const getSearch = async () => {
			cancelTokens();

			cancelCompaniesToken = axios.CancelToken.source();
			cancelCandidatesToken = axios.CancelToken.source();

			const tokenConfig = {
				cancelCompaniesToken: cancelCompaniesToken.token,
				cancelCandidatesToken: cancelCandidatesToken.token,
			};

			setIsLoading(true);

			await dispatch(
				getSearchData({
					value: searchTerm,
					filterType: "all",
					config: tokenConfig,
					perPage: 20,
				}),
			);

			cancelCompaniesToken = undefined;
			cancelCandidatesToken = undefined;

			setIsLoading(false);
		};

		getSearch();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchTerm]);

	const onChange = useCallback(
		async (value: string) => {
			setSearchTerm(value);
		},
		[setSearchTerm],
	);

	return {
		searchTerm,
		isLoading,
		onChange,
		companies,
		candidates,
		all: searchData,
		activeCategory,
		setActiveCategory,
	};
};

export default useCompanyPeopleSearch;
