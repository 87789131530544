import type {FC} from "react";

import type {IconProps} from "./types";

type SortProps = {
	sortType: "down" | "up";
} & IconProps;

const Sort: FC<SortProps> = ({onClick, sortType = "down"}) => (
	<div style={{cursor: "pointer"}}>
		{sortType == "down" && (
			<svg
				onClick={onClick}
				width="20"
				height="20"
				viewBox="0 0 20 20"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<mask
					id="mask0_138_3616"
					style={{maskType: "alpha"}}
					maskUnits="userSpaceOnUse"
					x="0"
					y="0"
					width="20"
					height="20"
				>
					<rect width="20" height="20" fill="#D9D9D9" />
				</mask>
				<g mask="url(#mask0_138_3616)">
					<path
						d="M10.5 3C10.5 2.72386 10.2761 2.5 10 2.5C9.72386 2.5 9.5 2.72386 9.5 3L10.5 3ZM10 17L12.8868 12L7.11325 12L10 17ZM9.5 3L9.5 12.5L10.5 12.5L10.5 3L9.5 3Z"
						fill="#0C5850"
					/>
				</g>
			</svg>
		)}

		{sortType == "up" && (
			<svg
				width="20"
				height="20"
				viewBox="0 0 20 20"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<mask
					id="mask0_138_3644"
					style={{maskType: "alpha"}}
					maskUnits="userSpaceOnUse"
					x="0"
					y="0"
					width="20"
					height="20"
				>
					<rect width="20" height="20" fill="#D9D9D9" />
				</mask>
				<g mask="url(#mask0_138_3644)">
					<path
						d="M9.5 17C9.5 17.2761 9.72386 17.5 10 17.5C10.2761 17.5 10.5 17.2761 10.5 17L9.5 17ZM10 3L7.11325 8L12.8868 8L10 3ZM10.5 17L10.5 7.5L9.5 7.5L9.5 17L10.5 17Z"
						fill="#0C5850"
					/>
				</g>
			</svg>
		)}
	</div>
);

export default Sort;
