import React, {useCallback, useMemo, useState} from "react";
import styles from "./styles.module.scss";
import {HiOutlineDownload} from "react-icons/hi";
import {useSelector} from "react-redux";
import {activeSearchRequestSelector} from "store/mapx/additional-profiles/additionalProfilesSelectors";
import {
	prepareDateForFileNameSuffix,
	prepareSpreadSheetFileAndDownload,
} from "helpers/documentHelpers";
import {Loader} from "components";
import colors from "styles/themes.module.scss";
import {projectSelector} from "store/mapx/project-list/projectListSelectors";
import {isSuperUserSelector} from "store/mapx/user/userSelectors";
import {slugifyForParameter} from "helpers/string";
import searchRequestApi from "api/projectApi/searchRequestApi";

const DownloadSearchRequestLog = () => {
	const project = useSelector(projectSelector);

	const [downloadProfileRequestProcessing, setDownloadProfileRequestProcessing] = useState(false);

	const activeSearchRequest = useSelector(activeSearchRequestSelector);

	const isSuperUser = useSelector(isSuperUserSelector);

	const activeSearchType = useMemo(() => {
		return activeSearchRequest?.type;
	}, [activeSearchRequest?.type]);

	const handleProfilesRequestDownloadRequest = useCallback(async () => {
		const ID = activeSearchRequest?.id;

		if (ID === undefined) return;

		setDownloadProfileRequestProcessing(true);

		const formattedDate = prepareDateForFileNameSuffix();
		const projectName = project?.name !== null ? project.name : "Unnamed Project";
		const fileName = slugifyForParameter(
			`${projectName}-${activeSearchType}-${ID}-${formattedDate}`,
		);

		await searchRequestApi.exportSearchRequestLog(parseInt(ID)).then((res) => {
			prepareSpreadSheetFileAndDownload(res.data, fileName);
		});

		setDownloadProfileRequestProcessing(false);
	}, [activeSearchRequest?.id, activeSearchType, project?.name]);

	const shouldDisplaySearchTypeDownload = useMemo(() => {
		if (!isSuperUser || !project) return false;

		return (
			activeSearchRequest &&
			(activeSearchRequest.status === "In Progress" ||
				activeSearchRequest.status === "Completed")
		);
	}, [activeSearchRequest, isSuperUser, project]);

	const LoaderComponent = (
		<Loader
			height={24}
			width={24}
			type="ThreeDots"
			customStyle={{display: "flex", alignItems: "center", margin: "0 5px"}}
			color={colors.loaderDotColor}
		/>
	);

	return (
		shouldDisplaySearchTypeDownload && (
			<div>
				{downloadProfileRequestProcessing ? (
					LoaderComponent
				) : (
					<span
						className={styles.downloadButton}
						onClick={handleProfilesRequestDownloadRequest}
					>
						<HiOutlineDownload size={18} className={styles.downloadIcon} /> Download
						Search Request
					</span>
				)}
			</div>
		)
	);
};

export default DownloadSearchRequestLog;
