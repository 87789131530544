import styles from "./dealExperienceInfo.module.scss";
import DealExperienceIcon from "assets/icons/DealExperienceIcon";
import {TDealExperienceInfoProps} from "./types";

const DealExperienceInfo = ({events}: TDealExperienceInfoProps) => {
	const formatDate = (date: string) => {
		return date
			? new Date(date).toLocaleString("default", {month: "long", year: "numeric"})
			: "";
	};

	const formatSize = (num: number): string => {
		if (num >= 1_000_000_000) {
			return `$${(num / 1_000_000_000).toFixed(1)}b`;
		} else if (num >= 1_000_000) {
			return `$${(num / 1_000_000).toFixed(1)}m`;
		} else if (num >= 1_000) {
			return `${(num / 1_000).toFixed(1)}k`;
		} else {
			return num.toString();
		}
	};

	return (
		<div>
			{events?.map((event, index) => (
				<section key={index} className={styles.eventSection}>
					<div className={styles.eventHeader}>
						<DealExperienceIcon />
						<div className={styles.eventDetails}>
							<span className={styles.eventInfo}>
								<span> {event.type} </span>
								{event.date && <span>&bull; {formatDate(event.date)}</span>}
								{event.size && <span> &bull; {formatSize(event.size)} </span>}
							</span>
						</div>
					</div>
					<p className={styles.eventDescription}>{event.description}</p>
				</section>
			))}
		</div>
	);
};

export default DealExperienceInfo;
