import React, {Dispatch, SetStateAction, useEffect, useMemo, useRef, useState} from "react";
import styles from "./styles.module.scss";
import classNames from "classnames";
import CircularProgress from "mapx-components/Cards/CandidateScoring/ProgressCircle";
import {AccordionAlt} from "mapx-components/index";
import YesIcon from "mapx-components/AccordionAlt/Icons/yesIcon";
import NoIcon from "mapx-components/AccordionAlt/Icons/noIcon";
import MaybeIcon from "mapx-components/AccordionAlt/Icons/maybeIcon";
import {TCandidateFitToBrief} from "api/candidateApi/types";
import ContentLoader from "react-content-loader";
import EditFitToBriefModal from "../../Modals/FitToBriefModal/Edit";
import AssessmentEditIcon from "../../Modals/FitToBriefModal/Icon/editIcon";

const CandidateScoring = ({
	fit_to_brief,
	isLoading = false,
	expanded = false,
	setShouldAllExpDisplayed,
	handleOnEdit,
}: {
	fit_to_brief?: TCandidateFitToBrief;
	isLoading?: boolean;
	expanded?: boolean;
	setShouldAllExpDisplayed?: Dispatch<SetStateAction<boolean>>;
	handleOnEdit: () => void;
}) => {
	const containerRef = useRef(null);

	const matchScorePercentage = useMemo(() => {
		if (fit_to_brief === undefined) {
			return "low";
		} else if (fit_to_brief?.score < 33) {
			return "low";
		} else if (fit_to_brief?.score < 66) {
			return "mid";
		} else {
			return "high";
		}
	}, [fit_to_brief]);

	const progressCircleColor = useMemo(() => {
		if (matchScorePercentage === "low") {
			return "#4E5555";
		} else if (matchScorePercentage === "mid") {
			return "#960";
		} else {
			return "#26734C";
		}
	}, [matchScorePercentage]);

	useEffect(() => {
		const checkExpandedStatus = () => {
			const divElem = containerRef.current as unknown as HTMLDivElement;

			if (containerRef.current && setShouldAllExpDisplayed) {
				const expandedItems = divElem?.querySelectorAll('[aria-expanded="true"]');

				if (expandedItems && expandedItems?.length > 0) {
					setShouldAllExpDisplayed(true);
				} else {
					setShouldAllExpDisplayed(false);
				}
			}
		};

		// Optionally, add a MutationObserver if accordions change dynamically
		const observer = new MutationObserver(checkExpandedStatus);
		if (containerRef.current) {
			observer.observe(containerRef.current, {
				attributes: true,
				subtree: true,
				attributeFilter: ["aria-expanded"],
			});
		}

		// Clean up observer on unmount
		return () => observer.disconnect();
	}, [setShouldAllExpDisplayed]);

	const [modalDisplay, setModalDisplay] = useState(false);

	const handleEditClick = () => {
		setModalDisplay(true);
		handleOnEdit();
	};

	return (
		<div className={styles.scoringContainer}>
			{isLoading && (
				<ContentLoader
					style={{marginTop: 10}}
					speed={2}
					height={130}
					width={"100%"}
					backgroundColor="#EEEDEC"
					foregroundColor="#e6e6e6"
				>
					<rect x="1" y="8.8" width="99%" height="22" />
					<rect x="1" y="38.8" width="96%" height="22" />
					<rect x="1" y="68.8" width="98%" height="22" />
					<rect x="1" y="98.8" width="91%" height="22" />
				</ContentLoader>
			)}

			{!isLoading &&
				fit_to_brief &&
				(fit_to_brief?.assessments === undefined ||
					fit_to_brief?.assessments?.length === 0) && (
					<div className={styles.noScore}>
						<span className={styles.title}>Not scored yet</span>
						<span className={styles.description}>
							{"Click 'Fit to Brief' to start the AI scoring process."}
						</span>
					</div>
				)}

			{!isLoading && fit_to_brief && fit_to_brief?.assessments?.length > 0 && (
				<div>
					<div
						className={classNames(styles.fitToBriefScore, {
							[styles.lowScoreMatch]: matchScorePercentage === "low",
							[styles.midScoreMatch]: matchScorePercentage === "mid",
							[styles.highScoreMatch]: matchScorePercentage === "high",
						})}
					>
						<span className={styles.title}>
							<span> Overall Score </span>

							<span
								className={styles.editIcon}
								onClick={handleEditClick}
								style={{border: `1px solid ${progressCircleColor}`}}
							>
								<AssessmentEditIcon
									color={progressCircleColor}
									width={16}
									height={16}
								/>{" "}
								Edit
							</span>
						</span>

						<span className={styles.score}>
							<span className={styles.percentage}>{fit_to_brief.score}% Match</span>

							<CircularProgress
								size={20}
								percentage={fit_to_brief.score}
								color={progressCircleColor}
							/>
						</span>
					</div>

					<div className={styles.criteriaResults} ref={containerRef} id="criteria-list">
						<AccordionAlt>
							{fit_to_brief.assessments.map((item) => (
								<AccordionAlt.AccordionItem
									key={item.criterion.name}
									title={item.criterion.name}
									expanded={expanded}
									titlePostFixChildren={
										<div
											className={classNames(styles.status, {
												[styles.yes]: item.verdict === "Yes",
												[styles.no]: item.verdict === "No",
												[styles.maybe]: item.verdict === "Maybe",
											})}
										>
											{item.verdict}

											{item.verdict === "Yes" && <YesIcon />}
											{item.verdict === "No" && <NoIcon />}
											{item.verdict === "Maybe" && <MaybeIcon />}
										</div>
									}
								>
									{item.assessment}
								</AccordionAlt.AccordionItem>
							))}
						</AccordionAlt>
					</div>
				</div>
			)}

			{fit_to_brief && (
				<div>
					<EditFitToBriefModal
						modalDisplay={modalDisplay}
						setModalDisplay={setModalDisplay}
						assessments={fit_to_brief.assessments}
					/>
				</div>
			)}
		</div>
	);
};

export default CandidateScoring;
