export const getUpdatedOrderingData = (orderingList: string[], itemKey: string): string[] => {
	const keyIncluded = orderingList.find((orderingListItem: string) =>
		orderingListItem.includes(itemKey),
	);
	let updatedOrdering = [...orderingList];

	if (!keyIncluded) {
		updatedOrdering.unshift(`-${itemKey}`);
	} else if (keyIncluded.includes("-")) {
		updatedOrdering = updatedOrdering.map((updatedOrderingItem) => {
			if (updatedOrderingItem.includes(keyIncluded)) {
				return itemKey;
			}

			return updatedOrderingItem;
		});
	} else {
		updatedOrdering = updatedOrdering.filter(
			(updatedOrderingItem) => !updatedOrderingItem.includes(keyIncluded),
		);
	}

	return updatedOrdering;
};
