import ContentLoader from "react-content-loader";

const PieChartSkeleton = () => (
	<ContentLoader
		speed={2}
		height={185}
		width={"100%"}
		backgroundColor="#DCE7E7"
		foregroundColor="#F9F9F9"
	>
		<circle cx="90" cy="90" r="80" />

		<circle cx="280" cy="20" r="7" />
		<rect x="295" y="11" rx="4" width="20%" height="18" />
		<rect x="470" y="11" rx="4" width="5%" height="18" />

		<circle cx="280" cy="45" r="7" />
		<rect x="295" y="35" rx="4" width="20%" height="18" />
		<rect x="470" y="35" rx="4" width="5%" height="18" />

		<circle cx="280" cy="70" r="7" />
		<rect x="295" y="59" rx="4" width="20%" height="18" />
		<rect x="470" y="59" rx="4" width="5%" height="18" />

		<circle cx="280" cy="95" r="7" />
		<rect x="295" y="83" rx="4" width="20%" height="18" />
		<rect x="470" y="83" rx="4" width="5%" height="18" />

		<circle cx="280" cy="120" r="7" />
		<rect x="295" y="107" rx="4" width="20%" height="18" />
		<rect x="470" y="107" rx="4" width="5%" height="18" />

		<circle cx="280" cy="145" r="7" />
		<rect x="295" y="131" rx="4" width="20%" height="18" />
		<rect x="470" y="131" rx="4" width="5%" height="18" />
	</ContentLoader>
);

export default PieChartSkeleton;
