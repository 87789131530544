import React, {FC} from "react";
import {TLinkedInBulkImportProgressBarProps} from "./types";
import styles from "./report.module.scss";

const LinkedInBulkImportReportProgressBar: FC<TLinkedInBulkImportProgressBarProps> = ({
	percentage,
	progressCount,
}) => {
	return (
		<div data-testid="progressBar">
			<p
				style={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
					gap: "5px",
				}}
			>
				Importing {percentage}%
				<progress
					value={percentage}
					max="100"
					className={styles.progress_style}
					style={{
						width:
							Number(progressCount.split("/").pop()) < 10
								? "370px"
								: Number(progressCount.split("/").pop()) > 99
								? "330px"
								: "350px",
					}}
				></progress>
				<style>
					{`
                    progress::-webkit-progress-bar {
                        background-color: #F4F0ED;
                    }

                    progress::-webkit-progress-value {
                        background-color: #0C5850;
                    }

                    progress::-moz-progress-bar {
                        background-color: #0C5850;
                    }
                `}
				</style>
				<span> {progressCount}</span>
			</p>
		</div>
	);
};

export default LinkedInBulkImportReportProgressBar;
