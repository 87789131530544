import {createSelector} from "reselect";
import {TRootState} from "types";
import {
	TProfilesImportInProgress,
	TLinkedInUrlImportItem,
} from "mapx-components/AddPeopleByLinkedIn/types";
import {projectSelector} from "../project-list/projectListSelectors";
import {companySelector} from "../company/companySelectors";
import {profilesImportsByLinkedInSelector} from "../background-tasks/backgroundTaskSelectors";

export const importLinkedInCandidateInProgressSelector = (state: TRootState) =>
	state.linkedinImport.importLinkedInCandidateInProgress;

export const showBulkImportReportSelector = (state: TRootState) =>
	state.linkedinImport.showBulkImportReport;

export const processedImportsSelectors = (state: TRootState) =>
	state.linkedinImport.processedImports;

export const showLastBulkImportReportSelector = (state: TRootState) =>
	state.linkedinImport.showLastBulkImportReport;

export const showLastBulkImportReportForCompanySelector = (state: TRootState) =>
	state.linkedinImport.showLastBulkImportReportForCompany;

export const isModalDisplayedForProject = (state: TRootState) =>
	state.linkedinImport.activeAddPeopleModalForProject;

export const activeImportInfoForCompanySelector = (state: TRootState) =>
	state.linkedinImport.bulkImportInfoOfCompany;

export const lastCompletedCandidateImportByLinkedInForProjectSelector = (state: TRootState) =>
	state.linkedinImport.lastCompletedCandidateImportByLinkedInForProject;

export const candidatesImportForCompanySelector = (state: TRootState) =>
	state.linkedinImport.candidateImportByLinkedInForCompany;

const getImportData = (importInfo: TProfilesImportInProgress[], importID: Nullable<number>) => {
	return importInfo?.find(
		(importData) => importData.purpose === "import" && importData?.project_id === importID,
	);
};

const getImportOrRefreshData = (
	importInfo: TProfilesImportInProgress[],
	importID: Nullable<number>,
) => {
	return importInfo?.find((importData) => importData?.project_id === importID);
};

export const currentProjectBulkImportURLsSelector = createSelector(
	[profilesImportsByLinkedInSelector, projectSelector],
	(
		candidateImportsByLinkedInForProject: TProfilesImportInProgress[],
		project,
	): Nullable<TLinkedInUrlImportItem[]> => {
		const projectImportData = getImportData(candidateImportsByLinkedInForProject, project?.id);

		if (!projectImportData || !projectImportData?.linkedin_candidate_urls) {
			return null;
		}

		return projectImportData.linkedin_candidate_urls.map((urlData: TLinkedInUrlImportItem) => ({
			reason: urlData.reason,
			url: urlData.url,
			status: urlData.status,
		}));
	},
);

export const currentCompanyBulkImportURLsSelector = createSelector(
	[profilesImportsByLinkedInSelector, activeImportInfoForCompanySelector, companySelector],
	(
		candidateImportsByLinkedInForProject: TProfilesImportInProgress[],
		activeImport,
		currentCompany,
	): Nullable<TLinkedInUrlImportItem[]> => {
		const companyImportData = getImportData(candidateImportsByLinkedInForProject, null);

		if (!companyImportData || !companyImportData?.linkedin_candidate_urls) {
			return null;
		}

		if (activeImport?.id !== currentCompany?.id) {
			return null;
		}

		return companyImportData.linkedin_candidate_urls.map((urlData) => ({
			reason: urlData.reason,
			url: urlData.url,
			status: urlData.status,
		}));
	},
);

export const isImportInProgressForCurrentProject = createSelector(
	[processedImportsSelectors, projectSelector, profilesImportsByLinkedInSelector],
	(processedImportsList, project, profilesImportsByLinkedIn) => {
		const projectImportData = getImportData(profilesImportsByLinkedIn, project?.id);
		const hasInProgressImport = projectImportData && projectImportData.status !== "waiting";

		return (
			(processedImportsList && processedImportsList.includes(project?.id)) ||
			hasInProgressImport
		);
	},
);

export const isImportOrRefreshInProgressForCurrentProject = createSelector(
	[processedImportsSelectors, projectSelector, profilesImportsByLinkedInSelector],
	(processedImportsList, project, profilesImportsByLinkedIn) => {
		const projectImportData = getImportOrRefreshData(profilesImportsByLinkedIn, project?.id);
		const hasInProgressImport = projectImportData && projectImportData.status !== "waiting";

		return (
			(processedImportsList && processedImportsList.includes(project?.id)) ||
			hasInProgressImport
		);
	},
);

export const isImportInProgressForCurrentCompany = createSelector(
	[
		processedImportsSelectors,
		profilesImportsByLinkedInSelector,
		activeImportInfoForCompanySelector,
		companySelector,
	],
	(processedImportsList, profilesImportsByLinkedIn, activeImport, company) => {
		if (!activeImport || activeImport.id !== company?.id) {
			return false;
		}

		const companyImportData = getImportData(profilesImportsByLinkedIn, null);
		const isImportInProgress =
			companyImportData?.status && companyImportData.status !== "waiting";

		return processedImportsList?.includes(0) || isImportInProgress;
	},
);

export const activeImportPercentageForCompanySelector = createSelector(
	[profilesImportsByLinkedInSelector],
	(profilesImportsByLinkedIn) => {
		const companyImportData = getImportData(profilesImportsByLinkedIn, null);

		if (companyImportData) {
			return companyImportData.progress;
		} else {
			return 100;
		}
	},
);

export const isImportInProgressForNonCurrentCompany = createSelector(
	[
		processedImportsSelectors,
		profilesImportsByLinkedInSelector,
		activeImportPercentageForCompanySelector,
		activeImportInfoForCompanySelector,
		companySelector,
	],
	(
		processedImportsList,
		profilesImportsByLinkedIn,
		activeImportPercentage,
		activeImport,
		company,
	) => {
		if (!activeImport || activeImport.id === company?.id) {
			return false;
		}

		const companyImportData = getImportData(profilesImportsByLinkedIn, null);
		const isImportInProgress =
			companyImportData?.status && companyImportData.status !== "waiting";

		return (
			(processedImportsList?.includes(0) &&
				activeImportPercentage &&
				activeImportPercentage < 100) ||
			isImportInProgress
		);
	},
);

export const activeImportPercentageForProject = createSelector(
	[profilesImportsByLinkedInSelector, projectSelector],
	(profilesImportsByLinkedIn, project) => {
		const projectImportData = getImportData(profilesImportsByLinkedIn, project?.id);

		if (projectImportData) {
			return projectImportData.progress;
		} else {
			return 100;
		}
	},
);

export const showBulkImportReportForProjectSelector = createSelector(
	[showBulkImportReportSelector, projectSelector],
	(showReport, project) => {
		if (showReport) {
			return showReport[project?.id] ?? false;
		} else {
			return false;
		}
	},
);

export const showBulkImportReportForCompanySelector = createSelector(
	[showBulkImportReportSelector],
	(showReport) => {
		if (showReport) {
			return showReport[0] ?? false;
		} else {
			return false;
		}
	},
);
