import {useEffect, useState} from "react";

const CircularProgress = ({
	size = 100,
	strokeWidth = 2,
	percentage = 0,
	color = "#996600",
	displayTextAtCenter = false,
	duration = 1,
}) => {
	const [targetPercentage, setTargetPercentage] = useState(0);
	const radius = (size - strokeWidth) / 2;
	const circumference = 2 * Math.PI * radius;
	const offset = circumference - (targetPercentage / 100) * circumference;

	useEffect(() => {
		const increment = 1;
		const intervalDuration = (duration * 1000) / (percentage / increment);

		const interval = setInterval(() => {
			setTargetPercentage((prev) => {
				if (prev >= percentage) {
					clearInterval(interval);

					return percentage;
				}

				return prev + increment;
			});
		}, intervalDuration);

		return () => clearInterval(interval); // Clean up interval on component unmount
	}, [duration, percentage]);

	return (
		<svg width={size} height={size} viewBox={`0 0 ${size} ${size}`}>
			<circle
				cx={size / 2}
				cy={size / 2}
				r={radius}
				stroke={color}
				strokeOpacity="0.1"
				strokeWidth={strokeWidth}
				fill="none"
			/>
			{/* Progress circle */}
			<circle
				cx={size / 2}
				cy={size / 2}
				r={radius}
				stroke={color}
				strokeWidth={strokeWidth}
				fill="none"
				strokeDasharray={circumference}
				strokeDashoffset={offset}
				strokeLinecap="round"
				transform={`rotate(-90 ${size / 2} ${size / 2})`}
				style={{
					transition: `stroke-dashoffset 0.1s ease-in-out`,
				}}
			/>
			{displayTextAtCenter && (
				<text
					x="50%"
					y="50%"
					dy=".3em"
					textAnchor="middle"
					fontSize={size * 0.2}
					fill={color}
				>
					{`${percentage}%`}
				</text>
			)}
		</svg>
	);
};

export default CircularProgress;
