import Select from "react-select";
import AsyncSelect from "react-select/async";
import css from "./index.module.scss";
import {TSelectDropdownProps} from "components/Inputs/SelectDropdown/types";
import {DropdownIndicator} from "components/Inputs/SelectDropdown/DropdownIndicator";
import {CSSProperties} from "react";

const SelectDropdown = ({
	// isMulti = false,
	customClass = "",
	customStyle = {},
	isAsync = false,
	borderColor = "#dadada",
	placeHolderFontColor = "#B0B5B4",
	hideIndicator = false,
	hidePlaceholder = false,
	themeColor = "#0c5850",
	noOptionsMessage = "No Options",
	noResultsText = "No Results Found!",
	dropdownIndicatorChild,
	hasAddButton = false,
	...props
}: TSelectDropdownProps) => {
	const customStyles = {
		menuPortal: (provided: CSSProperties) => ({
			...provided,
			zIndex: 101,
		}),
		control: (
			base: Record<string, unknown>,
			state: {
				isMulti: boolean;
			},
		) => ({
			...base,
			border:
				props.errorText !== undefined && props.errorText
					? "1px solid red"
					: `1px solid ${borderColor} !important`,
			height: !state.isMulti ? "40px" : "auto",
			borderRadius: "4px",
			boxShadow: "0px 17px 33px -2px rgba(79, 75, 74, 0.05)",
			fontSize: 14,
			fontWeight: 500,
			...customStyle,
		}),
		menu: (provided: Record<string, unknown>) => ({
			...provided,
			// maxHeight: "200px",
			overflowY: "auto",
			cursor: "pointer",
			zIndex: 101,
		}),
		indicatorSeparator: () => ({display: hideIndicator ? "none" : "initial"}),
		dropdownIndicator: (
			provided: Record<string, unknown>,
			state: {selectProps: {menuIsOpen: boolean}},
		) => ({
			...provided,
			transform: state.selectProps.menuIsOpen && !dropdownIndicatorChild && "rotate(180deg)",
			transition: "transform 0.2s linear",
		}),
		placeholder: (base: Record<string, unknown>) => ({
			...base,
			display: hidePlaceholder ? "none" : "flex",
			fontSize: "14px",
			lineHeight: "20px",
			color: placeHolderFontColor,
		}),
		valueContainer: (provided: Record<string, unknown>) => ({
			...provided,
			// display: "flex",
			gap: "8px",
			paddingRight: hasAddButton ? "68px" : "8px",
		}),
		multiValue: (provided: Record<string, unknown>) => ({
			...provided,
			backgroundColor: "#F8F7F7",
			display: "flex",
			gap: "2px",
			borderRadius: "3px",
			margin: 0,
		}),
		option: (
			provided: Record<string, unknown>,
			state: {
				isDisabled: boolean;
				isSelected: boolean;
				isFocused: boolean;
			},
		) => ({
			...provided,
			fontSize: "14px",
			lineHeight: "24px",
			color: state.isDisabled
				? null
				: state.isSelected
				? "#000"
				: state.isFocused
				? themeColor
				: "#000",
			backgroundColor: state.isDisabled ? null : state.isSelected ? "#fff" : null,
			":hover": {
				backgroundColor: state.isDisabled
					? null
					: state.isSelected
					? themeColor
					: "#EEEEEE",
				color: "#EEEEEE",
			},
		}),
	};

	return isAsync ? (
		<AsyncSelect
			{...props}
			components={{
				DropdownIndicator: (props) => (
					<DropdownIndicator hideIndicator={hideIndicator} {...props}>
						{dropdownIndicatorChild}
					</DropdownIndicator>
				),
			}}
			className={[css.select, customClass ? customClass : ""].join(" ")}
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			styles={customStyles}
			noOptionsMessage={(input) =>
				input?.inputValue.length > 3 ? noResultsText : noOptionsMessage
			}
			getOptionLabel={(option: Record<string, string>) => option?.label || option.name}
			getOptionValue={(option: Record<string, string>) => option?.value || option.id}
		/>
	) : (
		<Select
			id="UFIInputContainer"
			{...props}
			components={{
				DropdownIndicator: (props) => (
					<DropdownIndicator hideIndicator={hideIndicator} {...props}>
						{dropdownIndicatorChild}
					</DropdownIndicator>
				),
			}}
			className={[css.select, customClass ? customClass : ""].join(" ")}
			noOptionsMessage={(input) =>
				input?.inputValue.length > 3 ? noResultsText : noOptionsMessage
			}
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			styles={customStyles}
			// cacheOptions
			// defaultOptions
			getOptionLabel={(option: Record<string, string>) => option?.label || option.name}
			getOptionValue={(option: Record<string, string>) => option?.value || option.id}
		/>
	);
};

export default SelectDropdown;
