import React, {FC, useMemo} from "react";
import {TLinkedInBulkImportReportProps} from "./types";
import {Button} from "components";
import classNames from "classnames";
import {BiErrorCircle, BiRefresh} from "react-icons/bi";
import {projectSelector} from "store/mapx/project-list/projectListSelectors";
import LinkedInBulkImportReportProgressBar from "mapx-components/Modals/SearchByLinkedInModal/Report/progressBar";
import {useAppSelector} from "hooks";
import LinkedInBulkImportReportToolTip from "mapx-components/Modals/SearchByLinkedInModal/Report/Tooltip";
import styles from "./report.module.scss";
import DownloadIcon from "assets/icons/Download";
import {CalculateStatusTypeCounts, STATUS_COLOR_MAP, STATUS_MESSAGES} from "./utils";
import ExclamatoryIcon from "assets/icons/ExclamatoryIcon";
import {TLinkedInUrlImportItem} from "mapx-components/AddPeopleByLinkedIn/types";
import {Loader} from "components";

const LinkedInBulkImportReport: FC<TLinkedInBulkImportReportProps> = ({
	linkedInUrl,
	handleClick,
	handleDownload,
	inProgress,
	lastCompletedDisplay,
	importedLinkedInUrls,
	totalURLs,
	source,
	isLastBulkImportDataLoading,
	importPercentage,
	loadingImportHistoryDownload,
}) => {
	const project = useAppSelector(projectSelector);

	const counts = useMemo(() => {
		return CalculateStatusTypeCounts(linkedInUrl);
	}, [linkedInUrl]);

	const progressCountDisplayString = useMemo(() => {
		const total = linkedInUrl?.length || 0;
		const nonProgressing = linkedInUrl?.filter(
			({status}) =>
				!["waiting", "importing", "scraping", "in progress", "created", "scraped"].includes(
					status?.toLowerCase(),
				),
		).length;

		return `${nonProgressing}/${total}`;
	}, [linkedInUrl]);

	const getStatusColor = (status: string) => STATUS_COLOR_MAP[status.toLowerCase()] || "#a4a4a4";
	const getStatusMessage = (status: string) => STATUS_MESSAGES[status.toLowerCase()] || status;

	const renderToolTip = (urlInfo: TLinkedInUrlImportItem) => {
		const color = getStatusColor(urlInfo.status);
		const tooltipStatuses = ["bad url", "not found", "import error", "scraping error"];

		if (!color || !tooltipStatuses.includes(urlInfo.status.toLowerCase())) {
			return null;
		}

		return (
			<LinkedInBulkImportReportToolTip
				color={color}
				status={urlInfo.status}
				reason={urlInfo?.reason}
				url={urlInfo.url}
			/>
		);
	};

	const renderUniqueUrlMessage = () =>
		linkedInUrl && (
			<>
				Out of <strong style={{color: "#2C3030"}}>{totalURLs}</strong> Profiles,{" "}
				<strong style={{color: "#2C3030"}}>
					{linkedInUrl.length - (counts.badURL + counts.notFound)}
				</strong>{" "}
				unique and valid URLs found.
				<br />
				<br />
			</>
		);

	return (
		<div className={styles.reportContainer}>
			<h3>Add People</h3>
			<hr />
			{linkedInUrl && linkedInUrl.length > 0 && counts.finished === linkedInUrl.length ? (
				<section style={{marginBottom: "20px"}}>
					<div className={styles.header} data-testid={"LinkedInBulkImportReport"}>
						{counts.error === 0 ? (
							<>
								<ExclamatoryIcon />
								<p style={{color: "#0C5850FF"}}>
									Imports {lastCompletedDisplay ? "were" : "are"} completed
								</p>
							</>
						) : (
							<>
								<BiErrorCircle size={"20px"} color={"#F5A300"} />
								<p style={{color: "#F5A300", fontWeight: "500"}}>
									Imports {lastCompletedDisplay ? "were" : "are"} completed with
									errors
								</p>
							</>
						)}
					</div>
					<p className={counts.error === 0 ? styles.successCount : styles.errorCount}>
						{totalURLs > linkedInUrl.length - counts.badURL - counts.notFound &&
							renderUniqueUrlMessage()}
						<strong>{counts.success}</strong> Profiles successfully{" "}
						{source === "project" ? (
							<>
								added to your <strong>{project?.name}</strong> project
							</>
						) : (
							"imported"
						)}
					</p>
				</section>
			) : (
				inProgress && (
					<LinkedInBulkImportReportProgressBar
						percentage={importPercentage}
						progressCount={progressCountDisplayString}
					/>
				)
			)}

			<div className={styles.reportBox}>
				{linkedInUrl &&
					linkedInUrl.map((urlInfo) => (
						<div className={styles.reportItems} key={urlInfo.url}>
							<p style={{width: "200px"}}>{`/${urlInfo.url.split("/").pop()}`}</p>
							<div
								className={styles.statuses}
								style={{color: getStatusColor(urlInfo.status)}}
							>
								{renderToolTip(urlInfo)}
								<span className={styles.status}>
									{getStatusMessage(urlInfo.status)}
								</span>
							</div>
						</div>
					))}
			</div>

			{importPercentage === 100 && !isLastBulkImportDataLoading && (
				<div className={styles.downloadReport} data-testid={"DownloadReportButton"}>
					{inProgress && (
						<a onClick={() => handleDownload(linkedInUrl)}>
							<DownloadIcon /> <span>Download CSV report</span>
						</a>
					)}
					{importedLinkedInUrls && importedLinkedInUrls?.length > 0 && (
						<a
							onClick={() => !loadingImportHistoryDownload && handleDownload(null)}
							className={classNames(styles.downloadAllReport, {
								[styles.disabled]: loadingImportHistoryDownload,
							})}
						>
							{loadingImportHistoryDownload ? (
								<Loader type={"TailSpin"} height={"13px"} width={"12px"} />
							) : (
								<DownloadIcon />
							)}
							<span>Download all imports in CSV</span>
						</a>
					)}
				</div>
			)}

			<Button
				name={"Done"}
				type={"button"}
				data-testid={"DoneButton"}
				className={classNames(styles.addButton)}
				onClick={() => handleClick(project?.id ?? 0)}
				disabled={importPercentage !== 100 || isLastBulkImportDataLoading}
			>
				{importPercentage !== 100 && <BiRefresh size={22} className={styles.refresh} />}{" "}
				{importPercentage === 100
					? `Add ${linkedInUrl && linkedInUrl.length > 0 ? "more" : ""} people`
					: "Importing"}
			</Button>
		</div>
	);
};

export default LinkedInBulkImportReport;
