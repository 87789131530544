import {createSelector} from "reselect";
import {
	companySpecialitiesConnectivityLogicSelector,
	companySpecialtiesAndSelector,
	companySpecialtiesNotSelector,
	companySpecialtiesOrSelector,
} from "./filterSelectors";
import {TRootState} from "types";
import {removeDuplicatesFromArray} from "helpers/filterHandlers";

export const specialtyClusterSearchQuerySelector = (state: TRootState) =>
	state.search.specialtyClusterSearchQuery;
export const companySpecialityClusterFilterKeyByLogic = createSelector(
	(_: string, logic: string) => logic,
	(logic) => {
		switch (logic) {
			case "not":
				return "specialties_not";
			case "or":
				return "specialties_or";
			case "and":
			default:
				return "specialties_and";
		}
	},
);

export const companySpecialtyClusterByLogicSelector = createSelector(
	[
		companySpecialtiesAndSelector,
		companySpecialtiesOrSelector,
		companySpecialtiesNotSelector,
		(_: string, logic: string) => logic,
	],
	(andSpecialties, orSpecialties, notSpecialties, logic) => {
		switch (logic) {
			case "and":
				return andSpecialties || [];
			case "or":
				return orSpecialties || [];
			case "not":
				return notSpecialties || [];
			default:
				return [];
		}
	},
);

export const allSelectedCompanySpecialtiesByLogicSelector = createSelector(
	[
		companySpecialtiesAndSelector,
		companySpecialtiesOrSelector,
		companySpecialtiesNotSelector,
		companySpecialitiesConnectivityLogicSelector,
	],
	(andSpecialties, orSpecialties, notSpecialties, logic) => {
		switch (logic) {
			case "or":
				return removeDuplicatesFromArray(orSpecialties) as number[];
			case "and":
				return removeDuplicatesFromArray(andSpecialties) as number[];
			case "not":
				return removeDuplicatesFromArray(notSpecialties) as number[];
			default:
				return [];
		}
	},
);
