import type {IconProps} from "./types";

const PencilIcon = (props: IconProps) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={20}
			height={20}
			viewBox="0 0 20 20"
			fill="none"
			{...props}
		>
			<mask
				id="a"
				width={20}
				height={20}
				x={0}
				y={0}
				maskUnits="userSpaceOnUse"
				style={{
					maskType: "alpha",
				}}
			>
				<path fill="#D9D9D9" d="M0 0h20v20H0z" />
			</mask>
			<g mask="url(#a)">
				<path
					fill="#2C3030"
					d="M4.25 15.75h1.23l7-7-1.23-1.23-7 7v1.23Zm11.938-8.208-3.73-3.73 1.021-1.02c.347-.348.76-.521 1.24-.521.479 0 .892.173 1.24.52l1.25 1.25c.333.334.5.747.5 1.24s-.167.906-.5 1.24l-1.021 1.02Zm-1.23 1.229L6.23 17.5H2.5v-3.73l8.73-8.728 3.728 3.729Z"
				/>
			</g>
		</svg>
	);
};

export default PencilIcon;
