import {removeDuplicatesFromArray} from "helpers/filterHandlers";
import {flatMap} from "lodash";
import {createSelector} from "reselect";
import {countryOptionsSelector} from "../search/searchSelectors";
import {targetListCompaniesSelector} from "../target-list/targetListSelectors";

export const activeAccordionOnFilterSelector = (state) => state.filter.activeAccordionOnFilter;

export const selectedCandidatesSelector = (state) => state.filter.selectedCandidates;

export const selectedCandidatesInProgressSelector = (state) =>
	state.filter.selectedCandidatesInProgress;

export const searchedCandidatesInProgressSelector = (state) =>
	state.filter.searchedCandidatesInProgress;

export const searchedCompaniesInProgressSelector = (state) =>
	state.filter.searchedCompaniesInProgress;

export const aiCompanyIdeaFilterModeSelector = (state) => state.filter.ai_company_idea_filter_mode;

export const candidateJobTitleFilterModeSelector = (state) => state.filter.job_title_filter_mode;
export const candidateSelectedJobTitlesSelector = (state) =>
	state.filter.candidate_filters.current_job_titles_or || [];
export const candidateLinkedInUrlSelector = (state) => state.filter.candidate_filters.linkedin_url;

export const candidateCountriesSelector = (state) => state.filter.candidate_filters.countries;

export const candidateCountryRegionsSelector = (state) => state.filter.candidate_filters.regions;

export const companyCountriesSelector = (state) => state.filter.company_filters.countries;

export const companyHQCountriesSelector = (state) =>
	state.filter.company_filters.headquarters_countries;

export const specialtySearchQuerySelector = (state) => state.filter.specialtySearchQuery;

export const selectedCompaniesSelector = (state) => state.filter.selected_companies || [];

export const searchedCompaniesSelector = (state) => state.filter.searchedCompanies?.results || [];
export const searchedCompaniesPaginationSelector = (state) =>
	state.filter.searchedCompanies?.pagination || [];

export const anyCompaniesSelector = (state) => state.filter.candidate_filters.companies;

export const currentCompaniesSelector = (state) => state.filter.candidate_filters.current_companies;

export const previousCompaniesSelector = (state) =>
	state.filter.candidate_filters.previous_companies;

export const candidateAnyTLCompaniesSelector = (state) =>
	state.filter.candidate_filters.tl_companies;

export const candidateCurrentTLCompaniesSelector = (state) =>
	state.filter.candidate_filters.current_tl_companies;

export const candidatePreviousTLCompaniesSelector = (state) =>
	state.filter.candidate_filters.previous_tl_companies;

export const companyIndustriesSelector = (state) => state.filter.company_filters.industries;

export const currentSenioritySelector = (state) => state.filter.candidate_filters.current_seniority;

export const candidateLanguagesSelector = (state) => state.filter.candidate_filters.languages;

export const companySizesSelector = (state) => state.filter.company_filters.size;
export const companyRevenuesSelector = (state) => state.filter.company_filters.revenue;

export const companyIndexesSelector = (state) => state.filter.company_filters.indexes;

export const candidateCompanyPositionSelector = (state) =>
	state.filter.candidate_filters_position.companies;

export const candidateTargetListToggleSelector = (state) =>
	state.filter.candidate_filters_position.target_list_toggle;

export const candidateTargetListPositionSelector = (state) =>
	state.filter.candidate_filters_position.target_list;

export const candidateTLCompaniesByPositionSelector = createSelector(
	[
		candidateCurrentTLCompaniesSelector,
		candidatePreviousTLCompaniesSelector,
		candidateAnyTLCompaniesSelector,
		(_, position) => position,
	],
	(currentTLCompanies, previousTLCompanies, anyTLCompanies, position) => {
		switch (position) {
			case "current":
				return currentTLCompanies;
			case "previous":
				return previousTLCompanies;
			case "any":
				return anyTLCompanies;
			default:
				return [];
		}
	},
);

export const showEnhancedCandidatesSelector = (state) => state.filter.enhanceCandidatesResult;

export const candidateFiltersSelector = (state) => state.filter.candidate_filters;

export const searchedCandidateIdsFilterSelector = (state) =>
	state.filter.candidate_filters.candidates;

export const showEnhancedCompaniesSelector = (state) => state.filter.enhanceCompaniesResult;

export const companyFiltersSelector = (state) => state.filter.company_filters;

export const allCompaniesCountSelector = createSelector(
	[currentCompaniesSelector, previousCompaniesSelector, anyCompaniesSelector],
	(current, previous, any) => current.length + previous.length + any.length,
);

export const targetListCompaniesByPositionSelector = createSelector(
	[
		candidateCurrentTLCompaniesSelector,
		candidatePreviousTLCompaniesSelector,
		candidateAnyTLCompaniesSelector,
		(_, position) => position,
	],
	(currentTLCompanies, previousTLCompanies, anyTLCompanies, position) => {
		switch (position) {
			case "current":
				return currentTLCompanies;
			case "previous":
				return previousTLCompanies;
			case "any":
				return anyTLCompanies;
			default:
				return [];
		}
	},
);

export const companiesByPositionSelector = createSelector(
	[
		currentCompaniesSelector,
		previousCompaniesSelector,
		anyCompaniesSelector,
		candidateCompanyPositionSelector,
		(_, position) => position,
	],
	(currentCompanies, previousCompanies, anyCompanies, position, manuallyDefinedPosition) => {
		switch (manuallyDefinedPosition || position) {
			case "current":
				return currentCompanies;
			case "previous":
				return previousCompanies;
			case "any":
				return anyCompanies;
			default:
				return [];
		}
	},
);

export const tlCompaniesByTargetListPositionSelector = createSelector(
	[
		candidateCurrentTLCompaniesSelector,
		candidatePreviousTLCompaniesSelector,
		candidateAnyTLCompaniesSelector,
		candidateTargetListPositionSelector,
	],
	(current, previous, any, position) => {
		switch (position) {
			case "current":
				return current;
			case "previous":
				return previous;
			case "any":
				return any;
			default:
				return [];
		}
	},
);

export const companiesFilterKeyByPositionSelector = createSelector(
	(_, position) => position,
	(position) => {
		switch (position) {
			case "current":
				return "current_companies";
			case "previous":
				return "previous_companies";
			case "any":
			default:
				return "companies";
		}
	},
);

export const tlCompaniesFilterKeyByPositionSelector = createSelector(
	(_, position) => position,
	(position) => {
		switch (position) {
			case "current":
				return "current_tl_companies";
			case "previous":
				return "previous_tl_companies";
			case "any":
			default:
				return "tl_companies";
		}
	},
);

export const isTargetListExpandedSelector = createSelector(
	[
		targetListCompaniesSelector,
		candidateCurrentTLCompaniesSelector,
		candidatePreviousTLCompaniesSelector,
		candidateAnyTLCompaniesSelector,
	],
	(targetListCompanies, current, previous, any) => {
		const companies = [...current, ...previous, ...any];

		return targetListCompanies.length > 0 && companies.length > 0;
	},
);

export const targetListFilterCountSelector = createSelector(
	[
		candidateCurrentTLCompaniesSelector,
		candidatePreviousTLCompaniesSelector,
		candidateAnyTLCompaniesSelector,
	],
	(current, previous, any) => current.length + previous.length + any.length,
);

export const selectedTargetListCompaniesSelector = createSelector(
	tlCompaniesByTargetListPositionSelector,
	(targetListCompanies) => new Set(targetListCompanies.map((c) => c.id)),
);

export const flattenCountriesSelector = createSelector(
	[countryOptionsSelector],
	(continentCountries) => flatMap(continentCountries, "countries"),
);

export const filterConnectivityLogicOptionsSelector = createSelector([], () => {
	return [
		{label: "AND", value: "and"},
		{label: "OR", value: "or"},
		{label: "NOT", value: "not"},
	];
});

export const filterPositionOptionsSelector = createSelector(
	(state) => state,
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	(_) => {
		return [
			{label: "Current", value: "current"},
			{label: "Previous", value: "previous"},
			{label: "Any", value: "any"},
		];
	},
);

// COMPANY SPECIALITY LOGIC SELECTION

export const specialitiesSelector = (state) => state.filter.company_filters.specialties;

export const companySpecialitiesConnectivityLogicSelector = (state) =>
	state.filter.company_speciality_selected_connectivity;

export const companySpecialtiesOrSelector = (state) => state.filter.company_filters.specialties_or;

export const companySpecialtiesAndSelector = (state) =>
	state.filter.company_filters.specialties_and;

export const companySpecialtiesNotSelector = (state) =>
	state.filter.company_filters.specialties_not;

export const companySpecialitiesFilterKeyByLogic = createSelector(
	(_, logic) => logic,
	(logic) => {
		switch (logic) {
			case "not":
				return "specialties_not";
			case "or":
				return "specialties_or";
			case "and":
			default:
				return "specialties_and";
		}
	},
);

export const specialtiesByLogicSelector = createSelector(
	[
		companySpecialtiesAndSelector,
		companySpecialtiesOrSelector,
		companySpecialtiesNotSelector,
		(_, logic) => logic,
	],
	(andSpecialties, orSpecialties, notSpecialties, logic) => {
		switch (logic) {
			case "and":
				return andSpecialties || [];
			case "or":
				return orSpecialties || [];
			case "not":
				return notSpecialties || [];
			default:
				return [];
		}
	},
);

export const allSelectedSpecialtiesSelector = createSelector(
	[companySpecialtiesAndSelector, companySpecialtiesOrSelector, companySpecialtiesNotSelector],
	(andSpecialties, orSpecialties, notSpecialties) => {
		return removeDuplicatesFromArray([...andSpecialties, ...orSpecialties, ...notSpecialties]);
	},
);

export const allCompanySpecialtiesCountSelector = createSelector(
	[companySpecialtiesAndSelector, companySpecialtiesOrSelector, companySpecialtiesNotSelector],
	(and, or, not) => (and?.length || 0) + (or?.length || 0) + (not?.length || 0) || 0,
);
