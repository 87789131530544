import {ChangeEvent, memo, useCallback, useState} from "react";
import useDebouncedEffect from "use-debounced-effect";
import {useSelector} from "react-redux";
import {Checkbox, CheckboxSkeletonLoader, ResetFilters} from "components";
import {SearchInput} from "mapx-components";
import useFetchListOptions from "hooks/useFetchListOptions";
import {getJobFunctions} from "store/mapx/search/searchAsyncActions";
import {getName} from "helpers/string";
import {jobFunctionOptionsSelector} from "store/mapx/search/searchSelectors";
import CheckboxList from "mapx-components/Inputs/CheckboxList";
import ExpandableArrow from "mapx-components/ExpandableArrow";

import {getFunctionExpandableData} from "./utils";
import {STJobFunction} from "api/filterOptionsApi/JobFunctionApi/types";
import {TGetExpandableDataResult} from "types";
import {TFunctionNSpecialismSearchFilterProps} from "./types";
import {arrayIntersected} from "helpers/array";

function JobFunctionSearchFilter({
	handleResetClick,
	selectedSpecialismIds,
	handleOnChange,
}: TFunctionNSpecialismSearchFilterProps) {
	const [expanded, setExpanded] = useState<TGetExpandableDataResult>({});

	const [, setSearchQuery] = useState<null | string>(null);

	const jobFunctionOptions = useSelector(jobFunctionOptionsSelector);

	const {filteredData, searchTerm, onFilterChanged, loading} = useFetchListOptions({
		options: jobFunctionOptions,
		callbackMethod: getJobFunctions,
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		childFilterKey: "specialisms",
	});

	useDebouncedEffect(
		() => {
			if (!searchTerm) {
				setExpanded({});

				return;
			}

			const expandableFilteredData = getFunctionExpandableData(filteredData);

			if (expandableFilteredData) {
				setExpanded(expandableFilteredData);
			}
		},
		500,
		[searchTerm],
	);

	const expand = useCallback(
		(id: number) => {
			setExpanded({
				...expanded,
				[id]: !expanded[id],
			});
		},
		[expanded],
	);

	const handleSearchChange = useCallback(
		(e: ChangeEvent<HTMLInputElement>) => {
			onFilterChanged(e);

			setSearchQuery(e.target.value);
		},
		[onFilterChanged],
	);

	const specialismIdsByJobFunction = useCallback((jobFunction: STJobFunction) => {
		return jobFunction?.specialisms?.map(({id}) => id);
	}, []);

	const hasJobFunctionSelected = useCallback(
		(jobFunction: STJobFunction) =>
			arrayIntersected(selectedSpecialismIds, specialismIdsByJobFunction(jobFunction)),
		[selectedSpecialismIds, specialismIdsByJobFunction],
	);

	const hasJobFunctionPartiallySelected = useCallback(
		(jobFunction: STJobFunction) => {
			return (
				!hasJobFunctionSelected(jobFunction) &&
				jobFunction.specialisms.some((s) => selectedSpecialismIds.includes(s.id))
			);
		},
		[hasJobFunctionSelected, selectedSpecialismIds],
	);

	const handleFunctionChange = useCallback(
		(jobFunction: STJobFunction, checked: boolean) => {
			handleOnChange("current_job_functions", jobFunction.id);
			handleOnChange(
				"current_specialisms",
				jobFunction.specialisms.map((item) => item.id).join(","),
				checked,
			);
		},
		[handleOnChange],
	);

	const handleSpecialismChange = useCallback(
		(specialismID: number, checked: boolean) => {
			handleOnChange("current_specialisms", String(specialismID), checked);
		},
		[handleOnChange],
	);

	return (
		<div>
			<SearchInput
				type="text"
				placeholder="Search for a Function or Specialism"
				onChange={handleSearchChange}
				errorText={undefined}
				errorClass={undefined}
				isLoading={loading}
			/>

			<ResetFilters
				parentStyle={{color: "#5A5A5A", marginRight: 19}}
				onClick={handleResetClick}
				displayIcon={true}
			>
				Clear Selection
			</ResetFilters>

			<CheckboxList>
				{filteredData.map((f: STJobFunction) => {
					const isJobFunctionSelected = hasJobFunctionSelected(f);
					const isJobFunctionPartiallySelected = hasJobFunctionPartiallySelected(f);

					return (
						<CheckboxList.Accordion key={f.id}>
							<CheckboxList.AccordionHeader>
								<ExpandableArrow
									onClick={() => expand(f.id)}
									rotated={!expanded[f.id]}
								/>

								<Checkbox
									borderColor="#0C5850"
									isChecked={isJobFunctionSelected}
									key={f.id}
									label={`${getName(f.name)} (${f.specialisms.length})`}
									onChange={(e: {target: {checked: boolean}}) =>
										handleFunctionChange(f, e.target.checked)
									}
									value={f.id.toString()}
									partiallySelected={isJobFunctionPartiallySelected}
								/>
							</CheckboxList.AccordionHeader>

							<CheckboxList.AccordionContent expanded={expanded[f.id]}>
								{f.specialisms.map((s) => (
									<Checkbox
										borderColor="#0C5850"
										isChecked={selectedSpecialismIds?.includes(s.id)}
										key={s.id}
										label={getName(s.name)}
										onChange={(e: {target: {checked: boolean}}) =>
											handleSpecialismChange(s.id, e.target.checked)
										}
										value={getName(s.name)}
									/>
								))}
							</CheckboxList.AccordionContent>
						</CheckboxList.Accordion>
					);
				})}
			</CheckboxList>

			{(loading || jobFunctionOptions.length === 0) && <CheckboxSkeletonLoader />}
		</div>
	);
}

export default memo(JobFunctionSearchFilter);
