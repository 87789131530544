import {noop} from "lodash";
import type {FC} from "react";

import type {IconProps} from "./types";

const RecycleBinLine: FC<IconProps> = ({onClick = noop, className = ""}) => (
	<svg
		width="24"
		height="24"
		fill="none"
		onClick={onClick}
		viewBox="0 0 24 24"
		className={className}
		xmlns="http://www.w3.org/2000/svg"
	>
		<mask
			x="0"
			y="0"
			width="24"
			height="24"
			id="mask0_306_9529"
			maskUnits="userSpaceOnUse"
			style={{maskType: "alpha"}}
		>
			<rect width="24" height="24" fill="#D9D9D9" />
		</mask>
		<g mask="url(#mask0_306_9529)">
			<path
				fill="#C91515"
				d="M9.4 16.5L12 13.9L14.6 16.5L16 15.1L13.4 12.5L16 9.9L14.6 8.5L12 11.1L9.4 8.5L8 9.9L10.6 12.5L8 15.1L9.4 16.5ZM7 21C6.45 21 5.97933 20.8043 5.588 20.413C5.196 20.021 5 19.55 5 19V6H4V4H9V3H15V4H20V6H19V19C19 19.55 18.8043 20.021 18.413 20.413C18.021 20.8043 17.55 21 17 21H7ZM17 6H7V19H17V6Z"
			/>
		</g>
	</svg>
);

export default RecycleBinLine;
