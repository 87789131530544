import React, {useCallback, useMemo} from "react";
import styles from "mapx-pages/Project/SavedPeople/styles.module.scss";
import {ActionAltButton} from "mapx-components";
import classNames from "classnames";
import FilterIcon from "assets/icons/IconFilter";
import {useAppDispatch, useAppSelector} from "hooks";
import {
	customSelectionOption,
	TSavedPeopleHeaderSectionProps,
} from "mapx-pages/Project/SavedPeople/SavedPeopleHeaderSection/types";
import ExpandAllIcon from "mapx-pages/Project/SavedPeople/SavedPeopleHeaderSection/Icons/expandAllIcon";
import AssessmentIcon from "mapx-pages/Project/SavedPeople/SavedPeopleHeaderSection/Icons/assessmentIcon";
import SummaryViewIcon from "mapx-pages/Project/SavedPeople/SavedPeopleHeaderSection/Icons/summaryViewIcon";
import {
	activeProjectReportViewOnCandidateCardSelector,
	projectProgressStatusesSelector,
} from "store/mapx/project/projectSelectors";
import {setActiveProjectReportViewOnCandidateCard} from "store/mapx/project/projectActions";
import {Checkbox, Dropdown} from "components";
import {
	handleAllCandidateSelectionForAssessment,
	handleCustomCandidateSelectionForAction,
} from "store/mapx/project/projectAssessmentAsyncActions";
import BulkActions from "mapx-pages/Project/SavedPeople/SavedPeopleHeaderSection/BulkActions";
import {ArrowDown} from "assets/icons";
import {STProjectProgressStatus} from "api/projectApi/types";

const SavedPeopleHeaderSection = ({
	displayAllExperience,
	setDisplayAllExperience,
	currentDisplayedCount,
	totalSavedPeopleCount,
	hasActiveFilters,
	candidateSelectionTypeForAssessment = "none",
}: TSavedPeopleHeaderSectionProps) => {
	const dispatch = useAppDispatch();

	const activeProjectReportViewOnCandidateCard = useAppSelector(
		activeProjectReportViewOnCandidateCardSelector,
	);

	const projectProgressStatuses: STProjectProgressStatus[] = useAppSelector(
		projectProgressStatusesSelector,
	);

	const dropdownOptions = useMemo(() => {
		const BULK_ACTIONS_OPTIONS: customSelectionOption[] = [
			{
				value: "select_all",
				name: "Select all",
			},
			{
				value: "deselect_all",
				name: "Deselect all",
			},
			{
				value: "select_recommended",
				name: "Select recommended",
			},
			{
				value: "select_status_with",
				name: "Select status with",
				children: [],
			},
			{
				value: "invert_selection",
				name: "Invert selection",
				hasBreakLine: true,
			},
			{
				value: "scores_100",
				name: "Scores 100",
			},
			{
				value: "scores_over_75",
				name: "Scores above >75",
			},
			{
				value: "scores_over_50",
				name: "Scores above >50",
			},
			{
				value: "scores_over_25",
				name: "Scores above >25",
				hasBreakLine: true,
			},
			{
				value: "scores_below_75",
				name: "Scores below <75",
			},
			{
				value: "scores_below_50",
				name: "Scores below <50",
			},
			{
				value: "scores_below_25",
				name: "Scores below >25",
				hasBreakLine: true,
			},
			{
				value: "not_scored",
				name: "Not Scored",
			},
		];

		return BULK_ACTIONS_OPTIONS.map((option) => {
			if (
				option.value === "select_status_with" &&
				option.children &&
				projectProgressStatuses?.length > 0
			) {
				return {
					...option,
					children: [
						...projectProgressStatuses.map((status) => ({
							value: status.id,
							name: status.name,
						})),
						...option.children,
					],
				};
			}

			return option;
		});
	}, [projectProgressStatuses]);

	const onSelect = useCallback(
		async (option: customSelectionOption) => {
			if (option.value !== "select_status_with") {
				dispatch(handleCustomCandidateSelectionForAction(option));
			}
		},
		[dispatch],
	);

	return (
		<div className={styles.headerInfoWrapper}>
			<div className={styles.left}>
				<div className={styles.userSelectCheckboxContainer}>
					<Checkbox
						value={"Select All"}
						isChecked={candidateSelectionTypeForAssessment === "all"}
						partiallySelected={candidateSelectionTypeForAssessment === "partial"}
						onChange={() =>
							dispatch(
								handleAllCandidateSelectionForAssessment(
									candidateSelectionTypeForAssessment,
								),
							)
						}
					/>

					<Dropdown
						key={"Default"}
						dropdownButtonClass={styles.dropdownButton}
						onSelect={onSelect}
						options={dropdownOptions}
						getOptionLabel={(option) => option.name}
						hasSeparator={(option) => !!option.hasBreakLine}
						/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
						// @ts-ignore
						getChildrenItems={(option) => (option.children ? option.children : [])}
						dropdownButtonWidth={256}
						placeholder={
							<div className={styles.customSelectionOption}>
								<ArrowDown />
							</div>
						}
						dropdownAlign={"right"}
					/>
				</div>

				<ActionAltButton
					key={"expand"}
					customClass={classNames(styles.toggleExpandButton, {
						[styles.reverse]: displayAllExperience,
					})}
					iconComponent={<ExpandAllIcon />}
					handleClick={() => setDisplayAllExperience((prev) => !prev)}
					title={displayAllExperience ? "Collapse All" : "Expand All"}
				/>

				{hasActiveFilters && (
					<div className={styles.filterCountInfo}>
						<span>
							<FilterIcon height={20} width={20} />
						</span>{" "}
						Showing {currentDisplayedCount ?? 0} of {totalSavedPeopleCount} people...
					</div>
				)}
			</div>

			<div className={styles.right}>
				<div className={styles.buttonsGroup}>
					<ActionAltButton
						key={"assessment_view"}
						customClass={classNames(styles.viewButton, {
							[styles.active]:
								activeProjectReportViewOnCandidateCard === "assessment",
						})}
						iconComponent={<AssessmentIcon />}
						handleClick={() =>
							dispatch(setActiveProjectReportViewOnCandidateCard("assessment"))
						}
						title={"Assessment View"}
					/>
					<ActionAltButton
						key={"summary_view"}
						customClass={classNames(styles.viewButton, {
							[styles.active]: activeProjectReportViewOnCandidateCard === "summary",
						})}
						iconComponent={<SummaryViewIcon />}
						handleClick={() =>
							dispatch(setActiveProjectReportViewOnCandidateCard("summary"))
						}
						title={"Summary View"}
					/>
				</div>

				<BulkActions />
			</div>
		</div>
	);
};

export default SavedPeopleHeaderSection;
