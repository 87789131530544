import DiversityStats from "./DiversityStats";
import IndustryBackground from "./BackgroundAnalysis";
import FrequentlyHiredFrom from "./FrequentlyHiredFrom";

import type {TContentTabsData} from "components/ContentTabs/types";

export const diversityIndustryFrequentlyData: TContentTabsData[] = [
	{
		id: 0,
		name: "Hiring Stats",
		ContentComponent: DiversityStats,
	},
	{
		id: 1,
		name: "Skills Analysis",
		ContentComponent: IndustryBackground,
	},
	{
		id: 2,
		name: "Companies Hired From",
		ContentComponent: FrequentlyHiredFrom,
	},
];
