import {
	ADD_SPECIALISM_TO_LIST,
	ADD_SPECIALITY_CLUSTER_TO_LIST,
	ADD_SPECIALITY_TO_LIST,
	GET_CANDIDATE_COMPANY_DATE_RANGE_SUCCESS,
	GET_CANDIDATE_COMPANY_EVENT_SIZE_RANGE_SUCCESS,
	GET_CANDIDATE_COMPANY_EVENTS_FETCH,
	GET_CANDIDATE_COMPANY_EVENTS_SUCCESS,
	GET_COMPANY_HEADCOUNT_RANGE_SUCCESS,
	GET_COMPANY_REVENUE_RANGE_SUCCESS,
	GET_COMPANY_TYPE_SUCCESS,
	GET_COUNTRIES_FETCH,
	GET_COUNTRIES_SUCCESS,
	GET_INDEXES_FETCH,
	GET_INDEXES_SUCCESS,
	GET_INDUSTRIES_FETCH,
	GET_INDUSTRIES_SUCCESS,
	GET_JOB_FUNCTIONS_FETCH,
	GET_JOB_FUNCTIONS_SUCCESS,
	GET_JOB_TITLES_COMPLETED,
	GET_JOB_TITLES_FETCH,
	GET_JOB_TITLES_SUCCESS,
	GET_KEYWORDS_FETCH,
	GET_KEYWORDS_SUCCESS,
	GET_LANGUAGE_FETCH,
	GET_LANGUAGE_SUCCESS,
	GET_SEARCH_DATA_FAILED,
	GET_SEARCH_DATA_FETCH,
	GET_SEARCH_DATA_SUCCESS,
	GET_SENIORITY_SUCCESS,
	GET_SPECIALISMS_SUCCESS,
	GET_SPECIALITIES_CLUSTER_FETCH,
	GET_SPECIALITIES_FETCH,
	GET_SPECIALITIES_SUCCESS,
	GET_SPECIALITY_CLUSTERS_SUCCESS,
	JOB_TITLE_SEARCH_QUERY,
	SET_AP_KEYWORD_SEARCH_QUERY,
	SET_CANDIDATE_COMPANY_EVENTS_SEARCH_QUERY,
	SET_KEYWORD_SEARCH_QUERY,
	SET_SPECIALTY_CLUSTER_SEARCH_QUERY,
	SET_SPECIALTY_SEARCH_QUERY,
} from "../mapXActionTypes";

export function getSearchDataFetch() {
	return {type: GET_SEARCH_DATA_FETCH};
}

// DEPRECATED
export function getSearchDataSuccess(data) {
	return {type: GET_SEARCH_DATA_SUCCESS, payload: data};
}

export function getSearchDataFailed(error) {
	return {type: GET_SEARCH_DATA_FAILED, payload: error};
}

//industries

export function getIndustriesFetch() {
	return {type: GET_INDUSTRIES_FETCH};
}

export function getIndustriesSuccess(payload) {
	return {type: GET_INDUSTRIES_SUCCESS, payload};
}

//keywords

export function getKeywordsFetch() {
	return {type: GET_KEYWORDS_FETCH};
}

export function getKeywordsSuccess(payload) {
	return {type: GET_KEYWORDS_SUCCESS, payload};
}

// Job Titles

export function getJobTitlesFetch() {
	return {type: GET_JOB_TITLES_FETCH};
}

export function getJobTitlesFetchCompleted() {
	return {type: GET_JOB_TITLES_COMPLETED};
}

export function getJobTitlesSuccess(payload) {
	return {type: GET_JOB_TITLES_SUCCESS, payload};
}

export function setJobTitleSearchQuery(payload) {
	return {type: JOB_TITLE_SEARCH_QUERY, payload};
}

// Countries

export function getCountriesFetch() {
	return {type: GET_COUNTRIES_FETCH};
}

export function getCountriesSuccess(payload) {
	return {type: GET_COUNTRIES_SUCCESS, payload};
}

// Indexes

export function getIndexesFetch() {
	return {type: GET_INDEXES_FETCH};
}

export function getIndexesSuccess(payload) {
	return {type: GET_INDEXES_SUCCESS, payload};
}

export function getCompanyHeadcountRangeSuccess(payload) {
	return {type: GET_COMPANY_HEADCOUNT_RANGE_SUCCESS, payload};
}

export function getCompanyRevenueRangeSuccess(payload) {
	return {type: GET_COMPANY_REVENUE_RANGE_SUCCESS, payload};
}

// Seniority List

export function getSenioritySuccess(payload) {
	return {type: GET_SENIORITY_SUCCESS, payload};
}
//company type
export function getCompanyTypeSuccess(payload) {
	return {type: GET_COMPANY_TYPE_SUCCESS, payload};
}

//Language
export function getLanguageFetch() {
	return {type: GET_LANGUAGE_FETCH};
}
export function getLanguageSuccess(payload) {
	return {type: GET_LANGUAGE_SUCCESS, payload};
}

// Job Functions

export function getJobFunctionsFetch() {
	return {type: GET_JOB_FUNCTIONS_FETCH};
}

export function getJobFunctionsSuccess(payload) {
	return {type: GET_JOB_FUNCTIONS_SUCCESS, payload};
}

// Specialities

export function getSpecialitiesFetch() {
	return {type: GET_SPECIALITIES_FETCH};
}

export function getSpecialitiesSuccess(payload) {
	return {type: GET_SPECIALITIES_SUCCESS, payload};
}

export function addSpecialityToList(payload) {
	return {type: ADD_SPECIALITY_TO_LIST, payload};
}

export function setSpecialtySearchQuery(payload) {
	return {type: SET_SPECIALTY_SEARCH_QUERY, payload};
}

// Speciality Cluster

export function getSpecialityClustersFetch() {
	return {type: GET_SPECIALITIES_CLUSTER_FETCH};
}

export function getSpecialtyClustersSuccess(payload) {
	return {type: GET_SPECIALITY_CLUSTERS_SUCCESS, payload};
}

export function addSpecialityClusterToList(payload) {
	return {type: ADD_SPECIALITY_CLUSTER_TO_LIST, payload};
}

export function setSpecialtyClusterSearchQuery(payload) {
	return {type: SET_SPECIALTY_CLUSTER_SEARCH_QUERY, payload};
}

// keywords

export function setKeywordSearchQuery(payload) {
	return {type: SET_KEYWORD_SEARCH_QUERY, payload};
}

export function apSetKeywordSearchQuery(payload) {
	return {type: SET_AP_KEYWORD_SEARCH_QUERY, payload};
}

// Specialisms

export function getSpecialismsSuccess(payload) {
	return {type: GET_SPECIALISMS_SUCCESS, payload};
}

export function addSpecialismToList(payload) {
	return {type: ADD_SPECIALISM_TO_LIST, payload};
}

//CandidateCompanyEvents

export function setCandidateCompanyEventsSearchQuery(payload) {
	return {type: SET_CANDIDATE_COMPANY_EVENTS_SEARCH_QUERY, payload};
}

export function getCandidateCompanyEventsFetch() {
	return {type: GET_CANDIDATE_COMPANY_EVENTS_FETCH};
}

export function getCandidateCompanyEventsSuccess(payload) {
	return {type: GET_CANDIDATE_COMPANY_EVENTS_SUCCESS, payload};
}

export function getCandidateCompanyEventSizeRangeSuccess(payload) {
	return {type: GET_CANDIDATE_COMPANY_EVENT_SIZE_RANGE_SUCCESS, payload};
}

export function getCandidateCompanyDateRangeSuccess(payload) {
	return {type: GET_CANDIDATE_COMPANY_DATE_RANGE_SUCCESS, payload};
}
