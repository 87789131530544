import React, {FC, useEffect, useState} from "react";
import {TIndustreiesSkeletonLoaderProps} from "mapx-pages/Project/Overview/IndustriesReport/IndustriesReportSkeletonLoader/types";
import IndustryServicesHeaderSkeletonLoader from "mapx-pages/Project/Overview/IndustriesReport/IndustriesReportSkeletonLoader/industriesServicesHeaderSkeletonLoader";
import IndustryServicesSkeletonLoaderCard from "mapx-pages/Project/Overview/IndustriesReport/IndustriesReportSkeletonLoader/industriesServicesSkeletonLoader";
import classNames from "classnames";
import styles from "mapx-pages/Project/Overview/IndustriesReport/IndustriesReportSkeletonLoader/IndustriesSkeletonLoader.module.scss";

const IndustriesReportSkeletonLoader: FC<TIndustreiesSkeletonLoaderProps> = ({
	columns,
	customClass,
	rows,
}) => {
	const [numColumns, setNumColumns] = useState(columns);

	useEffect(() => {
		const handleResize = () => {
			if (window.innerWidth <= 768) {
				setNumColumns(1);
			} else {
				setNumColumns(2);
			}
		};

		window.addEventListener("resize", handleResize);

		handleResize();

		return () => window.removeEventListener("resize", handleResize);
	}, []);

	return (
		<div title="loading">
			{Array.from({length: rows}, (_, idx) => (
				<div
					key={idx}
					className={classNames(styles.industriesSkeletonBlock, {
						[customClass]: customClass,
					})}
				>
					{Array.from({length: numColumns}, (_, i) => (
						<div key={i}>
							<IndustryServicesHeaderSkeletonLoader />
							<IndustryServicesSkeletonLoaderCard count={3} rows={2} />
						</div>
					))}
				</div>
			))}
		</div>
	);
};

export default IndustriesReportSkeletonLoader;
