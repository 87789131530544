import React from "react";
import {BiUser} from "react-icons/bi";
import LazyLoadImageWrapper from "components/LazyLoadImageWrapper";
import css from "../styles.module.scss";

const UserAvatarImage = (avatar: Nullable<string>, altText: string) => {
	return avatar ? (
		<LazyLoadImageWrapper className={css.logo} image={avatar} alt={altText} />
	) : (
		<BiUser className={css.logo} />
	);
};

export default UserAvatarImage;
