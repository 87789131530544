import MapxProjectApi from "api/projectApi";
import {clearTrackedEventsData, setSyncedTrackedData} from "./eventTrackingActions";
import {Dispatch} from "redux";
import {TAppDispatch} from "types";
import {STEvents} from "api/types";
import axios from "axios";

/* eslint-disable  @typescript-eslint/no-explicit-any */
const cancelTokens = {} as any;

export const sendTrackedEventsData =
	(data: STEvents[]) => async (dispatch: Dispatch<TAppDispatch>) => {
		let response;

		const requestKey = `TRACK_EVENT`;

		if (requestKey in cancelTokens) {
			cancelTokens[requestKey].cancel("Operation canceled due to new request.");
		}

		cancelTokens[requestKey] = axios.CancelToken.source();

		const config = {
			cancelToken: cancelTokens[requestKey].token,
		};

		try {
			const payload = data.map((item: STEvents) => {
				delete item.event;
				delete item.details.recommended;

				return item;
			});

			response = await MapxProjectApi.saveEvents(payload, config);

			if (response && response?.status === 201) {
				dispatch(setSyncedTrackedData(data));
				dispatch(clearTrackedEventsData());
			}
		} catch (error) {
			console.log(error);
		}
	};
