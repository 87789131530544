export const candidateCompanyDateRangeBand = [
	{
		id: "< 6 months",
		name: "< 6 months",
		min_value: new Date(new Date().setMonth(new Date().getMonth() - 6)),
		max_value: "",
	},
	{
		id: "6-12 months",
		name: "6-12 months",
		min_value: new Date(new Date().setMonth(new Date().getMonth() - 12)),
		max_value: new Date(new Date().setMonth(new Date().getMonth() - 6)),
	},
	{
		id: "1-2 years",
		name: "1-2 years",
		min_value: new Date(new Date(new Date().setFullYear(new Date().getFullYear() - 2))),
		max_value: new Date(new Date(new Date().setFullYear(new Date().getFullYear() - 1))),
	},
	{
		id: "2-3 years",
		name: "2-3 years",
		min_value: new Date(new Date(new Date().setFullYear(new Date().getFullYear() - 3))),
		max_value: new Date(new Date(new Date().setFullYear(new Date().getFullYear() - 2))),
	},
	{
		id: "3-4 years",
		name: "3-4 years",
		min_value: new Date(new Date(new Date().setFullYear(new Date().getFullYear() - 4))),
		max_value: new Date(new Date(new Date().setFullYear(new Date().getFullYear() - 3))),
	},
	{
		id: "4-5 years",
		name: "4-5 years",
		min_value: new Date(new Date(new Date().setFullYear(new Date().getFullYear() - 5))),
		max_value: new Date(new Date(new Date().setFullYear(new Date().getFullYear() - 4))),
	},
	{
		id: "5-6 years",
		name: "5-6 years",
		min_value: new Date(new Date(new Date().setFullYear(new Date().getFullYear() - 6))),
		max_value: new Date(new Date(new Date().setFullYear(new Date().getFullYear() - 5))),
	},
	{
		id: "6+ years",
		name: "6+ years ago",
		min_value: "",
		max_value: new Date(new Date().setFullYear(new Date().getFullYear() - 6)),
	},
];
