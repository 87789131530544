import {forwardRef, MouseEvent, useEffect, useMemo, useState} from "react";
import {noop} from "lodash";
import classNames from "classnames";

import {Button} from "components/shared";
import {ArrowUp, Reset, SearchIcon} from "assets/icons";

import type {TSelectHeadProps} from "./types";
import {RemoveSelectedItemIcon} from "./utils";
import styles from "./MultiSelect.module.scss";
import {Loader} from "components/index";

const SelectHead = forwardRef<HTMLDivElement, TSelectHeadProps>(
	(
		{
			name,
			data,
			values,
			isOpen,
			isOnce,
			onClick,
			title = "",
			isSelected,
			placeholder,
			isResetShow,
			resetFilters,
			isResettable,
			selectSearchable,
			onRemoveSelection,
			setSearchInputValue,
			isLoading,
		},
		ref,
	) => {
		const isLocation = name === "countries";
		const [showAllItems, setShowAllItems] = useState<number>(1);

		const renderSelectedItems = data?.filter((elem) => values?.includes(elem.id));
		const selectedList = renderSelectedItems?.flat();
		const filteringLocationSelected = data
			?.map((item) => item?.steps)
			?.flat(1)
			// @TODO: Refactor to real type
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			?.filter((item: any) => values?.includes(item?.id));

		const selectedCountriesLength = Number(filteringLocationSelected?.length);
		const isShowAllCountries = showAllItems === selectedCountriesLength;

		const isSelectedDates = !!values?.length;
		const isSelectedClose = isSelectedDates && !isOpen;
		const selectedListLength = Number(selectedList?.length);
		const isShowAll = showAllItems === selectedListLength;
		const titleItem = !isSelectedClose && <p className={styles.selected__title}>{title}</p>;

		const isRenderSelectedItems = isLocation ? filteringLocationSelected : selectedList;

		const selectedSearch = (
			<div className={styles.selected__container}>
				<SearchIcon fill="#A39C9A" />
				<div className={styles.selected__inputContainer}>
					<input
						autoFocus
						placeholder={placeholder}
						data-testid="select-header-input"
						onChange={(event) =>
							setSearchInputValue && setSearchInputValue(event.target.value)
						}
					/>
					{isLoading && (
						<div className={styles.loader}>
							<Loader />
						</div>
					)}
				</div>
			</div>
		);

		const isSearchable = !isOpen ? titleItem : selectedSearch;

		useEffect(() => {
			if (!isOpen) {
				setShowAllItems(1);
			}
		}, [isOpen]);

		const activeHandler = () => {
			if (!selectSearchable) {
				onClick();
			}
			if (selectSearchable && !isOpen) {
				onClick();
			}

			return noop;
		};

		const closeHandler = () => {
			if (selectSearchable && isOpen) {
				onClick();
			}

			return noop;
		};

		const returnBadges = useMemo(
			() => isRenderSelectedItems?.slice(0, showAllItems),
			[isRenderSelectedItems, showAllItems],
		);

		const selectedItems = useMemo(
			() =>
				isSelectedClose &&
				// @TODO: Refactor to real type
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				returnBadges?.map((elem: any) => (
					<p key={elem.id} className={styles.selected__item}>
						<span className={styles.selected__item_text}>{elem.name || elem.text}</span>

						<Button
							defaultBtnStyle
							dataTestId="remove-selected-items"
							onClick={(e) => {
								e.stopPropagation();
								onRemoveSelection(elem.id, elem?.steps || [elem]);
							}}
							LeftIcon={RemoveSelectedItemIcon}
						/>
					</p>
				)),
			[isSelectedClose, returnBadges, onRemoveSelection],
		);

		const showAllSelectedItems = (e: MouseEvent<HTMLButtonElement>) => {
			e.stopPropagation();
			setShowAllItems(isLocation ? selectedCountriesLength : selectedListLength);
		};

		const selectedItemsList = (
			<div
				className={classNames(styles.wrapper, {
					[styles.wrapper__reset_show]: isResetShow,
				})}
			>
				<div
					className={classNames(styles.wrapper_container, {
						[styles.wrapper_container_full]: isShowAll,
					})}
				>
					{selectedItems}
				</div>

				{(isLocation ? selectedCountriesLength > 1 : selectedListLength > 1) &&
					(isLocation ? !isShowAllCountries : !isShowAll) &&
					!isOpen &&
					returnBadges?.length === showAllItems && (
						<Button
							defaultBtnStyle
							onClick={showAllSelectedItems}
							className={styles.wrapper__others}
						>
							+{(isLocation ? selectedCountriesLength : selectedListLength) - 1}
						</Button>
					)}
			</div>
		);

		return (
			<div
				ref={ref}
				role="button"
				onClick={activeHandler}
				data-testid="open-select"
				className={classNames(styles.selected, {
					[styles.selected__once]: isOnce,
				})}
			>
				{!selectSearchable ? titleItem : isSearchable}

				{isSelectedClose ? selectedItemsList : null}

				<div className={styles.selected_wrapper}>
					{isResetShow && isSelected && (
						<Button
							defaultBtnStyle
							LeftIcon={Reset}
							onClick={resetFilters}
							disabled={!isResettable}
							className={styles.selected__reset}
						>
							Reset
						</Button>
					)}

					<Button onClick={closeHandler} data-testid="close-select" defaultBtnStyle>
						<ArrowUp
							className={classNames(styles.selected__transform, {
								[styles.selected__transform_rotate]: isOpen,
							})}
						/>
						{/*<ArrowDown*/}
						{/*	stroke="#A39C9A"*/}
						{/*	className={classNames(styles.selected__transform, {*/}
						{/*		[styles.selected__transform_rotate]: isOpen,*/}
						{/*	})}*/}
						{/*/>*/}
					</Button>
				</div>
			</div>
		);
	},
);

SelectHead.displayName = "SelectHead";

export default SelectHead;
