import HttpClient from "api/index";

class MapxSpecialityApi extends HttpClient {
	async getSpecialities(pageNumber: number, config = {}) {
		return this.doGet(`/mapx/specialties?page=${pageNumber}&per_page=40`, config);
	}

	async searchSpecialities(queryParam: string, pageNumber: number, config = {}) {
		return this.doGet(`/mapx/specialties${queryParam}&page=${pageNumber}&per_page=40`, config);
	}

	async getClusterSpecialities(pageNumber: number, config = {}) {
		return this.doGet(`/specialty-clusters?page=${pageNumber}&per_page=40`, config);
	}

	async searchClusterSpecialities(queryParam: string | null, pageNumber: number, config = {}) {
		return this.doGet(
			`/specialty-clusters${queryParam}&page=${pageNumber}&per_page=40`,
			config,
		);
	}
}

const mapxSpecialityApi = new MapxSpecialityApi();

export default mapxSpecialityApi;
