import {type FC, useEffect} from "react";

import ProjectDetails from "./ProjectDetails";
import QuickViewCandidate from "./QuickViewCandidate";
import {projectSelector} from "store/mapx/project-list/projectListSelectors";
import {IProjectEntry} from "./types";
import {MainWrapper} from "mapx-components";
import {useAppDispatch, useAppSelector} from "hooks";
import {getAllBackgroundBulkImports} from "store/mapx/background-tasks/backgroundTaskAsyncActions";
import {setShowLastImportReport} from "store/mapx/linkedin-bulk-import/linkedInBulkImportActions";
import {projectProgressStatusesSelector} from "store/mapx/project/projectSelectors";
import {getProjectProgressStatuses} from "store/mapx/project/projectAsyncActions";
import {STProject, STProjectProgressStatus} from "api/projectApi/types";

const ProjectEntry: FC<IProjectEntry> = (props) => {
	const activeProjectInState: STProject = useAppSelector(projectSelector);

	const projectProgressStatuses: STProjectProgressStatus[] = useAppSelector(
		projectProgressStatusesSelector,
	);

	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(setShowLastImportReport(true));
		dispatch(getAllBackgroundBulkImports(activeProjectInState));
	}, [activeProjectInState, dispatch]);

	useEffect(() => {
		if (!projectProgressStatuses?.length) {
			dispatch(getProjectProgressStatuses());
		}
	}, [dispatch, projectProgressStatuses]);

	return (
		<MainWrapper>
			<ProjectDetails {...props} key={activeProjectInState?.id} />
			<QuickViewCandidate />
		</MainWrapper>
	);
};

export default ProjectEntry;
