import {DebounceSettings, debounce} from "lodash";
import {useCallback, useEffect, useState, useRef} from "react";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useDebouncedCallback = <T extends (...args: any[]) => any>(
	callback: T,
	delay = 300,
	options?: DebounceSettings,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
): any => {
	// eslint-disable-next-line react-hooks/exhaustive-deps
	return useCallback(debounce(callback, delay, options), [callback, delay, options]);
};

export const useDebounce = <T>(value: T, delay = 0, options?: DebounceSettings): T => {
	const previousValue = useRef(value);
	const [current, setCurrent] = useState(value);

	const debouncedCallback = useDebouncedCallback((value: T) => setCurrent(value), delay, options);

	useEffect(() => {
		if (value !== previousValue.current) {
			debouncedCallback(value);
			previousValue.current = value;

			return debouncedCallback.cancel;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [value]);

	return current;
};
