import classNames from "classnames";
import React, {useCallback, useMemo} from "react";
import {
	activeProjectResultsTabContentSelector,
	activeSearchRequestSelector,
	allProfileRequestsSelector,
	allSearchRequestsSelector,
} from "store/mapx/additional-profiles/additionalProfilesSelectors";
import filterBlockStyles from "./styles.module.scss";
import {useAppDispatch, useAppSelector} from "hooks";
import {TProfileRequest} from "api/projectApi/profileRequestApi/types";
import ProjectCandidatesFilter from "mapx-pages/Project/ProjectCandidatesFilter";
import {
	TFilterBlockProps,
	TProjectsResultsTabContentTypes,
} from "mapx-components/SearchRequestResults/FilterBlock/types";
import SidebarTabs from "mapx-components/SearchRequestResults/FilterBlock/SidebarTabs";
import FindIcon from "assets/icons/IconFind";
import {PlusIcon} from "assets/icons";
import {
	setPRFilters,
	setProjectResultsTabActiveContent,
} from "store/mapx/additional-profiles/additionalProfilesActions";
import {Loader} from "components";
import colors from "styles/themes.module.scss";
import {TSearchRequestMiniResponse} from "api/projectApi/searchRequestApi/types";
import {getJobTitlesSuggestionForProfileRequest} from "store/mapx/search/jobTitlesAsyncActions";

const FilterBlock = ({
	isDisplayingMatchingTo,
	setDisplayingMatchingTo,
	searchType,
	addSearchResultsTab = false,
}: TFilterBlockProps) => {
	// const hasAccess = useAppSelector(hasAccessToManualSearchSelector);

	const dispatch = useAppDispatch();

	const allSearchRequests: TSearchRequestMiniResponse[] =
		useAppSelector(allSearchRequestsSelector);

	const activeContent: TProjectsResultsTabContentTypes = useAppSelector(
		activeProjectResultsTabContentSelector,
	);

	const activeSearchRequest = useAppSelector(activeSearchRequestSelector);

	const allProfileRequests = useAppSelector(allProfileRequestsSelector);

	const latestCompletedPR = useMemo(() => {
		if (allProfileRequests?.length) {
			const pr = allProfileRequests.find(
				(item: TProfileRequest) => item.status === "Completed",
			);

			if (pr !== undefined) {
				return pr;
			}
		}

		return null;
	}, [allProfileRequests]);

	const aiSearchIsInProgress = useMemo(() => {
		return (
			allSearchRequests.filter((sr) => sr.status === "In Progress")?.length > 0 &&
			activeContent !== "profile_request"
		);
	}, [activeContent, allSearchRequests]);

	const handleFilterClick = useCallback(() => {
		if (!aiSearchIsInProgress) {
			if (activeSearchRequest?.type === "Additional Profiles") {
				const payload = {
					company_ids: activeSearchRequest.company_ids || [],
					candidate_id:
						activeSearchRequest.input_candidates?.length > 0
							? activeSearchRequest.input_candidates[0].id
							: null,
					selected_job_titles: activeSearchRequest.job_titles || [],
					country_ids: activeSearchRequest.country_ids || [],
					search_width: activeSearchRequest.search_width || "Narrow",
					job_title_suggestions: [],
				};

				dispatch(setPRFilters(payload));

				if (payload.candidate_id) {
					dispatch(getJobTitlesSuggestionForProfileRequest(payload.candidate_id, true));
				}
			}

			dispatch(setProjectResultsTabActiveContent("profile_request"));
		}
	}, [activeSearchRequest, aiSearchIsInProgress, dispatch]);

	const LOADER_COMPONENT = (
		<Loader height={15} width={15} type="Oval" color={colors.loaderDotColor} />
	);

	return (
		<div
			className={classNames(
				filterBlockStyles.filterBlocksWrapper,
				filterBlockStyles.filter_container,
			)}
		>
			{searchType === "Additional Profiles" && (
				<div className={classNames(filterBlockStyles.aiWebSearch)}>
					<div className={filterBlockStyles.title}>
						<FindIcon /> AI Web Search
					</div>

					{activeSearchRequest?.status !== "In Progress" ? (
						<div className={filterBlockStyles.newSearch} onClick={handleFilterClick}>
							<PlusIcon /> New Search
						</div>
					) : (
						<div className={filterBlockStyles.newSearch}>
							{activeSearchRequest?.status === "In Progress" && LOADER_COMPONENT}
						</div>
					)}
				</div>
			)}

			{allSearchRequests?.length > 0 && activeContent !== "profile_request" && (
				<div style={{display: "flex", flexDirection: "column", gap: 10}}>
					<div className={filterBlockStyles.additionalProfilesFilter}>
						<ProjectCandidatesFilter
							canUserClick={latestCompletedPR !== null}
							source={"results"}
							activeContent={activeContent}
							className={classNames(
								filterBlockStyles.filterBlock,
								filterBlockStyles.headerSection,
							)}
							activeClass={filterBlockStyles.active}
							titleClass={filterBlockStyles.filterTitle}
							disabled={isDisplayingMatchingTo}
						/>
					</div>
				</div>
			)}

			<div className={filterBlockStyles.additionalProfilesFilter}>
				<SidebarTabs
					addSearchResultsTab={addSearchResultsTab}
					isDisplayingMatchingTo={isDisplayingMatchingTo}
					setDisplayingMatchingTo={setDisplayingMatchingTo}
				/>
			</div>
		</div>
	);
};

export default FilterBlock;
