import {Loader} from "components";
import {SectionWrapper} from "mapx-components";
import React, {useEffect, useMemo, useState} from "react";
import {HorizontalBar} from "react-chartjs-2";
import {useDispatch, useSelector} from "react-redux";
import {getEthnicDiversityData} from "store/mapx/project/projectAsyncActions";
import styles from "./styles.module.scss";
import colors from "styles/themes.module.scss";
import NotEnoughData from "../NotEnoughData";
import {projectSelector} from "store/mapx/project-list/projectListSelectors";

const labels = {
	black_percent: "Black",
	east_asian_percent: "East Asian",
	hispanic_percent: "Hispanic",
	middle_eastern_percent: "Middle East",
	other_percent: "Other",
	south_asian_percent: "South Asian",
	white_percent: "White",
};

const chartOptions = {
	legend: {
		display: false,
	},
	tooltips: {
		enabled: false,
	},
	hover: {
		mode: null,
	},
	scales: {
		xAxes: [
			{
				display: false,
				stacked: false,
				gridLines: {
					display: false,
				},
			},
		],
		yAxes: [
			{
				position: "right",
				gridLines: {
					display: false,
				},
				categoryPercentage: 0.5,
				barPercentage: 1,
			},
		],
	},
	plugins: {
		tooltip: {
			enabled: false,
		},
	},
};

export const EthnicDiversityChart = () => {
	const dispatch = useDispatch();

	const project = useSelector(projectSelector);

	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (project?.id) {
			setLoading(true);
			dispatch(getEthnicDiversityData(project.id, () => setLoading(false)));
		}
	}, [project?.id, dispatch]);

	const ethnicReport = useSelector((state) => state.mapxProject.insights.ethnicDiversity);

	const {sortedEthnicReport, sortedLabels} = useMemo(() => {
		if (!ethnicReport) {
			return {
				sortedEthnicReport: [],
				sortedLabels: [],
			};
		}

		let sortable = [];
		for (var vehicle in ethnicReport) {
			sortable.push([vehicle, ethnicReport[vehicle]]);
		}

		sortable.sort(function (a, b) {
			return b[1] - a[1];
		});

		return {
			sortedEthnicReport: sortable.filter((val) => val[1] >= 0).map((val) => val[1] * -1),
			sortedLabels: sortable
				.filter((val) => val[1] >= 0)
				.map((val) => `         ${labels[val[0]]} ${ethnicReport[val[0]]}%`),
		};
	}, [ethnicReport]);

	return (
		<SectionWrapper label="Ethnicity" className={styles.ethnicityChart}>
			<div className={styles.chartWrapper}>
				{sortedEthnicReport.length && !loading ? (
					<HorizontalBar
						options={chartOptions}
						data={{
							labels: sortedLabels,
							datasets: [
								{
									borderWidth: 0,
									backgroundColor: [
										"#68B68A",
										"#5B9FC9",
										"#83C39F",
										"#85B7D6",
										"#9FD1B4",
										"#C2DAEB",
									],
									data: sortedEthnicReport,
								},
							],
						}}
					/>
				) : null}
				{loading ? (
					<Loader height={30} width={30} type="ThreeDots" color={colors.loaderDotColor} />
				) : null}
			</div>
			{!loading && sortedEthnicReport.length < 5 ? <NotEnoughData /> : null}
		</SectionWrapper>
	);
};
