import mapxProjectApi from "api/projectApi";
import mapxProjectInsightsApi from "api/projectApi/projectStatsApi";
import axios, {CancelTokenSource} from "axios";
import {successResponse} from "helpers/map";
import {noop} from "lodash";

import {
	bulkProjectCandidateStatusChange,
	getIndustriesBackgroundReportDone,
	getIndustriesBackgroundReportFailed,
	getIndustriesBackgroundReportInit,
	getIndustriesReportFailed,
	getIndustriesReportFetch,
	getIndustriesReportSuccess,
	getProjectProgressStatusesFailed,
	getProjectProgressStatusesInit,
	getProjectStatsFailed,
	getProjectStatsInit,
	setCandidateIdsForAssessment,
	setCountryReport,
	setEthnicDiversityData,
	setGenderDiversityData,
	setJobFunctionDistributionData,
	setJobFunctionDistributionInit,
	setProjectFrequentlyHiredFromData,
	setProjectProgressStatuses,
	setProjectProgressStatusesFinished,
	setProjectStats,
} from "./projectActions";
import {TAppDispatch, TRootState} from "types";
import {
	STProject,
	STProjectProgressStatus,
	STProjectsIdFrequentlyHiredFrom,
} from "api/projectApi/types";
import projectCandidateApi from "api/projectApi/projectCandidatesApi";
import {selectedCandidateIdsForAssessmentSelector} from "store/mapx/project/candidateScoringSelectors";
import {projectSelector} from "store/mapx/project-list/projectListSelectors";
import {TProjectCandidatesStatusFormBody} from "api/projectApi/projectCandidatesApi/types";
import {getTargetListCandidates} from "store/mapx/target-list/targetListCandidatesAsyncActions";
import {TBulkStatusChangeParams} from "mapx-pages/Project/SavedPeople/SavedPeopleHeaderSection/types";
import {updateAPCandidateByKey} from "store/mapx/target-list/targetListActions";

const cancelTokens: {[key: string]: CancelTokenSource} = {};

export const getGenderDiversityData =
	(id: number, callback?: () => void) => async (dispatch: TAppDispatch) => {
		let data;
		try {
			const res = await mapxProjectInsightsApi.getGenderDiversityReport(id);
			res.status === 409 ? (data = []) : (data = res.data);

			dispatch(setGenderDiversityData(data));
		} catch (e) {
			console.error(e);
			callback && callback();
		} finally {
			callback && callback();
		}
	};

export const getEthnicDiversityData =
	(id: number, callback?: () => void) => async (dispatch: TAppDispatch) => {
		let data;
		try {
			const res = await mapxProjectInsightsApi.getEthnicDiversityReport(id);
			res.status !== 200 ? (data = []) : (data = res.data);
			dispatch(setEthnicDiversityData(data));
		} catch (e) {
			console.error(e);
		} finally {
			callback && callback();
		}
	};

export const getCountryReport =
	(id: number, callback?: () => void) => async (dispatch: TAppDispatch) => {
		let data = [];

		try {
			const res = await mapxProjectInsightsApi.getCountryReport(id);
			res.status !== 200 ? (data = []) : (data = res.data.results);

			dispatch(setCountryReport(data));
		} catch (e) {
			console.error(e);
		} finally {
			callback && callback();
		}
	};

export const getIndustryReportData = (id: number) => async (dispatch: TAppDispatch) => {
	dispatch(getIndustriesReportFetch());

	try {
		const requestKey = `GET_INDUSTRIES_REPORT_PROJECT`;

		if (requestKey in cancelTokens) {
			cancelTokens[requestKey].cancel("Operation canceled due to new request.");
		}

		cancelTokens[requestKey] = axios.CancelToken.source();

		const config = {
			cancelToken: cancelTokens[requestKey].token,
		};

		const response = await mapxProjectInsightsApi.getIndustriesReport(id, config);

		if (successResponse(response, 200)) {
			const data = response.data.results;

			dispatch(getIndustriesReportSuccess(data));
		}

		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		if (axios.isCancel()) {
			dispatch(getIndustriesReportFailed());
		}
	} catch (e) {
		console.error(e);

		dispatch(getIndustriesReportFailed());
	}
};

export const getJobFunctionDistributionReport = (id: number) => async (dispatch: TAppDispatch) => {
	try {
		dispatch(setJobFunctionDistributionInit());

		const response = await mapxProjectInsightsApi.getJobFunctionDistribution(id);

		if (successResponse(response, 200)) {
			const data = response.data.results;

			dispatch(setJobFunctionDistributionData(data));
		}
	} catch (e) {
		console.error(e);
	}
};

export const getProjectStats = (id: number) => async (dispatch: TAppDispatch) => {
	try {
		dispatch(getProjectStatsInit());

		const requestKey = `GET_STATISTICS_PROJECT`;

		if (requestKey in cancelTokens) {
			cancelTokens[requestKey].cancel("Operation canceled due to new request.");
		}

		cancelTokens[requestKey] = axios.CancelToken.source();

		const config = {
			cancelToken: cancelTokens[requestKey].token,
		};

		const response = await mapxProjectInsightsApi.getProjectStats(id, config);

		if (successResponse(response, 200)) {
			const data = response.data;

			dispatch(setProjectStats(data));
		}

		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		if (axios.isCancel()) {
			dispatch(getIndustriesReportFailed());
		}
	} catch (e) {
		console.error(e);

		dispatch(getProjectStatsFailed());
	}
};

export const getProjectFrequentlyHiredFromData =
	(id: number, params: STProjectsIdFrequentlyHiredFrom, config = {}, callback = noop) =>
	async (dispatch: TAppDispatch) => {
		try {
			const response = await mapxProjectInsightsApi.getProjectFrequentlyHiredFrom(
				id,
				params,
				config,
			);

			if (successResponse(response, 200)) {
				const data = response.data;

				dispatch(setProjectFrequentlyHiredFromData(data));
			}
		} catch (e) {
			console.error(e);
		} finally {
			callback && callback();
		}
	};

export const getIndustriesBackgroundReport =
	(project_id: number) => async (dispatch: TAppDispatch) => {
		try {
			dispatch(getIndustriesBackgroundReportInit());

			const response = await mapxProjectInsightsApi.getIndustriesBackgroundReport(project_id);

			if (successResponse(response, 200)) {
				const data = response.data.results;

				dispatch(getIndustriesBackgroundReportDone(data));
			}
		} catch (error) {
			dispatch(getIndustriesBackgroundReportFailed());

			return {error};
		}
	};

export const getProjectProgressStatuses = () => async (dispatch: TAppDispatch) => {
	try {
		dispatch(getProjectProgressStatusesInit());

		const response = await mapxProjectApi.getProjectProgressStatuses();

		if (successResponse(response, 200)) {
			const data = response.data.results;

			if (data?.length > 0) {
				dispatch(setProjectProgressStatuses(data));
			} else {
				dispatch(setProjectProgressStatusesFinished());
			}
		}
	} catch (error) {
		dispatch(getProjectProgressStatusesFailed());

		return {error};
	}
};

export const changeProjectCandidateStatus =
	({isRecommended, statusId}: TBulkStatusChangeParams) =>
	async (dispatch: TAppDispatch, getState: TRootState) => {
		try {
			const state = getState();

			const candidateIds: number[] = selectedCandidateIdsForAssessmentSelector(state);

			const project: STProject = projectSelector(state);

			const formBody: TProjectCandidatesStatusFormBody = {
				candidate_ids: candidateIds,
			};

			if (isRecommended !== undefined) {
				formBody.is_recommended = isRecommended;
			}

			if (statusId !== undefined) {
				formBody.progress_status_id = statusId;
			}

			dispatch(bulkProjectCandidateStatusChange(true));

			const response = await projectCandidateApi.updateProjectCandidatesStatus(
				project.id,
				formBody,
			);

			dispatch(bulkProjectCandidateStatusChange(false));

			if (successResponse(response, 200)) {
				dispatch(getTargetListCandidates());

				return response.data;
			}
		} catch (error) {
			dispatch(bulkProjectCandidateStatusChange(false));

			return {error};
		}
	};

export const removeSelectedCandidatesFromProject =
	() => async (dispatch: TAppDispatch, getState: TRootState) => {
		try {
			const state = getState();

			const candidateIds: number[] = selectedCandidateIdsForAssessmentSelector(state);

			const project: STProject = projectSelector(state);

			const formBody = {
				candidate_ids: candidateIds,
			};

			const response = await projectCandidateApi.deleteProjectCandidatesStatus(
				project.id,
				formBody,
			);

			if (successResponse(response, 200)) {
				dispatch(getTargetListCandidates());

				dispatch(setCandidateIdsForAssessment([]));

				return response.data;
			}
		} catch (error) {
			return {error};
		}
	};

export const changeCandidateStatusByCandidateId =
	(candidateId: number, status: STProjectProgressStatus) =>
	async (dispatch: TAppDispatch, getState: TRootState) => {
		try {
			const state = getState();

			const project: STProject = projectSelector(state);

			const formBody = {
				candidate_ids: [candidateId],
				progress_status_id: status.id,
			};

			const response = await projectCandidateApi.updateProjectCandidatesStatus(
				project.id,
				formBody,
			);

			if (successResponse(response, 200)) {
				const payload = {
					id: candidateId,
					key: "progress_status",
					value: status.name,
				};

				dispatch(updateAPCandidateByKey(payload));

				return response.data;
			}
		} catch (error) {
			return {error};
		}
	};
