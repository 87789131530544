import styles from "./styles.module.scss";
import {GeneralButton} from "mapx-components";
import {ExternalLinkIcon} from "assets/icons";
import {useAppSelector} from "hooks";
import {projectSelector} from "store/mapx/project-list/projectListSelectors";

const Instruction = () => {
	const project = useAppSelector(projectSelector);

	const VIDEO_TUTORIAL_URL =
		process.env?.REACT_APP_DWS_V_TUTORIAL_EMBED_URL ??
		"https://www.loom.com/embed/86fc05db02f44363991a79de6d267a86?sid=1b00a060-5925-4d54-81b8-16b84736677a";

	const WEB_TUTORIAL_URL =
		process.env?.REACT_APP_DWS_WEB_TUTORIAL_URL ??
		"https://26144956.hs-sites-eu1.com/en/knowledge-base";

	const EXTERNAL_URL_ICON = <ExternalLinkIcon />;

	return (
		<div className={styles.instructionContainer}>
			{project && (
				<div className={styles.instructionWrapper}>
					<div className={styles.instructionTitle}>AI Web Search</div>

					<div className={styles.instructionDesc}>
						Configure MapX AI to find hidden talent from across the web.
					</div>

					<div className={styles.requiredInstruction}>* Required fields</div>

					<div style={{position: "relative", paddingBottom: "46.25%", height: 0}}>
						<iframe
							title={"instruction"}
							src={VIDEO_TUTORIAL_URL}
							allowFullScreen
							style={{
								position: "absolute",
								top: 0,
								left: 0,
								width: "560px",
								height: "320px",
								borderRadius: 6,
							}}
						></iframe>
					</div>

					{WEB_TUTORIAL_URL && WEB_TUTORIAL_URL !== "" && (
						<GeneralButton
							className={styles.learnButton}
							title={"Learn More"}
							icon={EXTERNAL_URL_ICON}
							onClick={() => window.open(WEB_TUTORIAL_URL, "_blank")}
						/>
					)}
				</div>
			)}

			{!project && (
				<div className={styles.instructionWrapper}>
					<div className={styles.instructionTitle}>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="20"
							height="20"
							viewBox="0 0 20 20"
							fill="none"
						>
							<mask
								id="mask0_5263_6559"
								style={{maskType: "alpha"}}
								maskUnits="userSpaceOnUse"
								x="0"
								y="0"
								width="20"
								height="20"
							>
								<rect width="20" height="20" fill="#D9D9D9" />
							</mask>
							<g mask="url(#mask0_5263_6559)">
								<path
									d="M9.99563 14C10.2069 14 10.3854 13.9285 10.5312 13.7856C10.6771 13.6427 10.75 13.4656 10.75 13.2544C10.75 13.0431 10.6785 12.8646 10.5356 12.7188C10.3927 12.5729 10.2156 12.5 10.0044 12.5C9.79313 12.5 9.61458 12.5715 9.46875 12.7144C9.32292 12.8573 9.25 13.0344 9.25 13.2456C9.25 13.4569 9.32146 13.6354 9.46438 13.7812C9.60729 13.9271 9.78438 14 9.99563 14ZM9.25 11H10.75V6H9.25V11ZM10.0058 18C8.90472 18 7.86806 17.7917 6.89583 17.375C5.92361 16.9583 5.07292 16.3854 4.34375 15.6562C3.61458 14.9271 3.04167 14.0767 2.625 13.105C2.20833 12.1333 2 11.0951 2 9.99042C2 8.88569 2.20833 7.85069 2.625 6.88542C3.04167 5.92014 3.61458 5.07292 4.34375 4.34375C5.07292 3.61458 5.92333 3.04167 6.895 2.625C7.86667 2.20833 8.90486 2 10.0096 2C11.1143 2 12.1493 2.20833 13.1146 2.625C14.0799 3.04167 14.9271 3.61458 15.6562 4.34375C16.3854 5.07292 16.9583 5.92167 17.375 6.89C17.7917 7.85847 18 8.89319 18 9.99417C18 11.0953 17.7917 12.1319 17.375 13.1042C16.9583 14.0764 16.3854 14.9271 15.6562 15.6562C14.9271 16.3854 14.0783 16.9583 13.11 17.375C12.1415 17.7917 11.1068 18 10.0058 18ZM10 16.5C11.8056 16.5 13.3403 15.8681 14.6042 14.6042C15.8681 13.3403 16.5 11.8056 16.5 10C16.5 8.19444 15.8681 6.65972 14.6042 5.39583C13.3403 4.13194 11.8056 3.5 10 3.5C8.19444 3.5 6.65972 4.13194 5.39583 5.39583C4.13194 6.65972 3.5 8.19444 3.5 10C3.5 11.8056 4.13194 13.3403 5.39583 14.6042C6.65972 15.8681 8.19444 16.5 10 16.5Z"
									fill="#2C3030"
								/>
							</g>
						</svg>
						Project Not Selected
					</div>

					<div className={styles.instructionDesc}>
						{/* eslint-disable-next-line react/no-unescaped-entities */}
						It looks like you haven't selected a project. Please select a project from
						the the dropdown above to proceed with your <b>AI Web Search</b>.
					</div>

					<div className={styles.instructionDesc}>
						This selection is necessary for the tool to provide accurate and relevant
						search results.
					</div>
				</div>
			)}
		</div>
	);
};

export default Instruction;
