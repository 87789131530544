import {forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState} from "react";

import type {ChangeEvent, SyntheticEvent} from "react";

import type {TRangeInput, HRangeInput} from "./types";

import css from "./rangeInput.module.scss";
import {MaxIcon, MinIcon} from "assets/icons";
import TextInput from "../TextInput";
import {convertToShortFormat} from "helpers/string";

const ShorthandConverter: {[notation: string]: number} = {
	k: 1000,
	m: 1000000,
	b: 1000000000,
};

function convertShorthandValue(value: string) {
	const match = value?.match?.(/^(\d+(\.\d+)?)([kmb])?$/i);
	if (match) {
		const numericValue = parseFloat(match[1]);
		const shorthandLetter = match[3] && match[3].toLowerCase();
		const multiplier = ShorthandConverter[shorthandLetter] || 1;

		return (numericValue * multiplier).toFixed(0);
	} else {
		return "";
	}
}

const removeDotAtEnd = (value: string) => value.replace(/\.$/, "");

const RangeInput = forwardRef<HRangeInput, TRangeInput>(function RangeInput(
	{
		onSubmit,
		minLabel = "",
		maxLabel = "",
		minPlaceholder = "",
		maxPlaceholder = "",
		defaultMin = "",
		defaultMax = "",
		...rest
	},
	ref,
) {
	const cachedMin = useRef("");
	const cachedMax = useRef("");

	const [minValue, setMinValue] = useState(convertToShortFormat(defaultMin));
	const [maxValue, setMaxValue] = useState(convertToShortFormat(defaultMax));

	const handleMinBlur = useCallback(() => {
		const cleanedMinValue = removeDotAtEnd(minValue);
		setMinValue(cleanedMinValue);

		const min = convertShorthandValue(cleanedMinValue);
		const max = convertShorthandValue(maxValue);

		if (max !== defaultMax || min !== defaultMin) {
			if (parseInt(max, 10) < parseInt(min, 10)) {
				if (cachedMin.current !== min || cachedMax.current !== max) {
					cachedMin.current = min;
					cachedMax.current = max;

					window.alert("Minimum value cannot be bigger than maximum value.");
				}
			} else {
				onSubmit(min, max);
			}
		}
	}, [defaultMax, defaultMin, maxValue, minValue, onSubmit]);

	const handleMaxBlur = useCallback(() => {
		const cleanedMaxValue = removeDotAtEnd(maxValue);
		setMaxValue(cleanedMaxValue);

		const min = convertShorthandValue(minValue);
		const max = convertShorthandValue(cleanedMaxValue);

		if (max !== defaultMax || min !== defaultMin) {
			if (parseInt(max, 10) < parseInt(min, 10)) {
				if (cachedMin.current !== min || cachedMax.current !== max) {
					cachedMin.current = min;
					cachedMax.current = max;

					window.alert("Minimum value cannot be bigger than maximum value.");
				}
			} else {
				onSubmit(min, max);
			}
		}
	}, [defaultMax, defaultMin, maxValue, minValue, onSubmit]);

	const handleMinInputChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
		const {value} = event.target;

		if (!value) {
			setMinValue("");
		} else if (/^([1-9]\d*(\.\d+)?)([kmb])?$/i.test(value) || /(\d+\.)$/i.test(value)) {
			if ((value?.match?.(/\./g) || []).length <= 1) {
				setMinValue(value);
			}
		}
	}, []);

	const handleMaxInputChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
		const {value} = event.target;

		if (!value) {
			setMaxValue("");
		} else if (/^([1-9]\d*(\.\d+)?)([kmb])?$/i.test(value) || /(\d+\.)$/i.test(value)) {
			if ((value?.match(/\./g) || []).length <= 1) {
				setMaxValue(value || "");
			}
		}
	}, []);

	const handleSubmit = useCallback(
		(e: SyntheticEvent) => {
			e.preventDefault();

			const max = convertShorthandValue(maxValue);
			const min = convertShorthandValue(minValue);

			if (max !== defaultMax || min !== defaultMin) {
				if (parseInt(max, 10) < parseInt(min, 10)) {
					if (cachedMin.current !== min || cachedMax.current !== max) {
						cachedMin.current = min;
						cachedMax.current = max;

						window.alert("Minimum value cannot be bigger than maximum value.");
					}
				} else {
					onSubmit(min, max);
				}
			}
		},
		[defaultMax, defaultMin, maxValue, minValue, onSubmit],
	);

	useImperativeHandle(
		ref,
		() => ({
			reset: () => {
				setMinValue(convertToShortFormat(defaultMin));
				setMaxValue(convertToShortFormat(defaultMax));
			},
		}),
		[defaultMax, defaultMin],
	);

	useEffect(() => {
		if (defaultMin && defaultMax) {
			setMinValue(convertToShortFormat(defaultMin));
			setMaxValue(convertToShortFormat(defaultMax));
		}
	}, [defaultMin, defaultMax]); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<form className={css.form} onSubmit={handleSubmit} {...rest}>
			<TextInput
				type="text"
				value={minValue}
				onBlur={handleMinBlur}
				placeholder={minPlaceholder}
				onChange={handleMinInputChange}
				rightIcon={<MinIcon />}
				label={minLabel}
				min="1"
			/>
			<div className={css.line}>
				<svg width={16} height={1} fill="none" xmlns="http://www.w3.org/2000/svg">
					<path stroke="#D1CDCC" d="M0 .5h16" />
				</svg>
			</div>
			<TextInput
				type="text"
				value={maxValue}
				onBlur={handleMaxBlur}
				placeholder={maxPlaceholder}
				onChange={handleMaxInputChange}
				rightIcon={<MaxIcon />}
				label={maxLabel}
				min="1"
			/>
			<button type="submit" className={css.submit} />
		</form>
	);
});

export default RangeInput;
