import HttpClient from "api/index";

class CandidateCompanyEventsApi extends HttpClient {
	async getCandidateCompanyEvents(pageNumber = 1, config = {}) {
		return this.doGet(`/company-event-types?page=${pageNumber}&per_page=50`, config);
	}

	async searchCandidateCompanyEvents(queryParam: string, pageNumber: number, config = {}) {
		return this.doGet(
			`/company-event-types${
				queryParam ? queryParam + "&" : "?"
			}page=${pageNumber}&per_page=50`,
			config,
		);
	}
}

const candidateCompanyEventsApi = new CandidateCompanyEventsApi();

export default candidateCompanyEventsApi;
