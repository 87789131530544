import {ref, object, string, boolean} from "yup";

import type {Field, TForm} from "types";
import {EyeShowIcon, EyeHideIcon} from "assets/icons";

const fields: Field[] = [
	{
		type: "text",
		name: "name",
		label: "Name",
		placeholder: "Name",
	},
	{
		type: "text",
		name: "lastName",
		label: "Last Name",
		placeholder: "Last Name",
	},
	{
		type: "password",
		name: "password",
		label: "Password",
		placeholder: "Password",
		RightIcon: EyeHideIcon,
		RightToggledIcon: EyeShowIcon,
	},
	{
		type: "password",
		name: "confirmPassword",
		label: "Confirm Password",
		placeholder: "Confirm Password",
		RightIcon: EyeHideIcon,
		RightToggledIcon: EyeShowIcon,
	},
	{
		type: "checkbox",
		name: "agree",
		label: (
			<>
				I have read and agree to the{" "}
				<a href="https://mapx-ai.com/privacy-policy" target="_blank" rel="noreferrer">
					Privacy Policy
				</a>{" "}
				and{" "}
				<a
					href="https://mapx-ai.com/terms-of-service"
					target="_blank"
					rel="license noreferrer"
				>
					Terms and Conditions
				</a>
				.
			</>
		),
	},
];

const schema = object().shape({
	name: string().trim().required("Name is a required field."),
	lastName: string().trim().required("Last Name is a required field."),
	password: string()
		.required("New Password is a required field")
		.matches(/^[^\s]+$/, "Must not contain whitespace.")
		.matches(/[A-Z]/, "Must have at least one Uppercase.")
		.matches(/[a-z]/, "Must have at least one Lowercase.")
		// eslint-disable-next-line no-useless-escape
		.matches(/[!@#\$%^&*(),.?":{}|<>]/, "Must have at least one Special Symbol.")
		.min(8, "Must be minimum 8 characters long.")
		.max(20, "Must be maximum 20 characters long."),
	confirmPassword: string()
		.required("This field is required.")
		.oneOf([ref("password"), null], "Passwords do not match."),
	agree: boolean().oneOf(
		[true],
		"You must agree to the Terms and Conditions and Privacy Policy.",
	),
});

const acceptInvitation: TForm = {
	fields,
	schema,
};

export default acceptInvitation;
