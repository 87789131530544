export const emailPattern = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

const checkPasswordValidity = (value) => {
	let failedRules = [];

	const isNotWhiteSpace = /^\S+$/g;

	if (!isNotWhiteSpace.test(value)) {
		failedRules.push("whitespace"); // "Password must not contain Whitespaces."
	}

	const isContainsUppercase = /^(?=.*[A-Z]).*$/;

	if (!isContainsUppercase.test(value)) {
		failedRules.push("uppercase"); // "Password must have at least one Uppercase Character."
	}

	const isContainsLowercase = /^(?=.*[a-z]).*$/;

	if (!isContainsLowercase.test(value)) {
		failedRules.push("lowercase"); // "Password must have at least one Lowercase Character."
	}

	const isContainsNumber = /^(?=.*[0-9]).*$/;

	if (!isContainsNumber.test(value)) {
		failedRules.push("digit"); // "Password must contain at least one Digit."
	}

	const isContainsSymbol = /^(?=.*[~`!@#$%^&*()--+={}[\]|\\:;"'<>,.?/_]).*$/;

	if (!isContainsSymbol.test(value)) {
		failedRules.push("special_symbol"); // "Password must contain at least one Special Symbol."
	}

	const isValidLength = /^.{8,20}$/;

	if (!isValidLength.test(value)) {
		failedRules.push("length"); // "Password must be 8-20 Characters Long."
	}

	return failedRules;
};

export const FormValidation = ({key = "", value = "", matchValue = ""}) => {
	switch (key) {
		case "email":
			const validEmail = emailPattern.test(value);

			return {
				valid: validEmail,
				error: validEmail ? "" : "You have entered an invalid e-mail address!",
			};
		case "password":
			const validPassword = value.length > 7;

			return {
				valid: validPassword,
				error: validPassword ? "" : "Password should contain minimum 8 character or above!",
			};
		case "new_password":
			const failedRules = checkPasswordValidity(value);

			return {
				valid: failedRules.length === 0,
				error: !failedRules.length ? "" : failedRules,
			};
		case "conf_password":
			const isValidPassword = value === matchValue;

			return {
				valid: isValidPassword,
				error: isValidPassword ? "" : "The passwords you entered do not match.",
			};
		case "user_name":
			const validName = value.length > 3;

			return {
				valid: validName,
				error: validName ? "" : "Name is not correct",
			};
		case "first_name":
			const validFirstName = value.length > 1;

			return {
				valid: validFirstName,
				error: validFirstName
					? ""
					: "First name should contain minimum 2 character or above",
			};
		case "last_name":
			const validLastName = value.length > 3;

			return {
				valid: validLastName,
				error: validLastName ? "" : "Last name should contain minimum 4 character or above",
			};
		case "role":
			const role = value.length > 0;

			return {
				valid: role,
				error: role ? "" : "SelectDropdown Role",
			};
		case "project_name":
			const validProjectName = value.length > 0;

			return {
				valid: validProjectName,
				error: validProjectName ? "" : "Enter Project Name",
			};
		case "role_location":
			const validLocation = value.length > 0;

			return {
				valid: validLocation,
				error: validLocation ? "" : "Enter Role Location",
			};
		case "assignment":
			return {
				valid: value != null,
				error: value != null ? "" : "Please SelectDropdown Assignment",
			};
		case "assignment_id":
			const isValid = value != null;

			return {
				valid: isValid,
				error: isValid ? "" : "Please SelectDropdown Assignment Id",
			};
		default:
			return {
				valid: true,
				error: "",
			};
	}
};

export const newPasswordRules = [
	{
		rule: "whitespace",
		label: "Password must not contain Whitespaces.",
		isValid: false,
	},
	{
		rule: "uppercase",
		label: "Password must have at least one Uppercase Character.",
		isValid: false,
	},
	{
		rule: "lowercase",
		label: "Password must have at least one Lowercase Character.",
		isValid: false,
	},
	{
		rule: "special_symbol",
		label: "Password must contain at least one Special Symbol.",
		isValid: false,
	},
	{
		rule: "length",
		label: "Password must be 8-20 Characters Long.",
		isValid: false,
	},
];
