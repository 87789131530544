import {TRootState} from "types";
import {createSelector} from "reselect";
import {targetListCandidatesSelector} from "../target-list/targetListSelectors";

export const generateCandidatesContactDetailsForAllProjectSelector = (state: TRootState) =>
	state.mapxProject.generateCandidatesContactDetailsByProject;

export const generateCandidatesContactDetailsFlattenedForAllProjectSelector = (
	state: TRootState,
) => {
	const contactDetails = state.mapxProject.generateCandidatesContactDetailsByProject;

	return [...Object.values(contactDetails).flat()];
};

export const targetListIdSelector = (_: TRootState, id: number) => id;
export const candidateIdSelector = (_: TRootState, __: TRootState, candidateId: number) =>
	candidateId;

export const getAllGenerateContactDetailsCandidateIdsForCurrentProjectSelector = createSelector(
	generateCandidatesContactDetailsForAllProjectSelector,
	targetListCandidatesSelector,
	targetListIdSelector,
	(generateCandidatesContactDetailsForAllProject, targetListCandidates, tlId) => {
		if (tlId) {
			return targetListCandidates
				?.filter((candidate: {id: number}) =>
					Object.values(generateCandidatesContactDetailsForAllProject)?.includes(
						Number(candidate.id),
					),
				)
				.map((candidate: {id: number}) => candidate.id);
		}

		return [];
	},
);

export const isContactDetailsForCurrentCandidateInProgressForCurrentProjectSelector =
	createSelector(
		generateCandidatesContactDetailsForAllProjectSelector,
		targetListIdSelector,
		candidateIdSelector,
		(generateCandidatesContactDetailsForAllProject, tlId, candidateId) => {
			if (tlId && candidateId) {
				const objectKey =
					typeof generateCandidatesContactDetailsForAllProject === "object"
						? Object.keys(generateCandidatesContactDetailsForAllProject).find(
								(id) => id.toString() === tlId.toString(),
						  )
						: null;

				if (
					objectKey &&
					generateCandidatesContactDetailsForAllProject[objectKey] !== undefined &&
					Array.isArray(generateCandidatesContactDetailsForAllProject[objectKey])
				) {
					return generateCandidatesContactDetailsForAllProject[objectKey].includes(
						candidateId,
					);
				}
			}

			return false;
		},
	);

export const isCandidateContactDetailsGenerating = createSelector(
	[generateCandidatesContactDetailsForAllProjectSelector, (_, candidate) => candidate],
	(candidatesContactDetailsInProgress, candidateInfo) => {
		return (
			Object.values(candidatesContactDetailsInProgress)?.includes(candidateInfo.id) &&
			candidateInfo.contact_details === null
		);
	},
);
