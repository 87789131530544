import {type FC, Fragment} from "react";
import {isNull} from "lodash";
import {useHistory} from "react-router-dom";

import {Button} from "components";
import {CloseOnboardingTooltip} from "assets/icons";
import {useAppDispatch, useAppSelector} from "hooks";
import {userLicenseTypeNameSelector} from "store/mapx/user/userSelectors";
import {onboardingForStarterUserSelector} from "store/mapx/project/projectSelectors";
import {setOnboardingStep, setOnboardingVisibility} from "store/mapx/project/projectActions";

import TooltipClose from "./TooltipClose";
import TooltipNavigation from "./TooltipNavigation";
import OnboardingFilterComponent from "./OnboardingFilterComponent";
import {returnStepContent, renderText, contentClassName} from "./utils";
import {EOnboardingTooltipSteps, type TOnboardingTooltipProps} from "./types";
import styles from "./OnboardingTooltip.module.scss";

const OnboardingTooltip: FC<TOnboardingTooltipProps> = ({step, className = ""}) => {
	const history = useHistory();
	const dispatch = useAppDispatch();

	const open = useAppSelector(onboardingForStarterUserSelector);
	const userLicenseTypeName = useAppSelector(userLicenseTypeNameSelector);
	const isStarterUser: boolean = userLicenseTypeName === "Starter";

	const closeHandler = () => {
		dispatch(setOnboardingStep(EOnboardingTooltipSteps.First));
		dispatch(setOnboardingVisibility(false));

		history.push("/home");
	};

	return (
		<Fragment>
			{open && !isNull(step) && (
				<div className={styles.wrapper}>
					<div className={styles.wrapper__bg} />

					<div className={contentClassName(step, className)}>
						<OnboardingFilterComponent step={step} />

						<div className={styles.wrapper__content__container}>
							{step !== EOnboardingTooltipSteps.Search ? (
								<TooltipClose closeHandler={closeHandler} text={renderText(step)} />
							) : (
								<Button
									defaultBtnStyle
									onClick={closeHandler}
									className={styles.wrapper__content__container_close}
								>
									<CloseOnboardingTooltip />
								</Button>
							)}

							{returnStepContent(step, isStarterUser)}

							<TooltipNavigation step={step} closeHandler={closeHandler} />
						</div>
					</div>
				</div>
			)}
		</Fragment>
	);
};

export default OnboardingTooltip;
