import type {FC} from "react";
import classNames from "classnames";

import Checkbox from "../Checkbox";

import type {ICheckboxRowProps} from "./types";
import styles from "./CheckboxRow.module.scss";

const CheckboxRow: FC<ICheckboxRowProps> = ({inputProps, containerClass = ""}) => (
	<Checkbox
		{...inputProps}
		useCustomOnChange
		dataTestid="checkbox"
		className={classNames(styles.checkbox, {
			[containerClass]: containerClass,
		})}
	>
		<span className={styles.checkbox__text}>{inputProps.label}</span>
	</Checkbox>
);

export default CheckboxRow;
