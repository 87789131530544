import React from "react";

const RejectMinusIcon = ({color = "#C91515"}) => {
	return (
		<>
			<svg
				width="17"
				height="16"
				viewBox="0 0 17 16"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M8.49967 14.6667C12.1816 14.6667 15.1663 11.6819 15.1663 8.00004C15.1663 4.31814 12.1816 1.33337 8.49967 1.33337C4.81778 1.33337 1.83301 4.31814 1.83301 8.00004C1.83301 11.6819 4.81778 14.6667 8.49967 14.6667Z"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M5.83301 8H11.1663"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</svg>
		</>
	);
};

export default RejectMinusIcon;
