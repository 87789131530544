import {forwardRef} from "react";
import classNames from "classnames";
import usePortal from "react-useportal";

import {useLockBodyScroll} from "hooks";

import type {TCustomModalProps} from "./types";
import styles from "./CustomModal.module.scss";

const CustomModal = forwardRef<HTMLDivElement, TCustomModalProps>(
	({children, modalDisplay, className = ""}, ref) => {
		const {Portal} = usePortal();

		useLockBodyScroll(modalDisplay);

		return modalDisplay ? (
			<Portal>
				<div className={styles.wrapper}>
					<div
						ref={ref}
						data-testid="modal-container"
						className={classNames(styles.wrapper__container, {
							[className]: className,
						})}
					>
						{children}
					</div>
				</div>
			</Portal>
		) : null;
	},
);

CustomModal.displayName = "CustomModal";

export default CustomModal;
