import {useCallback, useRef, useState} from "react";
import classNames from "classnames";

import {ArrowDown} from "assets/icons";
import {useOutsideClick} from "hooks";

import {TSelectWithActionOptions, ISelectWithActionProps, TSelectWithActionGroup} from "./types";

import styles from "./index.module.scss";

const SelectWithAction = ({
	action,
	actionClick,
	className,
	contentEditable = false,
	isSelected = false,
	options,
	placeholder,
	selected,
	setSelected,
}: ISelectWithActionProps) => {
	const ref = useRef<HTMLDivElement>(null);

	const [expanded, setExpanded] = useState<boolean>(false);

	const toggleExpand = useCallback(() => {
		setExpanded(!expanded);
	}, [expanded]);

	const closeExpand = useCallback(() => {
		setExpanded(false);
	}, []);

	const handleSelect = useCallback(
		(option: TSelectWithActionOptions) => {
			setSelected(option);
			toggleExpand();
		},
		[setSelected, toggleExpand],
	);

	const handleActionClick = useCallback(() => {
		actionClick && actionClick();
		toggleExpand();
	}, [actionClick, toggleExpand]);

	useOutsideClick(ref, closeExpand);

	return (
		<div className={classNames(styles.container, {[styles.expanded]: expanded}, className)}>
			<div
				ref={ref}
				role="select"
				className={classNames(styles.select, {[styles.expanded]: expanded})}
			>
				<div
					className={classNames(styles.textbox, {
						[styles.expanded]: expanded,
						[styles.editable]: contentEditable,
						[styles.data]: selected,
					})}
					suppressContentEditableWarning
					contentEditable={contentEditable}
					role="textbox"
					onClick={toggleExpand}
				>
					{(selected && selected.label) || placeholder}
					<ArrowDown />
				</div>
				<div
					className={classNames(styles.dropdown, {
						[styles.expanded]: expanded,
						[styles.withAction]: action && !isSelected,
					})}
				>
					{options.map((option: TSelectWithActionOptions) => (
						<div
							role="button"
							key={option.value}
							className={styles.option}
							onClick={() => handleSelect(option)}
						>
							{option.label}
						</div>
					))}
					{action && !isSelected && (
						<div role="button" className={styles.action} onClick={handleActionClick}>
							{action}
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

// To not set z-index for each SelectWithAction, group them together
// in reverse order by wrapping with <SelectWithAction.Group>
const SelectWithActionGroup = ({children, ...rest}: TSelectWithActionGroup) => (
	<div className={styles.group} {...rest}>
		{children}
	</div>
);

SelectWithAction.Group = SelectWithActionGroup;

export default SelectWithAction;
