import {Checkbox, ResetFilters} from "components";

import {SearchInput} from "mapx-components";
import styles from "mapx-components/Filters/KeywordSearchFilter/styles.module.scss";
import React from "react";
import {useSelector} from "react-redux";
import {companyTypeOptionSelector} from "store/mapx/search/searchSelectors";
import {TCompanyTypeSearchFilterProps} from "./types";
import useFetchListOptions from "hooks/useFetchListOptions";
import {getCompanyTypeList} from "store/mapx/search/searchAsyncActions";
import {STCompanyType} from "api/companyApi/types";

const CompanyTypeSearchFilter = ({
	selectedCompanyTypes,
	handleResetClick,
	handleOnChange,
	displayResetFilterOption,
}: TCompanyTypeSearchFilterProps) => {
	const options: STCompanyType[] = useSelector(companyTypeOptionSelector);

	const {filteredData, onFilterChanged, loading} = useFetchListOptions({
		options: options,
		callbackMethod: getCompanyTypeList,
	});

	return (
		<div>
			<div className={styles.searchInputWrapper}>
				<SearchInput
					onChange={onFilterChanged}
					placeholder="Search for a Company Type"
					type="text"
					errorText={undefined}
					errorClass={undefined}
					isLoading={loading}
				/>
			</div>

			{displayResetFilterOption && (
				<ResetFilters
					parentStyle={{color: "#5A5A5A", marginRight: 19}}
					onClick={handleResetClick}
					displayIcon={true}
				>
					Clear Selection
				</ResetFilters>
			)}

			<div className={styles.checkboxList}>
				{filteredData.map((s: STCompanyType) => {
					return (
						<Checkbox
							borderColor="#0C5850"
							containerClass={styles.checkboxContainer}
							isChecked={selectedCompanyTypes.includes(s.id)}
							key={s.id}
							label={`${s.name}`}
							onChange={() => handleOnChange(s.id)}
							value={s.name}
						/>
					);
				})}
			</div>
		</div>
	);
};

export default CompanyTypeSearchFilter;
