import type {FC} from "react";

import PieChartSkeleton from "components/Charts/PieChart/PieChartSkeleton";

import styles from "./CompanySkeletons.module.scss";

const RoundChartGroupSkeleton: FC = () => (
	<div className={styles.round__chart}>
		<PieChartSkeleton />
		<PieChartSkeleton />
	</div>
);

export default RoundChartGroupSkeleton;
