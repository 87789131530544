import {type FC, Fragment, useState} from "react";
import classNames from "classnames";

import {ArrowDown} from "assets/icons";
import {transformToTooltipData} from "components/Tooltip/utils";
import type {TChartTooltipProps} from "components/Tooltip/types";
import CandidatesTooltip from "components/Tooltip/CandidatesTooltip";
import type {TFunctionalData} from "mapx-pages/CompanyDetails/BackgroundAnalysis/types";

import {colors} from "../PieChart/utils";
import type {IChartDataProps, IPieChartProps} from "../PieChart/types";

import styles from "./CustomBarChart.module.scss";

const CustomBarChart: FC<IPieChartProps> = ({isRoundedChartVal, data}) => {
	const [isActive, setIsActive] = useState<number | undefined>(undefined);

	const changeActive = (id?: number) => {
		if (isActive === id) {
			setIsActive(undefined);
		} else {
			setIsActive(id);
		}
	};

	const filterChartItems = data.find((item) => item.id === isActive);

	const specialisms = filterChartItems?.children
		?.map((el) => {
			return {
				...el,
				percent: parseFloat(
					(
						(el.candidates_count /
							filterChartItems?.totalCandidateCountForSkillAnalysis) *
						100
					).toFixed(1),
				),
			};
		})
		.filter((el) => el.percent >= 0.3)
		.sort((a, b) => b.percent - a.percent);

	const renderSpecialism = specialisms?.map((el: TFunctionalData) => {
		const getName = () => {
			if (isActive === -1) {
				return `${el.job_function.name}: ${el.name}`;
			} else {
				return el.name;
			}
		};

		return (
			<Fragment key={`${el.id}-${el.name}-${el.candidates_count}`}>
				<div
					data-tooltip-delay-show={300}
					className={styles.wrapper__children}
					data-tooltip-id={`${el.id}-${el.percent}`}
				>
					<p className={styles.wrapper__children_name}>
						{getName()} ({el.candidates_count || 0})
					</p>

					<p className={styles.wrapper__children_percent}>{el.percent}%</p>
				</div>

				{el?.candidates && (
					<CandidatesTooltip
						title={el.name}
						id={`${el.id}-${el.percent}`}
						percent={`${el.percent}%`}
						data={transformToTooltipData(el?.candidates) as TChartTooltipProps[]}
					/>
				)}
			</Fragment>
		);
	});

	const renderChartItem = data?.map((elem: IChartDataProps, idx: number) => {
		const color = colors[idx];

		const originalValue = Number(elem.frequencyValue.toFixed(1)) || 0;

		return (
			<Fragment key={`${elem.id}-${elem.name}`}>
				<div
					role="button"
					className={classNames(styles.wrapper__item, {
						[styles.wrapper__item_empty]: !elem?.children?.length,
					})}
					data-testid="chart-item"
					onClick={elem?.children && (() => changeActive(elem?.id))}
				>
					<div
						className={styles.wrapper__item_background}
						style={{
							width: `${originalValue > 100 ? 100 : originalValue}%`,
							backgroundColor: `${color}`,
						}}
					/>
					<div
						className={styles.wrapper__item_content}
						data-tooltip-id={`${elem.id}-${originalValue}-${elem?.children?.length}-${elem.name}-${elem?.candidateCount}`}
					>
						<p
							className={classNames(styles.wrapper__item_content_name, {
								[styles.wrapper__item_content_name_active]: isActive === elem.id,
							})}
						>
							{!!elem?.children?.length && (
								<ArrowDown
									stroke="#2C3030"
									className={classNames(styles.wrapper__item_content_icon, {
										[styles.wrapper__item_content_icon_active]:
											isActive === elem.id,
									})}
								/>
							)}
							{elem.name} {`(${elem?.candidateCount || 0})`}
						</p>

						<p className={styles.wrapper__item_content_percent}>
							{isRoundedChartVal ? Math.round(originalValue) : originalValue}%
						</p>
					</div>
				</div>

				{isActive === elem.id &&
					filterChartItems?.children &&
					(elem.id === -1 ||
						filterChartItems?.children[0]?.job_function?.id === elem?.id) &&
					renderSpecialism}

				{elem?.candidates && (
					<CandidatesTooltip
						title={elem.name}
						percent={`${originalValue}%`}
						data={transformToTooltipData(elem?.candidates) as TChartTooltipProps[]}
						id={`${elem.id}-${originalValue}-${elem?.children?.length}-${elem.name}-${elem?.candidateCount}`}
					/>
				)}
			</Fragment>
		);
	});

	return <div className={styles.wrapper}>{renderChartItem}</div>;
};

export default CustomBarChart;
