import {useState} from "react";
import {debounce} from "lodash";
import {useAppDispatch} from "hooks";
import {getJobTitles} from "store/mapx/search/jobTitlesAsyncActions";

// let cancelToken: CancelTokenSource;

const useJobTitleSearchService = (companyId: Nullable<number> = null) => {
	const dispatch = useAppDispatch();

	const [loadingJobTitle, setLoadingJobTitle] = useState<boolean>(false);

	const fetchJobTitle = debounce(
		async (inputString: string, callback: (options: string[]) => void) => {
			setLoadingJobTitle(true);

			const response = await dispatch(getJobTitles(inputString, companyId));

			if (response === undefined) {
				setLoadingJobTitle(true);
			}

			if (Array.isArray(response)) {
				setLoadingJobTitle(false);

				const data: string[] = response;

				return callback([...data, inputString]);
			} else {
				setLoadingJobTitle(false);

				return callback([]);
			}
		},
		800,
	);

	return {
		loadingJobTitle,
		setLoadingJobTitle,
		fetchJobTitle,
	};
};

export default useJobTitleSearchService;
