import {useRef, useState, useEffect} from "react";
import type {FC, CSSProperties} from "react";
import classNames from "classnames";

import type {TOutsiderProps} from "./types";

export const Outsider: FC<TOutsiderProps> = ({onOutside, children, className = ""}) => {
	const wrapperRef = useRef<HTMLDivElement | null>(null);
	const [style, setStyles] = useState<CSSProperties>({
		opacity: 0,
	});

	useEffect(() => {
		const eventHandler = (event: Event) => {
			if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
				setStyles({opacity: 0});
				event?.preventDefault();

				setTimeout(() => {
					onOutside();
				}, 400);
			}
		};

		setStyles({opacity: 1});
		window.addEventListener("click", eventHandler, true);

		return () => {
			window.removeEventListener("click", eventHandler, true);
		};
	}, [onOutside]);

	return (
		<div
			style={style}
			ref={wrapperRef}
			className={classNames("outside-wrapper", {[className]: className})}
		>
			{children}
		</div>
	);
};
