import type {FC} from "react";
import ContentLoader from "react-content-loader";

const CompanySkeletonTooltip: FC = () => (
	<ContentLoader
		speed={2}
		height={318}
		width={"100%"}
		backgroundColor="#DCE7E7"
		foregroundColor="#F9F9F9"
	>
		<circle cx="40" cy="40" r="32.3" />
		<rect x="35%" y="15" rx="5" ry="5" width="65%" height="20" />
		<rect x="35%" y="45" rx="5" ry="5" width="65%" height="20" />

		<rect x="1%" y="95.7" rx="5" ry="5" width="80%" height="20" />

		<rect x="1%" y="135" rx="5" ry="5" width="99%" height="15" />
		<rect x="1%" y="155" rx="5" ry="5" width="99%" height="15" />
		<rect x="1%" y="175" rx="5" ry="5" width="99%" height="15" />

		<rect x="1%" y="205" rx="5" ry="5" width="15%" height="30" />
		<rect x="20%" y="205" rx="5" ry="5" width="22%" height="30" />
		<rect x="48%" y="205" rx="5" ry="5" width="18%" height="30" />

		<rect x="1%" y="255" rx="5" ry="5" width="25%" height="25" />
		<rect x="30%" y="255" rx="5" ry="5" width="25%" height="25" />
	</ContentLoader>
);

export default CompanySkeletonTooltip;
