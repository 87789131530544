import type {
	SRUserUpdate,
	STGetInvitations,
	STInvitations,
	STOrganisationsStats,
	STOrganisationStatistics,
	STUsersTransfer,
} from "api/organisationApi/types";

import HttpClient from "api/index";
import {AxiosRequestConfig} from "axios";

class MapXOrganisationApi extends HttpClient {
	async searchUsers(
		query: string,
		pagination: {page: 1; perPage: 1000},
		config: AxiosRequestConfig = {},
	) {
		query = `?q=${query}&page=${pagination.page}&per_page=${pagination.perPage} `;

		return this.doGet(`/users${query}`, config);
	}

	async getInvitations(params?: STGetInvitations) {
		return this.doGet("/invitations?page=1&per_page=999999", {
			params,
		});
	}

	async getOrganisations() {
		return this.doGet("/organisations?page=1&per_page=999999");
	}

	async getUsers() {
		return this.doGet("/users?page=1&per_page=999999");
	}

	async getSeats(organisationID: number) {
		return this.doGet(`/organisations/${organisationID}/seats`);
	}

	async getOrganisationUsers(organization_id: string | number, params = {}) {
		return this.doGet(`/users?&per_page=99999&organisation_id=${organization_id}`, {
			params,
		});
	}

	async getOrganisationUsersForAdmin(organization_id: number, params = {}) {
		return this.doGet(`/users?&per_page=99999&organisation_id=${organization_id}`, {
			params,
		});
	}

	async deleteUser(userID: string | number) {
		return this.doDelete(`/users/${userID}`);
	}

	async usersTransfer(body: STUsersTransfer) {
		return this.doPost("/users/transfer", body);
	}

	async userUpdate(userId: string | number, body: SRUserUpdate) {
		return this.doPatch(`/users/${userId}`, body);
	}

	async resendInvitation(invitationID: string | number) {
		return this.doPost(`/invitations/${invitationID}/resend`);
	}

	async verifyInvitationCode(invitationCode: string | number) {
		return this.doGet(`/invitation-codes/${invitationCode}`);
	}

	async deleteInvitation(invitationID: string | number) {
		return this.doDelete(`/invitations/${invitationID}`);
	}

	async createOrganisation(name: string) {
		return this.doPost("/organisations", {name});
	}

	async createInvitation(invitationBody: STInvitations) {
		return this.doPost("/invitations", invitationBody);
	}

	async getOrganizationList(params: STOrganisationsStats) {
		return this.doGet("/organisations/stats?page=1&per_page=999999", {
			params,
		});
	}

	async postOrganizationStatistics() {
		return this.doPost("/organisations");
	}

	async deleteOrganization(organization_id: string | number) {
		return this.doDelete(`/organisations/${organization_id}`);
	}

	async updateOrganizationStatistics(
		organization_id: string | number,
		body: STOrganisationStatistics,
	) {
		return this.doPatch(`/organisations/${organization_id}`, body);
	}
}

const mapXOrganisationApi = new MapXOrganisationApi();

export default mapXOrganisationApi;
