import {
	ADD_PAGINATED_DATA_FOR_COMPANY_ORGANIZATIONAL_CHART,
	CLEAR_COMPANY_INDUSTRY_BACKGROUND,
	CLEAR_COMPANY_ORGANIZATIONAL_CHART,
	CLEAR_COMPANY_PAGE_FILTERS_AND_ALL_THE_RESPONSES,
	CLEAR_MAP_THIS_FUNCTION_FOR_CURRENT_COMPANY,
	CLEAR_SINGLE_COMPANY_DONE,
	COMPANY_ORGANIZATIONAL_CHART_PAGINATION_LOADING,
	GET_COMPANIES_BY_FILTER_DONE,
	GET_COMPANIES_BY_FILTER_INIT,
	GET_COMPANIES_DONE,
	GET_COMPANIES_FAIL,
	GET_COMPANIES_INIT,
	GET_COMPANY_ORG_CHART_FILTERING,
	GET_COMPANY_ORG_CHART_FILTERS_DONE,
	GET_LICENSE_TYPES_DONE,
	GET_LICENSE_TYPES_FAIL,
	GET_LICENSE_TYPES_INIT,
	GET_SIMILAR_COMPANIES_DONE,
	GET_SIMILAR_COMPANIES_FAIL,
	GET_SIMILAR_COMPANIES_INIT,
	GET_SINGLE_COMPANY_DONE,
	GET_SINGLE_COMPANY_FAIL,
	GET_SINGLE_COMPANY_INIT,
	LOAD_MORE_COMPANIES_INIT,
	LOGOUT,
	RESET_COMPANY_REVENUE_DISTRIBUTION_DATA,
	RESET_COMPANY_SIZE_DISTRIBUTION_DATA,
	SET_COMPANY_ETHNIC_DIVERSITY_DATA,
	SET_COMPANY_ETHNIC_DIVERSITY_INIT,
	SET_COMPANY_FREQUENTLY_HIRED_FROM_DATA,
	SET_COMPANY_FUNCTIONAL_BACKGROUND,
	SET_COMPANY_GENDER_DIVERSITY_DATA,
	SET_COMPANY_GENDER_DIVERSITY_INIT,
	SET_COMPANY_INDUSTRY_BACKGROUND,
	SET_COMPANY_ORGANIZATIONAL_CHART,
	SET_COMPANY_REVENUE_DISTRIBUTION_DATA,
	SET_COMPANY_SIZE_DISTRIBUTION_DATA,
	SET_COMPANY_TRENDS_STATS,
	SET_COMPANY_TRENDS_STATS_INIT,
	SET_MAP_THIS_FUNCTION_FOR_CURRENT_COMPANY,
	SET_MAP_THIS_FUNCTION_IS_IN_PROGRESS_FOR_CURRENT_COMPANY,
	SET_ORGCHART_KEYWORDS_FILTERS_DROPDOWN_DATA,
	UPDATE_TOOLTIP_COMPANIES,
	UPDATE_TOOLTIP_COMPANIES_FAILED,
	UPDATE_TOOLTIP_COMPANIES_PROGRESS,
} from "../mapXActionTypes";

const initSize = {
	min_size: 1,
	max_size: 15000,
	data: [],
};

const initRevenue = {
	min_revenue: 1,
	max_revenue: 1,
	data: [],
};

export const companyInitialState = {
	companies: [],
	paginationInfo: {},
	companiesError: false,
	companiesInProgress: false,
	filteredCompaniesInProgress: false,
	companiesOrgChartFilteringInProgress: false,
	companiesOrgChartPaginationInProgress: false,
	sizeFilterInProgress: false,
	revenueFilterInProgress: false,
	currentCompany: null,
	companyError: false,
	similarCompanies: [],
	similarCompaniesError: false,
	similarCompaniesInProgress: false,
	companySizeDistributeData: initSize,
	companyRevenueDistributeData: initRevenue,
	companyGenderDiversityDataInProgress: true,
	companyGenderDiversityData: null,
	companyEthnicDiversityDataInProgress: true,
	companyEthnicDiversityData: null,
	companiesOrgChartFilters: {
		countries: [],
		current_job_functions: [],
		current_keywords: [],
		current_seniority: [],
		current_specialisms: [],
		regions: [],
	},
	trendsStatsInProgress: true,
	trendsStats: null,
	organizationalChart: null,
	companyFrequentlyHiredFromData: null,
	industryBackground: [],
	functionalBackground: [],
	licenseTypes: [],
	tooltipCompanies: {},
	tooltipCompaniesProgress: false,
	getLicenseTypeInProgress: false,
	currentIndustryDiversityTab: 0,
	orgchart_keywords_filters_dropdown_data: [],
	// map this
	candidateMappingForCompanies: {}, // Structure companyId: Info
	candidateMappingInProgressForCompanies: {}, // companyId: true/false
};

const companyReducer = (state = companyInitialState, action) => {
	switch (action.type) {
		case GET_SINGLE_COMPANY_INIT:
			return {...state, companiesInProgress: true};
		case GET_COMPANY_ORG_CHART_FILTERING:
			return {...state, companiesOrgChartFilteringInProgress: action.payload};
		case GET_COMPANY_ORG_CHART_FILTERS_DONE:
			return {...state, companiesOrgChartFilters: action.payload};

		case CLEAR_SINGLE_COMPANY_DONE:
			return {
				...state,
				currentCompany: null,
			};

		case GET_SINGLE_COMPANY_DONE:
			return {
				...state,
				currentCompany: action.payload,
				companiesInProgress: false,
			};
		case GET_SINGLE_COMPANY_FAIL:
			return {
				...state,
				companyError: true,
			};

		case GET_COMPANIES_INIT:
			return {
				...state,
				companiesInProgress: true,
				sizeFilterInProgress: true,
				revenueFilterInProgress: true,
			};
		case GET_COMPANIES_DONE:
			const prevCompanies = action.payload.pageNumber > 1 ? state.companies : [];
			const companies = [...prevCompanies, ...action.payload.data];
			const paginationInfo = action.payload.paginationInfo;

			return {
				...state,
				companiesInProgress: false,
				companies,
				paginationInfo,
			};
		case GET_COMPANIES_FAIL:
			return {
				...state,
				companiesError: true,
			};

		case GET_COMPANIES_BY_FILTER_INIT:
			return {
				...state,
				filteredCompaniesInProgress: true,
				// sizeFilterInProgress: true,
				// revenueFilterInProgress: true,
			};
		case GET_COMPANIES_BY_FILTER_DONE:
			const prevFilteredCompanies = action.payload.pageNumber > 1 ? state.companies : [];
			const filteredCompanies = [...prevFilteredCompanies, ...action.payload.data];
			const filteredPaginationInfo = action.payload.paginationInfo || {};

			return {
				...state,
				companiesInProgress: false,
				filteredCompaniesInProgress: false,
				companies: filteredCompanies,
				paginationInfo: filteredPaginationInfo,
			};

		case GET_SIMILAR_COMPANIES_INIT:
			return {...state, similarCompaniesInProgress: true};
		case GET_SIMILAR_COMPANIES_DONE:
			const similarCompanies = [...action.payload];

			return {
				...state,
				similarCompanies,
				similarCompaniesInProgress: false,
			};
		case GET_SIMILAR_COMPANIES_FAIL:
			return {
				...state,
				similarCompaniesError: true,
			};
		case GET_LICENSE_TYPES_INIT:
			return {...state, getLicenseTypeInProgress: true};
		case GET_LICENSE_TYPES_DONE:
			return {
				...state,
				getLicenseTypeInProgress: false,
				licenseTypes: action.payload || [],
			};
		case GET_LICENSE_TYPES_FAIL:
			return {
				...state,
				getLicenseTypeInProgress: false,
			};
		case LOAD_MORE_COMPANIES_INIT:
			return {...state, companiesInProgress: false};
		case SET_COMPANY_SIZE_DISTRIBUTION_DATA:
			return {
				...state,
				companySizeDistributeData: action.payload,
				sizeFilterInProgress: false,
			};
		case RESET_COMPANY_SIZE_DISTRIBUTION_DATA:
			return {
				...state,
				companySizeDistributeData: initSize,
			};

		case SET_COMPANY_REVENUE_DISTRIBUTION_DATA:
			return {
				...state,
				companyRevenueDistributeData: action.payload,
				revenueFilterInProgress: false,
			};
		case RESET_COMPANY_REVENUE_DISTRIBUTION_DATA:
			return {
				...state,
				companyRevenueDistributeData: initRevenue,
			};

		case SET_COMPANY_GENDER_DIVERSITY_DATA:
			return {
				...state,
				companyGenderDiversityData: action.payload,
				companyGenderDiversityDataInProgress: false,
			};

		case SET_COMPANY_GENDER_DIVERSITY_INIT:
			return {
				...state,
				companyGenderDiversityDataInProgress: true,
			};

		case SET_COMPANY_ETHNIC_DIVERSITY_INIT:
			return {
				...state,
				companyEthnicDiversityDataInProgress: true,
			};
		case SET_COMPANY_ETHNIC_DIVERSITY_DATA:
			return {
				...state,
				companyEthnicDiversityData: action.payload,
				companyEthnicDiversityDataInProgress: false,
			};
		case SET_COMPANY_FREQUENTLY_HIRED_FROM_DATA: {
			const {results = [], pagination} = action.payload;
			const {companyFrequentlyHiredFromData} = state;

			return {
				...state,
				companyFrequentlyHiredFromData: {
					results:
						pagination?.page === 1
							? results
							: [...(companyFrequentlyHiredFromData.results || []), ...results],
					pagination,
				},
			};
		}
		case SET_COMPANY_TRENDS_STATS_INIT:
			return {
				...state,
				trendsStatsInProgress: true,
			};
		case SET_COMPANY_TRENDS_STATS:
			return {
				...state,
				trendsStats: action.payload,
				trendsStatsInProgress: false,
			};
		case SET_COMPANY_ORGANIZATIONAL_CHART:
			const orgChartData = [...action.payload];

			return {
				...state,
				organizationalChart: orgChartData,
				companiesOrgChartFilteringInProgress: false,
			};

		case COMPANY_ORGANIZATIONAL_CHART_PAGINATION_LOADING:
			return {
				...state,
				companiesOrgChartPaginationInProgress: action.payload,
			};

		case ADD_PAGINATED_DATA_FOR_COMPANY_ORGANIZATIONAL_CHART:
			const paginatedOrgChartData = action.payload;

			const updatedOrgChartData = [...state.organizationalChart].map((item) => {
				if (paginatedOrgChartData.seniority === item.seniority) {
					item.candidates = [
						...item.candidates,
						...paginatedOrgChartData.candidates.splice(40),
					];
					item.pagination = paginatedOrgChartData.pagination;
				}

				return item;
			});

			return {
				...state,
				organizationalChart: updatedOrgChartData,
				companiesOrgChartPaginationInProgress: false,
			};

		case CLEAR_COMPANY_ORGANIZATIONAL_CHART:
			return {
				...state,
				organizationalChart: null,
			};

		case SET_COMPANY_INDUSTRY_BACKGROUND:
			return {
				...state,
				industryBackground: action.payload,
			};

		case SET_COMPANY_FUNCTIONAL_BACKGROUND:
			return {
				...state,
				functionalBackground: action.payload,
			};

		case CLEAR_COMPANY_INDUSTRY_BACKGROUND:
			return {
				...state,
				industryBackground: companyInitialState.industryBackground,
			};

		case CLEAR_COMPANY_PAGE_FILTERS_AND_ALL_THE_RESPONSES:
			return {
				...state,
				companyGenderDiversityData: null,
				companyGenderDiversityDataInProgress: true,
				companyEthnicDiversityDataInProgress: true,
				companyEthnicDiversityData: null,
				companiesOrgChartFilters: {
					countries: [],
					current_job_functions: [],
					current_keywords: [],
					current_seniority: [],
					current_specialisms: [],
					regions: [],
				},
				trendsStats: null,
				trendsStatsInProgress: true,
				organizationalChart: null,
				companyFrequentlyHiredFromData: null,
				industryBackground: [],
				functionalBackground: [],
			};

		// MAP THIS FUNCTION
		case SET_MAP_THIS_FUNCTION_FOR_CURRENT_COMPANY:
			const mapPayloadData = action.payload.data;
			const companyId = action.payload.companyId;
			const mappingData = {...state.candidateMappingForCompanies};

			if (companyId && mapPayloadData) {
				mappingData[companyId] = mapPayloadData;
			}

			return {
				...state,
				candidateMappingForCompanies: {...mappingData},
			};
		case SET_MAP_THIS_FUNCTION_IS_IN_PROGRESS_FOR_CURRENT_COMPANY:
			const progressPayload = !action.payload.completed;
			const currentCompanyId = action.payload.companyId;

			const mappingProgressInfo = {...state.candidateMappingInProgressForCompanies};

			if (currentCompanyId) {
				mappingProgressInfo[currentCompanyId] = progressPayload;
			}

			return {
				...state,
				candidateMappingInProgressForCompanies: {...mappingProgressInfo},
			};
		case CLEAR_MAP_THIS_FUNCTION_FOR_CURRENT_COMPANY:
			const cMapObj = {...state.candidateMappingForCompanies};
			const cMapProgressObj = {...state.candidateMappingInProgressForCompanies};

			if (action.payload) {
				const isInProgressObj = cMapProgressObj[action.payload];

				if (
					cMapProgressObj &&
					!(
						isInProgressObj?.status === "Created" ||
						isInProgressObj?.status === "In Progress"
					)
				) {
					// only removes if it is not running process
					delete cMapObj[action.payload];
					delete cMapProgressObj[action.payload];
				}
			}

			return {
				...state,
				candidateMappingForCompanies: {...cMapObj},
				candidateMappingInProgressForCompanies: {...cMapProgressObj},
			};

		case UPDATE_TOOLTIP_COMPANIES_PROGRESS:
			return {...state, tooltipCompaniesProgress: true};

		case UPDATE_TOOLTIP_COMPANIES:
			return {...state, tooltipCompaniesProgress: false, tooltipCompanies: action.payload};

		case UPDATE_TOOLTIP_COMPANIES_FAILED:
			return {...state, tooltipCompaniesProgress: false};

		case SET_ORGCHART_KEYWORDS_FILTERS_DROPDOWN_DATA:
			return {
				...state,
				orgchart_keywords_filters_dropdown_data: action.payload,
			};
		case LOGOUT:
			return {
				...state,
				...companyInitialState,
			};

		default:
			return state;
	}
};

export default companyReducer;
