import classNames from "classnames";
import {noop} from "lodash";
import css from "./specialityButton.module.scss";

function InfoButton({
	name,
	active = false,
	handleClick,
	customStyle = null,
	secondText,
	disabled,
	customClass = "",
}) {
	const truncatedName = name?.length > 60 ? `${name?.slice(0, 57)}...` : name;

	return (
		<a
			className={classNames(css.specialityButton, {
				[css.specialitySelected]: active,
				[customClass]: customClass,
				[css.disabled]: disabled,
			})}
			style={customStyle || {}}
			onClick={disabled ? noop : handleClick}
			title={name?.length > 60 ? name : ""}
		>
			{truncatedName}

			{secondText && (
				<span className={classNames(css.secondText, {[css.activeSecond]: active})}>
					{secondText}
				</span>
			)}
		</a>
	);
}

export default InfoButton;
