import type {FC} from "react";
import ContentLoader from "react-content-loader";

const CompanySkeletonCard: FC = (props) => (
	<ContentLoader
		speed={2}
		height={280}
		width={"100%"}
		backgroundColor="#DCE7E7"
		foregroundColor="#F9F9F9"
		{...props}
	>
		<circle cx="40.2" cy="36.2" r="32.3" />
		<rect x="13%" y="2.2" rx="5" ry="5" width="35%" height="30" />
		<rect x="13%" y="44.2" rx="5" ry="5" width="20%" height="25" />
		<rect x="80%" y="2.2" rx="5" ry="5" width="20%" height="30" />

		<rect x="1%" y="95.7" rx="5" ry="5" width="90%" height="15" />
		<rect x="1%" y="125.7" rx="5" ry="5" width="85%" height="15" />
		<rect x="1%" y="155.7" rx="5" ry="5" width="88%" height="15" />

		<rect x="1%" y="197.7" rx="5" ry="5" width="15%" height="30" />
		<rect x="20%" y="197.7" rx="5" ry="5" width="22%" height="30" />
		<rect x="48%" y="197.7" rx="5" ry="5" width="18%" height="30" />

		<rect x="81%" y="230.7" rx="5" ry="5" width="18%" height="40" />
	</ContentLoader>
);

export default CompanySkeletonCard;
