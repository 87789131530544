import type {FC} from "react";

import QuickViewCandidate from "mapx-pages/Project/QuickViewCandidate";
import FlaggedCandidates from "mapx-pages/FlaggedCandidates/FlaggedCandidates";

const FlaggedCandidatesPage: FC = () => (
	<div>
		<FlaggedCandidates />
		<QuickViewCandidate />
	</div>
);

export default FlaggedCandidatesPage;
