import type {TRootState} from "types";
import {createSelector} from "reselect";
import {profilesImportsByLinkedInSelector} from "../background-tasks/backgroundTaskSelectors";
import {projectSelector} from "../project-list/projectListSelectors";
import {
	TProfilesImportInProgress,
	TLinkedInUrlImportItem,
} from "mapx-components/AddPeopleByLinkedIn/types";

export const refreshLinkedInCandidatesInProgressSelector = (state: TRootState) =>
	state.refresh_candidate.refreshLinkedInCandidatesInProgress;

export const currentRefreshLinkedInProfilesScrapeInfoSelector = (state: TRootState) =>
	state.refresh_candidate.currentRefreshLinkedInProfilesScrapeInfo;

export const inProcessRefreshLinkedinCandidateURLsSelector = (state: TRootState) =>
	state.refresh_candidate.processedRefreshURLs;

export const bulkRefreshInProgressSelector = createSelector(
	[profilesImportsByLinkedInSelector],
	(profilesImportsByLinkedIn) => {
		return profilesImportsByLinkedIn?.some(
			(importData: TProfilesImportInProgress) =>
				importData.progress !== 100 && importData.purpose === "refresh",
		);
	},
);

export const getLinkedInUrl = (_: TRootState, url: string) => url;

export const isCurrentCurrentCandidateRefreshIsInProgressSelector = createSelector(
	[
		refreshLinkedInCandidatesInProgressSelector,
		currentRefreshLinkedInProfilesScrapeInfoSelector,
		profilesImportsByLinkedInSelector,
		getLinkedInUrl,
		projectSelector,
	],
	(
		allRefreshIsInProgress,
		currentRefreshLinkedInProfileScrapeInfo,
		profilesImportsByLinkedIn,
		linkedInUrl,
		project,
	) => {
		const hasPendingProfilesInCurrentBulkRefresh = profilesImportsByLinkedIn?.some(
			(importData: {
				project_id: Nullable<number>;
				linkedin_candidate_urls: TLinkedInUrlImportItem[];
			}) =>
				importData.project_id === project?.id &&
				importData.linkedin_candidate_urls.some(
					(urlObj) =>
						["scraped", "created", "in progress"].includes(
							urlObj?.status.toLowerCase(),
						) && urlObj?.url === linkedInUrl,
				),
		);

		return (
			hasPendingProfilesInCurrentBulkRefresh ||
			!!(allRefreshIsInProgress && allRefreshIsInProgress[linkedInUrl] !== undefined) ||
			!!(
				currentRefreshLinkedInProfileScrapeInfo &&
				currentRefreshLinkedInProfileScrapeInfo[linkedInUrl] !== undefined
			)
		);
	},
);
