import React, {useCallback} from "react";
import {useSelector} from "react-redux";
import {Accordion, LocationSearchFilter} from "mapx-components";
import {
	filterPositionOptionsSelector,
	flattenCountriesSelector,
} from "store/mapx/filter/filterSelectors";
import {
	setLocationFilterConfig,
	setMultipleFilterForCandidates,
} from "store/mapx/filter/filterActions";
import LocationFilterHelper from "mapx-components/Filters/LocationSearchFilter/LocationFilterHelper";
import {useAppDispatch, useAppSelector} from "hooks";
import {
	allCountriesCountSelector,
	candidateCountryIdsByPositionSelector,
	candidateRegionsByPositionSelector,
	locationCandidatePositionSelector,
	locationFilterConfigSelector,
	selectedAnyCountriesSelector,
	selectedCurrentCountriesSelector,
	selectedPreviousCountriesSelector,
} from "store/mapx/filter/locationFilterSelectors";
import {TabSelect} from "components";
import {
	moveLocationsForCandidate,
	setLocationForCandidate,
} from "store/mapx/filter/locationFilterAsyncActions";
import {TCountry, TRegion} from "mapx-components/Filters/LocationSearchFilter/types";
import {TLocationPosition} from "./types";

function LocationFilter({disabled = false, displayLock = false}) {
	const dispatch = useAppDispatch();

	const position = useAppSelector(locationCandidatePositionSelector);

	const filterPositionOptions = useAppSelector(filterPositionOptionsSelector);

	const locationFilterConfig = useAppSelector(locationFilterConfigSelector);

	const flattenCountries = useSelector(flattenCountriesSelector);

	const selectedCountriesByPostion = useSelector((state) =>
		candidateCountryIdsByPositionSelector(state, position),
	);

	const selectedCountryRegions = useSelector((state) =>
		candidateRegionsByPositionSelector(state, position),
	);

	const count = useSelector(allCountriesCountSelector);

	const currentCountries = useAppSelector(selectedCurrentCountriesSelector);
	const previousCountries = useAppSelector(selectedPreviousCountriesSelector);
	const anyCountries = useAppSelector(selectedAnyCountriesSelector);

	const handlePositionChange = useCallback(
		(updatedPositionValue: string) => {
			dispatch(
				setLocationFilterConfig({
					...locationFilterConfig,
					active_position: updatedPositionValue,
				}),
			);
		},
		[dispatch, locationFilterConfig],
	);

	const {handleCheckChangeForSingleItem, handleCheckChangeForBulkItems, handleRegionCheckChange} =
		LocationFilterHelper({
			selectedCountries: selectedCountriesByPostion,
			selectedCountryRegions,
			flattenCountries,
		});

	const handleChange = useCallback(
		(id: number) => {
			const {nextCountryIds, toBeSetRegionIds} = handleCheckChangeForSingleItem(id);

			dispatch(setLocationForCandidate({position, nextCountryIds, toBeSetRegionIds}));
		},
		[dispatch, position, handleCheckChangeForSingleItem],
	);

	const handleTagClick = useCallback(
		(
			id: number,
			fromPosition: TLocationPosition,
			selectedCountryIds: number[],
			region_id?: number,
		) => {
			if (selectedCountryIds) {
				let nextCountryIds = [...selectedCountryIds].filter((i) => i !== id);
				const {toBeSetRegionIds} = handleCheckChangeForSingleItem(id);

				let nextRegionIds = toBeSetRegionIds;

				if (region_id) {
					nextRegionIds = [...selectedCountryRegions].filter((i) => i !== region_id);
				}

				nextCountryIds = nextCountryIds.filter((countryId) => {
					const country = flattenCountries.find((c) => c.id === countryId);

					if (country) {
						const countryRegionIds = country.regions.map(
							(region: TRegion) => region.id,
						);
						const allRegionsIncluded = countryRegionIds.every((regionId: number) =>
							nextRegionIds.includes(regionId),
						);

						if (allRegionsIncluded) {
							nextRegionIds = nextRegionIds.filter(
								(regionId) => !countryRegionIds.includes(regionId),
							);

							return true;
						}
					}

					return !country?.regions.some((region: TRegion) =>
						nextRegionIds.includes(region.id),
					);
				});

				dispatch(
					setLocationForCandidate({
						position: fromPosition,
						nextCountryIds,
						toBeSetRegionIds: nextRegionIds,
					}),
				);
			}
		},
		[dispatch, handleCheckChangeForSingleItem, flattenCountries, selectedCountryRegions],
	);

	const handleTagDrop = useCallback(
		(country_id: number, region_id: string, from: TLocationPosition, to: TLocationPosition) => {
			dispatch(
				moveLocationsForCandidate({
					from: from,
					to: to,
					country_id: country_id,
					region_id: region_id,
				}),
			);
		},
		[dispatch],
	);

	const handleResetClick = useCallback(() => {
		dispatch(
			setMultipleFilterForCandidates({
				countries_or: [],
				previous_countries_or: [],
				current_countries_or: [],
				regions_or: [],
				previous_regions_or: [],
				current_regions_or: [],
			}),
		);
	}, [dispatch]);

	const handleBulkUpdate = useCallback(
		(nextCountryIds: number[]) => {
			const toBeSetRegionIds = handleCheckChangeForBulkItems(nextCountryIds);

			dispatch(setLocationForCandidate({position, nextCountryIds, toBeSetRegionIds}));
		},
		[dispatch, handleCheckChangeForBulkItems, position],
	);

	const handleRegionChange = useCallback(
		(regionId: number, country: TCountry) => {
			const {toBeSetRegionIds, nextCountryIds} = handleRegionCheckChange(regionId, country);

			dispatch(setLocationForCandidate({position, nextCountryIds, toBeSetRegionIds}));
		},
		[dispatch, handleRegionCheckChange, position],
	);

	return (
		<Accordion
			title="Location"
			subtitle={selectedCountriesByPostion?.length + (selectedCountryRegions?.length || 0)}
			disabled={disabled}
			displayLock={displayLock}
		>
			<TabSelect.LabelContainer label="Position">
				<TabSelect
					selected={position}
					onTabChange={handlePositionChange}
					options={filterPositionOptions}
				/>
			</TabSelect.LabelContainer>

			<LocationSearchFilter
				filterType={"candidates"}
				handleResetClick={handleResetClick}
				handleOnChange={handleChange}
				selectedLocation={selectedCountriesByPostion}
				selectedCountryRegions={selectedCountryRegions}
				handleBulkUpdate={handleBulkUpdate}
				handleRegionChange={handleRegionChange}
				handleTagClick={handleTagClick}
				handleMoveLocationTag={handleTagDrop}
				selectedCountries={{
					current: currentCountries,
					previous: previousCountries,
					any: anyCountries,
				}}
				allSelectedCountriesCount={count}
			/>
		</Accordion>
	);
}

export default LocationFilter;
