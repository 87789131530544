import React, {useCallback} from "react";
import {useSelector} from "react-redux";
import {LocationSearchFilter} from "mapx-components";
import {
	prJobTitleSuggestionLoadingSelector,
	prLocationSelector,
} from "store/mapx/additional-profiles/additionalProfilesSelectors";
import {setPRFilter} from "store/mapx/additional-profiles/additionalProfilesActions";
import {useAppDispatch} from "hooks";
import {setLocationForPRFilters} from "store/mapx/additional-profiles/profileRequestConfigurator";

function APLocationFilter() {
	const selectedCountries = useSelector(prLocationSelector);

	const dispatch = useAppDispatch();

	const isPRSaving = useSelector(prJobTitleSuggestionLoadingSelector);

	const handleBulkUpdate = useCallback(
		(ids: number[]) => {
			dispatch(setLocationForPRFilters(ids));
		},
		[dispatch],
	);

	const handleChange = useCallback(
		(id: number) => {
			const index = selectedCountries?.indexOf(id);

			const alreadySelected = index !== -1;

			const nextIds = alreadySelected
				? [...selectedCountries.slice(0, index), ...selectedCountries.slice(index + 1)]
				: [...selectedCountries, id];

			handleBulkUpdate(nextIds);
		},
		[handleBulkUpdate, selectedCountries],
	);

	const handleResetClick = useCallback(() => {
		dispatch(setPRFilter({type: "country_ids", values: []}));
	}, [dispatch]);

	return (
		<LocationSearchFilter
			filterType={"apr_candidates"}
			handleResetClick={handleResetClick}
			handleOnChange={handleChange}
			selectedLocation={selectedCountries}
			handleBulkUpdate={handleBulkUpdate}
			disabled={isPRSaving}
		/>
	);
}

export default APLocationFilter;
