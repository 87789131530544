import {useEffect, useRef, useState} from "react";

const useElementOnScreen = (options) => {
	const containerRef = useRef(null);
	const [isVisible, setIsVisible] = useState(false);

	const callbackFunction = (entries) => {
		const [entry] = entries;
		setIsVisible(entry.isIntersecting);
	};

	useEffect(() => {
		if (options.shouldTrack) {
			const observer = new window.IntersectionObserver(callbackFunction, options);
			const currentObserver = containerRef.current;

			if (currentObserver) observer.observe(currentObserver);

			return () => {
				if (currentObserver) observer.unobserve(currentObserver);
			};
		}
	}, [containerRef, options]);

	return [containerRef, isVisible];
};

export default useElementOnScreen;
