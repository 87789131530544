import type {FC} from "react";

import type {TRoundCustomChartsProps} from "./types";

import ChartItem from "./ChartItem";
import styles from "./RoundCustomCharts.module.scss";
import NotEnoughData from "../../../mapx-pages/Project/Insights/NotEnoughData";

const RoundCustomCharts: FC<TRoundCustomChartsProps> = ({
	title,
	isLoad,
	twoTitle,
	oneTitle,
	percentTwo,
	percentOne,
	oneDescription,
	twoDescription,
}) => (
	<div className={styles.container}>
		<p className={styles.container__title}>{title}</p>

		<div className={styles.container__charts}>
			{!isLoad && percentOne === 0 ? (
				<NotEnoughData
					applySmallSize={true}
					flexCol={true}
					title={`There is not enough data to show ${oneTitle} chart`}
				/>
			) : (
				<ChartItem
					isLoad={isLoad}
					title={oneTitle}
					roundPercent={percentOne}
					description={oneDescription}
				/>
			)}

			{!isLoad && percentTwo === 0 ? (
				<NotEnoughData
					applySmallSize={true}
					flexCol={true}
					title={`There is not enough data to show ${twoTitle} chart`}
				/>
			) : (
				<ChartItem
					isLoad={isLoad}
					title={twoTitle}
					roundPercent={percentTwo}
					description={twoDescription}
				/>
			)}
		</div>
	</div>
);

export default RoundCustomCharts;
