import type {FC} from "react";
import classNames from "classnames";

import type {TProgressBarProps} from "./types";
import styles from "./styles.module.scss";

const ProgressBar: FC<TProgressBarProps> = ({
	value,
	background,
	mini = false,
	miniClass = "",
	className = "",
	displayValue = false,
}) => (
	<div
		aria-valuemin={0}
		role="progressbar"
		aria-valuemax={100}
		data-testid="progress-bar"
		aria-valuenow={value < 5 ? 1 : value}
		className={classNames(styles.wrapper, {[className]: className})}
	>
		<div
			data-testid="mini-bar"
			className={classNames(styles.barContainer, {
				[styles.mini]: mini,
				[miniClass]: miniClass,
			})}
		>
			<div className={styles.filler} data-testid="filler-element">
				<div
					className={styles.fillerBackground}
					style={{background: background, width: `${value < 5 ? 1 : value}%`}}
				/>
			</div>
		</div>
		{displayValue && <div className={styles.textValue}>{value}%</div>}
	</div>
);

export default ProgressBar;
