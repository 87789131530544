import {type FC, Fragment, useCallback, useEffect} from "react";
import {useHistory, useLocation} from "react-router-dom";

import {
	LeftArrow,
	FiveOOIcon,
	FourOFourIcon,
	FiveOThreeIcon,
	FourOThreeIcon,
	InvitationInvalidIcon,
	UnknownError,
} from "assets/icons";
import {Button} from "components";
import {useAppSelector} from "hooks";
import {Mixpanel} from "helpers/mixpanel";
import {MainWrapper} from "mapx-components";
import {userSelector} from "store/mapx/user/userSelectors";
import {userEmailSelector} from "store/mapx/user/userSelectors";

import styles from "./error.module.scss";

export const pages = {
	"/403": {
		code: "403",
		title: "Unauthorized Access",
		description:
			"We are sorry, but you don't have the permission to access the file. <br/> To obtain access, contact the team working on your project.",
		icon: <FourOThreeIcon />,
	},
	"/404": {
		code: "404",
		title: "Page Not Found",
		description: "Sorry, we couldn't find the page.",
		icon: <FourOFourIcon />,
	},
	"/503": {
		code: "503",
		title: "Service Unavailable",
		description:
			"Sorry, we are under maintenance. <br/> We'll be ready soon! Please come back later.",
		icon: <FiveOThreeIcon />,
	},
	"/500": {
		code: "500",
		title: "Internal Server Error",
		description:
			"Sorry! It seems there is a problem with our server! <br/> Please try again in a few moments.",
		icon: <FiveOOIcon />,
	},
	"/invitation-invalid": {
		code: "404",
		title: "Invitation Link is No Longer Active",
		description:
			"Your invitation link is no longer available. <br/> Either it's been used or it's expired.",
		icon: <InvitationInvalidIcon />,
	},
	"/unknown-error": {
		code: "unknown-error",
		title: "Unknown Error",
		description:
			"Oops, something went wrong. We are investigating the issue. <br/> Please try again later.",
		icon: <UnknownError />,
	},
};

const Error: FC = () => {
	const history = useHistory();

	const {pathname} = useLocation();

	const user = useAppSelector(userSelector);

	const userEmail = useAppSelector(userEmailSelector);

	const isAuthenticated = !!user.email;

	const Wrapper = isAuthenticated ? MainWrapper : Fragment;

	const content = pages[pathname as keyof typeof pages];

	const handleClick = useCallback(() => {
		history.push(isAuthenticated ? "/home" : "/login");
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isAuthenticated]);

	useEffect(() => {
		try {
			Mixpanel.track(`Error: ${content.title}`, {
				name: `Error: ${content.title}`,
				pageTitle: `Error: ${content.title}`,
				url: window.location.pathname,
				distinct_id: userEmail,
			});
		} catch (e) {
			console.log(e);
		}
	}, [content.title, pathname, userEmail]);

	return (
		<Wrapper>
			<div className={styles.container}>
				<div className={styles.container__icon}>{content.icon}</div>
				<div className={styles.container__title}>{content.title}</div>
				<div
					className={styles.container__description}
					dangerouslySetInnerHTML={{__html: content.description}}
				/>
				<Button
					LeftIcon={LeftArrow}
					onClick={handleClick}
					className={styles.container__button}
					leftIconClasses={styles.container__button__icon}
				>
					Return to {!isAuthenticated ? "Login" : "Dashboard"}
				</Button>
			</div>
		</Wrapper>
	);
};

export default Error;
