import type {FC} from "react";
import {useRef, useEffect, useMemo} from "react";
import classNames from "classnames";
import ContentLoader from "react-content-loader";

import {Button} from "components";
import {useAppSelector} from "hooks";
import {SearchListSkeleton} from "assets/icons";
import {searchedDataSelector} from "store/mapx/search/searchSelectors";

import Peoples from "./Peoples";
import Companies from "./Companies";
import type {TSearchCategoryProps} from "./types";
import {countHandler, searchCategory} from "./utils";
import styles from "./Search.module.scss";

const SearchCategory: FC<TSearchCategoryProps> = ({
	onSelect,
	searchTerm,
	isLoading,
	companies,
	candidates,
	activeCategory,
	setActiveCategory,
}) => {
	const listDataRef = useRef<HTMLDivElement | null>(null);
	const {companiesPagination, candidatesPagination} = useAppSelector(searchedDataSelector);

	useEffect(() => {
		listDataRef.current?.scrollTo({top: 0, behavior: "smooth"});
	}, [activeCategory]);

	const renderCategoryData = useMemo(() => {
		const commonProps = {
			onSelect,
			searchTerm,
			activeCategory,
			setActiveCategory,
		};
		const companiesProps = {
			companies,
			...commonProps,
		};
		const candidatesProps = {
			candidates,
			...commonProps,
		};

		switch (activeCategory) {
			case 0:
				return (
					<>
						<Companies {...companiesProps} />
						<Peoples {...candidatesProps} />
					</>
				);
			case 1:
				return <Companies {...companiesProps} />;
			case 2:
				return <Peoples {...candidatesProps} />;

			default:
				break;
		}
	}, [activeCategory, candidates, companies, onSelect, searchTerm, setActiveCategory]);

	const renderSearchCategory = searchCategory.map((item, idx) => (
		<Button
			key={item.id}
			defaultBtnStyle
			onClick={() => setActiveCategory(idx)}
			className={classNames(styles.list__header__category, {
				[styles.list__header__category_active]: activeCategory === idx,
			})}
		>
			{item.text}

			{isLoading ? (
				<div className={styles.list__header__category__skeleton}>
					<ContentLoader
						speed={2}
						height={20}
						width={24}
						backgroundColor="#DCE7E7"
						foregroundColor="#F9F9F9"
						viewBox="0 0 24 20"
					>
						<rect x="0" y="0" width="24px" height="20px" />
					</ContentLoader>
				</div>
			) : (
				<span className={styles.list__header__category__badge}>
					{countHandler(idx, companiesPagination?.count, candidatesPagination?.count) ||
						"-"}
				</span>
			)}
		</Button>
	));

	return (
		<div className={styles.list}>
			<div className={styles.list__header}>{renderSearchCategory}</div>
			<div ref={listDataRef} className={styles.list__data}>
				{isLoading ? <SearchListSkeleton /> : renderCategoryData}
			</div>
		</div>
	);
};

export default SearchCategory;
