import {
	CREATE_PROJECT_MODAL,
	DELETE_PROJECT_DONE,
	DELETE_PROJECT_FAIL,
	DELETE_PROJECT_INIT,
	EDIT_PROJECT_MODAL,
	GET_PROJECT_DONE,
	GET_PROJECT_FAIL,
	GET_PROJECT_INIT,
	GET_PROJECT_LIST_DONE,
	GET_PROJECT_LIST_FAIL,
	GET_PROJECT_LIST_INIT,
	SAVE_PROJECT_VIA_MODAL_DONE,
	SAVE_PROJECT_VIA_MODAL_FAIL,
	SAVE_PROJECT_VIA_MODAL_INIT,
	SET_PROJECT,
	SET_PROJECT_FORM,
	SET_PROJECT_TAB_INDEX,
	SET_PROJECT_WORKFLOW_STEP,
	UPDATE_PROJECT_DONE,
	UPDATE_PROJECT_FAIL,
	UPDATE_PROJECT_INIT,
	UPDATE_PROJECT_LIST_DONE,
	UPDATE_PROJECT_LIST_FAIL,
	UPDATE_PROJECT_LIST_INIT,
} from "../mapXActionTypes";

export const setProject = (payload) => {
	return {
		type: SET_PROJECT,
		payload,
	};
};

export function getProjectListInit() {
	return {type: GET_PROJECT_LIST_INIT};
}

export function getProjectListDone(data) {
	return {type: GET_PROJECT_LIST_DONE, payload: data};
}

export function getProjectListFail() {
	return {type: GET_PROJECT_LIST_FAIL};
}

export function updateProjectListInit() {
	return {type: UPDATE_PROJECT_LIST_INIT};
}

export function updateProjectListDone(data) {
	return {type: UPDATE_PROJECT_LIST_DONE, payload: data};
}

export function updateProjectListFail(error) {
	return {type: UPDATE_PROJECT_LIST_FAIL, payload: error};
}

export function saveProjectViaModalInit() {
	return {type: SAVE_PROJECT_VIA_MODAL_INIT};
}

export function saveProjectViaModalDone(data) {
	return {type: SAVE_PROJECT_VIA_MODAL_DONE, payload: data};
}

export function saveProjectViaModalFail(error) {
	return {type: SAVE_PROJECT_VIA_MODAL_FAIL, payload: error};
}

export function updateProjectInit() {
	return {type: UPDATE_PROJECT_INIT};
}

export function updateProjectDone(data) {
	return {type: UPDATE_PROJECT_DONE, payload: data};
}

export function updateProjectFail(error) {
	return {type: UPDATE_PROJECT_FAIL, payload: error};
}

export function deleteProjectInit() {
	return {type: DELETE_PROJECT_INIT};
}

export function deleteProjectDone(data) {
	return {type: DELETE_PROJECT_DONE, payload: data};
}

export function deleteProjectFail(error) {
	return {type: DELETE_PROJECT_FAIL, payload: error};
}

export function setProjectTabIndex(index) {
	return {type: SET_PROJECT_TAB_INDEX, payload: index};
}

export function getProjectInit(projectID) {
	return {type: GET_PROJECT_INIT, payload: projectID};
}

export function getProjectDone(data) {
	return {type: GET_PROJECT_DONE, payload: data};
}

export function getProjectFail(error) {
	return {type: GET_PROJECT_FAIL, payload: error};
}

export function createProjectModal(display) {
	return {type: CREATE_PROJECT_MODAL, payload: {display}};
}

export function editProjectModal(payload) {
	return {type: EDIT_PROJECT_MODAL, payload};
}

export function setProjectWorkFlowStep(payload) {
	return {type: SET_PROJECT_WORKFLOW_STEP, payload};
}

export function setProjectForm(payload) {
	return {type: SET_PROJECT_FORM, payload};
}
