import type {SVGProps} from "react";

function OrganisationsIcon(props: SVGProps<SVGSVGElement>) {
	return (
		<svg
			width={24}
			height={24}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M14 5a2 2 0 11-4 0 2 2 0 014 0zm-1 3.874A4.002 4.002 0 0012 1a4 4 0 00-1 7.874V12H2.5v4H0v7h7v-7H4.5v-2H11v2H8.5v7h7v-7H13v-2h6.5v2H17v7h7v-7h-2.5v-4H13V8.874zM2 18h3v3H2v-3zm20 0h-3v3h3v-3zm-11.5 3v-3h3v3h-3z"
				fill="gray"
			/>
		</svg>
	);
}

export default OrganisationsIcon;
