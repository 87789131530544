import {useCallback, useEffect, useState} from "react";
import axios from "axios";
import {isEqual, uniq} from "lodash";
import useDebouncedEffect from "use-debounced-effect";

import {
	getCompanyEthnicDiversityDataFilter,
	getCompanyFrequentlyHiredFromDataFilter,
	getCompanyGenderDiversityDataFilter,
	getCompanyOrganizationalChartFilter,
	getCompanyTrendStatsFilter,
} from "store/mapx/company/companyAsyncActions";
import {getCompanyOrgChartFiltersDone} from "store/mapx/company/companyActions";
import type {TValue} from "components/MultiSelect/types";
import {companiesOrgChartFiltersSelector} from "store/mapx/company/companySelectors";

import {useAppDispatch, useAppSelector, useCompanyIdParam, usePrevious} from "../index";

import type {TUseOrgChartFiltersReturn} from "./types";
import {TReportFilterParams} from "api/companyApi/types";

const initialSelectedFilters = {
	regions: [],
	countries: [],
	current_keywords: [],
	current_seniority: [],
	current_specialisms: [],
	current_job_functions: [],
};

const useOrgChartFilters = (): TUseOrgChartFiltersReturn => {
	const dispatch = useAppDispatch();

	const companyParamId = useCompanyIdParam();

	const filtersInState = useAppSelector(companiesOrgChartFiltersSelector);

	const [cancelTokenSources, setCancelTokenSources] = useState({
		ethnicDiversity: axios.CancelToken.source(),
		genderDiversity: axios.CancelToken.source(),
		trendStats: axios.CancelToken.source(),
		organizationalChart: axios.CancelToken.source(),
		frequentlyHiredFrom: axios.CancelToken.source(),
	});

	const [selectedFilters, setSelectedFilters] =
		useState<TReportFilterParams>(initialSelectedFilters);

	const [resettingInProgress, setResettingInProgress] = useState<boolean>(false);

	const prevSelectedFilters = usePrevious(selectedFilters);

	useEffect(() => {
		if (filtersInState && Object.keys(filtersInState).length) {
			const filtersInLocalState: TReportFilterParams = {...selectedFilters};

			for (const key in {...filtersInState}) {
				if (filtersInState[key] !== undefined && filtersInState[key]?.length) {
					// if (key === "current_seniority") {
					//     filtersInLocalState[key] = filtersInState[key].split(",");
					// } else {
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					filtersInLocalState[key] = filtersInState[key];
					// }
				}
			}

			setSelectedFilters({...filtersInLocalState});
			// applyFilteringInProgress(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useDebouncedEffect(
		() => {
			const prevFilterApplied =
				prevSelectedFilters &&
				Object.values(prevSelectedFilters).some((filters) => filters.length);

			const filterApplied = Object.values(selectedFilters).some(
				(filters) => !!filters.length,
			);

			if (
				companyParamId &&
				!isEqual(selectedFilters, prevSelectedFilters) &&
				(filterApplied || prevFilterApplied)
			) {
				const applyOrgChartFilters = async () => {
					Object.values(cancelTokenSources).forEach((source) => source.cancel());

					const newCancelTokenSources = {
						ethnicDiversity: axios.CancelToken.source(),
						genderDiversity: axios.CancelToken.source(),
						trendStats: axios.CancelToken.source(),
						organizationalChart: axios.CancelToken.source(),
						frequentlyHiredFrom: axios.CancelToken.source(),
					};
					setCancelTokenSources(newCancelTokenSources);

					dispatch(getCompanyOrgChartFiltersDone(selectedFilters));

					const modifiedSelectedFilters = {...selectedFilters};

					await dispatch(
						getCompanyOrganizationalChartFilter(
							companyParamId,
							modifiedSelectedFilters,
							{
								cancelToken: newCancelTokenSources.organizationalChart.token,
							},
						),
					);

					dispatch(
						getCompanyEthnicDiversityDataFilter(
							companyParamId,
							modifiedSelectedFilters,
							{
								cancelToken: newCancelTokenSources.ethnicDiversity.token,
							},
						),
					);

					dispatch(
						getCompanyGenderDiversityDataFilter(
							companyParamId,
							modifiedSelectedFilters,
							{
								cancelToken: newCancelTokenSources.genderDiversity.token,
							},
						),
					);

					dispatch(
						getCompanyTrendStatsFilter(companyParamId, modifiedSelectedFilters, {
							cancelToken: newCancelTokenSources.trendStats.token,
						}),
					);

					dispatch(
						getCompanyFrequentlyHiredFromDataFilter(
							companyParamId,
							modifiedSelectedFilters,
							{
								cancelToken: newCancelTokenSources.frequentlyHiredFrom.token,
							},
						),
					);
				};

				if (!resettingInProgress) {
					applyOrgChartFilters();
				}
			}
		},
		10,
		[companyParamId, prevSelectedFilters, selectedFilters, resettingInProgress],
	);

	const handleSelectChange: TUseOrgChartFiltersReturn["onSelectChange"] = useCallback(
		(name: string, id: TValue, checked = true) => {
			setSelectedFilters((prevSelectedFilters) => {
				const filterName = name as keyof TReportFilterParams;
				const currentFilterState = prevSelectedFilters[filterName] as TValue[];

				let updatedFilterValues: TValue[] = [];
				let idsArray: TValue[] = [];

				if (typeof id === "string") {
					idsArray = id.split(",").map(Number);
				} else if (Array.isArray(id)) {
					idsArray = id as TValue[];
				} else {
					idsArray = [id];
				}

				if (checked) {
					// If checked is true, proceed with adding or keeping the ids
					if (
						name === "countries" ||
						name === "current_specialisms" ||
						name === "regions"
					) {
						if (typeof id !== "number") {
							if (idsArray) {
								// Add ids that are not yet in currentFilterState
								updatedFilterValues = [
									...currentFilterState,
									...idsArray.filter(
										(id: TValue) => !currentFilterState?.includes(id),
									),
								];
							}
						}
					} else {
						// Standard logic for other filters (add or retain)
						updatedFilterValues = currentFilterState?.includes(id)
							? currentFilterState
							: [...currentFilterState, id];
					}
				} else if (
					name === "countries" ||
					name === "current_specialisms" ||
					name === "regions"
				) {
					if (typeof id !== "number") {
						if (idsArray) {
							// Remove ids from the currentFilterState
							updatedFilterValues = currentFilterState?.filter(
								(filterValue) => !idsArray.includes(Number(filterValue)),
							);
						}
					}
				} else {
					// Remove the id from the state
					updatedFilterValues = currentFilterState?.filter(
						(filterValue) => filterValue !== id,
					);
				}

				// Reset if id is empty
				if (!id || id === "") {
					updatedFilterValues = [];
				}

				return {
					...prevSelectedFilters,
					[filterName]: uniq(updatedFilterValues),
				};
			});
		},
		[],
	);

	const handleResetFilters = async () => {
		setResettingInProgress(true);
		// applyFilteringInProgress(true);
		setSelectedFilters(initialSelectedFilters);

		dispatch(getCompanyEthnicDiversityDataFilter(companyParamId));
		dispatch(getCompanyGenderDiversityDataFilter(companyParamId));
		dispatch(getCompanyTrendStatsFilter(companyParamId));
		dispatch(getCompanyOrganizationalChartFilter(companyParamId));
		dispatch(getCompanyFrequentlyHiredFromDataFilter(companyParamId));

		await dispatch(getCompanyOrgChartFiltersDone(initialSelectedFilters));

		// applyFilteringInProgress(false);
		setResettingInProgress(false);
	};

	return {
		selectedFilters,
		resetFilters: handleResetFilters,
		onSelectChange: handleSelectChange,
	};
};

export default useOrgChartFilters;
