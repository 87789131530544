import type {FC} from "react";

import type {IconProps} from "./types";

const OrganizationArrow: FC<IconProps> = ({className = ""}) => (
	<svg
		width="24"
		height="24"
		fill="none"
		viewBox="0 0 24 24"
		className={className}
		xmlns="http://www.w3.org/2000/svg"
	>
		<mask
			x="0"
			y="0"
			width="24"
			height="24"
			id="mask0_1399_16923"
			maskUnits="userSpaceOnUse"
			style={{maskType: "alpha"}}
		>
			<rect width="24" height="24" fill="#D9D9D9" />
		</mask>
		<g mask="url(#mask0_1399_16923)">
			<path
				fill="#F2A901"
				d="M7.4 15.375L6 13.975L12 7.97501L18 13.975L16.6 15.375L12 10.775L7.4 15.375Z"
			/>
			<path
				fill="#271B00"
				d="M7.4 15.375L6 13.975L12 7.97501L18 13.975L16.6 15.375L12 10.775L7.4 15.375Z"
			/>
		</g>
	</svg>
);

export default OrganizationArrow;
