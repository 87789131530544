import {
	useRef,
	type FC,
	Fragment,
	useState,
	useEffect,
	ChangeEvent,
	type SyntheticEvent,
	type KeyboardEventHandler,
} from "react";

import {Button, TextInput} from "components";
import {useLockBodyScroll, useOutsideClick} from "hooks";
import SelectWithAction from "components/Inputs/SelectWithAction";

import type {TEditUserModalProps, TOrganizations} from "./types";
import stylesUser from "../CreateUserModal/CreateUserModal.module.scss";
import styles from "./EditUserModal.module.scss";

const EditUserModal: FC<TEditUserModalProps> = ({
	email,
	license,
	isOpen,
	loading,
	onSubmit,
	setIsOpen,
	emailError,
	selectedOrg,
	licenseType,
	organisations,
	emailOnChange,
	setLicenseType,
	setSelectedOrg,
	selectedLicenseType,
	freeTrialDefaultValue,
}) => {
	const ref = useRef<HTMLDivElement | null>(null);

	const [freeTrialVal, setFreeTrialVal] = useState<number>(freeTrialDefaultValue);

	const isFreeTrial = licenseType ? licenseType.label === "Prospect" : false;

	const removeHandler = () => {
		setLicenseType({
			label: null,
			value: null,
		});
	};

	const submitHandler = (e: SyntheticEvent) => {
		e.preventDefault();
		onSubmit?.(e, freeTrialVal);
	};

	const closeModalHandler = () => {
		setIsOpen(false);
	};

	useEffect(() => {
		if (!loading && isOpen) {
			closeModalHandler();
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loading]);

	const organizationOptions = organisations.map((el: TOrganizations) => {
		return {
			value: el.id,
			label: el.name,
		};
	});

	const handleFreeTrialChange = (e: ChangeEvent<HTMLInputElement>) => {
		if (freeTrialVal > 0) {
			if (Number(e.target.value)) {
				setFreeTrialVal(Number(e.target.value));
			}
		}
	};

	const handleFreeTrialKeyDown: KeyboardEventHandler = (evt) =>
		["e", "E", "+", "-", "."].includes(evt.key) && evt.preventDefault();

	useLockBodyScroll(isOpen);
	useOutsideClick(ref, () => setIsOpen(false));

	return (
		<Fragment>
			{isOpen && (
				<div className={styles.container}>
					<div className={styles.wrapper} ref={ref}>
						<p className={styles.wrapper__title}>Edit User</p>
						<form className={styles.wrapper__content} onSubmit={submitHandler}>
							<div className={styles.wrapper__content_item}>
								<p className={styles.wrapper__content_item_title}>Organization</p>
								<SelectWithAction
									selected={selectedOrg}
									setSelected={setSelectedOrg}
									options={organizationOptions}
									placeholder="Select Organization"
								/>
							</div>
							<div className={styles.wrapper__content_item}>
								<p className={styles.wrapper__content_item_title}>E-Mail</p>
								<TextInput
									value={email}
									placeholder="User Email"
									onChange={emailOnChange}
									errorText={!emailError ? "E-Mail is not valid" : ""}
								/>
							</div>
							{licenseType && (
								<div className={styles.wrapper__content_item}>
									<p className={styles.wrapper__content_item_title}>User Type</p>

									<SelectWithAction
										selected={licenseType}
										placeholder="License Type"
										actionClick={removeHandler}
										setSelected={setLicenseType}
										className={styles.inviteUser__license}
										isSelected={!selectedLicenseType?.value}
										action={
											licenseType?.value ? (
												<p role="button" className={styles.remove_all}>
													Remove
												</p>
											) : (
												<div />
											)
										}
										options={license}
									/>
								</div>
							)}

							{isFreeTrial && (
								<div className={stylesUser.wrapper__content_item}>
									<p className={stylesUser.wrapper__content_item_title}>
										Free Trial Duration
									</p>

									<p className={stylesUser.wrapper__content_item__free}>
										<input
											min={1}
											type="number"
											className={styles.trial}
											defaultValue={freeTrialVal}
											onChange={handleFreeTrialChange}
											onKeyDown={handleFreeTrialKeyDown}
										/>
										<span
											className={
												stylesUser.wrapper__content_item__free_remainder
											}
										>
											days
										</span>
									</p>
								</div>
							)}

							<Button
								type="submit"
								loading={loading}
								disabled={!emailError}
								className={styles.wrapper__content_button}
							>
								Edit User
							</Button>
						</form>
					</div>
				</div>
			)}
		</Fragment>
	);
};

export default EditUserModal;
