import {useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Accordion} from "mapx-components";
import {setFilterForCandidates} from "store/mapx/filter/filterActions";
import {currentSenioritySelector} from "store/mapx/filter/filterSelectors";
import SenioritySearchFilter from "mapx-components/Filters/SenioritySearchFilter";
import {setSeniorityForCandidates} from "store/mapx/filter/filterAsyncActions";

function SeniorityFilter({disabled}) {
	const dispatch = useDispatch();

	const currentSeniority = useSelector(currentSenioritySelector);

	const handleChange = useCallback(
		(id) => {
			dispatch(setSeniorityForCandidates({id}));
		},
		[dispatch],
	);

	const handleResetClick = useCallback(() => {
		dispatch(setFilterForCandidates({type: "current_seniority", values: []}));
	}, [dispatch]);

	return (
		<Accordion
			title="Seniority"
			subtitle={currentSeniority.length}
			disabled={disabled}
			tooltipId={"seniority_filter_hover"}
		>
			<SenioritySearchFilter
				handleOnChange={handleChange}
				handleResetClick={handleResetClick}
				selectedSeniority={currentSeniority}
			/>
		</Accordion>
	);
}

export default SeniorityFilter;
