import {FC, Fragment, useState} from "react";

import {Button} from "components";
import {useLockBodyScroll} from "hooks";
import {CloseIcon, PlayIcon} from "assets/icons";

import type {TVideoTutorialModalProps} from "./types";
import styles from "./VideoTutorialModal.module.scss";

const VideoTutorialModal: FC<TVideoTutorialModalProps> = ({closeHandlerProps}) => {
	const [showVideo, setShowVideo] = useState<boolean>(false);

	const showVideoHandler = () => {
		setShowVideo(true);
	};

	useLockBodyScroll(true);

	return (
		<div className={styles.wrapper}>
			<div className={styles.wrapper__bg} role="button" onClick={closeHandlerProps} />
			<div className={styles.container}>
				{!showVideo ? (
					<Fragment>
						<div className={styles.container__head}>
							<h5 className={styles.container__head_title}>Introduction to MapX</h5>

							<CloseIcon
								onClick={closeHandlerProps}
								className={styles.container__head_close}
							/>
						</div>

						<hr className={styles.container__hr} />

						<div className={styles.container__body}>
							<div className={styles.container__body__left}>
								<p className={styles.container__body__left_title}>
									Welcome to MapX
								</p>
								<p className={styles.container__body__left_description}>
									MapX is a revolutionary new product designed specifically for
									Executive Talent Acquisition.
								</p>
								<p className={styles.container__body__left_content}>
									Using proprietary leadership and talent mapping algorithms, MapX
									can quickly and efficiently map executive talent across multiple
									industries, geographies and markets.
								</p>
								<ul>
									<li className={styles.container__body__left_content}>
										Quickly build longlists for any executive role
									</li>
									<li className={styles.container__body__left_content}>
										Create visual maps of leadership talent at competitors
									</li>
									<li className={styles.container__body__left_content}>
										Identify new sources of talent
									</li>
									<li className={styles.container__body__left_content}>
										Increase your leadership diversity{" "}
									</li>
									<li className={styles.container__body__left_content}>
										Create insights rich reports{" "}
									</li>
									<li className={styles.container__body__left_content}>
										Build succession plans that stay up to date
									</li>
								</ul>
							</div>
							<div className={styles.container__body__right}>
								<div
									role="button"
									onClick={showVideoHandler}
									className={styles.container__body__right_img}
								/>

								<p className={styles.container__body__right_title}>
									How to use MapX AI
								</p>

								<span className={styles.container__body__right_description}>
									Learn what MapX is, see how it works, and understand practical
									use cases for your business.
								</span>

								<Button
									defaultBtnStyle
									LeftIcon={PlayIcon}
									onClick={showVideoHandler}
									className={styles.container__body__right__watch}
									leftIconClasses={styles.container__body__right__watch_icon}
								>
									Watch Demo
								</Button>
							</div>
						</div>
					</Fragment>
				) : (
					<div className={styles.container__video}>
						<video
							controls
							autoPlay
							playsInline
							className={styles.container__video_iframe}
							src="https://storage.googleapis.com/savannah-cdn/videos/How%20To%20Use%20MapX%20AI%20to%20Find%20Executive%20Talent.mp4"
						/>
					</div>
				)}
			</div>
		</div>
	);
};

export default VideoTutorialModal;
