import {FC, useState} from "react";
import classNames from "classnames";
import {BiChevronsLeft} from "react-icons/bi";

import type {TLessButtonProps} from "./types";
import styles from "./lessButton.module.scss";

const LessButton: FC<TLessButtonProps> = ({handleClick}) => {
	const [hide, setHide] = useState(false);

	const buttonClass = classNames(styles.moreButton, {
		[styles.hide]: hide,
	});

	const onClick = () => {
		setHide(true);
		setTimeout(() => {
			handleClick();
		}, 600);
	};

	return (
		<button className={buttonClass} onClick={onClick}>
			<BiChevronsLeft />
		</button>
	);
};

export default LessButton;
