import {forwardRef, useCallback} from "react";
import {useState} from "react";
import classNames from "classnames";

import {changePassForm} from "mapx-constants";
import colors from "styles/themes.module.scss";
import {ChangeApprovedIcon} from "assets/icons";
import {useAppDispatch, useModalHook} from "hooks";
import {Button, CustomModal, Form} from "components";
import {changePassword} from "store/mapx/user/userAsyncAction";
import {TChangePassFormValues} from "mapx-constants/forms/changePassForm/types";

import type {TChangePasswordModalProps} from "./types";
import styles from "./ChangePasswordModal.module.scss";

const ChangePasswordModal = forwardRef<HTMLDivElement, TChangePasswordModalProps>(
	({setChangePassModalDisplay, changePassModalDisplay = true}, ref) => {
		const dispatch = useAppDispatch();

		const {loading, setLoading} = useModalHook(
			{content: {width: "400px", borderRadius: "8px"}},
			changePassModalDisplay,
			colors.mainThemeColor,
		);

		const [formError, setFormError] = useState<string>("");
		const [passChanged, setPassChanged] = useState<boolean>(false);

		const closeModal = () => {
			setChangePassModalDisplay([false, null]);
			setPassChanged(false);
		};

		const onSubmit = useCallback(async (values: TChangePassFormValues) => {
			const formData = {
				current_password: values.currentPassword,
				new_password: values.newPassword,
			};

			setLoading(true);

			const dispatchChangePass = await dispatch(changePassword(formData));

			if (dispatchChangePass?.status === 200) {
				setPassChanged(true);
			} else {
				setFormError(
					dispatchChangePass?.data?.detail
						? dispatchChangePass?.data?.detail
						: "Incorrect Current Password: The password you have entered is incorrect. Please try again or reset your password if needed",
				);
			}

			setLoading(false);
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, []);

		return (
			<CustomModal
				ref={ref}
				modalDisplay={changePassModalDisplay}
				className={classNames("modal", styles.wrapper, {
					[styles.wrapper__changed]: passChanged,
				})}
			>
				{passChanged && <ChangeApprovedIcon className={styles.wrapper__changed_icon} />}

				<h1 className={styles.wrapper__title}>
					{!passChanged ? "Change Password" : "Password Changed Successfully"}
				</h1>

				<h3
					className={classNames(styles.wrapper__subtitle, {
						[styles.wrapper__changed_subtitle]: passChanged,
					})}
				>
					{!passChanged
						? "Please enter your current and new password"
						: "Your password is changed successfully."}{" "}
				</h3>

				{!passChanged ? (
					<Form
						loading={loading}
						onSubmit={onSubmit}
						form={changePassForm}
						inputError={formError}
						setInputError={setFormError}
						submitText="Change Password"
						className={styles.wrapper__form}
						btnClasses={styles.wrapper__form__btn}
					/>
				) : (
					<Button className={styles.wrapper__finish} onClick={closeModal}>
						Done
					</Button>
				)}
			</CustomModal>
		);
	},
);

ChangePasswordModal.displayName = "ChangePasswordModal";

export default ChangePasswordModal;
