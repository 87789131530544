import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useSelector} from "react-redux";
import css from "./styles.module.scss";
import classNames from "classnames";
import SearchByName from "./SearchByName";
import SearchByLinkedIn from "mapx-pages/CandidateSearch/ProfileSearch/PersonFilter/SearchByLinkedIn";
import {
	candidateLinkedInUrlSelector,
	selectedCandidatesSelector,
} from "store/mapx/filter/filterSelectors";
import {InfoIcon} from "assets/icons";
import {Tooltip as ReactTooltip} from "react-tooltip";
import {useAppDispatch, useAppSelector} from "hooks";
import {userLicenseTypeNameSelector} from "store/mapx/user/userSelectors";
import {SICandidate} from "api/candidateApi/types";
import {TPersonFilterProps} from "mapx-pages/CandidateSearch/ProfileSearch/types";
import {clearSimilarCandidatesResults} from "store/mapx/candidate/candidateActions";

function PersonFilter({setDisplaySearchResult}: TPersonFilterProps) {
	const [activeFilter, setActiveFilter] = useState<"name" | "url">("name");

	const dispatch = useAppDispatch();

	const searchedLinkedInUrl = useSelector(candidateLinkedInUrlSelector);

	const userLicenseTypeName = useAppSelector(userLicenseTypeNameSelector);

	const selectedCandidates = useSelector(selectedCandidatesSelector);

	const isStarterUser = useMemo(() => userLicenseTypeName === "Starter", [userLicenseTypeName]);

	useEffect(() => {
		if (searchedLinkedInUrl !== null) {
			setActiveFilter("url");
		}
	}, [searchedLinkedInUrl]);

	const toggleDisplayUserSearchedResult = useCallback(
		(updatedSelectedCandidates: SICandidate) => {
			if (Array.isArray(updatedSelectedCandidates)) {
				setDisplaySearchResult(true);
				dispatch(clearSimilarCandidatesResults());
			}
		},
		[setDisplaySearchResult, dispatch],
	);

	useEffect(() => {
		if (selectedCandidates?.length > 0) {
			setDisplaySearchResult(true);
		} else {
			setDisplaySearchResult(false);
		}
	}, [selectedCandidates?.length, setDisplaySearchResult]);

	return (
		<div className={css.searchContainer}>
			<div className={css.filterBy}>
				<div className={css.filters}>
					<div
						className={classNames(css.filterType, {
							[css.active]: activeFilter === "name",
						})}
						onClick={() => setActiveFilter("name")}
					>
						<span>Search By Name</span>
					</div>
					<div
						className={classNames(css.filterType, {
							[css.active]: activeFilter === "url",
						})}
						onClick={() => setActiveFilter("url")}
					>
						<span>Search By URL</span>
					</div>
				</div>

				<div
					data-tip="true"
					data-tooltip-id="aiMatching"
					style={{cursor: "pointer", width: 20, display: "flex"}}
				>
					<span style={{pointerEvents: "none"}}>
						{" "}
						<InfoIcon />
					</span>
				</div>

				<ReactTooltip
					place="left"
					id="aiMatching"
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					type="light"
					textColor="#404040"
					backgroundColor="#fff"
					arrowColor="#fff"
					className={css.tooltip}
				>
					<div style={{fontWeight: 500, marginBottom: 8}}>
						{activeFilter === "name" ? "Search By Name" : "Search by URL"}
					</div>

					<div>
						<div style={{marginBottom: 10}}>
							{activeFilter === "name"
								? `Search by a name and AI will find the ${
										isStarterUser ? "10" : "100"
								  } most similar people on MapX.`
								: `Search by a URL and AI will find the 100 most similar people people on MapX.`}
							<b>This will lock the filters.</b>

							{isStarterUser && (
								<div style={{marginTop: 10}}>
									<b
										style={{
											fontWeight: 600,
											color: "#0C5850",
											textDecoration: "underline",
										}}
									>
										Upgrade
									</b>{" "}
									now to see more candidates.
								</div>
							)}
						</div>
					</div>
				</ReactTooltip>
			</div>

			{activeFilter === "name" && (
				<SearchByName toggleDisplayUserSearchedResult={toggleDisplayUserSearchedResult} />
			)}

			{activeFilter === "url" && (
				<SearchByLinkedIn
					toggleDisplayUserSearchedResult={toggleDisplayUserSearchedResult}
				/>
			)}
		</div>
	);
}

export default PersonFilter;
