import type {FC} from "react";
import {take} from "lodash";
import useInfiniteScroll from "react-infinite-scroll-hook";

import {Button, Loader} from "components";
import {PeopleHeaderIcon} from "assets/icons";
import colors from "styles/themes.module.scss";
import {useAppDispatch, useAppSelector} from "hooks";
import {
	getSearchDataInProgressSelector,
	searchedDataSelector,
} from "store/mapx/search/searchSelectors";
import {getSearchData} from "store/mapx/search/searchAsyncActions";
import {SelectFilterType} from "hooks/useCompanyPeopleSearch/types";

import type {TPeoplesProps, TRenderCandidatesProps} from "./types";
import styles from "./Search.module.scss";

const Peoples: FC<TPeoplesProps> = ({
	onSelect,
	searchTerm,
	activeCategory,
	setActiveCategory,
	candidates: propsCandidates,
}) => {
	const dispatch = useAppDispatch();

	const loading = useAppSelector(getSearchDataInProgressSelector);
	const {candidatesPagination} = useAppSelector(searchedDataSelector);

	const [sentryRef] = useInfiniteScroll({
		loading,
		hasNextPage: candidatesPagination?.pages > candidatesPagination?.page,
		onLoadMore: () => {
			dispatch(
				getSearchData({
					value: searchTerm,
					filterType: "candidates",
					perPage: 20,
					page: candidatesPagination?.page + 1 || 1,
				}),
			);
		},
		rootMargin: "0px 0px 400px 0px",
	});

	const candidates = !activeCategory ? take(propsCandidates, 10) : propsCandidates;

	const renderCandidates = candidates?.map((item: TRenderCandidatesProps) => {
		const {id, full_name, positions, avatar_url} = item;
		const current =
			positions && positions?.length > 0 ? positions.find((p) => p.is_current) : "";
		const currentJob = current ? current.job_title : "";
		const currentCompany = current ? current.company?.name : "";

		return (
			<Button
				key={id}
				defaultBtnStyle
				onClick={() => onSelect(SelectFilterType.Candidates, item)}
				className={styles.companies__container__item}
			>
				{avatar_url ? (
					<img
						className={styles.companies__container__item_img}
						src={avatar_url}
						alt={""}
					/>
				) : (
					<PeopleHeaderIcon className={styles.companies__container__item_img} />
				)}
				<div className={styles.companies__container__item__texts}>
					<p className={styles.companies__container__item__texts_name}>{full_name}</p>
					<span className={styles.companies__container__item__texts_text}>
						{currentJob}
						{currentCompany && (
							<>
								<span> at </span>
								<span style={{fontWeight: 500}}>{currentCompany}</span>
							</>
						)}
					</span>
				</div>
			</Button>
		);
	});

	const handleShowMore = () => {
		setActiveCategory(2);
	};

	return (
		<div className={styles.companies}>
			<h5 className={styles.companies__title}>People</h5>

			{candidates.length ? (
				<>
					<div className={styles.companies__container}>
						{renderCandidates}

						{activeCategory === 0 && (
							<Button
								defaultBtnStyle
								onClick={handleShowMore}
								className={styles.companies__container__show}
							>
								Show more
							</Button>
						)}

						{!loading && activeCategory !== 0 && (
							<div ref={sentryRef} style={{width: "100%", height: 1}} />
						)}

						{loading && activeCategory !== 0 && (
							<div style={{marginLeft: 10}}>
								<Loader
									width={20}
									height={20}
									type={"ThreeDots"}
									color={colors.loaderDotColor}
									displayAtCenterOfPage={false}
								/>
							</div>
						)}
					</div>
				</>
			) : (
				<h5>No People Found</h5>
			)}
		</div>
	);
};

export default Peoples;
