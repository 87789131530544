import {
	ACTIVE_ADD_PEOPLE_MODAL_FOR_PROJECT,
	RESET_BULK_IMPORT_STATES_FOR_COMPANY,
	RESET_BULK_IMPORT_STATES_FOR_PROJECT,
	SET_BULK_IMPORT_ID_FOR_COMPANY,
	SET_IMPORT_LINKEDIN_PROFILE_INFO_FOR_COMPANY,
	SET_LAST__COMPLETE_IMPORT_LINKEDIN_PROFILE_INFO_FOR_PROJECT,
	SET_NON_CLOSED_PROJECT_ID_FOR_LINKEDIN_BULK_IMPORT,
	SET_SHOW_IMPORT_REPORT,
	SET_SHOW_LAST_IMPORT_REPORT,
	SET_SHOW_LAST_IMPORT_REPORT_FOR_COMPANY,
} from "../mapXActionTypes";
import {LinkedinBulkImportReducerTypes} from "store/mapx/linkedin-bulk-import/types";
import {AnyAction} from "redux";

const initialState: LinkedinBulkImportReducerTypes = {
	showLastBulkImportReport: false,
	showBulkImportReport: [],
	showLastBulkImportReportForCompany: false,
	isLastBulkImportReportDataLoading: false,
	lastCompletedCandidateImportByLinkedInForProject: {}, // used to store completed bulk import urls
	candidateImportByLinkedInForCompany: {}, // used for projectApi // Structure projectId: Info
	activeAddPeopleModalForProject: null, // can be null or id
	processedImports: [],
	processedRefreshes: [],
	bulkImportInfoOfCompany: null,
};

const linkedInBulkImportReducer = (state = initialState, action: AnyAction) => {
	switch (action.type) {
		case SET_BULK_IMPORT_ID_FOR_COMPANY:
			return {
				...state,
				bulkImportInfoOfCompany: action.payload,
			};

		case SET_IMPORT_LINKEDIN_PROFILE_INFO_FOR_COMPANY:
			return {
				...state,
				candidateImportByLinkedInForCompany: {
					...state.candidateImportByLinkedInForCompany,
					[action.payload.id]: action.payload.data,
				},
			};

		case ACTIVE_ADD_PEOPLE_MODAL_FOR_PROJECT:
			return {
				...state,
				activeAddPeopleModalForProject: action.payload,
			};

		case SET_LAST__COMPLETE_IMPORT_LINKEDIN_PROFILE_INFO_FOR_PROJECT:
			return {
				...state,
				lastCompletedCandidateImportByLinkedInForProject: {
					[action.payload.id]: action.payload.data, // sets object for project by id
				},
			};

		case SET_SHOW_LAST_IMPORT_REPORT:
			return {
				...state,
				showLastBulkImportReport: action.payload,
			};

		case SET_SHOW_IMPORT_REPORT:
			return {
				...state,
				showBulkImportReport: {
					...state.showBulkImportReport,
					[action.payload.id]: action.payload.value,
				},
			};

		case SET_NON_CLOSED_PROJECT_ID_FOR_LINKEDIN_BULK_IMPORT: {
			return {
				...state,
				processedImports: [...state.processedImports, action.payload],
			};
		}

		case RESET_BULK_IMPORT_STATES_FOR_PROJECT: {
			const existingImports = [...state.processedImports];

			const importKey = action.payload;

			const updatedImports = existingImports?.filter((item) => item !== importKey);

			return {
				...state,
				processedImports: [...updatedImports],
				showLastBulkImportReport: false,
				showBulkImportReport: {
					...state.showBulkImportReport,
					[importKey]: false,
				},
			};
		}

		case RESET_BULK_IMPORT_STATES_FOR_COMPANY: {
			const existingImports = [...state.processedImports];

			const importKey = action.payload;

			const updatedImports = existingImports?.filter((item) => item !== importKey);

			return {
				...state,
				processedImports: [...updatedImports],
				showLastBulkImportReport: false,
				showLastBulkImportReportForCompany: false,
				showBulkImportReport: {
					...state.showBulkImportReport,
					[importKey]: false,
				},
				bulkImportInfoOfCompany: null,
			};
		}

		case SET_SHOW_LAST_IMPORT_REPORT_FOR_COMPANY:
			return {
				...state,
				showLastBulkImportReportForCompany: action.payload,
			};

		default:
			return state;
	}
};

export default linkedInBulkImportReducer;
