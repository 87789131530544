import {useEffect, useMemo} from "react";
import {useSelector} from "react-redux";
import {Redirect, Route, Switch} from "react-router-dom";
import {toast} from "react-toastify";
import routes from "./routes";
import {useClearStorage} from "hooks/useClearStorage";
import withAuth, {Layout} from "withAuth";
import usePageTrackingHook from "hooks/usePageTrackingHook";
import useTabIsActiveHook from "hooks/useTabIsActiveHook";
import {pages as errorPages} from "./mapx-pages/Error";
import {userLicenseTypeNameSelector, userSelector} from "store/mapx/user/userSelectors";

const PrivateRoute = ({component, isAuthenticated, ...rest}) => {
	const Component = useMemo(() => withAuth(component), [component]);

	const userInfo = useSelector((state) => state.user.user);

	const loggedOutManually = useSelector((state) => state.user.loggedOutManually);

	const getRouteComponent = (props) => {
		let routeComponent;
		const role = userInfo.role;

		const allowedToVisitMapX = ["Admin", "MapX User", "RT+MapX User"];

		if (isAuthenticated) {
			if (allowedToVisitMapX.includes(role)) {
				routeComponent = <Component {...props} />;
			} else {
				routeComponent = <Redirect to={"/home"} />;
			}
		} else {
			const pathname = window.location.pathname;
			let redirectTo =
				!loggedOutManually && pathname && !errorPages[pathname]
					? `?redirectTo=${pathname}`
					: "";

			routeComponent = <Redirect to={`/login${redirectTo}`} />;
		}

		return routeComponent;
	};

	return <Route {...rest} render={(props) => getRouteComponent(props)} />;
};

const getRoutes = (links, isAuthenticated) => {
	return links.map((route, i) => {
		const {component: Component, ...rest} = route;

		if (route.private) {
			return <PrivateRoute key={i} {...route} isAuthenticated={isAuthenticated} />;
		}

		return <Route key={i} {...rest} render={(props) => <Component {...props} />} />;
	});
};

const AppContainer = () => {
	const user = useSelector(userSelector);
	const userLicenseTypeName = useSelector(userLicenseTypeNameSelector);

	const isAuthenticated = !!user.email;

	useClearStorage();

	usePageTrackingHook();

	const {tabIsActive} = useTabIsActiveHook();

	const applyFavIcons = (isAlert) => {
		const linkElement = document.querySelector("link[rel=icon]");
		const smallLinkElement = document.querySelector("link[rel=small-icon]");
		const appleLinkElement = document.querySelector("link[rel=apple-touch-icon]");

		const regularIcons = {
			small: "/X-Favicon/favicon-16x16.png",
			regular: "/X-Favicon/favicon-32x32.png",
			apple: "/X-Favicon/apple-icon.png",
		};

		const alertIcons = {
			small: "/X-Favicon-Alert/favicon-16x16.png",
			regular: "/X-Favicon-Alert/favicon-32x32.png",
			apple: "/X-Favicon-Alert/apple-icon.png",
		};

		if (isAlert) {
			linkElement.href = alertIcons.regular;
			smallLinkElement.href = alertIcons.small;
			appleLinkElement.href = alertIcons.apple;
		} else {
			linkElement.href = regularIcons.regular;
			smallLinkElement.href = regularIcons.small;
			appleLinkElement.href = regularIcons.apple;
		}
	};

	useEffect(() => {
		toast.onChange((payload) => {
			if (payload.status === "added" && !tabIsActive) {
				applyFavIcons(true);
			} else if (payload.status === "removed") {
				applyFavIcons(false);
			}
		});

		if (tabIsActive) {
			setTimeout(() => {
				applyFavIcons(false);
			}, 800);
		} else {
			const isToastDisplayed =
				document?.getElementById("toast-container")?.hasChildNodes() || false;

			if (isToastDisplayed) {
				applyFavIcons(true);
			}
		}
	}, [tabIsActive]);

	const shouldDisplayOnBoarding =
		userLicenseTypeName === "Starter" || userLicenseTypeName === "Plus";
	const filteredRoutes = routes(shouldDisplayOnBoarding);

	return (
		<Layout isAuthenticated={isAuthenticated}>
			<Switch>
				<Redirect from="/projects/:id" to="/project/:id" />
				{getRoutes(filteredRoutes, isAuthenticated)}
			</Switch>
		</Layout>
	);
};

export default AppContainer;
