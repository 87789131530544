import React, {useEffect, useRef, useState} from "react";
import useElementOnScreen from "./useElementOnScreen";
import {setTrackedEventsData} from "store/mapx/events/eventTrackingActions";
import {useDispatch} from "react-redux";

interface ItemEventDetails {
	event: string;
	// Add other properties based on the actual structure
}

interface UseEventMonitorProps {
	shouldTrack: boolean;
	itemEventDetails: ItemEventDetails;
	threshold?: number;
}

const useEventMonitor = ({
	shouldTrack,
	itemEventDetails,
	threshold = 0.2,
}: UseEventMonitorProps) => {
	const currentRef = useRef(null);
	const [alreadyAppeared, setAlreadyAppeared] = useState(false);
	const dispatch = useDispatch();

	const [containerRef, isVisible] = useElementOnScreen({
		root: null,
		rootMargin: "0px",
		threshold,
		shouldTrack,
	});

	useEffect(() => {
		if (shouldTrack && isVisible) {
			if (!alreadyAppeared) {
				setAlreadyAppeared(true);
			}

			if (
				!alreadyAppeared ||
				(alreadyAppeared && itemEventDetails.event === "hover") ||
				itemEventDetails.event === "add" ||
				itemEventDetails.event === "remove" ||
				itemEventDetails.event === "expanded" ||
				itemEventDetails.event === "details_viewed" ||
				itemEventDetails.event === "click"
			) {
				dispatch(setTrackedEventsData(itemEventDetails));
			}
		}

		return () => {
			currentRef.current = null;
		};
	}, [dispatch, isVisible, itemEventDetails, shouldTrack, alreadyAppeared]);

	return containerRef as React.MutableRefObject<HTMLDivElement | null>;
};

export default useEventMonitor;
