import {isNull, sortBy} from "lodash";

export function orderIndustriesByDate(industries) {
	const industriesArray = Object.keys(industries).map((industryId) => ({
		id: industryId,
		...industries[industryId],
	}));

	const sortedIndustries = sortBy(industriesArray, [
		(industry) => {
			const firstExperience = industry.experiences[0];

			if (isNull(firstExperience.end_date)) {
				return new Date(2100, 0, 1);
			}

			return new Date(firstExperience.end_date);
		},
		(industry) => {
			return new Date(industry.experiences[0].start_date);
		},
	]).reverse();

	return sortedIndustries;
}
