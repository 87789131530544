import type {IconProps} from "./types";

const ContentCopyIcon = ({color = "#2C3030", ...rest}: IconProps) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={20}
		height={20}
		fill="none"
		viewBox="0 0 20 20"
		{...rest}
	>
		<mask
			id="a"
			width={20}
			height={20}
			x={0}
			y={0}
			maskUnits="userSpaceOnUse"
			style={{
				maskType: "alpha",
			}}
		>
			<path fill="#D9D9D9" d="M0 0h20v20H0z" />
		</mask>
		<g mask="url(#a)">
			<path
				fill={color}
				d="M4.5 18c-.412 0-.766-.147-1.06-.44A1.445 1.445 0 0 1 3 16.5V5h1.5v11.5H14V18H4.5Zm3-3c-.412 0-.766-.147-1.06-.44A1.445 1.445 0 0 1 6 13.5v-10c0-.413.147-.766.44-1.06.294-.293.647-.44 1.06-.44h8c.412 0 .766.147 1.06.44.293.294.44.647.44 1.06v10c0 .412-.147.766-.44 1.06-.294.293-.647.44-1.06.44h-8Zm0-1.5h8v-10h-8v10Z"
			/>
		</g>
	</svg>
);

export default ContentCopyIcon;
