import type {IconProps} from "./types";

const UserSettingsIcon = (props: IconProps) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={24}
		height={24}
		fill="none"
		viewBox="0 0 24 24"
		{...props}
	>
		<path
			fill="#868D8D"
			d="M10 12c-1.1 0-2.042-.392-2.825-1.175C6.392 10.042 6 9.1 6 8s.392-2.042 1.175-2.825C7.958 4.392 8.9 4 10 4s2.042.392 2.825 1.175C13.608 5.958 14 6.9 14 8s-.392 2.042-1.175 2.825C12.042 11.608 11.1 12 10 12Zm-8 8v-2.8c0-.55.142-1.067.425-1.55.283-.483.675-.85 1.175-1.1.85-.433 1.808-.8 2.875-1.1C7.542 13.15 8.717 13 10 13h.35c.1 0 .2.017.3.05a6.96 6.96 0 0 0-.338.938A7.773 7.773 0 0 0 10.1 15H10c-1.183 0-2.246.15-3.188.45-.941.3-1.712.6-2.312.9a.973.973 0 0 0-.5.85v.8h6.3c.1.35.233.696.4 1.038.167.341.35.662.55.962H2Zm14 1-.3-1.5c-.2-.083-.387-.17-.563-.262a4.358 4.358 0 0 1-.537-.338l-1.45.45-1-1.7 1.15-1a4.62 4.62 0 0 1-.05-.65c0-.2.017-.417.05-.65l-1.15-1 1-1.7 1.45.45c.183-.133.363-.246.537-.338.175-.091.363-.179.563-.262L16 11h2l.3 1.5c.2.083.387.175.563.275.175.1.354.225.537.375l1.45-.5 1 1.75-1.15 1c.033.2.05.408.05.625a3.8 3.8 0 0 1-.05.625l1.15 1-1 1.7-1.45-.45a4.358 4.358 0 0 1-.537.338c-.175.091-.363.179-.563.262L18 21h-2Zm1-3c.55 0 1.02-.196 1.413-.587.391-.392.587-.863.587-1.413s-.196-1.02-.587-1.412A1.926 1.926 0 0 0 17 14c-.55 0-1.02.196-1.412.588A1.926 1.926 0 0 0 15 16c0 .55.196 1.02.588 1.413.391.391.862.587 1.412.587Zm-7-8c.55 0 1.02-.196 1.412-.588C11.804 9.021 12 8.55 12 8c0-.55-.196-1.02-.588-1.412A1.926 1.926 0 0 0 10 6c-.55 0-1.02.196-1.412.588A1.926 1.926 0 0 0 8 8c0 .55.196 1.02.588 1.412.391.392.862.588 1.412.588Z"
		/>
	</svg>
);

export default UserSettingsIcon;
