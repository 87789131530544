import type {FC} from "react";

import type {IconProps} from "./types";

const ReportBug: FC<IconProps> = ({className = ""}) => (
	<svg
		width="24"
		fill="none"
		height="24"
		viewBox="0 0 24 24"
		className={className}
		xmlns="http://www.w3.org/2000/svg"
	>
		<mask id="mask0_1610_28894" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
			<rect width="24" height="24" fill="#D9D9D9" />
		</mask>
		<g mask="url(#mask0_1610_28894)">
			<path
				d="M12 21C10.9167 21 9.91267 20.7333 8.988 20.2C8.06267 19.6667 7.33333 18.9333 6.8 18H4V16H6.1C6.05 15.6667 6.021 15.3333 6.013 15C6.00433 14.6667 6 14.3333 6 14H4V12H6C6 11.6667 6.00433 11.3333 6.013 11C6.021 10.6667 6.05 10.3333 6.1 10H4V8H6.8C7.03333 7.61667 7.296 7.25833 7.588 6.925C7.87933 6.59167 8.21667 6.3 8.6 6.05L7 4.4L8.4 3L10.55 5.15C11.0167 5 11.4917 4.925 11.975 4.925C12.4583 4.925 12.9333 5 13.4 5.15L15.6 3L17 4.4L15.35 6.05C15.7333 6.3 16.0793 6.58733 16.388 6.912C16.696 7.23733 16.9667 7.6 17.2 8H20V10H17.9C17.95 10.3333 17.9793 10.6667 17.988 11C17.996 11.3333 18 11.6667 18 12H20V14H18C18 14.3333 17.996 14.6667 17.988 15C17.9793 15.3333 17.95 15.6667 17.9 16H20V18H17.2C16.6667 18.9333 15.9377 19.6667 15.013 20.2C14.0877 20.7333 13.0833 21 12 21ZM12 19C13.1 19 14.0417 18.6083 14.825 17.825C15.6083 17.0417 16 16.1 16 15V11C16 9.9 15.6083 8.95833 14.825 8.175C14.0417 7.39167 13.1 7 12 7C10.9 7 9.95833 7.39167 9.175 8.175C8.39167 8.95833 8 9.9 8 11V15C8 16.1 8.39167 17.0417 9.175 17.825C9.95833 18.6083 10.9 19 12 19ZM10 16H14V14H10V16ZM10 12H14V10H10V12Z"
				fill="#404040"
			/>
		</g>
	</svg>
);

export default ReportBug;
