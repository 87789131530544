import type {FC} from "react";
import ContentLoader from "react-content-loader";

import styles from "./CompanySkeletons.module.scss";

const LocationSkeleton: FC = () => (
	<div className={styles.location__wrapper}>
		<ContentLoader
			speed={2}
			height={800}
			width={"100%"}
			backgroundColor="#DCE7E7"
			foregroundColor="#F9F9F9"
		>
			<rect x="1%" y="30" rx="4" width="100%" height="1" />
			<rect x="1%" y="50" rx="4" width="15%" height="37" />
			<rect x="1%" y="100" rx="4" width="99%" height="650" />
			<rect x="1%" y="799" rx="4" width="100%" height="1" />
		</ContentLoader>

		<ContentLoader
			speed={2}
			height={150}
			width={"200"}
			backgroundColor="#d0dbdb"
			foregroundColor="#F9F9F9"
			className={styles.location__item}
		>
			<circle cx="15" cy="50" r="15" />
			<rect x="25%" y="38" rx="4" width="150px" height="24" />

			<circle cx="15" cy="100" r="15" />
			<rect x="25%" y="88" rx="4" width="150px" height="24" />
		</ContentLoader>
	</div>
);

export default LocationSkeleton;
