import type {FC} from "react";

import type {IconProps} from "./types";

const ChangePassword: FC<IconProps> = ({className = ""}) => (
	<svg
		width="24"
		height="24"
		fill="none"
		viewBox="0 0 24 24"
		className={className}
		xmlns="http://www.w3.org/2000/svg"
	>
		<mask id="mask0_2365_43612" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
			<rect width="24" height="24" fill="#D9D9D9" />
		</mask>
		<g mask="url(#mask0_2365_43612)">
			<path
				d="M13 21C11.7667 21 10.6127 20.7707 9.538 20.312C8.46267 19.854 7.51667 19.225 6.7 18.425L8.125 17C8.75833 17.6167 9.49167 18.104 10.325 18.462C11.1583 18.8207 12.05 19 13 19C14.9333 19 16.5833 18.3167 17.95 16.95C19.3167 15.5833 20 13.9333 20 12C20 10.0667 19.3167 8.41667 17.95 7.05C16.5833 5.68333 14.9333 5 13 5C11.0667 5 9.41667 5.68333 8.05 7.05C6.68333 8.41667 6 10.0667 6 12V12.175L7.825 10.35L9.25 11.75L5 16L0.75 11.75L2.175 10.35L4 12.2V12C4 10.75 4.23767 9.579 4.713 8.487C5.18767 7.39567 5.82933 6.44567 6.638 5.637C7.446 4.829 8.396 4.18733 9.488 3.712C10.5793 3.23733 11.75 3 13 3C14.25 3 15.421 3.23733 16.513 3.712C17.6043 4.18733 18.5543 4.829 19.363 5.637C20.171 6.44567 20.8127 7.39567 21.288 8.487C21.7627 9.579 22 10.75 22 12C22 14.5 21.125 16.625 19.375 18.375C17.625 20.125 15.5 21 13 21ZM11 16C10.7167 16 10.4793 15.904 10.288 15.712C10.096 15.5207 10 15.2833 10 15V12C10 11.7167 10.096 11.479 10.288 11.287C10.4793 11.0957 10.7167 11 11 11V10C11 9.45 11.196 8.979 11.588 8.587C11.9793 8.19567 12.45 8 13 8C13.55 8 14.021 8.19567 14.413 8.587C14.8043 8.979 15 9.45 15 10V11C15.2833 11 15.521 11.0957 15.713 11.287C15.9043 11.479 16 11.7167 16 12V15C16 15.2833 15.9043 15.5207 15.713 15.712C15.521 15.904 15.2833 16 15 16H11ZM12 11H14V10C14 9.71667 13.9043 9.479 13.713 9.287C13.521 9.09567 13.2833 9 13 9C12.7167 9 12.4793 9.09567 12.288 9.287C12.096 9.479 12 9.71667 12 10V11Z"
				fill="#404040"
			/>
		</g>
	</svg>
);

export default ChangePassword;
