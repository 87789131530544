import React from "react";

export const HeaderShape1 = () => (
	<svg width="81" height="75" viewBox="0 0 81 75" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M62.8557 67.6424C80.0467 57.7172 85.9815 35.8126 76.1114 18.7172C66.2414 1.62176 29.9705 -6.16756 13.8712 8.52527C-2.22818 23.2182 -2.26817 44.2752 7.60188 61.3706C17.4719 78.466 45.6647 77.5676 62.8557 67.6424Z"
			fill="url(#paint0_linear_1658_5437)"
		/>
		<defs>
			<linearGradient
				id="paint0_linear_1658_5437"
				x1="32.4995"
				y1="2.42795"
				x2="69.5927"
				y2="74.0737"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#23786D" />
				<stop offset="1" stopColor="#2A688A" />
			</linearGradient>
		</defs>
	</svg>
);

export const HeaderShape2 = () => (
	<svg
		width="134"
		height="141"
		viewBox="0 0 134 141"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M0.150391 42.5C0.150391 96.9001 56.2593 141 110.656 141C165.053 141 209.15 96.9001 209.15 42.5C209.15 -11.9 165.053 -56 110.656 -56C56.2593 -56 0.150391 -11.9 0.150391 42.5Z"
			fill="url(#paint0_linear_1658_5438)"
		/>
		<defs>
			<linearGradient
				id="paint0_linear_1658_5438"
				x1="110.653"
				y1="-56"
				x2="110.653"
				y2="141"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#DEDABA" />
				<stop offset="1" stopColor="#236978" />
			</linearGradient>
		</defs>
	</svg>
);
