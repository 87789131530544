/** @format */

import {useCallback, useEffect, useRef} from "react";
import {scaleLinear} from "d3-scale";
import {select} from "d3-selection";
import "./_chart.scss";

// Components
import Slider from "./Slider";
// import Input from "./Input";

// Helpers
import config from "./config";
import generateSteps from "./generateSteps";
import generateLabel from "./generateLabel";
import LogScale from "log-scale";

global.logScale = LogScale;

// Getting constants
const {ACTIVE_COLOR, INACTIVE_COLOR, MARGIN_HORIZONTAL, LABEL_HEIGHT, LABELS} = config;

const SliderChartFilter = ({
	range = [1, 1],
	data = [],
	width = 340,
	height = 60,
	// numberOfLabels = 2,
	onChange,
	startLabel = "Min Headcount",
	endLabel = "Max Headcount",
	onChangeEnd,
	name = "size",
}) => {
	// const [localRange, setLocalRange] = useState([0, 0]);

	// Refs
	const _wrapper = useRef(null);
	const _axis = useRef(null);
	const _columns = useRef(null);
	// const _timeout = useRef(null);

	// Dimensions
	const numberOfSteps = data.length;
	const relativeWidth = 1 / numberOfSteps;
	const innerHeight = height;
	const innerWidth = width - 2 * MARGIN_HORIZONTAL;
	const columnWidth = innerWidth / numberOfSteps;
	const rectWidth = columnWidth * 0.6;
	const rectOffset = (columnWidth - rectWidth) / 2;

	// Horizontal bounds
	const xMin = Math.min(...data.map((_) => _.x)) || 1;
	const xMax = Math.max(...data.map((_) => _.x)) || 15000;

	// Generating the columns
	const value = generateSteps(
		data.map((entry) => ({...entry, x: Math.log10(entry.x)})),
		numberOfSteps,
	);

	// Vertical bounds
	const yMax = Math.max(...value.map((_) => _?.y));

	// Range slider
	const rangeValue = range
		.map((val) =>
			val ? (Math.log10(val) - Math.log10(xMin)) / (Math.log10(xMax) - Math.log10(xMin)) : 0,
		)
		.map((e) => Math.max(Math.min(e, 1), 0));
	const _handleSliderChange = (val) => {
		// clearTimeout(_timeout.current);
		const range = val.map((e) =>
			Math.round(Math.pow(10, e * (Math.log10(xMax) - Math.log10(xMin)) + Math.log10(xMin))),
		);

		onChange(range);
		// _timeout.current = setTimeout(() => _handleChangeEnd(range), 500);
	};

	const _handleMouseUp = (val) => {
		const range = val.map((e) =>
			Math.round(Math.pow(10, e * (Math.log10(xMax) - Math.log10(xMin)) + Math.log10(xMin))),
		);

		_handleChangeEnd(range);
	};

	// Scales
	const y = scaleLinear().domain([0, yMax]).range([0, height]);

	const generateChart = useCallback(() => {
		const labels = name === "size" ? [...LABELS] : [];

		// needs to reset to avoid overlap by append
		select(_wrapper.current).selectAll("svg").remove();
		select(_axis.current).selectAll("svg").remove();

		// Generate columns
		_columns.current = select(_wrapper.current)
			.append("svg")
			.attr("width", width)
			.attr("height", height)
			.selectAll("someRandomSelector")
			.data(data)
			.join("g")
			.append("rect")
			.attr("x", (d, i) => i * columnWidth + MARGIN_HORIZONTAL + rectOffset)
			.attr("y", (d) => innerHeight - y(d.y))
			.attr("width", rectWidth)
			.attr("height", (d) => y(d.y))
			.attr("fill", ACTIVE_COLOR);

		// Generate labels
		select(_axis.current)
			.append("svg")
			.attr("width", width)
			.attr("height", LABEL_HEIGHT)
			.selectAll("someRandomSelector")
			.data([...labels.filter((label) => label <= xMax / 2 && label >= xMin), null])
			.join("text")
			.text((d) => (d ? generateLabel(d) : "Max")) // to show label "Max" at the end of line .text((d) => (d ? generateLabel(d) : "Max"))
			// TODO: Fix horizontal position
			.attr("x", (d, i, e) =>
				d
					? ((Math.log10(d) - Math.log10(xMin)) / (Math.log10(xMax) - Math.log10(xMin))) *
							innerWidth +
					  MARGIN_HORIZONTAL -
					  e[i].getBoundingClientRect().width / 2
					: innerWidth + MARGIN_HORIZONTAL - e[i].getBoundingClientRect().width / 2,
			)
			.attr("y", LABEL_HEIGHT)
			.style("font-size", 12)
			.style("font-weight", 500)
			.style("font-family", '"Inter", sans-serif');

		// // Setting local range value
		// setLocalRange(range);
	}, [
		name,
		columnWidth,
		data,
		height,
		innerHeight,
		innerWidth,
		rectOffset,
		rectWidth,
		width,
		xMax,
		xMin,
		y,
	]);
	// Generate chart

	useEffect(generateChart, [generateChart]);

	// Updating chart styling based on sliders
	useEffect(() => {
		_columns.current.attr("fill", (d, i, e) =>
			i / e.length >= rangeValue[0] - relativeWidth / 2 &&
			i / e.length <= rangeValue[1] - relativeWidth / 2
				? ACTIVE_COLOR
				: INACTIVE_COLOR,
		);
		// setLocalRange(range);
	}, [rangeValue, range, relativeWidth]);

	const _handleChangeEnd = (range) => onChangeEnd(range);

	// const _handleChange = (index) => (val) => {
	// 	const v = parseInt(val) || xMin;

	// 	clearTimeout(_timeout.current);
	// 	const range = [...localRange];

	// 	if (index === 0) {
	// 		range[index] = v > range[1] ? range[1] - 1 : v;
	// 	} else if (index === 1) {
	// 		range[index] = v < range[0] ? range[0] + 1 : v;
	// 	}

	// 	range[index] = range[index] < xMin ? xMin : range[index];

	// 	setLocalRange(range);
	// 	onChange(range);
	// };

	// const _handleSubmit = (e) => {
	// 	e.preventDefault();
	// 	e.stopPropagation();

	// 	const range = [...localRange].sort((a, b) => a - b);

	// 	onChange(range);
	// 	_timeout.current = setTimeout(() => _handleChangeEnd(range), 500);
	// };

	return (
		<div className="chart-wrapper" style={{width}}>
			<div className="chart" style={{height}} ref={_wrapper} />

			<div
				className="slider"
				style={{
					paddingLeft: MARGIN_HORIZONTAL,
					paddingRight: MARGIN_HORIZONTAL,
				}}
			>
				<Slider
					value={rangeValue}
					onPureChange={_handleSliderChange}
					onMouseUp={_handleMouseUp}
					range
					xMin={xMin}
					xMax={xMax}
					disabled
				/>
			</div>

			<div className="axis" ref={_axis} />

			<div className="label-row">
				<span>{`${startLabel}`}</span>
				<span>{`${endLabel}`}</span>
			</div>

			{/* <form className="inputs" onSubmit={_handleSubmit}>
				<div style={{visibility: "hidden"}}>
					<Input
						type="number"
						value={Math.round(localRange[0].toString())}
						onBlur={_handleSubmit}
						onChange={_handleChange(0)}
						max={range[1]}
						min={xMin}
						placeholder="Min Headcount"
					/>

					<Input
						type="number"
						value={Math.round(localRange[1].toString())}
						onBlur={_handleSubmit}
						onChange={_handleChange(1)}
						min={range[0]}
						max={xMax}
						placeholder="Max Headcount"
					/>
					<input
						type="submit"
						value="submit"
						style={{visibility: "hidden", position: "absolute", zIndex: -1}}
						tabIndex={-1}
					/>
				</div>
			</form> */}
		</div>
	);
};

export default SliderChartFilter;
