import type {FC} from "react";

import type {IconProps} from "./types";

const BadgeArrow: FC<IconProps> = ({fill = "#0C5850", className = ""}) => (
	<svg
		width="20"
		height="20"
		fill="none"
		viewBox="0 0 20 20"
		className={className}
		xmlns="http://www.w3.org/2000/svg"
	>
		<mask
			x="0"
			y="0"
			width="20"
			height="20"
			id="mask0_1394_37441"
			maskUnits="userSpaceOnUse"
			style={{maskType: "alpha"}}
		>
			<rect width="20" height="20" fill="#D9D9D9" />
		</mask>
		<g mask="url(#mask0_1394_37441)">
			<path
				fill={fill}
				d="M9.25 14V8.875L7.0625 11.0625L6 10L10 6L14 10L12.9375 11.0625L10.75 8.875V14H9.25Z"
			/>
		</g>
	</svg>
);

export default BadgeArrow;
