export function formatCandidateNumberToHumanReadableFormat(num: number): string {
	if (num < 1000) {
		return num.toString();
	} else if (num >= 1000 && num < 10_000) {
		// Round up to the nearest hundred for numbers between 1k and 10k
		const roundedHundreds = Math.floor(num / 100); // Get the hundreds place

		return `${roundedHundreds / 10}k+`; // Divide by 10 to keep it in k format
	} else if (num >= 10_000 && num < 100_000) {
		// Round up to the nearest thousand for numbers between 10k and 100k
		const roundedThousands = Math.floor(num / 1000); // Get the thousands place

		return `${roundedThousands}k+`; // Simply append 'k' after rounding
	} else if (num >= 100_000 && num < 1_000_000) {
		// Round down to the nearest ten thousand for numbers between 100k and 1 million
		const roundedTenThousands = Math.floor(num / 10000) * 10; // Round down to nearest 10k

		return `${roundedTenThousands}k+`; // Convert to 'k' format
	} else if (num >= 1_000_000 && num <= 10_000_000) {
		// Numbers between 1 million and 10 million
		const millions = Math.floor(num / 100_000) / 10; // Shift 6 digits and floor it

		return `${millions}m+`;
	} else if (num > 10_000_000) {
		return `10m+`; // Cap large numbers at 10m+
	}

	return num.toString(); // Fallback for unexpected cases
}
