import {useCallback, useEffect, useMemo, useState} from "react";
import styles from "./experienceCard.module.scss";
import Experience from "mapx-components/Cards/ExperienceCard/Experience";
import {LineIcon, PlusIcon} from "assets/icons";
import {
	TCandidateExperienceCardProps,
	TExperienceHistoryByCompany,
} from "mapx-components/Cards/ExperienceCard/types";

const ExperienceCard = ({
	positions,
	printRequested = false,
}: Readonly<TCandidateExperienceCardProps>) => {
	const [showMoreExecutive, setShowMoreExecutive] = useState(false);
	const [isExpanded, setIsExpanded] = useState(false);

	const handleShowMoreExecutiveClick = useCallback(() => {
		setShowMoreExecutive(!showMoreExecutive);
	}, [showMoreExecutive]);

	const executiveExperiences = useMemo(() => {
		if (positions === null) {
			return [];
		} else {
			const executive: TExperienceHistoryByCompany[] = [];
			const executiveCompanyIds: Set<number> = new Set();

			positions?.forEach((exp) => {
				const {company, ...rest} = exp;

				if (executiveCompanyIds.has(company.id)) {
					const index = executive.findIndex((g) => g.company.id === company.id);
					executive[index] = {company, details: [...executive[index].details, rest]};
				} else {
					executiveCompanyIds.add(company.id);
					executive.push({company, details: [rest]});
				}
			});

			return executive || [];
		}
	}, [positions]);

	if (executiveExperiences && !executiveExperiences?.length) {
		return null;
	}

	useEffect(() => {
		if (printRequested) {
			setIsExpanded(true);
			setShowMoreExecutive(true);
		}
	}, [printRequested]);

	const details = executiveExperiences.flatMap((val) => val.details);

	const hasDescriptions = details.some((val) => val.description);

	return (
		<div className={styles.card}>
			{executiveExperiences?.length > 0 && (
				<div className={styles.cardTitle}>
					<span>Experience</span>

					{!printRequested && hasDescriptions && (
						<button
							className={styles.expandButton}
							onClick={() => setIsExpanded((prev) => !prev)}
						>
							{isExpanded ? <LineIcon /> : <PlusIcon />}
							<span>
								{isExpanded ? "Collapse" : "Expand"} role
								{executiveExperiences.length === 1 ? "" : "s"}
							</span>
						</button>
					)}
				</div>
			)}

			<div className={styles.experienceList}>
				{executiveExperiences?.map((exp: TExperienceHistoryByCompany, i) => (
					<Experience
						experience={exp}
						isExpanded={printRequested || isExpanded}
						index={i}
						key={exp.company.id}
						showAll={printRequested || showMoreExecutive}
						printRequested={printRequested}
					/>
				))}
			</div>

			{!printRequested && executiveExperiences.length > 4 && (
				<div className={styles.center}>
					<div className={styles.showMore} onClick={handleShowMoreExecutiveClick}>
						{showMoreExecutive
							? "Show less experience"
							: `Show more experience (${executiveExperiences.length - 4})`}
					</div>
				</div>
			)}
		</div>
	);
};

export default ExperienceCard;
