import type {IconProps} from "./types";

const LearnToRankIcon = (props: IconProps) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={25}
		height={25}
		fill="none"
		viewBox="0 0 25 25"
		{...props}
	>
		<mask
			id="a"
			width={25}
			height={25}
			x={0}
			y={0}
			maskUnits="userSpaceOnUse"
			style={{
				maskType: "alpha",
			}}
		>
			<path fill="#D9D9D9" d="M.5.5h24v24H.5z" />
		</mask>
		<g mask="url(#a)">
			<path
				fill="#383838"
				d="m21.5 12.5-4.35 6.15a1.91 1.91 0 0 1-.712.625 2.02 2.02 0 0 1-.938.225h-10c-.55 0-1.02-.196-1.412-.587A1.926 1.926 0 0 1 3.5 17.5v-10c0-.55.196-1.02.588-1.412A1.926 1.926 0 0 1 5.5 5.5h10c.333 0 .646.075.938.225.291.15.529.358.712.625l4.35 6.15Zm-2.45 0-3.55-5h-10v10h10l3.55-5Zm-8.025 4 1.25-2.75 2.75-1.25-2.75-1.25-1.25-2.75-1.25 2.75-2.75 1.25 2.75 1.25 1.25 2.75Z"
			/>
		</g>
	</svg>
);

export default LearnToRankIcon;
