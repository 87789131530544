import type {IconProps} from "./types";

const InvitationInvalidIcon = (props: IconProps) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={256}
			height={256}
			fill="none"
			viewBox="0 0 256 256"
			{...props}
		>
			<g clipPath="url(#a)">
				<path
					fill="#F4F4F4"
					d="M128 256c70.692 0 128-57.308 128-128C256 57.308 198.692 0 128 0 57.308 0 0 57.307 0 128c0 70.692 57.307 128 128 128Z"
				/>
				<rect width={184} height={140} x={36} y={58} fill="#CBC5C2" rx={8} />
				<path
					fill="#0C5850"
					d="M47.906 74a4 4 0 1 0 0-8 4 4 0 0 0 0 8ZM59.906 74a4 4 0 1 0 0-8 4 4 0 0 0 0 8ZM71.906 74a4 4 0 1 0 0-8 4 4 0 0 0 0 8Z"
				/>
				<path
					fill="#F5F5F5"
					d="M212 72c0 1.1-.975 2-2.167 2H84.167C82.975 74 82 73.1 82 72v-4c0-1.1.975-2 2.167-2h125.666c1.192 0 2.167.9 2.167 2v4ZM212 186c0 2.2-1.8 4-4 4H48c-2.2 0-4-1.8-4-4V86c0-2.2 1.8-4 4-4h160c2.2 0 4 1.8 4 4v100Z"
				/>
				<mask
					id="b"
					width={48}
					height={48}
					x={104}
					y={112}
					maskUnits="userSpaceOnUse"
					style={{
						maskType: "alpha",
					}}
				>
					<path fill="#D9D9D9" d="M104 112h48v48h-48z" />
				</mask>
				<g mask="url(#b)">
					<path
						fill="#0C5850"
						d="m142.3 146.15-3.7-3.6c1.6-.3 2.933-1.042 4-2.225 1.067-1.183 1.6-2.582 1.6-4.197 0-1.785-.637-3.32-1.91-4.603-1.274-1.283-2.804-1.925-4.59-1.925h-7.2v-4.75h7.15c3.137 0 5.805 1.099 8.003 3.297 2.198 2.198 3.297 4.866 3.297 8.003 0 2.167-.608 4.15-1.825 5.95a11.314 11.314 0 0 1-4.825 4.05Zm-8.35-8.2-3.5-3.6h5.85v3.6h-2.35Zm9.95 19.25-37.15-37.15 2.55-2.55 37.15 37.15-2.55 2.55Zm-17.6-9.95h-7.95c-3.137 0-5.805-1.091-8.003-3.272-2.198-2.182-3.297-4.841-3.297-7.978 0-2.433.7-4.625 2.1-6.575 1.4-1.95 3.25-3.308 5.55-4.075l4.1 4.1h-.45c-1.833 0-3.383.633-4.65 1.9-1.267 1.267-1.9 2.817-1.9 4.65 0 1.833.635 3.383 1.906 4.65 1.271 1.267 2.826 1.9 4.665 1.9h7.929v4.7Zm-6.55-9.45v-3.6h3.8l3.6 3.6h-7.4Z"
					/>
				</g>
			</g>
			<defs>
				<clipPath id="a">
					<path fill="#fff" d="M0 0h256v256H0z" />
				</clipPath>
			</defs>
		</svg>
	);
};

export default InvitationInvalidIcon;
