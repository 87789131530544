import React, {useCallback, useState} from "react";
import styles from "./styles.module.scss";
import JobTitleIcon from "containers/Filters/PeopleFilters/Icons/jobTitleIcon";
import classNames from "classnames";
import {ChevronIcon, HelpIcon} from "assets/icons";
import Switch from "components/Inputs/Switch";
import JobTitlesSearchFilter from "mapx-components/Filters/JobTitlesSearchFilter";
import {useAppDispatch, useAppSelector} from "hooks";
import {
	candidateJobTitleFilterModeSelector,
	candidateSelectedJobTitlesSelector,
} from "store/mapx/filter/filterSelectors";
import {
	setJobTitleFilterMode,
	setMultipleFilterForCandidates,
} from "store/mapx/filter/filterActions";
import {getJobTitleClassification} from "store/mapx/search/jobTitlesAsyncActions";
import {getJobTitleInProgressSelector} from "store/mapx/search/searchSelectors";
import {removeDuplicatesFromArray} from "helpers/filterHandlers";
import {getJobTitlesSuccess} from "store/mapx/search/searchActions";

const JobTitleFilter = () => {
	const [expanded, setExpanded] = useState(false);

	const [loadingClassification, setLoadingClassification] = useState(false);

	const jobTitleFilterMode: "contains" | "close" = useAppSelector(
		candidateJobTitleFilterModeSelector,
	);

	const selectedJobTitles: string[] = useAppSelector(candidateSelectedJobTitlesSelector);

	const getJobTitleInProgress = useAppSelector(getJobTitleInProgressSelector);

	const dispatch = useAppDispatch();

	const setFilters = useCallback(
		async (updatedJobTitles: string[], mode: "contains" | "close") => {
			if (mode === "close") {
				if (updatedJobTitles?.length > 0) {
					setLoadingClassification(true);

					const filters = await dispatch(getJobTitleClassification(updatedJobTitles));

					dispatch(
						setMultipleFilterForCandidates({
							current_job_titles_or: filters.job_titles,
							current_specialisms_or: filters.specialisms,
							current_seniority: filters.current_seniority,
						}),
					);

					setLoadingClassification(false);
				} else {
					dispatch(
						setMultipleFilterForCandidates({
							current_job_titles_or: [],
						}),
					);
				}
			} else {
				dispatch(
					setMultipleFilterForCandidates({
						current_job_titles_or: updatedJobTitles,
						current_specialisms_or: [],
						current_seniority: [],
					}),
				);
			}
		},
		[dispatch],
	);

	const handleBulkUpdate = useCallback(
		(newJobTitles: string[]) => {
			const updatedJobTitles = removeDuplicatesFromArray([
				...selectedJobTitles,
				...newJobTitles,
			]);

			setFilters(updatedJobTitles, jobTitleFilterMode);
		},
		[jobTitleFilterMode, selectedJobTitles, setFilters],
	);

	const handleSwitchClick = useCallback(async () => {
		const mode = jobTitleFilterMode === "contains" ? "close" : "contains";

		dispatch(setJobTitleFilterMode(mode));

		if (selectedJobTitles?.length > 0) {
			setFilters([...selectedJobTitles], mode);
		}
	}, [dispatch, jobTitleFilterMode, selectedJobTitles, setFilters]);

	const handleOnChange = useCallback(
		async (selectedItem: string) => {
			let updatedJobTitles = [...selectedJobTitles];

			if (updatedJobTitles.includes(selectedItem)) {
				updatedJobTitles = updatedJobTitles.filter((jt) => jt !== selectedItem);
			} else {
				updatedJobTitles = [...selectedJobTitles, selectedItem];
			}

			setFilters(updatedJobTitles, jobTitleFilterMode);
		},
		[jobTitleFilterMode, selectedJobTitles, setFilters],
	);

	const handleResetClick = useCallback(() => {
		setFilters([], jobTitleFilterMode);
		dispatch(getJobTitlesSuccess([]));
	}, [jobTitleFilterMode, setFilters, dispatch]);

	return (
		<div className={styles.jobTitleContainer}>
			<div className={styles.titleContainer} onClick={() => setExpanded((prev) => !prev)}>
				<div className={styles.textTitle}>
					<JobTitleIcon /> Job Title
					{selectedJobTitles?.length > 0 && jobTitleFilterMode === "contains" && (
						<span className={styles.count} data-testid="count">
							{selectedJobTitles?.length}
						</span>
					)}
				</div>

				<div>
					<HelpIcon dataTooltipId="jobTitle" data-testid="jobTitle-help-icon" />

					<ChevronIcon
						className={classNames(styles.arrowIcon, {[styles.rotated]: !expanded})}
						width={24}
						height={24}
						color={"#808080"}
					/>
				</div>
			</div>

			<div className={classNames(styles.inputContainer, {[styles.open]: expanded})}>
				<div className={styles.containSwitch}>
					<span className={styles.active}>Contains</span>
					<Switch checked={jobTitleFilterMode === "close"} onChange={handleSwitchClick} />
					<span className={styles.inactive}>Close match</span>
				</div>

				<JobTitlesSearchFilter
					handleOnChange={handleOnChange}
					handleResetClick={handleResetClick}
					selectedJobTitles={selectedJobTitles}
					handleBulkUpdate={handleBulkUpdate}
					addByClick={true}
					loading={getJobTitleInProgress || loadingClassification}
					expanded={expanded}
				/>
			</div>
		</div>
	);
};

export default JobTitleFilter;
