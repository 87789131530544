import {useEffect} from "react";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";
import {logoutUser} from "store/mapx/user/userAsyncAction";
import packageInfo from "../../package.json";

export const useClearStorage = () => {
	const history = useHistory();
	const dispatch = useDispatch();

	useEffect(() => {
		const checkVersion = async () => {
			const localStorageVersion = localStorage.getItem("version");
			const currentVersion = packageInfo.version;

			// If version is not stored at all
			if (localStorageVersion === null || localStorageVersion === undefined) {
				localStorage.setItem("version", currentVersion);
			}

			// If storage is set during old version
			// @todo temporary refactor,if localstorage version missing, don't logout
			if (localStorageVersion && localStorageVersion !== currentVersion) {
				localStorage.clear();
				await dispatch(logoutUser());
				localStorage.setItem("version", currentVersion);
				history.push("/login");
			}
		};

		checkVersion().then((r) => r);
	}, [dispatch, history]);
};
