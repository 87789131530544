import type {FC} from "react";
import ContentLoader from "react-content-loader";
import React from "react";

const IndustriesStatsSkeleton: FC = (props) => (
	<ContentLoader
		speed={2}
		height={80}
		width={"100%"}
		backgroundColor="#DCE7E7"
		foregroundColor="#F9F9F9"
		{...props}
	>
		<rect x="45%" y="20" rx="5" ry="5" width="15%" height="15" />
		<rect x="40%" y="50" rx="5" ry="5" width="25%" height="5" />
	</ContentLoader>
);

export default IndustriesStatsSkeleton;
