import {
	ADD_SPECIALISM_TO_LIST,
	ADD_SPECIALITY_CLUSTER_TO_LIST,
	ADD_SPECIALITY_TO_LIST,
	GET_CANDIDATE_COMPANY_DATE_RANGE_SUCCESS,
	GET_CANDIDATE_COMPANY_EVENT_SIZE_RANGE_SUCCESS,
	GET_CANDIDATE_COMPANY_EVENTS_FETCH,
	GET_CANDIDATE_COMPANY_EVENTS_SUCCESS,
	GET_COMPANY_HEADCOUNT_RANGE_SUCCESS,
	GET_COMPANY_REVENUE_RANGE_SUCCESS,
	GET_COMPANY_TYPE_SUCCESS,
	GET_COUNTRIES_FETCH,
	GET_COUNTRIES_SUCCESS,
	GET_INDEXES_FETCH,
	GET_INDEXES_SUCCESS,
	GET_INDUSTRIES_FETCH,
	GET_INDUSTRIES_SUCCESS,
	GET_JOB_FUNCTIONS_FETCH,
	GET_JOB_FUNCTIONS_SUCCESS,
	GET_JOB_TITLES_COMPLETED,
	GET_JOB_TITLES_FETCH,
	GET_JOB_TITLES_SUCCESS,
	GET_KEYWORDS_FETCH,
	GET_KEYWORDS_SUCCESS,
	GET_LANGUAGE_FETCH,
	GET_LANGUAGE_SUCCESS,
	GET_SEARCH_DATA_FAILED,
	GET_SEARCH_DATA_FETCH,
	GET_SEARCH_DATA_SUCCESS,
	GET_SENIORITY_SUCCESS,
	GET_SPECIALISMS_SUCCESS,
	GET_SPECIALITIES_CLUSTER_FETCH,
	GET_SPECIALITIES_FETCH,
	GET_SPECIALITIES_SUCCESS,
	GET_SPECIALITY_CLUSTERS_SUCCESS,
	JOB_TITLE_SEARCH_QUERY,
	LOGOUT,
	SET_AP_KEYWORD_SEARCH_QUERY,
	SET_CANDIDATE_COMPANY_EVENTS_SEARCH_QUERY,
	SET_KEYWORD_SEARCH_QUERY,
	SET_SPECIALTY_CLUSTER_SEARCH_QUERY,
	SET_SPECIALTY_SEARCH_QUERY,
} from "../mapXActionTypes";
import {removeDuplicateObjectFromArray} from "helpers/filterHandlers";

export const searchInitialState = {
	searchedData: {
		results: [],
		companiesPagination: null,
		candidatesPagination: null,
	},

	getSearchDataInProgress: null,
	searchDataError: null,

	industryOptionsInProgress: null,
	industryOptions: [],

	keywordOptionsInProgress: null,
	keywordOptions: [],
	keywordPaginationInfo: {},
	keywordSearchQuery: "",
	apKeywordPaginationInfo: {},
	apKeywordSearchQuery: "",

	countryOptionsInProgress: null,
	countryOptions: [],

	indexOptionsInProgress: null,
	indexOptions: [],

	companyHeadcountRangeOptions: [],
	companyRevenueRangeOptions: [],

	seniorityOptions: [],

	companyTypeOptions: [],

	// Languages
	languageOptions: [],
	languageOptionsInProgress: false,

	//Candidate Company Types
	candidateCompanyEvents: [],
	candidateCompanyEventSizeRangeOptions: [],
	candidateCompanyDateRangeOptions: [],
	candidateCompanyEventsSearchQueryInProgress: null,
	candidateCompanyEventsSearchQuery: "",
	candidateCompanyEventsPaginationInfo: {},

	jobFunctionOptionsInProgress: null,
	jobFunctionOptions: [],

	// Speciality
	specialtyOptionsInProgress: false,
	specialtiesOptions: [],
	specialtySearchQuery: "",

	// Speciality Cluster
	specialtyClusterOptionsInProgress: false,
	specialtyClusterSearchInProgress: false,
	specialtyClusterOptions: [],
	specialtyClusterSearchQuery: "",

	// Specialisms
	specialismOptionsInProgress: null,
	specialismOptions: [],
	specialismSearchQuery: "",

	// Job Titles
	jobTitleOptionsInProgress: null,
	jobTitleOptions: [],
	jobTitleSearchQuery: "",
	apJobTitlesPaginationInfo: {},
};

const searchReducer = (state = searchInitialState, action) => {
	switch (action.type) {
		case GET_SEARCH_DATA_FETCH:
			return {...state, getSearchDataInProgress: true};
		case GET_SEARCH_DATA_SUCCESS:
			const newCompaniesPagination = action?.payload?.companiesPagination
				? action.payload?.companiesPagination
				: state?.searchedData?.companiesPagination;
			const newCandidatesPagination = action.payload?.candidatesPagination
				? action?.payload?.candidatesPagination
				: state?.searchedData?.candidatesPagination;

			return {
				...state,
				getSearchDataInProgress: false,
				searchedData: {
					...action.payload,
					companiesPagination: newCompaniesPagination,
					candidatesPagination: newCandidatesPagination,
				},
			};

		case GET_SEARCH_DATA_FAILED:
			return {
				...state,
				getSearchDataInProgress: false,
				searchDataError: action.payload,
			};

		// INDUSTRIES
		case GET_INDUSTRIES_FETCH:
			return {...state, industryOptionsInProgress: true};
		case GET_INDUSTRIES_SUCCESS:
			return {
				...state,
				industryOptionsInProgress: false,
				industryOptions: action.payload,
			};

		// KEYWORDS
		case GET_JOB_TITLES_FETCH:
			return {...state, jobTitleOptionsInProgress: true};
		case GET_JOB_TITLES_COMPLETED:
			return {...state, jobTitleOptionsInProgress: false};
		case GET_JOB_TITLES_SUCCESS:
			// const prevJobTitlesData = [...state.jobTitleOptions];
			// let jobTitlesData = [];
			//
			// if (action.payload.shouldAddMore) {
			// 	jobTitlesData = [...prevJobTitlesData, ...action.payload.results];
			// } else {
			// 	jobTitlesData = removeDuplicateObjectFromArray(
			// 		[...action.payload.results],
			// 		"job_title",
			// 	);
			// }

			// const paginationKey =
			// 	action.payload.type === "additional_profiles"
			// 		? "apJobTitlesPaginationInfo"
			// 		: "keywordPaginationInfo";

			return {
				...state,
				jobTitleOptionsInProgress: false,
				jobTitleOptions: action.payload,
				// apJobTitlesPaginationInfo: action.payload.pagination,
			};
		case JOB_TITLE_SEARCH_QUERY:
			return {
				...state,
				jobTitleSearchQuery: action.payload,
			};

		// JOB TITLES
		case GET_KEYWORDS_FETCH:
			return {...state, keywordOptionsInProgress: true};
		case GET_KEYWORDS_SUCCESS:
			const prevKeywordsData = [...state.keywordOptions];
			let keywordsData = [];

			if (action.payload.shouldAddMore) {
				keywordsData = [...prevKeywordsData, ...action.payload.results];
			} else {
				const previouslySelectedKeywords = [...prevKeywordsData]?.filter((item) =>
					action.payload?.selectedKeywords?.includes(item.id),
				);

				keywordsData = removeDuplicateObjectFromArray([
					...previouslySelectedKeywords,
					...action.payload.results,
				]);
			}

			const paginationKey =
				action.payload.type === "additional_profiles"
					? "apKeywordPaginationInfo"
					: "keywordPaginationInfo";

			return {
				...state,
				keywordOptionsInProgress: false,
				keywordOptions: keywordsData,
				[paginationKey]: action.payload.pagination,
			};
		case SET_KEYWORD_SEARCH_QUERY:
			return {
				...state,
				keywordSearchQuery: action.payload,
			};

		case SET_AP_KEYWORD_SEARCH_QUERY:
			return {
				...state,
				apKeywordSearchQuery: action.payload,
			};

		//COUNTRIES
		case GET_COUNTRIES_FETCH:
			return {...state, countryOptionsInProgress: true};
		case GET_COUNTRIES_SUCCESS:
			return {
				...state,
				countryOptionsInProgress: false,
				countryOptions: action.payload,
			};

		//INDEXES
		case GET_INDEXES_FETCH:
			return {...state, indexOptionsInProgress: true};
		case GET_INDEXES_SUCCESS:
			return {
				...state,
				indexOptionsInProgress: false,
				indexOptions: action.payload,
			};

		case GET_COMPANY_HEADCOUNT_RANGE_SUCCESS:
			return {
				...state,
				companyHeadcountRangeOptions: action.payload,
			};
		case GET_COMPANY_REVENUE_RANGE_SUCCESS:
			return {
				...state,
				companyRevenueRangeOptions: action.payload,
			};

		//SENIORITY
		case GET_SENIORITY_SUCCESS:
			return {
				...state,
				seniorityOptions: action.payload,
			};

		//COMPANY TYPE
		case GET_COMPANY_TYPE_SUCCESS:
			return {
				...state,
				companyTypeOptions: action.payload,
			};

		//LANGUAGE
		case GET_LANGUAGE_FETCH:
			return {...state, languageOptionsInProgress: true};

		case GET_LANGUAGE_SUCCESS:
			return {
				...state,
				languageOptions: action.payload,
				languageOptionsInProgress: false,
			};

		//JOB_FUNCTIONS
		case GET_JOB_FUNCTIONS_FETCH:
			return {...state, jobFunctionOptionsInProgress: true};

		case GET_JOB_FUNCTIONS_SUCCESS:
			return {
				...state,
				jobFunctionOptionsInProgress: false,
				jobFunctionOptions: action.payload,
			};

		//SPECIALITIES
		case GET_SPECIALITIES_FETCH:
			return {...state, specialtyOptionsInProgress: true};
		case GET_SPECIALITIES_CLUSTER_FETCH:
			return {...state, specialtyClusterOptionsInProgress: true};
		case GET_SPECIALITIES_SUCCESS:
			const prevData = [...state.specialtiesOptions];
			let data = [];

			if (action.payload.shouldAddMore) {
				data = [...prevData, ...action.payload.results];
			} else {
				const previouslySelected = [...prevData]?.filter((item) =>
					action.payload?.selectedSpecialities?.includes(item.id),
				);
				data = [...previouslySelected, ...action.payload.results];
			}

			return {
				...state,
				specialtyOptionsInProgress: false,
				specialtiesOptions: data,
			};

		case GET_SPECIALITY_CLUSTERS_SUCCESS:
			const prevClusterData = [...state.specialtyClusterOptions];
			let clusterData = [];

			if (action.payload.shouldAddMore) {
				clusterData = [...prevClusterData, ...action.payload.results];
			} else {
				const previouslySelected = [...prevClusterData]?.filter((item) =>
					action.payload?.selectedSpecialities?.includes(item.id),
				);
				clusterData = [...previouslySelected, ...action.payload.results];
			}

			return {
				...state,
				specialtyClusterOptionsInProgress: false,
				specialtyClusterOptions: clusterData,
				specialtyClusterSearchInProgress: false,
			};

		case ADD_SPECIALITY_TO_LIST:
			return {
				...state,
				specialtiesOptions: [action.payload, ...state.specialtiesOptions],
			};
		case ADD_SPECIALITY_CLUSTER_TO_LIST:
			return {
				...state,
				specialtyClusterOptions: [action.payload, ...state.specialtyClusterOptions],
			};
		case SET_SPECIALTY_SEARCH_QUERY:
			return {
				...state,
				specialtySearchQuery: action.payload,
			};
		case SET_SPECIALTY_CLUSTER_SEARCH_QUERY:
			return {
				...state,
				specialtyClusterSearchInProgress: true,
				specialtyClusterSearchQuery: action.payload,
			};

		//SPECIALISMS
		case GET_SPECIALISMS_SUCCESS:
			const prevSpecialismsData = [...state.specialismOptions];
			let specialismsData = [];

			if (action.payload.shouldAddMore) {
				specialismsData = [...prevSpecialismsData, ...action.payload.results];
			} else {
				specialismsData = [...action.payload.results];
			}

			return {
				...state,
				specialismOptionsInProgress: false,
				specialismOptions: specialismsData,
			};
		case ADD_SPECIALISM_TO_LIST:
			return {
				...state,
				specialismOptions: [action.payload, ...state.specialismOptions],
			};

		//CANDIDATE COMPANY EVENTS
		case SET_CANDIDATE_COMPANY_EVENTS_SEARCH_QUERY:
			return {
				...state,
				candidateCompanyEventsSearchQuery: action.payload,
			};

		case GET_CANDIDATE_COMPANY_EVENTS_FETCH:
			return {...state, candidateCompanyEventsSearchQueryInProgress: true};

		case GET_CANDIDATE_COMPANY_EVENTS_SUCCESS:
			const prevCandidateCompanyEventsData = [...state.candidateCompanyEvents];
			let candidateCompanyEventsData = [];

			if (action.payload.shouldAddMore) {
				candidateCompanyEventsData = [
					...prevCandidateCompanyEventsData,
					...action.payload.results,
				];
			} else {
				const previouslySelectedCandidateCompanyEvents = [
					...prevCandidateCompanyEventsData,
				]?.filter((item) =>
					action.payload?.selectedCandidateCompanyEvents?.includes(item.id),
				);

				candidateCompanyEventsData = removeDuplicateObjectFromArray([
					...previouslySelectedCandidateCompanyEvents,
					...action.payload.results,
				]);
			}

			return {
				...state,
				candidateCompanyEventsSearchQueryInProgress: false,
				candidateCompanyEvents: candidateCompanyEventsData,
				candidateCompanyEventsPaginationInfo: action.payload.pagination,
			};

		case GET_CANDIDATE_COMPANY_EVENT_SIZE_RANGE_SUCCESS:
			return {
				...state,
				candidateCompanyEventSizeRangeOptions: action.payload,
			};

		case GET_CANDIDATE_COMPANY_DATE_RANGE_SUCCESS:
			return {
				...state,
				candidateCompanyDateRangeOptions: action.payload,
			};

		case LOGOUT:
			return {
				...searchInitialState,
			};
		default:
			return state;
	}
};

export default searchReducer;
