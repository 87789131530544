import type {FC} from "react";
import React from "react";

const ExclamatoryIcon: FC = () => (
	<svg width="20" height="20" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M22.066 37.2665L40.066 19.2665L37.7327 16.9998L22.066 32.6665L14.1993 24.7332L11.9327 27.0665L22.066 37.2665ZM25.9993 51.3332C22.5327 51.3332 19.2549 50.6665 16.166 49.3332C13.0771 47.9998 10.3882 46.1887 8.09935 43.8998C5.81046 41.6109 3.99935 38.9221 2.66602 35.8332C1.33268 32.7443 0.666016 29.4665 0.666016 25.9998C0.666016 22.4887 1.33268 19.1887 2.66602 16.0998C3.99935 13.011 5.81046 10.3332 8.09935 8.0665C10.3882 5.79984 13.0771 3.99984 16.166 2.6665C19.2549 1.33317 22.5327 0.666504 25.9993 0.666504C29.5105 0.666504 32.8105 1.33317 35.8993 2.6665C38.9882 3.99984 41.666 5.79984 43.9327 8.0665C46.1994 10.3332 47.9994 13.011 49.3327 16.0998C50.666 19.1887 51.3327 22.4887 51.3327 25.9998C51.3327 29.4665 50.666 32.7443 49.3327 35.8332C47.9994 38.9221 46.1994 41.6109 43.9327 43.8998C41.666 46.1887 38.9882 47.9998 35.8993 49.3332C32.8105 50.6665 29.5105 51.3332 25.9993 51.3332ZM25.9993 48.3332C32.2216 48.3332 37.4994 46.1554 41.8327 41.7998C46.166 37.4443 48.3327 32.1776 48.3327 25.9998C48.3327 19.7776 46.166 14.4998 41.8327 10.1665C37.4994 5.83317 32.2216 3.6665 25.9993 3.6665C19.8216 3.6665 14.5549 5.83317 10.1993 10.1665C5.84379 14.4998 3.66602 19.7776 3.66602 25.9998C3.66602 32.1776 5.84379 37.4443 10.1993 41.7998C14.5549 46.1554 19.8216 48.3332 25.9993 48.3332Z"
			fill="#0C5850FF"
		/>
	</svg>
);

export default ExclamatoryIcon;
