import React, {useCallback, useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Accordion, DiversitySelectionFilter} from "mapx-components";
import {
	setFilterForCandidates,
	setMultipleFilterForCandidates,
} from "store/mapx/filter/filterActions";

function DiversityFilter({disabled, displayLock = false}) {
	const dispatch = useDispatch();

	const genderDiversityImportance = useSelector(
		(state) => state.filter.candidate_filters.gender_diversity_importance,
	);

	const ethnicDiversityImportance = useSelector(
		(state) => state.filter.candidate_filters.ethnic_diversity_importance,
	);

	const setFilterParam = useCallback(
		(type, values) => {
			dispatch(setFilterForCandidates({type, values}));
		},
		[dispatch],
	);

	const handleResetClick = useCallback(() => {
		dispatch(
			setMultipleFilterForCandidates({
				gender_diversity_importance: null,
				ethnic_diversity_importance: null,
			}),
		);
	}, [dispatch]);

	const count = useMemo(() => {
		let count = 0;

		if (genderDiversityImportance !== null) {
			count += 1;
		}

		if (ethnicDiversityImportance !== null) {
			count += 1;
		}

		return count;
	}, [ethnicDiversityImportance, genderDiversityImportance]);

	return (
		<Accordion
			title="Diversity"
			subtitle={count > 0 && count}
			disabled={disabled}
			displayLock={displayLock}
		>
			<DiversitySelectionFilter
				key={`${ethnicDiversityImportance}${genderDiversityImportance}${count}`}
				setFilterParam={setFilterParam}
				ethnicDiversityImportance={ethnicDiversityImportance}
				genderDiversityImportance={genderDiversityImportance}
				handleResetClick={handleResetClick}
			/>
		</Accordion>
	);
}

export default DiversityFilter;
