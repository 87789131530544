import React from "react";

const SummaryIcon = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
		>
			<mask
				id="mask0_1526_66867"
				style={{maskType: "alpha"}}
				maskUnits="userSpaceOnUse"
				x="0"
				y="0"
				width="20"
				height="20"
			>
				<rect width="20" height="20" fill="#D9D9D9" />
			</mask>
			<g mask="url(#mask0_1526_66867)">
				<path
					d="M5.4941 18C5.08137 18 4.72917 17.8531 4.4375 17.5594C4.14583 17.2656 4 16.9125 4 16.5V3.5C4 3.0875 4.14687 2.73438 4.44062 2.44063C4.73437 2.14688 5.0875 2 5.5 2H12L16 6V16.5C16 16.9125 15.853 17.2656 15.5591 17.5594C15.2652 17.8531 14.9119 18 14.4992 18H5.4941ZM11 7V3.5H5.5V16.5H14.5V7H11Z"
					fill="#4E5555"
				/>
				<path
					d="M9.10417 12.3958L10.0625 14.4375L11 12.3958L13.0625 11.4375L11 10.5L10.0625 8.4375L9.10417 10.5L7.0625 11.4375L9.10417 12.3958Z"
					fill="#4E5555"
				/>
			</g>
		</svg>
	);
};

export default SummaryIcon;
