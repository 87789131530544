import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";

const useCompanyCandidateIdParam = (): string | null => {
	const params = useParams<{candidateId: string}>();
	const [candidateParamId, setCandidateParamId] = useState<string | null>(null);

	useEffect(() => {
		if (candidateParamId && !params.candidateId) {
			setCandidateParamId(null);

			return;
		}

		if (params.candidateId && (!candidateParamId || candidateParamId !== params.candidateId)) {
			setCandidateParamId(params.candidateId);
		}
	}, [candidateParamId, params.candidateId]);

	return candidateParamId || params.candidateId;
};

export default useCompanyCandidateIdParam;
