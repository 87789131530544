import HttpClient from "api/index";

class MapxSpecialismApi extends HttpClient {
	async getSpecialisms(pageNumber: number) {
		return this.doGet(`/mapx/specialisms?page=${pageNumber}&per_page=40`);
	}

	async searchSpecialisms(searchTerm: string, pageNumber: number) {
		return this.doGet(`/mapx/specialisms?name=${searchTerm}&page=${pageNumber}&per_page=40`);
	}
}

const mapxSpecialismApi = new MapxSpecialismApi();

export default mapxSpecialismApi;
