import {ArrowDown, ClearSelectionIcon, LockIcon, MagicUser} from "assets/icons";
import RefreshIcon from "assets/icons/RefreshIcon";
import RejectMinusIcon from "assets/icons/RejectMinusIcon";
import SavePlusIcon from "assets/icons/SavePlusIcon";
import classNames from "classnames";
import {customConfirmAlert} from "helpers";
import {buildCandidateApiRequestPayload} from "helpers/filterHandlers";
import {useAppDispatch, useAppSelector} from "hooks";
import {ActionAltButton, InputProfilesButton} from "mapx-components";
import React, {useCallback, useMemo, useState} from "react";
import {Link} from "react-router-dom";
import {
	clearAPFilters,
	toggleDisplayRefreshAPResult,
} from "store/mapx/additional-profiles/additionalProfilesActions";
import {
	activeProjectResultsTabContentSelector,
	activeSearchRequestSelector,
	additionalProfileFiltersSelector,
	displayRefreshSearchSelector,
} from "store/mapx/additional-profiles/additionalProfilesSelectors";
import styles from "./styles.module.scss";
import {approveORejectAllCandidates} from "store/mapx/additional-profiles/candidateDecisionAsyncActions";
import {requestGenerateMoreResults} from "store/mapx/additional-profiles/quickSearchAsyncActions";
import {userLicenseTypeNameSelector} from "store/mapx/user/userSelectors";
import {TInputCandidate} from "api/projectApi/searchRequestApi/types";
import {TResultHeaderBlockProps} from "mapx-components/SearchRequestResults/ContentBlock/ResultHeaderBlock/types";
import {TProjectsResultsTabContentTypes} from "mapx-components/SearchRequestResults/FilterBlock/types";

const ResultHeaderBlock = ({
	searchType,
	searchStatus,
	iterationCount,
	activeResultsInfo,
	displayAllExperience,
	handleDisplayAllExpClick,
	handleAPICall,
	displayClearFilters,
}: TResultHeaderBlockProps) => {
	// const project = useAppSelector(projectSelector);

	const displayRefreshSearch = useAppSelector(displayRefreshSearchSelector);

	const filterSelection = useAppSelector(additionalProfileFiltersSelector);

	const userLicenseTypeName = useAppSelector(userLicenseTypeNameSelector);

	const userLicenseType = useAppSelector(userLicenseTypeNameSelector);

	const activeSearchRequest = useAppSelector(activeSearchRequestSelector);

	const activeContent: TProjectsResultsTabContentTypes = useAppSelector(
		activeProjectResultsTabContentSelector,
	);

	const dispatch = useAppDispatch();

	const [savingAll, setSavingAll] = useState(false);

	const [rejectingAll, setRejectingAll] = useState(false);

	const [loading, setLoading] = useState(false);

	const [generatingMoreResults, setGeneratingMoreResults] = useState(false);

	const mappedInputCandidate = useMemo(() => {
		return activeSearchRequest?.input_candidates;
	}, [activeSearchRequest?.input_candidates]);

	const displayInputProfilesList = useMemo(
		() => activeContent === "search_results" && mappedInputCandidate?.length > 0,
		[activeContent, mappedInputCandidate?.length],
	);

	const saveIconComponent = <SavePlusIcon />;

	const rejectIconComponent = <RejectMinusIcon />;

	const saveIconComponentForAlert = <SavePlusIcon color={"#FFFFFF"} />;

	const rejectIconComponentForAlert = <RejectMinusIcon color={"#FFFFFF"} />;

	const candidateFilterPayload = useMemo(() => {
		const payload = buildCandidateApiRequestPayload({...filterSelection});

		if (payload !== null) {
			return {...payload.filters, ...payload.sorting_options};
		} else return null;
	}, [filterSelection]);

	const handleRejectAllClick = async () => {
		if (userLicenseTypeName === "Starter") return;

		customConfirmAlert({
			title: "Reject all profiles?",
			message: "This will move all profiles in your current view to the rejected tab.",
			alertType: "error",
			actionButtonPrefixIcon: rejectIconComponentForAlert,
			handlePressYes: async () => {
				setRejectingAll(true);
				await dispatch(approveORejectAllCandidates(false));
				setRejectingAll(false);
			},
		});
	};

	const handleSaveAllClick = async () => {
		if (userLicenseTypeName === "Starter") return;

		customConfirmAlert({
			title: "Save all profiles?",
			message: "This will move all profiles in your current view to your project.",
			alertType: "success",
			actionButtonPrefixIcon: saveIconComponentForAlert,
			handlePressYes: async () => {
				setSavingAll(true);
				await dispatch(approveORejectAllCandidates(true));
				setSavingAll(false);
			},
		});
	};

	const handleRefreshClick = async () => {
		if (handleAPICall) {
			setLoading(true);
			await handleAPICall(1, candidateFilterPayload);
			setLoading(false);
			dispatch(toggleDisplayRefreshAPResult(false));
		}
	};

	const handleGenerateMoreResultsClick = async () => {
		setGeneratingMoreResults(true);

		await dispatch(requestGenerateMoreResults());

		setGeneratingMoreResults(false);
	};

	const displayGenerateMoreResults: boolean = useMemo(() => {
		return (
			userLicenseType !== "Starter" &&
			searchType === "Free Text Input Search" &&
			searchStatus === "Completed" &&
			iterationCount !== undefined &&
			iterationCount > 0
		); // user can run 5 generate more results
	}, [userLicenseType, searchType, searchStatus, iterationCount]);

	const handleClearFilters = useCallback(() => {
		dispatch(clearAPFilters());
	}, [dispatch]);

	const CLEAR_FILTER_DIV = useMemo(
		() =>
			displayClearFilters && (
				<ActionAltButton
					key={"clearFilter"}
					customClass={styles.resetFilterButton}
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					iconComponent={<ClearSelectionIcon />}
					handleClick={handleClearFilters}
					title={"Clear Filters"}
				/>
			),
		[displayClearFilters, handleClearFilters],
	);

	return (
		<div className={styles.actionBlock}>
			{displayInputProfilesList && (
				<div className={styles.inputProfilesBlock}>
					<span className={styles.label}>Based On:</span>
					<span className={styles.buttonsRow}>
						{mappedInputCandidate.map((candidate: TInputCandidate) => (
							<Link
								key={candidate.id}
								to={{
									pathname: `${location.pathname}/${candidate.id}?src=${searchType}`,
								}}
							>
								<InputProfilesButton candidate={candidate} />
							</Link>
						))}
					</span>
				</div>
			)}

			{/*{activeResultsInfo?.count === 0 && CLEAR_FILTER_DIV}*/}

			<div className={styles.actionButtons}>
				<div style={{display: "flex", gap: 10}}>
					{activeResultsInfo?.count > 0 && (
						<div className={styles.actionButtons__container}>
							<ActionAltButton
								key={"expand"}
								customClass={classNames(styles.toggleExpandButton, {
									[styles.reverse]: displayAllExperience,
								})}
								iconComponent={<ArrowDown />}
								handleClick={handleDisplayAllExpClick}
								title={displayAllExperience ? "Collapse All" : "Expand All"}
							/>
						</div>
					)}

					{displayRefreshSearch && (
						<ActionAltButton
							key={"refresh"}
							customClass={styles.refreshBlock}
							iconComponent={
								<RefreshIcon className={classNames({[styles.loading]: loading})} />
							}
							handleClick={handleRefreshClick}
							title={loading ? "Fetching..." : "Refresh"}
						/>
					)}

					{displayGenerateMoreResults && (
						<ActionAltButton
							key={"generate_more_results"}
							customClass={classNames(styles.generateMoreResults, {
								[styles.generating]: generatingMoreResults,
							})}
							iconComponent={<MagicUser />}
							handleClick={handleGenerateMoreResultsClick}
							title={
								generatingMoreResults ? "Generating..." : `Generate More Results`
							}
						/>
					)}

					{CLEAR_FILTER_DIV}
				</div>

				{activeResultsInfo?.count > 0 && (
					<div style={{display: "flex"}}>
						<ActionAltButton
							key={"approve"}
							customClass={classNames(styles.saveButton, {
								[styles.locked]: userLicenseTypeName === "Starter",
							})}
							iconComponent={
								userLicenseTypeName === "Starter" ? <LockIcon /> : saveIconComponent
							}
							handleClick={handleSaveAllClick}
							loading={savingAll}
							title={savingAll ? "Saving All" : "Save All"}
							disabled={savingAll || rejectingAll}
							showUpgradeTooltip={userLicenseTypeName === "Starter"}
							tooltipIdentifier={`upgradeToPlusTooltipSaveAllAIResults`}
						/>

						<ActionAltButton
							key={"reject"}
							customClass={classNames(styles.rejectButton, {
								[styles.locked]: userLicenseTypeName === "Starter",
							})}
							iconComponent={
								userLicenseTypeName === "Starter" ? (
									<LockIcon />
								) : (
									rejectIconComponent
								)
							}
							handleClick={handleRejectAllClick}
							loading={rejectingAll}
							title={rejectingAll ? "Rejecting All" : "Reject All"}
							disabled={savingAll || rejectingAll}
							showUpgradeTooltip={userLicenseTypeName === "Starter"}
							tooltipIdentifier={`upgradeToPlusTooltipRejectAllAIResults`}
						/>
					</div>
				)}
			</div>
		</div>
	);
};

export default ResultHeaderBlock;
