import React, {FC} from "react";
import classNames from "classnames";
import styles from "./styles.module.scss";
import {SkeletonLoaderProps} from "./types";
import ContentLoader from "react-content-loader";

const ProjectSkeletonLoader: FC<SkeletonLoaderProps> = ({count = 5}) => {
	return (
		<div
			data-testid="projects-skeleton"
			className={classNames(styles.skeletonContainer)}
			data-tooltip={"Loading projects..."}
		>
			{Array.from({length: count}, (_, idx) => (
				<div key={idx} className={classNames(styles.skeletonContainer__card)}>
					<ContentLoader
						speed={2}
						height={100}
						width={"100%"}
						backgroundColor="#DCE7E7"
						foregroundColor="#F9F9F9"
					>
						{/* Project Name */}
						<rect x="10" y="20" rx="3" ry="3" width="200" height="20" />
						{/* People, Companies, Diversity */}
						<rect x="10" y="50" rx="3" ry="3" width="150" height="15" />
						<rect x="170" y="50" rx="3" ry="3" width="150" height="15" />
						<rect x="330" y="50" rx="3" ry="3" width="180" height="15" />
						{/* Update Date */}
						<rect x="71%" y="50" rx="3" ry="3" width="140" height="15" />
						{/* Open Project Button */}
						<rect x="87%" y="40" rx="3" ry="3" width="140" height="25" />
						{/* Three dots */}
						<rect x="97%" y="-2.2" rx="1" ry="1" width="2px" height="30" />
					</ContentLoader>
				</div>
			))}
		</div>
	);
};

export default ProjectSkeletonLoader;
