import classNames from "classnames";

import type {FC} from "react";
import type {TRoundedButton} from "./types";

import css from "./roundedButton.module.scss";

const RoundedButton: FC<TRoundedButton> = ({children, className, ...props}) => {
	return (
		<button className={classNames(css.roundedButton, className)} {...props}>
			{children}
		</button>
	);
};

export default RoundedButton;
