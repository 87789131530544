import React from "react";

const FitToBriefIcon = () => {
	return (
		<svg
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<mask
				id="mask0_1733_102222"
				style={{maskType: "alpha"}}
				maskUnits="userSpaceOnUse"
				x="0"
				y="0"
				width="20"
				height="20"
			>
				<rect width="20" height="20" fill="#D9D9D9" />
			</mask>
			<g mask="url(#mask0_1733_102222)">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M2 6.5C2 6.0875 2.14688 5.73438 2.44063 5.44063C2.73438 5.14688 3.0875 5 3.5 5H7V3.49562C7 3.08187 7.14708 2.72917 7.44125 2.4375C7.73528 2.14583 8.08882 2 8.50187 2H11.5054C11.9185 2 12.2708 2.14688 12.5625 2.44063C12.8542 2.73438 13 3.0875 13 3.5V5H16.5C16.9125 5 17.2656 5.14688 17.5594 5.44063C17.8531 5.73438 18 6.0875 18 6.5H16.5H3.5H2ZM11.5 5H8.5V3.5H11.5V5Z"
					fill="#0C5850"
				/>
				<path
					d="M12.8125 17H3.5C3.09722 17 2.74653 16.8507 2.44792 16.5521C2.14931 16.2535 2 15.9028 2 15.5V6.5C2 6.09722 2.14931 5.74653 2.44792 5.44792C2.74653 5.14931 3.09722 5 3.5 5H16.5C16.9125 5 17.2656 5.14931 17.5594 5.44792C17.8531 5.74653 18 6.09722 18 6.5V15.5C18 15.9028 17.8531 16.2535 17.5594 16.5521C17.2656 16.8507 16.9125 17 16.5 17H14.9375L10.9583 13C10.6944 13.1528 10.4196 13.2743 10.1338 13.3646C9.84792 13.4549 9.55333 13.5 9.25 13.5C8.34722 13.5 7.57986 13.184 6.94792 12.5521C6.31597 11.9201 6 11.1528 6 10.25C6 9.34722 6.31597 8.57986 6.94792 7.94792C7.57986 7.31597 8.34722 7 9.25 7C10.1528 7 10.9201 7.31597 11.5521 7.94792C12.184 8.57986 12.5 9.34722 12.5 10.25C12.5 10.5556 12.4583 10.8533 12.375 11.1431C12.2917 11.433 12.1667 11.7047 12 11.9583L15.5625 15.5H16.5V6.5H3.5V15.5H11.3125L12.8125 17ZM9.24583 12C9.73472 12 10.1493 11.8313 10.4896 11.4938C10.8299 11.1561 11 10.7429 11 10.2542C11 9.76528 10.8313 9.35069 10.4938 9.01042C10.1561 8.67014 9.74292 8.5 9.25417 8.5C8.76528 8.5 8.35069 8.66875 8.01042 9.00625C7.67014 9.34389 7.5 9.75708 7.5 10.2458C7.5 10.7347 7.66875 11.1493 8.00625 11.4896C8.34389 11.8299 8.75708 12 9.24583 12Z"
					fill="#0C5850"
				/>
			</g>
		</svg>
	);
};

export default FitToBriefIcon;
