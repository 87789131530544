import type {IconProps} from "./types";

const StarIcon = (props: IconProps) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={17}
			height={17}
			fill="none"
			viewBox="0 0 17 17"
			{...props}
		>
			<mask
				id="a"
				width={17}
				height={17}
				x={0}
				y={0}
				maskUnits="userSpaceOnUse"
				style={{
					maskType: "alpha",
				}}
			>
				<path fill="#D9D9D9" d="M.5.5h16v16H.5z" />
			</mask>
			<g mask="url(#a)">
				<path
					fill="#E0A764"
					d="M4.55 13.9 5.6 9.467 2.1 6.483l4.6-.4L8.5 1.9l1.8 4.2 4.6.383-3.5 2.984 1.05 4.433-3.95-2.35-3.95 2.35Z"
				/>
			</g>
		</svg>
	);
};

export default StarIcon;
