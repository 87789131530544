import {FC} from "react";

import type {IconProps} from "./types";

export const Reset: FC<IconProps> = ({className = ""}) => (
	<svg
		width="20"
		height="20"
		fill="none"
		viewBox="0 0 20 20"
		className={className}
		xmlns="http://www.w3.org/2000/svg"
	>
		<mask id="mask0_2054_17808" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
			<rect width="20" height="20" fill="#D9D9D9" />
		</mask>
		<g mask="url(#mask0_2054_17808)">
			<path
				d="M12.3327 9.97915L11.1452 8.79165L14.1243 4.99998H7.35352L5.68685 3.33331H15.8327C16.1799 3.33331 16.4299 3.48609 16.5827 3.79165C16.7355 4.0972 16.7077 4.38887 16.4993 4.66665L12.3327 9.97915ZM16.4785 18.8333L11.666 14.0208V15.8333C11.666 16.0694 11.5862 16.2673 11.4264 16.4271C11.2667 16.5868 11.0688 16.6666 10.8327 16.6666H9.16601C8.9299 16.6666 8.73199 16.5868 8.57227 16.4271C8.41254 16.2673 8.33268 16.0694 8.33268 15.8333V10.6875L1.16602 3.52081L2.33268 2.33331L17.666 17.6666L16.4785 18.8333Z"
				fill="#D1CDCC"
			/>
		</g>
	</svg>
);
