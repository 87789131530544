import {useEffect} from "react";
import config from "config";
import {hotjar} from "react-hotjar";
import {Mixpanel} from "helpers/mixpanel";
import ReactGA from "react-ga4";
import {useSelector} from "react-redux";
import {
	projectSelector,
	projectTabIndexSelector,
} from "store/mapx/project-list/projectListSelectors";
import {userEmailSelector} from "store/mapx/user/userSelectors";

const useTrackProjectDetails = () => {
	const project = useSelector(projectSelector);

	const projectTabIndex = useSelector(projectTabIndexSelector);

	const userEmail = useSelector(userEmailSelector);

	useEffect(() => {
		try {
			if (project && project?.name) {
				let pageTitle;

				if (projectTabIndex === 4) {
					pageTitle = `Deep Web Search: ${project.name} | ${config.pageTitlePostFix}`;

					if (hotjar.initialized()) {
						hotjar.stateChange(window.location.pathname);
						hotjar.event("Deep Web Search");
					}

					Mixpanel.track(`Project AI Web Search Page Viewed`, {
						name: `${project.name}`,
						pageTitle: `${project.name}`,
						url: window.location.pathname,
						distinct_id: userEmail,
					});
				} else if (projectTabIndex === 3) {
					pageTitle = `Insights: ${project.name} | ${config.pageTitlePostFix}`;

					if (hotjar.initialized()) {
						hotjar.stateChange(window.location.pathname);
						hotjar.event("Insights");
					}

					Mixpanel.track(`Project Insights Report Page Viewed`, {
						name: `${project.name}`,
						pageTitle: `${project.name}`,
						url: window.location.pathname,
						distinct_id: userEmail,
					});
				} else if (projectTabIndex === 2) {
					pageTitle = `Target Companies: ${project.name} | ${config.pageTitlePostFix}`;

					if (hotjar.initialized()) {
						hotjar.stateChange(window.location.pathname);
						hotjar.event("Target Companies");
					}

					Mixpanel.track(`Project Target Companies Page Viewed`, {
						name: `${project.name}`,
						pageTitle: `${project.name}`,
						url: window.location.pathname,
						distinct_id: userEmail,
					});
				} else if (projectTabIndex === 1) {
					pageTitle = `Target People: ${project.name} | ${config.pageTitlePostFix}`;

					if (hotjar.initialized()) {
						hotjar.stateChange(window.location.pathname);
						hotjar.event("Target People");
					}

					Mixpanel.track(`Project Target People Page Viewed`, {
						name: `${project.name}`,
						pageTitle: `${project.name}`,
						url: window.location.pathname,
						distinct_id: userEmail,
					});
				} else {
					pageTitle = `Project Overview: ${project.name} | ${config.pageTitlePostFix}`;

					if (hotjar.initialized()) {
						hotjar.stateChange(window.location.pathname);
						hotjar.event("Overview");
					}

					Mixpanel.track("Project Overview viewed", {
						name: `${project.name}`,
						pageTitle: `${project.name}`,
						url: window.location.pathname,
						distinct_id: userEmail,
					});
				}

				window.document.title = pageTitle;

				ReactGA.send({
					hitType: "pageview",
					page: window.location.pathname,
					title: pageTitle,
				});
			}
		} catch (e) {
			console.log(e);
		}
	}, [project, projectTabIndex, userEmail]);
};

export default useTrackProjectDetails;
