import {useEffect, useMemo} from "react";
import {useHistory} from "react-router-dom";

// Define a custom hook for access control
export function useAccessControl(hasAccessToExtendedPages: boolean, redirectTo = "/403") {
	const history = useHistory();

	const currentRoute = history.location.pathname;

	const extendedPages = useMemo(() => ["/companies", "/candidates", "/market-maps"], []);

	const hasAccess = useMemo(() => {
		if (extendedPages.includes(currentRoute)) {
			return hasAccessToExtendedPages;
		} else {
			return true; // as other pages are allowed by all user
		}
	}, [currentRoute, extendedPages, hasAccessToExtendedPages]);

	useEffect(() => {
		if (!hasAccess) {
			// If the user doesn't have access, redirect to the 403 page
			history.replace(redirectTo);
		}
	}, [hasAccess, history, redirectTo]);
}
