import {useCallback} from "react";
import {useAppDispatch, useAppSelector} from "hooks";
import {filterPositionOptionsSelector} from "store/mapx/filter/filterSelectors";
import {
	TCompanyRevenuePosition,
	TCompanyRevenueRangeItem,
} from "mapx-components/Filters/CompanyRevenueSearchFilter/types";
import CompanyRevenueSearchFilter from "mapx-components/Filters/CompanyRevenueSearchFilter";
import {TabSelect} from "components";
import {
	apCandidateCompanyRevenueByPositionSelector,
	apCandidateCompanyRevenueFilterActivePositionSelector,
	apCandidateCompanyRevenueFilterConfigSelector,
	apCandidatesAllCompanyRevenueCountSelector,
	apSelectedAnyCompanyRevenueTagsSelector,
	apSelectedCurrentCompanyRevenueTagsSelector,
	apSelectedPreviousCompanyRevenueTagsSelector,
} from "store/mapx/additional-profiles/apCompanyRevenueFilterSelectors";
import {
	setAPCompanyRevenueFilterConfig,
	setAPMultipleFilters,
} from "store/mapx/additional-profiles/additionalProfilesActions";
import {
	moveRevenueForAPCandidate,
	setCompanyRevenueForAPCandidate,
} from "store/mapx/additional-profiles/apCompanyRevenueFilterAsyncActions";
import Tags from "mapx-components/Filters/CompanyRevenueSearchFilter/Tags";

const APRevenueFilter = () => {
	const dispatch = useAppDispatch();

	const filterPositionOptions = useAppSelector(filterPositionOptionsSelector);

	const candidateCompanyRevenueFilterConfig = useAppSelector(
		apCandidateCompanyRevenueFilterConfigSelector,
	);

	const position = useAppSelector<TCompanyRevenuePosition>(
		apCandidateCompanyRevenueFilterActivePositionSelector,
	);

	const selectedRevenues = useAppSelector((state) =>
		apCandidateCompanyRevenueByPositionSelector(state, position),
	);

	const currentRevenueTags = useAppSelector(apSelectedCurrentCompanyRevenueTagsSelector);

	const previousRevenueTags = useAppSelector(apSelectedPreviousCompanyRevenueTagsSelector);

	const anyRevenueTags = useAppSelector(apSelectedAnyCompanyRevenueTagsSelector);

	const revenueCount = useAppSelector(apCandidatesAllCompanyRevenueCountSelector);

	const handlePositionChange = useCallback(
		(updatedPositionValue: string) => {
			dispatch(
				setAPCompanyRevenueFilterConfig({
					...candidateCompanyRevenueFilterConfig,
					active_position: updatedPositionValue,
				}),
			);
		},
		[dispatch, candidateCompanyRevenueFilterConfig],
	);

	const handleResetClick = useCallback(() => {
		dispatch(
			setAPMultipleFilters({
				revenue: [],
				current_revenue: [],
				previous_revenue: [],
			}),
		);
	}, [dispatch]);

	const clearTagsSelectionByPosition = useCallback(
		(position: TCompanyRevenuePosition) => {
			if (position === "current") {
				dispatch(
					setAPMultipleFilters({
						current_revenue: [],
					}),
				);
			} else if (position === "previous") {
				dispatch(
					setAPMultipleFilters({
						previous_revenue: [],
					}),
				);
			} else {
				dispatch(
					setAPMultipleFilters({
						revenue: [],
					}),
				);
			}
		},
		[dispatch],
	);

	const handleOnChange = useCallback(
		(revenueItem: TCompanyRevenueRangeItem) => {
			dispatch(setCompanyRevenueForAPCandidate({position, revenueItem}));
		},
		[dispatch, position],
	);

	const moveRevenueTagPosition = useCallback(
		(id: string, from: TCompanyRevenuePosition, to: TCompanyRevenuePosition) => {
			dispatch(
				moveRevenueForAPCandidate({
					from,
					to,
					ids: [id],
				}),
			);
		},
		[dispatch],
	);

	return (
		<div>
			<TabSelect.LabelContainer label="Position">
				<TabSelect
					selected={position}
					onTabChange={handlePositionChange}
					options={filterPositionOptions}
				/>
			</TabSelect.LabelContainer>

			<CompanyRevenueSearchFilter
				handleOnChange={handleOnChange}
				handleResetClick={handleResetClick}
				selectedRevenues={selectedRevenues}
			/>

			{revenueCount > 0 && (
				<>
					<Tags
						tagLabel={"Current"}
						tagValue={"current"}
						tags={currentRevenueTags}
						handleTagClick={handleOnChange}
						clearTagsByPosition={clearTagsSelectionByPosition}
						moveRevenueTagPosition={moveRevenueTagPosition}
					/>

					<Tags
						tagLabel={"Previous"}
						tagValue={"previous"}
						tags={previousRevenueTags}
						handleTagClick={handleOnChange}
						clearTagsByPosition={clearTagsSelectionByPosition}
						moveRevenueTagPosition={moveRevenueTagPosition}
					/>

					<Tags
						tagLabel={"Any"}
						tagValue={"any"}
						tags={anyRevenueTags}
						handleTagClick={handleOnChange}
						clearTagsByPosition={clearTagsSelectionByPosition}
						moveRevenueTagPosition={moveRevenueTagPosition}
					/>
				</>
			)}
		</div>
	);
};

export default APRevenueFilter;
