import type {FC} from "react";
import {useEffect, useState} from "react";
import {useHistory, useLocation} from "react-router-dom";

import {Loader, AuthView} from "components";
import {acceptInvitation} from "mapx-constants";
import {useAppDispatch, useOnAuth} from "hooks";
import colors from "styles/themes.module.scss";
import {verifyInvitationCode} from "store/mapx/organisation/organisationAsyncFunctions";

import css from "./index.module.scss";

const AcceptInvitation: FC = () => {
	const history = useHistory();
	const {search} = useLocation();
	const dispatch = useAppDispatch();

	const verificationCode = new URLSearchParams(search).get("invitation_code") as string;

	const [paramsLoading, setParamsLoading] = useState<boolean>(true);
	const {loading, error, onSubmit} = useOnAuth({
		history,
		type: "accept-invitation",
		redirectUrl: "/home",
		registerInvitationCode: verificationCode,
		themeColor: colors.loaderDotColor,
	});

	const isError = error !== null ? String(error) : "";

	useEffect(() => {
		const verifyInvitation = async () => {
			const response = await dispatch(verifyInvitationCode(verificationCode));

			if (response.status === 200) {
				setParamsLoading(false);
			}
		};

		if (!verificationCode) {
			history.push("/login");
		} else {
			verifyInvitation();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return paramsLoading ? (
		<div className={css.loaderContainer}>
			<Loader height={80} width={80} type="Rings" color={colors.loaderDotColor} />
		</div>
	) : (
		<AuthView
			to="/accept-invitation"
			error={isError}
			showErrorTooltip
			loading={loading}
			onSubmit={onSubmit}
			form={acceptInvitation}
			title="Last Step"
			submitText="Get Started!"
			rightLabel="Forgot Password?"
			description="A few more details and you'll be ready to start searching"
			coverDescription="MapX uses AI to speed up Executive talent ID, find new sources of talent and generate insights into competitors and talent pools."
		/>
	);
};

export default AcceptInvitation;
