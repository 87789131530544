import {
	ACTIVE_ADD_PEOPLE_MODAL_FOR_PROJECT,
	IMPORT_LINKEDIN_PROFILE_IN_PROGRESS,
	REMOVE_IMPORT_DATA_FROM_BULK_IMPORT_STATE,
	REMOVE_REFRESH_DATA_FROM_BULK_IMPORT_STATE,
	RESET_BULK_IMPORT_STATES_FOR_COMPANY,
	RESET_BULK_IMPORT_STATES_FOR_PROJECT,
	RESET_FILTERED_CANDIDATES,
	SEARCH_LINKEDIN_PROFILE_IN_PROGRESS_FOR_CURRENT_IMPORT,
	SET_BACKGROUND_BULK_IMPORTS_IN_PROGRESS_TO_WATCHER,
	SET_BULK_IMPORT_ID_FOR_COMPANY,
	SET_IMPORT_LINKEDIN_PROFILE_INFO,
	SET_IMPORT_LINKEDIN_PROFILE_INFO_FOR_COMPANY,
	SET_LAST__COMPLETE_IMPORT_LINKEDIN_PROFILE_INFO_FOR_PROJECT,
	SET_NON_CLOSED_PROJECT_ID_FOR_LINKEDIN_BULK_IMPORT,
	SET_SELECTED_CANDIDATES_DONE,
	SET_SHOW_IMPORT_REPORT,
	SET_SHOW_LAST_IMPORT_REPORT,
	SET_SHOW_LAST_IMPORT_REPORT_FOR_COMPANY,
} from "../mapXActionTypes";
import {
	TRemoveBulkRefreshLinkedinProfileInfoPayload,
	TSetImportLinkedinProfileInfoPayload,
} from "mapx-components/AddPeopleByLinkedIn/types";
import {STCompany} from "api/companyApi/types";
import {TSetLastCompletedImportInfoPayload} from "../background-tasks/types";

export function setSelectedCandidatesDone(data: boolean) {
	return {type: SET_SELECTED_CANDIDATES_DONE, payload: data};
}

export function resetFilteredCandidates() {
	return {type: RESET_FILTERED_CANDIDATES};
}

export function setBulkImportStateFromBackgroundInProgressData(payload: object) {
	return {type: SET_BACKGROUND_BULK_IMPORTS_IN_PROGRESS_TO_WATCHER, payload};
}

export function importLinkedInCandidateInProgress(payload: boolean) {
	return {type: IMPORT_LINKEDIN_PROFILE_IN_PROGRESS, payload};
}

export function setImportLinkedInProfileInfo(payload: object) {
	return {type: SET_IMPORT_LINKEDIN_PROFILE_INFO, payload};
}

export function setLastCompleteImportLinkedInProfileInfoForProject(
	payload: TSetLastCompletedImportInfoPayload,
) {
	return {type: SET_LAST__COMPLETE_IMPORT_LINKEDIN_PROFILE_INFO_FOR_PROJECT, payload};
}

/**
 ** @param payload: {projectId: true|false}
 */

export function setSearchProgressCandidateByLinkedInForCurrentImport(payload: object) {
	return {type: SEARCH_LINKEDIN_PROFILE_IN_PROGRESS_FOR_CURRENT_IMPORT, payload};
}

export function setBulkImportIdForCompany(payload: Nullable<STCompany>) {
	return {type: SET_BULK_IMPORT_ID_FOR_COMPANY, payload};
}

/**
 ** @param payload: {id: projectId, data: object } object contains import information, status receives from BE
 */

export function setImportLinkedInProfileInfoForCompany(
	payload: TSetImportLinkedinProfileInfoPayload,
) {
	return {type: SET_IMPORT_LINKEDIN_PROFILE_INFO_FOR_COMPANY, payload};
}

export function activeAddPeopleModalForProject(payload: number | null) {
	return {type: ACTIVE_ADD_PEOPLE_MODAL_FOR_PROJECT, payload};
}

export function setShouldOpenBulkImportReport(payload: {id: number; value: boolean}) {
	return {type: SET_SHOW_IMPORT_REPORT, payload};
}

export function setNonClosedProjectIdForLinkedinBulkImport(payload: number) {
	return {type: SET_NON_CLOSED_PROJECT_ID_FOR_LINKEDIN_BULK_IMPORT, payload};
}

export function resetBulkImportStatesForProject(payload: number) {
	return {type: RESET_BULK_IMPORT_STATES_FOR_PROJECT, payload};
}

export function resetBulkImportStatesForCompany(payload: number) {
	return {type: RESET_BULK_IMPORT_STATES_FOR_COMPANY, payload};
}

export function removeBulkImportDataForSource(payload: number) {
	return {type: REMOVE_IMPORT_DATA_FROM_BULK_IMPORT_STATE, payload};
}

export function removeBulkRefreshDataForSource(
	payload: TRemoveBulkRefreshLinkedinProfileInfoPayload,
) {
	return {type: REMOVE_REFRESH_DATA_FROM_BULK_IMPORT_STATE, payload};
}

export function setShowLastImportReport(payload: boolean) {
	return {type: SET_SHOW_LAST_IMPORT_REPORT, payload};
}

export function setShowLastImportReportForCompany(payload: boolean) {
	return {type: SET_SHOW_LAST_IMPORT_REPORT_FOR_COMPANY, payload};
}
