import {Dispatch} from "redux";
import {TAppDispatch, TRootState} from "types";
import {TCompanyTypePosition} from "../../../mapx-components/Filters/CandidateCompanyTypeSearchFilter/types";
import {
	apCandidateCompanyTypesByPositionSelector,
	apCompanyTypeFilterKeyByPosition,
} from "store/mapx/additional-profiles/apCompanyTypeFilterSelectors";
import {setAPMultipleFilters} from "store/mapx/additional-profiles/additionalProfilesActions";

export const apMoveCompanyTypeForCandidate =
	({from, to, id}: {from: TCompanyTypePosition; to: TCompanyTypePosition; id: string}) =>
	async (dispatch: Dispatch<TAppDispatch>, getState: () => TRootState) => {
		const idsSet = new Set([id]);

		const state = getState();

		const fromFilterKey = apCompanyTypeFilterKeyByPosition(state, from);

		const toFilterKey = apCompanyTypeFilterKeyByPosition(state, to);

		const fromCompanyTypes = apCandidateCompanyTypesByPositionSelector(state, from);

		const toCompanyType = apCandidateCompanyTypesByPositionSelector(state, to);

		dispatch(
			setAPMultipleFilters({
				[fromFilterKey]: fromCompanyTypes.filter((id: string) => !idsSet.has(id)),
				[toFilterKey]: [
					...toCompanyType.filter((id: string) => !idsSet.has(id)), // prevent duplication
					...fromCompanyTypes.filter((id: string) => idsSet.has(id)),
				],
			}),
		);
	};

export const apSetCompanyTypeForCandidate =
	({position, id}: {position: TCompanyTypePosition; id: string}) =>
	async (dispatch: Dispatch<TAppDispatch>, getState: () => TRootState) => {
		const state = getState();

		const idsOnState = apCandidateCompanyTypesByPositionSelector(state, position);

		const index = idsOnState.indexOf(id);

		const alreadySelected = index !== -1;

		const nextIds = alreadySelected
			? [...idsOnState.slice(0, index), ...idsOnState.slice(index + 1)]
			: [...idsOnState, id];

		const specialtyFilterKey = apCompanyTypeFilterKeyByPosition(state, position);

		dispatch(setAPMultipleFilters({[specialtyFilterKey]: nextIds}));
	};

export const apClearCompanyTypeByPositionForCandidate =
	(position: TCompanyTypePosition) => (dispatch: Dispatch<TAppDispatch>) => {
		let payload;

		switch (position) {
			case "current":
				payload = {
					current_company_type: [],
				};
				break;
			case "previous":
				payload = {
					previous_company_type: [],
				};
				break;
			default:
				payload = {
					company_type: [],
				};
		}

		dispatch(setAPMultipleFilters(payload));
	};
