import {FC} from "react";

import type {IconProps} from "./types";

const PlusIconModule: FC<IconProps> = ({className = ""}) => (
	<svg
		width="24"
		height="24"
		fill="none"
		viewBox="0 0 24 24"
		className={className}
		xmlns="http://www.w3.org/2000/svg"
	>
		<mask id="mask0_306_9444" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
			<rect width="24" height="24" fill="#D9D9D9" />
		</mask>
		<g mask="url(#mask0_306_9444)">
			<path d="M11 19V13H5V11H11V5H13V11H19V13H13V19H11Z" fill="#404040" />
		</g>
	</svg>
);

export default PlusIconModule;
