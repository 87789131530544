import {Fragment, type FC} from "react";
import {useHistory} from "react-router-dom";

import {Button} from "components";
import {useAppDispatch} from "hooks";
import {NavigationArrowIcon} from "assets/icons";
import {setOnboardingStep} from "store/mapx/project/projectActions";

import {EOnboardingTooltipSteps, type TTooltipNavigationProps} from "./types";
import styles from "./OnboardingTooltip.module.scss";

const TooltipNavigation: FC<TTooltipNavigationProps> = ({step, closeHandler}) => {
	const history = useHistory();
	const dispatch = useAppDispatch();

	const prevHandler = () => {
		if (Number(step) > EOnboardingTooltipSteps.Search) {
			dispatch(setOnboardingStep("decrement"));
			if (step === EOnboardingTooltipSteps.First) {
				history.push("/onboarding/home");
			}
		}
	};

	const nextHandler = () => {
		dispatch(setOnboardingStep("increment"));
		if (step === EOnboardingTooltipSteps.Search) {
			history.push("/onboarding/projects");
		}
	};

	return (
		<div className={styles.footer}>
			<span className={styles.footer__step}>{Number(step) + 1}/7</span>

			<div className={styles.footer__navigation}>
				{/* Start */}
				{step === EOnboardingTooltipSteps.Search && (
					<Button defaultBtnStyle onClick={nextHandler}>
						<NavigationArrowIcon className={styles.footer__navigation_right} />
					</Button>
				)}

				{/* Progress */}
				{step !== null &&
					Number(step) > EOnboardingTooltipSteps.Search &&
					Number(step) < EOnboardingTooltipSteps.Sixth && (
						<Fragment>
							<Button defaultBtnStyle onClick={prevHandler}>
								<NavigationArrowIcon className={styles.footer__navigation_left} />
							</Button>
							<Button defaultBtnStyle onClick={nextHandler}>
								<NavigationArrowIcon />
							</Button>
						</Fragment>
					)}

				{/* Finish */}
				{step === EOnboardingTooltipSteps.Sixth && (
					<Fragment>
						<Button defaultBtnStyle onClick={prevHandler}>
							<NavigationArrowIcon className={styles.footer__navigation_left} />
						</Button>

						<Button
							defaultBtnStyle
							onClick={closeHandler}
							className={styles.footer__navigation_done}
						>
							Done
						</Button>
					</Fragment>
				)}
			</div>
		</div>
	);
};

export default TooltipNavigation;
