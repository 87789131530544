import classNames from "classnames";
import {LazyLoadImageWrapper} from "components";
import {useOpenLink} from "hooks";
import useUserBehaviorTrackHook from "hooks/mapx/useUserBehaviorTrackHook";
import {useMemo} from "react";
import {BiUser} from "react-icons/bi";
import {useDispatch} from "react-redux";
import {useLocation} from "react-router-dom";
import {setTrackedEventsData} from "store/mapx/events/eventTrackingActions";
import css from "./candidateInformation.module.scss";

export default function CandidateInformation({
	avatar,
	candidate,
	isRecommended = false,
	position,
	from,
	detailed,
	fromQuickView,
	history,
}) {
	const openLink = useOpenLink();
	const dispatch = useDispatch();
	const location = useLocation();
	const {itemEventDetails} = useUserBehaviorTrackHook({
		itemType: "candidate",
		defaultEvent: "details_viewed",
		itemId: candidate.id,
		source: from,
		isRecommended,
		position,
	});

	const linkedin_url = candidate?.linkedin_url;

	const clickable = from !== "Profile";

	const positions = candidate?.positions || candidate.experience;

	const currentJob = useMemo(
		() => (positions ? positions.find((val) => !val.end_date) : null),
		[positions],
	);
	const prevJob = useMemo(
		() => (positions ? positions.find((val) => val.end_date) : null),
		[positions],
	);

	const handleItemClick = (event) => {
		event.preventDefault();

		dispatch(setTrackedEventsData(itemEventDetails));

		const originSource = location.pathname.split("/")[1];

		if (clickable) {
			if (
				(fromQuickView ||
					event.ctrlKey ||
					event.metaKey ||
					event.ctrlKey ||
					event.altKey) &&
				event.button === 0
			) {
				const link = `/candidate/${candidate.id}${
					isRecommended
						? `?recommended=true&src=/${originSource}`
						: `?src=/${originSource}`
				}`;

				openLink(event, link, true);
			} else {
				const link = `${location.pathname}/candidate/${candidate.id}${
					isRecommended
						? `?recommended=true&src=/${originSource}`
						: `?src=/${originSource}`
				}`;

				history.push(link);
			}
		}
	};

	const latestExperience = useMemo(() => {
		if (positions?.length > 0) {
			return positions[0];
		}

		return null;
	}, [positions]);

	return (
		<div className={css.informationContainer}>
			<div className={css.infoFirstRow}>
				<div className={classNames(css.avatarContainer)}>
					{avatar ? (
						<LazyLoadImageWrapper
							onClick={handleItemClick}
							className={classNames(css.logo, css.layLoadImage, {
								[css.clickable]: clickable,
							})}
							image={avatar}
							alt={candidate.full_name ?? candidate.name}
						/>
					) : (
						<BiUser
							className={classNames(css.iconLogo, css.layLoadImage, {
								[css.clickable]: clickable,
							})}
							onClick={handleItemClick}
						/>
					)}
					{latestExperience && detailed && (
						<img
							src={
								latestExperience.company.logo_url ||
								"https://storage.googleapis.com/nebula-static/logos100x100/empty_logo.png"
							}
							alt="company"
							className={css.companyLogo}
						/>
					)}
				</div>

				<div className={css.candidateLocation}>
					<div className={classNames(css.candidateTop, {[css.brief]: !detailed})}>
						<div
							className={classNames(css.infoTitle, {[css.clickable]: clickable})}
							onClick={handleItemClick}
						>
							<a
								href={`/candidate/${candidate.id}${
									isRecommended ? "?recommended=true" : ""
								}`}
								target="_blank"
								rel="noopener noreferrer"
							>
								{candidate && (candidate.name || candidate.full_name)}
							</a>
						</div>
						{candidate && candidate.country && candidate.country.name && (
							<div className={css.infoDetail}>
								{candidate && candidate.country?.name}
								{candidate && candidate.town && (
									<>
										<span>{", "}</span>
										{candidate && candidate.town}
									</>
								)}
							</div>
						)}
						{linkedin_url && detailed && (
							<a
								href={linkedin_url}
								className={css.viewOnLinkedin}
								target="_blank"
								rel="noreferrer"
								onClick={(ev) => ev.stopPropagation()}
							>
								View On Linkedin
							</a>
						)}
					</div>

					{detailed && (
						<>
							<div className={css.currentJob}>
								{currentJob && (
									<div>
										<b>{currentJob.company.name}</b>
										<span>{currentJob.job_title}</span>
										{currentJob.year_from && (
											<div className={css.from}>
												{`from ${currentJob.year_from}`}
											</div>
										)}
									</div>
								)}
							</div>
							<div className={css.prevJob}>
								{prevJob && (
									<div>
										<span>Previously worked at</span>
										<b>{prevJob.company.name}</b>
										<span>{prevJob.job_title}</span>
										{prevJob.year_from && prevJob.year_to && (
											<span>
												{prevJob.year_from} - {prevJob.year_to}
											</span>
										)}
									</div>
								)}
							</div>
						</>
					)}
				</div>
			</div>
		</div>
	);
}
