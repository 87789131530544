import {type FC, useCallback, useEffect, useMemo, useState} from "react";
import useInfiniteScroll from "react-infinite-scroll-hook";

import {Loader} from "components";
import colors from "styles/themes.module.scss";
import type {SICandidate} from "api/candidateApi/types";
import {ProjectCandidateCard} from "mapx-components";
import {useAppSelector} from "hooks";
import {buildCandidateApiRequestPayload} from "helpers/filterHandlers";
import css from "mapx-pages/Projects/projects.module.scss";
import {
	activeProjectResultsTabContentSelector,
	additionalProfileFiltersSelector,
	AdditionalProfilesPaginationInfoSelector,
} from "store/mapx/additional-profiles/additionalProfilesSelectors";

import type {TAPResultBlockProps} from "./types";
import {useHistory} from "react-router-dom";
import {TCandidateApiFilterSelection} from "api/candidateApi/form";
import {TProjectsResultsTabContentTypes} from "mapx-components/SearchRequestResults/FilterBlock/types";

const PaginatedResultBlock: FC<TAPResultBlockProps> = ({
	handleAPICall,
	results,
	setCancelled,
	setLoadingFilteredResult,
	displayAllExperience,
	searchType,
	currentSearchStatus,
	limitResult,
	displayingLoader,
}) => {
	const activeContent: TProjectsResultsTabContentTypes = useAppSelector(
		activeProjectResultsTabContentSelector,
	);

	const additionalProfilesFilters = useAppSelector(additionalProfileFiltersSelector);

	const paginationInfo = useAppSelector(AdditionalProfilesPaginationInfoSelector);

	const [paginateLoading, setPaginateLoading] = useState<boolean>(false);

	const history = useHistory();

	const getProfiles = useCallback(
		async (filterPayload?: Nullable<TCandidateApiFilterSelection>, page = 1) => {
			const response = await handleAPICall(page, filterPayload);

			if (setCancelled) {
				if (response === "cancelled") {
					setCancelled(true);
				} else {
					setCancelled(false);
				}
			}
		},
		[handleAPICall, setCancelled],
	);

	const candidateFilterPayload = useMemo(() => {
		const payload = buildCandidateApiRequestPayload({...additionalProfilesFilters});

		if (payload !== null) {
			return {...payload.filters, ...payload.sorting_options};
		} else return null;
	}, [additionalProfilesFilters]);

	useEffect(() => {
		if (currentSearchStatus === "Completed") {
			if (setLoadingFilteredResult) {
				setLoadingFilteredResult(true);
			}

			if (candidateFilterPayload != null) {
				getProfiles(candidateFilterPayload).finally(() => {
					if (setLoadingFilteredResult) {
						setLoadingFilteredResult(false);
					}
				});
			} else {
				getProfiles(null).finally(() => {
					if (setLoadingFilteredResult) {
						setLoadingFilteredResult(false);
					}
				});
			}
		}
	}, [getProfiles, currentSearchStatus, candidateFilterPayload, setLoadingFilteredResult]);

	const displayedResults = useMemo(() => {
		if (results?.count > 0 && limitResult !== undefined) {
			if (results.count <= limitResult) {
				return results.candidates;
			} else {
				return [...results.candidates].splice(0, limitResult + 1);
			}
		} else {
			return results.candidates;
		}
	}, [limitResult, results]);

	/**
	 * Pagination Logic For Both Additional Profiles Result
	 * IF we are limiting results, then pagination disabled
	 */
	const [sentryRef] = useInfiniteScroll({
		loading: paginateLoading,
		hasNextPage:
			paginationInfo?.pages > paginationInfo?.page &&
			(limitResult !== undefined ? displayedResults?.length < limitResult : true),
		onLoadMore: async () => {
			setPaginateLoading(true);
			await getProfiles(candidateFilterPayload, paginationInfo?.page + 1 || 1).finally(() =>
				setPaginateLoading(false),
			);
		},
		disabled: paginationInfo === null || currentSearchStatus === "In Progress",
		delayInMs: 300,
	});

	const LoaderComponent = (
		<div className={css.loaderContainer}>
			<Loader height={40} width={40} type="ThreeDots" color={colors.loaderDotColor} />
		</div>
	);

	return (
		<div>
			{displayedResults?.length > 0 &&
				displayedResults?.map((candidate: SICandidate, index: number) => (
					<ProjectCandidateCard
						key={`${candidate?.id}`}
						candidate={candidate}
						searchType={searchType}
						isAdditionalProfileList={activeContent === "search_results"}
						displayAllExperience={displayAllExperience}
						upgradeView={limitResult !== undefined ? index === limitResult : false}
					/>
				))}

			{!displayingLoader && !(displayedResults?.length > 0) && (
				<div className={css.noResultFound}>
					{(searchType === "Free Text Input Search" ||
						searchType === "Generate More Results") && (
						<div>
							Hmm, I have not found any results &#128542;. Try rephrasing what you are
							looking for using the{" "}
							<span onClick={() => history.push("/home")}>
								Start New Search button
							</span>
							.
						</div>
					)}

					{searchType === "More Like This" && (
						<div>
							Hmm, I have not found any results &#128542;. Try choosing another
							profile to search on.
						</div>
					)}

					{searchType === "Additional Profiles" && (
						<div>
							Hmm, I have not found any results &#128542;. Try to re-configure your
							search criteria on Deep Web Search.
						</div>
					)}
				</div>
			)}

			{currentSearchStatus === "Completed" &&
				activeContent !== "rejected_profiles" &&
				!paginateLoading &&
				results.count > 100 && (
					<div
						ref={sentryRef}
						key="additional_profiles"
						style={{width: "100%", height: "20px"}}
					/>
				)}

			{paginateLoading && LoaderComponent}
		</div>
	);
};

export default PaginatedResultBlock;
