import type {TModal} from "./types";

import {CloseIcon} from "assets/icons";

import styles from "./modal.module.scss";

const ModalHeader = ({title, onClose, padding = 16, ...rest}: TModal) => (
	<div className={styles.header} style={{padding}} {...rest} data-testid="modal-header">
		<div className={styles.title}>{title}</div>
		<CloseIcon
			width={24}
			height={24}
			color="#A39C9A"
			onClick={onClose}
			className={styles.close}
			data-testid="close-icon"
		/>
	</div>
);

export default ModalHeader;
