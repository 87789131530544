import {type FC, useCallback, useEffect, useRef, useState} from "react";
import {Redirect} from "react-router-dom";

import colors from "styles/themes.module.scss";
import {NotchArrowDown, NotchArrowLeft, NotchArrowRight} from "assets/icons";
import {Loader} from "components";
import {useAppDispatch, useAppSelector} from "hooks";
import {MainWrapper} from "mapx-components";
import {
	createLearnToRankInProgressSelector,
	lastRecordAtSelector,
	learnToRankDataSelector,
	recordLearnToRankInProgressSelector,
} from "store/mapx/learn-to-rank/learnToRankSelectors";
import {
	createLearnToRank,
	recordLearnToRank,
} from "store/mapx/learn-to-rank/learnToRankAsyncFunctions";
import {isSuperUserSelector, userOrganisationTypeSelector} from "store/mapx/user/userSelectors";

import type {Position} from "./types";

import css from "./learnToRank.module.scss";
import CandidateCard from "./CandidateCard";
import TotalRecords from "./TotalRecords";

const LearnToRank: FC = () => {
	const dispatch = useAppDispatch();

	const timeout = useRef<ReturnType<typeof setTimeout>>();

	const data = useAppSelector(learnToRankDataSelector);
	const creating = useAppSelector(createLearnToRankInProgressSelector);
	const recording = useAppSelector(recordLearnToRankInProgressSelector);
	const lastRecordAt = useAppSelector(lastRecordAtSelector);

	const isSuperUser = useAppSelector(isSuperUserSelector);
	const organisationType = useAppSelector(userOrganisationTypeSelector);

	const [deciding, setDeciding] = useState<Position | null>(null);

	const handleClick = useCallback(
		(position: Position, decisionCandidateId: number | null) => {
			if (!recording) {
				setDeciding(position);
				timeout.current = setTimeout(() => {
					if (decisionCandidateId === null || typeof decisionCandidateId === "number") {
						dispatch(recordLearnToRank(data.id, decisionCandidateId));
					}
				}, 300);
			}
		},
		[data, dispatch, recording],
	);

	const handleArrowKeyDown = useCallback(
		(e: KeyboardEvent) => {
			switch (e.key) {
				case "ArrowLeft":
					handleClick("left", data.option_1_candidate.id);
					break;
				case "ArrowDown":
					handleClick("down", null);
					break;
				case "ArrowRight":
					handleClick("right", data.option_2_candidate.id);
					break;
				default:
					break;
			}
		},
		[data, handleClick],
	);

	useEffect(() => {
		if (lastRecordAt) {
			setDeciding(null);
			dispatch(createLearnToRank());
		}
	}, [dispatch, lastRecordAt]);

	useEffect(() => {
		window.addEventListener("keydown", handleArrowKeyDown);

		return () => {
			window.removeEventListener("keydown", handleArrowKeyDown);
		};
	}, [handleArrowKeyDown]);

	useEffect(() => {
		if (data == null) {
			dispatch(createLearnToRank());
		}

		return () => {
			clearTimeout(timeout.current);
		};
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	if (!isSuperUser || organisationType === "Client") {
		return <Redirect to="/403" />;
	}

	return (
		<MainWrapper className={css.wrapper}>
			<div className={css.container}>
				<div className={css.header}>
					<div className={css.empty} />
					<div className={css.information}>
						<h1>Select the most similar profile</h1>
						<p>
							You can use{" "}
							<b>
								<NotchArrowLeft />
								left arrow
							</b>{" "}
							or{" "}
							<b>
								right arrow <NotchArrowRight />
							</b>{" "}
							to select the candidate.
						</p>
						<p>
							Or press{" "}
							<b>
								<NotchArrowDown /> down arrow
							</b>{" "}
							if you are not sure.
						</p>
					</div>
					<div className={css.completed}>
						<TotalRecords />
					</div>
				</div>
				{creating || data == null ? (
					<div className={css.loaderContainer}>
						<Loader height={80} width={80} type="Rings" color={colors.loaderDotColor} />
					</div>
				) : (
					<div className={css.grids}>
						<CandidateCard
							active={deciding === "left"}
							candidate={data.option_1_candidate}
							hide={!!deciding && deciding !== "left"}
							loading={recording && deciding === "left"}
							logo="A"
							onRecord={() => handleClick("left", data.option_1_candidate.id)}
							position="left"
							title="Candidate A"
						/>
						<CandidateCard
							active={deciding === "down"}
							buttonText="Not Sure"
							candidate={data.query_candidate}
							highlighted
							loading={recording && deciding === "down"}
							logo={
								<svg
									style={{position: "absolute"}}
									width="32"
									height="32"
									viewBox="0 0 32 32"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M6.03854 24.8206C7.45521 23.7693 8.99847 22.9391 10.6683 22.3302C12.3382 21.7212 14.1154 21.4167 16 21.4167C17.8847 21.4167 19.6619 21.7212 21.3317 22.3302C23.0016 22.9391 24.5449 23.7693 25.9615 24.8206C26.9979 23.6817 27.8189 22.3633 28.4247 20.8654C29.0305 19.3675 29.3334 17.7457 29.3334 16C29.3334 12.3056 28.0348 9.15973 25.4375 6.56251C22.8403 3.96529 19.6945 2.66668 16 2.66668C12.3056 2.66668 9.15976 3.96529 6.56254 6.56251C3.96532 9.15973 2.66671 12.3056 2.66671 16C2.66671 17.7457 2.9696 19.3675 3.57537 20.8654C4.18115 22.3633 5.00221 23.6817 6.03854 24.8206ZM16 17.25C14.4787 17.25 13.1956 16.7275 12.1507 15.6827C11.1058 14.6378 10.5834 13.3547 10.5834 11.8333C10.5834 10.312 11.1058 9.02887 12.1507 7.98401C13.1956 6.93915 14.4787 6.41672 16 6.41672C17.5214 6.41672 18.8045 6.93915 19.8494 7.98401C20.8942 9.02887 21.4167 10.312 21.4167 11.8333C21.4167 13.3547 20.8942 14.6378 19.8494 15.6827C18.8045 16.7275 17.5214 17.25 16 17.25ZM16 31.8333C13.8013 31.8333 11.7388 31.4198 9.81258 30.5929C7.8863 29.766 6.21055 28.6399 4.78533 27.2147C3.36014 25.7895 2.23408 24.1137 1.40716 22.1875C0.58022 20.2612 0.166748 18.1987 0.166748 16C0.166748 13.8013 0.58022 11.7388 1.40716 9.81255C2.23408 7.88627 3.36014 6.21052 4.78533 4.7853C6.21055 3.36011 7.8863 2.23405 9.81258 1.40713C11.7388 0.580189 13.8013 0.166718 16 0.166718C18.1988 0.166718 20.2613 0.580189 22.1875 1.40713C24.1138 2.23405 25.7895 3.36011 27.2148 4.7853C28.6399 6.21052 29.766 7.88627 30.5929 9.81255C31.4199 11.7388 31.8333 13.8013 31.8333 16C31.8333 18.1987 31.4199 20.2612 30.5929 22.1875C29.766 24.1137 28.6399 25.7895 27.2148 27.2147C25.7895 28.6399 24.1138 29.766 22.1875 30.5929C20.2613 31.4198 18.1988 31.8333 16 31.8333Z"
										fill="#0C5850"
									/>
								</svg>
							}
							onRecord={() => handleClick("down", null)}
							position="down"
							title="Input Candidate"
						/>
						<CandidateCard
							active={deciding === "right"}
							candidate={data.option_2_candidate}
							hide={!!deciding && deciding !== "right"}
							loading={recording && deciding === "right"}
							logo="B"
							onRecord={() => handleClick("right", data.option_2_candidate.id)}
							position="right"
							title="Candidate B"
						/>
					</div>
				)}
			</div>
		</MainWrapper>
	);
};

export default LearnToRank;
