import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";

const useCompanyIdParam = (): number => {
	const params = useParams<{id: string}>();
	const [companyParamId, setCompanyParamId] = useState<string | null>(null);

	useEffect(() => {
		if (params.id && (!companyParamId || companyParamId !== params.id)) {
			setCompanyParamId(params.id);
		}
	}, [companyParamId, params.id]);

	return parseInt(<string>companyParamId);
};

export default useCompanyIdParam;
