import type {FC} from "react";
import {useState, useEffect, useRef} from "react";
import classNames from "classnames";
import {useLocation} from "react-router-dom";

import {useAppSelector, useOnClickOutside} from "hooks";
import {projectSelector} from "store/mapx/project-list/projectListSelectors";

import FeatureList from "./FeatureList";
import type {THedDropDown} from "./types";
import DropDownTitle from "./DropDownTitle";
import styles from "./HeaderDropDown.module.scss";

const HeaderDropDown: FC<THedDropDown> = ({
	loading,
	onClick,
	children,
	showItem,
	LeftIcon,
	noBorder,
	pathName,
	RightIcon,
	topBorder,
	groupLeft,
	title = "",
	subTitle = "",
	scrollList,
	groupRight,
	deleteItem,
	groupCenter,
	closeHandler,
	titleChildren,
	className = "",
	featureUserList,
	listClasses = "",
	handleTrashClick,
	rightArrow = false,
	headerClasses = "",
	leftIconClasses = "",
	rightIconClasses = "",
	onHoverTooltip,
}) => {
	const ref = useRef<HTMLDivElement | null>(null);
	const location = useLocation();

	const project = useAppSelector(projectSelector);

	const [showModal, setShowModal] = useState<boolean>(false);

	const dropDownClasses = classNames(styles.wrapper, {[className]: className});

	const showModalHandler = () => {
		setShowModal(!showModal);
	};

	useEffect(() => {
		if (showModal) {
			setShowModal(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location.pathname, project]);

	useOnClickOutside(ref, () => {
		if (showModal) {
			setShowModal(false);
		}
	});

	return (
		<div ref={ref} className={dropDownClasses}>
			<DropDownTitle
				groupCenter={groupCenter}
				groupLeft={groupLeft}
				groupRight={groupRight}
				headerClasses={headerClasses}
				LeftIcon={LeftIcon}
				noBorder={noBorder}
				leftIconClasses={leftIconClasses}
				loading={loading}
				onClick={showModalHandler}
				rightArrow={rightArrow}
				RightIcon={RightIcon}
				rightIconClasses={rightIconClasses}
				title={title}
				subTitle={subTitle}
				titleChildren={titleChildren}
			/>

			{showModal ? (
				<FeatureList
					closeHandler={closeHandler}
					deleteItem={deleteItem}
					featureUserList={featureUserList}
					handleTrashClick={handleTrashClick}
					listClasses={listClasses}
					onClick={onClick}
					onHoverTooltip={onHoverTooltip}
					pathName={pathName}
					project={project}
					scrollList={scrollList}
					showItem={showItem}
					topBorder={topBorder}
				>
					{children}
				</FeatureList>
			) : (
				<></>
			)}
		</div>
	);
};

export default HeaderDropDown;
