import type {FC} from "react";
import {noop} from "lodash";

import type {IconProps} from "./types";

export const LinkedInIcon: FC<IconProps> = ({onClick = noop, className = "", fill = undefined}) => (
	<svg
		width="30"
		height="30"
		viewBox="0 0 30 30"
		fill={fill}
		onClick={onClick}
		className={className}
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M5.4 27H24.6C25.9254 27 27 25.9254 27 24.6V5.4C27 4.0746 25.9254 3 24.6 3H5.4C4.0746 3 3 4.0746 3 5.4V24.6C3 25.9254 4.0746 27 5.4 27Z"
			fill="#0B6CAC"
		/>
		<path d="M6.59961 12H10.1996V19.881V23.4H6.59961V12Z" fill="white" />
		<path
			d="M8.39961 10.2C7.31961 10.2 6.59961 9.52021 6.59961 8.68021C6.59961 7.84021 7.31961 7.20001 8.39961 7.20001C9.47961 7.20001 10.1546 7.84021 10.1996 8.68021C10.1996 9.52021 9.52461 10.2 8.39961 10.2Z"
			fill="white"
		/>
		<path
			d="M23.4 23.4H19.8C19.8 23.4 19.8 17.8458 19.8 17.4C19.8 16.2 19.2 15 17.7 14.9742C16.2132 14.9478 15.6 16.2 15.6 17.4C15.6 17.9454 15.6 23.4 15.6 23.4H12V12H15.6V13.5366C15.6 13.5366 16.758 12 19.0878 12C21.4668 12 23.4 13.6356 23.4 16.9566V23.4Z"
			fill="white"
		/>
	</svg>
);

export default LinkedInIcon;
