import {tickFormatter} from "./utils";
import {TCustomizedAxisTickProps} from "./types";

const CustomizedAxisTick = ({x, y, payload}: TCustomizedAxisTickProps) => (
	<g transform={`translate(${x},${y - 17})`}>
		<title>{payload?.value}</title>
		<text x={0} y={0} dy={16} textAnchor="end" dominantBaseline="middle">
			{tickFormatter(payload.value)}
		</text>
	</g>
);

export default CustomizedAxisTick;
