import {TInitialValue} from "./types";

export const initialValue: TInitialValue = {
	firstName: {error: "", valid: true},
	lastName: {error: "", valid: true},
	email: {error: "", valid: true},
	password: {error: "", valid: false},
	newPassword: {error: "", valid: false},
};

// @TODO: Need to refactoring for change type to real types
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getAllValid = (validationObject: any) => {
	for (const key in validationObject) {
		if (Object.prototype.hasOwnProperty.call(validationObject, key)) {
			if (!validationObject[key]?.valid) {
				return false;
			}
		}
	}

	return true;
};
