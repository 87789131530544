import {useState, useEffect} from "react";

const useScrollDeduct: () => number = () => {
	const [scrollPosition, setScrollPosition] = useState<number>(0);

	const handleScroll = () => {
		const position = window.pageYOffset;
		setScrollPosition(position);
	};

	useEffect(() => {
		window.addEventListener("scroll", handleScroll, {passive: true});

		return () => window.removeEventListener("scroll", handleScroll);
	}, []);

	return scrollPosition;
};

export default useScrollDeduct;
