import React, {FC, Fragment, useCallback, useMemo, useState} from "react";
import {NavLink} from "react-router-dom";
import Highlighter from "react-highlight-words";
import useDebouncedEffect from "use-debounced-effect";

import {CompanyInfoTooltip, LazyLoadImageWrapper} from "components";
import {sanitizeStringText} from "helpers/map";
import {LineIcon, PlusIcon} from "assets/icons";
import {useAppDispatch, useAppSelector} from "hooks";
import useUserBehaviorTrackHook from "hooks/mapx/useUserBehaviorTrackHook";
import {setTrackedEventsData} from "store/mapx/events/eventTrackingActions";
import {tooltipCompaniesSelector} from "store/mapx/company/companySelectors";
import {updateTooltipCompaniesList} from "store/mapx/company/companyAsyncActions";
import {candidateSelectedKeywordLabelsSelector} from "store/mapx/candidate/candidateSelectors";
import {apCandidateSelectedKeywordLabelsSelector} from "store/mapx/additional-profiles/additionalProfilesSelectors";

import type {
	TCandidateExperienceProps,
	TExperience,
	TExperienceCompany,
	TExperiencesType,
} from "./types";
import styles from "./candidateExperience.module.scss";

import type {SICandidatePosition} from "api/candidateApi/types";
import DealExperienceIcon from "assets/icons/DealExperienceIcon";
import DealExperienceToolTip from "components/Tooltip/DealExperienceTooltip";
import {STCompanyEvent} from "api/companyApi/types";
import DealExperienceInfo from "components/Other/DealExperience/dealExperienceInfo";
import classNames from "classnames";

const CandidateExperience: FC<TCandidateExperienceProps> = ({
	searchType,
	position,
	candidateId,
	isRecommended,
	fromQuickView = false,
	displayDealExperience,
	positions,
}) => {
	const dispatch = useAppDispatch();

	const keywordLabels = useAppSelector(candidateSelectedKeywordLabelsSelector);
	const apKeywordLabels = useAppSelector(apCandidateSelectedKeywordLabelsSelector);

	const [companyId, setCompanyId] = useState<string>("");
	const [isExpanded, setIsExpanded] = useState<boolean>(false);
	const [hoveredCompanyId, setHoveredCompanyId] = useState<string | null>(null);
	const [eventRecordedForRole, setEventRecordedForRole] = useState<boolean>(false);

	const {itemEventDetails} = useUserBehaviorTrackHook({
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		position,
		source: searchType,
		isRecommended,
		itemId: candidateId,
		itemType: "candidate",
		defaultEvent: "expanded",
	});

	const tooltipId = `${searchType}-${candidateId}`;
	const tooltipIdDE = `${searchType}-${candidateId}-DE`;
	const tooltipCompanies = useAppSelector(tooltipCompaniesSelector);
	const companyToWatch = companyId ? tooltipCompanies[companyId] : null;

	const isAIResultsType = useMemo(() => {
		return (
			searchType === "Additional Profiles" ||
			searchType === "Free Text Input Search" ||
			searchType === "More Like This" ||
			searchType === "Rejected Profiles"
		);
	}, [searchType]);

	const shouldSearchHighlight = useMemo(() => isAIResultsType, [isAIResultsType]);

	const groupedExperiencesByCompany = useMemo(() => {
		// @TODO need to add type for experiencesDuplicate
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const experiencesDuplicate: any[] = [];
		const companyIds = new Set<string>();

		positions?.forEach((exp: SICandidatePosition) => {
			const {company, ...rest} = exp;

			// Keep events within the company object for company-wise display
			const {events} = company;

			if (companyIds.has(String(company.id))) {
				const index = experiencesDuplicate.findIndex(
					(fnd) => fnd.company.id === company.id,
				);

				// Concatenate new events (rest) with existing events array
				experiencesDuplicate[index].details.push({...rest, events});
			} else {
				companyIds.add(String(company.id));

				// Add events to the details object for role-wise display
				experiencesDuplicate.push({
					company,
					details: [{...rest, events}],
				});
			}
		});

		return experiencesDuplicate;
	}, [positions]);

	// Check if there is at least one description or event in the positions
	const hasDescriptionsOrEvents = useMemo(() => {
		return positions?.some(
			(val: SICandidatePosition) =>
				val.description || (val.company.events && val.company.events.length > 0),
		);
	}, [positions]);

	useDebouncedEffect(
		() => {
			if (companyId && hoveredCompanyId === companyId) {
				dispatch(updateTooltipCompaniesList(companyId));
			}
		},
		1000,
		[companyId, hoveredCompanyId],
	);

	const [eventToDisplay, setEventToDisplay] = useState<STCompanyEvent[]>([]);
	const handleMouseEnter = (company: TExperienceCompany) => {
		setHoveredCompanyId(String(company.id));
		setCompanyId(String(company.id));

		if (company?.events && company?.events?.length > 0) {
			setEventToDisplay(company?.events);
		}
	};

	const handleMouseLeave = () => {
		setEventToDisplay([]);
	};

	const getJobTitleWithHighlighted = useCallback(
		(description: string) => {
			const allowed = shouldSearchHighlight || fromQuickView;
			const additionalOrNot = isAIResultsType ? apKeywordLabels : keywordLabels;

			if (additionalOrNot && allowed) {
				return (
					<Highlighter
						autoEscape={false}
						searchWords={additionalOrNot}
						textToHighlight={description}
						highlightClassName={styles.highlighter}
					/>
				);
			} else {
				return description;
			}
		},
		[shouldSearchHighlight, fromQuickView, isAIResultsType, apKeywordLabels, keywordLabels],
	);

	const getPositionEndDate = (date: Nullable<string>, isCurrent?: boolean | undefined) => {
		if (isCurrent !== undefined) {
			return isCurrent ? "Present" : date ? new Date(date).getFullYear() : "n/a";
		} else {
			return date ? new Date(date).getFullYear() : "Present";
		}
	};

	return (
		<Fragment>
			<div className={styles.workList}>
				<div
					className={classNames(styles.expandContainer, {
						[styles.hidden]: !(fromQuickView && hasDescriptionsOrEvents),
					})}
				>
					<button
						className={styles.expandButton}
						onClick={() => {
							setIsExpanded((prev) => !prev);
							if (!isExpanded) {
								if (!eventRecordedForRole) {
									dispatch(setTrackedEventsData(itemEventDetails));
									setEventRecordedForRole(true);
								}
							}
						}}
					>
						{isExpanded ? <LineIcon /> : <PlusIcon />}
						<span>
							{isExpanded ? "Collapse" : "Expand"} role
							{positions?.length === 1 ? "" : "s"}
						</span>
					</button>
				</div>
				{fromQuickView && !hasDescriptionsOrEvents && (
					<section>
						<br />
					</section>
				)}

				{[...groupedExperiencesByCompany].map((exp: TExperience, idx) => (
					<div key={idx} className={styles.experience}>
						<div className={styles.companyNameContainer}>
							<span className={styles.companyName}>
								<NavLink
									data-tooltip-id={tooltipId}
									data-tooltip-delay-show={1000}
									onMouseOut={handleMouseLeave}
									onBlur={handleMouseLeave}
									onMouseEnter={() => handleMouseEnter(exp?.company)}
									target="_blank"
									rel="noreferrer"
									to={`/company/${exp.company.id}`}
								>
									<div
										className={styles.companyName__skeleton}
										onMouseOut={handleMouseLeave}
										onBlur={handleMouseLeave}
										onMouseEnter={() => handleMouseEnter(exp?.company)}
									/>
									<LazyLoadImageWrapper
										onMouseOut={handleMouseLeave}
										onMouseEnter={() => handleMouseEnter(exp?.company)}
										alt={exp && exp.company.name}
										image={
											exp.company.logo_url !== null
												? exp.company.logo_url
												: "https://storage.googleapis.com/nebula-static/logos100x100/empty_logo.png"
										}
										className={styles.companyLogo}
									/>
									{exp.company.name}
								</NavLink>
							</span>
							{exp.details?.some(
								(detail) => detail.events && detail.events.length > 0,
							) &&
								displayDealExperience &&
								!isExpanded && (
									<div
										onMouseEnter={() => handleMouseEnter(exp?.company)}
										onMouseLeave={handleMouseLeave}
										className={styles.iconContainer}
										data-tooltip-id={tooltipIdDE}
										data-tooltip-delay-show={10}
									>
										<DealExperienceIcon />
									</div>
								)}
						</div>

						<span className={styles.details}>
							{exp.details.map((detail: TExperiencesType, dx) => (
								<div key={`${idx}${dx}${detail.position_id}`}>
									<div className={styles.detail}>
										{detail.start_date ? (
											<span className={styles.years}>
												{`${new Date(
													detail.start_date,
												).getFullYear()} - ${getPositionEndDate(
													detail.end_date,
													detail.is_current,
												)}`}
											</span>
										) : (
											<span className={styles.years}>{`n/a - n/a`}</span>
										)}
										<span className={styles.jobTitle}>
											{getJobTitleWithHighlighted(detail.job_title)}
										</span>
									</div>
									{detail.description !== null && isExpanded && (
										<div className={styles.description}>
											{sanitizeStringText(detail.description)
												.split("<br/>")
												.map((chunk, index) => (
													<div key={index}>
														{index > 0 && (
															<span
																style={{
																	display: "flex",
																	paddingBottom: 5,
																}}
															/>
														)}
														{getJobTitleWithHighlighted(chunk)}
													</div>
												))}
										</div>
									)}
									{isExpanded &&
									detail?.events?.length &&
									detail.events.length > 0 ? (
										<div style={{margin: "15px 0 0 115px"}}>
											<DealExperienceInfo events={detail.events} />
										</div>
									) : (
										""
									)}
								</div>
							))}
						</span>
					</div>
				))}
			</div>

			<CompanyInfoTooltip id={tooltipId} isInCompanyPage company={companyToWatch} />
			<DealExperienceToolTip events={eventToDisplay} id={tooltipIdDE} />
		</Fragment>
	);
};

export default CandidateExperience;
