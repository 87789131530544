import {get} from "lodash";

import type {TCountry, TLocation, TRegion, TGetLocationExpandableDataResult} from "./types";

export const getLocationExpandableData = (
	filteredData?: (TLocation | TCountry | TRegion)[] | null,
) =>
	filteredData?.reduce<TGetLocationExpandableDataResult>((acc, curVal) => {
		const curValRegions = get(curVal, "regions");
		const curValCountries = get(curVal, "countries");

		if (curValCountries || curValRegions) {
			acc[curVal.id] = true;

			Object.assign(acc, getLocationExpandableData(curValCountries || curValRegions));
		}

		return acc;
	}, {});
