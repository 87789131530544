import type {FC} from "react";
import classNames from "classnames";

import {Loader} from "components";

import ButtonWrapper from "./ButtonWrapper";
import type {TButtonProps} from "./types";

import styles from "./Button.module.scss";

const Button: FC<TButtonProps> = ({
	name,
	to = "",
	onClick,
	children,
	disabled,
	LeftIcon,
	RightIcon,
	href = "",
	target = "",
	className = "",
	type = "button",
	loading = false,
	dataTestId = "button-wrapper",
	leftIconClasses = "",
	rightIconClasses = "",
	defaultBtnStyle = false,
	loaderType = "ThreeDots",
	...rest
}) => {
	const buttonClasses = classNames({
		[className]: className,
		[styles.default]: defaultBtnStyle,
		[styles.container]: !defaultBtnStyle,
		[styles.container__disabled]: disabled || loading,
	});

	return (
		<ButtonWrapper
			to={to}
			name={name}
			href={href}
			type={type}
			target={target}
			onClick={onClick}
			loading={loading}
			disabled={disabled}
			dataTestId={dataTestId}
			className={buttonClasses}
			defaultBtnStyle={defaultBtnStyle}
			{...rest}
		>
			{loading && (
				<div className={styles.container__loader} data-testid="loading-spinner">
					<Loader
						width={100}
						height={25}
						color="#0c5850"
						customStyle={{}}
						type={loaderType}
						displayAtCenterOfPage
					/>
				</div>
			)}
			{!!LeftIcon && (
				<div className={leftIconClasses}>
					<LeftIcon />
				</div>
			)}
			{children}

			{!!RightIcon && (
				<RightIcon className={rightIconClasses} height={24} width={24} stroke={"#808080"} />
			)}
		</ButtonWrapper>
	);
};

export default Button;
