import {candidateCompanyDateRangeBand} from "api/candidateApi/candidateCompanyDateRangeBand";
import {TDEDateRange} from "mapx-components/Filters/DealExperienceSearchFilter/types";
import ranges from "api/candidateApi/candidateCompanyEventSizeBand.json";

// Helper function to compare only the date part (ignoring the time component)
const compareOnlyDate = (date1: Date, date2: Date) => {
	return (
		date1.getFullYear() === date2.getFullYear() &&
		date1.getMonth() === date2.getMonth() &&
		date1.getDate() === date2.getDate()
	);
};

export const getDateRangeLabel = (dates: TDEDateRange[]): string => {
	return dates
		?.map(({from_date, to_date}) => {
			const from = from_date ? new Date(from_date) : null;
			const to = to_date ? new Date(to_date) : null;

			const matchingRange = candidateCompanyDateRangeBand.find((range) => {
				const minDate = range.min_value ? new Date(range.min_value) : null;
				const maxDate = range.max_value ? new Date(range.max_value) : null;

				// Handle empty min_value and max_value correctly
				const isFromValid =
					from &&
					(minDate === null || from >= minDate || compareOnlyDate(from, minDate)) &&
					(maxDate === null || from <= maxDate || compareOnlyDate(from, maxDate));
				const isToValid =
					to &&
					(minDate === null || to >= minDate || compareOnlyDate(to, minDate)) &&
					(maxDate === null || to <= maxDate || compareOnlyDate(to, maxDate));

				// If either minDate or maxDate is empty (null), adjust comparison
				const isValidForEmptyMin =
					minDate === null
						? true
						: from && (from >= minDate || compareOnlyDate(from, minDate));
				const isValidForEmptyMax =
					maxDate === null ? true : to && (to <= maxDate || compareOnlyDate(to, maxDate));

				return (isFromValid || isValidForEmptyMin) && (isToValid || isValidForEmptyMax);
			});

			return matchingRange ? matchingRange.name : "";
		})
		.filter((label) => label) // Filter out any empty results
		.join(", ");
};

export const getEventSizeLabel = (sizes: string[]): string => {
	const matchingRanges = ranges.filter((range) => sizes.includes(range.id));

	// Map the matching ranges to their names and join them into a string
	return matchingRanges.map((range) => range.name).join(", ");
};
