import React, {useState} from "react";

import styles from "./registerCover.module.scss";
import {Logo} from "assets/icons";
import {HeaderShape1, HeaderShape2} from "components/AuthView/AuthCoverIcons/HeaderShapes";
import FooterShape from "components/AuthView/AuthCoverIcons/FooterShape";
import VirginMediaLogo from "components/AuthView/AuthCoverIcons/VirginMediaLogo";
import TripAdvisorLogo from "components/AuthView/AuthCoverIcons/TripAdvisorLogo";
import TEConnectivityLogo from "components/AuthView/AuthCoverIcons/TEConnectivityLogo";
import FastIcon from "components/AuthView/AuthCoverIcons/FastIcon";
import AccurateIcon from "components/AuthView/AuthCoverIcons/AccurateIcon";
import SmartIcon from "components/AuthView/AuthCoverIcons/SmartIcon";

const RegisterCover = () => {
	const [info] = useState([
		{
			icon: <FastIcon />,
			title: "Fast",
			description: "Find relevant people in 45 seconds",
		},

		{
			icon: <AccurateIcon />,
			title: "Accurate",
			description: "Uncover executives other tools miss",
		},

		{
			icon: <SmartIcon />,
			title: "Smart",
			description: "State of the art AI, trained by executive search experts",
		},
	]);

	const [clients] = useState([
		{name: "Virgin Media", logo: <VirginMediaLogo />},
		{name: "TripAdvisor", logo: <TripAdvisorLogo />},
		{name: "TE Connectivity", logo: <TEConnectivityLogo />},
	]);

	return (
		<div className={styles.cover}>
			<div className={styles.topHeader}>
				<div className={styles.logo}>
					<Logo />
				</div>

				<div className={styles.headerShapes}>
					<HeaderShape1 />
					<HeaderShape2 />
				</div>
			</div>

			<div className={styles.contentArea}>
				<div className={styles.title}>
					Super <span>Human</span> Search
				</div>

				<div className={styles.description}>
					Super smart AI that builds lists of executive candidates and sales prospects in
					minutes.
				</div>

				<div className={styles.cards}>
					{info.map((item, key) => (
						<div className={styles.card} key={key}>
							<div className={styles.cardIcon}>{item.icon}</div>

							<div className={styles.texts}>
								<div className={styles.iconTitle}>{item.title}</div>
								<div className={styles.iconDescription}>{item.description}</div>
							</div>
						</div>
					))}
				</div>
			</div>

			<div className={styles.footer}>
				<div className={styles.footerShape}>
					<FooterShape />
				</div>

				<div className={styles.clients}>
					{clients.map((client, key) => (
						<div className={styles.client} key={key}>
							{client.logo}
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

export default RegisterCover;
