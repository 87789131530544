import {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {useSelector} from "react-redux";
import {formatISO} from "date-fns";
import {companiesPaginationInfoSelector} from "store/mapx/company/companySelectors";
import {candidatesPaginationInfoSelector} from "store/mapx/candidate/candidateSelectors";

/*
 ** IF INTERACTED true MEANS SEEN AND SPENT TIME ON IT
 ** IF INTERACTED false MEANS SEEN BUT QUICKLY MOVED TO OTHER ITEM
 */

const useUserBehaviorTrackHook = ({
	itemId,
	itemType = "company",
	source = "project",
	position = undefined,
	isRecommended = false,
	trackForSecond = 3,
	defaultEvent = "appear",
}) => {
	let trackTimer;

	const {company_filters, candidate_filters} = useSelector((state) => state.filter);

	const companiesPaginationInfo = useSelector(companiesPaginationInfoSelector);

	const candidatesPaginationInfo = useSelector(candidatesPaginationInfoSelector);

	const [event, setEvent] = useState(defaultEvent);

	const [filterOptions, setFilterOptions] = useState(null);

	const [interacted, setInteracted] = useState(false);

	const [targetListId, setTargetListId] = useState(null);

	const currentRef = useRef(true);

	const trackStart = () => {
		if (!interacted && currentRef.current) {
			setEvent("appear");
			trackTimer = setTimeout(() => {
				setInteracted(true);
			}, trackForSecond * 1000);
		}
	};

	const trackComplete = () => {
		if (trackTimer) {
			clearTimeout(trackTimer);
		}
	};

	const behaviorType = useMemo(() => {
		const object = isRecommended ? "Recommended" : "Non-Recommended";

		let subject = `${object} ${itemType}`;

		switch (event) {
			case "hover":
				return `${subject} hovered`;
			case "click":
				return `${subject} clicked`;
			case "add":
				return `${subject} added to target list`;
			case "remove":
				return `${subject} removed from target list`;
			case "expanded":
				return `${subject} card expanded in the list`;
			case "details_viewed":
				return `${subject} profile details viewed`;
			default:
				return `${subject} appeared on the screen`;
		}
	}, [itemType, isRecommended, event]);

	const getPurifiedFilterData = useCallback(() => {
		if (filterOptions === null) return null;

		let filterDetails = {...filterOptions};
		let updatedFilter = null;

		Object.keys(filterDetails).forEach(function (key) {
			const data = filterDetails[key];

			if (Array.isArray(data) && data.length) {
				if (updatedFilter === null) updatedFilter = {};

				updatedFilter[key] = data;
			}
		});

		return updatedFilter;
	}, [filterOptions]);

	const totalResults = useMemo(() => {
		if (itemType === "company") {
			return companiesPaginationInfo ? companiesPaginationInfo?.count : 0;
		} else {
			return candidatesPaginationInfo ? candidatesPaginationInfo?.count : 0;
		}
	}, [candidatesPaginationInfo, companiesPaginationInfo, itemType]);

	const itemEventDetails = useMemo(() => {
		const time = formatISO(Date.now());

		let details = {
			id: itemId,
			filter: getPurifiedFilterData(),
			recommended: isRecommended,
			target_list_id: targetListId,
			total_results: totalResults,
			enhanced_mode: isRecommended ? "on" : "off",
			time,
			source,
			position,
		};

		return {
			type: behaviorType,
			details,
			event,
		};
	}, [
		itemId,
		getPurifiedFilterData,
		isRecommended,
		targetListId,
		totalResults,
		source,
		position,
		behaviorType,
		event,
	]);

	useEffect(() => {
		if (interacted) {
			setEvent("hover");
		}

		return () => {
			clearTimeout(trackTimer);
			currentRef.current = false;
		};
	}, [trackTimer, interacted]);

	useEffect(() => {
		if (itemType === "company") {
			setFilterOptions({...company_filters});
		} else {
			setFilterOptions({...candidate_filters});
		}

		return () => {
			currentRef.current = false;
		};
	}, [itemType, company_filters, candidate_filters]);

	return {
		trackStart,
		trackComplete,
		setEvent,
		setTargetListId,
		itemEventDetails,
	};
};

export default useUserBehaviorTrackHook;
