export const data = [
	{
		id: 1,
		name: "Military & Protective",
		value: 30,
	},
	{
		id: 2,
		name: "Technology",
		value: 25,
	},
	{
		id: 3,
		name: "Armed Forces",
		value: 35,
	},
	{
		id: 4,
		name: "IT Services and IT Consulting",
		value: 10,
	},
];

export const dataDuplicate = [
	{
		id: 1,
		name: "Military & Protective",
		value: 10,
	},
	{
		id: 2,
		name: "Technology",
		value: 20,
	},
	{
		id: 3,
		name: "Armed Forces",
		value: 30,
	},
	{
		id: 4,
		name: "IT Services and IT Consulting",
		value: 10,
	},
	{
		id: 4,
		name: "IT Services and IT Consulting",
		value: 30,
	},
	{
		id: 2,
		name: "Technology",
		value: 20,
	},
];
