import React, {forwardRef} from "react";
import classNames from "classnames";
import Loader from "components/Plugins/Loader";
import colors from "styles/themes.module.scss";
import ExpandableArrow from "../../ExpandableArrow";
import css from "./checkboxList.module.scss";
import {
	AccordionChildrenContentProps,
	AccordionContentProps,
	AccordionHeaderProps,
	AccordionProps,
	CheckboxListProps,
} from "./types";

interface CheckboxListComponent
	extends React.ForwardRefExoticComponent<
		CheckboxListProps & React.RefAttributes<HTMLDivElement>
	> {
	Accordion: React.FC<AccordionProps>;
	AccordionHeader: React.FC<AccordionHeaderProps>;
	AccordionContent: React.FC<AccordionContentProps>;
	AccordionChildrenContent: React.FC<AccordionChildrenContentProps>;
}

const CheckboxList: CheckboxListComponent = forwardRef<HTMLDivElement, CheckboxListProps>(
	(props, ref) => {
		const {children, displayOverlay = false, overlayText, ...rest} = props;

		const LoaderComponent = (
			<Loader
				height={80}
				width={80}
				type="ThreeDots"
				customStyle={{display: "flex", alignItems: "center", margin: "0 5px"}}
				color={colors.mainThemeColor}
			/>
		);

		return (
			<div className={css.checkboxListWrapperContainer}>
				<div className={css.checkboxList} role="checkbox" {...rest} ref={ref}>
					{children}
				</div>

				{displayOverlay && (
					<div className={css.overlay}>
						{LoaderComponent}
						<span className={css.overlayText} data-overlay-text={overlayText}>
							{overlayText} <span className={css.loading} />
						</span>
					</div>
				)}
			</div>
		);
	},
) as CheckboxListComponent;

CheckboxList.displayName = "CheckboxList"; // Adding display name to the main component

const Accordion: React.FC<AccordionProps> = ({children, ...rest}) => (
	<div className={css.accordion} {...rest}>
		{children}
	</div>
);

Accordion.displayName = "Accordion"; // Adding display name to Accordion component

const AccordionHeader: React.FC<AccordionHeaderProps> = ({children}) => (
	<div className={css.header}>{children}</div>
);

AccordionHeader.displayName = "AccordionHeader"; // Adding display name to AccordionHeader component

const AccordionContent: React.FC<AccordionContentProps> = ({children, expanded}) => (
	<div className={classNames(css.content, css.singleChildren, {[css.expanded]: expanded})}>
		{children}
	</div>
);

AccordionContent.displayName = "AccordionContent"; // Adding display name to AccordionContent component

const AccordionChildrenContent: React.FC<AccordionChildrenContentProps> = ({
	expanded,
	hasChildren,
	handleExpandClick,
	children,
	treeIndex,
	childrenExpanded,
}) => (
	<div
		className={classNames(css.content, {
			[css.expanded]: expanded,
			[css.content1stChild]: treeIndex === 1,
			[css.content2ndChild]: treeIndex === 2,
			[css.content3rdChild]: treeIndex === 3,
			[css.hasChildren]: hasChildren,
		})}
		data-index={treeIndex}
	>
		<div className={css.childrenCheckboxList}>
			{Array(treeIndex)
				.fill(treeIndex)
				.map((item, index) => (
					<div
						className={classNames(css.line, {
							[css.content1stLine]: index + 1 === 1,
							[css.content2ndLine]: index + 1 === 2,
							[css.content3rdLine]: index + 1 === 3,
							[css.hasChildren]: hasChildren,
						})}
						key={index}
						data-key={index}
					/>
				))}
			<div className={classNames(css.hrLine, {[css.hasChildren]: hasChildren})} />

			{hasChildren && (
				<ExpandableArrow onClick={handleExpandClick} rotated={!childrenExpanded} />
			)}

			{children}
		</div>
	</div>
);

AccordionChildrenContent.displayName = "AccordionChildrenContent"; // Adding display name to AccordionChildrenContent component

CheckboxList.Accordion = Accordion;
CheckboxList.AccordionHeader = AccordionHeader;
CheckboxList.AccordionContent = AccordionContent;
CheckboxList.AccordionChildrenContent = AccordionChildrenContent;

export default CheckboxList;
