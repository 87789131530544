import {useCallback, useState} from "react";
import type {BaseSyntheticEvent} from "react";

import {CreatePerson} from "assets/icons";
import {useAppDispatch, useAppSelector} from "hooks";
import {ActionAltButton, CreateUserModal} from "mapx-components";
import {createInvitationSuccessSelector} from "store/mapx/organisation/organisationSelectors";
import {createInvitation} from "store/mapx/organisation/organisationAsyncFunctions";
import {userOrganisationSelector} from "store/mapx/user/userSelectors";

import styles from "./UserList.module.scss";
import UserTable from "./UserTable";

const UserList = () => {
	const dispatch = useAppDispatch();

	const createUserSuccess = useAppSelector(createInvitationSuccessSelector);
	const organisation = useAppSelector(userOrganisationSelector);

	const [organizationEmail, setOrganizationEmail] = useState("");
	const [displayCreateUserModal, setDisplayCreateUserModal] = useState(false);

	const handleInviteUserClick = useCallback(() => {
		setDisplayCreateUserModal(true);
	}, []);

	const createUserSubmit = useCallback(
		(e: BaseSyntheticEvent) => {
			e.preventDefault();
			dispatch(createInvitation(organizationEmail, organisation.id));
		},
		[dispatch, organisation, organizationEmail],
	);

	const changeEmailHandler = useCallback((e: BaseSyntheticEvent) => {
		setOrganizationEmail(e.target.value);
	}, []);

	return (
		<div className={styles.wrapper}>
			<div className={styles.head}>
				<h3 className={styles.title}>User List</h3>
				<ActionAltButton
					customClass={styles.saveButton}
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					iconComponent={<CreatePerson />}
					handleClick={handleInviteUserClick}
					title="Invite a User"
				/>
			</div>
			<UserTable displayCreateUserModal={handleInviteUserClick} />
			<CreateUserModal
				loading={createUserSuccess}
				onSubmit={createUserSubmit}
				onChange={changeEmailHandler}
				organizationEmail={organizationEmail}
				isOpen={displayCreateUserModal}
				organizationName={`${organisation?.name}`}
				setIsOpen={setDisplayCreateUserModal}
			/>
		</div>
	);
};

export default UserList;
