import type {FC} from "react";
import {useMemo, useRef, useState} from "react";
import classNames from "classnames";

import {ArrowDown} from "assets/icons";
import {Button, Loader} from "components";
import colors from "styles/themes.module.scss";

import type {TDropDownTitleProps} from "./types";
import styles from "./HeaderDropDown.module.scss";
import {useOutsideClick} from "hooks";

const DropDownTitle: FC<TDropDownTitleProps> = ({
	title,
	subTitle,
	onClick,
	loading,
	LeftIcon,
	RightIcon,
	groupLeft,
	noBorder,
	rightArrow,
	groupRight,
	groupCenter,
	titleChildren,
	headerClasses = "",
	leftIconClasses = "",
	rightIconClasses = "",
}) => {
	const [toggleFeatureListOpened, setToggleFeatureListOpened] = useState<boolean>(false);

	const ref = useRef<HTMLDivElement>(null);

	const headClasses = classNames(styles.wrapper_head, {
		[headerClasses]: headerClasses,
		[styles.wrapper_head__no_border]: noBorder,
		[styles.wrapper_head__group_left]: groupLeft,
		[styles.wrapper_head__group_right]: groupRight,
		[styles.wrapper_head__group_center]: groupCenter,
		[styles.wrapper_head__opened]: toggleFeatureListOpened,
	});

	const leftIconClassName = classNames(styles.wrapper_head__left_icon, {
		[leftIconClasses]: leftIconClasses,
	});

	const rightIconClassNames = classNames({
		[rightIconClasses]: rightIconClasses,
		[styles.wrapper_head__right_icon]: rightArrow,
		[styles.wrapper_head__right_icon__rotated]: toggleFeatureListOpened,
	});

	const RenderRightIcon = useMemo(() => {
		return rightArrow ? ArrowDown : RightIcon;
	}, [RightIcon, rightArrow]);

	const handleOnClick = () => {
		if (onClick) {
			setToggleFeatureListOpened(!toggleFeatureListOpened);

			onClick();
		}
	};

	useOutsideClick(ref, () => setToggleFeatureListOpened(false));

	return (
		<div ref={ref}>
			<Button
				defaultBtnStyle
				onClick={handleOnClick}
				LeftIcon={LeftIcon}
				className={headClasses}
				RightIcon={RenderRightIcon}
				leftIconClasses={leftIconClassName}
				rightIconClasses={rightIconClassNames}
			>
				{loading ? (
					<Loader
						width={10}
						height={10}
						type="ThreeDots"
						displayAtCenterOfPage={false}
						color={colors.loaderDotColor}
					/>
				) : (
					<div>
						<div className={styles.wrapper_head__text}>
							<h3
								className={classNames(styles.wrapper_head__text__title, {
									[styles.noProject]: title === "No Project Selected",
								})}
							>
								{title}
							</h3>
							{titleChildren}
						</div>
						{subTitle && <p className={styles.wrapper_head__subTitle}>{subTitle}</p>}
					</div>
				)}
			</Button>
		</div>
	);
};

export default DropDownTitle;
