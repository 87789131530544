import React, {useMemo, useState} from "react";
import styles from "mapx-pages/Project/project.module.scss";
import {CompanyCard, CompaniesSkeletonLoader} from "mapx-components";
import {useAppSelector} from "hooks";
import {
	targetCompaniesPaginationSelector,
	targetListCompaniesSelector,
	targetListCompanyInProgressSelector,
} from "store/mapx/target-list/targetListSelectors";
import {STCompany} from "api/companyApi/types";
import useInfiniteScroll from "react-infinite-scroll-hook";

const SavedCompanies = () => {
	const savedCompanies: STCompany[] = useAppSelector(targetListCompaniesSelector);

	const companiesFetchInProgress = useAppSelector(targetListCompanyInProgressSelector);

	// const moreCompaniesFetchInProgress = useAppSelector(moreSavedCompaniesInProgress);

	const targetCompaniesPagination = useAppSelector(targetCompaniesPaginationSelector);

	const [page, setPage] = useState<number>(1);

	const totalDisplayNumber = useMemo(() => page * 20, [page]);

	const displayedCompanies = useMemo(() => {
		return savedCompanies.slice(0, totalDisplayNumber);
	}, [savedCompanies, totalDisplayNumber]);

	const [sentryRef] = useInfiniteScroll({
		loading: false,
		hasNextPage: targetCompaniesPagination?.count > totalDisplayNumber,
		onLoadMore: async () => {
			setPage((prev) => prev + 1);
		},
		disabled: targetCompaniesPagination === null,
		delayInMs: 300,
	});

	return (
		<div className={styles.listedItems}>
			{displayedCompanies.map((company, index) => {
				return <CompanyCard key={index} company={company} type="project" />;
			})}

			{companiesFetchInProgress && <CompaniesSkeletonLoader count={5} />}

			{!companiesFetchInProgress && targetCompaniesPagination?.count > totalDisplayNumber && (
				<div
					ref={sentryRef}
					key="saved_companies"
					style={{width: "100%", height: "20px"}}
				/>
			)}

			{!displayedCompanies?.length && !companiesFetchInProgress && (
				<span>
					No companies to display here. You can import companies from market maps.
				</span>
			)}
		</div>
	);
};

export default SavedCompanies;
