import type {FC} from "react";

import type {IconProps} from "./types";

const PlusSquare: FC<IconProps> = (props) => (
	<svg
		fill="none"
		height={24}
		role="img"
		viewBox="0 0 24 24"
		width={24}
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<mask
			id="plus-square_svg__a"
			style={{
				maskType: "alpha",
			}}
			maskUnits="userSpaceOnUse"
			x={0}
			y={0}
			width={24}
			height={24}
		>
			<path fill="#D9D9D9" d="M0 0h24v24H0z" />
		</mask>
		<g mask="url(#plus-square_svg__a)">
			<path
				d="M11 17h2v-4h4v-2h-4V7h-2v4H7v2h4v4Zm-6 4c-.55 0-1.021-.196-1.413-.587A1.928 1.928 0 0 1 3 19V5c0-.55.196-1.021.587-1.413A1.928 1.928 0 0 1 5 3h14c.55 0 1.021.196 1.413.587.391.392.587.863.587 1.413v14c0 .55-.196 1.021-.587 1.413A1.928 1.928 0 0 1 19 21H5Zm0-2h14V5H5v14Z"
				fill="#0C5850"
			/>
		</g>
	</svg>
);

export default PlusSquare;
