import type {FC} from "react";

import type {TChartItemProps} from "./types";

import RoundChart from "./RoundChart";
import RoundChartSkeleton from "./RoundChartSkeleton";
import styles from "./RoundCustomCharts.module.scss";

const ChartItem: FC<TChartItemProps> = ({isLoad, roundPercent, title, description}) =>
	!isLoad ? (
		<div className={styles.container__charts__wrapper} data-testid="chart-item">
			<RoundChart percent={roundPercent} />
			<p className={styles.container__charts__wrapper__title}>{title}</p>
			<p className={styles.container__charts__wrapper__description}>{description}</p>
		</div>
	) : (
		<div className={styles.container__charts_is_load} data-testid="loading-spinner">
			<RoundChartSkeleton />
		</div>
	);

export default ChartItem;
