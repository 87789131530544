import React from "react";

const CreateWithAIIcon = () => {
	return (
		<svg
			width="64"
			height="64"
			viewBox="0 0 64 64"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect width="64" height="64" rx="8" fill="#EDF2F2" />
			<path
				d="M42.17 46.5489H21.83C19.3837 46.5489 17.3934 44.5657 17.3934 42.1279V21.8723C17.3934 19.4345 19.3836 17.4513 21.83 17.4513H42.17C44.6163 17.4513 46.6065 19.4345 46.6065 21.8723V42.1279C46.6064 44.5657 44.6163 46.5489 42.17 46.5489ZM21.83 18.83C20.1439 18.83 18.7721 20.1947 18.7721 21.8724V42.128C18.7721 43.8055 20.1439 45.1704 21.83 45.1704H42.17C43.8561 45.1704 45.2278 43.8056 45.2278 42.128V21.8723C45.2278 20.1947 43.8561 18.8299 42.17 18.8299L21.83 18.83Z"
				fill="#0C5850"
			/>
			<path
				d="M23.8361 18.4116C23.4553 18.4116 23.1467 18.1028 23.1467 17.7223V10.0324C23.1467 9.65183 23.4553 9.34302 23.8361 9.34302C24.2169 9.34302 24.5254 9.65183 24.5254 10.0324V17.7223C24.5254 18.1029 24.2169 18.4116 23.8361 18.4116Z"
				fill="#0C5850"
			/>
			<path
				d="M29.2788 18.4116C28.898 18.4116 28.5895 18.1028 28.5895 17.7223V10.0324C28.5895 9.65183 28.898 9.34302 29.2788 9.34302C29.6596 9.34302 29.9682 9.65183 29.9682 10.0324V17.7223C29.9682 18.1029 29.6595 18.4116 29.2788 18.4116Z"
				fill="#0C5850"
			/>
			<path
				d="M34.7212 18.4116C34.3404 18.4116 34.0319 18.1028 34.0319 17.7223V10.0324C34.0319 9.65183 34.3404 9.34302 34.7212 9.34302C35.102 9.34302 35.4105 9.65183 35.4105 10.0324V17.7223C35.4105 18.1029 35.102 18.4116 34.7212 18.4116Z"
				fill="#0C5850"
			/>
			<path
				d="M40.164 18.4116C39.7831 18.4116 39.4746 18.1028 39.4746 17.7223V10.0324C39.4746 9.65183 39.7831 9.34302 40.164 9.34302C40.5448 9.34302 40.8533 9.65183 40.8533 10.0324V17.7223C40.8533 18.1029 40.5447 18.4116 40.164 18.4116Z"
				fill="#0C5850"
			/>
			<path
				d="M23.8361 54.6571C23.4553 54.6571 23.1467 54.3483 23.1467 53.9678V46.2778C23.1467 45.8973 23.4553 45.5885 23.8361 45.5885C24.2169 45.5885 24.5254 45.8973 24.5254 46.2778V53.9678C24.5254 54.3484 24.2169 54.6571 23.8361 54.6571Z"
				fill="#0C5850"
			/>
			<path
				d="M29.2788 54.6571C28.898 54.6571 28.5895 54.3483 28.5895 53.9678V46.2778C28.5895 45.8973 28.898 45.5885 29.2788 45.5885C29.6596 45.5885 29.9682 45.8973 29.9682 46.2778V53.9678C29.9682 54.3484 29.6595 54.6571 29.2788 54.6571Z"
				fill="#0C5850"
			/>
			<path
				d="M34.7212 54.6571C34.3404 54.6571 34.0319 54.3483 34.0319 53.9678V46.2778C34.0319 45.8973 34.3404 45.5885 34.7212 45.5885C35.102 45.5885 35.4105 45.8973 35.4105 46.2778V53.9678C35.4105 54.3484 35.102 54.6571 34.7212 54.6571Z"
				fill="#0C5850"
			/>
			<path
				d="M40.164 54.6571C39.7831 54.6571 39.4746 54.3483 39.4746 53.9678V46.2778C39.4746 45.8973 39.7831 45.5885 40.164 45.5885C40.5448 45.5885 40.8533 45.8973 40.8533 46.2778V53.9678C40.8533 54.3484 40.5447 54.6571 40.164 54.6571Z"
				fill="#0C5850"
			/>
			<path
				d="M54.0589 24.5591H46.337C45.9562 24.5591 45.6477 24.2503 45.6477 23.8698C45.6477 23.4892 45.9562 23.1804 46.337 23.1804H54.0589C54.4397 23.1804 54.7482 23.4892 54.7482 23.8698C54.7482 24.2503 54.4397 24.5591 54.0589 24.5591Z"
				fill="#0C5850"
			/>
			<path
				d="M54.0589 29.9792H46.337C45.9562 29.9792 45.6477 29.6703 45.6477 29.2898C45.6477 28.9093 45.9562 28.6005 46.337 28.6005H54.0589C54.4397 28.6005 54.7482 28.9093 54.7482 29.2898C54.7482 29.6703 54.4397 29.9792 54.0589 29.9792Z"
				fill="#0C5850"
			/>
			<path
				d="M54.0589 35.3996H46.337C45.9562 35.3996 45.6477 35.0908 45.6477 34.7102C45.6477 34.3297 45.9562 34.0209 46.337 34.0209H54.0589C54.4397 34.0209 54.7482 34.3297 54.7482 34.7102C54.7482 35.0908 54.4397 35.3996 54.0589 35.3996Z"
				fill="#0C5850"
			/>
			<path
				d="M54.0589 40.8197H46.337C45.9562 40.8197 45.6477 40.5109 45.6477 40.1304C45.6477 39.7499 45.9562 39.441 46.337 39.441H54.0589C54.4397 39.441 54.7482 39.7499 54.7482 40.1304C54.7482 40.5109 54.4397 40.8197 54.0589 40.8197Z"
				fill="#0C5850"
			/>
			<path
				d="M17.663 24.5591H9.94117C9.56036 24.5591 9.25183 24.2503 9.25183 23.8698C9.25183 23.4892 9.56036 23.1804 9.94117 23.1804H17.663C18.0438 23.1804 18.3523 23.4892 18.3523 23.8698C18.3523 24.2503 18.0438 24.5591 17.663 24.5591Z"
				fill="#0C5850"
			/>
			<path
				d="M17.663 29.9792H9.94117C9.56036 29.9792 9.25183 29.6703 9.25183 29.2898C9.25183 28.9093 9.56036 28.6005 9.94117 28.6005H17.663C18.0438 28.6005 18.3523 28.9093 18.3523 29.2898C18.3523 29.6703 18.0438 29.9792 17.663 29.9792Z"
				fill="#0C5850"
			/>
			<path
				d="M17.663 35.3996H9.94117C9.56036 35.3996 9.25183 35.0908 9.25183 34.7102C9.25183 34.3297 9.56036 34.0209 9.94117 34.0209H17.663C18.0438 34.0209 18.3523 34.3297 18.3523 34.7102C18.3523 35.0908 18.0438 35.3996 17.663 35.3996Z"
				fill="#0C5850"
			/>
			<path
				d="M17.663 40.8197H9.94117C9.56036 40.8197 9.25183 40.5109 9.25183 40.1304C9.25183 39.7499 9.56036 39.441 9.94117 39.441H17.663C18.0438 39.441 18.3523 39.7499 18.3523 40.1304C18.3523 40.5109 18.0438 40.8197 17.663 40.8197Z"
				fill="#0C5850"
			/>
			<path
				d="M34.0711 36.6405C33.8063 36.6405 33.554 36.4875 33.44 36.2298L30.3365 29.2063L27.2328 36.2298C27.0788 36.5777 26.6729 36.7352 26.3235 36.5817C25.9752 36.4277 25.8177 36.0207 25.9716 35.6724L29.366 27.9914C29.5359 27.6077 29.9167 27.3595 30.3363 27.3595C30.7562 27.3595 31.137 27.6077 31.3068 27.9914L34.7013 35.6724C34.8552 36.0207 34.6977 36.4277 34.3494 36.5817C34.2587 36.6216 34.164 36.6405 34.0711 36.6405Z"
				fill="#0C5850"
			/>
			<path
				d="M32.8232 33.8028H27.8495C27.6164 33.8028 27.3991 33.6853 27.2719 33.4896C27.1447 33.2944 27.1247 33.048 27.219 32.8348L29.3076 28.1086C29.4873 27.702 29.8908 27.4391 30.3361 27.4386H30.3362C30.7812 27.4386 31.1851 27.7016 31.3651 28.1086L33.4537 32.8348C33.5479 33.048 33.528 33.2944 33.4007 33.4896C33.2736 33.6852 33.0564 33.8028 32.8232 33.8028ZM28.9078 32.4241H31.765L30.3365 29.1915L28.9078 32.4241Z"
				fill="#0C5850"
			/>
			<path
				d="M37.3978 36.6405C37.017 36.6405 36.7085 36.3317 36.7085 35.9512V31.2879C36.7085 30.9073 37.017 30.5985 37.3978 30.5985C37.7787 30.5985 38.0872 30.9073 38.0872 31.2879V35.9512C38.0871 36.3318 37.7786 36.6405 37.3978 36.6405Z"
				fill="#0C5850"
			/>
			<path
				d="M37.3978 29.0829C37.017 29.0829 36.7085 28.7741 36.7085 28.3936V28.1463C36.7085 27.7657 37.017 27.4569 37.3978 27.4569C37.7787 27.4569 38.0872 27.7657 38.0872 28.1463V28.3936C38.0871 28.7742 37.7786 29.0829 37.3978 29.0829Z"
				fill="#0C5850"
			/>
		</svg>
	);
};

export default CreateWithAIIcon;
