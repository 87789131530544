import {type FC, useEffect, useState} from "react";

import {useAppDispatch} from "hooks";
import {LogoAnimation} from "components";
import {Logo} from "assets/icons";
import {OnboardingTooltip} from "mapx-components";
// import LayoutHeader from "mapx-pages/Home/LayoutHeader";
import ProjectsEmpty from "mapx-pages/Projects/ProjectsEmpty";
import {EOnboardingTooltipSteps} from "mapx-components/OnboardingTooltip/types";
import {setOnboardingStep, setOnboardingVisibility} from "store/mapx/project/projectActions";

import type {TStepBoardingType} from "../types";

import OnboardingFakeSearch from "./OnboardingFakeSearch";
import styles from "./OnboardingHome.module.scss";

const OnboardingHome: FC<TStepBoardingType> = ({step}) => {
	const dispatch = useAppDispatch();

	const [isShowAnimation, setIsShowAnimation] = useState<boolean>(false);

	useEffect(() => {
		setIsShowAnimation(true);
		dispatch(setOnboardingVisibility(true));
		dispatch(setOnboardingStep(EOnboardingTooltipSteps.Search));

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const timer = setTimeout(() => {
			setIsShowAnimation(false);
		}, 3500);

		return () => clearTimeout(timer);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return isShowAnimation ? (
		<LogoAnimation />
	) : (
		<div className={styles.container}>
			{/*<LayoutHeader />*/}

			<div className={styles.container__search}>
				<Logo className={styles.container__search__logo} />

				<div className={styles.container__search__wrapper}>
					<OnboardingFakeSearch step={step} />

					<OnboardingTooltip
						className={styles.container__search__wrapper__boarding}
						step={step === EOnboardingTooltipSteps.Search ? step : null}
					/>
				</div>

				<ProjectsEmpty className={styles.container__search__empty} />
			</div>
		</div>
	);
};

export default OnboardingHome;
