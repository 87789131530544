import type {FC} from "react";

import {MapAnimationIcon, XAnimationIcon} from "assets/icons";

import styles from "./LogoAnimation.module.scss";

const LogoAnimation: FC = () => (
	<div className={styles.animation}>
		<div className={styles.animation__bg} />
		<div className={styles.animation__block} />

		<MapAnimationIcon className={styles.animation__map} />
		{/*<SavanahAnimationIcon className={styles.animation__logo_savannah} />*/}
		<XAnimationIcon className={styles.animation__logo_x} />

		<div className={styles.animation__savannah_block} />
	</div>
);

export default LogoAnimation;
