import {type FC, useEffect, useMemo, useState} from "react";
import {useParams} from "react-router-dom";
import classNames from "classnames";
import {groupBy} from "lodash";
import axios from "axios";
import config from "config";

import {EMPTY_COMPANY_LOGO} from "mapx-constants";
import {MainWrapper} from "mapx-components";
import type {STCompany} from "api/companyApi/types";

import styles from "./MarketMap.module.scss";

const MarketMap: FC = () => {
	const {id, token} = useParams<{id: string; token: string}>();

	const [companies, setCompanies] = useState<STCompany[]>([]);

	const groupedCompanies = useMemo(
		() => groupBy(companies, (company) => company.industry?.name || "Other"),
		[companies],
	);

	const groupedCompaniesArray = Object.entries(groupedCompanies)
		.map(([industryName, companies]) => ({industryName, companies}))
		.sort((a, b) => b.companies.length - a.companies.length);

	useEffect(() => {
		if (id && token) {
			const client = axios.create({
				baseURL: config.apiUrl,
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json",
					Authorization: token,
				},
			});

			client.get(`/market-maps/${id}`).then((response) => {
				setCompanies(response.data.companies);
			});
		}
	}, [id, token]);

	// Set body background as transparent in order to provide a transparent background for the png.
	useEffect(() => {
		const originalBackground = document.body.style.background;

		document.body.style.background = "transparent";

		return () => {
			document.body.style.background = originalBackground;
		};
	}, []);

	return (
		<MainWrapper>
			<div id="market-map-png" className={styles.grids}>
				{groupedCompaniesArray.map(({industryName, companies}) => (
					<div
						key={industryName}
						className={classNames(styles.industry, {
							[styles.singleGrid]: groupedCompaniesArray.length === 1,
						})}
					>
						<div className={styles.industryName}>{industryName}</div>
						<div className={styles.companies}>
							{companies.map((company) => (
								<div key={company.id.toString()} className={styles.company}>
									<img
										loading="lazy"
										alt={company.name}
										className={styles.companyImage}
										src={company?.logo_url || EMPTY_COMPANY_LOGO}
										onError={(e) => {
											(e.target as HTMLImageElement).src = EMPTY_COMPANY_LOGO;
										}}
									/>
									<div className={styles.companyName} title={company.name}>
										{company.name}
									</div>
								</div>
							))}
						</div>
					</div>
				))}
			</div>
		</MainWrapper>
	);
};

export default MarketMap;
