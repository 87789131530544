import type {FC} from "react";

import type {IconProps} from "./types";

const FeatureBlockArrow: FC<IconProps> = ({className = ""}) => (
	<svg
		width="11"
		height="9"
		fill="none"
		viewBox="0 0 11 9"
		className={className}
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fill="white"
			fillRule="evenodd"
			clipRule="evenodd"
			d="M7.26058 1.05246C6.50565 -0.350819 4.49293 -0.350819 3.73799 1.05246L-0.000732422 8.00201H10.9993L7.26058 1.05246Z"
		/>
	</svg>
);

export default FeatureBlockArrow;
