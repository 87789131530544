import React, {useCallback} from "react";
import {useDispatch} from "react-redux";
import {
	allAPCountriesCountSelector,
	apCandidateCountryIdsByPositionSelector,
	apCandidateLocationFilterConfigSelector,
	apCandidateLocationPositionSelector,
	apCandidateRegionIdsByPositionSelector,
	selectedAPAnyCountriesSelector,
	selectedAPCurrentCountriesSelector,
	selectedAPPreviousCountriesSelector,
} from "store/mapx/additional-profiles/additionalProfilesSelectors";
import LocationFilterHelper from "mapx-components/Filters/LocationSearchFilter/LocationFilterHelper";
import {
	filterPositionOptionsSelector,
	flattenCountriesSelector,
} from "store/mapx/filter/filterSelectors";
import {
	setAPLocationFilterConfig,
	setAPMultipleFilters,
} from "store/mapx/additional-profiles/additionalProfilesActions";
import {useAppSelector} from "hooks";
import {TabSelect} from "components";
import {
	moveAPLocationsForCandidate,
	setAPLocationForCandidate,
} from "store/mapx/filter/locationFilterAsyncActions";
import LocationSearchFilter from "mapx-components/Filters/LocationSearchFilter";

function LocationFilterForAP() {
	const dispatch = useDispatch();

	const position = useAppSelector(apCandidateLocationPositionSelector);

	const filterPositionOptions = useAppSelector(filterPositionOptionsSelector);

	const locationFilterConfig = useAppSelector(apCandidateLocationFilterConfigSelector);

	const selectedCountriesByPostion = useAppSelector((state) =>
		apCandidateCountryIdsByPositionSelector(position)(state),
	);

	const selectedCountryRegions = useAppSelector((state) =>
		apCandidateRegionIdsByPositionSelector(position)(state),
	);

	const currentCountries = useAppSelector(selectedAPCurrentCountriesSelector);
	const previousCountries = useAppSelector(selectedAPPreviousCountriesSelector);
	const anyCountries = useAppSelector(selectedAPAnyCountriesSelector);

	const flattenCountries = useAppSelector(flattenCountriesSelector);

	const count = useAppSelector(allAPCountriesCountSelector);

	const {handleCheckChangeForSingleItem, handleCheckChangeForBulkItems, handleRegionCheckChange} =
		LocationFilterHelper({
			selectedCountries: selectedCountriesByPostion,
			selectedCountryRegions,
			flattenCountries,
		});

	const handlePositionChange = useCallback(
		(updatedPositionValue) => {
			dispatch(
				setAPLocationFilterConfig({
					...locationFilterConfig,
					active_position: updatedPositionValue,
				}),
			);
		},
		[dispatch, locationFilterConfig],
	);

	const handleChange = useCallback(
		(id) => {
			const {nextCountryIds, toBeSetRegionIds} = handleCheckChangeForSingleItem(id);

			dispatch(setAPLocationForCandidate({position, nextCountryIds, toBeSetRegionIds}));
		},
		[dispatch, position, handleCheckChangeForSingleItem],
	);

	const handleResetClick = useCallback(() => {
		dispatch(
			setAPMultipleFilters({
				countries_or: [],
				previous_countries_or: [],
				current_countries_or: [],
				regions_or: [],
				previous_regions_or: [],
				current_regions_or: [],
			}),
		);
	}, [dispatch]);

	const handleBulkUpdate = useCallback(
		(nextCountryIds) => {
			const toBeSetRegionIds = handleCheckChangeForBulkItems(nextCountryIds);

			dispatch(setAPLocationForCandidate({position, nextCountryIds, toBeSetRegionIds}));
		},
		[dispatch, handleCheckChangeForBulkItems, position],
	);

	const handleRegionChange = useCallback(
		(regionId, country) => {
			const {toBeSetRegionIds, nextCountryIds} = handleRegionCheckChange(regionId, country);

			dispatch(setAPLocationForCandidate({position, nextCountryIds, toBeSetRegionIds}));
		},
		[dispatch, handleRegionCheckChange, position],
	);

	const handleTagClick = useCallback(
		(id, fromPosition, selectedCountryIds, region_id) => {
			if (selectedCountryIds) {
				let nextCountryIds = [...selectedCountryIds].filter((i) => i !== id);
				const {toBeSetRegionIds} = handleCheckChangeForSingleItem(id);

				let nextRegionIds = toBeSetRegionIds;

				if (region_id) {
					nextRegionIds = [...selectedCountryRegions].filter((i) => i !== region_id);
				}

				nextCountryIds = nextCountryIds.filter((countryId) => {
					const country = flattenCountries.find((c) => c.id === countryId);

					if (country) {
						const countryRegionIds = country.regions.map((region) => region.id);
						const allRegionsIncluded = countryRegionIds.every((regionId) =>
							nextRegionIds.includes(regionId),
						);

						if (allRegionsIncluded) {
							nextRegionIds = nextRegionIds.filter(
								(regionId) => !countryRegionIds.includes(regionId),
							);

							return true;
						}
					}

					return !country?.regions.some((region) => nextRegionIds.includes(region.id));
				});

				dispatch(
					setAPLocationForCandidate({
						position: fromPosition,
						nextCountryIds,
						toBeSetRegionIds: nextRegionIds,
					}),
				);
			}
		},
		[dispatch, handleCheckChangeForSingleItem, flattenCountries, selectedCountryRegions],
	);

	const handleTagDrop = useCallback(
		(country_id, region_id, from, to) => {
			dispatch(
				moveAPLocationsForCandidate({
					from: from,
					to: to,
					country_id: country_id,
					region_id: region_id,
				}),
			);
		},
		[dispatch],
	);

	return (
		<div>
			<TabSelect.LabelContainer label="Position">
				<TabSelect
					selected={position}
					onTabChange={handlePositionChange}
					options={filterPositionOptions}
				/>
			</TabSelect.LabelContainer>

			<LocationSearchFilter
				filterType={"ap_candidates"}
				handleResetClick={handleResetClick}
				handleOnChange={handleChange}
				selectedLocation={selectedCountriesByPostion}
				selectedCountryRegions={selectedCountryRegions}
				handleBulkUpdate={handleBulkUpdate}
				handleRegionChange={handleRegionChange}
				handleMoveLocationTag={handleTagDrop}
				handleTagClick={handleTagClick}
				selectedCountries={{
					current: currentCountries,
					previous: previousCountries,
					any: anyCountries,
				}}
				allSelectedCountriesCount={count}
			/>
		</div>
	);
}

export default LocationFilterForAP;
