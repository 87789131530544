export enum EOnboardingTooltipSteps {
	Search = 0,
	First = 1,
	Second = 2,
	Third = 3,
	Fourth = 4,
	Fifth = 5,
	Sixth = 6,
}

export type TFilterFakeData = {
	id: number;
	name: string;
};
export type TTooltipCloseProps = {
	text: string;
	closeHandler: () => void;
};

export type TTooltipNavigationProps = {
	step: number | null;
	closeHandler?: TTooltipCloseProps["closeHandler"];
};

export type TOnboardingTooltipProps = {
	className?: string;
	step: TTooltipNavigationProps["step"];
};
