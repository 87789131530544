import {InfoIcon} from "assets/icons";
import LockIcon from "assets/icons/LockIcon";
import classNames from "classnames";
import Loader from "components/Plugins/Loader";
import {useAppDispatch, useAppSelector} from "hooks";
import css from "mapx-components/Filters/DiversitySelectionFilter/diversityFilter.module.scss";
import Slider from "rc-slider";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useSelector} from "react-redux";
import {Tooltip as ReactTooltip} from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import {
	prJobTitleSuggestionLoadingSelector,
	prSearchWidthSelector,
} from "store/mapx/additional-profiles/additionalProfilesSelectors";
import {
	organisationAdminSelector,
	organisationHasPaidPlanSelector,
} from "store/mapx/organisation/organisationSelectors";
import {contactAdminModal, pricingTableModal} from "store/mapx/payment/paymentActions";
import {
	userLicenseTypeNameSelector,
	userOrganisationRoleSelector,
} from "store/mapx/user/userSelectors";
import colors from "styles/themes.module.scss";
import styles from "./styles.module.scss";
import {setSearchWidthForPRFilters} from "store/mapx/additional-profiles/profileRequestConfigurator";

function APAdvanceFilter() {
	const dispatch = useAppDispatch();

	const searchWidthImportance = useSelector(prSearchWidthSelector);

	const isPRSaving = useSelector(prJobTitleSuggestionLoadingSelector);

	const [searchWidth, setSearchSearchWidth] = useState<number>(0);

	const userLicenseTypeName = useAppSelector(userLicenseTypeNameSelector);

	const isStarterUser = userLicenseTypeName === "Starter";

	const organisationRole = useSelector(userOrganisationRoleSelector);

	const admin = useSelector(organisationAdminSelector);

	const organisationHasPaidPlan = useSelector(organisationHasPaidPlanSelector);

	const handleUpgrade = useCallback(() => {
		if (!organisationHasPaidPlan && (admin == null || organisationRole === "Admin")) {
			dispatch(pricingTableModal(true));
		} else {
			dispatch(contactAdminModal(true));
		}
	}, [admin, dispatch, organisationHasPaidPlan, organisationRole]);

	const sliderLabelByValue = (value: number) => {
		if (value === 0) {
			return "Narrow";
		} else {
			return "Wide";
		}
	};

	const onFilterChanged = (value: number) => {
		const title = sliderLabelByValue(value);

		// If value is not changed handled
		if (title !== searchWidthImportance) {
			dispatch(setSearchWidthForPRFilters(title));
		}
	};

	const getSliderValueByTextValue = (textValue: string) => {
		if (textValue === "Wide") {
			return 100;
		} else {
			return 0;
		}
	};

	useEffect(() => {
		if (searchWidthImportance) {
			const value = getSliderValueByTextValue(searchWidthImportance);
			setSearchSearchWidth(value);
		} else {
			setSearchSearchWidth(0);
		}
	}, [searchWidth, searchWidthImportance]);

	useEffect(() => {
		setSearchSearchWidth(0);
	}, [isStarterUser]);

	const LoaderComponent = (
		<Loader
			height={80}
			width={80}
			type="ThreeDots"
			customStyle={{display: "flex", alignItems: "center", margin: "0 5px"}}
			color={colors.mainThemeColor}
		/>
	);

	const displayOverlay = useMemo(() => {
		return isPRSaving;
	}, [isPRSaving]);

	const overlayText = useMemo(() => "Adjusting search parameters", []);

	const PremiumFeatureTooltip = (
		<ReactTooltip
			clickable
			place="right"
			id="premium"
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			type="light"
			textColor="#404040"
			backgroundColor="#fff"
			arrowColor="#fff"
			className={styles.tooltip}
		>
			<div style={{fontWeight: 500, marginBottom: 8}}>Premium Features</div>

			<div>
				<div style={{marginBottom: 10}}>
					Your plan does not include premium features.{" "}
					<span className={styles.green} onClick={handleUpgrade}>
						Upgrade
					</span>{" "}
					now to access them.
				</div>
			</div>
		</ReactTooltip>
	);

	const AIMatchingTooltip = (
		<ReactTooltip
			place="right"
			id="aiMatching"
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			type="light"
			textColor="#404040"
			backgroundColor="#fff"
			arrowColor="#fff"
			className={styles.tooltip}
		>
			<div style={{fontWeight: 500, marginBottom: 8}}>AI Matching</div>

			<div>
				<div style={{marginBottom: 10}}>
					Choosing <b>Exact Match</b> runs faster and will only return people at the same
					seniority and specialisms as your input profile who are currently at the
					companies you have selected.
				</div>

				<div>
					Choosing <b>Close Match</b> takes longer but will return people one seniority
					level higher and lower as well as people from similar companies.
				</div>
			</div>
		</ReactTooltip>
	);

	return (
		<div className={classNames(css.diversityContainer, styles.filterContainer)}>
			<a className={classNames(styles.title, {[styles.locked]: isStarterUser})}>
				AI Matching
				{isStarterUser ? (
					<div className={styles.lockIconWrapper}>
						<div data-tip="true" data-tooltip-id="premium">
							<LockIcon />
						</div>

						{PremiumFeatureTooltip}
					</div>
				) : (
					<>
						<div
							data-tip="true"
							data-tooltip-id="aiMatching"
							style={{cursor: "pointer"}}
						>
							<InfoIcon />
						</div>

						{AIMatchingTooltip}
					</>
				)}
			</a>

			<Slider
				dots
				key={"aiMatching"}
				className={classNames(css.slide, css.slideContainer)}
				step={null}
				min={0}
				max={100}
				marks={{0: "Exact Match", 100: "Close Match"}}
				value={searchWidth}
				onChange={(value) => {
					if (typeof value === "number") {
						setSearchSearchWidth(value);
						onFilterChanged(value);
					}
				}}
				disabled={isPRSaving || isStarterUser}
			/>

			{displayOverlay && (
				<div className={styles.overlay}>
					{LoaderComponent}
					<span className={styles.overlayText} data-overlay-text={overlayText}>
						{overlayText} <span className={styles.loading} />
					</span>
				</div>
			)}
		</div>
	);
}

export default APAdvanceFilter;
