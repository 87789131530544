import type {FC} from "react";
import {useState} from "react";

import {Loader} from "components";
import {useAppDispatch} from "hooks";
import {logoutUser} from "store/mapx/user/userAsyncAction";
import styles from "containers/Profile/index.module.scss";

import type {TLogoutButton} from "./types";

const LogoutButton: FC<TLogoutButton> = ({
	onLoggingOut = null,
	exitingSessions = false,
	backgroundColor = "#0c5850",
}) => {
	const [loggingOut, setLoggingOut] = useState(false);
	const dispatch = useAppDispatch();

	const onLogout = async () => {
		if (onLoggingOut !== null) {
			onLoggingOut(true);
		}

		setLoggingOut(true);
		await dispatch(logoutUser());
		setLoggingOut(false);

		if (onLoggingOut !== null) {
			onLoggingOut(false);
		}
	};

	const InlineLoaderComponent = (
		<Loader
			width={40}
			height={40}
			type="ThreeDots"
			color="#0c5850"
			displayAtCenterOfPage={false}
		/>
	);

	return (
		<button
			type="button"
			onClick={onLogout}
			className={styles.submitButton}
			style={{background: backgroundColor}}
			disabled={exitingSessions || loggingOut}
		>
			Logout{" "}
			{!!loggingOut && (
				<>
					<span>&nbsp;&nbsp;</span>
					{InlineLoaderComponent}
				</>
			)}
		</button>
	);
};

export default LogoutButton;
