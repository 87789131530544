import {forwardRef} from "react";
import classNames from "classnames";

import type {FC} from "react";

import type {TStandardTooltip} from "./types";

import css from "./standardTooltip.module.scss";

const StandardTooltip: FC<TStandardTooltip> = forwardRef<HTMLDivElement, TStandardTooltip>(
	function StandardTooltip({children, arrowPosition, showArrow = false, ...rest}, ref) {
		return (
			<div>
				<div className={css.tooltip} {...rest}>
					{children}
				</div>
				{showArrow && (
					<div
						ref={ref}
						className={classNames(css.arrow, {
							[css[arrowPosition.placement]]: arrowPosition.placement,
						})}
						style={{
							left: `${arrowPosition.x}px`,
							top: `${arrowPosition.y}px`,
						}}
					/>
				)}
			</div>
		);
	},
);

export default StandardTooltip;
