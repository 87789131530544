import type {ChangeEvent} from "react";
import {useCallback, useEffect, useMemo, useState} from "react";
import {useSelector} from "react-redux";

import colors from "styles/themes.module.scss";
import {GeneralButton, SearchInput} from "mapx-components";
import {ModalContent, ModalHeader, ModalSeparator} from "components";
import {useAppDispatch, useLockBodyScroll, useModalHook} from "hooks";
import {
	addFromMarketMapModalPropsSelector,
	addFromMarketMapModalSelector,
	marketMapsModalSelector,
} from "store/mapx/market-map/marketMapSelectors";
import {addFromMarketMapModal} from "store/mapx/market-map/marketMapActions";
import {addCompaniesFromMarketMapToTargetList} from "store/mapx/target-list/targetListAsyncActions";
import {addingFromMarketMapInProgressSelector} from "store/mapx/target-list/targetListSelectors";
import type {STMarketMap} from "api/marketMapApi/types";

import styles from "./addFromMarketMapModal.module.scss";
import List from "./list";

const AddFromMarketMapModal = () => {
	const dispatch = useAppDispatch();

	const modalDisplay = useSelector(addFromMarketMapModalSelector);
	const marketMaps: STMarketMap[] = useSelector(marketMapsModalSelector);
	const submitting = useSelector(addingFromMarketMapInProgressSelector);
	const {targetListID} = useSelector(addFromMarketMapModalPropsSelector);

	const [search, setSearch] = useState("");
	const [selected, setSelected] = useState<number | null>(null);

	const {modalIsOpen, customStyles, Modal} = useModalHook(
		{content: {width: "99%", borderRadius: "8px", maxWidth: "720px"}},
		modalDisplay,
		colors.mainThemeColor,
	);

	const selectedMarketMap = useMemo(() => {
		return marketMaps.find((map) => map.id === selected);
	}, [marketMaps, selected]);

	const handleModalHide = useCallback(() => {
		dispatch(addFromMarketMapModal(false, {}));
	}, [dispatch]);

	const handleOnChange = useCallback(
		(e: ChangeEvent<HTMLInputElement>) => {
			setSearch(e.target.value);
		},
		[setSearch],
	);

	const handleSubmit = useCallback(async () => {
		if (selectedMarketMap) {
			await dispatch(
				addCompaniesFromMarketMapToTargetList(selectedMarketMap.id, targetListID),
			);
		}
	}, [dispatch, selectedMarketMap, targetListID]);

	useEffect(() => {
		if (!modalDisplay) {
			setSearch("");
		}
	}, [modalDisplay]);

	useLockBodyScroll(modalIsOpen);

	return (
		<Modal
			title="Add from Target List"
			style={customStyles}
			isOpen={modalIsOpen}
			contentLabel="Modal"
			onRequestClose={handleModalHide}
		>
			<ModalHeader title="Add from Target List" onClose={handleModalHide} />
			<ModalSeparator />
			<ModalContent padding="16px" className={styles.content}>
				{/* @TODO: Convert SearchInput into TS */}
				{/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
				{/* @ts-ignore */}
				<SearchInput
					autoComplete="off"
					customClass={styles.search}
					onChange={handleOnChange}
					placeholder="Search a Target List"
					type="text"
					value={search}
					margin="0"
				/>
				<List selected={selected} setSelected={setSelected} search={search} />
				<GeneralButton
					customClass={styles.button}
					disabled={!selectedMarketMap?.companies?.length}
					loadingSave={submitting}
					title="Add from Target List"
					type="button"
					onClick={handleSubmit}
				/>
			</ModalContent>
		</Modal>
	);
};

export default AddFromMarketMapModal;
