import {useHistory} from "react-router-dom";
import type {FC} from "react";

import type {TForgetPassword} from "./types";

import {forgotPassForm} from "mapx-constants";
import {AuthView} from "components";
import {useOnAuth} from "hooks";

const ForgotPassword: FC<TForgetPassword> = () => {
	const history = useHistory();

	const {loading, error, message, onSubmit} = useOnAuth({
		history,
		redirectUrl: "/login",
		type: "forgot-password",
	});

	return (
		<AuthView
			error={error}
			form={forgotPassForm}
			loading={loading}
			submitText="Send"
			onSubmit={onSubmit}
			success={message}
			title="Forgot Password?"
			to="/login"
			description="We'll send an email with instructions to reset your password."
		/>
	);
};

export default ForgotPassword;
