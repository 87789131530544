import UpgradeStep from "mapx-pages/Project/Insights/UserPreloadInsightSkeleton/UpgradeStep";

import styles from "./upgradeNow.module.scss";

const UpgradeNowCard = () => {
	return (
		<div className={styles.promo}>
			<div className={styles.mapxLogo}>
				<svg
					width="121"
					height="42"
					viewBox="0 0 121 42"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<g clipPath="url(#clip0_799_23624)">
						<path
							d="M77.5494 39.7239L89.8501 24.7168L92.3092 26.4826L84.2575 36.4123H88.2833L94.3221 29.349L100.584 36.4123H112.885L90.2912 8.8242H94.9863L120.485 39.7189H98.5609L94.312 35.0867L90.2861 39.7189H77.5342L77.5494 39.7239ZM99.9198 30.8947H104.615L81.8034 3.53168H94.5554L100.371 11.0353L107.084 3.53168H110.887L102.161 13.6815L104.62 16.1077L118.264 0H105.289L100.366 5.51763L96.5632 0H74.8672L99.9198 30.8947Z"
							fill="#0C5850"
						/>
						<path
							d="M0.5 4.85232C0.5 4.5922 0.677462 4.41711 0.941121 4.41711H4.88078C5.28134 4.41711 5.49937 4.54718 5.63627 4.85232L13.9212 23.161H14.0987L22.2467 4.85232C22.3786 4.54718 22.6017 4.41711 22.9972 4.41711H26.8963C27.1599 4.41711 27.3374 4.5922 27.3374 4.85232V33.6911C27.3374 33.9512 27.1599 34.1263 26.8963 34.1263H23.0428C22.7791 34.1263 22.6017 33.9512 22.6017 33.6911V15.5174H22.4242L16.0457 29.4991C15.8682 29.8942 15.6046 30.0693 15.204 30.0693H12.6334C12.2328 30.0693 11.9691 29.8942 11.7917 29.4991L5.41824 15.5174H5.24078V33.6961C5.24078 33.9562 5.06332 34.1313 4.79966 34.1313H0.941121C0.677462 34.1313 0.5 33.9562 0.5 33.6961V4.85232Z"
							fill="black"
						/>
						<path
							d="M45.3528 34.1313C45.0892 34.1313 44.9117 33.9562 44.9117 33.6961V32.1654H44.8661C43.847 33.6061 41.8543 34.6116 38.8425 34.6116C34.9434 34.6116 31.668 32.6006 31.668 28.1885C31.668 23.7764 34.9434 21.5003 40.2166 21.5003H44.5568C44.7343 21.5003 44.8205 21.4153 44.8205 21.2402V20.2347C44.8205 17.8336 43.6695 16.738 40.0797 16.738C37.6865 16.738 36.2262 17.3933 35.2071 18.1787C34.984 18.3538 34.7203 18.3088 34.5885 18.0937L32.9508 15.2073C32.8189 14.9872 32.8646 14.7721 33.037 14.637C34.766 13.4115 37.1135 12.5811 40.6121 12.5811C47.1224 12.5811 49.5156 14.7671 49.5156 19.9246V33.6911C49.5156 33.9512 49.3381 34.1263 49.0745 34.1263H45.3528V34.1313ZM44.8205 27.053V25.4372C44.8205 25.2621 44.7343 25.1771 44.5568 25.1771H41.0126C37.8234 25.1771 36.4087 26.0525 36.4087 28.0184C36.4087 29.7642 37.6915 30.6397 40.0848 30.6397C43.1422 30.6397 44.8255 29.4591 44.8255 27.058L44.8205 27.053Z"
							fill="black"
						/>
						<path
							d="M55.113 41.9999C54.8493 41.9999 54.6719 41.8249 54.6719 41.5647V13.5064C54.6719 13.2463 54.8493 13.0712 55.113 13.0712H59.1896C59.4532 13.0712 59.6307 13.2463 59.6307 13.5064V15.0821H59.6763C60.6955 13.6414 62.465 12.5909 65.345 12.5909C68.9753 12.5909 71.6322 14.2968 72.7426 17.6584C73.275 19.2741 73.4981 20.7598 73.4981 23.6012C73.4981 26.4425 73.275 27.9282 72.7426 29.544C71.6373 32.9106 68.9804 34.6114 65.345 34.6114C62.465 34.6114 60.6955 33.5609 59.6763 32.1202H59.6307V41.5597C59.6307 41.8199 59.4532 41.9949 59.1896 41.9949H55.113V41.9999ZM68.0018 27.5331C68.3111 26.5726 68.4429 25.5221 68.4429 23.6012C68.4429 21.6803 68.3111 20.6298 68.0018 19.6693C67.4238 17.9235 66.0548 16.958 64.0622 16.958C62.0695 16.958 60.6498 17.9185 60.0769 19.6693C59.7676 20.6298 59.6357 21.6803 59.6357 23.6012C59.6357 25.5221 59.7676 26.5726 60.0769 27.5331C60.6549 29.2789 62.0239 30.2444 64.0622 30.2444C66.1004 30.2444 67.4289 29.2839 68.0018 27.5331Z"
							fill="black"
						/>
					</g>
					<defs>
						<clipPath id="clip0_799_23624">
							<rect width="120" height="42" fill="white" transform="translate(0.5)" />
						</clipPath>
					</defs>
				</svg>
			</div>

			<div className={styles.upgradeMessage}>
				<div>Upgrade Now to</div>
				<div>Unlock More Profiles</div>
				<div>
					<UpgradeStep className={styles.upgradeNowButton} hideText />
				</div>
			</div>
		</div>
	);
};

export default UpgradeNowCard;
