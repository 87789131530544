import React from "react";

const SpreadSheetIcon = () => {
	return (
		<svg
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			data-testid="spreadsheet-icon"
		>
			<g clipPath="url(#clip0_943_1043)">
				<path
					d="M19.375 3.12548H11.25V1.25048C11.25 1.06423 11.1675 0.887976 11.0238 0.769226C10.8813 0.650476 10.69 0.599226 10.51 0.636726L0.51 2.51173C0.21375 2.56673 0 2.82423 0 3.12548V16.8755C0 17.1755 0.21375 17.4342 0.51 17.4892L10.51 19.3642C10.5475 19.3717 10.5863 19.3755 10.625 19.3755C10.77 19.3755 10.9113 19.3255 11.0238 19.2317C11.1675 19.113 11.25 18.9355 11.25 18.7505V16.8755H19.375C19.72 16.8755 20 16.5955 20 16.2505V3.75048C20 3.40548 19.72 3.12548 19.375 3.12548ZM8.595 12.0892C8.8225 12.348 8.79625 12.743 8.53625 12.9705C8.4175 13.0742 8.27125 13.1255 8.125 13.1255C7.95125 13.1255 7.77875 13.053 7.655 12.9117L5.8375 10.8355L4.24375 12.8855C4.12 13.043 3.935 13.1255 3.75 13.1255C3.61625 13.1255 3.48125 13.083 3.36625 12.9942C3.09375 12.7817 3.045 12.3892 3.25625 12.1167L4.99875 9.87673L3.28 7.91173C3.0525 7.65298 3.07875 7.25798 3.33875 7.03048C3.5975 6.80298 3.99125 6.82798 4.22125 7.08923L5.78125 8.87173L7.6325 6.49173C7.845 6.22048 8.2375 6.17048 8.51 6.38298C8.7825 6.59423 8.83125 6.98673 8.61875 7.26048L6.61875 9.83048L8.595 12.0892ZM18.75 15.6255H11.25V14.3755H13.125C13.47 14.3755 13.75 14.0955 13.75 13.7505C13.75 13.4055 13.47 13.1255 13.125 13.1255H11.25V11.8755H13.125C13.47 11.8755 13.75 11.5955 13.75 11.2505C13.75 10.9055 13.47 10.6255 13.125 10.6255H11.25V9.37548H13.125C13.47 9.37548 13.75 9.09548 13.75 8.75048C13.75 8.40548 13.47 8.12548 13.125 8.12548H11.25V6.87548H13.125C13.47 6.87548 13.75 6.59548 13.75 6.25048C13.75 5.90548 13.47 5.62548 13.125 5.62548H11.25V4.37548H18.75V15.6255Z"
					fill="#EDF2F2"
				/>
				<path
					d="M16.875 5.62549H15.625C15.28 5.62549 15 5.90549 15 6.25049C15 6.59549 15.28 6.87549 15.625 6.87549H16.875C17.22 6.87549 17.5 6.59549 17.5 6.25049C17.5 5.90549 17.22 5.62549 16.875 5.62549Z"
					fill="#EDF2F2"
				/>
				<path
					d="M16.875 8.12549H15.625C15.28 8.12549 15 8.40549 15 8.75049C15 9.09549 15.28 9.37549 15.625 9.37549H16.875C17.22 9.37549 17.5 9.09549 17.5 8.75049C17.5 8.40549 17.22 8.12549 16.875 8.12549Z"
					fill="#EDF2F2"
				/>
				<path
					d="M16.875 10.6255H15.625C15.28 10.6255 15 10.9055 15 11.2505C15 11.5955 15.28 11.8755 15.625 11.8755H16.875C17.22 11.8755 17.5 11.5955 17.5 11.2505C17.5 10.9055 17.22 10.6255 16.875 10.6255Z"
					fill="#EDF2F2"
				/>
				<path
					d="M16.875 13.1255H15.625C15.28 13.1255 15 13.4055 15 13.7505C15 14.0955 15.28 14.3755 15.625 14.3755H16.875C17.22 14.3755 17.5 14.0955 17.5 13.7505C17.5 13.4055 17.22 13.1255 16.875 13.1255Z"
					fill="#EDF2F2"
				/>
			</g>
			<defs>
				<clipPath id="clip0_943_1043">
					<rect width="20" height="20" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};

export default SpreadSheetIcon;
