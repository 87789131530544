import type {FC} from "react";
import {noop} from "lodash";
import {useHistory} from "react-router-dom";

import {HeaderDropDown} from "components";
import {useAppDispatch, useAppSelector} from "hooks";
import {logoutUser} from "store/mapx/user/userAsyncAction";
import {
	hasAccessToExtendedPagesSelector,
	isSuperUserSelector,
	userLicenseTypeNameSelector,
	userOrganisationTypeSelector,
	userSelector,
} from "store/mapx/user/userSelectors";
import type {TFeatureProp} from "components/HeaderDropDown/types";
import {
	ChangePassword,
	Feedback,
	FlagWithoutOutline,
	LearnToRankIcon,
	Login,
	OrganisationsIcon,
	ReportBug,
	ThreeDotsVerticalIcon,
	UserSettingsIcon,
} from "assets/icons";

import type {TModDisplay} from "../types";
import styles from "../header.module.scss";
import UserImageOrAvatar from "assets/icons/userImageOrAvatar";

const HeadUser: FC<TModDisplay> = ({setModalDisplay}) => {
	const dispatch = useAppDispatch();

	const history = useHistory();

	const userInfo = useAppSelector(userSelector);
	const userLicenseTypeName = useAppSelector(userLicenseTypeNameSelector);
	const isStarterUser: boolean = userLicenseTypeName === "Starter";

	const organisationType = useAppSelector(userOrganisationTypeSelector);
	const isSuperUser = useAppSelector(isSuperUserSelector);
	const hasAccessToExtendedPages = useAppSelector(hasAccessToExtendedPagesSelector);

	const notClient: boolean = organisationType !== "Client";
	const superUserOrNotClient = isSuperUser && notClient;

	const onLogout = async () => {
		await dispatch(logoutUser());
	};

	const handleOnClickOnFlaggedProfiles = () => {
		history.push("/flagged-candidates");
	};

	const featureUserList: TFeatureProp[] = [
		{
			id: "flagged-profiles",
			icon: FlagWithoutOutline,
			text: "Flagged Profiles",
			btnClassName: isStarterUser ? styles.starter : "",
			onClick: isStarterUser ? noop : handleOnClickOnFlaggedProfiles,
		},

		hasAccessToExtendedPages &&
			superUserOrNotClient && {
				id: "learn-to-rank-link",
				icon: LearnToRankIcon,
				text: "Learn to Rank",
				onClick: () => history.push("/learn-to-rank"),
			},
		{id: "hrLine"},
		{
			id: "report-bug",
			target: "blank",
			icon: ReportBug,
			text: "Report Bug",
			href: "https://docs.google.com/forms/d/e/1FAIpQLSf3vZtCDrC0gIWCGbUA9T5Aw984f2LiDfCNipakWXBcc4muzg/viewform",
		},

		{
			id: "feedback",
			icon: Feedback,
			target: "blank",
			text: "Give Feedback",
			href: "https://docs.google.com/forms/d/e/1FAIpQLSeO7muSSfMAaE3N6L_OrKB2-wgqTehs0H4EBtect1NEtDrS0g/viewform?usp=send_form",
		},
		{id: "hrLine"},
		{
			id: "organisation-link",
			icon:
				hasAccessToExtendedPages && superUserOrNotClient
					? OrganisationsIcon
					: UserSettingsIcon,
			text:
				hasAccessToExtendedPages && superUserOrNotClient ? "Organizations" : "Org Settings",
			onClick: () =>
				hasAccessToExtendedPages && superUserOrNotClient
					? history.push("/organisations")
					: history.push("/organisation-settings"),
		},
		{
			id: "change-password",
			icon: ChangePassword,
			text: "Change Password",
			onClick: () => setModalDisplay?.([true, null]),
		},
		{id: "logout", icon: Login, text: "Logout", onClick: onLogout},
	];

	return (
		<HeaderDropDown
			rightArrow={false}
			RightIcon={ThreeDotsVerticalIcon}
			deleteItem
			topBorder={false}
			noBorder={true}
			LeftIcon={UserImageOrAvatar}
			listClasses={styles.container}
			featureUserList={featureUserList}
			title={`${userInfo.firstName} ${userInfo.lastName}`}
			subTitle={`${userInfo.email}`}
			headerClasses={styles.user_name}
			leftIconClasses={styles.user_icon}
			rightIconClasses={styles.user_three_dots}
		/>
	);
};

export default HeadUser;
