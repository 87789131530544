import type {IconProps} from "./types";

const StoreIcon = (props: IconProps) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={20}
		height={20}
		fill="none"
		viewBox="0 0 20 20"
		{...props}
	>
		<mask
			id="a"
			width={20}
			height={20}
			x={0}
			y={0}
			maskUnits="userSpaceOnUse"
			style={{
				maskType: "alpha",
			}}
		>
			<path fill="#D9D9D9" d="M0 0h20v20H0z" />
		</mask>
		<g mask="url(#a)">
			<path
				fill="#F2A901"
				d="M3.333 5V3.333h13.334V5H3.333Zm0 11.667v-5H2.5V10l.833-4.167h13.334L17.5 10v1.667h-.833v5H15v-5h-3.333v5H3.333ZM5 15h5v-3.333H5V15Zm-.792-5h11.584l-.5-2.5H4.708l-.5 2.5Z"
			/>
			<path
				fill="#271B00"
				d="M3.333 5V3.333h13.334V5H3.333Zm0 11.667v-5H2.5V10l.833-4.167h13.334L17.5 10v1.667h-.833v5H15v-5h-3.333v5H3.333ZM5 15h5v-3.333H5V15Zm-.792-5h11.584l-.5-2.5H4.708l-.5 2.5Z"
			/>
		</g>
	</svg>
);

export default StoreIcon;
