import {setMultipleFilterForCandidates} from "store/mapx/filter/filterActions";
import {Dispatch} from "redux";
import {TAppDispatch, TRootState} from "types";
import {
	candidateCountryIdsByPositionSelector,
	candidateRegionIdsByPositionSelector,
	locationFilterKeyByPosition,
	regionFilterKeyByPosition,
} from "./locationFilterSelectors";
import {TLocationPosition} from "containers/Filters/PeopleFilters/LocationFilter/types";
import {setAPMultipleFilters} from "../additional-profiles/additionalProfilesActions";
import {
	apCandidateCountryIdsByPositionSelector,
	apCandidateRegionIdsByPositionSelector,
	apLocationFilterKeyByPosition,
	apRegionFilterKeyByPosition,
} from "../additional-profiles/additionalProfilesSelectors";

export const setLocationForCandidate =
	({
		position,
		nextCountryIds,
		toBeSetRegionIds,
	}: {
		position: TLocationPosition;
		nextCountryIds: number[];
		toBeSetRegionIds: number[];
	}) =>
	async (dispatch: Dispatch<TAppDispatch>, getState: () => TRootState) => {
		const state = getState();

		const locationFilterKey = locationFilterKeyByPosition(state, position);
		const regionFilterKey = regionFilterKeyByPosition(state, position);

		dispatch(
			setMultipleFilterForCandidates({
				[locationFilterKey]: nextCountryIds,
				[regionFilterKey]: toBeSetRegionIds,
			}),
		);
	};

export const setAPLocationForCandidate =
	({
		position,
		nextCountryIds,
		toBeSetRegionIds,
	}: {
		position: TLocationPosition;
		nextCountryIds: number[];
		toBeSetRegionIds: number[];
	}) =>
	async (dispatch: Dispatch<TAppDispatch>) => {
		const locationFilterKey = apLocationFilterKeyByPosition(position);
		const regionFilterKey = apRegionFilterKeyByPosition(position);

		dispatch(
			setAPMultipleFilters({
				[locationFilterKey]: nextCountryIds,
				[regionFilterKey]: toBeSetRegionIds,
			}),
		);
	};

const handleMoveLocation =
	(
		{
			from,
			to,
			country_id,
			region_id,
		}: {
			from: TLocationPosition;
			to: TLocationPosition;
			country_id: number;
			region_id: string;
		},
		getFilterKeys: (
			position: TLocationPosition,
			state: TRootState,
		) => {locationKey: string; regionKey: string},
		getCandidateIds: (
			position: TLocationPosition,
			state: TRootState,
		) => {locations: number[]; regions: number[]},
		setFilterAction: (filterChanges: Record<string, number[]>) => TAppDispatch,
	) =>
	async (dispatch: Dispatch<TAppDispatch>, getState: () => TRootState) => {
		const state = getState();

		const {locationKey: fromLocationKey, regionKey: fromRegionKey} = getFilterKeys(from, state);
		const {locationKey: toLocationKey, regionKey: toRegionKey} = getFilterKeys(to, state);
		const {locations: fromLocations, regions: fromRegions} = getCandidateIds(from, state);
		const {locations: toLocations, regions: toRegions} = getCandidateIds(to, state);

		const moveIdsBetweenFilters = (
			fromIds: number[],
			toIds: number[],
			filterCondition: (id: number) => boolean,
		) => ({
			from: fromIds.filter((id) => !filterCondition(id)),
			to: [...toIds.filter((id) => !filterCondition(id)), ...fromIds.filter(filterCondition)],
		});

		const filterChanges = region_id
			? {
					[fromRegionKey]: moveIdsBetweenFilters(
						fromRegions,
						toRegions,
						(id) => region_id === String(id),
					).from,
					[toRegionKey]: moveIdsBetweenFilters(
						fromRegions,
						toRegions,
						(id) => region_id === String(id),
					).to,
			  }
			: {
					[fromLocationKey]: moveIdsBetweenFilters(
						fromLocations,
						toLocations,
						(id) => country_id === id,
					).from,
					[toLocationKey]: moveIdsBetweenFilters(
						fromLocations,
						toLocations,
						(id) => country_id === id,
					).to,
			  };

		dispatch(setFilterAction(filterChanges));
	};

export const moveAPLocationsForCandidate = (params: {
	from: TLocationPosition;
	to: TLocationPosition;
	country_id: number;
	region_id: string;
}) =>
	handleMoveLocation(
		params,
		(position) => ({
			locationKey: apLocationFilterKeyByPosition(position),
			regionKey: apRegionFilterKeyByPosition(position),
		}),
		(position, state) => ({
			locations: apCandidateCountryIdsByPositionSelector(position)(state),
			regions: apCandidateRegionIdsByPositionSelector(position)(state),
		}),
		setAPMultipleFilters,
	);

export const moveLocationsForCandidate = (params: {
	from: TLocationPosition;
	to: TLocationPosition;
	country_id: number;
	region_id: string;
}) =>
	handleMoveLocation(
		params,
		(position, state) => ({
			locationKey: locationFilterKeyByPosition(state, position),
			regionKey: regionFilterKeyByPosition(state, position),
		}),
		(position, state) => ({
			locations: candidateCountryIdsByPositionSelector(state, position),
			regions: candidateRegionIdsByPositionSelector(state, position),
		}),
		setMultipleFilterForCandidates,
	);
