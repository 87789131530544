import React from "react";
import {Tag} from "components";
import FilterTags from "mapx-components/FilterTags";

function Tags({handleClick, tagCompanies}) {
	return (
		<FilterTags
			label="Added Companies"
			count={tagCompanies.length}
			tags={tagCompanies.map((item) => (
				<Tag key={item.id} onClick={() => handleClick(item)}>
					{item.name}
				</Tag>
			))}
		/>
	);
}

export default React.memo(Tags);
