import {type ChangeEvent, forwardRef} from "react";
import {noop} from "lodash";
import classNames from "classnames";

import type {TCheckboxProps} from "./types";

import styles from "./Checkbox.module.scss";

const Checkbox = forwardRef<HTMLInputElement, TCheckboxProps>(
	(
		{
			name,
			value,
			children,
			className = "",
			dataTestid = "",
			onChange = noop,
			useCustomOnChange,
			checkboxBorderClassName = "",
		},
		ref,
	) => {
		const isChecked = (event: ChangeEvent<HTMLInputElement>) => {
			onChange(useCustomOnChange ? event : event.target.checked);
		};

		return (
			<label
				htmlFor={name}
				data-testid={dataTestid}
				className={classNames(styles.container, {
					[className]: className,
				})}
			>
				<div className={styles.container__wrapper}>
					<input
						id={name}
						ref={ref}
						name={name}
						type="checkbox"
						checked={value}
						autoComplete="off"
						className={styles.container__input}
						onChange={(event) => isChecked(event)}
					/>
					<span
						className={classNames(styles.container__after, {
							[checkboxBorderClassName]: checkboxBorderClassName,
						})}
					/>
				</div>

				{children}
			</label>
		);
	},
);

Checkbox.displayName = "Checkbox";

export default Checkbox;
