import classNames from "classnames";
import {useHistory} from "react-router-dom";
import {BiCloudDownload} from "react-icons/bi";

import ArrowRight from "assets/icons/ArrowRight";

import styles from "./titles.module.scss";
import {TPageTitleProps} from "mapx-components/Titles/types";

export default function PageTitle({
	title,
	content,
	print = false,
	onClick,
	onPrintClick,
	printRequested = false,
}: Readonly<TPageTitleProps>) {
	const history = useHistory();

	const handleOnClick = () => {
		if (onClick) {
			onClick();
		} else {
			history.goBack();
		}
	};

	const handleOnPrintClick = () => {
		if (onPrintClick) {
			onPrintClick();
		}
	};

	return (
		<div className={styles.pageTitle}>
			<div className={styles.breadcrumb}>
				<span className={styles.previousButton} onClick={handleOnClick}>
					{title}
				</span>

				{content && (
					<>
						<ArrowRight />
						{content}
					</>
				)}
			</div>

			{print && (
				<div
					id="printBtn"
					className={classNames(styles.printButton, {
						[styles.printRequested]: printRequested,
					})}
					onClick={handleOnPrintClick}
				>
					<BiCloudDownload size={20} color="#404040" /> Download
				</div>
			)}
		</div>
	);
}
