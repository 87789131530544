import {useCallback} from "react";
import {useAppDispatch, useAppSelector} from "hooks";
import {filterPositionOptionsSelector} from "store/mapx/filter/filterSelectors";
import {
	TCompanyHeadcountPosition,
	TCompanyHeadcountRangeItem,
} from "mapx-components/Filters/CompanyHeadcountSearchFilter/types";
import CompanyHeadcountSearchFilter from "mapx-components/Filters/CompanyHeadcountSearchFilter";
import {TabSelect} from "components";
import {
	apCandidateCompanyHeadcountByPositionSelector,
	apCandidateCompanyHeadcountFilterActivePositionSelector,
	apCandidateCompanyHeadcountFilterConfigSelector,
	apCandidatesAllCompanyHeadcountCountSelector,
	apSelectedAnyCompanyHeadcountTagsSelector,
	apSelectedCurrentCompanyHeadcountTagsSelector,
	apSelectedPreviousCompanyHeadcountTagsSelector,
} from "store/mapx/additional-profiles/apCompanyHeadcountFilterSelectors";
import {
	setAPCompanyHeadcountFilterConfig,
	setAPMultipleFilters,
} from "store/mapx/additional-profiles/additionalProfilesActions";
import {
	moveHeadcountForAPCandidate,
	setCompanyHeadcountForAPCandidate,
} from "store/mapx/additional-profiles/apCompanyHeadcountFilterAsyncActions";
import Tags from "mapx-components/Filters/CompanyHeadcountSearchFilter/Tags";

const APHeadcountFilter = () => {
	const dispatch = useAppDispatch();

	const filterPositionOptions = useAppSelector(filterPositionOptionsSelector);

	const candidateCompanyHeadcountFilterConfig = useAppSelector(
		apCandidateCompanyHeadcountFilterConfigSelector,
	);

	const position = useAppSelector<TCompanyHeadcountPosition>(
		apCandidateCompanyHeadcountFilterActivePositionSelector,
	);

	const selectedHeadcounts = useAppSelector((state) =>
		apCandidateCompanyHeadcountByPositionSelector(state, position),
	);

	const currentHeadcountTags = useAppSelector(apSelectedCurrentCompanyHeadcountTagsSelector);

	const previousHeadcountTags = useAppSelector(apSelectedPreviousCompanyHeadcountTagsSelector);

	const anyHeadcountTags = useAppSelector(apSelectedAnyCompanyHeadcountTagsSelector);

	const headcountCount = useAppSelector(apCandidatesAllCompanyHeadcountCountSelector);

	const handlePositionChange = useCallback(
		(updatedPositionValue: string) => {
			dispatch(
				setAPCompanyHeadcountFilterConfig({
					...candidateCompanyHeadcountFilterConfig,
					active_position: updatedPositionValue,
				}),
			);
		},
		[dispatch, candidateCompanyHeadcountFilterConfig],
	);

	const handleResetClick = useCallback(() => {
		dispatch(
			setAPMultipleFilters({
				company_size: [],
				current_company_size: [],
				previous_company_size: [],
			}),
		);
	}, [dispatch]);

	const clearTagsSelectionByPosition = useCallback(
		(position: TCompanyHeadcountPosition) => {
			if (position === "current") {
				dispatch(
					setAPMultipleFilters({
						current_company_size: [],
					}),
				);
			} else if (position === "previous") {
				dispatch(
					setAPMultipleFilters({
						previous_company_size: [],
					}),
				);
			} else {
				dispatch(
					setAPMultipleFilters({
						company_size: [],
					}),
				);
			}
		},
		[dispatch],
	);

	const handleOnChange = useCallback(
		(headcountItem: TCompanyHeadcountRangeItem) => {
			dispatch(setCompanyHeadcountForAPCandidate({position, headcountItem}));
		},
		[dispatch, position],
	);

	const moveHeadcountTagPosition = useCallback(
		(id: string, from: TCompanyHeadcountPosition, to: TCompanyHeadcountPosition) => {
			dispatch(
				moveHeadcountForAPCandidate({
					from,
					to,
					ids: [id],
				}),
			);
		},
		[dispatch],
	);

	return (
		<div>
			<TabSelect.LabelContainer label="Position">
				<TabSelect
					selected={position}
					onTabChange={handlePositionChange}
					options={filterPositionOptions}
				/>
			</TabSelect.LabelContainer>

			<CompanyHeadcountSearchFilter
				handleOnChange={handleOnChange}
				handleResetClick={handleResetClick}
				selectedHeadcounts={selectedHeadcounts}
			/>

			{headcountCount > 0 && (
				<>
					<Tags
						tagLabel={"Current"}
						tagValue={"current"}
						tags={currentHeadcountTags}
						handleTagClick={handleOnChange}
						clearTagsByPosition={clearTagsSelectionByPosition}
						moveHeadcountTagPosition={moveHeadcountTagPosition}
					/>

					<Tags
						tagLabel={"Previous"}
						tagValue={"previous"}
						tags={previousHeadcountTags}
						handleTagClick={handleOnChange}
						clearTagsByPosition={clearTagsSelectionByPosition}
						moveHeadcountTagPosition={moveHeadcountTagPosition}
					/>

					<Tags
						tagLabel={"Any"}
						tagValue={"any"}
						tags={anyHeadcountTags}
						handleTagClick={handleOnChange}
						clearTagsByPosition={clearTagsSelectionByPosition}
						moveHeadcountTagPosition={moveHeadcountTagPosition}
					/>
				</>
			)}
		</div>
	);
};

export default APHeadcountFilter;
