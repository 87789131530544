import type {FC} from "react";

import type {IconProps} from "./types";

const Error: FC<IconProps> = ({...rest}) => (
	<svg
		width={20}
		height={20}
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		role="img"
		viewBox="0 0 20 20"
		{...rest}
	>
		<mask
			id="error_svg__a"
			style={{
				maskType: "alpha",
			}}
			maskUnits="userSpaceOnUse"
			x={0}
			y={0}
			width={20}
			height={20}
		>
			<path fill="#D9D9D9" d="M0 0h20v20H0z" />
		</mask>
		<g mask="url(#error_svg__a)">
			<path
				d="M10 14a.723.723 0 0 0 .531-.219.723.723 0 0 0 .219-.531.723.723 0 0 0-.219-.531A.723.723 0 0 0 10 12.5a.723.723 0 0 0-.531.219.723.723 0 0 0-.219.531c0 .208.073.385.219.531A.723.723 0 0 0 10 14Zm-.75-3h1.5V6h-1.5v5Zm.75 7a7.794 7.794 0 0 1-3.104-.625 8.067 8.067 0 0 1-2.552-1.719 8.065 8.065 0 0 1-1.719-2.552A7.795 7.795 0 0 1 2 10c0-1.111.208-2.15.625-3.115a8.066 8.066 0 0 1 4.271-4.26A7.795 7.795 0 0 1 10 2c1.111 0 2.15.208 3.115.625a8.1 8.1 0 0 1 4.26 4.26C17.792 7.85 18 8.889 18 10a7.794 7.794 0 0 1-.625 3.104 8.066 8.066 0 0 1-4.26 4.271A7.775 7.775 0 0 1 10 18Z"
				fill="#E05A5A"
			/>
		</g>
	</svg>
);

export default Error;
