import type {FC} from "react";

import {Button} from "components";
import {HelpIconTooltip, CloseOnboardingTooltip} from "assets/icons";

import type {TTooltipCloseProps} from "./types";
import styles from "./OnboardingTooltip.module.scss";

const TooltipClose: FC<TTooltipCloseProps> = ({text = "", closeHandler}) => (
	<div className={styles.close}>
		<HelpIconTooltip />

		<p className={styles.close__text}>{text}</p>

		<Button defaultBtnStyle onClick={closeHandler}>
			<CloseOnboardingTooltip />
		</Button>
	</div>
);

export default TooltipClose;
