import type {FC} from "react";

import type {IconProps} from "./types";

const CompanyAsBuildingIcon: FC<IconProps> = ({className = "", fill = "#868D8D", ...rest}) => (
	<svg
		width="20"
		height="20"
		viewBox="0 0 20 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		className={className}
		{...rest}
	>
		<path
			d="M16.2222 1H7.33333C6.35289 1 5.55556 1.8073 5.55556 2.8V8.2H3.77778C2.79733 8.2 2 9.0073 2 10V18.1C2 18.3387 2.09365 18.5676 2.26035 18.7364C2.42705 18.9052 2.65314 19 2.88889 19H17.1111C17.3469 19 17.573 18.9052 17.7397 18.7364C17.9064 18.5676 18 18.3387 18 18.1V2.8C18 1.8073 17.2027 1 16.2222 1ZM3.77778 10H9.11111V17.2H3.77778V10ZM16.2222 17.2H10.8889V10C10.8889 9.0073 10.0916 8.2 9.11111 8.2H7.33333V2.8H16.2222V17.2Z"
			fill={fill}
		/>
		<path
			d="M9.16146 5H10.8281V6.66667H9.16146V5ZM12.4948 5H14.1615V6.66667H12.4948V5ZM12.4948 8.35917H14.1615V10H12.4948V8.35917ZM12.4948 11.6667H14.1615V13.3333H12.4948V11.6667ZM5.82812 11.6675H7.49479V13.3342H5.82812V11.6675Z"
			fill={fill}
		/>
	</svg>
);

export default CompanyAsBuildingIcon;
