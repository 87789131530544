import {type FC, useCallback, useEffect} from "react";

import colors from "styles/themes.module.scss";
import {AuthView, Loader} from "components";
import {sendVerificationEmailForm} from "mapx-constants";
import {useAppDispatch, useAppSelector} from "hooks";
import {getUserLocation, sendVerificationEmail} from "store/mapx/user/userAsyncAction";
import {
	locationInProgressSelector,
	userLocationSelector,
	userSelector,
	verificationEmailErrorSelector,
	verificationEmailInProgressSelector,
	verificationEmailSelector,
	verificationEmailSentSelector,
} from "store/mapx/user/userSelectors";

import styles from "./index.module.scss";
import {STIpApiLocation} from "api/ipApi/ipapi";
import {useHistory} from "react-router-dom";
import {Mixpanel} from "helpers/mixpanel";

const VerifyEmail: FC = () => {
	const dispatch = useAppDispatch();

	const history = useHistory();

	const user = useAppSelector(userSelector);
	const inProgress = useAppSelector(verificationEmailInProgressSelector);
	const error = useAppSelector(verificationEmailErrorSelector);
	const emailSent = useAppSelector(verificationEmailSentSelector);
	const email = useAppSelector(verificationEmailSelector);
	const locationInProgress = useAppSelector(locationInProgressSelector);
	const location: STIpApiLocation = useAppSelector(userLocationSelector);

	const isError = error !== null ? String(error) : "";

	const onSubmit = useCallback(
		(params: Record<string, unknown>) => {
			dispatch(sendVerificationEmail(params.email));
		},
		[dispatch],
	);

	useEffect(() => {
		if (window.location.hostname !== "localhost") {
			dispatch(getUserLocation());
		}
	}, [dispatch]);

	useEffect(() => {
		switch (location?.country) {
			// case "GB": // United Kingdom
			case "RU": // russia
			case "CN": // chine
			case "IN": // india
				Mixpanel.track(`Signup: Region Blocked`, {
					pageTitle: "Signup Page",
					description: location,
					location: window.location.pathname,
				});
				history.replace("/register/blocked");
				break;
			default:
		}

		if (user?.email) {
			history.replace("/home");
		}
	}, [history, location, user]);

	if (locationInProgress) {
		return (
			<div className={styles.loaderContainer}>
				<Loader height={80} width={80} type="Rings" color={colors.loaderDotColor} />
			</div>
		);
	}

	return emailSent ? (
		<AuthView
			error={isError}
			showErrorTooltip
			loading={inProgress}
			title="Check your inbox"
			rightLabel="Forgot Password?"
			description={
				<div className={styles.emailSentMessage}>
					<p>
						Great, we&apos;ve sent you a verification email to: <b>{email}</b>
					</p>
					<p>
						<b>Please check your junk as well as your inbox</b> and verify your email
						address to complete the registration process.
					</p>
				</div>
			}
		/>
	) : (
		<AuthView
			to="/register/verify-email"
			error={isError}
			showErrorTooltip
			loading={inProgress}
			onSubmit={onSubmit}
			title="Sign up today"
			submitText="Sign up"
			form={sendVerificationEmailForm}
			description="And start searching in minutes!"
		/>
	);
};

export default VerifyEmail;
