import {useCallback} from "react";
import {useSelector} from "react-redux";
import {Accordion, IndustrySearchFilter} from "mapx-components";
import {
	setIndustriesFilterConfig,
	setMultipleFilterForCandidates,
} from "store/mapx/filter/filterActions";
import {TabSelect} from "components";
import {setIndustryForCandidate} from "store/mapx/filter/industriesFilterAsyncActions";
import {
	allIndustriesCountSelector,
	candidateIndustriesByPositionSelector,
	industryCandidatePositionSelector,
	industryFilterConfigSelector,
} from "store/mapx/filter/industriesFilterSelectors";
import AnyTags from "./Tags/AnyTags";
import CurrentTags from "./Tags/CurrentTags";
import PreviousTags from "./Tags/PreviousTags";
import {TIndustryFilterProps} from "containers/Filters/PeopleFilters/IndustryFilter/types";
import {useAppDispatch, useAppSelector} from "hooks";
import {TIndustryPosition} from "api/filterOptionsApi/IndustryApi/types";
import {filterPositionOptionsSelector} from "store/mapx/filter/filterSelectors";

function IndustryFilter({disabled}: TIndustryFilterProps) {
	const dispatch = useAppDispatch();

	const filterPositionOptions = useAppSelector(filterPositionOptionsSelector);

	const industryFilterConfig = useAppSelector(industryFilterConfigSelector);

	const position = useAppSelector<TIndustryPosition>(industryCandidatePositionSelector);

	const industries = useSelector((state) =>
		candidateIndustriesByPositionSelector(state, position),
	);

	const count = useSelector(allIndustriesCountSelector);

	const handlePositionChange = useCallback(
		(updatedPositionValue: string) => {
			dispatch(
				setIndustriesFilterConfig({
					...industryFilterConfig,
					active_position: updatedPositionValue,
				}),
			);
		},
		[dispatch, industryFilterConfig],
	);

	const handleChange = useCallback(
		(nextIds: number[]) => {
			dispatch(setIndustryForCandidate({nextIds, position}));
		},
		[dispatch, position],
	);

	const handleResetClick = useCallback(() => {
		dispatch(
			setMultipleFilterForCandidates({
				industries: [],
				current_industries: [],
				previous_industries: [],
			}),
		);
	}, [dispatch]);

	return (
		<Accordion title="Industry" subtitle={count} disabled={disabled}>
			<TabSelect.LabelContainer label="Position">
				<TabSelect
					selected={position}
					onTabChange={handlePositionChange}
					options={filterPositionOptions}
				/>
			</TabSelect.LabelContainer>

			<IndustrySearchFilter
				selectedIndustries={industries}
				handleResetClick={handleResetClick}
				handleOnChange={handleChange}
			/>

			{count !== 0 && (
				<>
					<CurrentTags />

					<PreviousTags />

					<AnyTags />
				</>
			)}
		</Accordion>
	);
}

export default IndustryFilter;
