import type {FC} from "react";

import type {TTableHeader} from "../types";

import type {TTableHeaderProps} from "./types";
import TableHeaderStep from "./TableHeaderStep";
import styles from "./TableHeader.module.scss";

const TableHeader: FC<TTableHeaderProps> = ({stepList, onSort}) => {
	const renderSteps = stepList.map(
		({id, text, isDropdown, itemKey}: TTableHeader, idx: number) => (
			<TableHeaderStep
				id={id}
				key={id}
				idx={idx}
				text={text}
				onSort={onSort}
				itemKey={itemKey}
				isDropdown={isDropdown}
			/>
		),
	);

	return (
		<div className={styles.wrapper}>
			<div className={styles.wrapper__container}>{renderSteps}</div>
		</div>
	);
};

export default TableHeader;
