import HttpClient from "api/index";

class MapxKeywordApi extends HttpClient {
	async getKeywords(pageNumber = 1, config = {}) {
		return this.doGet(`/keywords?page=${pageNumber}&per_page=40`, config);
	}

	async searchKeywords(queryParam: string, pageNumber: number, config = {}) {
		return this.doGet(
			`/keywords${queryParam ? queryParam + "&" : "?"}page=${pageNumber}&per_page=40`,
			config,
		);
	}
}

const mapxKeywordApi = new MapxKeywordApi();

export default mapxKeywordApi;
