import React, {KeyboardEventHandler} from "react";
import classNames from "classnames";
import IconError from "assets/icons/IconError16";
import css from "mapx-components/Inputs/SearchTextArea/searchTextArea.module.scss";
import {Loader} from "components";
import colors from "styles/themes.module.scss";

interface SearchTextAreaProps {
	id?: string;
	isLinkIcon?: boolean;
	type?: string;
	errorText?: string | boolean;
	errorClass?: string | boolean;
	customClass?: string | undefined;
	isLoading?: boolean;
	label?: string | undefined;
	loaderColor?: string;
	className?: string;
	margin?: string;
	disableFocus?: boolean;
	disabled?: boolean;
	placeholder?: string;
	value?: string;
	autoComplete?: string;
	onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
	onKeyDown?: KeyboardEventHandler<HTMLTextAreaElement>;
}

const SearchTextArea: React.FC<SearchTextAreaProps> = ({
	label = undefined,
	errorText,
	customClass = "",
	isLoading = false,
	loaderColor = colors.loaderDotColor,
	errorClass,
	margin = "16px 0",
	disableFocus = false,
	onKeyDown,
	className = "",
	...restProps
}) => {
	const InlineLoaderComponent = (
		<Loader
			displayAtCenterOfPage={false}
			type={"ThreeDots"}
			height={20}
			width={20}
			color={loaderColor}
		/>
	);

	return (
		<div
			className={classNames(
				css.inputContainer,
				{[css.invalid]: errorText, [customClass]: customClass},
				errorText !== undefined ? "invalid-input" : "valid-input",
			)}
			style={{margin}}
		>
			{label !== undefined && label !== "" && (
				<label aria-labelledby="search-input" className={css.label}>
					{label}
				</label>
			)}

			<div className={css.inputWrapper}>
				<textarea
					onKeyDown={onKeyDown}
					{...restProps}
					className={classNames(css.searchTextArea, {
						[css.disableFocus]: disableFocus,
						[className]: className !== "",
					})}
					data-testid="search-input"
				/>

				{isLoading && (
					<span data-testid="inline-loader" className={css.loader}>
						{InlineLoaderComponent}
					</span>
				)}

				<span className={css.iconError}>
					<IconError />
				</span>
			</div>

			{errorText !== undefined && (
				<span data-testid="error-test-id" className={classNames(css.errorText, errorClass)}>
					{errorText}
				</span>
			)}
		</div>
	);
};

export default SearchTextArea;
