const LineIcon = () => (
	<svg width="12" height="2" viewBox="0 0 12 2" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M1.33334 1H10.6667"
			stroke="#0C5850"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);

export default LineIcon;
