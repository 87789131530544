import type {FC} from "react";
import {useHistory} from "react-router-dom";

import {loginForm} from "mapx-constants";
import {AuthView} from "components";
import {useOnAuth} from "hooks/useOnAuth";
import colors from "styles/themes.module.scss";

const Login: FC = () => {
	const history = useHistory();
	const {loading, error, onSubmit} = useOnAuth({
		history,
		type: "login",
		redirectUrl: "/home",
		themeColor: colors.loaderDotColor,
	});

	return (
		<AuthView
			error={error}
			form={loginForm}
			loading={loading}
			submitText="Login"
			onSubmit={onSubmit}
			title="Welcome Back"
			to="/forgot-password"
			rightLabel="Forgot Password?"
			description="Enter your credentials to access your account."
		/>
	);
};

export default Login;
