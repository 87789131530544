import type {FC} from "react";
import usePortal from "react-useportal";
import PasswordChecklist from "react-password-checklist";

import {FeatureBlockArrow, ValidField, InValidField} from "assets/icons";

import type {TInputErrorTooltipProps} from "./types";
import styles from "./FormInput.module.scss";

const InputErrorTooltip: FC<TInputErrorTooltipProps> = ({password, inputWrapperRef}) => {
	const {Portal} = usePortal({
		bindTo: inputWrapperRef.current as HTMLDivElement,
	});

	return (
		<Portal>
			<div className={styles.errors}>
				<div className={styles.errors__container}>
					<FeatureBlockArrow className={styles.errors__container__icon} />

					<p className={styles.errors__container__text}>Password Requirements</p>

					<PasswordChecklist
						rules={[
							"overallLength",
							"specialChar",
							"capital",
							"lowercase",
							"whitespace",
						]}
						overallLength={{
							minLength: 8,
							maxLength: 20,
						}}
						value={password || ""}
						messages={{
							capital: "Must have at least one Uppercase",
							lowercase: "Must have at least one Lowercase",
							specialChar: "Must have at least one Special Symbol",
						}}
						iconComponents={{
							ValidIcon: <ValidField />,
							InvalidIcon: <InValidField />,
						}}
					/>
				</div>
			</div>
		</Portal>
	);
};

export default InputErrorTooltip;
