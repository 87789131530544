import {TRootState} from "types";

export const candidateCompanyEventSizeSelector = (state: TRootState) =>
	state.filter.candidate_filters.company_events.size;

export const candidateCompanyEventTypesSelector = (state: TRootState) =>
	state.filter.candidate_filters.company_events.types;

export const candidateCompanyEventsSelector = (state: TRootState) =>
	state.filter.candidate_filters.company_events;

//Candidate company events
export const candidateCompanyEventPaginationInfoSelector = (state: TRootState) =>
	state.search.candidateCompanyEventsPaginationInfo;
export const candidateCompanyEventsSearchInProgressSelector = (state: TRootState) =>
	state.search.specialtyClusterSearchInProgress;
export const candidateCompanyEventsSearchQuerySelector = (state: TRootState) =>
	state.search.candidateCompanyEventsSearchQuery;

export const CandidateCompanyEventsSelector = (state: TRootState) =>
	state.search.candidateCompanyEvents;

export const CandidateCompanyEventSizeRangeOptionsSelector = (state: TRootState) =>
	state.search.candidateCompanyEventSizeRangeOptions;

export const CandidateCompanyDateRangeOptionsSelector = (state: TRootState) =>
	state.search.candidateCompanyDateRangeOptions;

export const isSizeDropDownActiveOnDEFilterSelector = (state: TRootState) =>
	state.filter.isSizeDropDownActiveOnDEFilter;

export const isDateDropDownActiveOnDEFilterSelector = (state: TRootState) =>
	state.filter.isDateDropDownActiveOnDEFilter;

export const isTypeDropDownActiveOnDEFilterSelector = (state: TRootState) =>
	state.filter.isTypeDropDownActiveOnDEFilter;
