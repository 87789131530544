import type {AnyAction} from "redux";

import type {STProduct} from "api/paymentApi/types";

import {
	CONTACT_ADMIN_MODAL,
	GET_PRODUCTS_DONE,
	GET_PRODUCTS_FAIL,
	GET_PRODUCTS_INIT,
	OPEN_CHECKOUT_DONE,
	OPEN_CHECKOUT_FAIL,
	OPEN_CHECKOUT_INIT,
	OPEN_CUSTOMER_PORTAL_DONE,
	OPEN_CUSTOMER_PORTAL_FAIL,
	OPEN_CUSTOMER_PORTAL_INIT,
	PRICING_TABLE_MODAL,
	UPDATE_PLAN_MODAL,
} from "../mapXActionTypes";
import {
	contactAdminModal,
	openCheckoutInit,
	pricingTableModal,
	updatePlanModal,
} from "./paymentActions";

export type TPaymentState = {
	contact_admin_modal: boolean;
	open_checkout_in_progress: Nullable<string>;
	open_customer_portal_in_progress: boolean;
	pricing_table_modal: boolean;
	products_in_progress: boolean;
	products: STProduct[];
	update_plan_modal: boolean;
};

export const initialState: TPaymentState = {
	contact_admin_modal: false,
	open_checkout_in_progress: null,
	open_customer_portal_in_progress: false,
	pricing_table_modal: false,
	products_in_progress: false,
	products: [],
	update_plan_modal: false,
};

const paymentReducer = (state = initialState, action: AnyAction) => {
	switch (action.type) {
		case OPEN_CUSTOMER_PORTAL_INIT:
			return {
				...state,
				open_customer_portal_in_progress: true,
			};
		case OPEN_CUSTOMER_PORTAL_DONE:
			return {
				...state,
				open_customer_portal_in_progress: false,
			};
		case OPEN_CUSTOMER_PORTAL_FAIL:
			return {
				...state,
				open_customer_portal_in_progress: false,
			};
		case OPEN_CHECKOUT_INIT: {
			const {priceID} = action as ReturnType<typeof openCheckoutInit>;

			return {
				...state,
				open_checkout_in_progress: priceID,
			};
		}
		case OPEN_CHECKOUT_DONE:
			return {
				...state,
				open_checkout_in_progress: null,
			};
		case OPEN_CHECKOUT_FAIL:
			return {
				...state,
				open_checkout_in_progress: null,
			};
		case GET_PRODUCTS_INIT:
			return {
				...state,
				products_in_progress: true,
			};
		case GET_PRODUCTS_DONE: {
			const {products} = action;

			return {
				...state,
				products_in_progress: false,
				products,
			};
		}
		case GET_PRODUCTS_FAIL:
			return {
				...state,
				products_in_progress: false,
			};
		case CONTACT_ADMIN_MODAL: {
			const {displayModal} = action as ReturnType<typeof contactAdminModal>;

			return {
				...state,
				contact_admin_modal: displayModal,
			};
		}
		case UPDATE_PLAN_MODAL: {
			const {displayModal} = action as ReturnType<typeof updatePlanModal>;

			return {
				...state,
				update_plan_modal: displayModal,
			};
		}
		case PRICING_TABLE_MODAL: {
			const {displayModal} = action as ReturnType<typeof pricingTableModal>;

			return {
				...state,
				pricing_table_modal: displayModal,
			};
		}
		default:
			return state;
	}
};

export default paymentReducer;
