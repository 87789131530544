import {FC, useMemo} from "react";

import {Sidebar} from "mapx-components";

import type {TMainWrapperProps} from "./types";
import styles from "./mainWrapper.module.scss";
import {useAppSelector} from "hooks";
import {projectSelector} from "store/mapx/project-list/projectListSelectors";
import classNames from "classnames";
import ProjectChecklist from "mapx-components/Layouts/ProjectChecklist";
import {useHistory} from "react-router-dom";

const MainWrapper: FC<TMainWrapperProps> = ({children, className, ...rest}) => {
	const project = useAppSelector(projectSelector);

	const history = useHistory();

	const shouldDisplayProjectChecklist = useMemo(() => {
		const currentPath = history.location.pathname;

		const projectUrlRegex = /^\/project\/\d+$/;

		return (
			!!project &&
			(projectUrlRegex.test(currentPath) ||
				currentPath === "/companies" ||
				currentPath === "/candidates-filter-search" ||
				currentPath === "/candidates-text-search" ||
				currentPath === "/candidates-ai-web-search")
		);
	}, [project, history?.location?.pathname]);

	return (
		<div className={classNames(styles.wrapper, className)} {...rest}>
			<Sidebar />

			<div
				className={classNames(styles.wrapper__content, {
					[styles.wrapper__content__is_show]: project?.name,
				})}
			>
				{children}
			</div>

			{shouldDisplayProjectChecklist && <ProjectChecklist />}
		</div>
	);
};

export default MainWrapper;
