/** @format */

export const loadState = (name = "state") => {
	try {
		const token = localStorage.getItem("token"); // if no token available there won't be any state available for user.
		const serializedState = localStorage.getItem(name);

		if (serializedState === null || !token) {
			return undefined;
		}

		return JSON.parse(serializedState);
	} catch (err) {
		return undefined;
	}
};

export const saveState = (state, name = "state") => {
	try {
		const serializedState = JSON.stringify(state);

		localStorage.setItem(name, serializedState);
	} catch {
		// ignore write errors
	}
};
