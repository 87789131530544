import {useEffect, useMemo} from "react";
import {uniqBy} from "lodash";

import {
	getCountries,
	getJobFunctions,
	getKeywordsList,
	getSeniorityList,
} from "store/mapx/search/searchAsyncActions";

import {searchSelector} from "store/mapx/search/searchSelectors";
import {useAppDispatch, useAppSelector} from "../index";
import {getName} from "helpers/string";

import {
	FilterOptions,
	TUseOrgChartFilterOptionsParams,
	TUseOrgChartFilterOptionsReturn,
} from "./types";

const useOrgChartFilterOptions = ({
	excludedFilterOptions = [],
}: TUseOrgChartFilterOptionsParams): TUseOrgChartFilterOptionsReturn => {
	const dispatch = useAppDispatch();

	const {seniorityOptions, jobFunctionOptions, countryOptions, keywordOptions} =
		useAppSelector(searchSelector);

	const functionSpecialismOptions = useMemo(
		() =>
			// @TODO: Refactor any to real type
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			uniqBy(jobFunctionOptions, "id").map(({id, name, specialisms}: any) => ({
				id,
				name,
				steps: uniqBy(
					// @TODO: Refactor any to real type
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					specialisms.map(({id, name}: any) => ({
						id,
						count: 0,
						text: name,
					})),
					"id",
				),
			})),
		[jobFunctionOptions],
	);

	const locationOptions = useMemo(
		() =>
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			countryOptions.map(({id, name, countries}: any) => ({
				id,
				name,
				// @TODO: Refactor any to real type
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				steps: countries.map(({id, name, regions}: any) => ({
					id,
					count: 0,
					text: name,
					regions,
				})),
			})),
		[countryOptions],
	);

	const mappedKeywordOptions = useMemo(() => {
		// @TODO: Refactor any to real type
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		return keywordOptions.map((item: any) => {
			return {
				...item,
				name: getName(item.name),
			};
		});
	}, [keywordOptions]);

	const keywordFinalOptions = useMemo(
		() => uniqBy(mappedKeywordOptions, "id"),
		[mappedKeywordOptions],
	);

	useEffect(() => {
		const isSeniorityExcluded = excludedFilterOptions?.includes(FilterOptions.Seniority);
		const isJobFunctionExcluded = excludedFilterOptions?.includes(FilterOptions.JobFunction);
		const isCountryExcluded = excludedFilterOptions?.includes(FilterOptions.Country);
		const isKeywordExcluded = excludedFilterOptions?.includes(FilterOptions.Keyword);

		if (!seniorityOptions.length && !isSeniorityExcluded) {
			dispatch(getSeniorityList());
		}

		if (!jobFunctionOptions.length && !isJobFunctionExcluded) {
			dispatch(getJobFunctions());
		}

		if (!countryOptions.length && !isCountryExcluded) {
			dispatch(getCountries());
		}

		if (!keywordOptions.length && !isKeywordExcluded) {
			dispatch(getKeywordsList());
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return {
		seniorityOptions,
		functionSpecialismOptions,
		countryOptions: locationOptions,
		keywordOptions: keywordFinalOptions,
	};
};

export default useOrgChartFilterOptions;
