import React from "react";

const RefreshIcon = ({className = "", ...rest}) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="32"
			height="32"
			viewBox="0 0 32 32"
			fill="none"
			className={className}
			{...rest}
		>
			<rect x="0.5" y="0.5" width="31" height="31" rx="3.5" stroke="#DAD8D7" />
			<mask
				id="mask0_4446_17118"
				style={{maskType: "alpha"}}
				maskUnits="userSpaceOnUse"
				x="6"
				y="6"
				width="20"
				height="21"
			>
				<rect x="6" y="6.5" width="20" height="20" fill="#D9D9D9" />
			</mask>
			<g mask="url(#mask0_4446_17118)">
				<path
					d="M16 22.5C14.3333 22.5 12.9167 21.9167 11.75 20.75C10.5833 19.5833 10 18.1667 10 16.5C10 14.8333 10.5833 13.4167 11.75 12.25C12.9167 11.0833 14.3333 10.5 16 10.5C16.9028 10.5 17.7396 10.6875 18.5104 11.0625C19.2812 11.4375 19.9444 11.9375 20.5 12.5625V10.5H22V15.5H17V14H19.7292C19.3264 13.3889 18.7986 12.9028 18.1458 12.5417C17.4931 12.1806 16.7778 12 16 12C14.75 12 13.6875 12.4375 12.8125 13.3125C11.9375 14.1875 11.5 15.25 11.5 16.5C11.5 17.75 11.9375 18.8125 12.8125 19.6875C13.6875 20.5625 14.75 21 16 21C17.1667 21 18.1667 20.6146 19 19.8438C19.8333 19.0729 20.3125 18.125 20.4375 17H21.9792C21.8542 18.5556 21.2222 19.8611 20.0833 20.9167C18.9444 21.9722 17.5833 22.5 16 22.5Z"
					fill="#2C3030"
				/>
			</g>
		</svg>
	);
};

export default RefreshIcon;
