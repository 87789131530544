import type {FC} from "react";

import type {IconProps} from "./types";

const ChevronIcon: FC<IconProps> = ({color = "#808080", height = 24, width = 24, ...rest}) => (
	<svg
		width={width}
		height={width}
		viewBox={`0 0 24 24`}
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...rest}
	>
		<mask
			id="prefix__a"
			style={{
				maskType: "alpha",
			}}
			maskUnits="userSpaceOnUse"
			x={0}
			y={0}
			width={width}
			height={height}
		>
			<path fill={color} d="M0 0h24v24H0z" />
		</mask>
		<g mask="url(#prefix__a)">
			<path
				d="M7.4 15.55l-1.6-1.575 6.2-6.2 6.2 6.2-1.6 1.575-4.6-4.6-4.6 4.6z"
				fill={color}
			/>
		</g>
	</svg>
);

export default ChevronIcon;
