/** @format */

import {useState} from "react";
import {FormValidation} from "../helpers/validator";

/***
 *** This hook takes initial form data and initial validation as parameter
 *** Returns the data, validation and form manipulation logic for reuse purpose.
 ***/
const useFormValidation = (initialData, initialValidation) => {
	const [formData, setFormData] = useState({...initialData});
	const [validation, setValidation] = useState({...initialValidation});

	const changeForm = (key, value) => {
		const updatedData = {...formData};

		updatedData[key] = value;
		let keyValuePair = {key, value};

		if (key === "conf_password") {
			keyValuePair = {
				...keyValuePair,
				matchValue: updatedData["new_password"],
			};
		} else if (key === "assignment") {
			key = "assignment_id";
			keyValuePair = {key, value: value?.value || null};
		}

		const newValidation = FormValidation(keyValuePair);
		let mergedValidation = {...validation, [key]: newValidation};

		// Trigger conf_password validation whener new_password changes.
		if (key === "new_password" && formData["conf_password"]) {
			keyValuePair = {
				...keyValuePair,
				matchValue: updatedData["new_password"],
			};
			const confValidation = FormValidation({
				key: "conf_password",
				value: formData["conf_password"],
				matchValue: updatedData["new_password"],
			});

			mergedValidation = {
				...mergedValidation,
				conf_password: confValidation,
			};
		}

		setValidation(mergedValidation);
		setFormData(updatedData);

		return updatedData;
	};

	return [formData, validation, changeForm, setFormData, setValidation];
};

export default useFormValidation;
