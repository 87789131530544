import {type FC, useState} from "react";

import {LogoutButton} from "components";
import Loader from "components/Plugins/Loader";
import {FormValidation} from "helpers/validator";
import TextInput from "components/Inputs/TextInput";
import {useAppDispatch, useAppSelector} from "hooks";
import {logoutBulk} from "store/mapx/user/userAsyncAction";

import type {TInitialValue} from "./types";
import {getAllValid, initialValue} from "./utils";
import styles from "./index.module.scss";

const Profile: FC = () => {
	const dispatch = useAppDispatch();
	const {user} = useAppSelector((state) => state.user); // fetchUserInProgress
	const [loggingOut, setLoggingOut] = useState<boolean>(false);
	const [exitingSessions, setExitingSession] = useState<boolean>(false);
	const [userData, setUserData] = useState({
		...user,
		password: "",
		newPassword: "",
	});
	const [validation, setValidation] = useState<TInitialValue>(initialValue);

	const changeForm = (key: string, value: string) => {
		const newUserData = {...userData};

		newUserData[key] = value;
		const newValidation = FormValidation({key, value});
		const mergedValidation = {...validation, [key]: newValidation};

		setValidation(mergedValidation);
		setUserData(newUserData);
	};

	const onSessionEndClick = async () => {
		setExitingSession(true);
		await dispatch(logoutBulk());
		setExitingSession(false);
	};

	const onSubmit = async () => {
		console.log("submit");
	};

	const submitDisabled = getAllValid(validation);

	const InlineLoaderComponent = <Loader height={40} width={40} color={"#bbbbbb"} />;

	return (
		<div className={styles.container}>
			<h1 className={styles.title}>Profile</h1>
			<form className={styles.form}>
				<TextInput
					label="First Name"
					name="firstName"
					type="text"
					value={userData.firstName}
					placeholder="Enter the first name"
					errorText={String(validation?.firstName?.error)}
					onChange={(e) => changeForm("firstName", e.target.value)}
				/>
				<TextInput
					label="Last Name"
					name="lastName"
					type="text"
					value={userData.lastName}
					placeholder="Enter the last name"
					errorText={validation.lastName.error}
					onChange={(e) => changeForm("lastName", e.target.value)}
				/>
				<TextInput
					label="Email"
					name="email"
					type="email"
					value={userData.email}
					placeholder="Enter your email"
					errorText={validation.email.error}
					onChange={(e) => changeForm("lastName", e.target.value)}
				/>
				<div className={styles.passwordRow}>
					<TextInput
						label="Current Password"
						name="password"
						type="password"
						value={userData.password}
						placeholder="Enter the password"
						errorText={validation.password.error}
						onChange={(e) => changeForm("password", e.target.value)}
					/>
					<TextInput
						label="New Password"
						name="newPassword"
						type="password"
						value={userData.newPassword}
						placeholder="Confirm the password"
						errorText={validation.newPassword.error}
						onChange={(e) => changeForm("newPassword", e.target.value)}
					/>
				</div>

				<span className={styles.sessionNote}>
					{exitingSessions ? (
						<span onClick={onSessionEndClick}>Ending {InlineLoaderComponent}</span>
					) : (
						<span onClick={onSessionEndClick}>
							End all session activities (except this one)
						</span>
					)}
				</span>

				<div className={styles.buttonsRow}>
					<LogoutButton
						exitingSessions={exitingSessions}
						onLoggingOut={(value) => setLoggingOut(value)}
					/>

					<button
						type="button"
						disabled={!submitDisabled || exitingSessions || loggingOut}
						onClick={onSubmit}
						className={styles.submitButton}
					>
						Save
					</button>
				</div>
			</form>
		</div>
	);
};

export default Profile;
