import {
	CLEAR_TRACKED_EVENT_DATA,
	SET_SYNCED_EVENTS_DATA,
	SET_TRACKED_EVENT_DATA,
} from "../eventTrackingActionTypes";

export const setTrackedEventsData = (payload) => {
	return {type: SET_TRACKED_EVENT_DATA, payload};
};

export const clearTrackedEventsData = () => {
	return {type: CLEAR_TRACKED_EVENT_DATA};
};

export const setSyncedTrackedData = (payload) => {
	return {type: SET_SYNCED_EVENTS_DATA, payload};
};
