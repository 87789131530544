import {useEffect, useMemo, useRef, useState} from "react";
import {useSelector} from "react-redux";
import classNames from "classnames";

import {ChevronIcon} from "assets/icons";
import {Loader} from "components";
import {AnyEvent} from "hooks/types";
import UserPlansDropDown from "mapx-components/Modals/UserPlansDropdown";
import {updatingUsersSelector, userOrganisationRoleSelector} from "store/mapx/user/userSelectors";
import {organisationHasPaidPlanSelector} from "store/mapx/organisation/organisationSelectors";
import type {TUserPlans} from "mapx-pages/Organisation/types";

import styles from "./UserPlans.module.scss";

const UserPlans = ({user}: TUserPlans) => {
	const ref = useRef<HTMLDivElement | null>(null);

	const [showDropdown, setShowDropdown] = useState(false);

	const updatingUsers = useSelector(updatingUsersSelector);
	const organisationRole = useSelector(userOrganisationRoleSelector);

	const organisationHasPaidPlan = useSelector(organisationHasPaidPlanSelector);

	const updatingUser = useMemo(() => updatingUsers.includes(user.id), [updatingUsers, user]);

	const licenseName = user.license?.type?.name || "None";
	const disabled =
		organisationRole !== "Admin" ||
		!organisationHasPaidPlan ||
		updatingUser ||
		licenseName === "Prospect" ||
		licenseName === "Enterprise" ||
		licenseName === "Pro";

	useEffect(() => {
		const listener = (event: AnyEvent) => {
			if (ref?.current?.contains(event.target as Node)) {
				return;
			}
			setShowDropdown(false);
		};

		document.addEventListener("mousedown", listener);
		document.addEventListener("touchstart", listener);

		return () => {
			document.removeEventListener("mousedown", listener);
			document.removeEventListener("touchstart", listener);
		};
	}, []);

	return (
		<div
			ref={ref}
			role="button"
			className={classNames(styles.plans, {[styles.disabled]: disabled})}
			onClick={() => setShowDropdown(!showDropdown)}
		>
			<div
				className={classNames(styles.dropdown, {
					[styles.upgraded]: licenseName !== "Starter" && licenseName !== "None",
					[styles.expanded]: showDropdown,
				})}
			>
				<div className={styles.planText}>{licenseName}</div>
				{updatingUser ? (
					<Loader height={16} width={16} type="TailSpin" color="#0c5850" />
				) : (
					<>{!disabled && <ChevronIcon width={24} height={24} />}</>
				)}
			</div>

			{showDropdown && <UserPlansDropDown user={user} />}
		</div>
	);
};

export default UserPlans;
