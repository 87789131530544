import {useDispatch, useSelector} from "react-redux";
import {useCallback, type FC} from "react";

import {Button} from "components";
import {ArrowCircleUpIcon} from "assets/icons";
import {contactAdminModal, pricingTableModal} from "store/mapx/payment/paymentActions";
import {userOrganisationRoleSelector} from "store/mapx/user/userSelectors";

import styles from "./UserPreloadInsightSkeleton.module.scss";
import {
	organisationAdminSelector,
	organisationHasPaidPlanSelector,
} from "store/mapx/organisation/organisationSelectors";
import type {TUpgradeStepProps} from "./types";

const RightIcon = () => <span className={styles.upgrade__plus}>PLUS</span>;

const UpgradeStep: FC<TUpgradeStepProps> = ({hideText = false, ...rest}) => {
	const dispatch = useDispatch();

	const organisationRole = useSelector(userOrganisationRoleSelector);
	const admin = useSelector(organisationAdminSelector);
	const organisationHasPaidPlan = useSelector(organisationHasPaidPlanSelector);

	const handleUpgrade = useCallback(() => {
		if (!organisationHasPaidPlan && (admin == null || organisationRole === "Admin")) {
			dispatch(pricingTableModal(true));
		} else {
			dispatch(contactAdminModal(true));
		}
	}, [admin, dispatch, organisationHasPaidPlan, organisationRole]);

	return (
		<div className={styles.upgrade} {...rest}>
			{!hideText && (
				<span className={styles.upgrade__text}>
					Upgrade Now to Get Insights from your Project
				</span>
			)}

			<Button
				className={styles.upgrade__button}
				leftIconClasses={styles.upgrade__leftIcon}
				LeftIcon={ArrowCircleUpIcon}
				RightIcon={RightIcon}
				onClick={handleUpgrade}
			>
				Upgrade to
			</Button>
		</div>
	);
};

export default UpgradeStep;
