import {Mixpanel} from "helpers/mixpanel";
import {useEffect, useLayoutEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import useAuthRedirect from "./useAuthRedirect";
import useFormValidation from "./useFormValidation";
import {
	loginUser,
	registerUser,
	resetPassword,
	verifyEmailAccount,
} from "store/mapx/user/userAsyncAction";
import Loader from "components/Plugins/Loader";
import {useLocation} from "react-router-dom";

const initialUserData = {
	email: "",
	password: "",
};
const initialValidation = {
	email: {valid: false},
	password: {valid: false},
};

export const useOnAuth = ({
	history,
	type = "login",
	userInitialData = null,
	userInitialValidation = null,
	redirectUrl = "/home",
	themeColor = "#0c5850",
	registerInvitationCode = "",
	registerVerificationCode = "",
}) => {
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);
	const user = useSelector((state) => state.user.user); // fetchUserInProgress removed from const
	const [error, setError] = useState("");
	const [message, setMessage] = useState("");
	const urlSearchParams = new URLSearchParams(window.location.search);
	const redirectTo = Object.fromEntries(urlSearchParams.entries())?.redirectTo || null;

	const [userData, validation, changeForm] = useFormValidation(
		userInitialData || initialUserData,
		userInitialValidation || initialValidation,
	);

	// for handling memory leak
	const unmounted = useRef(false);

	useEffect(() => {
		return () => {
			unmounted.current = true;
		};
	}, []);

	useAuthRedirect(user, history, redirectTo || redirectUrl);

	const {search} = useLocation();

	const onSubmit = async (values) => {
		const finalValues = values || userData;

		if (!submitDisabled && !values) return;
		setError("");
		setMessage("");

		let res;

		setLoading(true);

		if (type === "forgot-password") {
			res = await dispatch(verifyEmailAccount(finalValues));

			if (res && !res?.error) {
				setMessage("E-mail has been sent.");
			}
		} else if (type === "reset-password") {
			const verificationCode = new URLSearchParams(search).get("code");

			res = await dispatch(resetPassword(verificationCode, finalValues));

			if (res && !res?.error) {
				setMessage(
					"Password updated successfully. You will be redirected to login page...",
				);

				setTimeout(() => {
					history.push(redirectTo || redirectUrl);
				}, 2000);
			}
		} else if (type === "accept-invitation") {
			const finalObject = {
				first_name: finalValues.name,
				invitation_code: registerInvitationCode,
				last_name: finalValues.lastName,
				password: finalValues.password,
			};

			Mixpanel.track(`Signup: entered required information`, {
				pageTitle: "Signup Page",
				code: registerInvitationCode,
				description: {
					first_name: finalValues.name,
					invitation_code: registerInvitationCode,
					last_name: finalValues.lastName,
				},
				location: window.location.pathname,
			});

			res = await dispatch(registerUser(finalObject));

			if (res && !res?.error) {
				setMessage("Registered successfully. Redirecting you to the project page...");

				Mixpanel.track(`Signup: successful registration`, {
					pageTitle: "Signup Page",
					code: registerInvitationCode,
					description: {
						first_name: finalValues.name,
						last_name: finalValues.lastName,
						invitation_code: registerInvitationCode,
					},
					location: window.location.pathname,
				});

				setTimeout(() => {
					history.push(redirectTo || redirectUrl);
				}, 2000);
			} else {
				Mixpanel.track(`Signup: error in registration`, {
					pageTitle: "Signup Page",
					code: registerInvitationCode,
					description: {
						first_name: finalValues.name,
						last_name: finalValues.lastName,
						invitation_code: registerInvitationCode,
					},
					error: res?.error,
					location: window.location.pathname,
				});
			}
		} else if (type === "register") {
			const finalObject = {
				first_name: finalValues.name,
				verification_code: registerVerificationCode,
				job_title: finalValues.jobTitle,
				last_name: finalValues.lastName,
				password: finalValues.password,
			};

			Mixpanel.track(`Signup: entered required information`, {
				pageTitle: "Signup Page",
				code: registerInvitationCode,
				description: {
					first_name: finalValues.name,
					invitation_code: registerInvitationCode,
					last_name: finalValues.lastName,
					job_title: finalValues.jobTitle,
				},
				location: window.location.pathname,
			});

			res = await dispatch(registerUser(finalObject));

			if (res && !res?.error) {
				setMessage("Registered successfully. Redirecting you to the project page...");

				Mixpanel.track(`Signup: successful registration`, {
					pageTitle: "Signup Page",
					code: registerInvitationCode,
					description: {
						first_name: finalValues.name,
						last_name: finalValues.lastName,
						invitation_code: registerInvitationCode,
					},
					location: window.location.pathname,
				});
			} else {
				Mixpanel.track(`Signup: error in registration`, {
					pageTitle: "Signup Page",
					code: registerInvitationCode,
					description: {
						first_name: finalValues.name,
						last_name: finalValues.lastName,
						invitation_code: registerInvitationCode,
					},
					error: res?.error,
					location: window.location.pathname,
				});
			}
		} else {
			res = await dispatch(loginUser(finalValues));
		}

		if (!unmounted.current) {
			setLoading(false);
		}

		if (res.error) {
			setError(res.error);
			setMessage("");
		}

		setLoading(false);
	};

	const LoaderComponent = <Loader type={"Rings"} height={40} width={40} color={themeColor} />;

	const [autoFilled, setAutofilled] = useState(false);

	useLayoutEffect(() => {
		/**
		 * The field can be prefilled on the very first page loading by the browser
		 * By the security reason browser limits access to the field value from JS level and the value becomes available
		 * only after first user interaction with the page
		 * it actually can have some value, so we should process this edge case in the form logic
		 */

		const checkAutofilled = () => {
			const hasValue =
				!!document.getElementById("email")?.matches("*:-webkit-autofill") &&
				!!document.getElementById("password")?.matches("*:-webkit-autofill");
			setAutofilled(hasValue);
		};

		// The time when it's ready is not very stable, so check few times
		setTimeout(() => {
			checkAutofilled();
		}, 500);
	}, []);

	const isDisabled = () => {
		const isEmailValid = validation?.email?.valid || autoFilled;
		const isPasswordValid = validation.password
			? validation.password.valid || autoFilled
			: false;

		if (type === "forgot-password") {
			return isEmailValid;
		} else if (type === "reset-password") {
			return validation.password.valid && validation.conf_password.valid;
		} else if (type === "register") {
			return (
				validation.email.valid &&
				validation.first_name.valid &&
				validation.last_name.valid &&
				validation.password.valid &&
				validation.conf_password.valid
			);
		} else {
			return isEmailValid && isPasswordValid;
		}
	};

	const submitDisabled = isDisabled();

	return {
		loading,
		error,
		changeForm,
		onSubmit,
		LoaderComponent,
		submitDisabled,
		validation,
		message,
	};
};
