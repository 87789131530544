import {Tag} from "components";
import {useAppDispatch, useAppSelector} from "hooks";
import FilterTags from "mapx-components/FilterTags";
import React, {DragEvent, useCallback} from "react";
import {companySpecialtyClusterByLogicSelector} from "store/mapx/filter/companySpecialtyCLusterSelectors";
import {TSpecialtyClustersTagProps} from "./types";
import {
	moveSpecialtyClustersForCompanies,
	setSpecialityClustersForCompany,
} from "store/mapx/filter/companySpecialtyClusterFilterAsyncActions";

function Tags({options, tagLabel, tagValue}: TSpecialtyClustersTagProps) {
	const dispatch = useAppDispatch();

	const selectedIds =
		useAppSelector((state) => companySpecialtyClusterByLogicSelector(state, tagValue)) || [];

	const handleTagClick = useCallback(
		(id: number) => {
			dispatch(setSpecialityClustersForCompany([id], tagValue));
		},
		[dispatch, tagValue],
	);

	const handleTagDragStart = useCallback(
		(e: React.DragEvent, id: number) => {
			e.dataTransfer.setData("id", id.toString());
			e.dataTransfer.setData("from", tagValue);
		},
		[tagValue],
	);

	const handleDrop = useCallback(
		(e: DragEvent<HTMLDivElement>) => {
			const id = parseInt(e.dataTransfer.getData("id"), 10);
			const from = e.dataTransfer.getData("from");

			dispatch(
				moveSpecialtyClustersForCompanies({
					from,
					to: tagValue,
					ids: [id],
				}),
			);
		},
		[dispatch, tagValue],
	);

	const label = (id: number) => {
		return options.find((item) => item?.id === id)?.name;
	};

	return (
		<FilterTags
			onDrop={handleDrop}
			label={tagLabel}
			count={selectedIds?.length}
			tags={selectedIds?.map((id: number) => (
				<Tag
					key={id}
					onClick={() => handleTagClick(id)}
					draggable
					onDragStart={(e) => handleTagDragStart(e, id)}
					onDragOver={(e) => e.preventDefault()}
				>
					{label(id) || ""}
				</Tag>
			))}
		/>
	);
}

export default React.memo(Tags);
