import type {FC} from "react";
import {useEffect, useMemo, useState} from "react";
import classNames from "classnames";
import colors from "styles/themes.module.scss";
import {useAppDispatch, useAppSelector, useModalHook} from "hooks";
import type {TSearchByLinkedInProps, TState} from "./types";
import styles from "./searchByLinkedIn.module.scss";
import Form from "./Form";
import LinkedInBulkImportReport from "mapx-components/Modals/SearchByLinkedInModal/Report";
import {projectSelector} from "store/mapx/project-list/projectListSelectors";
import Papa from "papaparse";
import {sortUrls} from "../../AddPeopleByLinkedIn/util";
import {setShouldOpenBulkImportReport} from "store/mapx/linkedin-bulk-import/linkedInBulkImportActions";
import {TLinkedInUrlImportItem} from "mapx-components/AddPeopleByLinkedIn/types";
import {STATUS_MESSAGES} from "./Report/utils";
import {
	backgroundTaskApiParameterOptions,
	STBackgroundTaskResponse,
} from "../../../api/backgroundTaskApi/types";
import mapXBackgroundTaskAPI from "api/backgroundTaskApi";
import {successResponse} from "helpers/map";
import {setBackgroundBulkImportsInProgress} from "store/mapx/background-tasks/backgroundTaskActions";
import {handleCompletedImports} from "store/mapx/background-tasks/backgroundTaskAsyncActions";

const SearchByLinkedInModal: FC<TSearchByLinkedInProps> = ({
	setModalDisplay,
	modalDisplay = true,
	onSubmit,
	inProgress,
	onSuccessClose,
	linkedInUrl,
	linkedInModalTestId = "",
	initialState = "link-submit",
	source,
	renderLastCompletedReport,
	lastCompletedReportData,
	isLastBulkImportDataLoading,
	importPercentage,
	lastCompletedImportID,
	otherImportInstancePresent,
	shouldOpenReportModal,
}) => {
	const {modalIsOpen, customStyles, Modal} = useModalHook(
		{content: {width: "550px", borderRadius: "8px"}},
		modalDisplay,
		colors.mainThemeColor,
	);

	const [state, setState] = useState<TState>("link-submit");

	const [loadingImportHistoryDownload, setLoadingImportHistoryDownload] = useState(false);

	const dispatch = useAppDispatch();

	const project = useAppSelector(projectSelector);

	const countUrls = (urls: TLinkedInUrlImportItem[]): number => {
		return urls?.length;
	};

	const closeModal = () => {
		setModalDisplay(false);
	};

	useEffect(() => {
		setState(initialState);
	}, [initialState]);

	const handleSubmit = async (url: string[]) => {
		const response: TState = await onSubmit(url);
		setState(response);
	};

	const handleDoneClick = (projectID: number) => {
		onSuccessClose(projectID);
		setState("link-submit");
	};

	useEffect(() => {
		if (shouldOpenReportModal && state === "success") {
			setModalDisplay(true);
			if (source === "project") {
				dispatch(setShouldOpenBulkImportReport({id: project?.id, value: false}));
			} else {
				dispatch(setShouldOpenBulkImportReport({id: 0, value: false}));
			}
		}
	}, [shouldOpenReportModal, setModalDisplay, state, dispatch, project, source]);

	const totalCompletedUrls = useMemo(() => {
		return countUrls(lastCompletedReportData);
	}, [lastCompletedReportData]);

	const getStatusMessage = (status: string) => STATUS_MESSAGES[status.toLowerCase()] || status;

	const getReasonMessage = (status: string): string => {
		const lowerStatus = getStatusMessage(status).toLowerCase();
		if (lowerStatus === "bad url") {
			return "Not a valid LinkedIn candidate URL";
		} else if (lowerStatus === "not found") {
			return "This profile was not found";
		} else if (lowerStatus === "sent for manual import") {
			return "This profile has been sent for manual import. If it is a valid URL, it will be visible on MapX within 12 hours";
		}

		return "";
	};

	const downloadReport = async (linkedInUrls?: Nullable<TLinkedInUrlImportItem[]>) => {
		let toBeDownloadedData = linkedInUrls;

		if (!toBeDownloadedData) {
			setLoadingImportHistoryDownload(true);

			const payload: backgroundTaskApiParameterOptions = {
				projectId: project?.id,
				status: ["Completed"],
				backgroundTaskType: ["Candidates Bulk Import"],
			};

			const pagination = {
				page: 1,
				perPage: 1000,
			};

			try {
				const response = await mapXBackgroundTaskAPI.getAllBackgroundTaskRequests(
					payload,
					pagination,
					{},
				);

				if (successResponse(response, 200)) {
					const bulkImportData: STBackgroundTaskResponse[] = response.data.results;

					const res = await dispatch(
						handleCompletedImports("download", bulkImportData, project),
					);

					toBeDownloadedData = sortUrls(res.importInfo.linkedin_candidate_urls);
				} else {
					console.error("Error fetching background bulk imports:");
				}
			} catch (error) {
				console.error("Error fetching background bulk imports:", error);
				dispatch(setBackgroundBulkImportsInProgress(false));
			}

			setLoadingImportHistoryDownload(false);
		}

		if (!toBeDownloadedData) {
			return;
		}

		// Helper function to generate a sanitized filename
		const generateFileName = (projectName: string) => {
			const date = new Date().toLocaleDateString();

			if (projectName) {
				return (
					projectName?.replace(/[.]/g, "_").replace(/[\s,]/g, "_") +
					"_" +
					date.replace(/[\s,]/g, "_")
				);
			} else {
				return "Candidate_import" + "_" + date.replace(/[\s,]/g, "_");
			}
		};

		// Process the data for CSV
		const csvData = toBeDownloadedData.map((item) => ({
			linkedin_url: item.url,
			status: getStatusMessage(item.status),
			reason: getReasonMessage(item.status),
		}));

		// Convert to CSV and trigger download
		const csv = Papa.unparse(csvData);
		const blob = new Blob([csv], {type: "text/csv;charset=utf-8;"});
		const url = URL.createObjectURL(blob);
		const a = document.createElement("a");
		a.setAttribute("href", url);
		a.setAttribute("download", generateFileName(project?.name));
		a.click();
	};

	return (
		<Modal
			data-testid={linkedInModalTestId}
			style={customStyles}
			isOpen={modalIsOpen}
			contentLabel="Modal"
			onRequestClose={closeModal}
		>
			<div
				data-testid="searchByLinkedinComponent"
				className={classNames("modal", styles.wrapper)}
			>
				{renderLastCompletedReport ? (
					<LinkedInBulkImportReport
						totalURLs={totalCompletedUrls}
						linkedInUrl={lastCompletedReportData}
						handleDownload={(urls) => downloadReport(urls)}
						inProgress={true}
						lastCompletedDisplay={true}
						isLastBulkImportDataLoading={isLastBulkImportDataLoading}
						importPercentage={importPercentage}
						loadingImportHistoryDownload={loadingImportHistoryDownload}
						handleClick={() => handleDoneClick(lastCompletedImportID)}
						importedLinkedInUrls={lastCompletedReportData}
						source={source}
					/>
				) : !inProgress ? (
					<Form
						handleSubmit={handleSubmit}
						handleDownload={(urls) => downloadReport(urls)}
						inProgress={inProgress}
						otherImportInstancePresent={otherImportInstancePresent}
						linkedInUrl={linkedInUrl}
						loadingImportHistoryDownload={loadingImportHistoryDownload}
						source={source}
						importedLinkedInUrls={lastCompletedReportData}
					/>
				) : (
					linkedInUrl && (
						<LinkedInBulkImportReport
							totalURLs={countUrls(linkedInUrl)}
							linkedInUrl={sortUrls(linkedInUrl)}
							handleDownload={(urls) => downloadReport(urls)}
							lastCompletedDisplay={false}
							isLastBulkImportDataLoading={isLastBulkImportDataLoading}
							inProgress={inProgress && countUrls(linkedInUrl) > 0}
							importPercentage={importPercentage}
							loadingImportHistoryDownload={loadingImportHistoryDownload}
							handleClick={() =>
								handleDoneClick(source === "project" ? project?.id : 0)
							}
							importedLinkedInUrls={lastCompletedReportData}
							source={source}
						/>
					)
				)}
			</div>
		</Modal>
	);
};

export default SearchByLinkedInModal;
