import {IconProps} from "./types";

const InsightsIcon = ({className = "", fill = "#D9D9D9"}: IconProps) => (
	<svg
		width="20"
		height="20"
		viewBox="0 0 20 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		className={className}
	>
		<mask
			id="mask0_594_15797"
			style={{maskType: "alpha"}}
			maskUnits="userSpaceOnUse"
			x="0"
			y="0"
			width="20"
			height="20"
		>
			<rect width="20" height="20" fill={fill} />
		</mask>
		<g mask="url(#mask0_594_15797)">
			<path
				d="M15 15C15.4167 15 15.7708 14.8542 16.0625 14.5625C16.3542 14.2708 16.5 13.9167 16.5 13.5C16.5 13.0833 16.3542 12.7292 16.0625 12.4375C15.7708 12.1458 15.4167 12 15 12C14.5833 12 14.2292 12.1458 13.9375 12.4375C13.6458 12.7292 13.5 13.0833 13.5 13.5C13.5 13.9167 13.6458 14.2708 13.9375 14.5625C14.2292 14.8542 14.5833 15 15 15ZM15.0048 18C15.5294 18 16.0174 17.8681 16.4688 17.6042C16.9201 17.3403 17.2847 16.9861 17.5625 16.5417C17.1875 16.2778 16.7804 16.0799 16.3413 15.9479C15.9022 15.816 15.4543 15.75 14.9975 15.75C14.5408 15.75 14.0938 15.816 13.6562 15.9479C13.2188 16.0799 12.8125 16.2778 12.4375 16.5417C12.7153 16.9861 13.0815 17.3403 13.5361 17.6042C13.9907 17.8681 14.4803 18 15.0048 18ZM4.5 17C4.0875 17 3.73437 16.8531 3.44062 16.5594C3.14687 16.2656 3 15.9125 3 15.5V4.5C3 4.0875 3.14687 3.73438 3.44062 3.44063C3.73437 3.14688 4.0875 3 4.5 3H15.5C15.9125 3 16.2656 3.14688 16.5594 3.44063C16.8531 3.73438 17 4.0875 17 4.5V9.875C16.7625 9.795 16.5188 9.72611 16.2688 9.66833C16.0188 9.61056 15.7625 9.56833 15.5 9.54167V4.5H4.5V15.5H9.52083C9.54688 15.7711 9.58811 16.0298 9.64454 16.2762C9.70096 16.5226 9.77083 16.7639 9.85417 17H4.5ZM4.5 15.5V4.5V9.54167V9.5V15.5ZM6 14H9.58333C9.63889 13.7361 9.70965 13.4794 9.79563 13.2299C9.88161 12.9803 9.97751 12.7371 10.0833 12.5H6V14ZM6 10.75H11.5C11.8611 10.4722 12.25 10.2326 12.6667 10.0312C13.0833 9.82986 13.5278 9.6875 14 9.60417V9.25H6V10.75ZM6 7.5H14V6H6V7.5ZM14.9953 19C13.8873 19 12.9444 18.6095 12.1667 17.8286C11.3889 17.0477 11 16.1033 11 14.9953C11 13.8873 11.3905 12.9444 12.1714 12.1667C12.9523 11.3889 13.8967 11 15.0047 11C16.1127 11 17.0556 11.3905 17.8333 12.1714C18.6111 12.9523 19 13.8967 19 15.0047C19 16.1127 18.6095 17.0556 17.8286 17.8333C17.0477 18.6111 16.1033 19 14.9953 19Z"
				fill="#868D8D"
			/>
		</g>
	</svg>
);

export default InsightsIcon;
