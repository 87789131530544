import {Checkbox, ResetFilters} from "components";

import {SearchInput} from "mapx-components";
import styles from "mapx-components/Filters/KeywordSearchFilter/styles.module.scss";
import React from "react";
import {useSelector} from "react-redux";
import {companyTypeOptionSelector} from "store/mapx/search/searchSelectors";
import Tags from "./Tags";
import {TCandidateCompanyTypeSearchFilterProps} from "./types";
import useFetchListOptions from "hooks/useFetchListOptions";
import {getCompanyTypeList} from "store/mapx/search/searchAsyncActions";
import {STCompanyType} from "api/companyApi/types";

const CompanyTypeSearchFilter = ({
	selectedCompanyTypes,
	handleResetClick,
	handleOnChange,
	handleTagClick,
	displayResetFilterOption,
	moveCompanyTypeTagPosition,
	selectedCompanyTypeTags,
	clearTagsByPosition,
}: TCandidateCompanyTypeSearchFilterProps) => {
	const options: STCompanyType[] = useSelector(companyTypeOptionSelector);

	const {filteredData, onFilterChanged, loading} = useFetchListOptions({
		options: options,
		callbackMethod: getCompanyTypeList,
	});

	return (
		<div>
			<div className={styles.searchInputWrapper}>
				<SearchInput
					onChange={onFilterChanged}
					placeholder="Search for a Company Type"
					type="text"
					errorText={undefined}
					errorClass={undefined}
					isLoading={loading}
				/>
			</div>

			{displayResetFilterOption && (
				<ResetFilters
					parentStyle={{color: "#5A5A5A", marginRight: 19}}
					onClick={handleResetClick}
					displayIcon={true}
				>
					Clear Selection
				</ResetFilters>
			)}

			<div className={styles.checkboxList}>
				{filteredData.map((s: STCompanyType) => {
					return (
						<Checkbox
							borderColor="#0C5850"
							containerClass={styles.checkboxContainer}
							isChecked={selectedCompanyTypes.includes(s.id)}
							key={s.id}
							label={`${s.name}`}
							onChange={() => handleOnChange(s.id)}
							value={s.name}
						/>
					);
				})}
			</div>

			<div style={{display: "flex", flexDirection: "column", gap: 8, marginTop: 8}}>
				<Tags
					key={"current"}
					tagLabel={"Current"}
					tagValue={"current"}
					handleTagClick={handleTagClick}
					tags={selectedCompanyTypeTags.current}
					moveCompanyTypeTagPosition={moveCompanyTypeTagPosition}
					clearTagsByPosition={clearTagsByPosition}
				/>

				<Tags
					key={"previous"}
					tagLabel={"Previous"}
					tagValue={"previous"}
					handleTagClick={handleTagClick}
					tags={selectedCompanyTypeTags.previous}
					moveCompanyTypeTagPosition={moveCompanyTypeTagPosition}
					clearTagsByPosition={clearTagsByPosition}
				/>

				<Tags
					key={"any"}
					tagLabel={"Any"}
					tagValue={"any"}
					handleTagClick={handleTagClick}
					tags={selectedCompanyTypeTags.any}
					moveCompanyTypeTagPosition={moveCompanyTypeTagPosition}
					clearTagsByPosition={clearTagsByPosition}
				/>
			</div>
		</div>
	);
};

export default CompanyTypeSearchFilter;
