import type {FC} from "react";
import classNames from "classnames";

import type {TSectionWrapperProps} from "./types";
import styles from "./SectionWrapper.module.scss";

const SectionWrapper: FC<TSectionWrapperProps> = ({
	label,
	children,
	className,
	childrenWrapperStyle,
	childrenWrapperClassName,
}) => (
	<div className={classNames(styles.sectionWrapper, className)}>
		<div className={styles.labelWrapper}>{label}</div>
		<div
			className={classNames(styles.childrenWrapper, childrenWrapperClassName)}
			style={childrenWrapperStyle}
		>
			{children}
		</div>
	</div>
);
export default SectionWrapper;
