import {
	ADD_SEARCH_REQUEST_TO_THE_LIST,
	CLEAR_ACTIVE_SEARCH_REQUEST,
	CLEAR_AP_ACTIVE_RESULT,
	CLEAR_AP_FILTER,
	CLEAR_AP_WORKFLOW_STATE,
	CLEAR_PR_FILTER,
	DISPLAY_AP_RESULTS,
	DISPLAY_REFRESH_AP_RESULT,
	JOB_TITLE_SUGGESTIONS_LOADING,
	PROJECT_RESULTS_TAB_ACTIVE_CONTENT,
	REMOVE_CURRENT_SEARCH_REQUEST_ON_PW,
	SEARCH_REQUEST_DETAILS_LOADING,
	SET_ACTIVE_SEARCH_REQUEST,
	SET_ALL_SEARCH_REQUESTS,
	SET_AP_ACTIVE_RESULT,
	SET_AP_COMPANY_POSITION,
	SET_AP_COMPANY_TL_POSITION,
	SET_AP_COMPANY_TYPE_FILTER_CONFIG,
	SET_AP_FILTER,
	SET_AP_HEADCOUNT_FILTER_CONFIG,
	SET_AP_JOB_FUNCTION_FILTER_CONFIG,
	SET_AP_KEYWORD_FILTER_CONFIG,
	SET_AP_MORE_RESULT,
	SET_AP_MULTIPLE_FILTERS,
	SET_AP_REVENUE_FILTER_CONFIG,
	SET_MORE_REJECTED_PROFILES_RESULT,
	SET_MULTIPLE_FILTER_FOR_AP_CANDIDATE,
	SET_PR_FILTER,
	SET_PR_FILTERS,
	SET_REJECTED_PROFILES_RESULT,
} from "./additionalProfilesActionTypes";
import {
	SET_AP_CANDIDATE_TARGETLIST_TOGGLE,
	SET_AP_LOCATIONS_FILTER_CONFIG,
} from "../mapXActionTypes";

export const toggleJobTitleSuggestionLoading = (payload) => {
	return {type: JOB_TITLE_SUGGESTIONS_LOADING, payload};
};
export const setPRFilter = (payload) => {
	return {type: SET_PR_FILTER, payload};
};

export const setPRFilters = (payload) => {
	return {type: SET_PR_FILTERS, payload};
};

export const clearPRFilters = () => {
	return {type: CLEAR_PR_FILTER};
};

export function setAPJobFunctionFilterConfig(payload) {
	return {type: SET_AP_JOB_FUNCTION_FILTER_CONFIG, payload};
}

export function setAPKeywordFilterConfig(payload) {
	return {type: SET_AP_KEYWORD_FILTER_CONFIG, payload};
}

export function setAPCompanyHeadcountFilterConfig(payload) {
	return {type: SET_AP_HEADCOUNT_FILTER_CONFIG, payload};
}

export function setAPCompanyRevenueFilterConfig(payload) {
	return {type: SET_AP_REVENUE_FILTER_CONFIG, payload};
}

export function setAPCompanyTypeFilterConfig(payload) {
	return {type: SET_AP_COMPANY_TYPE_FILTER_CONFIG, payload};
}

export const setAPFilter = (payload) => {
	return {type: SET_AP_FILTER, payload};
};

export const setAPMultipleFilters = (payload) => {
	return {type: SET_AP_MULTIPLE_FILTERS, payload};
};

export function setMultipleFilterForAPCandidates(payload) {
	return {type: SET_MULTIPLE_FILTER_FOR_AP_CANDIDATE, payload};
}

export const clearAPFilters = () => {
	return {type: CLEAR_AP_FILTER};
};

export const displayAPResults = (payload) => {
	return {type: DISPLAY_AP_RESULTS, payload};
};

export const setAPActiveResult = (payload) => {
	return {type: SET_AP_ACTIVE_RESULT, payload};
};

export const setAPMoreResult = (payload) => {
	return {type: SET_AP_MORE_RESULT, payload};
};

export const clearAPActiveResult = () => {
	return {type: CLEAR_AP_ACTIVE_RESULT};
};

// SWITCHING PROJECTS AND RESET THE OLD STATE

export const clearAPWorkflowState = () => {
	return {type: CLEAR_AP_WORKFLOW_STATE};
};
export const setProjectResultsTabActiveContent = (payload) => {
	return {type: PROJECT_RESULTS_TAB_ACTIVE_CONTENT, payload};
};

// REJECTED PROFILES ACTIONS

export const toggleDisplayRefreshAPResult = (payload) => {
	return {type: DISPLAY_REFRESH_AP_RESULT, payload};
};

export const setAPRejectedProfilesResult = (objectPayload) => {
	return {type: SET_REJECTED_PROFILES_RESULT, payload: objectPayload};
};

export const setAPMoreRejectedProfilesResult = (payload) => {
	return {type: SET_MORE_REJECTED_PROFILES_RESULT, payload};
};

export const setAPCompanyPosition = (payload) => {
	return {type: SET_AP_COMPANY_POSITION, payload};
};
export const setAPCompanyTLPosition = (payload) => {
	return {type: SET_AP_COMPANY_TL_POSITION, payload};
};

export function setAPCandidateTargetListToggle(payload) {
	return {type: SET_AP_CANDIDATE_TARGETLIST_TOGGLE, payload};
}

// SEARCH REQUESTS ACTIONS

export const setAllSearchRequests = (payload) => {
	return {type: SET_ALL_SEARCH_REQUESTS, payload};
};

export const setSearchRequestDetailsLoading = (payload) => {
	return {type: SEARCH_REQUEST_DETAILS_LOADING, payload};
};

export const setActiveSearchRequest = (payload) => {
	return {type: SET_ACTIVE_SEARCH_REQUEST, payload};
};

export const clearActiveSearchRequest = () => {
	return {type: CLEAR_ACTIVE_SEARCH_REQUEST};
};

export const addOrUpdateSearchRequestToTheList = (payload) => {
	return {type: ADD_SEARCH_REQUEST_TO_THE_LIST, payload};
};

export const clearCurrentSearchRequestFromProgressWatch = (payload) => {
	return {type: REMOVE_CURRENT_SEARCH_REQUEST_ON_PW, payload};
};

export function setAPLocationFilterConfig(payload) {
	return {type: SET_AP_LOCATIONS_FILTER_CONFIG, payload};
}
