import React, {useCallback, useEffect, useMemo} from "react";
import {useSelector} from "react-redux";
import classNames from "classnames";
import useInfiniteScroll from "react-infinite-scroll-hook";

import colors from "styles/themes.module.scss";
import {ChevronRightIcon} from "assets/icons";
import {useAppDispatch} from "hooks";
import {Loader} from "components";
import {
	getMarketMapsModalInProgressSelector,
	marketMapModalNameSelector,
	marketMapsModalPaginationSelector,
	marketMapsModalSelector,
} from "store/mapx/market-map/marketMapSelectors";
import {getMarketMapsForModal} from "store/mapx/market-map/marketMapAsyncFunctions";
import {EMPTY_COMPANY_LOGO} from "mapx-constants";
import type {STMarketMap} from "api/marketMapApi/types";

import styles from "./list.module.scss";
import type {TList} from "./types";
import {useDebounce} from "hooks/useDebounce";
import ContentLoader from "react-content-loader";

const List = ({selected, setSelected, search}: TList) => {
	const dispatch = useAppDispatch();

	const marketMaps: STMarketMap[] = useSelector(marketMapsModalSelector);
	const getting = useSelector(getMarketMapsModalInProgressSelector);
	const pagination = useSelector(marketMapsModalPaginationSelector);
	const prevSearch = useSelector(marketMapModalNameSelector);
	const hasMoreItems = pagination.pages > pagination.page;

	const debouncedQuery = useDebounce(search, 800);

	const selectedMarketMap = useMemo(() => {
		return marketMaps.find((map) => map.id === selected);
	}, [marketMaps, selected]);

	const loadMore = useCallback(() => {
		dispatch(getMarketMapsForModal(search));
	}, [dispatch, search]);

	const [sentryRef] = useInfiniteScroll({
		loading: getting && marketMaps.length !== 0,
		hasNextPage: hasMoreItems,
		onLoadMore: loadMore,
	});

	const getSearchedResults = useCallback(
		async (searchString: string) => {
			await dispatch(getMarketMapsForModal(searchString));
		},
		[dispatch],
	);

	useEffect(() => {
		getSearchedResults(debouncedQuery);
	}, [debouncedQuery, getSearchedResults]);

	useEffect(() => {
		if (marketMaps.length === 0) {
			dispatch(getMarketMapsForModal(search));
		}
	}, [dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<div className={styles.list}>
			<div className={styles.left}>
				{marketMaps.length === 0 && search && (
					<div className={styles.emptyResult}>
						{'No results found for "'}
						{<strong>{search}</strong>}
						{'".'}
					</div>
				)}
				{(marketMaps.length === 0 && getting) || (getting && prevSearch !== search) ? (
					<div className={styles.loaderContainer}>
						<ContentLoader
							speed={2}
							height={300}
							width={"100%"}
							backgroundColor="#DCE7E7"
							foregroundColor="#F9F9F9"
						>
							{/* Project Name */}
							<rect x="0" y="10" rx="3" ry="3" width="400" height="20" />
							<rect x="0" y="40" rx="3" ry="3" width="300" height="20" />
							<rect x="0" y="70" rx="3" ry="3" width="350" height="20" />
							<rect x="0" y="100" rx="3" ry="3" width="250" height="20" />
							<rect x="0" y="130" rx="3" ry="3" width="320" height="20" />
							<rect x="0" y="160" rx="3" ry="3" width="220" height="20" />
						</ContentLoader>
					</div>
				) : (
					<>
						{marketMaps.map((map) => (
							<div
								key={map.id}
								className={classNames(styles.map, {
									[styles.active]: selected === map.id,
								})}
								onClick={() => setSelected(map.id)}
							>
								<div className={styles.name}>{map.name}</div>
								<ChevronRightIcon
									color={selected === map.id ? "#404040" : "#CBC5C2"}
									width={20}
									height={20}
								/>
							</div>
						))}
						{!getting && marketMaps.length !== 0 && hasMoreItems && (
							<div style={{height: 2}} ref={sentryRef} />
						)}
						{getting && marketMaps.length > 0 && (
							<div className={styles.infiniteLoaderContainer}>
								<Loader
									height={40}
									width={40}
									type="ThreeDots"
									color={colors.loaderDotColor}
								/>
							</div>
						)}
					</>
				)}
			</div>
			<div className={styles.separator} />
			<div className={styles.right}>
				{selectedMarketMap ? (
					<>
						<div className={styles.header}>
							<div className={styles.title}>{selectedMarketMap.name}</div>
							<div className={styles.count}>{selectedMarketMap.companies.length}</div>
						</div>
						<div className={styles.companies}>
							{selectedMarketMap.companies.map((company) => (
								<div key={company.id.toString()} className={styles.company}>
									<img
										src={company.logo_url || EMPTY_COMPANY_LOGO}
										onError={(e) => {
											e.currentTarget.src = EMPTY_COMPANY_LOGO;
										}}
										className={styles.logo}
									/>
									<div className={styles.name}>{company.name}</div>
								</div>
							))}
						</div>
					</>
				) : (
					<div className={styles.empty}>No target list selected</div>
				)}
			</div>
		</div>
	);
};

export default List;
