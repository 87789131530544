import {useCallback} from "react";
import {Accordion} from "mapx-components";
import {
	setMultipleFilterForCandidates,
	setPeopleSearchCompanyRevenueFilterConfig,
} from "store/mapx/filter/filterActions";
import {useAppDispatch, useAppSelector} from "hooks";
import {filterPositionOptionsSelector} from "store/mapx/filter/filterSelectors";
import {
	TCompanyRevenuePosition,
	TCompanyRevenueRangeItem,
} from "mapx-components/Filters/CompanyRevenueSearchFilter/types";
import CompanyRevenueSearchFilter from "mapx-components/Filters/CompanyRevenueSearchFilter";
import {TabSelect} from "components";
import {TRevenueFilterProps} from "containers/Filters/PeopleFilters/RevenueFilter/types";
import {
	candidateCompanyRevenueByPositionSelector,
	candidateCompanyRevenueFilterActivePositionSelector,
	candidateCompanyRevenueFilterConfigSelector,
	candidatesAllCompanyRevenueCountSelector,
	selectedAnyCompanyRevenueTagsSelector,
	selectedCurrentCompanyRevenueTagsSelector,
	selectedPreviousCompanyRevenueTagsSelector,
} from "store/mapx/filter/companyRevenueFilterSelectors";
import {
	moveRevenueForCandidate,
	setCompanyRevenueForCandidate,
} from "store/mapx/filter/companyRevenueFilterAsyncActions";
import Tags from "mapx-components/Filters/CompanyRevenueSearchFilter/Tags";

const RevenueFilter = ({disabled}: TRevenueFilterProps) => {
	const dispatch = useAppDispatch();

	const filterPositionOptions = useAppSelector(filterPositionOptionsSelector);

	const candidateCompanyRevenueFilterConfig = useAppSelector(
		candidateCompanyRevenueFilterConfigSelector,
	);

	const position = useAppSelector<TCompanyRevenuePosition>(
		candidateCompanyRevenueFilterActivePositionSelector,
	);

	const selectedRevenues = useAppSelector((state) =>
		candidateCompanyRevenueByPositionSelector(state, position),
	);

	const currentRevenueTags = useAppSelector(selectedCurrentCompanyRevenueTagsSelector);

	const previousRevenueTags = useAppSelector(selectedPreviousCompanyRevenueTagsSelector);

	const anyRevenueTags = useAppSelector(selectedAnyCompanyRevenueTagsSelector);

	const revenueCount = useAppSelector(candidatesAllCompanyRevenueCountSelector);

	const handlePositionChange = useCallback(
		(updatedPositionValue: string) => {
			dispatch(
				setPeopleSearchCompanyRevenueFilterConfig({
					...candidateCompanyRevenueFilterConfig,
					active_position: updatedPositionValue,
				}),
			);
		},
		[dispatch, candidateCompanyRevenueFilterConfig],
	);

	const handleResetClick = useCallback(() => {
		dispatch(
			setMultipleFilterForCandidates({
				revenue: [],
				current_revenue: [],
				previous_revenue: [],
			}),
		);
	}, [dispatch]);

	const clearTagsSelectionByPosition = useCallback(
		(position: TCompanyRevenuePosition) => {
			if (position === "current") {
				dispatch(
					setMultipleFilterForCandidates({
						current_revenue: [],
					}),
				);
			} else if (position === "previous") {
				dispatch(
					setMultipleFilterForCandidates({
						previous_revenue: [],
					}),
				);
			} else {
				dispatch(
					setMultipleFilterForCandidates({
						revenue: [],
					}),
				);
			}
		},
		[dispatch],
	);

	const handleOnChange = useCallback(
		(revenueItem: TCompanyRevenueRangeItem) => {
			dispatch(setCompanyRevenueForCandidate({position, revenueItem}));
		},
		[dispatch, position],
	);

	const moveRevenueTagPosition = useCallback(
		(id: string, from: TCompanyRevenuePosition, to: TCompanyRevenuePosition) => {
			dispatch(
				moveRevenueForCandidate({
					from,
					to,
					ids: [id],
				}),
			);
		},
		[dispatch],
	);

	return (
		<Accordion title="Company Revenue" subtitle={revenueCount ?? ""} disabled={disabled}>
			<TabSelect.LabelContainer label="Position">
				<TabSelect
					selected={position}
					onTabChange={handlePositionChange}
					options={filterPositionOptions}
				/>
			</TabSelect.LabelContainer>

			<CompanyRevenueSearchFilter
				handleOnChange={handleOnChange}
				handleResetClick={handleResetClick}
				selectedRevenues={selectedRevenues}
			/>

			{revenueCount > 0 && (
				<>
					<Tags
						tagLabel={"Current"}
						tagValue={"current"}
						tags={currentRevenueTags}
						handleTagClick={handleOnChange}
						clearTagsByPosition={clearTagsSelectionByPosition}
						moveRevenueTagPosition={moveRevenueTagPosition}
					/>

					<Tags
						tagLabel={"Previous"}
						tagValue={"previous"}
						tags={previousRevenueTags}
						handleTagClick={handleOnChange}
						clearTagsByPosition={clearTagsSelectionByPosition}
						moveRevenueTagPosition={moveRevenueTagPosition}
					/>

					<Tags
						tagLabel={"Any"}
						tagValue={"any"}
						tags={anyRevenueTags}
						handleTagClick={handleOnChange}
						clearTagsByPosition={clearTagsSelectionByPosition}
						moveRevenueTagPosition={moveRevenueTagPosition}
					/>
				</>
			)}
		</Accordion>
	);
};

export default RevenueFilter;
