import {
	setMultipleFilterForCandidates,
	setMultipleFilterForCompany,
} from "store/mapx/filter/filterActions";
import {Dispatch} from "redux";
import {TAppDispatch, TRootState} from "types";
import {
	candidateCompanyTypesByPositionSelector,
	companyTypeFilterKeyByPosition,
	companyTypesSelector,
} from "store/mapx/filter/companyTypeFilterSelectors";
import {TCompanyTypePosition} from "mapx-components/Filters/CandidateCompanyTypeSearchFilter/types";

export const moveCompanyTypeForCandidate =
	({from, to, id}: {from: TCompanyTypePosition; to: TCompanyTypePosition; id: string}) =>
	async (dispatch: Dispatch<TAppDispatch>, getState: () => TRootState) => {
		const idsSet = new Set([id]);

		const state = getState();

		const fromFilterKey = companyTypeFilterKeyByPosition(state, from);

		const toFilterKey = companyTypeFilterKeyByPosition(state, to);

		const fromCompanyTypes = candidateCompanyTypesByPositionSelector(state, from);

		const toCompanyType = candidateCompanyTypesByPositionSelector(state, to);

		dispatch(
			setMultipleFilterForCandidates({
				[fromFilterKey]: fromCompanyTypes.filter((id: string) => !idsSet.has(id)),
				[toFilterKey]: [
					...toCompanyType.filter((id: string) => !idsSet.has(id)), // prevent duplication
					...fromCompanyTypes.filter((id: string) => idsSet.has(id)),
				],
			}),
		);
	};

export const setCompanyTypeForCandidate =
	({position, id}: {position: TCompanyTypePosition; id: string}) =>
	async (dispatch: Dispatch<TAppDispatch>, getState: () => TRootState) => {
		const state = getState();

		const idsOnState = candidateCompanyTypesByPositionSelector(state, position);

		const index = idsOnState.indexOf(id);

		const alreadySelected = index !== -1;

		const nextIds = alreadySelected
			? [...idsOnState.slice(0, index), ...idsOnState.slice(index + 1)]
			: [...idsOnState, id];

		const specialtyFilterKey = companyTypeFilterKeyByPosition(state, position);

		dispatch(setMultipleFilterForCandidates({[specialtyFilterKey]: nextIds}));
	};

// Function to extract and flatten company types
const extractCompanyTypes = (arr: string[]): string[] => {
	const companyTypes: string[] = [];
	const flattenArray = (subArr: string[]): void => {
		subArr.forEach((item) => {
			if (Array.isArray(item)) {
				flattenArray(item);
			} else if (typeof item === "string") {
				companyTypes.push(item);
			}
		});
	};
	flattenArray(arr);

	return [...new Set(companyTypes)];
};

export const setCompanyTypeFilter =
	({value}: {value: string}) =>
	async (dispatch: Dispatch<TAppDispatch>, getState: () => TRootState) => {
		const state = getState();

		const idsOnState = companyTypesSelector(state);
		const index = idsOnState.indexOf(value);
		const alreadySelected = index !== -1;

		const nextValues = alreadySelected
			? [...idsOnState.slice(0, index), ...idsOnState.slice(index + 1)]
			: [...idsOnState, value];

		const companyTypes = extractCompanyTypes(nextValues);

		dispatch(
			setMultipleFilterForCompany({
				company_type: companyTypes,
			}),
		);
	};

export const clearCompanyTypeByPositionForCandidate =
	(position: TCompanyTypePosition) => (dispatch: Dispatch<TAppDispatch>) => {
		let payload;

		switch (position) {
			case "current":
				payload = {
					current_company_type: [],
				};
				break;
			case "previous":
				payload = {
					previous_company_type: [],
				};
				break;
			default:
				payload = {
					company_type: [],
				};
		}

		dispatch(setMultipleFilterForCandidates(payload));
	};
