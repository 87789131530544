import type {IconProps} from "./types";

const LineBottomArrowIcon = (props: IconProps) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={20}
		height={20}
		fill="none"
		viewBox="0 0 21 20"
		{...props}
	>
		<g clipPath="url(#a)">
			<rect width={20} height={20} fill="#CBC5C2" rx={2} transform="matrix(0 1 1 0 .5 0)" />
			<mask
				id="b"
				width={21}
				height={20}
				x={0}
				y={0}
				maskUnits="userSpaceOnUse"
				style={{
					maskType: "alpha",
				}}
			>
				<path fill="#D9D9D9" d="M.5 0v20h20V0z" />
			</mask>
			<g mask="url(#b)">
				<path fill="#404040" d="m15.5 10-4.25 2.542V2h-1.5v10.542L5.5 10l5 8 5-8Z" />
			</g>
		</g>
		<defs>
			<clipPath id="a">
				<rect width={20} height={20} fill="#fff" rx={2} transform="matrix(0 1 1 0 .5 0)" />
			</clipPath>
		</defs>
	</svg>
);

export default LineBottomArrowIcon;
