import type {FC} from "react";

import type {IconProps} from "./types";

const LocationArrow: FC<IconProps> = ({className = ""}) => (
	<svg
		width="32"
		height="32"
		fill="none"
		viewBox="0 0 32 32"
		className={className}
		xmlns="http://www.w3.org/2000/svg"
	>
		<mask
			x="6"
			y="6"
			width="20"
			height="20"
			id="mask0_201_142341"
			maskUnits="userSpaceOnUse"
			style={{maskType: "alpha"}}
		>
			<rect x="6" y="6" width="20" height="20" fill="#D9D9D9" />
		</mask>
		<g mask="url(#mask0_201_142341)">
			<path
				fill="#837D7C"
				d="M10.168 21.8333V16.8333H11.8346V20.1666H15.168V21.8333H10.168ZM20.168 15.1666V11.8333H16.8346V10.1666H21.8346V15.1666H20.168Z"
			/>
		</g>
		<rect x="0.5" y="0.5" width="31" height="31" rx="3.5" stroke="#DAD8D7" />
	</svg>
);

export default LocationArrow;
