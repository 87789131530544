import styles from "./flaggedCandidate.module.scss";
import ContentLoader from "react-content-loader";
import {FC} from "react";

const skeletonItemCount = new Array(20).fill("");

const FlaggedCandidatesSkeletonLoader: FC = () => {
	return (
		<div>
			{skeletonItemCount.map((_, i) => (
				<div className={styles.loaderCell} key={i}>
					<ContentLoader
						height={40}
						width={"1200px"}
						viewBox="0 0 1200 40"
						backgroundColor="#DCE7E7"
						foregroundColor="#F9F9F9"
					>
						<rect x="20px" y="5.2" rx="1" ry="5" width="45px" height="22px" />
						<rect x="70px" y="5.2" rx="1" ry="5" width="45px" height="22px" />
						<rect x="120px" y="5.2" rx="1" ry="5" width="45px" height="22px" />

						<rect x="360px" y="5.2" rx="1" ry="5" width="45px" height="22px" />
						<rect x="410px" y="5.2" rx="1" ry="5" width="45px" height="22px" />
						<rect x="460px" y="5.2" rx="1" ry="5" width="45px" height="22px" />

						<rect x="630px" y="5.2" rx="1" ry="5" width="80px" height="22px" />

						<rect x="770px" y="5.2" rx="1" ry="5" width="80px" height="22px" />

						<rect x="920px" y="5.2" rx="1" ry="5" width="50px" height="22px" />

						<rect x="1115px" y="5.2" rx="1" ry="5" width="20px" height="22px" />
						{/*<rect x="25px" y="25" rx="5" ry="5" width="18px" height="35px"/>*/}
					</ContentLoader>
				</div>
			))}
		</div>
	);
};

export default FlaggedCandidatesSkeletonLoader;
