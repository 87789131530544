import React from "react";
import styles from "mapx-components/SearchRequestResults/ContentBlock/styles.module.scss";
import {TSearchCompletionStatsProps} from "mapx-components/SearchRequestResults/FilterBlock/SidebarTabs/SearchCompletionStats/types";

const SearchCompletionStats = ({
	uniqueUrlsCount,
	uniqueUrlsRejectedCount,
}: Omit<TSearchCompletionStatsProps, "results">) => {
	return (
		<div className={styles.profileRequestStats}>
			<span>
				Profiles found: <b>{uniqueUrlsCount}</b>
			</span>
			<span>
				Profiles filtered: <b>{uniqueUrlsRejectedCount}</b>
			</span>
			{/*<span>*/}
			{/*	SearchRequestResults: <b>{results || results !== "undefined" ? results : "..."}</b>*/}
			{/*</span>*/}
		</div>
	);
};

export default SearchCompletionStats;
