import type {FC} from "react";
import classNames from "classnames";

import {Button} from "components";

import type {TStepHead} from "../types";
import OrderingButton from "../OrderingButton";

import styles from "./SubHeader.module.scss";

const SubHeader: FC<TStepHead> = ({
	onSort,
	creditsUsedKey,
	creditsUsedValue,
	orgUserDataOrdering,
}) => {
	const stepListData = [
		{id: 0, text: "Name", itemKey: "first_name"},
		{id: 1, text: "E-Mail", itemKey: "email"},
		{id: 2, text: "User Type", itemKey: "license_type"},
		{id: 3, text: "Joined", itemKey: "joined_at"},
		{id: 5, text: "End Date", itemKey: "expires_at"},
		{id: 4, text: creditsUsedValue, itemKey: creditsUsedKey},
	];

	const renderSteps = stepListData.map((item, idx) => {
		const orderingKey = orgUserDataOrdering.find((orgUserDataOrderingItem) =>
			orgUserDataOrderingItem.includes(item.itemKey),
		);
		const isDescendingOrder = orderingKey?.includes("-");
		const isAscendingOrder = orderingKey && !isDescendingOrder;

		return (
			<div key={item.id} className={styles.wrapper__btn}>
				<Button
					defaultBtnStyle
					className={classNames(styles.wrapper__btn_text, {
						[styles.wrapper__btn_left]: idx <= 4,
						[styles.wrapper__btn_right]: idx === 5,
					})}
				>
					{item.text}
				</Button>

				<OrderingButton
					onClick={() => onSort(item.itemKey)}
					isAscendingOrder={!!isAscendingOrder}
					isDescendingOrder={!!isDescendingOrder}
				/>
			</div>
		);
	});

	return (
		<div className={styles.wrapper}>
			<div className={styles.wrapper__container}>{renderSteps}</div>

			<div className={styles.wrapper_skeleton} />
		</div>
	);
};

export default SubHeader;
