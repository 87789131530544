import React from "react";

const CollapseIcon = () => {
	return (
		<svg
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<mask
				id="mask0_1733_162950"
				style={{maskType: "alpha"}}
				maskUnits="userSpaceOnUse"
				x="0"
				y="0"
				width="20"
				height="20"
			>
				<rect width="20" height="20" fill="#D9D9D9" />
			</mask>
			<g mask="url(#mask0_1733_162950)">
				<path d="M4.8335 10.75V9.25H15.1668V10.75H4.8335Z" fill="#837D7C" />
			</g>
		</svg>
	);
};

export default CollapseIcon;
