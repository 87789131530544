import {type FC, Fragment, useState} from "react";
import classNames from "classnames";
import {matchPath, NavLink, useLocation} from "react-router-dom";

import type {TNavItemProps} from "./types";
import styles from "./Sidebar.module.scss";
import {ArrowDown} from "assets/icons";

const NavItem: FC<TNavItemProps> = ({name, Icon, path, childPaths, collapsed}) => {
	const [childItemsExpanded, setChildItemsExpanded] = useState<boolean>(false);

	const {pathname} = useLocation();

	return (
		<Fragment>
			{path &&
				Icon &&
				(path === "#" ? (
					<div
						className={classNames(styles.link, styles.mainTitle, {
							[styles.active]: childPaths?.some(
								(i) => i.path && matchPath(i.path, pathname),
							),
						})}
					>
						<div
							className={classNames(styles.navMainItem)}
							onClick={() => setChildItemsExpanded((p) => !p)}
						>
							<Icon className={styles.navItemIcon} />

							{!collapsed && <span className={styles.navItemText}>{name}</span>}

							{!collapsed && (
								<ArrowDown
									style={{
										cursor: "pointer",
										position: "absolute",
										right: "5px",
										top: "10px",
									}}
									className={classNames({
										[styles.arrowReversed]: childItemsExpanded,
									})}
								/>
							)}
						</div>

						{!collapsed &&
							childItemsExpanded &&
							childPaths &&
							Array.isArray(childPaths) &&
							childPaths.length > 0 && (
								<div className={styles.navChildItemsWrapper}>
									{childPaths
										.filter((cp) => cp.path !== undefined)
										.map((childItem) => (
											<NavLink
												key={childItem.path}
												// eslint-disable-next-line @typescript-eslint/ban-ts-comment
												// @ts-ignore
												to={childItem.path}
												className={(isActive) =>
													classNames(styles.navChildItem, {
														[styles.active]: isActive,
													})
												}
											>
												{childItem.name}
											</NavLink>
										))}
								</div>
							)}
					</div>
				) : (
					<NavLink
						to={path}
						className={(isActive) =>
							classNames(styles.link, {[styles.active]: isActive})
						}
					>
						<span
							className={classNames(styles.navItem, {[styles.expanded]: !collapsed})}
						>
							<Icon className={styles.navItemIcon} />

							{!collapsed && <span className={styles.navItemText}>{name}</span>}
						</span>
					</NavLink>
				))}
		</Fragment>
	);
};

export default NavItem;
