import {Checkbox, CheckboxSkeletonLoader, Loader, ResetFilters} from "components";
import {labelCapitalized} from "helpers/string";
import useFetchListPaginateOptions from "hooks/useFetchListPaginateOptions";

import {SearchInput} from "mapx-components";
import styles from "mapx-components/Filters/KeywordSearchFilter/styles.module.scss";
import React, {useCallback} from "react";
import {useSelector} from "react-redux";
import {keywordOptionsSelector} from "store/mapx/search/searchSelectors";
import {TKeywordSearchFilterProps} from "./types";
import {STKeywords} from "api/filterOptionsApi/KeywordApi/types";

const KeywordSearchFilter = ({
	selectedKeywords,
	handleResetClick,
	handleOnChange,
	apiCall,
	keywordSearchQuery,
	keywordPagination,
	setKeywordQueryOnState,
}: TKeywordSearchFilterProps) => {
	const keywordOptions: STKeywords[] = useSelector(keywordOptionsSelector);

	const {loading, onFilterChanged, listInnerRef, page, setPage, data} =
		useFetchListPaginateOptions({
			options: keywordOptions,
			apiCall,
			initialSearchQuery: keywordSearchQuery,
			// eslint-disable-next-line
			// @ts-ignore
			setQueryCallbackOnState: setKeywordQueryOnState,
		});

	const onScroll = useCallback(() => {
		try {
			if (!!setPage && !loading && keywordPagination?.pages > page) {
				if (listInnerRef.current) {
					const {scrollTop, scrollHeight, clientHeight} = listInnerRef.current;
					if (Math.ceil(scrollTop) + clientHeight >= scrollHeight - 1) {
						setPage(keywordPagination.page + 1);
					}
				}
			}
		} catch (e) {
			console.log(e);
		}
	}, [setPage, loading, keywordPagination, page, listInnerRef]);

	return (
		<div>
			<div className={styles.searchInputWrapper}>
				<SearchInput
					isLoading={loading}
					defaultValue={keywordSearchQuery}
					onChange={onFilterChanged}
					placeholder="Search for a keyword"
					type="text"
					errorText={undefined}
					errorClass={undefined}
				/>
			</div>

			<ResetFilters
				parentStyle={{color: "#5A5A5A", marginRight: 19}}
				onClick={handleResetClick}
				displayIcon={true}
			>
				Clear Selection
			</ResetFilters>

			<div className={styles.checkboxList} ref={listInnerRef} onScroll={onScroll}>
				{data.map((s: STKeywords) => {
					return (
						<Checkbox
							borderColor="#0C5850"
							containerClass={styles.checkboxContainer}
							isChecked={selectedKeywords?.includes(s.id)}
							key={s.id}
							label={`${labelCapitalized(s.name)}`}
							onChange={(e) =>
								handleOnChange("current_keywords", s.id, e.target.checked)
							}
							value={s.name}
						/>
					);
				})}

				{data.length < 1 && <CheckboxSkeletonLoader />}
				{data.length > 0 && loading && (
					<div style={{textAlign: "center"}}>
						<Loader
							width={30}
							height={30}
							type="ThreeDots"
							color="#0c5850"
							displayAtCenterOfPage={false}
						/>
					</div>
				)}
			</div>
		</div>
	);
};

export default KeywordSearchFilter;
