import type {FC} from "react";

import {LocationArrow} from "assets/icons";
import mapLocation from "assets/images/mapLocation.png";

import styles from "./UserPreloadInsightSkeleton.module.scss";

const LocationParts: FC = () => (
	<div className={styles.location}>
		<div className={styles.location__head}>
			<p className={styles.location__head__title}>Location</p>

			<LocationArrow className={styles.location__head__arrow} />
		</div>

		<img className={styles.location__image} src={mapLocation} alt="Map Location" />
	</div>
);

export default LocationParts;
