export const colors = [
	"#93BB61",
	"#71A0AA",
	"#927FBB",
	"#ddbac0",
	"#DA7D49",
	"#A44C51",
	"#B3B3B3",
	"#D9C34F",
	"#f3a470",
	"#924E7D",
	"#D36E70",
	"#8A6642",
	"#009E73",
	"#5B9FC9",
	"#3DAC57",
	"#0C5850",
	"#C91515",
];
