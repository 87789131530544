import FilterIcon from "assets/icons/IconFilter";
import classNames from "classnames";
import {useAppSelector, useOutsideClick} from "hooks";
import {Accordion} from "mapx-components";
import KeywordFilterForOrgChart from "../Filters/KeywordFilter";
import LocationFilterForOrgChart from "./LocationFilter";
import SeniorityFilterForOrgChart from "./SeniorityFilter";
import JobFunctionFilterForOrgChart from "../Filters/JobFunctionFilter";
import React, {useMemo, useRef, useState} from "react";
import {userLicenseTypeNameSelector} from "store/mapx/user/userSelectors";

import styles from "./styles.module.scss";
import {ResetFilters} from "components";
import {ChevronIcon} from "assets/icons";
import LockIcon from "assets/icons/LockIcon";
import {companiesOrgChartFiltersSelector} from "store/mapx/company/companySelectors";
import {TOrgChartFilterProps} from "./types";

const CompanyOrgChartFilter = ({
	className,
	titleClass,
	onSelectChange,
	shouldDisplayResetFilter,
	disabled = false,
	handleReset,
}: TOrgChartFilterProps) => {
	const userLicenseTypeName = useAppSelector(userLicenseTypeNameSelector);

	const [filterOpened, setFilterOpened] = useState<boolean>(false);

	const ref = useRef<HTMLDivElement>(null);

	const lockFilterForFreemium = useMemo(
		() => userLicenseTypeName === "Starter",
		[userLicenseTypeName],
	);

	const filtersInState = useAppSelector(companiesOrgChartFiltersSelector);

	const filters = useMemo(() => {
		return [
			{
				name: "Seniority",
				key: "current_seniority",
				type: "additional_profiles",
				filter: <SeniorityFilterForOrgChart onSelectChange={onSelectChange} />,
				disabled: lockFilterForFreemium,
				displayLock: lockFilterForFreemium,
				count: filtersInState.current_seniority?.length || 0,
			},

			{
				name: "Function & Specialisms",
				key: "job_functions",
				type: "additional_profiles",
				filter: <JobFunctionFilterForOrgChart onSelectChange={onSelectChange} />,
				disabled: lockFilterForFreemium,
				displayLock: lockFilterForFreemium,
				count: filtersInState.current_specialisms?.length || 0,
			},

			{
				name: "Keyword",
				key: "text_keywords_or",
				type: "additional_profiles",
				filter: <KeywordFilterForOrgChart onSelectChange={onSelectChange} />,
				disabled: lockFilterForFreemium,
				displayLock: lockFilterForFreemium,
				count: filtersInState.current_keywords?.length || 0,
			},

			{
				name: "Locations",
				key: "countries",
				type: "additional_profiles",
				filter: <LocationFilterForOrgChart onSelectChange={onSelectChange} />,
				disabled: lockFilterForFreemium,
				displayLock: lockFilterForFreemium,
				count: filtersInState.countries.length + filtersInState.regions.length,
			},
		];
	}, [onSelectChange, filtersInState, lockFilterForFreemium]);

	const handleOCFilterClick = () => {
		setFilterOpened(!filterOpened);
	};

	useOutsideClick(ref, () => setFilterOpened(false));

	const totalFilterCount = useMemo(
		() => filters.reduce((acc, filter) => acc + (filter.count || 0), 0),
		[filters],
	);

	return (
		<div
			ref={ref}
			style={{
				position: "relative",
				pointerEvents: disabled ? "none" : "auto",
				cursor: disabled ? "not-allowed" : "pointer",
				opacity: disabled ? "0.6" : 1,
			}}
		>
			<div
				className={classNames(
					styles.filterBlockButtonWrapper,
					{
						[styles.opened]: filterOpened,
					},
					className,
				)}
				onClick={handleOCFilterClick}
				style={{cursor: "pointer"}}
			>
				<span
					className={classNames(styles.title, titleClass && titleClass, {
						[styles.disabled]: disabled,
					})}
				>
					<span className={classNames(styles.icon, {[styles.disabled]: disabled})}>
						<FilterIcon width={20} height={20} />
					</span>
					Filter
					{disabled ? (
						<LockIcon className={styles.filterExpandIcon} />
					) : (
						<ChevronIcon
							className={classNames(styles.filterExpandIcon, {
								[styles.rotated]: !filterOpened,
							})}
							width={24}
							height={24}
						/>
					)}
				</span>

				{totalFilterCount > 0 && <span className={styles.count}>{totalFilterCount}</span>}
			</div>
			<div
				className={classNames(styles.filters, {
					[styles.filterDisplayed]: filterOpened,
				})}
				style={{top: "37px"}}
			>
				{filterOpened &&
					filters.map((item) => {
						return (
							<Accordion
								key={`${item.type}-${item.name}-${item.key}`}
								type={`${item.type}`}
								attribute={item.key}
								title={item.name}
								subtitle={item?.count || 0}
								contentCustomStyle={{
									margin: "auto",
								}}
								accordionCss={classNames(
									styles.accordion,
									styles.resultFilterAccordion,
								)}
								disabled={item?.disabled}
								displayLock={item?.displayLock}
							>
								{item.filter}
							</Accordion>
						);
					})}

				{shouldDisplayResetFilter && filterOpened && (
					<div className={classNames(styles.accordion, styles.resetFilterAccordion)}>
						<ResetFilters
							parentStyle={{color: "#868D8D", marginRight: 19}}
							onClick={handleReset}
							displayIcon={true}
						>
							Reset Filters
						</ResetFilters>
					</div>
				)}
			</div>
		</div>
	);
};

export default CompanyOrgChartFilter;
