import React from "react";
import {useAppSelector} from "hooks";
import styles from "mapx-components/Layouts/Header/header.module.scss";
import {userSelector} from "store/mapx/user/userSelectors";

const UserImageOrAvatar = () => {
	const user = useAppSelector(userSelector);

	return (
		<img
			className={styles.user_img}
			src={
				user?.avatar
					? user?.avatar
					: "https://img.freepik.com/free-icon/user_318-804790.jpg?w=2000"
			}
			alt="User Avatar"
		/>
	);
};

export default UserImageOrAvatar;
