import {FC, useCallback} from "react";
import {Accordion} from "mapx-components";
import {companyRevenuesSelector} from "store/mapx/filter/filterSelectors";
import {setMultipleFilterForCompany} from "store/mapx/filter/filterActions";
import {useAppDispatch, useAppSelector} from "hooks";
import {setCompanyRevenueRangeForCompany} from "store/mapx/filter/filterAsyncActions";
import {TCompanyRevenueRangeItem} from "mapx-components/Filters/CompanyRevenueSearchFilter/types";
import CompanyRevenueSearchFilter from "mapx-components/Filters/CompanyRevenueSearchFilter";

const RevenueFilter: FC = () => {
	const dispatch = useAppDispatch();

	const revenues = useAppSelector(companyRevenuesSelector);

	const handleResetClick = useCallback(() => {
		dispatch(
			setMultipleFilterForCompany({
				revenue: [],
			}),
		);
	}, [dispatch]);

	const handleOnChange = useCallback(
		(item: TCompanyRevenueRangeItem) => {
			dispatch(setCompanyRevenueRangeForCompany(item));
		},
		[dispatch],
	);

	return (
		<Accordion title="Estimated Revenue" subtitle={revenues?.length ?? ""}>
			<CompanyRevenueSearchFilter
				handleResetClick={handleResetClick}
				handleOnChange={handleOnChange}
				selectedRevenues={revenues}
			/>
		</Accordion>
	);
};

export default RevenueFilter;
