import {uniqBy} from "lodash";
import {TCandidateProps} from "components/Charts/PieChart/types";

export const transformToTooltipData = (realData?: TCandidateProps[]) => {
	return uniqBy(realData, "id")?.map((item) => {
		return {
			id: item?.id,
			name: item?.full_name,
			src: item?.avatar_url,
			description: item?.job_title,
			company_name: item?.company_name,
			company_logo: item?.company_logo,
		};
	});
};
