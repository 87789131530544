import type {FC} from "react";

import type {IconProps} from "./types";

const Rule3: FC<IconProps> = ({fill = "url(#paint0_linear_1686_29958)", className = ""}) => (
	<svg
		width="149"
		height="154"
		fill="none"
		viewBox="0 0 149 154"
		className={className}
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M149 80.3971C149 121.047 115.954 154 75.1904 154C34.4265 154 -8.27253 123.992 1.3809 80.3971C11.0344 36.8015 46.3496 0 87.1135 0C127.877 0 149 39.7473 149 80.3971Z"
			fill={fill}
		/>
		<defs>
			<linearGradient
				id="paint0_linear_1686_29958"
				x1="74.502"
				y1="0"
				x2="74.502"
				y2="154"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#23786D" />
				<stop offset="1" stopColor="#2A688A" />
			</linearGradient>
		</defs>
	</svg>
);

export default Rule3;
