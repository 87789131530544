import React, {useCallback, useMemo, useState} from "react";
import styles from "./styles.module.scss";
import {ActionAltButton} from "../../index";
import classNames from "classnames";
import {MagicWandIcon} from "assets/icons";
import {TProjectCandidateSummary} from "./types";
import ContentLoader from "react-content-loader";
import {generateCandidatesSummary} from "store/mapx/project/generateCandidateSummaryAsyncActions";
import {useAppDispatch, useAppSelector} from "hooks";
import {
	generateCandidatesSummariesFlattenedForAllProjectSelector,
	isSummaryForCurrentCandidateInProgressForCurrentProjectSelector,
} from "store/mapx/project/generateCandidateSummarySelectors";
import {
	isCandidateSavedSelector,
	targetListIDSelector,
} from "store/mapx/target-list/targetListSelectors";
import {projectSelector} from "store/mapx/project-list/projectListSelectors";
import {useLocation} from "react-router-dom";
import {isArray} from "lodash";

const CandidateSummary = ({id, summary, expanded}: TProjectCandidateSummary) => {
	const dispatch = useAppDispatch();

	const location = useLocation();

	const isFromProjectSource = location.pathname.split("/").includes("project");

	const isFromProjectSearchSource = location.search.toLowerCase().split("=").includes("project");

	const targetListID = useAppSelector(targetListIDSelector);

	const project = useAppSelector(projectSelector);

	const isCandidateSavedToProject = useAppSelector((state) =>
		isCandidateSavedSelector(state, id),
	);

	const showGenerateButton = project && isCandidateSavedToProject;

	const [creatingRequest, setCreatingRequest] = useState(false);

	const isSummaryForCurrentCandidateInProgressForCurrentProject = useAppSelector<boolean>(
		(state) =>
			isSummaryForCurrentCandidateInProgressForCurrentProjectSelector(
				state,
				targetListID,
				id,
			),
	);

	const flattenedCandidateSummariesForAllProject = useAppSelector(
		generateCandidatesSummariesFlattenedForAllProjectSelector,
	);

	const generatingSummary = useMemo(
		() => creatingRequest || isSummaryForCurrentCandidateInProgressForCurrentProject,
		[creatingRequest, isSummaryForCurrentCandidateInProgressForCurrentProject],
	);

	const handleGenerateButtonClick = useCallback(async () => {
		setCreatingRequest(true);
		await dispatch(generateCandidatesSummary([id]));
		setCreatingRequest(false);
	}, [dispatch, id]);

	return (
		<div className={classNames(styles.summaryBlock)}>
			<div className={styles.summaryActionWrapper}>
				<div
					className={classNames(styles.summaryTitle, {
						[styles.boldTitle]: isFromProjectSource && !isFromProjectSearchSource,
					})}
				>
					{(showGenerateButton || summary) && "Summary"}
				</div>

				{!summary && showGenerateButton && (
					<ActionAltButton
						key={"expand"}
						customClass={classNames(styles.generateSummaryButton, {
							[styles.loading]: generatingSummary,
						})}
						iconComponent={<MagicWandIcon color={"#4E5555"} />}
						handleClick={handleGenerateButtonClick}
						title={generatingSummary ? "Generating" : "Generate"}
						loading={generatingSummary}
					/>
				)}
			</div>

			<div className={styles.description}>
				{!summary &&
					!generatingSummary &&
					showGenerateButton &&
					"Click Generate button to add summary..."}
				{!!summary &&
					!generatingSummary &&
					(expanded ? summary : `${summary.substring(0, 270)}...`)}

				{isArray(flattenedCandidateSummariesForAllProject) &&
					flattenedCandidateSummariesForAllProject?.includes(id) && (
						<ContentLoader
							style={{marginTop: 10}}
							speed={2}
							height={100}
							width={"100%"}
							backgroundColor="#EEEDEC"
							foregroundColor="#e6e6e6"
						>
							<rect x="1" y="8.8" width="99%" height="22" />
							<rect x="1" y="38.8" width="96%" height="22" />
							<rect x="1" y="68.8" width="98%" height="22" />
						</ContentLoader>
					)}
			</div>
		</div>
	);
};

export default CandidateSummary;
