import {useCallback, useEffect, useMemo, useState} from "react";
import {useSelector} from "react-redux";
import type {SyntheticEvent} from "react";

import colors from "styles/themes.module.scss";
import {useAppDispatch, useModalHook} from "hooks";
import {SelectWithInfiniteScroll, Message, ModalHeader, ModalSeparator} from "components";
import GeneralButton from "mapx-components/Buttons/GeneralButton";
import {
	addToMarketMapModalSelector,
	creationInProgressSelector,
	getMarketMapsModalInProgressSelector,
	marketMapsModalPaginationSelector,
	marketMapsModalUserHasAccessSelector,
} from "store/mapx/market-map/marketMapSelectors";
import {
	getMarketMapsForModal,
	updateMarketMap,
} from "store/mapx/market-map/marketMapAsyncFunctions";
import {addToMarketMapModal} from "store/mapx/market-map/marketMapActions";
import type {TSelectWithActionOptions} from "components/Inputs/SelectWithAction/types";
import type {STMarketMap} from "api/marketMapApi/types";

import styles from "./addToMarketMapModal.module.scss";
import type {TAddToMarketMapModalProps} from "./types";

const AddToMarketMapModal = ({company}: TAddToMarketMapModalProps) => {
	const dispatch = useAppDispatch();

	const [marketMap, setMarketMap] = useState<TSelectWithActionOptions>({
		label: null,
		value: null,
	});

	const marketMaps: STMarketMap[] = useSelector(marketMapsModalUserHasAccessSelector);

	const loading = useSelector(creationInProgressSelector);

	const pagination = useSelector(marketMapsModalPaginationSelector);

	const modalDisplay = useSelector(addToMarketMapModalSelector);

	const gettingMarketMaps = useSelector(getMarketMapsModalInProgressSelector);

	const {modalIsOpen, customStyles, Modal} = useModalHook(
		{content: {width: "400px", borderRadius: "8px", overflow: "visible"}},
		modalDisplay,
		colors.mainThemeColor,
	);

	const selectedMarketMap = useMemo(
		() => marketMaps.find((map: STMarketMap) => map.id === marketMap?.value),
		[marketMap, marketMaps],
	);

	const max500 = useMemo(
		() => selectedMarketMap?.companies && selectedMarketMap.companies.length > 500,
		[selectedMarketMap],
	);

	const min0 = useMemo(
		() => selectedMarketMap?.companies && selectedMarketMap.companies.length === 0,
		[selectedMarketMap],
	);

	const handleInfiniteScroll = useCallback(() => {
		dispatch(getMarketMapsForModal());
	}, [dispatch]);

	const handleModalDisplay = useCallback(
		(display: boolean) => {
			dispatch(addToMarketMapModal(display));
		},
		[dispatch],
	);

	const handleSubmit = useCallback(
		(e: SyntheticEvent) => {
			e.preventDefault();

			if (selectedMarketMap) {
				dispatch(
					updateMarketMap(
						selectedMarketMap.id,
						selectedMarketMap.name,
						selectedMarketMap.is_global,
						selectedMarketMap.companies.map((company) => company.id).concat(company.id),
						selectedMarketMap.description || "",
					),
				);
			}

			// handleModalDisplay(false);
		},
		[company.id, dispatch, selectedMarketMap],
	);

	useEffect(() => {
		if (marketMaps.length === 0) {
			dispatch(getMarketMapsForModal());
		}
	}, [dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<Modal
			title="Add to a Target List"
			style={customStyles}
			isOpen={modalIsOpen}
			contentLabel="Modal"
			onRequestClose={() => handleModalDisplay(false)}
		>
			<ModalHeader title="Add to a Target List" onClose={() => handleModalDisplay(false)} />
			<ModalSeparator />
			<form className={styles.container} onSubmit={handleSubmit}>
				<label className={styles.label}>Target List</label>
				<SelectWithInfiniteScroll
					isSelected={!marketMap?.value}
					loading={gettingMarketMaps}
					onScroll={handleInfiniteScroll}
					options={marketMaps.map((map: STMarketMap) => ({
						label: map?.name,
						value: map?.id,
					}))}
					pagination={pagination}
					placeholder="Select Target List"
					selected={marketMap}
					setSelected={setMarketMap}
				/>
				{max500 && (
					<Message
						className={styles.message}
						level="error"
						message="You can add max 500 companies per Target List."
					/>
				)}
				{min0 && (
					<Message
						className={styles.message}
						level="error"
						message="No company has been added to the project."
					/>
				)}
				<GeneralButton
					disabled={min0 || max500 || marketMap?.value === null}
					customClass={styles.button}
					title="Import Project to Target List"
					loadingSave={loading}
				/>
			</form>
		</Modal>
	);
};

export default AddToMarketMapModal;
