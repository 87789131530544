import {type FC, MouseEvent} from "react";
import {Tooltip} from "react-tooltip";
import {useHistory} from "react-router-dom";

import {PeopleHeaderIcon} from "assets/icons";
import {useAppDispatch, useOpenLink} from "hooks";
import useUserBehaviorTrackHook from "hooks/mapx/useUserBehaviorTrackHook";
import {setTrackedEventsData} from "store/mapx/events/eventTrackingActions";

import type {TCandidatesTooltipProps} from "./types";
import styles from "./CandidatesTooltip.module.scss";

const CandidatesTooltip: FC<TCandidatesTooltipProps> = ({id, data, title, percent}) => {
	const openLink = useOpenLink();
	const history = useHistory();

	const dispatch = useAppDispatch();

	const {itemEventDetails} = useUserBehaviorTrackHook({
		itemType: "candidate",
		defaultEvent: "details_viewed",
		itemId: id,
	});

	const handleItemClick = (event: MouseEvent<HTMLDivElement>, id: number | string) => {
		dispatch(setTrackedEventsData(itemEventDetails));

		if (event.ctrlKey || event.metaKey || event.ctrlKey || event.altKey) {
			const link = `/candidate/${id} `;
			openLink(event, link, true);
		} else {
			const link = `${history.location.pathname}/${id} `;
			history.push(link);
		}
	};

	const renderCandidates = data?.map((item, idx) => (
		<div
			role="button"
			className={styles.tooltip__body__item}
			onClick={(e) => handleItemClick(e, item?.id)}
			key={`${item.id}-${item?.name}-${item.description}-${idx}`}
		>
			{item.src ? (
				<img src={item.src} alt={item?.name} className={styles.tooltip__body__item_img} />
			) : (
				<PeopleHeaderIcon className={styles.tooltip__body__item_img} />
			)}

			<div className={styles.tooltip__body__item__texts}>
				<p className={styles.tooltip__body__item__texts_name}>{item?.name}</p>
				{item?.company_name && (
					<div className={styles.tooltip__body__item__texts_description}>
						{item.description}
						<div className={styles.tooltip__body__item__texts_description__company}>
							<img
								src={
									item?.company_logo ||
									"https://www.globalfleet.com/sites/default/files/styles/medium/public/default_images/default-company.jpg?itok=b4eD7gVz"
								}
								alt="Company logo"
								className={
									styles.tooltip__body__item__texts_description__company_logo
								}
							/>
							<p
								className={
									styles.tooltip__body__item__texts_description__company_name
								}
							>
								{item?.company_name}
							</p>
						</div>
					</div>
				)}
			</div>
		</div>
	));

	return (
		<Tooltip delayShow={500} clickable id={`${id}`} className={styles.tooltip}>
			<div className={styles.tooltip__head}>
				<p className={styles.tooltip__head_title}>{title}</p>
				<p className={styles.tooltip__head_description}>{percent}</p>
			</div>

			<div className={styles.tooltip__body}>{renderCandidates}</div>
		</Tooltip>
	);
};

export default CandidatesTooltip;
