import {useEffect, useState} from "react";

const useTabIsActiveHook = () => {
	const [tabIsActive, setTabIsActive] = useState(true);

	useEffect(() => {
		let hidden, visibilityChange;

		if (typeof document.hidden !== "undefined") {
			hidden = "hidden";
			visibilityChange = "visibilitychange";
		} else if (typeof document.mozHidden !== "undefined") {
			hidden = "mozHidden";
			visibilityChange = "mozvisibilitychange";
		} else if (typeof document.msHidden !== "undefined") {
			hidden = "msHidden";
			visibilityChange = "msvisibilitychange";
		} else if (typeof document.webkitHidden !== "undefined") {
			hidden = "webkitHidden";
			visibilityChange = "webkitvisibilitychange";
		}

		const handleVisibilityChange = () => {
			if (document[hidden]) {
				setTabIsActive(false);
			} else {
				setTabIsActive(true);
			}
		};

		if (
			typeof document.addEventListener === "undefined" ||
			typeof document[hidden] === "undefined"
		) {
			console.log(
				"This demo requires a browser, such as Google Chrome or Firefox, that supports the Page Visibility API.",
			);
		} else {
			document.addEventListener(visibilityChange, handleVisibilityChange, false);
		}
	}, []);

	return {tabIsActive};
};

export default useTabIsActiveHook;
