import {
	IconUsers,
	InsightsIcon,
	OverviewIcon,
	ProfileResultsIcon,
	CompanyAsBuildingIcon,
} from "assets/icons";

export const fakeTabs = [
	{id: 0, title: "Overview", Icon: OverviewIcon},
	{id: 1, title: "Saved People", Icon: IconUsers},
	{id: 2, title: "Companies", Icon: CompanyAsBuildingIcon},
	{id: 3, title: "Insights", Icon: InsightsIcon},
	{id: 4, title: "SearchRequestResults", Icon: ProfileResultsIcon},
];

export const projectCandidateFakeCardData = [
	{
		avatar_url:
			"https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlcnxlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80",
		id: "string",
		name: "Emily Roberts",
		companyName: "AstraZeneca",
		country: "United Kingdom",
		details: [
			{
				name: "Director Global Quality",
				dateDetails: "2020 - Present",
			},
		],
		company_avatar:
			"https://c8.alamy.com/comp/PXPBDW/building-logo-design-real-estate-company-logo-design-abstract-construction-logo-design-building-logo-design-PXPBDW.jpg",
	},
	{
		avatar_url:
			"https://static.vecteezy.com/system/resources/previews/013/949/957/original/approved-candidate-flat-icon-free-vector.jpg",
		id: "qstring",
		name: "Darren Starr",
		companyName: "PepsiCo",
		country: "United Kingdom",
		details: [
			{
				name: "AMEA Sector Food Safety Manager",
				dateDetails: "2009 - 2012",
			},
		],
		company_avatar:
			"https://c8.alamy.com/comp/PXPBDW/building-logo-design-real-estate-company-logo-design-abstract-construction-logo-design-building-logo-design-PXPBDW.jpg",
	},
	{
		avatar_url:
			"https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlcnxlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80",
		id: "strdewing",
		name: "Mark Bowen",
		companyName: "AB Mauri, a global business of Associated British Foods plc",
		country: "United Kingdom",
		details: [
			{
				name: "Senior Manager Quality Assurance, QP",
				dateDetails: "2021 - Present",
			},
		],
		company_avatar:
			"https://c8.alamy.com/comp/PXPBDW/building-logo-design-real-estate-company-logo-design-abstract-construction-logo-design-building-logo-design-PXPBDW.jpg",
	},
];
