import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useAppDispatch, useAppSelector} from "hooks";
import {
	activeProjectResultsTabContentSelector,
	activeSearchRequestSelector,
	allSearchRequestsSelector,
} from "store/mapx/additional-profiles/additionalProfilesSelectors";
import {
	TInputCandidate,
	TSearchRequestDetails,
	TSearchRequestMiniResponse,
} from "api/projectApi/searchRequestApi/types";

import styles from "./styles.module.scss";
import classNames from "classnames";
import {
	clearAPActiveResult,
	setProjectResultsTabActiveContent,
} from "store/mapx/additional-profiles/additionalProfilesActions";
import {getSearchRequestDetails} from "store/mapx/additional-profiles/searchRequestAsyncActions";
import {Loader} from "components";
import colors from "styles/themes.module.scss";
import {TProjectsResultsTabContentTypes} from "mapx-components/SearchRequestResults/FilterBlock/types";
import SearchCompletionStats from "mapx-components/SearchRequestResults/FilterBlock/SidebarTabs/SearchCompletionStats";
import {TSidebarTabsProps} from "mapx-components/SearchRequestResults/FilterBlock/SidebarTabs/types";
import {useSelector} from "react-redux";
import {
	importLinkedInCandidateInProgressSelector,
	similarCandidatesSelector,
} from "store/mapx/candidate/candidateSelectors";

const SidebarTabs = ({
	isDisplayingMatchingTo,
	setDisplayingMatchingTo,
	addSearchResultsTab = false,
}: TSidebarTabsProps) => {
	const dispatch = useAppDispatch();

	const activeContent: TProjectsResultsTabContentTypes = useAppSelector(
		activeProjectResultsTabContentSelector,
	);

	const allSearchRequests = useAppSelector(allSearchRequestsSelector);

	const activeSearchRequest = useAppSelector(activeSearchRequestSelector);

	const [activeSearchRequestId, setActiveSearchRequestId] = useState<number | null>(
		activeSearchRequest?.id,
	);

	const importingLinkedInCandidateInProgress = useSelector(
		importLinkedInCandidateInProgressSelector,
	);

	const similarCandidatesList = useAppSelector(similarCandidatesSelector);

	useEffect(() => {
		if (activeSearchRequestId !== activeSearchRequest?.id) {
			setActiveSearchRequestId(activeSearchRequest?.id);
		}
		// eslint-disable-next-line
	}, [activeSearchRequest]);

	const handleTabClick = useCallback(
		(searchRequest: TSearchRequestMiniResponse) => {
			if (activeContent !== "search_results") {
				dispatch(setProjectResultsTabActiveContent("search_results"));
			}

			if (activeSearchRequest?.id !== searchRequest.id) {
				dispatch(clearAPActiveResult());

				setActiveSearchRequestId(searchRequest.id);

				dispatch(getSearchRequestDetails(searchRequest.id));
			}

			if (setDisplayingMatchingTo) {
				setDisplayingMatchingTo(false);
			}
		},
		[activeContent, activeSearchRequest?.id, dispatch, setDisplayingMatchingTo],
	);

	const getTabTitle = useCallback(
		(searchRequest: TSearchRequestMiniResponse) => {
			if (searchRequest.type === "Free Text Input Search") {
				return searchRequest.free_text_input;
			} else if (searchRequest.type === "Generate More Results") {
				const parentSearch =
					allSearchRequests instanceof Array
						? allSearchRequests.find(
								(sr: TSearchRequestMiniResponse) =>
									sr.id === searchRequest.parent_search_request_id,
						  )
						: null;

				return `More: ${
					parentSearch ? parentSearch.free_text_input : "SearchRequestResults"
				}`;
			} else if (searchRequest.type === "More Like This") {
				const candidateName =
					searchRequest.input_candidates.length > 0
						? searchRequest.input_candidates[0].full_name
						: "";

				return `More Like ${candidateName}`;
			} else if (searchRequest.type === "Additional Profiles") {
				const jobTitles =
					searchRequest["job_titles"] !== undefined
						? searchRequest["job_titles"].join(", ")
						: null;

				const candidateName =
					searchRequest.input_candidates.length > 0
						? searchRequest.input_candidates
								.map((candidate: TInputCandidate) => candidate.full_name)
								.join(", ")
						: "";

				return `${jobTitles ?? candidateName}`;
			} else {
				return `${searchRequest.id}`;
			}
		},
		[allSearchRequests],
	);

	const filteredSearchRequests = useMemo(
		() =>
			allSearchRequests?.filter(
				(searchRequest: TSearchRequestDetails) =>
					searchRequest.type === "Free Text Input Search" ||
					searchRequest.type === "More Like This" ||
					searchRequest.type === "Generate More Results" ||
					searchRequest.type === "Additional Profiles",
			) || [],
		[allSearchRequests],
	);

	const shouldDisplaySearchRequestStats = useCallback(
		(sr: TSearchRequestMiniResponse) => {
			return (
				sr.type === "Additional Profiles" &&
				sr.status === "Completed" &&
				activeSearchRequest?.id === sr.id
			);
		},
		[activeSearchRequest],
	);

	const LOADER_COMPONENT = (
		<Loader height={15} width={15} type="Oval" color={colors.loaderDotColor} />
	);

	const ARROW_RIGHT = (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<mask
				id="mask0_1602_42286"
				style={{maskType: "alpha"}}
				maskUnits="userSpaceOnUse"
				x="0"
				y="0"
				width="24"
				height="24"
			>
				<rect width="24" height="24" fill="#D9D9D9" />
			</mask>
			<g mask="url(#mask0_1602_42286)">
				<path d="M10 17V7L15 12L10 17Z" fill="#4E5555" />
			</g>
		</svg>
	);

	return (
		<div
			className={classNames(styles.sidebarTabWrapper, {
				[styles.noSearchRequests]: !filteredSearchRequests.length && !addSearchResultsTab,
			})}
		>
			{addSearchResultsTab && (
				<div
					key={"display-similar-profiles"}
					className={classNames(styles.tab, {
						[styles.active]: isDisplayingMatchingTo,
					})}
					onClick={() => {
						if (setDisplayingMatchingTo) {
							setDisplayingMatchingTo(true);
						}
					}}
				>
					<div>
						<div className={styles.title}>Search Results</div>
					</div>

					<div className={styles.countWrapper}>
						{!importingLinkedInCandidateInProgress &&
							similarCandidatesList &&
							similarCandidatesList?.length > 0 && (
								<div className={styles.count}>{similarCandidatesList?.length}</div>
							)}

						{ARROW_RIGHT}
					</div>
				</div>
			)}

			{filteredSearchRequests.map((sr: TSearchRequestMiniResponse) => (
				<div
					key={sr.id}
					className={classNames(styles.tab, {
						[styles.active]: activeSearchRequestId === sr.id && !isDisplayingMatchingTo,
					})}
					onClick={() => handleTabClick(sr)}
				>
					<div>
						<div className={styles.title}>{getTabTitle(sr)}</div>

						{shouldDisplaySearchRequestStats(sr) && (
							<SearchCompletionStats
								uniqueUrlsCount={activeSearchRequest.unique_urls_count}
								uniqueUrlsRejectedCount={activeSearchRequest.unique_urls_rejected}
								// results={profilesResultsPaginationInfo?.count}
							/>
						)}
					</div>

					<div className={styles.countWrapper}>
						<div className={styles.count}>{sr.found_candidates_count}</div>

						{activeSearchRequest?.id === sr.id && ARROW_RIGHT}

						{sr.status === "In Progress" && LOADER_COMPONENT}
					</div>
				</div>
			))}
		</div>
	);
};

export default SidebarTabs;
