import type {PieLabel} from "recharts";

import styles from "./PieChart.module.scss";

const CustomLabel: PieLabel = ({cx, cy, value, midAngle, innerRadius, outerRadius}) => {
	const RADIAN = Math.PI / 180;
	const labelRadius = innerRadius + (outerRadius - innerRadius) * 0.5;
	const x = cx + labelRadius * Math.cos(-midAngle * RADIAN);
	const y = cy + labelRadius * Math.sin(-midAngle * RADIAN);

	return (
		<text
			x={x as number}
			y={y as number}
			fill="white"
			textAnchor="middle"
			dominantBaseline="central"
			className={styles.percent}
		>
			{`${value}%`}
		</text>
	);
};

export default CustomLabel;
