import type {TGetExpandableDataResult} from "types";
import type {TExpandableData} from "./types";
import {TChildIndustryForFilter, TIndustryForFilter} from "api/filterOptionsApi/IndustryApi/types";
import {checkIntersectionWithoutSortedElement} from "helpers/array";

export const getIndustryExpandableData = (filteredData?: TExpandableData[] | null) =>
	filteredData?.reduce<TGetExpandableDataResult>((acc, curVal) => {
		if (curVal.children?.length) {
			acc[curVal.id] = true;

			Object.assign(acc, getIndustryExpandableData(curVal?.children as TExpandableData[]));
		}

		return acc;
	}, {});

const getSelectedIdsFromParentByIterate = (
	parentId: number | null,
	selectedIds: number[],
	allIndustryOptionsWithoutChild: TChildIndustryForFilter[],
): number[] => {
	const parentItem = parentId && allIndustryOptionsWithoutChild.find(({id}) => id === parentId); // default undefined

	if (parentItem) {
		const parentsAllChild = parentItem.all_child_ids;

		const isAllSelected = checkIntersectionWithoutSortedElement(selectedIds, parentsAllChild);

		if (isAllSelected) {
			selectedIds = [...selectedIds, parentItem.id];

			return getSelectedIdsFromParentByIterate(
				parentItem.parent_industry_id,
				selectedIds,
				allIndustryOptionsWithoutChild,
			);
		}
	}

	return selectedIds;
};

const removeAndGetUpdatedSelectedIdsFromByParentByIterate = (
	parentId: number | null,
	selectedIds: number[],
	allIndustryOptionsWithoutChild: TChildIndustryForFilter[],
): number[] => {
	const parentItem = parentId && allIndustryOptionsWithoutChild.find(({id}) => id === parentId); // default undefined

	if (parentItem && selectedIds.includes(parentItem.parent_industry_id)) {
		selectedIds = [...selectedIds].filter((id) => id !== parentItem.parent_industry_id);

		return removeAndGetUpdatedSelectedIdsFromByParentByIterate(
			parentItem.parent_industry_id,
			selectedIds,
			allIndustryOptionsWithoutChild,
		);
	}

	return selectedIds;
};

export const getUpdatedIndustryIdsByCurrentSelectedItem = (
	item: TIndustryForFilter,
	selectedIndustries: number[],
	allIndustryOptionsWithoutChild: TChildIndustryForFilter[],
): number[] => {
	const industries = [...selectedIndustries];

	const index = industries.indexOf(item.id);

	const alreadySelected = index !== -1;

	let nextIndustries;

	const childIds =
		item.all_child_ids !== undefined && Array.isArray(item.all_child_ids)
			? item.all_child_ids
			: [];

	if (alreadySelected) {
		const toBeRemovedIds = item.parent_industry_id
			? [item.parent_industry_id, item.id, ...childIds]
			: [item.id, ...childIds];
		nextIndustries = [...industries].filter((id) => !toBeRemovedIds.includes(id));

		nextIndustries = removeAndGetUpdatedSelectedIdsFromByParentByIterate(
			item.parent_industry_id,
			nextIndustries,
			allIndustryOptionsWithoutChild,
		);
	} else {
		nextIndustries =
			childIds?.length === 0
				? [...industries, item.id]
				: [...industries, item.id, ...childIds];

		nextIndustries = getSelectedIdsFromParentByIterate(
			item.parent_industry_id,
			nextIndustries,
			allIndustryOptionsWithoutChild,
		);
	}

	return nextIndustries;
};
