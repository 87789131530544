/** @format */

export const ACTIVE_COLOR = "#0C5850";
export const INACTIVE_COLOR = "rgb(234, 233, 240)";
export const MARGIN_HORIZONTAL = 12;
export const LABEL_HEIGHT = 16;
export const LABELS = [500, 10000];

export default {
	ACTIVE_COLOR,
	INACTIVE_COLOR,
	MARGIN_HORIZONTAL,
	LABEL_HEIGHT,
	LABELS,
};
