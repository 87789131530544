import mapXCompanyApi from "api/companyApi";
import {successResponse} from "helpers/map";
import {isValidId} from "helpers/string";
import qs from "qs";

import {
	companiesOrgChartFiltersSelector,
	companiesSelector,
	companySelector,
} from "store/mapx/company/companySelectors";
import {setEnhanceCompaniesResult} from "store/mapx/filter/filterActions";
import {showEnhancedCompaniesSelector} from "store/mapx/filter/filterSelectors";
import {
	addPaginatedDataForCompanyOrganizationalChart,
	getCompaniesByFilterDone,
	getCompaniesByFilterFail,
	getCompaniesByFilterInit,
	getCompaniesDone,
	getCompaniesFail,
	getCompaniesInit,
	getCompanyOrgChartFiltering,
	getSimilarCompaniesDone,
	getSimilarCompaniesFail,
	getSimilarCompaniesInit,
	getSingleCompanyDone,
	getSingleCompanyFail,
	getSingleCompanyInit,
	resetCompanyRevenueFilter,
	resetCompanySizeFilter,
	setCompanyEthnicDiversityData,
	setCompanyEthnicDiversityInit,
	setCompanyFrequentlyHiredFromData,
	setCompanyFunctionalBackground,
	setCompanyGenderDiversityData,
	setCompanyGenderDiversityInit,
	setCompanyIndustryBackground,
	setCompanyOrganizationalChart,
	setCompanyOrgChartPaginationLoading,
	setCompanyTrendsStats,
	setCompanyTrendStatsInit,
	setMapThisFunctionDataForCurrentCompany,
	setMapThisFunctionIsInProgressForCurrentCompany,
	updateTooltipCompanies,
	updateTooltipCompaniesFailed,
	updateTooltipCompaniesProgress,
} from "./companyActions";

export const getIndustryBackground = (companyId, params, config) => async (dispatch) => {
	const response = await mapXCompanyApi.getCompanyIndustryBackground(companyId, params, config);

	if (successResponse(response, 200)) {
		dispatch(setCompanyIndustryBackground(response.data.results));
	}
};

export const getFunctionalBackground = (companyId, params, config) => async (dispatch) => {
	try {
		const response = await mapXCompanyApi.getCompanyFunctionalBackground(
			companyId,
			params,
			config,
		);

		if (successResponse(response, 200)) {
			dispatch(setCompanyFunctionalBackground(response.data.results));
		}
	} catch (error) {
		return {error};
	}
};

// export const getRevenueDistribution =
// 	(companies, filterQuery = null) =>
// 		async (dispatch) => {
// 			if (companies === undefined) return;
//
// 			let queryString = "";
// 			let min_revenue = 1;
// 			let max_revenue = 1;
//
// 			if (filterQuery) {
// 				const parseQuery = qs.parse(filterQuery.substring(1)); //removes ? from string;
//
// 				if (parseQuery.min_revenue) {
// 					min_revenue = parseInt(parseQuery.min_revenue);
// 				}
//
// 				if (parseQuery.max_revenue) {
// 					max_revenue = parseInt(parseQuery.max_revenue);
// 				}
// 			}
//
// 			if (min_revenue === 1) {
// 				min_revenue = Math.round(0.9 * Math.min(...companies.map(({revenue}) => revenue)));
// 			}
//
// 			if (max_revenue === 1) {
// 				max_revenue = Math.round(1.1 * Math.max(...companies.map(({revenue}) => revenue)));
// 			}
//
// 			queryString = `?min_revenue=${min_revenue}&max_revenue=${max_revenue}`;
//
// 			const response = await mapXCompanyApi.getCompaniesRevenueDistribution(queryString);
//
// 			if (response.status === 200) {
// 				const data = {
// 					min_revenue,
// 					max_revenue,
// 					data: response.data.results,
// 				};
//
// 				dispatch(setCompanyRevenueFilter(data));
// 			}
// 		};

// export const onSuccessCompaniesResultResponse =
// 	(response, payload, queryString = null) =>
// 		(dispatch) => {
// 			if (response.status === 200) {
// 				dispatch(getSizeDistribution(payload.data, queryString));
// 				dispatch(getRevenueDistribution(payload.data));
// 			}
// 		};

export const getCompaniesData =
	(pageNumber = 1, config) =>
	async (dispatch, getState) => {
		const state = getState();

		if (pageNumber === 1) {
			dispatch(getCompaniesInit());
		}

		const showEnhancedCompanies = showEnhancedCompaniesSelector(state);

		if (showEnhancedCompanies) {
			dispatch(setEnhanceCompaniesResult(false));
		}

		let response;

		try {
			response = await mapXCompanyApi.fetchCompanies(pageNumber, config);

			let payload;

			if (response) {
				payload = {
					data: response.status === 200 ? response.data.results : [],
					paginationInfo: response.data.pagination,
					pageNumber: pageNumber,
				};

				dispatch(getCompaniesDone(payload));

				// dispatch(onSuccessCompaniesResultResponse(response, payload));
			} else {
				const companies = companiesSelector(state);

				payload = {
					data: companies,
					paginationInfo: null,
					pageNumber: 1,
					cancelled: true,
				};

				dispatch(getCompaniesByFilterDone(payload));
			}

			return payload;

			//@todo send seen data information to BE
		} catch (error) {
			dispatch(getCompaniesFail({error}));

			return {error};
		}
	};

export const getFilteredCompaniesData =
	(payload, pageNumber, config) => async (dispatch, getState) => {
		const state = getState();

		let response;

		if (pageNumber === 1) {
			dispatch(getCompaniesByFilterInit());
		}

		const apiPayload = {
			...payload,
			pagination: {
				page: pageNumber,
				per_page: 20,
			},
		};

		try {
			response = await mapXCompanyApi.fetchCompaniesByFilter(apiPayload, config);

			let companiesFilterDonePayload;

			if (response) {
				companiesFilterDonePayload = {
					data: response.data.results,
					paginationInfo: response.data.pagination,
					pageNumber: pageNumber,
				};

				dispatch(getCompaniesByFilterDone(companiesFilterDonePayload));

				// dispatch(onSuccessCompaniesResultResponse(response, payload, queryString));
			} else {
				const companies = companiesSelector(state);

				payload = {
					data: companies,
					paginationInfo: null,
					pageNumber: 1,
					cancelled: true,
				};
				dispatch(getCompaniesByFilterDone(payload));
			}

			return payload;

			//@todo send seen data information to BE
		} catch (error) {
			dispatch(getCompaniesByFilterFail({error}));
			console.log(error);

			return {error};
		}
	};

export const getSingleCompanyData = (companyID) => async (dispatch) => {
	try {
		dispatch(getSingleCompanyInit());

		if (!isValidId(companyID)) {
			window.location = "/404";

			throw new Error("CompanyDetails is not exist");
		}

		const {data, status} = await mapXCompanyApi.getCompany(companyID);

		if (status === 403) {
			window.location = "/403";

			throw new Error("You don't have permission to access this company.");
		}

		if (status === 404) {
			window.location = "/404";

			throw new Error("CompanyDetails is not exist");
		}

		dispatch(getSingleCompanyDone(data));
	} catch (error) {
		dispatch(getSingleCompanyFail({error}));

		return {error};
	}
};

export const getSimilarCompaniesData =
	(queryString, isItForCompanyDetails = false, config) =>
	async (dispatch) => {
		dispatch(getSimilarCompaniesInit());
		let response;

		try {
			const parseQuery = qs.parse(queryString.substring(1)); //removes ? from string;

			if (parseQuery.page) {
				parseQuery.page = "1";
				queryString = qs.stringify(parseQuery);
				queryString = "?" + queryString;
			}

			let perPage = isItForCompanyDetails ? 5 : 6;
			response = await mapXCompanyApi.getSimilarCompanies(queryString, perPage, config);

			const payload =
				response.status === 200
					? response.data.results.map((item) => {
							item.recommended = true;

							return item;
					  })
					: [];

			dispatch(getSimilarCompaniesDone(payload));

			// if (!isItForCompanyDetails) {
			// 	dispatch(onSuccessCompaniesResultResponse(response, payload, queryString));
			// }
		} catch (error) {
			dispatch(getSimilarCompaniesFail({error}));

			return {error};
		}
	};

export const getCompanyGenderDiversityDataFilter = (id, params, config) => async (dispatch) => {
	let data;

	try {
		const res = await mapXCompanyApi.getCompanyGenderDiversity(id, params, config);

		if (res?.status === 200) {
			data = res.data;
		} else {
			data = [];
		}

		dispatch(setCompanyGenderDiversityData(data));
	} catch (e) {
		console.error(e);
	}
};

export const getCompanyGenderDiversityData = (id, callback) => async (dispatch) => {
	let data;

	try {
		dispatch(setCompanyGenderDiversityInit());

		const res = await mapXCompanyApi.getCompanyGenderDiversity(id);

		if (res.status === 200) {
			data = res.data;
		} else {
			data = [];
		}

		dispatch(setCompanyGenderDiversityData(data));
	} catch (e) {
		console.error(e);
	} finally {
		callback && callback();
	}
};

export const getCompanyTrendStatsFilter = (id, params, config) => async (dispatch) => {
	let data;

	try {
		const res = await mapXCompanyApi.getCompanyTrendStats(id, params, config);

		if (res?.status === 200) {
			data = res.data;
		} else {
			data = [];
		}

		dispatch(setCompanyTrendsStats(data));
	} catch (e) {
		console.error(e);
	}
};

export const getCompanyTrendStats = (id, callback) => async (dispatch) => {
	let data;

	dispatch(setCompanyTrendStatsInit());

	try {
		const res = await mapXCompanyApi.getCompanyTrendStats(id);

		if (res.status === 200) {
			data = res.data;
		} else {
			data = [];
		}

		dispatch(setCompanyTrendsStats(data));
	} catch (e) {
		console.error(e);
	} finally {
		callback && callback();
	}
};

export const getCompanyOrganizationalChartFilter =
	(companyId, params, config) => async (dispatch) => {
		dispatch(getCompanyOrgChartFiltering(true));

		try {
			const res = await mapXCompanyApi.getCompanyOrganizationalChart(
				companyId,
				params,
				config,
			);

			if (res && res?.status === 200) {
				dispatch(setCompanyOrganizationalChart(res.data.results));
			}
		} catch (e) {
			console.error(e);
			dispatch(getCompanyOrgChartFiltering(false));
		}
	};

export const getCompanyOrganizationalChart = (companyId, callback) => async (dispatch) => {
	dispatch(getCompanyOrgChartFiltering(true));

	try {
		const res = await mapXCompanyApi.getCompanyOrganizationalChart(companyId);

		if (res && res?.status === 200) {
			dispatch(setCompanyOrganizationalChart(res?.data?.results));

			dispatch(getCompanyOrgChartFiltering(false));
		}
	} catch (e) {
		console.error(e);
		dispatch(getCompanyOrgChartFiltering(false));
	} finally {
		callback && callback();
	}
};

// THIS METHOD IS FOR 2nd PAGE FULL DATA
export const getPaginatedDataForCompanyOrgChart =
	(seniorityTitle, pageNumber = 1) =>
	async (dispatch, getState) => {
		try {
			dispatch(setCompanyOrgChartPaginationLoading(true));

			const state = getState();

			const company = companySelector(state);

			const filters = companiesOrgChartFiltersSelector(state);

			const paramsObject = {...filters};

			if (paramsObject.current_seniority?.length > 0) {
				paramsObject.current_seniority = [...paramsObject.current_seniority].filter(
					(s) => s === seniorityTitle,
				);
			} else {
				paramsObject.current_seniority = [seniorityTitle];
			}

			const params = {
				...paramsObject,
				page: pageNumber, // even though its page 1, we will skip 1st 40
				per_page: 1000,
			};

			const response = await mapXCompanyApi.getCompanyOrganizationalChart(company.id, params);

			if (response && response?.status === 200 && response?.data) {
				const data = response.data.results;

				const paginatedData = data.find((item) => item.seniority === seniorityTitle);

				dispatch(addPaginatedDataForCompanyOrganizationalChart(paginatedData));
			}
		} catch (e) {
			console.error(e);
			dispatch(setCompanyOrgChartPaginationLoading(false));
		}
	};

export const getCompanyEthnicDiversityDataFilter = (id, params, config) => async (dispatch) => {
	let data;

	try {
		const res = await mapXCompanyApi.getCompanyEthnicDiversity(id, params, config);

		if (res?.status === 200) {
			data = res.data;
		} else {
			data = [];
		}

		dispatch(setCompanyEthnicDiversityData(data));
	} catch (e) {
		console.error(e);
	}
};

export const getCompanyEthnicDiversityData = (id, callback) => async (dispatch) => {
	let data;

	try {
		dispatch(setCompanyEthnicDiversityInit());

		const res = await mapXCompanyApi.getCompanyEthnicDiversity(id);

		if (res.status === 200) {
			data = res.data;
		} else {
			data = [];
		}

		dispatch(setCompanyEthnicDiversityData(data));
	} catch (e) {
		console.error(e);
	} finally {
		callback && callback();
	}
};

export const getCompanyFrequentlyHiredFromDataFilter = (id, params, config) => async (dispatch) => {
	try {
		const res = await mapXCompanyApi.getCompanyFrequentlyHiredFrom(id, 1, params, config);

		if (res && res?.status === 200) {
			dispatch(setCompanyFrequentlyHiredFromData(res.data));
		} else {
			dispatch(
				setCompanyFrequentlyHiredFromData({
					results: [],
					pagination: {
						page: 1,
					},
				}),
			);
		}
	} catch (e) {
		console.error(e);
	}
};

export const getCompanyFrequentlyHiredFromData =
	(id, callback, page, params, config) => async (dispatch) => {
		try {
			const res = await mapXCompanyApi.getCompanyFrequentlyHiredFrom(
				id,
				page,
				params,
				config,
			);

			if (res && res?.status === 200) {
				dispatch(setCompanyFrequentlyHiredFromData(res.data));
			} else {
				dispatch(setCompanyFrequentlyHiredFromData([]));
			}
		} catch (e) {
			console.error(e);
		} finally {
			callback && callback();
		}
	};

export const resetFilteredCompaniesData = () => async (dispatch) => {
	// dispatch(resetFilteredCompanies());
	dispatch(resetCompanySizeFilter());
	dispatch(resetCompanyRevenueFilter());
};

// MAP THIS FUNCTION ASYNC ACTIONS

export const getMapFunctionHistoryByCompanyId = (companyId) => async (dispatch) => {
	let response;
	companyId = parseInt(companyId); // from param usually we fetch it as string

	try {
		response = await mapXCompanyApi.getCompanyMapFunctionHistory(companyId);

		if (response.status === 200) {
			const companyMapData = response.data.results;

			if (companyMapData?.length > 0) {
				const latestInProgressCompanyMap = companyMapData.find(
					(item) => item.status === "In Progress",
				);

				if (latestInProgressCompanyMap) {
					dispatch(
						setMapThisFunctionDataForCurrentCompany({
							companyId: companyId,
							data: latestInProgressCompanyMap,
						}),
					);
				} else {
					const latestCompletedMap = companyMapData.find(
						(item) => item.status === "Completed",
					);

					if (latestCompletedMap) {
						dispatch(
							setMapThisFunctionDataForCurrentCompany({
								companyId: companyId,
								data: latestCompletedMap,
							}),
						);

						await dispatch(getCompanyMapFunctionStatusById(latestCompletedMap.id));
					}
				}
			}
		}
	} catch (error) {
		console.log(error, "Error from map this history for company");
	}
};

export const createMapFunctionByCompanyId = (formBody) => async (dispatch) => {
	let response;

	try {
		dispatch(
			setMapThisFunctionIsInProgressForCurrentCompany({
				companyId: formBody.company_id,
				completed: false,
			}),
		);

		response = await mapXCompanyApi.saveCompanyMapRequest(formBody);

		if (response.status === 201) {
			const createdMapData = response.data;

			if (createdMapData) {
				dispatch(
					setMapThisFunctionDataForCurrentCompany({
						companyId: createdMapData.company_id,
						data: createdMapData,
					}),
				);
			} else {
				console.log(createdMapData);
			}
		} else {
			console.log(response);

			dispatch(
				setMapThisFunctionIsInProgressForCurrentCompany({
					companyId: formBody.company_id,
					completed: true,
				}),
			);
		}
	} catch (error) {
		console.log(error, "Error from map this create for company");
	}
};

export const getCompanyMapFunctionStatusById = (mapId) => async (dispatch) => {
	let response;

	try {
		response = await mapXCompanyApi.getCompanyMapFunctionById(mapId);

		if (response.status === 200) {
			const data = response.data;

			dispatch(
				setMapThisFunctionDataForCurrentCompany({
					companyId: data.company_id,
					data,
				}),
			);

			if (
				data.status === "Canceled" ||
				data.status === "Completed" ||
				data.status === "Error"
			) {
				dispatch(
					setMapThisFunctionIsInProgressForCurrentCompany({
						companyId: data.company_id,
						completed: true,
					}),
				);
			} else {
				dispatch(
					setMapThisFunctionIsInProgressForCurrentCompany({
						companyId: data.company_id,
						completed: false,
					}),
				);
			}

			return {
				data,
				status: data.status,
				company_id: data.company_id,
				error: false,
			};
		}
	} catch (error) {
		return {error};
	}
};

export const updateTooltipCompaniesList = (companyId) => async (dispatch, getState) => {
	const {tooltipCompanies} = getState().company;

	const alreadyStoredCompany = tooltipCompanies && tooltipCompanies[companyId];

	if (!alreadyStoredCompany) {
		dispatch(updateTooltipCompaniesProgress());

		try {
			const {data} = await mapXCompanyApi.getCompany(companyId);
			dispatch(
				updateTooltipCompanies({
					...tooltipCompanies,
					[companyId]: data,
				}),
			);
		} catch (error) {
			dispatch(updateTooltipCompaniesFailed());
			console.log(error, "error");
		}
	}
};
