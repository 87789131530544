import React from "react";

const StartWithCompaniesIcon = () => {
	return (
		<svg
			width="64"
			height="64"
			viewBox="0 0 64 64"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect width="64" height="64" rx="8" fill="#EDF2F2" />
			<path
				d="M24.2758 42.4828H16.5517C16.0943 42.4828 15.7241 42.853 15.7241 43.3104C15.7241 43.7678 16.0943 44.138 16.5517 44.138H24.2759C24.7333 44.138 25.1035 43.7678 25.1035 43.3104C25.1035 42.853 24.7331 42.4828 24.2758 42.4828Z"
				fill="#0C5850"
			/>
			<path
				d="M24.2758 36.9656H16.5517C16.0943 36.9656 15.7241 37.3358 15.7241 37.7932C15.7241 38.2506 16.0943 38.6208 16.5517 38.6208H24.2759C24.7333 38.6208 25.1035 38.2506 25.1035 37.7932C25.1035 37.3358 24.7331 36.9656 24.2758 36.9656Z"
				fill="#0C5850"
			/>
			<path
				d="M24.2758 31.4484H16.5517C16.0943 31.4484 15.7241 31.8186 15.7241 32.276C15.7241 32.7334 16.0943 33.1036 16.5517 33.1036H24.2759C24.7333 33.1036 25.1035 32.7334 25.1035 32.276C25.1035 31.8186 24.7331 31.4484 24.2758 31.4484Z"
				fill="#0C5850"
			/>
			<path
				d="M24.2758 25.9312H16.5517C16.0943 25.9312 15.7241 26.3014 15.7241 26.7588C15.7241 27.2162 16.0943 27.5864 16.5517 27.5864H24.2759C24.7333 27.5864 25.1035 27.2162 25.1035 26.7588C25.1034 26.3014 24.7331 25.9312 24.2758 25.9312Z"
				fill="#0C5850"
			/>
			<path
				d="M24.2758 20.4138H16.5517C16.0943 20.4138 15.7241 20.784 15.7241 21.2414C15.7241 21.6988 16.0943 22.0691 16.5517 22.0691H24.2759C24.7333 22.0691 25.1035 21.6988 25.1035 21.2414C25.1034 20.7839 24.7331 20.4138 24.2758 20.4138Z"
				fill="#0C5850"
			/>
			<path
				d="M38.0689 42.4828H34.2068C33.7494 42.4828 33.3792 42.853 33.3792 43.3104C33.3792 43.7678 33.7494 44.138 34.2068 44.138H38.0689C38.5263 44.138 38.8965 43.7678 38.8965 43.3104C38.8965 42.853 38.5263 42.4828 38.0689 42.4828Z"
				fill="#0C5850"
			/>
			<path
				d="M38.0689 36.9656H34.2068C33.7494 36.9656 33.3792 37.3358 33.3792 37.7932C33.3792 38.2506 33.7494 38.6208 34.2068 38.6208H38.0689C38.5263 38.6208 38.8965 38.2506 38.8965 37.7932C38.8965 37.3358 38.5263 36.9656 38.0689 36.9656Z"
				fill="#0C5850"
			/>
			<path
				d="M38.0689 31.4484H34.2068C33.7494 31.4484 33.3792 31.8186 33.3792 32.276C33.3792 32.7334 33.7494 33.1036 34.2068 33.1036H38.0689C38.5263 33.1036 38.8965 32.7334 38.8965 32.276C38.8965 31.8186 38.5263 31.4484 38.0689 31.4484Z"
				fill="#0C5850"
			/>
			<path
				d="M38.0689 25.9312H34.2068C33.7494 25.9312 33.3792 26.3014 33.3792 26.7588C33.3792 27.2162 33.7494 27.5864 34.2068 27.5864H38.0689C38.5263 27.5864 38.8965 27.2162 38.8965 26.7588C38.8965 26.3014 38.5263 25.9312 38.0689 25.9312Z"
				fill="#0C5850"
			/>
			<path
				d="M47.4483 42.4828H43.5862C43.1288 42.4828 42.7585 42.853 42.7585 43.3104C42.7585 43.7678 43.1288 44.138 43.5862 44.138H47.4483C47.9057 44.138 48.2759 43.7678 48.2759 43.3104C48.2759 42.853 47.9058 42.4828 47.4483 42.4828Z"
				fill="#0C5850"
			/>
			<path
				d="M47.4483 36.9656H43.5862C43.1288 36.9656 42.7585 37.3358 42.7585 37.7932C42.7585 38.2506 43.1288 38.6208 43.5862 38.6208H47.4483C47.9057 38.6208 48.2759 38.2506 48.2759 37.7932C48.2759 37.3358 47.9058 36.9656 47.4483 36.9656Z"
				fill="#0C5850"
			/>
			<path
				d="M47.4483 31.4484H43.5862C43.1288 31.4484 42.7585 31.8186 42.7585 32.276C42.7585 32.7334 43.1288 33.1036 43.5862 33.1036H47.4483C47.9057 33.1036 48.2759 32.7334 48.2759 32.276C48.2759 31.8186 47.9058 31.4484 47.4483 31.4484Z"
				fill="#0C5850"
			/>
			<path
				d="M47.4483 25.9312H43.5862C43.1288 25.9312 42.7585 26.3014 42.7585 26.7588C42.7585 27.2162 43.1288 27.5864 43.5862 27.5864H47.4483C47.9057 27.5864 48.2759 27.2162 48.2759 26.7588C48.2759 26.3014 47.9058 25.9312 47.4483 25.9312Z"
				fill="#0C5850"
			/>
			<path
				d="M55.1724 49.6552H53.1619C53.2088 49.4776 53.2413 49.2949 53.2413 49.1035V22.069C53.2413 20.8552 52.2482 19.8621 51.0344 19.8621H47.1365C47.1569 19.7496 47.1717 19.6354 47.1717 19.5173V18.7427C47.1717 17.5289 46.1792 16.5358 44.9649 16.5358H38.3442C37.1309 16.5358 36.1373 17.5289 36.1373 18.7427V19.5173C36.1373 19.6354 36.1522 19.7496 36.1725 19.8621H30.6206C30.4291 19.8621 30.2459 19.8947 30.0688 19.9411V14.8966C30.0688 13.6828 29.0757 12.6897 27.862 12.6897H12.9656C11.7518 12.6897 10.7587 13.6828 10.7587 14.8966V49.1034C10.7587 49.2949 10.7912 49.4775 10.8381 49.6551H8.82763C8.37022 49.6551 8 50.0254 8 50.4828C8 50.9402 8.37022 51.3104 8.82763 51.3104H55.1724C55.6298 51.3104 56 50.9402 56 50.4828C56 50.0254 55.6298 49.6552 55.1724 49.6552ZM28.4138 22.069V49.1035C28.4138 49.4025 28.1611 49.6552 27.8621 49.6552H12.9656C12.6666 49.6552 12.4138 49.4026 12.4138 49.1035V14.8966C12.4138 14.5976 12.6665 14.3449 12.9656 14.3449H27.8622C28.1611 14.3449 28.4139 14.5975 28.4139 14.8966V22.069H28.4138ZM37.7925 18.7427C37.7925 18.4437 38.0457 18.1909 38.3442 18.1909H44.965C45.2646 18.1909 45.5167 18.4436 45.5167 18.7427V19.5173C45.5167 19.6487 45.462 19.7661 45.3826 19.8621H37.9272C37.8477 19.7661 37.7925 19.6486 37.7925 19.5173V18.7427ZM51.5862 49.1036C51.5862 49.4026 51.3335 49.6553 51.0344 49.6553H30.6207C30.3217 49.6553 30.0689 49.4027 30.0689 49.1036V22.069C30.0689 21.77 30.3216 21.5173 30.6207 21.5173H51.0344C51.3334 21.5173 51.5862 21.7699 51.5862 22.069V49.1036Z"
				fill="#0C5850"
			/>
		</svg>
	);
};

export default StartWithCompaniesIcon;
