import React from "react";
import styles from "./styles.module.scss";
import StartWithCompaniesIcon from "mapx-components/Modals/ProjectCreateWithWorkflowsModal/Icons/StartWithCompaniesIcon";
import CreateWithAIIcon from "mapx-components/Modals/ProjectCreateWithWorkflowsModal/Icons/CreateWithAIIcon";
import {useAppDispatch, useAppSelector} from "hooks";
import {TProjectCreationTypes} from "./types";
import {setProjectWorkFlowStep} from "store/mapx/project-list/projectListActions";
import StartWithJobTitleIcon from "mapx-components/Modals/ProjectCreateWithWorkflowsModal/Icons/StartWithJobTitleIcon";
import {hasAccessToExtendedPagesSelector} from "store/mapx/user/userSelectors";

const ProjectCreationTypes = () => {
	const dispatch = useAppDispatch();

	const hasFullAccess = useAppSelector(hasAccessToExtendedPagesSelector);

	const handleStepClick = (step: TProjectCreationTypes) => {
		dispatch(setProjectWorkFlowStep(step));
	};

	return (
		<div className={styles.projectCreationTypesWrapper}>
			<div className={styles.projectCreationTypes}>
				{hasFullAccess && (
					<div
						className={styles.projectCreationItemCard}
						onClick={() => handleStepClick("Start With Companies")}
					>
						<StartWithCompaniesIcon />
						<div className={styles.title}>Start with companies</div>
						<div className={styles.description}>
							Search in a specific list of companies or use a previously saved company
							target list
						</div>
					</div>
				)}

				{hasFullAccess && (
					<div
						className={styles.projectCreationItemCard}
						onClick={() => handleStepClick("Start With Job Title")}
					>
						<StartWithJobTitleIcon />
						<div className={styles.title}>Start with job title</div>
						<div className={styles.description}>
							Build a structured search for a specific role
						</div>
					</div>
				)}

				<div
					className={styles.projectCreationItemCard}
					onClick={() => handleStepClick("Create With AI")}
				>
					<CreateWithAIIcon />
					<div className={styles.title}>Create with AI</div>
					<div className={styles.description}>
						Give AI an example profile to find matches to or describe the type of person
						you are looking for
					</div>
				</div>
			</div>

			{hasFullAccess && (
				<div
					className={styles.scratchCreateProjectLink}
					onClick={() => handleStepClick("Create From Scratch")}
				>
					<span>or Create a project from scratch</span>
				</div>
			)}
		</div>
	);
};

export default ProjectCreationTypes;
