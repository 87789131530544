import React, {useMemo} from "react";
import styles from "./inputProfilesButton.module.scss";
import {TInputCandidateProps} from "mapx-components/Buttons/InputProfilesButton/types";

const InputProfilesButton = ({candidate, onClick}: TInputCandidateProps) => {
	const handleClick = (id: number) => {
		if (onClick) {
			onClick(id);
		}
	};

	const AVATAR_ICON_COMPONENT = useMemo(
		() => (
			<svg
				width="16"
				height="16"
				viewBox="0 0 16 16"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M11.1133 8.7533C12.0266 9.3733 12.6666 10.2133 12.6666 11.3333V13.3333H15.3333V11.3333C15.3333 9.87996 12.9533 9.01996 11.1133 8.7533Z"
					fill="#808080"
				/>
				<path
					d="M6.00065 7.99996C7.47341 7.99996 8.66732 6.80605 8.66732 5.33329C8.66732 3.86053 7.47341 2.66663 6.00065 2.66663C4.52789 2.66663 3.33398 3.86053 3.33398 5.33329C3.33398 6.80605 4.52789 7.99996 6.00065 7.99996Z"
					fill="#808080"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M9.99995 7.99996C11.4733 7.99996 12.6666 6.80663 12.6666 5.33329C12.6666 3.85996 11.4733 2.66663 9.99995 2.66663C9.68661 2.66663 9.39328 2.73329 9.11328 2.82663C9.66661 3.51329 9.99995 4.38663 9.99995 5.33329C9.99995 6.27996 9.66661 7.15329 9.11328 7.83996C9.39328 7.93329 9.68661 7.99996 9.99995 7.99996Z"
					fill="#808080"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M6.00033 8.66663C4.22033 8.66663 0.666992 9.55996 0.666992 11.3333V13.3333H11.3337V11.3333C11.3337 9.55996 7.78032 8.66663 6.00033 8.66663Z"
					fill="#808080"
				/>
			</svg>
		),
		[],
	);

	return (
		<button className={styles.tag} onClick={() => handleClick(candidate.id)}>
			{candidate.avatar_url !== null ? (
				<img
					src={candidate.avatar_url}
					className={styles.avatar}
					alt={candidate.full_name}
				/>
			) : (
				AVATAR_ICON_COMPONENT
			)}
			<div className={styles.infoBlock}>
				<span className={styles.tagText}>{candidate.full_name}</span>
				<span style={{color: "#999", fontSize: 12}}>{candidate.job_title}</span>
			</div>
		</button>
	);
};

export default InputProfilesButton;
