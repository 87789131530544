import type {FC, SyntheticEvent} from "react";
import classNames from "classnames";

import type {ICompanyItemProps} from "./types";
import styles from "./companyItem.module.scss";

const defaultLogo = "https://storage.googleapis.com/nebula-static/logos100x100/empty_logo.png";

const CompanyItem: FC<ICompanyItemProps> = ({suggestion, onClick, ...rest}) => {
	const {logo_url, name} = suggestion;

	return (
		<span
			{...rest}
			onClick={() => onClick(suggestion)}
			className={classNames(styles.companyItem)}
		>
			<span className={styles.logo}>
				<img
					alt={name}
					onError={(e: SyntheticEvent<HTMLImageElement, Event>) => {
						(e.target as HTMLImageElement).src = defaultLogo;
					}}
					src={logo_url || defaultLogo}
				/>
			</span>

			<span className={styles.text} title={name}>
				{name}
			</span>
		</span>
	);
};

export default CompanyItem;
