import {IconProps} from "./types";

const ProfileResultsIcon = ({className = "", fill = "#D9D9D9"}: IconProps) => (
	<svg
		width="20"
		height="20"
		viewBox="0 0 20 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		className={className}
	>
		<mask
			id="mask0_225_8842"
			style={{maskType: "alpha"}}
			maskUnits="userSpaceOnUse"
			x="0"
			y="0"
			width="20"
			height="20"
		>
			<rect width="20" height="20" fill={fill} />
		</mask>
		<g mask="url(#mask0_225_8842)">
			<path
				d="M13 12C12.3056 12 11.7153 11.7569 11.2292 11.2708C10.7431 10.7847 10.5 10.1944 10.5 9.5C10.5 8.80556 10.7431 8.21528 11.2292 7.72917C11.7153 7.24306 12.3056 7 13 7C13.6944 7 14.2847 7.24306 14.7708 7.72917C15.2569 8.21528 15.5 8.80556 15.5 9.5C15.5 10.1944 15.2569 10.7847 14.7708 11.2708C14.2847 11.7569 13.6944 12 13 12ZM8 17V15.6875C8 15.2986 8.10069 14.9444 8.30208 14.625C8.50347 14.3056 8.77083 14.0625 9.10417 13.8958C9.70139 13.6042 10.3264 13.3819 10.9792 13.2292C11.6319 13.0764 12.3056 13 13 13C13.6944 13 14.3681 13.0764 15.0208 13.2292C15.6736 13.3819 16.2986 13.6042 16.8958 13.8958C17.2292 14.0625 17.4965 14.3056 17.6979 14.625C17.8993 14.9444 18 15.2986 18 15.6875V17H8ZM9.52083 15.5H16.4792C16.0625 15.1806 15.5625 14.934 14.9792 14.7604C14.3958 14.5868 13.7361 14.5 13 14.5C12.2639 14.5 11.6042 14.5868 11.0208 14.7604C10.4375 14.934 9.9375 15.1806 9.52083 15.5ZM13 10.5C13.2833 10.5 13.5208 10.4042 13.7125 10.2125C13.9042 10.0208 14 9.78333 14 9.5C14 9.21667 13.9042 8.97917 13.7125 8.7875C13.5208 8.59583 13.2833 8.5 13 8.5C12.7167 8.5 12.4792 8.59583 12.2875 8.7875C12.0958 8.97917 12 9.21667 12 9.5C12 9.78333 12.0958 10.0208 12.2875 10.2125C12.4792 10.4042 12.7167 10.5 13 10.5ZM3 11.75V10.25H9V11.75H3ZM3 5.5V4H12V5.5H3ZM9.10417 8.625H3V7.125H9.79167C9.625 7.34722 9.48532 7.58179 9.37262 7.82871C9.25994 8.07563 9.17046 8.34106 9.10417 8.625Z"
				fill="#868D8D"
			/>
		</g>
	</svg>
);

export default ProfileResultsIcon;
