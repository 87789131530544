import type {FC} from "react";

import type {IconProps} from "./types";

const LogoIconSmall: FC<IconProps> = ({className = ""}) => (
	<svg
		width="36"
		height="32"
		viewBox="0 0 36 32"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		className={className}
	>
		<g>
			<path
				d="M1.70092 30.1632L10.8628 18.8841L12.6944 20.2113L6.69728 27.6742H9.69585L14.1937 22.3656L18.8577 27.6742H28.0196L11.1914 6.93967H14.6884L33.6807 30.1594H17.3509L14.1862 26.6779L11.1876 30.1594H1.68958L1.70092 30.1632ZM18.363 23.5273H21.8601L4.86944 2.96194H14.3674L18.6991 8.60145L23.6993 2.96194H26.5317L20.0322 10.5903L21.8639 12.4138L32.0265 0.307617H22.3624L18.6954 4.45453L15.8629 0.307617H-0.296875L18.363 23.5273Z"
				fill="#0C5850"
			/>
		</g>
		<defs>
			<clipPath id="clip0_6351_132">
				<rect width="35" height="32" fill="white" transform="translate(0.5)" />
			</clipPath>
		</defs>
	</svg>
);

export default LogoIconSmall;
