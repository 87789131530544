import type {FC} from "react";
import classNames from "classnames";

import NotEnoughDataIcon from "assets/icons/NotEnoughDataIcon";

import type {TNotEnoughDataProps} from "./types";
import styles from "./notEnoughData.module.scss";

const NotEnoughData: FC<TNotEnoughDataProps> = ({
	applySmallSize = false,
	flexCol = false,
	title = "There is not enough data to display ethnic diversity report.",
}) => (
	<div
		className={classNames(styles.notEnoughDataWarningRow, {
			[styles.notEnoughDataWarningColumn]: flexCol,
		})}
	>
		<div style={{width: applySmallSize ? "75px" : "auto", display: "flex"}}>
			<NotEnoughDataIcon />
		</div>

		<div
			className={classNames(styles.warningContent, {
				[styles.warningContentSmall]: flexCol,
			})}
		>
			<p
				className={classNames(styles.warningTitle, {
					[styles.smallerWarningTitle]: applySmallSize,
				})}
			>
				Not Enough Data
			</p>
			<span
				className={classNames(styles.warningText, {
					[styles.smallerWarningText]: applySmallSize,
				})}
			>
				{title}
			</span>
		</div>
	</div>
);

export default NotEnoughData;
