import {type FC, Fragment, type SyntheticEvent, useEffect, useRef} from "react";

import {Button} from "components";
import {DeleteUserIcon} from "assets/icons";
import {useLockBodyScroll, useOutsideClick} from "hooks";

import type {TDeleteUserModalProps} from "./types";
import styles from "./DeleteUserModal.module.scss";

const DeleteUserModal: FC<TDeleteUserModalProps> = ({
	isOpen,
	loading,
	setIsOpen,
	titleText,
	submitText,
	deleteHandler,
}) => {
	const ref = useRef<HTMLDivElement | null>(null);

	const closeToggle = () => setIsOpen(false);

	const deleteClickHandler = (e: SyntheticEvent) => {
		e.preventDefault();
		deleteHandler?.();
	};

	useEffect(() => {
		if (!loading) {
			setIsOpen(false);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loading]);

	useLockBodyScroll(isOpen);
	useOutsideClick(ref, closeToggle);

	return (
		<Fragment>
			{isOpen && (
				<div className={styles.container} data-testid="delete-user-modal">
					<div ref={ref} className={styles.wrapper}>
						<DeleteUserIcon />
						<p className={styles.wrapper__title}>{titleText}</p>
						<div className={styles.wrapper__buttons}>
							<Button
								disabled={loading}
								onClick={closeToggle}
								className={styles.wrapper__buttons_cancel}
							>
								Cancel
							</Button>
							<Button
								loading={loading}
								onClick={deleteClickHandler}
								className={styles.wrapper__buttons_delete}
								data-testid="delete-user-modal_delete_button"
							>
								{submitText}
							</Button>
						</div>
					</div>
				</div>
			)}
		</Fragment>
	);
};
export default DeleteUserModal;
