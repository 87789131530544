import type {IconProps} from "./types";

const DriveFolderUploadIcon = (props: IconProps) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={24}
			height={24}
			viewBox="0 0 24 24"
			fill="none"
			{...props}
		>
			<mask
				id="a"
				width={24}
				height={24}
				x={0}
				y={0}
				maskUnits="userSpaceOnUse"
				style={{
					maskType: "alpha",
				}}
			>
				<path fill="#D9D9D9" d="M0 0h24v24H0z" />
			</mask>
			<g mask="url(#a)">
				<path
					fill="#404040"
					d="M11 17h2v-4.2l1.6 1.6L16 13l-4-4-4 4 1.4 1.4 1.6-1.6V17Zm-7 3c-.55 0-1.02-.196-1.413-.587A1.926 1.926 0 0 1 2 18V6c0-.55.196-1.02.587-1.412A1.926 1.926 0 0 1 4 4h6l2 2h8c.55 0 1.02.196 1.413.588.391.391.587.862.587 1.412v10c0 .55-.196 1.02-.587 1.413A1.926 1.926 0 0 1 20 20H4ZM4 6v12h16V8h-8.825l-2-2H4Z"
				/>
			</g>
		</svg>
	);
};

export default DriveFolderUploadIcon;
