import {useMemo} from "react";

import type {IListItem} from "./types";

const useCheckboxListSortHook = (data = []) => {
	return useMemo(() => {
		const set = new Set();

		return data.sort((a: IListItem, b: IListItem) => {
			const isASelected = set.has(a.id);

			const isBSelected = set.has(b.id);

			if (isASelected) {
				return -1;
			}

			if (isBSelected) {
				return 1;
			}

			return 0;
		});
	}, [data]);
};

export default useCheckboxListSortHook;
