import type {FC} from "react";

import {SortArrowsIcon, DescendingIcon} from "assets/icons";

import type {TOrderingButtonProps} from "./types";
import styles from "./OrderingButton.module.scss";

const OrderingButton: FC<TOrderingButtonProps> = ({
	onClick,
	isAscendingOrder,
	isDescendingOrder,
}) => {
	const renderSortIcon = () => {
		if (isDescendingOrder) {
			return <DescendingIcon className={styles.order__ascending} />;
		} else if (isAscendingOrder) {
			return <DescendingIcon />;
		} else {
			return <SortArrowsIcon />;
		}
	};

	return (
		<button onClick={onClick} className={styles.order}>
			{renderSortIcon()}
		</button>
	);
};

export default OrderingButton;
