import React from "react";

const RecommendedIcon = () => {
	return (
		<svg
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<circle cx="10" cy="10" r="10" fill="#D58A31" />
			<mask
				id="mask0_946_13590"
				style={{maskType: "alpha"}}
				maskUnits="userSpaceOnUse"
				x="2"
				y="1"
				width="16"
				height="16"
			>
				<rect x="2" y="1" width="16" height="16" fill="#D9D9D9" />
			</mask>
			<g mask="url(#mask0_946_13590)">
				<path
					d="M5.8834 15.6667L6.96674 10.9833L3.3334 7.83333L8.1334 7.41667L10.0001 3L11.8667 7.41667L16.6667 7.83333L13.0334 10.9833L14.1167 15.6667L10.0001 13.1833L5.8834 15.6667Z"
					fill="white"
				/>
			</g>
		</svg>
	);
};

export default RecommendedIcon;
