import {Fragment, ReactNode} from "react";
import classNames from "classnames";

import {
	Lightbulb,
	XPlusIcon,
	ShareIcon,
	EnhanceIcon,
	MapXStarsIcon,
	HelpIconTooltip,
	OnboardingTooltipArrowIcon,
} from "assets/icons";
import {Button} from "components";
import GeneralButton from "mapx-components/Buttons/GeneralButton";

import {EOnboardingTooltipSteps, type TFilterFakeData} from "./types";
import styles from "./OnboardingTooltip.module.scss";

export const contentClassName: (step: number | null, className?: string) => string = (
	step,
	className = "",
) => {
	return classNames(styles.wrapper__content, {
		[className]: className,
		[styles.wrapper__content__width]: step === EOnboardingTooltipSteps.First,
		[styles.wrapper__content__width_2]:
			step === EOnboardingTooltipSteps.Second || step === EOnboardingTooltipSteps.Third,
		[styles.wrapper__content__width_4]: step === EOnboardingTooltipSteps.Fourth,
		[styles.wrapper__content__horizontal]: step === EOnboardingTooltipSteps.Fourth,
		[styles.wrapper__content__width_5]:
			step === EOnboardingTooltipSteps.Fifth || step === EOnboardingTooltipSteps.Sixth,
	});
};

export const filterData: TFilterFakeData[] = [
	{id: 1, name: "Companies"},
	{id: 2, name: "Seniority"},
	{id: 3, name: "Function & Specialisms"},
	{id: 4, name: "Locations"},
	{id: 5, name: "Keyword"},
	{id: 6, name: "Diversity"},
	{id: 7, name: "Industries"},
];

export const renderText: (e: number | null, isStarterUser?: boolean) => string = (
	step: number | null,
) => {
	switch (step) {
		case EOnboardingTooltipSteps.First:
			return "SearchRequestResults List";

		case EOnboardingTooltipSteps.Second:
			return "Generate More Results";

		case EOnboardingTooltipSteps.Third:
			return "More Like This";

		case EOnboardingTooltipSteps.Fourth:
			return "Filter Your SearchRequestResults";

		case EOnboardingTooltipSteps.Fifth:
			return "Share Project";

		case EOnboardingTooltipSteps.Sixth:
			return "Start New Search";

		default:
			return "";
	}
};

export const returnStepContent: (step: number | null, isStarterUser?: boolean) => ReactNode = (
	step: number | null,
) => {
	switch (step) {
		case EOnboardingTooltipSteps.Search: {
			return (
				<div className={styles.wrapper__content__container__search}>
					<div className={styles.wrapper__content__container__search_banner}>
						<p className={styles.wrapper__content__container__search_banner_title}>
							<Lightbulb />
							Examples
						</p>
						<p className={styles.wrapper__content__container__search_banner_empty}>
							<span
								className={
									styles.wrapper__content__container__search_banner_empty_dot
								}
							/>
							Ideas for Google’s next CTO
						</p>
						<p className={styles.wrapper__content__container__search_banner_empty}>
							<span
								className={
									styles.wrapper__content__container__search_banner_empty_dot
								}
							/>
							Market Access Director for large US based pharma and life sciences
							businesses
						</p>
						<p className={styles.wrapper__content__container__search_banner_empty}>
							<span
								className={
									styles.wrapper__content__container__search_banner_empty_dot
								}
							/>
							Top AI Talent in San Francisco
						</p>
						<p className={styles.wrapper__content__container__search_banner_empty}>
							<span
								className={
									styles.wrapper__content__container__search_banner_empty_dot
								}
							/>
							M&A specialists in businesses similar to JP Morgan
						</p>
					</div>

					<p className={styles.wrapper__content__container__search_sub}>
						<HelpIconTooltip /> Your first project
					</p>

					<p className={styles.wrapper__content__container__search_info}>
						Let’s create your first project by describing a role in a company or in an
						industry.
						<br /> We can try typing something like{" "}
						<strong className={styles.wrapper__content__container__search_info_strong}>
							“Ideas for Google’s next CTO”
						</strong>{" "}
						and then clicking{" "}
						<strong className={styles.wrapper__content__container__search_info_strong}>
							“Search”
						</strong>
					</p>
				</div>
			);
		}

		case EOnboardingTooltipSteps.First: {
			return (
				<Fragment>
					<p
						className={classNames(
							styles.wrapper__content__container_text,
							styles.wrapper__content__container__one,
						)}
					>
						MapX will search for people that match your description. After a minute or
						so, your results will start appearing in a list.
					</p>

					<p
						className={classNames(
							styles.wrapper__content__container_text,
							styles.wrapper__content__container__second,
						)}
					>
						Clicking on their name opens their profile and clicking
					</p>
					<p
						className={classNames(
							styles.wrapper__content__container_text,
							styles.wrapper__content__container__third,
						)}
					>
						saves them to your project.
					</p>
				</Fragment>
			);
		}

		case EOnboardingTooltipSteps.Second: {
			return (
				<p
					className={classNames(
						styles.wrapper__content__container_text,
						styles.wrapper__content__container__second,
					)}
				>
					You can find more candidates with
					<XPlusIcon style={{position: "relative", top: 50}} />
				</p>
			);
		}

		case EOnboardingTooltipSteps.Third: {
			return (
				<p
					className={classNames(
						styles.wrapper__content__container_text,
						styles.wrapper__content__container__second,
					)}
				>
					click this button for more profile matches
				</p>
			);
		}
		case EOnboardingTooltipSteps.Fourth: {
			return (
				<Fragment>
					<p
						className={classNames(
							styles.wrapper__content__container_text,
							styles.wrapper__content__container__fourth,
						)}
					>
						Find specific people or prioritize diverse profiles by using the filters
						section.
					</p>

					<div className={styles.wrapper__content__container__fourth__container}>
						<MapXStarsIcon
							className={styles.wrapper__content__container__fourth__container_stars}
						/>
						<XPlusIcon className={styles.wrapper__content__container__plus_logo} />
					</div>
				</Fragment>
			);
		}
		case EOnboardingTooltipSteps.Fifth: {
			return (
				<div className={styles.wrapper__content__container__fifth}>
					<OnboardingTooltipArrowIcon
						className={styles.wrapper__content__container__fifth_icon}
					/>
					<p
						className={classNames(
							styles.wrapper__content__container_text,
							styles.wrapper__content__container__fifth_margin,
						)}
					>
						You can share your project with other members
					</p>

					<Button
						defaultBtnStyle
						RightIcon={ShareIcon}
						className={styles.wrapper__content__container__fifth_shared}
					>
						Share
					</Button>
				</div>
			);
		}

		case EOnboardingTooltipSteps.Sixth: {
			return (
				<div className={styles.wrapper__content__container__fifth}>
					<OnboardingTooltipArrowIcon
						className={styles.wrapper__content__container__fifth_icon}
					/>

					<p
						className={classNames(
							styles.wrapper__content__container_text,
							styles.wrapper__content__container__third,
						)}
					>
						You can always start a new search with
					</p>

					<GeneralButton
						title="Start New Search"
						icon={<EnhanceIcon color="white" />}
						className={styles.search__button}
					/>
				</div>
			);
		}
		default:
			return <Fragment />;
	}
};
