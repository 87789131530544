import axios from "axios";
import type {STContactsBlockedByCountryForm} from "api/hubspotApi/types";

class Index {
	async registerContactsBlockedByCountryForm(
		payload: STContactsBlockedByCountryForm,
		config = {},
	) {
		const response = await axios.post(
			"https://api.hsforms.com/submissions/v3/integration/submit/26144956/8bf73935-896b-4ad5-a196-d7a67c589d9f",
			payload,
			config,
		);

		return response.data;
	}
}

const mapXHubspotApi = new Index();

export default mapXHubspotApi;
