import {TLinkedInUrlImportItem} from "./types";

export const sortUrls = (urls: TLinkedInUrlImportItem[]) => {
	const statusPriority: {[key: string]: number} = {
		success: 1,
		imported: 1,
		completed: 1,
		"already exists": 1,
		"scraping error": 2,
		"import error": 3,
		"not found": 4,
		"bad url": 5,
	};

	if (!urls) return [];

	// Step 1: Filter duplicates by keeping the highest priority status
	const uniqueUrls: {[url: string]: TLinkedInUrlImportItem} = {};

	urls.forEach((url) => {
		const status = url.status?.toLowerCase();
		const priority = statusPriority[status] || 2;

		// If URL is not in the map or the current status has a higher priority, update the map
		if (
			!uniqueUrls[url.url] ||
			(uniqueUrls[url.url] &&
				priority < statusPriority[uniqueUrls[url.url].status.toLowerCase()])
		) {
			uniqueUrls[url.url] = url;
		}
	});

	// Step 2: Convert the map back to an array and sort by status priority
	return Object.values(uniqueUrls).sort((a, b) => {
		const priorityA = statusPriority[a.status.toLowerCase()] || 2;
		const priorityB = statusPriority[b.status.toLowerCase()] || 2;

		return priorityA - priorityB;
	});
};
