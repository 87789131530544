import React, {useEffect, useState} from "react";
import styles from "./styles.module.scss";
import {useAppDispatch, useAppSelector} from "hooks";
import {createFreeTextQuickSearch} from "store/mapx/additional-profiles/quickSearchAsyncActions";
import {toast} from "react-toastify";
import {ToastContent} from "components";
import {GeneralButton} from "mapx-components";
import {EnhanceIcon} from "assets/icons";
import classNames from "classnames";
import SearchRequestResults from "mapx-components/SearchRequestResults";
import {
	clearAPActiveResult,
	clearAPFilters,
	setAPCandidateTargetListToggle,
} from "store/mapx/additional-profiles/additionalProfilesActions";
import TextSearchRequest from "mapx-components/TextSearchRequest";
import {activeSearchRequestSelector} from "store/mapx/additional-profiles/additionalProfilesSelectors";

const CandidateTextSearch = () => {
	const dispatch = useAppDispatch();

	const [searchText, setSearchText] = useState<string>("");

	const [loading, setLoading] = useState<boolean>(false);

	const activeSearchRequest = useAppSelector(activeSearchRequestSelector);

	const handleButtonClick = async () => {
		if (searchText.length < 2) return;

		setLoading(true);

		const {status, message} = await dispatch(createFreeTextQuickSearch(searchText));

		setLoading(false);

		if (status === "success") {
			// history.push(`/project/${project.id}`);
		} else {
			toast.error(ToastContent, {
				autoClose: 2000,
				closeOnClick: true,
				data: {title: "Error!", description: message},
			});
		}
	};

	useEffect(() => {
		return () => {
			// dispatch(clearActiveSearchRequest());
			dispatch(clearAPActiveResult());
			dispatch(clearAPFilters());
			dispatch(setAPCandidateTargetListToggle(false));
		};
	}, [dispatch, activeSearchRequest]);

	return (
		<div className={styles.container}>
			<div className={styles.searchBarContainer}>
				<TextSearchRequest
					searchText={searchText}
					setSearchText={setSearchText}
					loading={loading}
					handleButtonClick={handleButtonClick}
				/>

				<GeneralButton
					icon={<EnhanceIcon color="white" />}
					title={"Search"}
					customClass={classNames(styles.quickSearchButton, {
						[styles.disabled]: searchText.length < 2,
					})}
					loadingSave={loading}
					onClick={handleButtonClick}
					disabled={searchText.length < 2}
				/>
			</div>

			<div className={styles.resultsWrapper}>
				<SearchRequestResults searchType={"Free Text Input Search"} />
			</div>
		</div>
	);
};

export default CandidateTextSearch;
