import {BaseSyntheticEvent, FC, Fragment, SyntheticEvent, useState} from "react";

import {Button} from "components";
import {useAppDispatch, useAppSelector} from "hooks";
import {PlusIcon} from "assets/icons";
import {CreateOrganizationModal} from "mapx-components";
import {createOrganisation} from "store/mapx/organisation/organisationAsyncFunctions";
import {createOrganisationInProgressSelector} from "store/mapx/organisation/organisationSelectors";

import OrganizationTable from "./OrganizationTable";
import styles from "./OrganizationList.module.scss";

const OrganizationList: FC = () => {
	const dispatch = useAppDispatch();

	const [organizationName, setOrganizationName] = useState<string>("");
	const [openCreateModal, setOpenCreateModal] = useState<boolean>(false);
	const createOrgSelector = useAppSelector(createOrganisationInProgressSelector);

	const changeOrgName = (e: BaseSyntheticEvent) => {
		setOrganizationName(e.target.value);
	};

	const onSubmit = (e: SyntheticEvent) => {
		e.preventDefault();
		dispatch(createOrganisation(organizationName));
		setOrganizationName("");
	};

	const openCreateOrgModal = () => {
		setOrganizationName("");
		setOpenCreateModal(true);
	};

	return (
		<Fragment>
			<div className={styles.wrapper}>
				<div className={styles.wrapper__head}>
					<h3 className={styles.wrapper__head_title}>Organization List</h3>

					<Button
						LeftIcon={PlusIcon}
						onClick={openCreateOrgModal}
						className={styles.wrapper__head__create}
						data-testid="organization-list-create-button"
						leftIconClasses={styles.wrapper__head__create_icon}
					>
						Create an Organization
					</Button>
				</div>

				<hr className={styles.wrapper__hr} />

				<OrganizationTable />
			</div>

			<CreateOrganizationModal
				onSubmit={onSubmit}
				isOpen={openCreateModal}
				onChange={changeOrgName}
				label="Organization Name"
				loading={createOrgSelector}
				title="Create an Organization"
				setIsOpen={setOpenCreateModal}
				organizationName={organizationName}
			/>
		</Fragment>
	);
};

export default OrganizationList;
