import {type FC, Fragment, useEffect} from "react";
import classNames from "classnames";
import {HiOutlineDownload} from "react-icons/hi";

import {Button} from "components";
import {useAppDispatch} from "hooks";
import {OnboardingTooltip} from "mapx-components";
import {ShareIcon, PencilIconButton} from "assets/icons";
import {setOnboardingStep} from "store/mapx/project/projectActions";
import {EOnboardingTooltipSteps} from "mapx-components/OnboardingTooltip/types";

import FakeTabs from "./FakeTabs";
import FakeResults from "./FakeResults";

import type {TStepBoardingType} from "../types";
import styles from "./OnboardingProjects.module.scss";

const OnboardingProjects: FC<TStepBoardingType> = ({step, userLicenseTypeName}) => {
	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(setOnboardingStep(EOnboardingTooltipSteps.First));

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Fragment>
			<div className={styles.head}>
				<p className={styles.head__text}>Ideas for Google’s next CTO</p>

				<div className={styles.head__right}>
					<div className={styles.head__right__shared}>
						<Button
							defaultBtnStyle
							RightIcon={ShareIcon}
							className={classNames(styles.head__right__shared_btn, {
								[styles.head__right__shared_btn_z_index]: step === 5,
							})}
						>
							Share
						</Button>

						<OnboardingTooltip
							className={styles.head__right__shared__boarding}
							step={step === EOnboardingTooltipSteps.Fifth ? step : null}
						/>
					</div>

					<PencilIconButton className={styles.head__right__edit} />

					<Button
						defaultBtnStyle
						LeftIcon={HiOutlineDownload}
						className={styles.head__right__button}
					>
						Download
					</Button>
				</div>
			</div>

			<FakeTabs />

			<FakeResults step={step} userLicenseTypeName={userLicenseTypeName} />
		</Fragment>
	);
};

export default OnboardingProjects;
