import React, {useCallback} from "react";
import {useSelector} from "react-redux";
import {
	keywordPaginationSelector,
	keywordSearchQuerySelector,
} from "store/mapx/search/searchSelectors";
import {useAppSelector} from "hooks";
import {setKeywordSearchQuery} from "store/mapx/search/searchActions";
import {getKeywordsList} from "store/mapx/search/searchAsyncActions";
import {companiesOrgChartFiltersSelector} from "store/mapx/company/companySelectors";
import KeywordSearchFilter from "./keywordSearchFilter";
import {TOrgChartFilterProps} from "../types";

function KeywordFilterForOrgChart({
	onSelectChange,
}: {
	onSelectChange: TOrgChartFilterProps["onSelectChange"];
}) {
	const keywordSearchQuery = useSelector(keywordSearchQuerySelector);
	const keywordPagination = useSelector(keywordPaginationSelector);
	const filtersInState = useAppSelector(companiesOrgChartFiltersSelector);
	const selectedKeywords = filtersInState?.current_keywords;

	const handleResetClick = useCallback(() => {
		onSelectChange("current_keywords", null);
	}, [onSelectChange]);

	return (
		<KeywordSearchFilter
			selectedKeywords={selectedKeywords}
			handleResetClick={handleResetClick}
			handleOnChange={onSelectChange}
			keywordSearchQuery={keywordSearchQuery}
			keywordPagination={keywordPagination}
			setKeywordQueryOnState={setKeywordSearchQuery}
			apiCall={getKeywordsList}
		/>
	);
}

export default KeywordFilterForOrgChart;
