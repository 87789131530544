import type {FC} from "react";
import {useState} from "react";
import classNames from "classnames";

import {Button} from "components";
import {OrganizationArrow} from "assets/icons";

import OrganizationStatistic from "./OrganizationStatistic";
import styles from "./ProjectPerOrganization.module.scss";

const ProjectPerOrganization: FC = () => {
	const [showPerOrganization, setShowPerOrganization] = useState<boolean>(true);

	const showPerOrganizationHandler = () => {
		setShowPerOrganization(!showPerOrganization);
	};

	return (
		<div className={styles.container}>
			<div className={styles.container__head}>
				<h3 className={styles.container__head__title}>Projects per Organization</h3>

				<Button
					onClick={showPerOrganizationHandler}
					defaultBtnStyle
					className={classNames(styles.container__head__btn, {
						[styles.container__head__btn_transform]: !showPerOrganization,
					})}
				>
					<OrganizationArrow />
				</Button>
			</div>
			<div
				className={classNames(styles.container__body, {
					[styles.container__body__closed]: !showPerOrganization,
				})}
			>
				<OrganizationStatistic />
			</div>
		</div>
	);
};

export default ProjectPerOrganization;
