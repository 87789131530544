import type {FC} from "react";

import type {IconProps} from "./types";
import React from "react";

const Logo: FC<IconProps> = ({className = "", width = "133", height = "48", ...rest}) => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 133 48"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		className={className}
		{...rest}
	>
		<g clipPath="url(#clip0_1658_5500)">
			<path
				d="M85.6429 44.7833L99.2683 27.8648L101.992 29.8555L93.0734 41.0499H97.5328L104.222 33.087L111.158 41.0499H124.784L99.7569 9.94808H104.958L133.203 44.7777H108.917L104.211 39.5555L99.7513 44.7777H85.6261L85.6429 44.7833ZM110.422 34.8296H115.623L90.3551 3.98149H104.48L110.922 12.4407L118.358 3.98149H122.571L112.905 15.424L115.629 18.1592L130.743 0H116.37L110.917 6.22037L106.704 0H82.6719L110.422 34.8296Z"
				fill="#0C5850"
			/>
			<path
				d="M0 5.47013C0 5.17687 0.196574 4.97949 0.488626 4.97949H4.85256C5.29626 4.97949 5.53776 5.12612 5.6894 5.47013L14.8666 26.1107H15.0632L24.0887 5.47013C24.2347 5.12612 24.4818 4.97949 24.9199 4.97949H29.2389C29.531 4.97949 29.7275 5.17687 29.7275 5.47013V37.9819C29.7275 38.2751 29.531 38.4725 29.2389 38.4725H24.9705C24.6784 38.4725 24.4818 38.2751 24.4818 37.9819V17.4935H24.2853L17.2198 33.256C17.0233 33.7015 16.7312 33.8989 16.2875 33.8989H13.44C12.9963 33.8989 12.7043 33.7015 12.5077 33.256L5.4479 17.4935H5.25132V37.9875C5.25132 38.2808 5.05475 38.4781 4.7627 38.4781H0.488626C0.196574 38.4781 0 38.2808 0 37.9875V5.47013Z"
				fill="black"
			/>
			<path
				d="M49.684 38.4786C49.392 38.4786 49.1954 38.2812 49.1954 37.9879V36.2622H49.1449C48.016 37.8864 45.8087 39.02 42.4726 39.02C38.1536 39.02 34.5254 36.7529 34.5254 31.7788C34.5254 26.8048 38.1536 24.2388 43.9946 24.2388H48.8023C48.9988 24.2388 49.0943 24.143 49.0943 23.9456V22.812C49.0943 20.1051 47.8194 18.87 43.843 18.87C41.192 18.87 39.5745 19.6088 38.4456 20.4942C38.1985 20.6916 37.9065 20.6408 37.7604 20.3983L35.9463 17.1443C35.8003 16.8962 35.8509 16.6537 36.0418 16.5014C37.957 15.1198 40.5574 14.1836 44.4327 14.1836C51.6441 14.1836 54.2951 16.6481 54.2951 22.4624V37.9823C54.2951 38.2755 54.0985 38.4729 53.8065 38.4729H49.684V38.4786ZM49.0943 30.4987V28.6771C49.0943 28.4797 48.9988 28.3839 48.8023 28.3839H44.8764C41.3437 28.3839 39.7767 29.3708 39.7767 31.5871C39.7767 33.5553 41.1977 34.5422 43.8486 34.5422C47.2353 34.5422 49.0999 33.2113 49.0999 30.5043L49.0943 30.4987Z"
				fill="black"
			/>
			<path
				d="M60.4945 47.349C60.2024 47.349 60.0059 47.1516 60.0059 46.8583V15.2264C60.0059 14.9331 60.2024 14.7357 60.4945 14.7357H65.0101C65.3021 14.7357 65.4987 14.9331 65.4987 15.2264V17.0028H65.5492C66.6781 15.3786 68.6383 14.1943 71.8284 14.1943C75.8497 14.1943 78.7927 16.1174 80.0227 19.9072C80.6124 21.7287 80.8595 23.4036 80.8595 26.6069C80.8595 29.8101 80.6124 31.4851 80.0227 33.3066C78.7983 37.102 75.8553 39.0194 71.8284 39.0194C68.6383 39.0194 66.6781 37.8351 65.5492 36.211H65.4987V46.8527C65.4987 47.146 65.3021 47.3433 65.0101 47.3433H60.4945V47.349ZM74.7714 31.0395C75.114 29.9567 75.26 28.7724 75.26 26.6069C75.26 24.4413 75.114 23.257 74.7714 22.1742C74.1311 20.206 72.6147 19.1176 70.4074 19.1176C68.2002 19.1176 66.6276 20.2004 65.9929 22.1742C65.6503 23.257 65.5043 24.4413 65.5043 26.6069C65.5043 28.7724 65.6503 29.9567 65.9929 31.0395C66.6332 33.0077 68.1496 34.0961 70.4074 34.0961C72.6652 34.0961 74.1367 33.0134 74.7714 31.0395Z"
				fill="black"
			/>
		</g>
		<defs>
			<clipPath id="clip0_1658_5500">
				<rect width="132.923" height="48" fill="white" />
			</clipPath>
		</defs>
	</svg>
);

export default Logo;
