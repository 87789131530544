import mapXCandidateApi from "api/candidateApi";
import axios, {CanceledError} from "axios";
import {buildQueryParameter} from "helpers/filterHandlers";
import {successResponse} from "helpers/map";
import qs from "qs";
import {getFilteredCandidatesForSearch} from "store/mapx/candidate/candidateResultsAsyncActions";
import {importLinkedInProfile} from "store/mapx/candidate/linkedInProfileImportAsyncActions";
import {searchedCandidateIdsFilterSelector} from "store/mapx/filter/filterSelectors";
import {getKeywordsList} from "store/mapx/search/searchAsyncActions";
import {keywordSearchQuerySelector} from "store/mapx/search/searchSelectors";
import {addCandidateToTargetList} from "store/mapx/target-list/targetListAsyncActions";
import {isCandidateSavedSelector} from "store/mapx/target-list/targetListSelectors";
import {
	getSimilarCandidatesDone,
	getSimilarCandidatesFail,
	getSimilarCandidatesInit,
	getSingleCandidateDone,
	getSingleCandidateFail,
	getSingleCandidateInit,
	resetFilteredCandidates,
	setSimilarProfilesWithPagination,
	setSearchProgressCandidateByLinkedInForProject,
} from "./candidateActions";

const cancelTokens = {};

export const setRelevantCandidateKeywords = (config) => (dispatch, getState) => {
	const state = getState();
	const searchQuery = keywordSearchQuerySelector(state);

	dispatch(getKeywordsList(searchQuery, 1, config));
};

export const getSimilarCandidatesBySearchedProfiles =
	(pageNumber = 1) =>
	async (dispatch, getState) => {
		const requestKey = "GET_SIMILAR_PROFILES";

		if (cancelTokens[requestKey]) {
			cancelTokens[requestKey].cancel("Operation canceled due to new request.");
		}

		cancelTokens[requestKey] = axios.CancelToken.source();

		const config = {
			cancelToken: cancelTokens[requestKey].token,
		};

		if (pageNumber === 1) {
			dispatch(getSimilarCandidatesInit());
		}

		let response;

		try {
			const state = getState();

			const candidates = searchedCandidateIdsFilterSelector(state);

			const data = {candidate_id: candidates};

			const filterQuery = buildQueryParameter(data); //removes ? from string;

			response = await mapXCandidateApi.fetchSimilarCandidatesBySearchedProfile(
				filterQuery,
				pageNumber,
				20,
				config,
			);

			let payload;

			if (response == null) {
				throw new CanceledError("Operation canceled due to new request.");
			} else if (response.status === 200) {
				payload = {
					data: response.data.results.map((item) => {
						item.recommended = true;

						return item;
					}),
					paginationInfo: response.data.pagination,
					pageNumber: pageNumber,
				};
			} else {
				payload = {
					data: [],
					paginationInfo: {
						count: 0,
						pages: 1,
						page: 1,
					},
					pageNumber: 1,
				};
			}

			dispatch(setSimilarProfilesWithPagination(payload));
		} catch (e) {
			console.log(e);
		}
	};

export const getSimilarCandidatesData =
	(filterQuery, isForCandidateDetails = false, config = {}) =>
	async (dispatch) => {
		dispatch(getSimilarCandidatesInit());
		let response;

		try {
			const parseQuery = qs.parse(filterQuery.substring(1)); //removes ? from string;

			if (parseQuery.page) {
				parseQuery.page = "1";
				filterQuery = qs.stringify(parseQuery);
				filterQuery = "?" + filterQuery;
			}

			const perPage = isForCandidateDetails ? 4 : 100;

			response = await mapXCandidateApi.fetchSimilarCandidatesBySearchedProfile(
				filterQuery,
				1,
				perPage,
				config,
			);
			// response = await mapXCandidateApi.getSimilarCandidates(filterQuery, perPage, config);

			if (response === null || response === undefined) {
				return {cancelled: true};
			} else if (successResponse(response, 200)) {
				const payload = response.data.results.map((item) => {
					item.recommended = true;

					return item;
				});

				dispatch(getSimilarCandidatesDone(payload));
			}
		} catch (error) {
			dispatch(getSimilarCandidatesFail({error}));

			return {error};
		}
	};

export const getSingleCandidateData = (candidateID) => async (dispatch) => {
	dispatch(getSingleCandidateInit());
	let response;

	try {
		if (candidateID !== undefined) {
			response = await mapXCandidateApi.getCandidate(candidateID);

			if (response.status === 200) {
				dispatch(getSingleCandidateDone(response.data));

				return response.data;
			} else {
				return null;
			}
		}
	} catch (error) {
		dispatch(getSingleCandidateFail({error}));

		return {error};
	}
};

export const resetFilteredCandidatesData = () => (dispatch) => {
	dispatch(resetFilteredCandidates());
};

// Searching candidate profile by LinkedIn Url

/**
 *
 * @param url = LinkedIn Url
 * @param id = integer (can be either project id or company id)
 * @param type = project | company
 */

export const searchCandidateByLinkedInUrl =
	(url, id, type = "project") =>
	async (dispatch, getState) => {
		const state = getState();

		if (type === "project") {
			dispatch(setSearchProgressCandidateByLinkedInForProject({id, data: true}));
		}

		const apiPayload = {
			filters: {linkedin_url: url},
			pagination: {page: 1},
		};

		try {
			const payload = await dispatch(getFilteredCandidatesForSearch(apiPayload));

			if (payload?.cancelled) {
				return payload;
			}

			let response;

			if (payload.status === 200) {
				if (payload.data.length > 0) {
					const candidate = payload.data[0];
					const isSaved = isCandidateSavedSelector(state, candidate.id);

					if (isSaved) {
						response = {candidate: candidate, message: "already exists"};
					} else {
						await dispatch(addCandidateToTargetList(candidate.id));

						response = {candidate: candidate, message: "success"};
					}
				} else if (payload.data.length === 0) {
					const importResponse = await dispatch(importLinkedInProfile(url, "project"));

					if (importResponse.status === 201) {
						const importInfo = importResponse.data;
						response = {candidate: null, message: "importing", importInfo};
					} else {
						response = {candidate: null, message: "import error"};
					}
				} else {
					response = {candidate: null, message: "unknown error"};
				}
			} else {
				response = {candidate: null, message: "unknown error"};
			}

			if (type === "project" && response.message !== "importing") {
				dispatch(setSearchProgressCandidateByLinkedInForProject({id, data: false}));
			}

			return response;
		} catch (error) {
			console.log(error);
		}

		return {candidate: null, message: "unknown error"};
	};

export const getFlaggedCandidatesResults = (query) => async () => {
	let response;

	try {
		response = await mapXCandidateApi.getFlaggedCandidates(query);

		if (response.status === 200) {
			const data = response.data.results;
			const pagination = response.data.pagination;

			return {
				data,
				pagination,
			};
		}
	} catch (e) {
		console.log(e);
	}

	return {
		data: [],
		pagination: {
			pages: 1,
			page: 1,
			count: 0,
		},
	};
};
