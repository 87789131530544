import React from "react";

const ExpandAllIcon = () => {
	return (
		<svg
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<mask
				id="mask0_1733_174886"
				style={{maskType: "alpha"}}
				maskUnits="userSpaceOnUse"
				x="0"
				y="0"
				width="20"
				height="20"
			>
				<rect width="20" height="20" fill="#D9D9D9" />
			</mask>
			<g mask="url(#mask0_1733_174886)">
				<path
					d="M10 16L5 11L6.0625 9.9375L10 13.875L13.9375 9.9375L15 11L10 16ZM10 10.0625L5 5.0625L6.0625 4L10 7.9375L13.9375 4L15 5.0625L10 10.0625Z"
					fill="#4E5555"
				/>
			</g>
		</svg>
	);
};

export default ExpandAllIcon;
