import {TRootState} from "types";

export const isLastBulkImportReportDataLoadingSelector = (state: TRootState) =>
	state.backgroundTask.isLastBulkImportReportDataLoading;

export const activeLastCandidateAssessmentForProjectSelector = (state: TRootState) =>
	state.backgroundTask.activeCandidateAssessmentForProject;

export const profilesImportsByLinkedInSelector = (state: TRootState) =>
	state.backgroundTask.bulkLinkedinProfileImports;
export const candidateAssessmentRequestCreateInProgressSelector = (state: TRootState) =>
	state.backgroundTask.candidateAssessmentRequestCreateInProgress;
