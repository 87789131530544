import type {FC} from "react";

import type {IconProps} from "./types";

const DescendingIcon: FC<IconProps> = ({className}) => (
	<svg
		width="20"
		height="20"
		fill="none"
		viewBox="0 0 20 20"
		className={className}
		xmlns="http://www.w3.org/2000/svg"
	>
		<mask
			x="0"
			y="0"
			width="20"
			height="20"
			id="mask0_5153_23778"
			maskUnits="userSpaceOnUse"
			style={{maskType: "alpha"}}
		>
			<rect width="20" height="20" fill="#D9D9D9" />
		</mask>
		<g mask="url(#mask0_5153_23778)">
			<path
				d="M10 17L12.8868 12L7.11325 12L10 17ZM9.5 3L9.5 12.5L10.5 12.5L10.5 3L9.5 3Z"
				fill="#2C3030"
			/>
		</g>
	</svg>
);

export default DescendingIcon;
