import {
	ADD_CANDIDATE_TO_TARGET_LIST_DONE,
	ADD_COMPANY_TO_TARGET_LIST_DONE,
	CREATE_PROJECT_MODAL,
	DELETE_PROJECT_DONE,
	DELETE_PROJECT_FAIL,
	DELETE_PROJECT_INIT,
	EDIT_PROJECT_MODAL,
	GET_PROJECT_DONE,
	GET_PROJECT_FAIL,
	GET_PROJECT_INIT,
	GET_PROJECT_LIST_DONE,
	GET_PROJECT_LIST_FAIL,
	GET_PROJECT_LIST_INIT,
	LOGOUT,
	REMOVE_CANDIDATE_FROM_TARGET_LIST_DONE,
	REMOVE_CANDIDATES_FROM_TARGET_LIST_DONE,
	REMOVE_COMPANIES_FROM_TARGET_LIST_DONE,
	REMOVE_COMPANY_FROM_TARGET_LIST_DONE,
	SAVE_PROJECT_VIA_MODAL_DONE,
	SAVE_PROJECT_VIA_MODAL_FAIL,
	SAVE_PROJECT_VIA_MODAL_INIT,
	SET_PROJECT,
	SET_PROJECT_FORM,
	SET_PROJECT_TAB_INDEX,
	SET_PROJECT_WORKFLOW_STEP,
	UPDATE_PROJECT_DONE,
	UPDATE_PROJECT_FAIL,
	UPDATE_PROJECT_INIT,
	UPDATE_PROJECT_LIST_DONE,
	UPDATE_PROJECT_LIST_FAIL,
	UPDATE_PROJECT_LIST_INIT,
} from "../mapXActionTypes";

export const projectInitialState = {
	deletingProjectInProgress: false,
	getProjectInProgress: false,
	getProjectListInProgress: false,
	pagination: {page: 0, pages: 1},
	project: null,
	projects: [],
	projectTabIndex: 0,
	saveProjectInProgress: false,
	updateProjectInProgress: false,
	selectingProjectInProgress: false,
	updateProjectListInProgress: false,
	createProjectModal: false,
	editProjectModal: false,
	projectWorkflowStep: null, // "Start With Companies" | "Create With AI" | "Create From Scratch"
	projectForm: {
		id: null,
		name: null,
		location: null,
		parent_project_id: null, // this activates from clone from project step
		user_ids: [],
	},
};

const projectListReducer = (state = projectInitialState, action) => {
	switch (action.type) {
		case SET_PROJECT_WORKFLOW_STEP: {
			return {
				...state,
				projectWorkflowStep: action.payload,
			};
		}
		case SET_PROJECT_FORM: {
			return {
				...state,
				projectForm: {...action.payload},
			};
		}
		case CREATE_PROJECT_MODAL: {
			const {display} = action.payload;

			return {
				...state,
				createProjectModal: display,
			};
		}
		case EDIT_PROJECT_MODAL: {
			return {
				...state,
				editProjectModal: action.payload,
			};
		}
		case ADD_COMPANY_TO_TARGET_LIST_DONE: {
			const {company, targetListID} = action.payload;
			const {projects} = state;

			const index = state.projects.findIndex(
				(project) => project.target_list_id === targetListID,
			);

			return {
				...state,
				projects:
					index === -1
						? projects
						: [
								...projects.slice(0, index),
								{
									...projects[index],
									companies_number: projects[index].companies_number + 1,
									companies: [company, ...(projects[index].companies || [])],
								},
								...projects.slice(index + 1),
						  ],
			};
		}
		case ADD_CANDIDATE_TO_TARGET_LIST_DONE: {
			const {targetListID} = action.payload;
			const {projects} = state;

			const index = state.projects.findIndex(
				(project) => project.target_list_id === targetListID,
			);

			return {
				...state,
				projects:
					index === -1
						? projects
						: [
								...projects.slice(0, index),
								{
									...projects[index],
									candidates_number: projects[index].candidates_number + 1,
								},
								...projects.slice(index + 1),
						  ],
			};
		}
		case REMOVE_CANDIDATES_FROM_TARGET_LIST_DONE: {
			const {targetListID} = action.payload;
			const {projects} = state;

			const index = state.projects.findIndex(
				(project) => project.target_list_id === targetListID,
			);

			return {
				...state,
				projects:
					index === -1
						? projects
						: [
								...projects.slice(0, index),
								{
									...projects[index],
									candidates_number: 0,
								},
								...projects.slice(index + 1),
						  ],
			};
		}
		case REMOVE_COMPANIES_FROM_TARGET_LIST_DONE: {
			const {targetListID} = action.payload;
			const {projects} = state;

			const index = state.projects.findIndex(
				(project) => project.target_list_id === targetListID,
			);

			return {
				...state,
				projects:
					index === -1
						? projects
						: [
								...projects.slice(0, index),
								{
									...projects[index],
									companies_number: 0,
									companies: [],
								},
								...projects.slice(index + 1),
						  ],
			};
		}
		case REMOVE_COMPANY_FROM_TARGET_LIST_DONE: {
			const {companyID, targetListID} = action.payload;
			const {projects} = state;

			const index = state.projects.findIndex(
				(project) => project.target_list_id === targetListID,
			);

			return {
				...state,
				projects:
					index === -1
						? projects
						: [
								...projects.slice(0, index),
								{
									...projects[index],
									companies_number: projects[index].companies_number - 1,
									companies: projects[index].companies.filter(
										(company) => company.id !== companyID,
									),
								},
								...projects.slice(index + 1),
						  ],
			};
		}
		case REMOVE_CANDIDATE_FROM_TARGET_LIST_DONE: {
			const {targetListID} = action.payload;
			const {projects} = state;

			const index = state.projects.findIndex(
				(project) => project.target_list_id === targetListID,
			);

			return {
				...state,
				projects:
					index === -1
						? projects
						: [
								...projects.slice(0, index),
								{
									...projects[index],
									candidates_number: projects[index].candidates_number - 1,
								},
								...projects.slice(index + 1),
						  ],
			};
		}
		case GET_PROJECT_INIT:
			return {
				...state,
				getProjectInProgress: true,
			};
		case GET_PROJECT_DONE: {
			const project = action.payload;

			return {
				...state,
				getProjectInProgress: false,
				project,
			};
		}
		case GET_PROJECT_FAIL:
			return {
				...state,
				getProjectInProgress: false,
			};
		case SET_PROJECT_TAB_INDEX:
			return {
				...state,
				projectTabIndex: action.payload,
			};
		case DELETE_PROJECT_INIT:
			return {
				...state,
				deletingProjectInProgress: true,
			};
		case DELETE_PROJECT_DONE:
			return {
				...state,
				deletingProjectInProgress: false,
				projects: state.projects.filter((project) => project.id !== action.payload),
			};
		case DELETE_PROJECT_FAIL:
			return {
				...state,
				deletingProjectInProgress: false,
			};
		case UPDATE_PROJECT_INIT:
			return {
				...state,
				updateProjectInProgress: true,
			};
		case UPDATE_PROJECT_DONE: {
			const project = action.payload;

			const index = state.projects.findIndex((p) => p.id === project.id);

			return {
				...state,
				updateProjectInProgress: false,
				projects: [
					...state.projects.slice(0, index),
					project,
					...state.projects.slice(index + 1),
				],
				project: state.project?.id === project.id ? {...project} : state.project,
				edit_project_modal: false,
			};
		}
		case UPDATE_PROJECT_FAIL:
			return {
				...state,
				updateProjectInProgress: false,
			};
		case SAVE_PROJECT_VIA_MODAL_INIT:
			return {
				...state,
				saveProjectInProgress: true,
			};
		case SAVE_PROJECT_VIA_MODAL_DONE: {
			const project = action.payload;

			return {
				...state,
				saveProjectInProgress: false,
				projects: [project, ...state.projects],
			};
		}
		case SAVE_PROJECT_VIA_MODAL_FAIL:
			return {
				...state,
				saveProjectInProgress: false,
			};
		case GET_PROJECT_LIST_INIT:
			return {
				...state,
				getProjectListInProgress: true,
			};
		case GET_PROJECT_LIST_DONE: {
			const {pagination, results} = action.payload;

			return {
				...state,
				getProjectListInProgress: false,
				pagination: pagination || structuredClone(projectInitialState.pagination),
				projects: results || [],
			};
		}
		case GET_PROJECT_LIST_FAIL:
			return {
				...state,
				getProjectListInProgress: false,
			};
		case UPDATE_PROJECT_LIST_INIT:
			return {
				...state,
				updateProjectListInProgress: true,
			};
		case UPDATE_PROJECT_LIST_DONE: {
			const {pagination, results} = action.payload;

			return {
				...state,
				updateProjectListInProgress: false,
				pagination,
				projects: [...state.projects, ...results],
			};
		}
		case UPDATE_PROJECT_LIST_FAIL:
			return {
				...state,
				updateProjectListInProgress: false,
			};
		case SET_PROJECT:
			let updatedProjects = [...state.projects];

			if (action.payload) {
				updatedProjects = [
					action.payload,
					...updatedProjects.filter((item) => item.id !== action.payload.id),
				];
			}

			return {
				...state,
				project: action.payload,
				projects: [...updatedProjects],
			};
		case LOGOUT:
			return {...projectInitialState};
		default:
			return state;
	}
};

export default projectListReducer;
