import React, {useCallback, useState} from "react";
import {TRefreshProfileProps} from "mapx-components/Cards/CandidateCard/RefreshProfile/types";
import styles from "mapx-components/Cards/CandidateCard/candidateCard.module.scss";
import {RefreshIcon} from "assets/icons";
import customConfirmAlert from "helpers/customConfirmAlert";
import {useAppDispatch, useAppSelector} from "hooks";
import {refreshLinkedInProfile} from "store/mapx/refresh-candidate/refreshCandidateAsyncActions";
import {isCurrentCurrentCandidateRefreshIsInProgressSelector} from "store/mapx/refresh-candidate/refreshCandidateSelectors";
import {TRootState} from "types";
import colors from "styles/themes.module.scss";
import {Loader} from "components";
import {removeTrailingSlash} from "helpers/string";

const RefreshProfile = ({linkedin_url, candidate_id, full_name}: TRefreshProfileProps) => {
	const dispatch = useAppDispatch();

	const isCurrentCurrentCandidateRefreshIsInProgress = useAppSelector((state: TRootState) =>
		isCurrentCurrentCandidateRefreshIsInProgressSelector(
			state,
			removeTrailingSlash(linkedin_url),
		),
	);

	const [creatingRequest, setCreatingRequest] = useState<boolean>(false);

	const handleOnClick = useCallback(() => {
		customConfirmAlert({
			yesButtonLabel: "Proceed",
			noButtonLabel: "Back",
			title: `You are about to refresh ${full_name}'s profile.`,
			message: `Do you want to proceed?`,
			handlePressYes: async () => {
				if (linkedin_url) {
					setCreatingRequest(true);
					await dispatch(refreshLinkedInProfile(removeTrailingSlash(linkedin_url)));
					setCreatingRequest(false);
				}
			},
			alertType: "warning",
		});
	}, [full_name, linkedin_url, dispatch]);

	return creatingRequest || isCurrentCurrentCandidateRefreshIsInProgress ? (
		<Loader
			displayAtCenterOfPage={false}
			key={`${linkedin_url}${candidate_id}`}
			type={"TailSpin"}
			height={30}
			width={30}
			timeout={5}
			isCenter={true}
			color={colors.loaderDotColor}
		/>
	) : (
		<RefreshIcon
			data-testid={"refresh-icon"}
			className={styles.refreshIcon}
			onClick={handleOnClick}
		/>
	);
};

export default RefreshProfile;
