import React from "react";

const FooterShape = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="828"
		height="111"
		viewBox="0 0 828 111"
		fill="none"
	>
		<path
			d="M1139 104.268C1076.23 85.6851 1061.57 44.9533 985.017 9.4285C908.465 -26.0963 827.074 48.9556 737.323 66.4683C647.573 83.9809 398 57 298.691 37.448C199.382 17.896 114.295 89.4219 3.92273 68.4698C-106.45 47.5177 -246.165 -31.4545 -341 111L1139 104.268Z"
			fill="white"
		/>
	</svg>
);

export default FooterShape;
