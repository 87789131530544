import {Accordion} from "mapx-components";
import {companyIndexesSelector} from "store/mapx/filter/filterSelectors";
import {useCallback} from "react";
import {setIndexForCompany} from "store/mapx/filter/filterAsyncActions";
import {setFilterForCompanies} from "store/mapx/filter/filterActions";
import IndexSearchFilter from "containers/Filters/CompanyFilters/IndexFilter/companyIndexFilter";
import {useAppDispatch, useAppSelector} from "hooks";

const IndexFilter = () => {
	const dispatch = useAppDispatch();
	const indexes = useAppSelector(companyIndexesSelector);

	const handleChange = useCallback(
		(id: number) => {
			dispatch(setIndexForCompany({id}));
		},
		[dispatch],
	);

	const handleResetClick = useCallback(() => {
		dispatch(setFilterForCompanies({type: "indexes", values: []}));
	}, [dispatch]);

	return (
		<Accordion title="Index" subtitle={indexes.length}>
			<IndexSearchFilter
				companyIndexes={indexes}
				handleOnChange={handleChange}
				handleResetClick={handleResetClick}
			/>
		</Accordion>
	);
};

export default IndexFilter;
