/** @format */

import HttpClient from "api/index";

class MapxJobFunctionsApi extends HttpClient {
	async get(config = {}) {
		return this.doGet("/mapx/job-functions", config);
	}
}

const mapxJobFunctionsApi = new MapxJobFunctionsApi();

export default mapxJobFunctionsApi;
