import type {FC} from "react";
import ContentLoader from "react-content-loader";

const OverviewSkeleton: FC = () => (
	<ContentLoader
		speed={2}
		height={300}
		width={"100%"}
		backgroundColor="#DCE7E7"
		foregroundColor="#F9F9F9"
	>
		<circle cx="40.2" cy="36.2" r="32.3" />
		<rect x="7%" y="2.2" rx="5" ry="5" width="15%" height="30" />
		<rect x="7%" y="44.2" rx="5" ry="5" width="16%" height="25" />
		<rect x="80%" y="2.2" rx="5" ry="5" width="20%" height="30" />

		<rect x="1%" y="95.7" rx="5" ry="5" width="7%" height="15" />
		<rect x="9%" y="95.7" rx="5" ry="5" width="20%" height="15" />

		<rect x="1%" y="135.7" rx="5" ry="5" width="12%" height="15" />

		<rect x="1%" y="160.7" rx="5" ry="5" width="7%" height="15" />
		<rect x="9%" y="160.7" rx="5" ry="5" width="10%" height="15" />
		<rect x="20%" y="160.7" rx="5" ry="5" width="6%" height="15" />

		<rect x="1%" y="185.7" rx="5" ry="5" width="12%" height="15" />
		<rect x="14%" y="185.7" rx="5" ry="5" width="10%" height="15" />

		<rect x="30%" y="75.7" rx="5" ry="5" width="70%" height="15" />
		<rect x="30%" y="95.7" rx="5" ry="5" width="69%" height="15" />
		<rect x="30%" y="115.7" rx="5" ry="5" width="67%" height="15" />
		<rect x="30%" y="135.7" rx="5" ry="5" width="68%" height="15" />
		<rect x="30%" y="155.7" rx="5" ry="5" width="70%" height="15" />
		<rect x="30%" y="175.7" rx="5" ry="5" width="69%" height="15" />
		<rect x="30%" y="195.7" rx="5" ry="5" width="65%" height="15" />

		<rect x="90%" y="230.7" rx="5" ry="5" width="10%" height="40" />

		<rect x="1%" y="280.7" rx="5" ry="5" width="100%" height="1" />
	</ContentLoader>
);

export default OverviewSkeleton;
