import {useMemo, forwardRef, useCallback, useState, useEffect, useRef} from "react";
import type {FC, SVGProps, ChangeEvent} from "react";
import classNames from "classnames";
import {useWatch} from "react-hook-form";

import Button from "../Button";
import type {TInputProps} from "./types";

import FormInputLabel from "./FormInputLabel";
import FormInputErrors from "./FormInputErrors";
import InputErrorTooltip from "./InputErrorTooltip";
import styles from "./FormInput.module.scss";

const passwordNames = ["password", "confirmPassword"];

const FormInput = forwardRef<HTMLInputElement, TInputProps>(
	(
		{
			to,
			name,
			error,
			value,
			control,
			trigger,
			onChange,
			disabled,
			setValue,
			RightIcon,
			wrapperRef,
			inputError,
			label = "",
			placeholder,
			clearErrors,
			toggleHandle,
			type = "text",
			className = "",
			rightLabel = "",
			RightToggledIcon,
			containerClass = "",
			passwordWatches = "",
			showErrorTooltip = false,
			readOnly = false,
			...rest
		},
		ref,
	) => {
		const disabledPasswordWatch = !passwordNames.includes(name as string);
		const passwordValue = useWatch({
			name: "password",
			control,
			disabled: disabledPasswordWatch,
		});
		const labelRef = useRef<HTMLLabelElement | null>(null);
		const inputWrapperRef = useRef<HTMLDivElement | null>(null);
		const [isFocused, setIsFocused] = useState<boolean>(false);

		const confirmPasswordValue = useWatch({
			control,
			name: "confirmPassword",
			disabled: disabledPasswordWatch,
		});
		const [isToggledIcon, setIsToggledIcon] = useState<boolean>(false);

		const inputContainerClasses = classNames(styles.container, className, {
			[styles.container_margin_error]: error || inputError,
		});
		const inputInnerContainerClasses = classNames(styles.container__inner, {
			[styles.container__inner_error]: error || inputError,
			[styles.container__inner_disabled]: disabled,
		});
		const inputClasses = classNames(styles.container__inner__input, {
			[styles.container_right_icon]: RightIcon,
		});

		const RightIconComponent = useMemo(
			() =>
				(RightIcon && RightToggledIcon
					? isToggledIcon
						? RightToggledIcon
						: RightIcon
					: RightIcon) as FC<SVGProps<SVGSVGElement>>,
			[RightIcon, RightToggledIcon, isToggledIcon],
		);

		useEffect(() => {
			if (confirmPasswordValue && trigger && clearErrors) {
				if (passwordValue !== confirmPasswordValue && !error) {
					trigger("confirmPassword");
				} else if (passwordValue === confirmPasswordValue && error) {
					clearErrors(passwordNames);
				}
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [passwordValue, confirmPasswordValue, error]);

		const togglePasswordVisibility = useCallback(() => {
			if (RightToggledIcon) {
				setIsToggledIcon(!isToggledIcon);
			}
		}, [RightToggledIcon, isToggledIcon]);

		const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
			const val = e.target.value;
			if (name && setValue) {
				setValue(name, val, {shouldValidate: true});
			}
			if (onChange) {
				onChange(e, name as string);
			}
		};

		const handleFocus = () => {
			setIsFocused(true);
		};

		const handleBlur = () => {
			setIsFocused(false);
		};

		const isShowErrorTooltip =
			(name === "password" || name === "newPassword") && isFocused && showErrorTooltip;

		useEffect(() => {
			// @TODO: Find a better solution for disabling autofill data on irrelevant fields.
			if (!readOnly) {
				const id = setTimeout(() => {
					const el = document.getElementById(name as string);
					el?.removeAttribute("readonly");
				}, 300);

				return () => clearTimeout(id);
			}
		}, []); // eslint-disable-line react-hooks/exhaustive-deps

		return (
			<div
				id={name}
				ref={inputWrapperRef}
				className={classNames(styles.container__wrapper, {
					[containerClass]: containerClass,
				})}
			>
				<label htmlFor={name} ref={labelRef} className={inputContainerClasses}>
					<FormInputLabel to={to} label={label} rightLabel={rightLabel} />

					<div ref={wrapperRef} className={inputInnerContainerClasses}>
						<input
							{...rest}
							id={name}
							ref={ref}
							name={name}
							value={value}
							data-dt-idx="1"
							autoComplete="off"
							readOnly={readOnly}
							disabled={disabled}
							onBlur={handleBlur}
							onFocus={handleFocus}
							onChange={handleChange}
							className={inputClasses}
							placeholder={placeholder}
							type={isToggledIcon ? "text" : type}
							pattern={type === "number" ? "[0-9]*" : undefined}
							inputMode={type === "number" ? "numeric" : "text"}
						/>

						{RightIcon && (
							<Button
								defaultBtnStyle
								className={styles.container__inner_right_icon}
								onClick={type === "text" ? toggleHandle : togglePasswordVisibility}
							>
								<RightIconComponent
									style={{cursor: RightToggledIcon ? "pointer" : "auto"}}
								/>
							</Button>
						)}
					</div>

					<FormInputErrors error={error} inputError={inputError} />
				</label>
				{isShowErrorTooltip && (
					<InputErrorTooltip
						inputWrapperRef={inputWrapperRef}
						password={passwordWatches}
					/>
				)}
			</div>
		);
	},
);

FormInput.displayName = "FormInput";

export default FormInput;
