import type {FC} from "react";
import classNames from "classnames";

import {Button} from "components";
import {OnboardingTooltip} from "mapx-components";
import {EOnboardingTooltipSteps} from "mapx-components/OnboardingTooltip/types";
import {ArrowDown, PersonAiIcon, RejectMinusIcon, SavePlusIcon} from "assets/icons";

import type {TDetailsProps, TProjectCandidateFakeCardProps} from "../../types";

import styles from "./FakeResults.module.scss";

const ProjectCandidateFakeCard: FC<TProjectCandidateFakeCardProps> = ({
	idx,
	step,
	name,
	details,
	country,
	avatar_url,
	companyName,
	company_avatar,
}) => (
	<div className={styles.fake_card}>
		<div className={styles.fake_card__head}>
			<img src={avatar_url} className={styles.fake_card__head__img} />
			<div className={styles.fake_card__head__description}>
				<p className={styles.fake_card__head__description__name}>{name}</p>
				<span className={styles.fake_card__head__description__country}>{country}</span>
			</div>
		</div>

		<div className={styles.fake_card__company}>
			<div className={styles.fake_card__company__details}>
				<img src={company_avatar} className={styles.fake_card__company__details_img} />

				<span className={styles.fake_card__company__details_name}>{companyName}</span>
			</div>

			<div className={styles.fake_card__company__info}>
				{details?.map((item: TDetailsProps) => (
					<p key={item.name} className={styles.fake_card__company__info__details}>
						{item.dateDetails}
						<span className={styles.fake_card__company__info__details_name}>
							{item.name}
						</span>
					</p>
				))}
			</div>
		</div>

		<div className={styles.fake_card__buttons}>
			<Button defaultBtnStyle LeftIcon={ArrowDown} className={styles.fake_card__buttons__btn}>
				Show more
			</Button>

			<div className={styles.fake_card__buttons__right}>
				<div className={styles.fake_card__buttons__right__more}>
					<Button
						defaultBtnStyle
						LeftIcon={PersonAiIcon}
						className={classNames(
							styles.fake_card__buttons__right__more_btn,
							styles.fake_card__buttons__btn,
							{
								[styles.fake_card__buttons__right__more_btn_z_index]:
									idx === 0 && step === EOnboardingTooltipSteps.Third,
							},
						)}
					>
						More People Like This
					</Button>

					{idx === 0 && step === EOnboardingTooltipSteps.Third && (
						<OnboardingTooltip
							step={step === EOnboardingTooltipSteps.Third ? step : null}
							className={styles.fake_card__buttons__right__more__boarding}
						/>
					)}
				</div>

				<Button
					defaultBtnStyle
					LeftIcon={RejectMinusIcon}
					className={classNames(
						styles.fake_card__buttons__right__reject,
						styles.fake_card__buttons__btn,
					)}
				>
					Reject
				</Button>
				<div className={styles.fake_card__buttons__right__save}>
					<Button
						defaultBtnStyle
						LeftIcon={SavePlusIcon}
						className={classNames(
							styles.fake_card__buttons__right__save_btn,
							styles.fake_card__buttons__btn,
							{
								[styles.fake_card__buttons__right__save_btn_z_index]:
									step === EOnboardingTooltipSteps.First && idx === 0,
							},
						)}
					>
						Save
					</Button>

					{idx === 0 && (
						<OnboardingTooltip
							className={styles.fake_card__buttons__right__save__boarding}
							step={step === EOnboardingTooltipSteps.First ? step : null}
						/>
					)}
				</div>
			</div>
		</div>
	</div>
);

export default ProjectCandidateFakeCard;
