import type {FC} from "react";
import ContentLoader from "react-content-loader";

const OrganizationalChartSectionSkeleton: FC = () => (
	<ContentLoader
		speed={2}
		height={140}
		width={"100%"}
		backgroundColor="#DCE7E7"
		foregroundColor="#F9F9F9"
	>
		<rect x="1%" y="3" rx="5" ry="5" width="20%" height="40" />
		<rect x="88%" y="3" rx="5" ry="5" width="7%" height="40" />
		<rect x="96.5%" y="3" rx="5" ry="5" width="40" height="40" />

		<rect x="1%" y="78" rx="5" ry="5" width="15%" height="30" />
		<rect x="17%" y="78" rx="5" ry="5" width="20%" height="30" />
		<rect x="38%" y="78" rx="5" ry="5" width="20%" height="30" />
		<rect x="59%" y="78" rx="5" ry="5" width="15%" height="30" />
		<rect x="75%" y="78" rx="5" ry="5" width="10%" height="30" />
		<rect x="86%" y="78" rx="5" ry="5" width="14%" height="30" />
	</ContentLoader>
);

export default OrganizationalChartSectionSkeleton;
