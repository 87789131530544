import {Dispatch} from "react";
import {TAppDispatch, TRootState} from "types";
import {userEmailSelector} from "store/mapx/user/userSelectors";
import mapXCandidateApi from "api/candidateApi";
import {Mixpanel} from "helpers/mixpanel";
import {toast} from "react-toastify";
import {ToastContent} from "components";
import {
	clearRefreshLinkedInProfileScrapeInfo,
	refreshLinkedInCandidateInProgress,
	setCurrentRefreshLinkedInProfileScrapeInfo,
} from "store/mapx/refresh-candidate/refreshCandidateActions";
import {projectSelector} from "store/mapx/project-list/projectListSelectors";
import {selectedCandidateIdsForAssessmentSelector} from "../project/candidateScoringSelectors";
import {getCandidateInfoFromTLSelector} from "../target-list/targetListSelectors";
import {SICandidate} from "api/candidateApi/types";
import {importLinkedInProfileToProject} from "../linkedin-bulk-import/linkedInProfilesImportAsyncActions";
import {removeTrailingSlash} from "helpers/string";
import {TLinkedInUrlImportItem} from "mapx-components/AddPeopleByLinkedIn/types";
import {setImportLinkedInProfileInfoForCurrentImport} from "../background-tasks/backgroundTaskActions";

export const refreshLinkedInProfile =
	(linkedInLink: string) =>
	async (dispatch: Dispatch<TAppDispatch>, getState: () => TRootState) => {
		const state = getState();

		const userEmail = userEmailSelector(state);

		const project = projectSelector(state);

		dispatch(refreshLinkedInCandidateInProgress({[linkedInLink]: true}));

		let response;

		try {
			const formBody = {linkedin_url: linkedInLink, project_id: project?.id ?? null};

			response = await mapXCandidateApi.createLinkedinUrlImportRequest(formBody);

			if (response.status === 201) {
				try {
					Mixpanel.track(`Refreshed LinkedIn Profile`, {
						pageTitle: window.location.href,
						url: linkedInLink,
						distinct_id: userEmail,
					});
					// eslint-disable-next-line
				} catch (e: any) {
					console.log(e.toString());
				}

				dispatch(
					setCurrentRefreshLinkedInProfileScrapeInfo({
						[linkedInLink]: response.data,
					}),
				);

				return response.status;
			} else {
				toast.error(ToastContent, {
					autoClose: false,
					closeOnClick: true,
					data: {title: response?.data?.detail || "Something went wrong!"},
				});

				dispatch(clearRefreshLinkedInProfileScrapeInfo(linkedInLink));

				return response.status;
			}
		} catch (error) {
			console.log(error);

			return 403;
		}
	};

export const bulkRefreshCandidateProfiles =
	() => async (dispatch: Dispatch<TAppDispatch>, getState: () => TRootState) => {
		const state = getState();

		const project = projectSelector(state);

		const selectedCandidateIdsForBulkRefresh = selectedCandidateIdsForAssessmentSelector(state);

		const candidateInfoFromTL = getCandidateInfoFromTLSelector(
			state,
			selectedCandidateIdsForBulkRefresh,
		);

		const urls = candidateInfoFromTL
			.filter((candidate: SICandidate) => candidate.linkedin_url !== null)
			.map((candidate: SICandidate) => ({
				url: removeTrailingSlash(candidate.linkedin_url ?? ""),
				status: "Created",
			}));

		if (urls?.length > 0) {
			const setImportStartPayload = {
				linkedin_candidate_urls: urls,
				progress: 0,
				project_id: project?.id,
				status: "In Progress",
				purpose: "refresh",
			};

			dispatch(setImportLinkedInProfileInfoForCurrentImport(setImportStartPayload));

			dispatch(
				importLinkedInProfileToProject(
					urls.map((item: TLinkedInUrlImportItem) => item.url),
					project,
					"refresh",
				),
			);
		}
	};
