import type {TSeniorityKeyPairObj} from "mapx-components/Cards/CandidateCard/types";
import type {SICandidatePosition, TSeniority} from "api/candidateApi/types";

const seniorities: TSeniorityKeyPairObj = {
	Board: 6,
	ExCo: 5,
	"C-Level": 4,
	"C-Level-1": 3,
	"C-Level-2": 2,
	"C-Level-3": 1,
	Other: 0,
};

interface ExperienceItem {
	name: string;
	total: number;
}

interface IPositionTenure {
	started: Nullable<number>;
	ended: Nullable<number>;
}

export const generateExperienceTable = (positions: SICandidatePosition[]): ExperienceItem[] => {
	const experienceData: Record<string, IPositionTenure> = {
		Board: {started: null, ended: null},
		ExCo: {started: null, ended: null},
		"C-Level": {started: null, ended: null},
		"C-Level-1": {started: null, ended: null},
		"C-Level-2": {started: null, ended: null},
		"C-Level-3": {started: null, ended: null},
		Other: {started: null, ended: null},
	};

	if (positions) {
		positions.forEach((item) => {
			const started = item.start_date ? parseInt(item.start_date) : null;
			const ended = item.end_date ? parseInt(item.end_date) : new Date().getFullYear();

			const seniority = item.seniority as keyof typeof experienceData;
			const currentExperience = experienceData[seniority];

			if (
				started !== null &&
				(currentExperience?.started === null || started <= currentExperience?.started)
			) {
				currentExperience.started = started;
			}

			if (
				ended !== null &&
				(currentExperience?.ended === null || ended >= currentExperience?.ended)
			) {
				currentExperience.ended = ended;
			}
		});
	}

	const experienceSumList: ExperienceItem[] = [];

	for (const seniority in experienceData) {
		const {started, ended} = experienceData[seniority] ?? {started: null, ended: null};

		if (started !== null && ended !== null && ended - started > 0) {
			experienceSumList.push({
				name: seniority,
				total: ended - started,
			});
		}
	}

	return experienceSumList.slice(0, 3);
};

export const seniorityExperienceTimeInYears = (
	positions: SICandidatePosition[],
	shouldLimitBySeniority: boolean,
	selectedSeniorities: TSeniority[],
) => {
	let filteredPositions = positions
		.filter((exp) => exp.seniority)
		.sort((a, b) => seniorities[b.seniority] - seniorities[a.seniority]);

	const currentSeniority = selectedSeniorities;

	if (shouldLimitBySeniority && currentSeniority.length > 0) {
		// At least one filter is applied

		const filteredExperience = [];

		for (const element of filteredPositions) {
			const {seniority} = element;

			if (seniority === "Board") {
				if (currentSeniority.some((cs: TSeniority) => cs === "Board" || cs === "ExCo")) {
					filteredExperience.push(element);
					continue;
				}
			}

			if (seniority === "ExCo") {
				if (
					currentSeniority.some(
						(cs: TSeniority) => cs === "Board" || cs === "ExCo" || cs === "C-Level",
					)
				) {
					filteredExperience.push(element);
					continue;
				}
			}

			if (seniority === "C-Level") {
				if (
					currentSeniority.some(
						(cs: TSeniority) => cs === "ExCo" || cs === "C-Level" || cs === "C-Level-1",
					)
				) {
					filteredExperience.push(element);
					continue;
				}
			}

			if (seniority === "C-Level-1") {
				if (
					currentSeniority.some(
						(cs: TSeniority) =>
							cs === "C-Level" || cs === "C-Level-1" || cs === "C-Level-2",
					)
				) {
					filteredExperience.push(element);
					continue;
				}
			}

			if (seniority === "C-Level-2") {
				if (
					currentSeniority.some(
						(cs: TSeniority) =>
							cs === "C-Level-1" || cs === "C-Level-2" || cs === "C-Level-3",
					)
				) {
					filteredExperience.push(element);
					continue;
				}
			}

			if (seniority === "C-Level-3") {
				if (
					currentSeniority.some(
						(cs: TSeniority) =>
							cs === "C-Level-2" || cs === "C-Level-3" || cs === "Other",
					)
				) {
					filteredExperience.push(element);
					continue;
				}
			}

			if (seniority === "Other") {
				if (
					currentSeniority.some((cs: TSeniority) => cs === "C-Level-3" || cs === "Other")
				) {
					filteredExperience.push(element);
				}
			}
		}

		filteredPositions = filteredExperience;
	}

	return filteredPositions;
};
