import {useId} from "react";
import {Tooltip} from "react-tooltip";

import {MarketMapSkeletonCard} from "assets/icons";

import styles from "./loader.module.scss";
import {TLoader} from "./types";

const Loader = ({count = 5}: TLoader) => {
	const id = useId();

	return (
		<div
			className={styles.skeletonContainer}
			data-tooltip-id={id}
			data-tooltip-content="Loading target lists"
		>
			{Array.from({length: count}, (_, idx) => (
				<div key={idx} className={styles.card}>
					<MarketMapSkeletonCard />
				</div>
			))}
			<Tooltip
				id={id}
				className={styles.tooltip}
				classNameArrow={styles.arrow}
				place="left"
			/>
		</div>
	);
};

export default Loader;
