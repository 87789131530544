import type {FC, SyntheticEvent} from "react";
import classNames from "classnames";
import {take} from "lodash";
import useInfiniteScroll from "react-infinite-scroll-hook";

import {Button, Loader} from "components";
import colors from "styles/themes.module.scss";
import {useAppDispatch, useAppSelector} from "hooks";
import {SelectFilterType} from "hooks/useCompanyPeopleSearch/types";
import {getSearchData} from "store/mapx/search/searchAsyncActions";
import {
	getSearchDataInProgressSelector,
	searchedDataSelector,
} from "store/mapx/search/searchSelectors";

import type {TCompaniesProps, TRenderCompaniesProps} from "./types";
import styles from "./Search.module.scss";

const defaultLogo = "https://storage.googleapis.com/nebula-static/logos100x100/empty_logo.png";

const Companies: FC<TCompaniesProps> = ({
	onSelect,
	searchTerm,
	activeCategory,
	setActiveCategory,
	companies: propsCompanies,
}) => {
	const dispatch = useAppDispatch();

	const loading = useAppSelector(getSearchDataInProgressSelector);
	const {companiesPagination} = useAppSelector(searchedDataSelector);

	const [sentryRef] = useInfiniteScroll({
		loading,
		hasNextPage: companiesPagination?.pages > companiesPagination?.page,
		onLoadMore: () => {
			dispatch(
				getSearchData({
					value: searchTerm,
					filterType: "companies",
					perPage: 20,
					page: companiesPagination?.page + 1 || 1,
				}),
			);
		},
		rootMargin: "0px 0px 400px 0px",
	});

	const companies = !activeCategory ? take(propsCompanies, 10) : propsCompanies;

	const companiesClassName = classNames(styles.companies, {
		[styles.companies__border]: activeCategory === 0,
	});

	const renderCompanies = companies?.map((item: TRenderCompaniesProps) => {
		const {id, name, logo_url, industry} = item;

		return (
			<Button
				key={id}
				defaultBtnStyle
				className={styles.companies__container__item}
				onClick={() => onSelect(SelectFilterType.Companies, item)}
			>
				<img
					alt={name}
					src={logo_url || defaultLogo}
					className={styles.companies__container__item_img}
					onError={(e: SyntheticEvent<HTMLImageElement, Event>) => {
						(e.target as HTMLImageElement).src = defaultLogo;
					}}
				/>

				<div className={styles.companies__container__item__texts}>
					<p className={styles.companies__container__item_name}>{name}</p>

					{industry?.name && (
						<span className={styles.companies__container__item__texts_text}>
							{industry?.name}
						</span>
					)}
				</div>
			</Button>
		);
	});

	const handleShowMore = () => {
		setActiveCategory(1);
	};

	return (
		<div className={companiesClassName}>
			<h5 className={styles.companies__title}>Companies</h5>

			{companies.length ? (
				<>
					<div className={styles.companies__container}>
						{renderCompanies}

						{activeCategory === 0 && (
							<Button
								defaultBtnStyle
								onClick={handleShowMore}
								className={styles.companies__container__show}
							>
								Show more
							</Button>
						)}

						{!loading && activeCategory !== 0 && (
							<div ref={sentryRef} style={{width: "100%", height: 1}} />
						)}

						{loading && activeCategory !== 0 && (
							<div style={{marginLeft: 10}}>
								<Loader
									width={20}
									height={20}
									type={"ThreeDots"}
									color={colors.loaderDotColor}
									displayAtCenterOfPage={false}
									customStyle={{marginLeft: "10"}}
								/>
							</div>
						)}
					</div>
				</>
			) : (
				<h5>No Companies Found</h5>
			)}
		</div>
	);
};

export default Companies;
