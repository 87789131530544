import type {FC} from "react";
import React from "react";

import type {IconProps} from "./types";

const FloatAddedIcon: FC<IconProps> = ({className = "", ...rest}) => (
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		className={className}
		{...rest}
	>
		<rect width="24" height="24" rx="4" fill="#0C5850" />
		<mask
			id="mask0_44_84937"
			style={{maskType: "alpha"}}
			maskUnits="userSpaceOnUse"
			x="2"
			y="2"
			width="20"
			height="20"
		>
			<rect x="2" y="2" width="20" height="20" fill="#D9D9D9" />
		</mask>
		<g mask="url(#mask0_44_84937)">
			<path
				d="M11.25 17V12.75H7V11.25H11.25V7H12.75V11.25H17V12.75H12.75V17H11.25Z"
				fill="#EDF2F2"
			/>
		</g>
	</svg>
);

export default FloatAddedIcon;
