import type {FC} from "react";

import type {IconProps} from "./types";

const ArrowUp: FC<IconProps> = ({height = 24, width = 24, className, stroke = "#808080"}) => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		className={className && className}
	>
		<mask
			id="mask0_137_5085"
			style={{maskType: "alpha"}}
			maskUnits="userSpaceOnUse"
			x="0"
			y="0"
			width="24"
			height="24"
		>
			<rect width="24" height="24" transform="matrix(1 0 0 -1 0 24)" fill="#D9D9D9" />
		</mask>
		<g mask="url(#mask0_137_5085)">
			<path
				d="M12 8.45001L5.80005 14.625L7.40005 16.225L12 11.625L16.6 16.225L18.2 14.625L12 8.45001Z"
				fill={stroke}
			/>
		</g>
	</svg>
);

export default ArrowUp;
