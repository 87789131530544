import {type FC, useCallback, useEffect} from "react";
import ReactGA from "react-ga4";
import {hotjar} from "react-hotjar";
import classNames from "classnames";

import config from "config";
import {Mixpanel} from "helpers/mixpanel";
import EnhanceIcon from "assets/icons/EnhanceIcon";
import {useAppDispatch, useAppSelector} from "hooks";
import {ButtonsRow, GeneralButton} from "mapx-components";
import {hasAccessToMarketMapsSelector, userEmailSelector} from "store/mapx/user/userSelectors";
import {addToMarketMapModal} from "store/mapx/market-map/marketMapActions";
import AddToMarketMapModal from "mapx-components/Modals/AddToMarketMapModal";
import {addToMarketMapModalSelector} from "store/mapx/market-map/marketMapSelectors";
import {decodeCharacterReferences, formatRevenueNumber, formatSizeBand} from "helpers/string";
import {
	isCompanyBeingAddedToTargetListSelector,
	isCompanyBeingRemovedFromTargetListSelector,
	isCompanySavedSelector,
} from "store/mapx/target-list/targetListSelectors";
import {
	addCompanyToTargetList,
	removeCompanyFromTargetList,
} from "store/mapx/target-list/targetListAsyncActions";

import {TOverviewProps} from "./types";
import styles from "./overview.module.scss";
import {useSelector} from "react-redux";

const OverView: FC<TOverviewProps> = ({company, isRecommended = false, type = "listing"}) => {
	const dispatch = useAppDispatch();

	const isSaved = useAppSelector((state) => isCompanySavedSelector(state, company.id));

	const isCompanyBeingAdded = useAppSelector((state) =>
		isCompanyBeingAddedToTargetListSelector(state, company.id),
	);

	const isCompanyBeingRemoved = useAppSelector((state) =>
		isCompanyBeingRemovedFromTargetListSelector(state, company.id),
	);

	const modalDisplay = useAppSelector(addToMarketMapModalSelector);

	const userEmail = useAppSelector(userEmailSelector);

	const hasAccessToMarketMaps = useSelector(hasAccessToMarketMapsSelector);

	const loadingSave = isCompanyBeingAdded || isCompanyBeingRemoved;

	const handleSaveItem = useCallback(() => {
		if (isSaved) {
			dispatch(removeCompanyFromTargetList(company.id));
		} else {
			dispatch(addCompanyToTargetList(company.id));
		}
	}, [company.id, dispatch, isSaved]);

	const handleDisplayAddToMarketMapModal = useCallback(() => {
		dispatch(addToMarketMapModal(true));
	}, [dispatch]);

	useEffect(() => {
		try {
			if (type === "profile") {
				const pageTitle = `${company.name} | ${config.pageTitlePostFix}`;

				window.document.title = pageTitle;

				ReactGA.send({
					hitType: "pageview",
					page: window.location.pathname,
					title: pageTitle,
				});

				if (hotjar.initialized()) {
					hotjar.stateChange(window.location.pathname);
				}

				Mixpanel.track(`Company Profile Viewed`, {
					name: `${company.name}`,
					pageTitle: `${company.name}`,
					url: window.location.pathname,
					distinct_id: userEmail,
				});
			}
		} catch (e) {
			console.log(e);
		}
	}, [company.name, type, userEmail]);

	return (
		<div className={classNames(styles.company, {[styles.recommended]: isRecommended})}>
			{isRecommended && (
				<span className={styles.enhanceIcon}>
					<EnhanceIcon />
				</span>
			)}

			<div className={styles.leftContainer}>
				<div className={styles.companyInfo}>
					<div className={styles.logo}>
						<img
							alt={company.name}
							src={
								company.logo_url !== null
									? company.logo_url
									: "https://storage.googleapis.com/nebula-static/logos100x100/empty_logo.png"
							}
						/>
					</div>
					<div className={styles.introduction}>
						<div className={styles.name}>{company.name}</div>
						<div className={styles.industry}>{company.industry?.name}</div>
					</div>
				</div>

				<div className={styles.location}>
					<span style={{width: 30}}>HQ</span>

					{company?.headquarters || company.headquarters_country ? (
						<span className={styles.headquarters}>
							{company.headquarters}
							{company?.headquarters_country &&
								(company.headquarters !== null ? ", " : "") +
									company.headquarters_country?.name}
						</span>
					) : (
						<span className={styles.headquarters}>N/A</span>
					)}
				</div>

				<div className={styles.specialities}>
					{company.specialties && company.specialties.length > 0 && (
						<ButtonsRow items={company.specialties} />
					)}
				</div>
			</div>

			<div className={styles.rightContainer}>
				<div className={styles.shortStats}>
					{company.size && (
						<span>
							<span className={styles.label}>Headcount:</span>
							<span className={styles.value}>{formatSizeBand(company.size)}</span>
						</span>
					)}

					{company.revenue && (
						<span>
							<span className={styles.label}>Revenue:</span>
							<span className={styles.value}>
								{"$" + formatRevenueNumber(company.revenue)}
							</span>
						</span>
					)}
				</div>

				<div className={styles.description}>
					{type === "profile" &&
						company.description != null &&
						decodeCharacterReferences(company.description)}
				</div>

				<div className={styles.targetListButton}>
					<GeneralButton
						customClass={styles.saveButton}
						isClicked={isSaved}
						handleClick={handleSaveItem}
						loadingSave={loadingSave}
						title={type === "project" ? "Remove" : isSaved ? "Saved" : "Save"}
						options={
							hasAccessToMarketMaps
								? [
										{
											label: "Add to a Target List",
											action: handleDisplayAddToMarketMapModal,
										},
								  ]
								: undefined
						}
					/>
				</div>
			</div>

			{modalDisplay && <AddToMarketMapModal company={company} />}
		</div>
	);
};

export default OverView;
