import type {FC} from "react";

import type {IconProps} from "./types";

const ArrowThin: FC<IconProps> = ({color = "#0C5850", ...rest}) => (
	<svg
		width={16}
		height={17}
		viewBox="0 0 16 17"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...rest}
	>
		<path
			d="M11.9103 10.4048L7.94018 6.59524L3.97009 10.4048"
			stroke={color}
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);

export default ArrowThin;
