import type {FC} from "react";
import ContentLoader from "react-content-loader";

import styles from "./CompanySkeletons.module.scss";

const skeletonItemCount = new Array(5).fill("");

const CompetitorsSkeleton: FC = () => {
	const renderSkeletonItem = skeletonItemCount.map((_, idx) => (
		<ContentLoader
			key={idx}
			speed={2}
			height={400}
			width={"100%"}
			backgroundColor="#DCE7E7"
			foregroundColor="#F9F9F9"
		>
			<circle cx="50%" cy="100" r="50" />
			<rect x="60" y="157" rx="4" width="50%" height="20" />
			<rect x="37" y="182" rx="4" width="70%" height="20" />
			<rect x="47" y="207" rx="4" width="60%" height="20" />
			<rect x="20" y="232" rx="4" width="85%" height="20" />
			<rect x="70" y="257" rx="4" width="40%" height="20" />
			<rect x="25" y="282" rx="4" width="80%" height="20" />
		</ContentLoader>
	));

	return <div className={styles.skeleton__wrapper}>{renderSkeletonItem}</div>;
};

export default CompetitorsSkeleton;
