import type {FC} from "react";
import ContentLoader from "react-content-loader";

const InfoCard: FC = (props) => (
	<ContentLoader
		speed={2}
		height={220}
		width={"100%"}
		backgroundColor="#EEEDEC"
		foregroundColor="#e6e6e6"
		{...props}
	>
		<circle cx="30.2" cy="43.2" r="25.3" />
		<rect x="70.9" y="14.5" width="30%" height="20" />
		<rect x="70.9" y="41.7" width="32%" height="25" />

		<rect x="10.9" y="88.8" width="99%" height="22" />
		<rect x="10.9" y="118.8" width="99%" height="22" />
		<rect x="10.9" y="148.8" width="99%" height="22" />

		<rect x="55%" y="185.3" width="128" height="30" />
		<rect x="74%" y="185.3" width="78" height="30" />
		<rect x="86%" y="185.3" width="108" height="30" />
	</ContentLoader>
);

export default InfoCard;
