import type {FC} from "react";

import type {IconProps} from "./types";

const ZoomIn: FC<IconProps> = ({className = ""}) => (
	<svg
		width="44"
		height="40"
		fill="none"
		viewBox="0 0 44 40"
		className={className}
		xmlns="http://www.w3.org/2000/svg"
	>
		<rect width="44" height="40" rx="5" fill="#F9F9F9" />
		<path
			fill="#3C6662"
			d="M26.375 22.5H25.3875L25.0375 22.1625C26.2625 20.7375 27 18.8875 27 16.875C27 12.3875 23.3625 8.75 18.875 8.75C14.3875 8.75 10.75 12.3875 10.75 16.875C10.75 21.3625 14.3875 25 18.875 25C20.8875 25 22.7375 24.2625 24.1625 23.0375L24.5 23.3875V24.375L30.75 30.6125L32.6125 28.75L26.375 22.5ZM18.875 22.5C15.7625 22.5 13.25 19.9875 13.25 16.875C13.25 13.7625 15.7625 11.25 18.875 11.25C21.9875 11.25 24.5 13.7625 24.5 16.875C24.5 19.9875 21.9875 22.5 18.875 22.5Z"
		/>
		<path
			fill="#323232"
			stroke="#0C5850"
			d="M19.5 16.75H21.5V17H19.5H19V17.5V19.5H18.75V17.5V17H18.25H16.25V16.75H18.25H18.75V16.25V14.25H19V16.25V16.75H19.5Z"
		/>
	</svg>
);

export default ZoomIn;
