import type {IconProps} from "./types";

const LineRightArrowIcon = (props: IconProps) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={20}
		height={20}
		fill="none"
		viewBox="0 0 20 20"
		{...props}
	>
		<g clipPath="url(#a)">
			<rect width={20} height={20} fill="#CBC5C2" rx={2} transform="matrix(0 1 1 0 0 0)" />
			<mask
				id="b"
				width={20}
				height={20}
				x={0}
				y={0}
				maskUnits="userSpaceOnUse"
				style={{
					maskType: "alpha",
				}}
			>
				<path fill="#D9D9D9" d="M0 0v20h20V0z" />
			</mask>
			<g mask="url(#b)">
				<path fill="#404040" d="m10 15 2.542-4.25H2v-1.5h10.542L10 5l8 5-8 5Z" />
			</g>
		</g>
		<defs>
			<clipPath id="a">
				<rect width={20} height={20} fill="#fff" rx={2} transform="matrix(0 1 1 0 0 0)" />
			</clipPath>
		</defs>
	</svg>
);

export default LineRightArrowIcon;
