import placeHolderImage from "assets/images/avatar-icon.png";
import React, {ChangeEvent, useCallback, useMemo, useState} from "react";
import useFetchListOptions from "hooks/useFetchListOptions";
import {GeneralButton, SearchInput} from "mapx-components";
import styles from "../styles.module.scss";
import {
	prCompaniesSelector,
	prJobTitleSuggestionLoadingSelector,
} from "store/mapx/additional-profiles/additionalProfilesSelectors";
import {setPRFilter} from "store/mapx/additional-profiles/additionalProfilesActions";
import {Checkbox, ResetFilters} from "components";
import {removeDuplicatesFromArray} from "helpers/filterHandlers";
import CheckboxList from "mapx-components/Inputs/CheckboxList";
import {IconUsers, MarketMapsIcon, SelectAllIcon, SortIcon} from "assets/icons";

import {useAppDispatch, useAppSelector} from "hooks";
import {addFromMarketMapModal} from "store/mapx/market-map/marketMapActions";
import {projectSelector} from "store/mapx/project-list/projectListSelectors";
import {setCompanyForPRFilters} from "store/mapx/additional-profiles/profileRequestConfigurator";
import {TAPCompaniesFilterProps, TSelectableCompanies, TSortOptions} from "./types";

const APCompaniesFilter = ({options}: TAPCompaniesFilterProps) => {
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	const {filteredData, onFilterChanged} = useFetchListOptions({
		options,
	});

	const selectedCompanies = useAppSelector(prCompaniesSelector);

	const dispatch = useAppDispatch();

	const [sort, setSort] = useState<TSortOptions>("up");

	const isPRSaving = useAppSelector(prJobTitleSuggestionLoadingSelector);

	const project = useAppSelector(projectSelector);

	const sortedFilteredData = useMemo(() => {
		if (sort === "up") {
			return [...filteredData].sort(
				(a: TSelectableCompanies, b: TSelectableCompanies) =>
					a.candidates_count - b.candidates_count,
			);
		} else {
			return [...filteredData].sort(
				(a: TSelectableCompanies, b: TSelectableCompanies) =>
					b.candidates_count - a.candidates_count,
			);
		}
	}, [filteredData, sort]);

	const handleChange = useCallback(
		(id: number) => {
			dispatch(setCompanyForPRFilters(id));
		},
		[dispatch],
	);

	const handleResetClick = useCallback(() => {
		dispatch(setPRFilter({type: "company_ids", values: []}));
	}, [dispatch]);

	const displayReset = useMemo(() => {
		return selectedCompanies?.length > 0;
	}, [selectedCompanies]);

	const handleSelectAll = () => {
		const allIds = [...sortedFilteredData].map(({id}) => id);

		dispatch(setPRFilter({type: "company_ids", values: removeDuplicatesFromArray(allIds)}));
	};

	const shouldDisplaySelectAll = useMemo(() => {
		return sortedFilteredData?.length !== selectedCompanies?.length;
	}, [selectedCompanies?.length, sortedFilteredData]);

	const handleSortClick = useCallback(() => {
		if (sort === "up") {
			setSort("down");
		} else {
			setSort("up");
		}
	}, [setSort, sort]);

	const displayOverlay = useMemo(() => {
		return isPRSaving;
	}, [isPRSaving]);

	const handleAddFromMarketMap = useCallback(() => {
		if (project?.target_list_id) {
			dispatch(addFromMarketMapModal(true, {targetListID: project.target_list_id}));
		}
	}, [dispatch, project?.target_list_id]);

	return (
		<div>
			<div className={styles.searchInputWrapper}>
				<SearchInput
					name="company"
					type="text"
					placeholder="Filter by Company name"
					className={styles.searchBox}
					onChange={(e: ChangeEvent<HTMLInputElement>) => onFilterChanged(e)}
					margin="0 auto"
				/>

				<GeneralButton
					customClass={styles.marketMap}
					icon={<MarketMapsIcon color="#0c5850" />}
					title={"Market Map"}
					onClick={handleAddFromMarketMap}
				/>
			</div>

			<div className={styles.resetFilterContainer}>
				<div className={styles.resetArea}>
					{displayReset ? (
						<ResetFilters
							parentStyle={{marginBottom: 0, color: "#5A5A5A", marginRight: 19}}
							onClick={handleResetClick}
							displayIcon={true}
						>
							Clear Selection
						</ResetFilters>
					) : (
						<span />
					)}

					{shouldDisplaySelectAll && (
						<div className={styles.selectAll} onClick={handleSelectAll}>
							<SelectAllIcon /> Select All
						</div>
					)}
				</div>

				{sortedFilteredData?.length > 1 && (
					<div className={styles.sortable} onClick={handleSortClick}>
						Sort <SortIcon sortType={sort} />
					</div>
				)}
			</div>

			<CheckboxList
				style={{paddingRight: 0}}
				displayOverlay={displayOverlay}
				overlayText={"Adjusting search parameters"}
			>
				{sortedFilteredData.map((item: TSelectableCompanies) => {
					const {id, name, candidates_count, logo_url} = item;

					return (
						<div key={id} style={{display: "flex", flexDirection: "column"}}>
							<Checkbox
								borderColor="#0C5850"
								containerClass={styles.checkboxContainer}
								isChecked={selectedCompanies?.includes(id)}
								key={id}
								// label={name + (candidates_count && ` (${candidates_count})`)}
								label={
									<div className={styles.labelContainer}>
										<div className={styles.labelTitle} title={name}>
											<div className={styles.image}>
												<img
													src={logo_url || placeHolderImage}
													onError={(e) => {
														e.currentTarget.src = placeHolderImage;
													}}
													alt={name}
												/>
											</div>{" "}
											{name}
										</div>
										<div className={styles.labelIconContainer}>
											{candidates_count} <IconUsers color="#808080" />
										</div>
									</div>
								}
								onChange={() => handleChange(id)}
								value={name}
								disabled={isPRSaving}
							/>
						</div>
					);
				})}
			</CheckboxList>
		</div>
	);
};

export default APCompaniesFilter;
