import {useCallback, useEffect, useState} from "react";
import useSearchDebounce from "hooks/useSearchDebounce";
import {
	allCompaniesCountSelector,
	searchedCompaniesSelector,
} from "store/mapx/filter/filterSelectors";
import {useAppDispatch, useAppSelector} from "hooks";
import {targetCompaniesPaginationSelector} from "store/mapx/target-list/targetListSelectors";
import {
	apCandidateCompanyByPositionSelector,
	apCandidateCompanyPositionSelector,
	apTargetListPositionSelector,
	apTargetListToggleSelector,
	apTLCompaniesByTargetListPositionSelector,
	isApTargetListExpandedSelector,
} from "store/mapx/additional-profiles/additionalProfilesSelectors";
import {
	moveAPCompanyAll,
	moveApTlCompanyAll,
	removeAllCompaniesFromProjectCandidateFilter,
	setAPCompany,
	setAPCompanyToTargetList,
	toggleAllTargetCompaniesOnAPCandidateFilter,
} from "store/mapx/additional-profiles/additionalProfilesAsyncActions";
import {
	setAPCandidateTargetListToggle,
	setAPMultipleFilters,
} from "store/mapx/additional-profiles/additionalProfilesActions";
import CompanySearchFilter from "mapx-components/Filters/CompanySearchFilterWithTL";
import {STCompany} from "api/companyApi/types";
import {useSelector} from "react-redux";
import TargetListFilter from "mapx-components/Filters/CompanySearchFilterWithTL/TargetListFilter";
import {getSearchedCompaniesData} from "store/mapx/filter/CompanySearchPageFilters/CompanyFilterAsyncActions";

function CompanyFilter() {
	const dispatch = useAppDispatch();

	const position = useAppSelector(apCandidateCompanyPositionSelector);

	const handlePositionChange = useCallback(
		(value: string) => {
			dispatch(moveAPCompanyAll({position: value}));
		},
		[dispatch],
	);

	const handleOnChange = (company: STCompany) => {
		dispatch(setAPCompany({id: company}));
	};

	const handleResetClick = () => {
		dispatch(
			setAPMultipleFilters({
				current_companies: [],
				previous_companies: [],
				companies: [],
			}),
		);
	};

	const [searchTerm, setSearchTerm] = useSearchDebounce(800);

	const [loading, setLoading] = useState(false);

	const searchedCompanies = useAppSelector(searchedCompaniesSelector);

	const companiesCount = useAppSelector(allCompaniesCountSelector);

	const targetCompaniesPagination = useAppSelector(targetCompaniesPaginationSelector);

	const searchCompanies = useCallback(
		async (companyName: string) => {
			if (companyName && companyName.length >= 2) {
				setLoading(true);
				await dispatch(getSearchedCompaniesData(companyName)).then(
					(response: {error: {name: string}}) => {
						if (response?.error && response?.error?.name === "CanceledError") {
							setLoading(true);
						} else {
							setLoading(false);
						}
					},
				);
			}
		},
		[dispatch],
	);

	const tagCompanies = useSelector(apCandidateCompanyByPositionSelector);

	const handleTagClick = useCallback(
		(id: STCompany) => {
			dispatch(setAPCompany({id}));
		},
		[dispatch],
	);

	const TLFilterShouldBeExpanded = useAppSelector(isApTargetListExpandedSelector);

	const TLFilterToggled = useAppSelector(apTargetListToggleSelector);

	const TLPosition = useAppSelector(apTargetListPositionSelector);

	const selectedCompaniesOnTL = useAppSelector((state) =>
		apTLCompaniesByTargetListPositionSelector(state),
	);

	const handleToggleTL = useCallback(() => {
		dispatch(toggleAllTargetCompaniesOnAPCandidateFilter(!TLFilterToggled));
	}, [dispatch, TLFilterToggled]);

	const handleTLResetClick = useCallback(() => {
		dispatch(removeAllCompaniesFromProjectCandidateFilter());
	}, [dispatch]);

	const handleSelectAllTL = useCallback(() => {
		dispatch(toggleAllTargetCompaniesOnAPCandidateFilter(true));
	}, [dispatch]);

	const handleTLPositionChange = useCallback(
		(value: string) => {
			dispatch(moveApTlCompanyAll({position: value}));
		},
		[dispatch],
	);

	const handleTLChange = useCallback(
		(company: STCompany) => {
			dispatch(setAPCompanyToTargetList({id: company}));
		},
		[dispatch],
	);

	useEffect(() => {
		if (TLFilterShouldBeExpanded) {
			dispatch(setAPCandidateTargetListToggle(true));
		}
	}, [dispatch, TLFilterShouldBeExpanded]);

	return (
		<div>
			<CompanySearchFilter
				// Search Props
				searchTerm={searchTerm}
				setSearchTerm={setSearchTerm}
				loading={loading}
				position={position}
				handlePositionChange={handlePositionChange}
				searchedCompanies={searchedCompanies}
				companiesCount={companiesCount}
				handleItemClick={handleOnChange}
				handleResetClick={handleResetClick}
				searchCompanies={searchCompanies}
				// Tag props
				handleTagClick={handleTagClick}
				tagCompanies={tagCompanies}
			/>
			<TargetListFilter
				totalCompanies={targetCompaniesPagination?.count}
				TLFilterToggled={TLFilterToggled}
				TLPosition={TLPosition}
				selectedCompaniesOnTL={selectedCompaniesOnTL}
				handleToggleTL={handleToggleTL}
				handleTLResetClick={handleTLResetClick}
				handleSelectAllTL={handleSelectAllTL}
				handleTLPositionChange={handleTLPositionChange}
				handleTLChange={handleTLChange}
			/>
		</div>
	);
}

export default CompanyFilter;
