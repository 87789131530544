import {FloatAddedIcon, MagicIcon} from "assets/icons";
import {Checkbox, ResetFilters, Tag} from "components";
import {SearchInput} from "mapx-components/index";
import React, {ChangeEvent, useCallback, useEffect, useMemo, useRef, useState} from "react";
import {getJobTitlesSuccess} from "store/mapx/search/searchActions";
import styles from "./jobTitles.module.scss";
import {TJobTitlesSearchFilterProps} from "mapx-components/Filters/JobTitlesSearchFilter/types";
import {useAppDispatch, useAppSelector} from "hooks";
import useSearchDebounce from "hooks/useSearchDebounce";
import useJobTitleSearchService from "services/useJobTitleSearchService";
import {debounce} from "lodash";
import {projectSelector} from "store/mapx/project-list/projectListSelectors";
import {STProject} from "api/projectApi/types";
import Loader from "components/Plugins/Loader";

function JobTitlesSearchFilter({
	handleOnChange,
	selectedJobTitles = [],
	isDisabled = false,
	suggestedDefaultJobTitles = [],
	handleResetClick,
	handleBulkUpdate,
	addByClick = false,
	loading = false,
	expanded = true,
}: TJobTitlesSearchFilterProps) {
	const dispatch = useAppDispatch();

	const ref = useRef<Nullable<HTMLDivElement>>(null);

	const [loaderHeight, setLoaderHeight] = useState(0);

	const project: STProject = useAppSelector(projectSelector);

	const {loadingJobTitle, fetchJobTitle} = useJobTitleSearchService(
		project && project?.company && project?.company?.id ? project.company.id : null,
	);

	const [searchTerm, setSearchTerm] = useSearchDebounce(500, "");

	const setData = useCallback(
		(data: string[]) => {
			dispatch(getJobTitlesSuccess(data));

			if (handleBulkUpdate) {
				handleBulkUpdate(data);
			}
		},
		[dispatch, handleBulkUpdate],
	);

	const fetch = useCallback(
		async (searchTerm: string) => {
			await fetchJobTitle(searchTerm, setData);

			const inputElement = document.querySelector(
				'input[placeholder="Search for a Job title"]',
			) as HTMLInputElement;

			if (inputElement) {
				inputElement.value = "";
			}

			setSearchTerm("");
		},
		[fetchJobTitle, setData, setSearchTerm],
	);

	const onSearch = debounce(async (e: ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value;

		setSearchTerm(value);

		if (value !== "" && !addByClick) {
			await fetch(value);
		}
	}, 500);

	const hideAddJobTitleIcon = useMemo(() => {
		if (!searchTerm || loadingJobTitle) return true;

		if (searchTerm !== "" && selectedJobTitles?.length > 0) {
			for (const item of selectedJobTitles) {
				if (item.toLowerCase() === searchTerm.toLowerCase()) {
					return true;
				}
			}
		}

		return false;
	}, [loadingJobTitle, searchTerm, selectedJobTitles]);

	const handleAddJobTitleIconClick = useCallback(async () => {
		if (!hideAddJobTitleIcon) {
			const inputElement = document.querySelector(
				'input[placeholder="Search for a Job title"]',
			) as HTMLInputElement;

			await fetch(inputElement.value);
		}
	}, [fetch, hideAddJobTitleIcon]);

	const handleOnSearchEnterClick = useCallback(
		(e: KeyboardEvent) => {
			if (e.code === "Enter" || e.key === "Enter") {
				handleAddJobTitleIconClick();
			}
		},
		[handleAddJobTitleIconClick],
	);

	useEffect(() => {
		return () => {
			dispatch(getJobTitlesSuccess([]));
		};
	}, [dispatch]);

	useEffect(() => {
		const updateHeight = () => {
			if (ref.current) {
				const calculatedHeight = ref.current.offsetHeight;

				const height = calculatedHeight < 144 ? 100 : calculatedHeight;

				setLoaderHeight(height + 8);
			}
		};

		if (expanded) {
			updateHeight();
		}

		const resizeObserver = new ResizeObserver(() => {
			updateHeight();
		});

		if (ref.current) {
			resizeObserver.observe(ref.current);
		}
	}, [expanded]);

	const InlineLoaderComponent = (
		<Loader height={60} width={60} customStyle={{height: "50px"}} type="ThreeDots" />
	);

	return (
		<div ref={ref}>
			<div className={styles.searchInputWrapper}>
				{!hideAddJobTitleIcon && (
					<FloatAddedIcon
						onClick={handleAddJobTitleIconClick}
						className={styles.floatAddedIcon}
						data-testid="add-job-title-icon"
					/>
				)}

				<SearchInput
					defaultValue={searchTerm}
					onChange={onSearch}
					placeholder="Search for a Job title"
					type="text"
					isLoading={loadingJobTitle}
					onKeyDown={handleOnSearchEnterClick}
					disabled={isDisabled || loading}
				/>
			</div>

			{selectedJobTitles?.length > 0 && (
				<ResetFilters
					parentStyle={{marginBottom: 0, color: "#5A5A5A", marginRight: 19}}
					onClick={handleResetClick}
					displayIcon={true}
				>
					Clear Selection
				</ResetFilters>
			)}

			<div className={styles.selectedJobTitles}>
				{selectedJobTitles?.map((jobTitle) => (
					<Tag closable={true} key={jobTitle} onClick={() => handleOnChange(jobTitle)}>
						{jobTitle}
					</Tag>
				))}
			</div>

			{searchTerm === "" && suggestedDefaultJobTitles?.length > 0 && (
				<div>
					<div className={styles.title}>
						{searchTerm !== "" ? (
							!loadingJobTitle && "Select Job Titles"
						) : (
							<>
								<MagicIcon /> AI Suggested Job Titles
							</>
						)}
					</div>

					{!loadingJobTitle && (
						<div className={styles.checkboxList}>
							{suggestedDefaultJobTitles?.map((jobTitle: string) => (
								<Checkbox
									borderColor="#0C5850"
									containerClass={styles.checkboxContainer}
									isChecked={selectedJobTitles.includes(jobTitle)}
									key={jobTitle}
									label={jobTitle}
									onChange={() => handleOnChange(jobTitle)}
									value={jobTitle}
									disabled={isDisabled}
								/>
							))}
						</div>
					)}
				</div>
			)}

			{loading && (
				<div className={styles.loader} style={{height: loaderHeight, width: 350}}>
					{InlineLoaderComponent}

					<span>Generating Job Titles</span>
				</div>
			)}
		</div>
	);
}

export default JobTitlesSearchFilter;
