import {useCallback, useEffect, useMemo, useState} from "react";
import {useSelector} from "react-redux";
import useInfiniteScroll from "react-infinite-scroll-hook";
import {debounce} from "lodash";

import {ContentCopyIcon, PencilIcon, RecycleBinPermanent} from "assets/icons";
import {useAppDispatch, useAppSelector} from "hooks";
import {customConfirmAlert} from "helpers";
import {EMPTY_COMPANY_LOGO} from "mapx-constants";
import {deleteMarketMap, getMarketMaps} from "store/mapx/market-map/marketMapAsyncFunctions";
import {
	deleteMarketMapInProgressSelector,
	duplicateMarketMapModalSelector,
	editMarketMapModalSelector,
	getMarketMapsInProgressSelector,
	marketMapCreatedBySelector,
	marketMapNameSelector,
	marketMapsPaginationSelector,
	marketMapsSelector,
} from "store/mapx/market-map/marketMapSelectors";
import MarketMapModal from "mapx-components/Modals/MarketMapModal";
import EditMarketMapModal from "mapx-components/Modals/EditMarketMapModal";
import DuplicateMarketMapModal from "mapx-components/Modals/DuplicateMarketMapModal";
import {duplicateMarketMapModal, editMarketMapModal} from "store/mapx/market-map/marketMapActions";
import type {STMarketMap} from "api/marketMapApi/types";
import type {STCompany} from "api/companyApi/types";

import css from "./List.module.scss";
import Options from "./options";
import Loader from "./loader";
import type {TList} from "./types";

const List = ({search, createdBy}: TList) => {
	const dispatch = useAppDispatch();

	const user = useAppSelector((state) => state.user.user);

	const marketMaps = useAppSelector(marketMapsSelector);
	const pagination = useAppSelector(marketMapsPaginationSelector);
	const getting = useAppSelector(getMarketMapsInProgressSelector);
	const hasMoreItems = pagination.pages > pagination.page;

	const duplicateMarketMapModalOpen = useSelector(duplicateMarketMapModalSelector);
	const editMarketMapModalOpen = useSelector(editMarketMapModalSelector);
	const deletingMarketMaps = useSelector(deleteMarketMapInProgressSelector);
	const prevSearch = useSelector(marketMapNameSelector);
	const prevCreatedBy = useSelector(marketMapCreatedBySelector);

	const [modalDisplay, setModalDisplay] = useState(false);
	const [modalProps, setModalProps] = useState<STMarketMap | null>(null);
	const [editModalProps, setEditModalProps] = useState<STMarketMap | null>(null);
	const [duplicateModalProps, setDuplicateModalProps] = useState<STMarketMap | null>(null);

	const createdByChanged = useMemo(
		() =>
			JSON.stringify(createdBy.map((cb) => cb.value).sort()) !==
			JSON.stringify(prevCreatedBy.sort()),
		[createdBy, prevCreatedBy],
	);

	const handleEditModalDisplay = useCallback(
		(open: boolean) => {
			dispatch(editMarketMapModal(open));
		},
		[dispatch],
	);

	const handleDuplicateModalDisplay = useCallback(
		(open: boolean) => {
			dispatch(duplicateMarketMapModal(open));
		},
		[dispatch],
	);

	const loadMore = useCallback(() => {
		dispatch(
			getMarketMaps(
				search,
				createdBy.map((c) => c.value as "organisation" | "self" | "mapx"),
			),
		);
	}, [createdBy, dispatch, search]);

	const [sentryRef] = useInfiniteScroll({
		loading: getting && marketMaps.length !== 0,
		hasNextPage: hasMoreItems,
		onLoadMore: loadMore,
	});

	const handleTitleClick = (map: STMarketMap) => {
		setModalProps(map);
		setModalDisplay(true);
	};

	const handleDeleteClick = useCallback(
		(id: number) => {
			customConfirmAlert({
				yesButtonLabel: "Yes",
				noButtonLabel: "No",
				title: "Target List Deletion Warning!",
				message: `You may have saved companies in the target list which will be lost once you remove this target list. Are you sure about deleting the target list?`,
				handlePressYes: () => {
					dispatch(deleteMarketMap(id));
				},
			});
		},
		[dispatch],
	);

	const debouncedGetMarketMaps = useMemo(
		() =>
			debounce((search: string, createdBy: ("organisation" | "self" | "mapx")[]) => {
				dispatch(getMarketMaps(search, createdBy));
			}, 300),
		[dispatch],
	);

	useEffect(() => {
		debouncedGetMarketMaps(
			search,
			createdBy.map((c) => c.value as "organisation" | "self" | "mapx"),
		);
	}, [search, createdBy, debouncedGetMarketMaps]);

	return (
		<>
			{(marketMaps.length === 0 && getting) ||
			(getting && prevSearch !== search) ||
			(getting && createdByChanged) ? (
				<Loader />
			) : (
				<div className={css.list}>
					{marketMaps.length === 0 && search && (
						<div className={css.empty}>
							{'No results found for "'}
							{<strong>{search}</strong>}
							{'".'}
						</div>
					)}
					{marketMaps.map((map: STMarketMap) => (
						<div key={map.id} className={css.map}>
							<div className={css.interaction}>
								<div className={css.name} onClick={() => handleTitleClick(map)}>
									{map.name}
								</div>
								{(user.isSuperUser || !map.is_global) && (
									<Options
										loading={deletingMarketMaps.includes(map.id)}
										options={[
											{
												name: "Edit Target List",
												action: () => {
													setEditModalProps(map);
													handleEditModalDisplay(true);
													setModalDisplay(false);
												},
												icon: <PencilIcon />,
											},
											{
												name: "Duplicate Target List",
												action: () => {
													setDuplicateModalProps(map);
													handleDuplicateModalDisplay(true);
													setModalDisplay(false);
												},
												icon: <ContentCopyIcon />,
											},
											{
												name: "Delete Target List",
												action: () => handleDeleteClick(map.id),
												icon: <RecycleBinPermanent />,
												color: "#F20D0D",
											},
										]}
									/>
								)}
							</div>
							<div className={css.description}>{map.description}</div>
							<div className={css.companies}>
								{map.companies.slice(0, 6).map((company: STCompany) => (
									<div
										key={company.id.toString()}
										className={css.companyImageWrapper}
									>
										<img
											loading="lazy"
											alt={company.name}
											className={css.companyImage}
											src={company?.logo_url || EMPTY_COMPANY_LOGO}
											onError={(e) => {
												(e.target as HTMLImageElement).src =
													EMPTY_COMPANY_LOGO;
											}}
										/>
									</div>
								))}
								{map.companies.length > 6 && (
									<div className={css.companyImageWrapper}>
										<div className={css.companyImage}>
											{map.companies.length}
										</div>
									</div>
								)}
								<div className={css.createdBy}>
									Created by{" "}
									{map.is_global
										? "MapX"
										: map.owner_id === user.user_id
										? "You"
										: "Your Organisation"}
								</div>
							</div>
						</div>
					))}

					{!getting && marketMaps.length !== 0 && hasMoreItems && (
						<div style={{height: 2}} ref={sentryRef} />
					)}

					{getting && marketMaps.length > 0 && <Loader count={1} />}
				</div>
			)}
			<MarketMapModal
				modalProps={modalProps}
				modalDisplay={modalDisplay}
				setModalDisplay={setModalDisplay}
			/>
			{editModalProps && editMarketMapModalOpen && (
				<EditMarketMapModal
					modalProps={editModalProps}
					modalDisplay={editMarketMapModalOpen}
					setModalDisplay={handleEditModalDisplay}
				/>
			)}
			{duplicateModalProps && duplicateMarketMapModalOpen && (
				<DuplicateMarketMapModal
					modalProps={duplicateModalProps}
					modalDisplay={duplicateMarketMapModalOpen}
					setModalDisplay={handleDuplicateModalDisplay}
				/>
			)}
		</>
	);
};

export default List;
