import Person1 from "assets/images/person1.png";
import Person2 from "assets/images/person2.png";
import Person3 from "assets/images/person3.png";
import Person4 from "assets/images/person4.png";
import Person5 from "assets/images/person5.png";
import UserFirst from "assets/images/user-1.png";
import InfoSecond from "assets/images/info-2.png";
import UserThird from "assets/images/user-third.png";
import InfoFirst from "assets/images/info-second.png";
import UserSecond from "assets/images/user-second.png";

import type {TChartProps, TPersonChartProps, TUserProps} from "./types";

export const personChart: TPersonChartProps[] = [
	{src: Person1},
	{src: Person2},
	{src: Person3},
	{src: Person4},
	{src: Person5},
];

export const users1: TUserProps[] = [
	{
		name: "Olivia Robins",
		src: UserFirst,
	},
	{src: InfoFirst},
];

export const users2: TUserProps[] = [
	{
		src: UserSecond,
		name: "Giuliano Buono",
	},
	{src: InfoSecond},
];

export const users3: TUserProps[] = [
	{
		src: UserThird,
		name: "Lara Howlett",
	},
	{src: InfoSecond},
];

export const emptyData: TChartProps[] = [
	{color: "#61C683"},
	{color: "#E05A5A"},
	{color: "#FA9D31"},
];

export const randomEmptyLineWidth = (idx: number): number =>
	Math.floor(Math.random() * (100 - 35 + (idx + 1))) + 35;
