import type {IconProps} from "./types";

const ImageIcon = (props: IconProps) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={20}
			height={20}
			viewBox="0 0 20 20"
			fill="none"
			{...props}
		>
			<mask
				id="a"
				width={20}
				height={20}
				x={0}
				y={0}
				maskUnits="userSpaceOnUse"
				style={{
					maskType: "alpha",
				}}
			>
				<path fill="#D9D9D9" d="M0 0h20v20H0z" />
			</mask>
			<g mask="url(#a)">
				<path
					fill="#404040"
					d="M4.56 17.17c-.48 0-.887-.172-1.224-.513a1.674 1.674 0 0 1-.505-1.217V4.56c0-.47.168-.875.505-1.217a1.655 1.655 0 0 1 1.224-.512h10.88c.48 0 .887.17 1.224.512.337.342.505.748.505 1.217v10.88c0 .47-.168.875-.505 1.217a1.655 1.655 0 0 1-1.224.512H4.56Zm0-1.73h10.88V4.56H4.56v10.88Zm.855-1.38h9.17L11.5 9.9l-2.25 3-1.5-2-2.335 3.16Z"
				/>
			</g>
		</svg>
	);
};

export default ImageIcon;
