import {useCallback} from "react";
import {useSelector} from "react-redux";

import colors from "styles/themes.module.scss";
import {useAppDispatch, useModalHook} from "hooks";
import {contactAdminModalSelector} from "store/mapx/payment/paymentSelectors";
import {contactAdminModal} from "store/mapx/payment/paymentActions";
import {organisationAdminSelector} from "store/mapx/organisation/organisationSelectors";
import ActionAltButton from "mapx-components/Buttons/ActionAltButton";
import {ModalContent, ModalHeader, ModalSeparator} from "components";

import styles from "./ContactAdminModal.module.scss";
import {userOrganisationRoleSelector} from "store/mapx/user/userSelectors";
import {Link} from "react-router-dom";

const ContactAdminModal = () => {
	const dispatch = useAppDispatch();

	const modalDisplay = useSelector(contactAdminModalSelector);
	const admin = useSelector(organisationAdminSelector);
	const role = useSelector(userOrganisationRoleSelector);

	const {modalIsOpen, customStyles, Modal} = useModalHook(
		{content: {width: "90%", borderRadius: "8px", maxWidth: "432px"}},
		modalDisplay,
		colors.mainThemeColor,
	);

	const handleClose = useCallback(() => {
		dispatch(contactAdminModal(false));
	}, [dispatch]);

	return (
		<Modal
			title="Upgrade MapX"
			style={customStyles}
			isOpen={modalIsOpen}
			contentLabel="Modal"
			onRequestClose={handleClose}
		>
			<ModalHeader padding="16px" title="Upgrade MapX" onClose={handleClose} />
			<ModalSeparator />
			<ModalContent className={styles.content}>
				{role === "Admin" ? (
					<>
						<div className={styles.title}>Assign A License Plan to Yourself</div>
						<div className={styles.description}>
							You are the admin of this organisation. You can assign a license plan to
							yourself from the{" "}
							<Link onClick={handleClose} to="/organisation-settings">
								organisation settings
							</Link>{" "}
							page.
						</div>
					</>
				) : (
					<>
						<div className={styles.title}>Please Contact Admin</div>
						<div className={styles.description}>
							Your organisation&apos;s admin holds billing access. You will find their
							contact information below. Kindly get in touch with them to discuss
							upgrade options.
						</div>
						<div className={styles.admin}>
							<div className={styles.info}>
								<span>Name: </span>
								{`${admin.first_name} ${admin.last_name}`}
							</div>
							<div className={styles.info}>
								<span>Email: </span>
								{admin.email}
							</div>
						</div>
					</>
				)}
				<ActionAltButton
					customClass={styles.cancel}
					handleClick={handleClose}
					title="Done"
				/>
			</ModalContent>
		</Modal>
	);
};

export default ContactAdminModal;
