import type {FC} from "react";
import {noop} from "lodash";

import type {IconProps} from "./types";

export const EyeHideIcon: FC<IconProps> = ({onClick = noop, className = "", fill = "#A1A1A1"}) => (
	<svg
		width="22"
		height="21"
		fill="none"
		onClick={onClick}
		viewBox="0 0 22 21"
		className={className}
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fill={fill}
			d="M15.1 11.3L13.65 9.84999C13.8 9.06665 13.575 8.33332 12.975 7.64999C12.375 6.96665 11.6 6.69999 10.65 6.84999L9.2 5.39999C9.48333 5.26665 9.77067 5.16665 10.062 5.09999C10.354 5.03332 10.6667 4.99999 11 4.99999C12.25 4.99999 13.3127 5.43732 14.188 6.31199C15.0627 7.18732 15.5 8.24999 15.5 9.49999C15.5 9.83332 15.4667 10.146 15.4 10.438C15.3333 10.7293 15.2333 11.0167 15.1 11.3ZM18.3 14.45L16.85 13.05C17.4833 12.5667 18.046 12.0373 18.538 11.462C19.0293 10.8873 19.45 10.2333 19.8 9.49999C18.9667 7.81665 17.7707 6.47899 16.212 5.48699C14.654 4.49565 12.9167 3.99999 11 3.99999C10.5167 3.99999 10.0417 4.03332 9.575 4.09999C9.10833 4.16665 8.65 4.26665 8.2 4.39999L6.65 2.84999C7.33333 2.56665 8.03333 2.35399 8.75 2.21199C9.46667 2.07065 10.2167 1.99999 11 1.99999C13.5167 1.99999 15.7583 2.69565 17.725 4.08699C19.6917 5.47899 21.1167 7.28332 22 9.49999C21.6167 10.4833 21.1127 11.396 20.488 12.238C19.8627 13.0793 19.1333 13.8167 18.3 14.45ZM18.8 20.6L14.6 16.45C14.0167 16.6333 13.4293 16.771 12.838 16.863C12.246 16.9543 11.6333 17 11 17C8.48333 17 6.24167 16.3043 4.275 14.913C2.30833 13.521 0.883333 11.7167 0 9.49999C0.35 8.61665 0.791667 7.79565 1.325 7.03699C1.85833 6.27899 2.46667 5.59999 3.15 4.99999L0.4 2.19999L1.8 0.799988L20.2 19.2L18.8 20.6ZM4.55 6.39999C4.06667 6.83332 3.625 7.30832 3.225 7.82499C2.825 8.34165 2.48333 8.89999 2.2 9.49999C3.03333 11.1833 4.229 12.5207 5.787 13.512C7.34567 14.504 9.08333 15 11 15C11.3333 15 11.6583 14.9793 11.975 14.938C12.2917 14.896 12.6167 14.85 12.95 14.8L12.05 13.85C11.8667 13.9 11.6917 13.9373 11.525 13.962C11.3583 13.9873 11.1833 14 11 14C9.75 14 8.68733 13.5627 7.812 12.688C6.93733 11.8127 6.5 10.75 6.5 9.49999C6.5 9.31665 6.51233 9.14165 6.537 8.97499C6.56233 8.80832 6.6 8.63332 6.65 8.44999L4.55 6.39999Z"
		/>
	</svg>
);

export default EyeHideIcon;
