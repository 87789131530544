import {GET_LEARN_TO_RANK_DONE} from "../mapXActionTypes";
import {GET_LEARN_TO_RANK_INIT} from "../mapXActionTypes";
import {GET_LEARN_TO_RANK_FAIL} from "../mapXActionTypes";
import {
	CREATE_LEARN_TO_RANK_DONE,
	CREATE_LEARN_TO_RANK_FAIL,
	CREATE_LEARN_TO_RANK_INIT,
	RECORD_LEARN_TO_RANK_DONE,
	RECORD_LEARN_TO_RANK_FAIL,
	RECORD_LEARN_TO_RANK_INIT,
} from "../mapXActionTypes";

export function createLearnToRankInit() {
	return {type: CREATE_LEARN_TO_RANK_INIT};
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function createLearnToRankDone(data: any) {
	return {type: CREATE_LEARN_TO_RANK_DONE, payload: data};
}

export function createLearnToRankFail(error: unknown) {
	return {type: CREATE_LEARN_TO_RANK_FAIL, payload: error};
}

export function recordLearnToRankInit() {
	return {type: RECORD_LEARN_TO_RANK_INIT};
}

export function recordLearnToRankDone() {
	return {type: RECORD_LEARN_TO_RANK_DONE};
}

export function recordLearnToRankFail(error: unknown) {
	return {type: RECORD_LEARN_TO_RANK_FAIL, payload: error};
}

export function getLearnToRankInit() {
	return {type: GET_LEARN_TO_RANK_INIT};
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getLearnToRankDone(data: any) {
	return {type: GET_LEARN_TO_RANK_DONE, payload: data};
}

export function getLearnToRankFail(error: unknown) {
	return {type: GET_LEARN_TO_RANK_FAIL, payload: error};
}
