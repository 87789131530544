import React from "react";

const FitToBriefLargeIcon = () => {
	return (
		<svg
			width="64"
			height="64"
			viewBox="0 0 64 64"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect width="64" height="64" rx="8" fill="#EDF2F2" />
			<mask
				id="mask0_1733_160443"
				style={{maskType: "alpha"}}
				maskUnits="userSpaceOnUse"
				x="8"
				y="8"
				width="48"
				height="48"
			>
				<rect x="8" y="8" width="48" height="48" fill="#D9D9D9" />
			</mask>
			<g mask="url(#mask0_1733_160443)">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12 23C12 22.2 12.3 21.5 12.9 20.9C13.5 20.3 14.2 20 15 20H24V15C24 14.2 24.3 13.5 24.9 12.9C25.5 12.3 26.2 12 27 12H37C37.8 12 38.5 12.3 39.1 12.9C39.7 13.5 40 14.2 40 15V20H49C49.8 20 50.5 20.3 51.1 20.9C51.7 21.5 52 22.2 52 23H49H15H12ZM37 20H27V15H37V20Z"
					fill="#0C5850"
				/>
				<path
					d="M41.75 52H15C14.2 52 13.5 51.7 12.9 51.1C12.3 50.5 12 49.8 12 49V23C12 22.2 12.3 21.5 12.9 20.9C13.5 20.3 14.2 20 15 20H49C49.8 20 50.5 20.3 51.1 20.9C51.7 21.5 52 22.2 52 23V49C52 49.8 51.7 50.5 51.1 51.1C50.5 51.7 49.8 52 49 52H45.982L35.85 41.65C35.15 42.25 34.4 42.6833 33.6 42.95C32.8 43.2167 31.95 43.35 31.05 43.35C28.7167 43.35 26.7083 42.5333 25.025 40.9C23.3417 39.2667 22.5 37.2583 22.5 34.875C22.5 32.4917 23.3417 30.4917 25.025 28.875C26.7083 27.2583 28.7167 26.45 31.05 26.45C33.3833 26.45 35.3583 27.2583 36.975 28.875C38.5917 30.4917 39.4 32.5 39.4 34.9C39.4 35.74 39.275 36.5333 39.025 37.28C38.775 38.0267 38.4167 38.7667 37.95 39.5L47.25 49H49V23H15V49H38.75L41.75 52ZM31.05 40.35C32.5833 40.35 33.8583 39.8333 34.875 38.8C35.8917 37.7667 36.4 36.4583 36.4 34.875C36.4 33.2917 35.8917 31.9917 34.875 30.975C33.8583 29.9583 32.5833 29.45 31.05 29.45C29.5167 29.45 28.2083 29.9583 27.125 30.975C26.0417 31.9917 25.5 33.2917 25.5 34.875C25.5 36.4583 26.0417 37.7667 27.125 38.8C28.2083 39.8333 29.5167 40.35 31.05 40.35Z"
					fill="#0C5850"
				/>
			</g>
		</svg>
	);
};

export default FitToBriefLargeIcon;
