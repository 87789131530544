import {useCallback, useMemo, useRef, useState} from "react";
import {toast} from "react-toastify";

import {CopyLinkIcon, ResendIcon, RevokeIcon, ThreeDotsHorizontal} from "assets/icons";
import {ToastContent} from "components";
import {DeleteUserModal, OrganizationActionsDropDown} from "mapx-components";
import {useAppDispatch, useAppSelector, useCopyToClipboard, useOutsideClick} from "hooks";
import {
	resendInvitation,
	revokeInvitation,
} from "store/mapx/organisation/organisationAsyncFunctions";
import {
	resendUserInvitationInProgressSelector,
	revokeInviteInProgressSelector,
} from "store/mapx/organisation/organisationSelectors";
import type {IInvitedUserProps} from "mapx-pages/Organisation/types";

import styles from "./InvitedUser.module.scss";
import {isSuperUserSelector, userOrganisationRoleSelector} from "store/mapx/user/userSelectors";

const InvitedUser = ({user}: IInvitedUserProps) => {
	const ref = useRef<HTMLDivElement | null>(null);

	const dispatch = useAppDispatch();

	const organisationRole = useAppSelector(userOrganisationRoleSelector);
	const isSuperUser = useAppSelector(isSuperUserSelector);
	const revokeInviteInProgress = useAppSelector(revokeInviteInProgressSelector);
	const resentInvitation = useAppSelector(resendUserInvitationInProgressSelector);

	const [showSettings, setShowSettings] = useState(false);
	const [showResendInvite, setShowResendInvite] = useState(false);
	const [showRevokeInvite, setShowRevokeInvite] = useState(false);

	const [copy] = useCopyToClipboard();

	const copyLinkHandler = useCallback(async () => {
		await copy(user?.invitation_link as string);
		toast.success(ToastContent, {data: {title: "Link copied to clipboard"}});
	}, [copy, user]);

	const resendInviteHandler = () => {
		if (user?.id) {
			dispatch(resendInvitation(user?.id));
		}
	};

	const revokeInviteHandler = () => {
		dispatch(revokeInvitation(user?.id));
	};

	const modalData = useMemo(() => {
		const data = [
			{
				id: 0,
				Icon: ResendIcon,
				isRemove: false,
				label: "Resend Invite",
				onClick: () => setShowResendInvite(!showResendInvite),
			},
		];

		if (organisationRole === "Admin" || isSuperUser) {
			data.concat([
				{
					id: 1,
					Icon: CopyLinkIcon,
					isRemove: false,
					label: "Copy Invitation Link",
					onClick: copyLinkHandler,
				},
				{
					id: 2,
					Icon: RevokeIcon,
					isRemove: true,
					label: "Revoke Invite",
					onClick: () => setShowRevokeInvite(!showRevokeInvite),
				},
			]);
		}

		return data;
	}, [copyLinkHandler, isSuperUser, organisationRole, showResendInvite, showRevokeInvite]);

	const rows = [
		"-",
		user?.email,
		user.organisation_role || "-",
		"-",
		user?.license_type?.name || "-",
	];
	const renderSteps = rows?.map((row, i: number) => (
		<div title={row} key={i} className={styles.wrapper__btn}>
			<p className={styles.wrapper__btn_invite}>{row}</p>
		</div>
	));

	useOutsideClick(ref, () => setShowSettings(false));

	return (
		<>
			<div className={styles.wrapper}>
				<div className={styles.wrapper__container}>{renderSteps}</div>

				<div
					ref={ref}
					role="button"
					className={styles.wrapper__three}
					onClick={() => setShowSettings(!showSettings)}
				>
					<ThreeDotsHorizontal className={styles.wrapper__three_dot} />

					{showSettings && (
						<OrganizationActionsDropDown
							data={modalData}
							className={styles.wrapper__three_modal}
						/>
					)}
				</div>
			</div>

			<DeleteUserModal
				submitText="Resend"
				loading={resentInvitation}
				deleteHandler={resendInviteHandler}
				isOpen={showResendInvite}
				setIsOpen={setShowResendInvite}
				titleText={`Would you like to resend invitation to “${user?.email}”?`}
			/>

			<DeleteUserModal
				submitText="Revoke"
				deleteHandler={revokeInviteHandler}
				loading={revokeInviteInProgress}
				isOpen={showRevokeInvite}
				setIsOpen={setShowRevokeInvite}
				titleText={`Would you like to revoke “${user?.email}” invitation?`}
			/>
		</>
	);
};

export default InvitedUser;
