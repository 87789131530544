import {useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "hooks";
import {
	flattenIndustriesSelector,
	industryOptionsSelector,
} from "store/mapx/search/searchSelectors";
import {getIndustries} from "store/mapx/search/searchAsyncActions";
import {SIIndustry} from "api/filterOptionsApi/IndustryApi/types";

const useIndustrySearchService = () => {
	const [loadingIndustry, setLoadingIndustry] = useState<boolean>(false);

	const dispatch = useAppDispatch();

	const industries = useAppSelector(industryOptionsSelector);

	const flattenIndustries = useAppSelector(flattenIndustriesSelector) as SIIndustry[];

	useEffect(() => {
		async function get() {
			setLoadingIndustry(true);

			await dispatch(getIndustries());

			setLoadingIndustry(false);
		}

		if (!flattenIndustries?.length) {
			get().then(() => setLoadingIndustry(false));
		}
	}, [dispatch, flattenIndustries?.length]);

	return {
		loadingIndustry,
		flattenIndustries,
		industries,
	};
};

export default useIndustrySearchService;
