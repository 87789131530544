import {useEffect} from "react";

const useLockBodyScroll = (isOpen: boolean) => {
	useEffect(() => {
		if (isOpen) {
			document.documentElement.style.overflow = "hidden";
		}

		return () => {
			document.documentElement.style.overflow = "unset";
		};
	}, [isOpen]);
};

export default useLockBodyScroll;
