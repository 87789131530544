import classNames from "classnames";

import styles from "./ModeSwitch.module.scss";
import type {TModeSwitchProps} from "./types";

const ModeSwitch = ({mode, setMode, labelOne, labelTwo}: TModeSwitchProps) => {
	return (
		<div className={styles.wrapper}>
			<input
				id="modeOne"
				type="radio"
				name="switch"
				onChange={() => setMode("modeOne")}
				checked={mode === "modeOne"}
			/>
			<input
				id="modeTwo"
				type="radio"
				name="switch"
				onChange={() => setMode("modeTwo")}
				checked={mode === "modeTwo"}
			/>
			<label htmlFor="modeOne">{labelOne}</label>
			<label htmlFor="modeTwo">{labelTwo}</label>
			<span className={classNames(styles.highlighter, styles[mode])}></span>
		</div>
	);
};

export default ModeSwitch;
