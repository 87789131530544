import type {IconProps} from "./types";

const RecycleBinPermanent = (props: IconProps) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" {...props}>
		<mask
			id="a"
			width={20}
			height={20}
			x={0}
			y={0}
			maskUnits="userSpaceOnUse"
			style={{
				maskType: "alpha",
			}}
		>
			<path fill="#D9D9D9" d="M0 0h20v20H0z" />
		</mask>
		<g mask="url(#a)">
			<path
				fill="#F20D0D"
				d="M8.333 13.23 10 11.561l1.667 1.667 1.062-1.062-1.666-1.667 1.666-1.667-1.062-1.062L10 9.438 8.333 7.77 7.271 8.833 8.938 10.5 7.27 12.167l1.062 1.062ZM6.5 17c-.412 0-.766-.147-1.06-.44A1.445 1.445 0 0 1 5 15.5v-10H4V4h4V3h4v1h4v1.5h-1v9.991c0 .423-.147.78-.44 1.072A1.45 1.45 0 0 1 13.5 17h-7Zm7-11.5h-7v10h7v-10Z"
			/>
		</g>
	</svg>
);

export default RecycleBinPermanent;
