import type {FC} from "react";
import classNames from "classnames";
import {Link} from "react-router-dom";

import type {TBtnVersion} from "./types";

import styles from "./Button.module.scss";

const ButtonWrapper: FC<TBtnVersion> = ({
	name,
	to = "",
	onClick,
	children,
	disabled,
	href = "",
	target = "",
	className = "",
	type = "button",
	loading = false,
	dataTestId = "",
	defaultBtnStyle = false,
	...rest
}) => {
	const buttonClasses = classNames({
		[className]: className,
		[styles.default]: defaultBtnStyle,
		[styles.container]: !defaultBtnStyle,
		[styles.container__disabled]: disabled || loading,
	});

	const renderContainerHandler = () => {
		if (href && !to) {
			return (
				<a
					href={href}
					type={type}
					role="button"
					target={target}
					data-testid={dataTestId}
					className={buttonClasses}
					{...rest}
				>
					{children}
				</a>
			);
		} else if (to && !href) {
			return (
				<Link
					to={to}
					role="button"
					target={target}
					data-testid={dataTestId}
					className={buttonClasses}
					type={type}
					{...rest}
				>
					{children}
				</Link>
			);
		} else {
			return (
				<button
					name={name}
					type={type}
					role="button"
					onClick={onClick}
					disabled={disabled}
					data-testid={dataTestId}
					className={buttonClasses}
					{...rest}
				>
					{children}
				</button>
			);
		}
	};

	return <>{renderContainerHandler()}</>;
};

export default ButtonWrapper;
