import React, {FC} from "react";
import classNames from "classnames";
import styles from "./styles.module.scss";
import {SkeletonLoaderProps} from "./types";
import {CompanySkeletonCard} from "assets/icons";

const CompaniesSkeletonLoader: FC<SkeletonLoaderProps> = ({count = 5}) => {
	return (
		<div
			data-testid={"companiesSkeletonLoader"}
			className={classNames(styles.skeletonContainer)}
			data-tooltip={"Loading companies..."}
		>
			{Array.from({length: count}, (_, idx) => (
				<div key={idx} className={classNames(styles.skeletonContainer__card)}>
					<CompanySkeletonCard />
				</div>
			))}
		</div>
	);
};

export default CompaniesSkeletonLoader;
