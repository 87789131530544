import {Tag} from "components";
import FilterTags from "mapx-components/FilterTags";
import React from "react";
import {TLanguageTagProps} from "../Tags/types";

function Tags({tagLabel, tags, handleTagClick, clearTags}: TLanguageTagProps) {
	return (
		<FilterTags
			onDrop={(e) => e.preventDefault()}
			label={tagLabel || ""}
			count={tags?.length}
			moveDisabled={true}
			tags={tags?.map((tag: string) => (
				<Tag key={tag} onClick={() => handleTagClick(tag)}>
					{`${tag}` || ""}
				</Tag>
			))}
			onClear={() => clearTags()}
		/>
	);
}

export default React.memo(Tags);
