import React from "react";
import {useSelector} from "react-redux";
import {SenioritySearchFilter} from "mapx-components";
import {apCandidateSenioritySelector} from "store/mapx/additional-profiles/additionalProfilesSelectors";

function SeniorityFilterForAP({setFilterParam}) {
	const selectedSeniority = useSelector(apCandidateSenioritySelector);

	const handleChange = (id) => {
		const index = selectedSeniority.indexOf(id);

		const alreadySelected = index !== -1;

		const nextIds = alreadySelected
			? [...selectedSeniority.slice(0, index), ...selectedSeniority.slice(index + 1)]
			: [...selectedSeniority, id];

		setFilterParam("current_seniority", nextIds);
	};

	const handleResetClick = () => {
		setFilterParam("current_seniority", []);
	};

	return (
		<SenioritySearchFilter
			handleResetClick={handleResetClick}
			handleOnChange={handleChange}
			selectedSeniority={selectedSeniority}
		/>
	);
}

export default SeniorityFilterForAP;
