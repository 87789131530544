import {type FC, useEffect, useMemo, useState} from "react";
import queryString from "qs";
import SlidingPane from "react-sliding-pane";
import {useHistory} from "react-router-dom";

import {CloseIcon} from "assets/icons";
import {Button, Loader} from "components";
import colors from "styles/themes.module.scss";
import {CandidateCard, EducationCard} from "mapx-components";
import {useAppDispatch, useAppSelector, useCompanyCandidateIdParam} from "hooks";
import {getSingleCandidateData} from "store/mapx/candidate/candidateAsyncActions";

import styles from "./project.module.scss";
import {CandidateSearchTypes} from "mapx-components/Cards/CandidateCard/types";
import {SICandidate, TCandidateEducation} from "api/candidateApi/types";
import {currentCandidateSelector} from "store/mapx/candidate/candidateSelectors";

const QuickViewCandidate: FC = () => {
	const history = useHistory();

	const dispatch = useAppDispatch();
	const candidateId = useCompanyCandidateIdParam();

	const currentCandidate: SICandidate = useAppSelector(currentCandidateSelector);

	const [loading, setLoading] = useState<boolean>(false);

	const type = queryString.parse(window.location.search?.substring(1))
		?.src as CandidateSearchTypes;

	useEffect(() => {
		const id = String(candidateId)?.split("?")[0];

		async function get() {
			if (id !== "undefined") {
				await dispatch(getSingleCandidateData(id));
			}
		}

		if (id && currentCandidate?.id?.toString() !== id) {
			setLoading(true);

			get().finally(() => setLoading(false));
		}

		if (candidateId) {
			document.body.style.overflow = "hidden";
		} else {
			document.body.style.overflow = "unset";
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [candidateId, currentCandidate?.id]);

	const hasEducationInfo: boolean = useMemo(() => {
		return Boolean(
			currentCandidate?.education?.some((edu: TCandidateEducation) =>
				Object.keys(edu).some(
					(key: string) => key !== "id" && edu[key as keyof typeof edu] !== null,
				),
			),
		);
	}, [currentCandidate?.education]);

	const handleClose = () => {
		if (candidateId) {
			history.goBack();
		}
	};

	const getHeights = () => (window.screen.width > 1024 ? 80 : 100);

	return (
		<SlidingPane
			width={`${getHeights()}%`}
			hideHeader
			from="right"
			shouldCloseOnEsc
			isOpen={!!candidateId}
			onRequestClose={handleClose}
			overlayClassName={styles.overlay}
			className={styles.quickViewContainer}
		>
			<Button
				defaultBtnStyle
				LeftIcon={CloseIcon}
				onClick={handleClose}
				className={styles.closeIcon}
			>
				Close
			</Button>

			<div className={styles.candidateDetailsBlock}>
				{loading && (
					<div className={styles.loaderContainer}>
						<Loader
							width={80}
							height={80}
							type="Rings"
							displayAtCenterOfPage
							color={colors.loaderDotColor}
						/>
					</div>
				)}

				{!loading && currentCandidate !== null && (
					<>
						<CandidateCard
							detailed
							fromQuickView
							isRecommended={currentCandidate.is_recommended}
							candidate={currentCandidate}
							displayDealExperience={true}
							searchType={type || "Quick View"}
						/>

						{hasEducationInfo && (
							<EducationCard educationHistory={currentCandidate?.education} />
						)}
					</>
				)}
			</div>
		</SlidingPane>
	);
};

export default QuickViewCandidate;
