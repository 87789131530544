import {type FC} from "react";
import {Tooltip} from "react-tooltip";
import styles from "mapx-components/Tooltips/AICompanyIdeasTooltip/AICompanyIdeasTooltip.module.scss";

const Index: FC = ({...props}) => {
	return (
		<Tooltip
			id={"ai_company_idea_filter_hover"}
			clickable
			place="right"
			delayShow={0}
			className={styles.wrapper}
			{...props}
		>
			<div>
				<h4 data-testid="ai-company-ideas-hover">AI Company Ideas</h4>
				<p>Matches are based on description.</p>
				<p>
					<strong>Exact:</strong> Results will contain the companies matches exactly to
					description.
				</p>
				<p>
					<strong>Suggested:</strong> Results will include companies that are closely
					related to your company description.
				</p>
			</div>
		</Tooltip>
	);
};

export default Index;
