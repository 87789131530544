import {Tag} from "components";
import FilterTags from "mapx-components/FilterTags";
import React, {DragEvent, useCallback} from "react";
import {TKeywordPosition} from "api/filterOptionsApi/KeywordApi/types";
import {TCompanyTypeTagProps} from "../Tags/types";
import {STCompanyType} from "api/companyApi/types";

function Tags({
	tagLabel,
	tagValue,
	tags,
	handleTagClick,
	clearTagsByPosition,
	moveCompanyTypeTagPosition,
}: TCompanyTypeTagProps) {
	const handleTagDragStart = useCallback(
		(e: React.DragEvent, label: string) => {
			if (tagValue) {
				e.dataTransfer.setData("id", label);
				e.dataTransfer.setData("from", tagValue);
			}
		},
		[tagValue],
	);

	const handleDrop = useCallback(
		(e: DragEvent<HTMLDivElement>) => {
			const value = e.dataTransfer.getData("id");
			const from = e.dataTransfer.getData("from") as TKeywordPosition;

			moveCompanyTypeTagPosition(value, from, tagValue);
		},
		[moveCompanyTypeTagPosition, tagValue],
	);

	return (
		<FilterTags
			onDrop={handleDrop}
			label={tagLabel || ""}
			count={tags?.length}
			moveDisabled={true}
			tags={tags?.map((tag: STCompanyType) => (
				<Tag
					key={tag.id}
					onClick={() => handleTagClick(tag.id, tagValue)}
					draggable
					onDragStart={(e) => handleTagDragStart(e, tag.id)}
					onDragOver={(e) => e.preventDefault()}
				>
					{`${tag.name}` || ""}
				</Tag>
			))}
			onClear={() => clearTagsByPosition(tagValue)}
		/>
	);
}

export default React.memo(Tags);
