import type {FC} from "react";

import {Loader} from "components";
import {BadgeArrow} from "assets/icons";

import type {TTotalsProps} from "./types";
import styles from "./ProjectPerOrganization.module.scss";

const Totals: FC<TTotalsProps> = ({title, value, badge}) => (
	<div className={styles.totals}>
		<h3 className={styles.totals__title}>{title}</h3>

		{value ? (
			<p className={styles.totals__value}>
				{value}{" "}
				{badge && (
					<span className={styles.totals__value__badge}>
						<BadgeArrow className={styles.totals__value__badge_is_bottom} /> {badge}
					</span>
				)}
			</p>
		) : (
			<Loader isCenter height={38} width={40} />
		)}
	</div>
);

export default Totals;
