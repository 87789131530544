import mixpanel from "mixpanel-browser";
import config from "config";

const hasToken =
	config?.mixPanelToken !== null &&
	config?.mixPanelToken !== "" &&
	config?.mixPanelToken !== undefined;

const token = process.env.REACT_APP_MIXPANEL_TOKEN;

const actions = {
	init: () => {
		try {
			if (hasToken) mixpanel.init(token);
		} catch (e) {
			console.log(e);
		}
	},
	identify: (id) => {
		try {
			if (hasToken) mixpanel.identify(id);
		} catch (e) {
			console.log(e);
		}
	},
	alias: (id) => {
		try {
			if (hasToken) mixpanel.alias(id);
		} catch (e) {
			console.log(e);
		}
	},
	track: (name, props) => {
		try {
			if (hasToken) mixpanel.track(name, props);
		} catch (e) {
			console.log(e);
		}
	},
	people: {
		set: (props) => {
			try {
				if (hasToken) mixpanel.people.set(props);
			} catch (e) {
				console.log(e);
			}
		},
	},
	reset: () => {
		try {
			if (hasToken) mixpanel.reset();
		} catch (e) {
			console.log(e);
		}
	},
};

export const Mixpanel = actions;
