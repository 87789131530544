import type {FC} from "react";
import ContentLoader from "react-content-loader";

const OrganisatioSettingsHeaderSkeleton: FC = (props) => (
	<div>
		<ContentLoader
			speed={2}
			width="100%"
			height={40}
			viewBox="0 0 400 40"
			backgroundColor="#DCE7E7"
			foregroundColor="#F9F9F9"
			{...props}
		>
			<rect x="0" y="0" rx="3" ry="3" width="64" height="10" />
			<rect x="0" y="16" rx="3" ry="3" width="86" height="24" />
			<rect x="90" y="16" rx="3" ry="3" width="16" height="24" />
			<rect x="122" y="0" rx="3" ry="3" width="2" height="40" />
			<rect x="138" y="0" rx="3" ry="3" width="64" height="10" />
			<rect x="228" y="16" rx="3" ry="3" width="16" height="24" />
			<rect x="138" y="16" rx="3" ry="3" width="86" height="24" />
			<rect x="288" y="0" rx="3" ry="3" width="116" height="41" />
		</ContentLoader>
	</div>
);

export default OrganisatioSettingsHeaderSkeleton;
