import {Loader} from "components";
import {SectionWrapper} from "mapx-components";
import React, {useEffect, useState} from "react";
import {Doughnut} from "react-chartjs-2";
import {useDispatch, useSelector} from "react-redux";
import {getGenderDiversityData} from "store/mapx/project/projectAsyncActions";
import styles from "./styles.module.scss";
import colors from "styles/themes.module.scss";
import NotEnoughData from "../NotEnoughData";
import {projectSelector} from "store/mapx/project-list/projectListSelectors";

export const GenderDiversityChart = () => {
	const dispatch = useDispatch();

	const project = useSelector(projectSelector);

	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (project?.id) {
			setLoading(true);
			dispatch(getGenderDiversityData(project.id, () => setLoading(false)));
		}
	}, [project?.id, dispatch]);

	const genderDiversity = useSelector((state) => state.mapxProject.insights.genderDiversity);

	return (
		<SectionWrapper label="Gender" className={styles.genderDoughnut}>
			{!loading && genderDiversity.male_percent ? (
				<>
					<Doughnut
						data={{
							labels: ["Female", "Male"],
							labelSuffix: "%",
							datasets: [
								{
									pointStyle: "rect",

									data: [
										genderDiversity.female_percent,
										genderDiversity.male_percent,
									],
									backgroundColor: ["#68B68A", "#5B9FC9"],
								},
							],
						}}
						options={{
							cutoutPercentage: 85,
							legend: {
								display: false,
							},
							responsive: true,
							maintainAspectRatio: false,

							tooltips: {
								callbacks: {
									label: (tooltipItem, data) => {
										// Get the dataset label, global label or fall back to empty label
										let label =
											(data.datasets[tooltipItem.datasetIndex].labels &&
												data.datasets[tooltipItem.datasetIndex].labels[
													tooltipItem.index
												]) ||
											data.labels[tooltipItem.index] ||
											"";
										if (label) {
											label += ": ";
										}

										label +=
											data.datasets[tooltipItem.datasetIndex].data[
												tooltipItem.index
											] + (data.labelSuffix || "");

										return label;
									},
								},
							},
						}}
						height={400}
					/>
					<div className={styles.doughnutLabels}>
						<div className={styles.doughnutLabel}>
							<span className={styles.labelText}>Male</span>
							<span className={styles.male}>{genderDiversity.male_percent}%</span>
						</div>
						<div className={styles.doughnutLabel}>
							<span className={styles.labelText}>Female</span>
							<span className={styles.female}>{genderDiversity.female_percent}%</span>
						</div>
					</div>
				</>
			) : null}
			{loading ? (
				<Loader height={30} width={30} type="ThreeDots" color={colors.loaderDotColor} />
			) : null}
			{!loading && !genderDiversity.male_percent ? (
				<NotEnoughData applySmallSize={true} />
			) : null}
		</SectionWrapper>
	);
};
