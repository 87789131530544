import React, {useMemo, useState} from "react";
import classNames from "classnames";
import styles from "./moreLikeThis.module.scss";
import {LockIcon, UserSearch} from "assets/icons";
import {GeneralButton} from "mapx-components/index";
import {TMoreLikeThisProps} from "mapx-components/Buttons/MoreLikeThisButton/types";
import {useAppDispatch, useAppSelector} from "hooks";
import {
	getAllSearchRequestsByType,
	prepareMoreLikeThisPayloadAndCreateSearchRequest,
} from "store/mapx/additional-profiles/searchRequestAsyncActions";
import {useHistory} from "react-router-dom";
import {projectSelector} from "store/mapx/project-list/projectListSelectors";
import {useSelector} from "react-redux";
import {
	allSearchRequestsSelector,
	getSearchRequestInProgressForCurrentProject,
} from "store/mapx/additional-profiles/additionalProfilesSelectors";
import {toast} from "react-toastify";
import {ToastContent} from "components";
import {userLicenseTypeNameSelector} from "store/mapx/user/userSelectors";

import type {STProject} from "api/projectApi/types";
import {TSearchRequestMiniResponse} from "api/projectApi/searchRequestApi/types";
import {saveProjectForAI} from "store/mapx/project-list/projectWorkflow";

const MoreLikeThisButton = ({className, candidate}: TMoreLikeThisProps) => {
	const [loading, setLoading] = useState(false);

	const dispatch = useAppDispatch();

	const project = useAppSelector(projectSelector);

	const allSearchRequest: TSearchRequestMiniResponse[] =
		useAppSelector(allSearchRequestsSelector);

	const userLicenseTypeName = useAppSelector(userLicenseTypeNameSelector);

	const history = useHistory();

	const searchRequestInProgress = useSelector((state) =>
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		getSearchRequestInProgressForCurrentProject(state, project ? project.id : null),
	);

	const limitAccess = useMemo(
		() => userLicenseTypeName === "Starter" && allSearchRequest?.length >= 2,
		[allSearchRequest?.length, userLicenseTypeName],
	);

	const moreLikeThisIcon = useMemo(() => {
		if (limitAccess) {
			return <LockIcon />;
		} else {
			return <UserSearch />;
		}
	}, [limitAccess]);

	const handleButtonClick = async () => {
		if (!limitAccess) {
			const candidateName = candidate.full_name ?? candidate.name;

			const title = candidateName
				? `You were about to search for more like ${candidate.full_name ?? candidate.name}`
				: "A search request is in progress";

			if (searchRequestInProgress) {
				toast.info(ToastContent, {
					autoClose: 5000,
					closeOnClick: true,
					data: {
						title: title,
						description: `A search request is in process for the currently selected project. You can start another search after the current one is finished.`,
					},
				});
			} else {
				setLoading(true);

				if (!project) {
					const projectName = `More like ${
						candidate.full_name ?? candidate.name
					} Project`;

					const newProject: Nullable<STProject> = await dispatch(
						saveProjectForAI({name: projectName}),
					);

					if (newProject) {
						await dispatch(prepareMoreLikeThisPayloadAndCreateSearchRequest(candidate));
						setLoading(false);
						history.push(`/candidates-profile-search`);
					}
				} else {
					if (!allSearchRequest?.length) {
						dispatch(getAllSearchRequestsByType(project.id, "More Like This"));
					}

					await dispatch(prepareMoreLikeThisPayloadAndCreateSearchRequest(candidate));
					setLoading(false);
					history.push(`/candidates-profile-search`);
				}
			}
		}
	};

	return (
		<GeneralButton
			customClass={classNames(styles.moreLikeThis, !!className && className, {
				[styles.locked]: limitAccess,
			})}
			icon={moreLikeThisIcon}
			loadingSave={loading}
			isClicked={false}
			handleClick={handleButtonClick}
			title={"More Like This"}
			showUpgradeTooltip={limitAccess}
			tooltipIdentifier={`upgradeToPlusTooltip${candidate.id}`}
			tooltipType={"more_like_this_limit"}
		/>
	);
};

export default MoreLikeThisButton;
