import React, {useMemo} from "react";
import {format, isValid, parse} from "date-fns";
import css from "./educationCard.module.scss";

export default function EducationCard({educationHistory}) {
	const formatDate = (date) => {
		const parsedDate = parse(date, "yyyy-MM-dd", new Date());
		const isValidDate = isValid(parsedDate);

		if (isValidDate) {
			return format(parsedDate, "yyyy");
		} else {
			return "?";
		}
	};

	const setDateRange = (startDate, endDate) => {
		if (startDate !== null && endDate === null) {
			return `${formatDate(startDate)} - Current`;
		} else if (startDate !== null && endDate !== null) {
			return `${formatDate(startDate)} - ${formatDate(endDate)}`;
		} else {
			return "";
		}
	};

	const degreeInfo = (edu) => {
		let desc = "";

		if (edu.qualification) {
			desc += edu.qualification;
		}

		if (edu.subject) {
			if (edu.qualification) {
				desc += ", ";
			}

			desc += edu.subject;
		}

		return desc;
	};

	const filteredEducationHistory = useMemo(
		() =>
			educationHistory.filter(
				(edu) => edu?.institution || edu?.qualification || edu?.subject,
			),
		[educationHistory],
	);

	return (
		<div className={css.educationCard}>
			<div className={css.title}>Education</div>
			<div className={css.educationList}>
				{filteredEducationHistory.map((edu, i) => {
					return (
						<div key={i} className={css.education}>
							{edu?.institution !== null && (
								<div className={css.instituteTitle}>
									{edu.institution.linkedin_url ? (
										<a
											href={edu.institution.linkedin_url}
											className={css.institutionLink}
											target="_blank"
											rel="noreferrer"
										>
											{edu.institution.name}
										</a>
									) : (
										edu.institution.name
									)}
								</div>
							)}

							{(edu?.qualification || edu?.subject) && (
								<div
									className={css.info}
									style={{fontWeight: edu?.institution ? 400 : 600}}
								>
									{degreeInfo(edu)}
								</div>
							)}

							{edu.start_date && edu.end_date && (
								<div className={css.info}>
									{setDateRange(edu.start_date, edu.end_date)}
								</div>
							)}
						</div>
					);
				})}
			</div>
		</div>
	);
}
