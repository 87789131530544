import React from "react";
import classNames from "classnames";
import IconError from "assets/icons/IconError16";
import css from "./searchInput.module.scss";
import {BiLinkAlt, BiSearch} from "react-icons/bi";
import {Loader} from "components";
import colors from "styles/themes.module.scss";
import {TSearchInputProps} from "mapx-components/Inputs/SearchInput/types";

const SearchInput = ({
	label,
	customClass,
	isLoading = false,
	loaderColor = colors.loaderDotColor,
	errorText = "",
	errorClass,
	isLinkIcon = false,
	margin = "16px 0",
	disableFocus = false,
	disabled = false,
	className,
	...restProps
}: TSearchInputProps) => {
	const InlineLoaderComponent = (
		<Loader
			displayAtCenterOfPage={false}
			type={"ThreeDots"}
			height={20}
			width={20}
			color={loaderColor}
		/>
	);

	return (
		<div
			className={classNames(
				css.inputContainer,
				{[css.invalid]: errorText},
				customClass && customClass,
				errorText !== undefined && errorText !== "" ? "invalid-input" : "valid-input",
			)}
			style={{margin}}
		>
			{label !== undefined && label !== "" && (
				<label htmlFor="search-input" className={css.label}>
					{label}
				</label>
			)}

			<div className={css.inputWrapper}>
				{isLinkIcon ? (
					<BiLinkAlt size={24} className={css.searchIcon} color={"#808080"} />
				) : (
					<BiSearch size={24} className={css.searchIcon} color="#808080" />
				)}
				<input
					{...restProps}
					className={classNames(css.searchInput, className && className, {
						[css.disableFocus]: disableFocus,
					})}
					id="search-input"
					data-testid="search-input"
					disabled={!!disabled}
				/>

				{isLoading && (
					<span data-testid="inline-loader" className={css.loader}>
						{InlineLoaderComponent}
					</span>
				)}

				<span className={css.iconError}>
					<IconError />
				</span>
			</div>

			{errorText !== "" && (
				<span data-testid="error-test-id" className={classNames(css.errorText, errorClass)}>
					{errorText}
				</span>
			)}
		</div>
	);
};

export default SearchInput;
