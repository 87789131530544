import {
	useRef,
	Fragment,
	useState,
	useEffect,
	useCallback,
	type SyntheticEvent,
	type BaseSyntheticEvent,
	useMemo,
} from "react";
import {useSelector} from "react-redux";
import {noop} from "lodash";
import classNames from "classnames";
import {differenceInDays} from "date-fns";

import {
	ChangePassword,
	RemoveUserIcon,
	ProjectEditIcon,
	TransferOwnership,
	ThreeDotsHorizontal,
} from "assets/icons";
import {
	EditUserModal,
	DeleteUserModal,
	ResetPasswordModal,
	TransferOwnershipModal,
	OrganizationActionsDropDown,
} from "mapx-components";
import {
	userSelector,
	iseEditedUserSelector,
	resetPassProgressSelector,
	deleteUserInProgressSelector,
	userTransferProgressSelector,
	userOrganisationSelector,
	userOrganisationRoleSelector,
} from "store/mapx/user/userSelectors";
import {
	deleteUser,
	userUpdate,
	verifyEmailAccount,
	usersTransferOwnership,
} from "store/mapx/user/userAsyncAction";
import {orgUsersListSelector} from "store/mapx/organisation/organisationSelectors";
import {useAppDispatch, useAppSelector, useOutsideClick} from "hooks";
import {companyLicenseTypesSelector} from "store/mapx/company/companySelectors";
import {getProjectList} from "store/mapx/project-list/projectListAsyncActions";
import type {TLicenseType} from "mapx-components/Modals/CreateUserModal/types";
import type {TSelectWithActionOptions} from "components/Inputs/SelectWithAction/types";

import styles from "./User.module.scss";
import UserPlans from "./UserPlans";
import type {IUserProps} from "../../../types";

const User = ({user, closeDropdownHandler = noop, usersCount = 0}: IUserProps) => {
	const ref = useRef<HTMLDivElement | null>(null);
	const dispatch = useAppDispatch();

	const users = useSelector(orgUsersListSelector);
	const userInfo = useAppSelector(userSelector);

	const iseEditedUser = useAppSelector(iseEditedUserSelector);

	const organisation = useAppSelector(userOrganisationSelector);
	const organisationRole = useAppSelector(userOrganisationRoleSelector);
	const licenseTypes = useAppSelector(companyLicenseTypesSelector);

	const resetPassInProgressSelector = useAppSelector(resetPassProgressSelector);
	const removeUserInProgressSelector = useAppSelector(deleteUserInProgressSelector);
	const userTransferInProgressSelector = useAppSelector(userTransferProgressSelector);

	// const [stepList, setStepList] = useState(propsStepList);

	const transformLicenseTypeData = useMemo(
		() =>
			licenseTypes.map((lt: TLicenseType) => {
				return {
					label: lt?.name,
					value: lt?.id,
				};
			}),
		[licenseTypes],
	);

	const freeTrialDefaultValue = user?.license?.expires_at
		? differenceInDays(new Date(user?.license?.expires_at), new Date(user?.license?.starts_at))
		: 7;

	const moreThanOneUsers = usersCount !== 1;

	const rows = [
		`${user?.first_name} ${user?.last_name}`,
		user?.email,
		user?.organisation_role,
		user?.joined_at,
		user?.license?.type?.name,
	];

	const [emailError, setEmailError] = useState<boolean>(true);
	const [emailVal, setEmailVal] = useState<string>(user?.email);
	const [modalState, setModalState] = useState({
		showSettings: false,
		showEditUser: false,
		showResetPass: false,
		ownershipModal: false,
		removeUser: false,
		showUserPlans: false,
	});
	const [selectedUser, setSelectedUser] = useState<TSelectWithActionOptions>({
		value: null,
		label: null,
	});
	const [, setLicenseType] = useState<TSelectWithActionOptions>({
		value: user?.license?.type?.id,
		label: user?.license?.type?.name,
	});
	const [selectedOrg, setSelectedOrg] = useState<TSelectWithActionOptions>({
		value: organisation?.id,
		label: organisation?.name,
	});

	const wrongTransfer = user?.id !== selectedUser?.value;

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const transferTo = users.find((user: any) => user?.id === selectedUser?.value);

	const onToggle = useCallback(
		(key: keyof typeof modalState, value: boolean) => {
			if (value) {
				closeDropdownHandler();
			}
			setModalState((prevState) => ({
				...prevState,
				[key]: value,
			}));

			if (!modalState.showEditUser) {
				setEmailVal(user.email);
				setEmailError(true);
			}
			if (!modalState.ownershipModal) {
				setSelectedUser({value: null, label: null});
			}
			if (!modalState.showEditUser) {
				setSelectedOrg({
					value: organisation?.id,
					label: organisation?.name,
				});
				setLicenseType({
					value: user?.license?.type?.id,
					label: user?.license?.type?.name,
				});
			}
		},
		[
			organisation,
			user,
			closeDropdownHandler,
			modalState.showEditUser,
			modalState.ownershipModal,
		],
	);

	useEffect(() => {
		if (emailVal) {
			const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
			setEmailError(pattern.test(emailVal));
		}
	}, [emailVal]);

	const resetPassHandler = async () => {
		await dispatch(
			verifyEmailAccount({
				email: user?.email,
			}),
		);
	};

	const emailOnChange = (e: BaseSyntheticEvent) => {
		setEmailVal(e.target.value);
	};

	const removeUserInCompanyHandler = async () => {
		await dispatch(deleteUser(user?.id, organisation.id));
	};

	const editSubmitHandler = useCallback(
		(e: SyntheticEvent) => {
			e.preventDefault();

			dispatch(
				userUpdate(
					user?.id,
					{
						email: emailVal,
						last_name: user?.last_name,
						job_title: user?.job_title,
						first_name: user?.first_name,
					},
					organisation,
				),
			);
		},
		[dispatch, emailVal, organisation, user],
	);

	const submitTransferHandler = (e: SyntheticEvent) => {
		e.preventDefault();

		if (wrongTransfer) {
			if (moreThanOneUsers) {
				dispatch(
					usersTransferOwnership({
						user_from: user?.id,
						user_to: selectedUser?.value,
					}),
				);
			}

			if (user?.id === userInfo?.user_id || transferTo?.id === userInfo?.user_id) {
				dispatch(getProjectList());
			}
		}
	};

	const modalData = useMemo(() => {
		const options = [
			{
				id: 0,
				isRemove: false,
				label: "Edit User",
				Icon: ProjectEditIcon,
				isDisabled: false,
				onClick: () => onToggle("showEditUser", true),
			},
			{
				id: 1,
				isRemove: false,
				Icon: ChangePassword,
				isDisabled: false,
				label: "Reset Password",
				onClick: () => onToggle("showResetPass", true),
			},
		];

		if (user.organisation_role === "Admin") {
			options.push({
				id: 2,
				isRemove: false,
				Icon: TransferOwnership,
				isDisabled: !moreThanOneUsers,
				label: "Transfer Ownership",
				onClick: () => onToggle("ownershipModal", true),
			});
		}

		if (user.organisation_role !== "Admin") {
			options.push({
				id: 3,
				isRemove: true,
				Icon: RemoveUserIcon,
				isDisabled: false,
				label: "Remove User",
				onClick: () => onToggle("removeUser", true),
			});
		}

		return options;
	}, [moreThanOneUsers, onToggle, user]);

	useOutsideClick(ref, () => onToggle("showSettings", false));

	return (
		<Fragment>
			<div className={styles.wrapper}>
				<div className={styles.wrapper__container}>
					{rows.map((row, i) => {
						const variantValue = () => {
							switch (row) {
								case user?.license:
									return user?.license?.type.name || "-";

								case user?.end_date:
									return user?.end_date || " ";

								default:
									return `${row}`;
							}
						};

						return i === 4 ? (
							<div key={i} className={styles.plansDropdown}>
								<UserPlans user={user} />
							</div>
						) : (
							<div
								key={i}
								title={`${variantValue()}`}
								className={classNames(styles.wrapper__btn, {
									[styles.wrapper__btn_left]: i <= 4,
									[styles.wrapper__btn_right]: i === 5,
								})}
							>
								<p
									className={classNames(styles.wrapper__btn_text, {
										[styles.wrapper__btn_text_cut]: user?.email,
									})}
								>
									{variantValue()}
								</p>
							</div>
						);
					})}
				</div>

				<div
					ref={ref}
					role="button"
					className={classNames(styles.wrapper__three, {
						[styles.hidden]: organisationRole !== "Admin",
					})}
					onClick={() => onToggle("showSettings", !modalState.showSettings)}
				>
					<ThreeDotsHorizontal className={styles.wrapper__three_dot} />

					{modalState.showSettings && (
						<OrganizationActionsDropDown
							data={modalData}
							className={styles.wrapper__three_modal}
						/>
					)}
				</div>
			</div>

			<EditUserModal
				email={emailVal}
				emailError={emailError}
				loading={iseEditedUser}
				selectedOrg={selectedOrg}
				onSubmit={editSubmitHandler}
				organisations={[organisation]}
				emailOnChange={emailOnChange}
				setSelectedOrg={setSelectedOrg}
				setLicenseType={setLicenseType}
				isOpen={modalState.showEditUser}
				license={transformLicenseTypeData}
				freeTrialDefaultValue={freeTrialDefaultValue}
				setIsOpen={(bool) => onToggle("showEditUser", bool)}
				selectedLicenseType={{
					value: user?.license?.type?.id,
					label: user?.license?.type?.name,
				}}
			/>

			<ResetPasswordModal
				email={emailVal}
				isOpen={modalState.showResetPass}
				resetPassHandler={resetPassHandler}
				loading={resetPassInProgressSelector}
				setIsOpen={(bool) => onToggle("showResetPass", bool)}
			/>

			{user.organisation_role === "Admin" && (
				<TransferOwnershipModal
					options={users}
					disabled={!wrongTransfer}
					selectedUser={selectedUser}
					onSubmit={submitTransferHandler}
					setSelectedUser={setSelectedUser}
					transferName={`${user?.first_name} ${user?.last_name}`}
					isOpen={modalState.ownershipModal}
					loading={userTransferInProgressSelector}
					setIsOpen={(bool) => onToggle("ownershipModal", bool)}
				/>
			)}

			<DeleteUserModal
				submitText="Delete"
				isOpen={modalState.removeUser}
				loading={removeUserInProgressSelector}
				deleteHandler={removeUserInCompanyHandler}
				setIsOpen={(bool) => onToggle("removeUser", bool)}
				titleText={`Would you like to delete ”${`${user?.first_name} ${user?.last_name}`}”?`}
			/>
		</Fragment>
	);
};

export default User;
