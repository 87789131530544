import {useCallback, useEffect, useState} from "react";
import {useHistory, useLocation} from "react-router-dom";
import {useSelector} from "react-redux";

import colors from "styles/themes.module.scss";
import {PricingSkeletonCard} from "assets/icons";
import {useAppDispatch, useLockBodyScroll, useModalHook} from "hooks";
import {ModalContent, ModalHeader, ModalSeparator, ModeSwitch, PricingCard} from "components";
import {
	openCheckoutInProgressSelector,
	pricingTableModalSelector,
	productsInProgressSelector,
	productsSelector,
} from "store/mapx/payment/paymentSelectors";
import {pricingTableModal} from "store/mapx/payment/paymentActions";
import {getProducts, openCheckout} from "store/mapx/payment/paymentAsyncActions";
import type {TModeSwitchMode} from "components/Inputs/ModeSwitch/types";

import styles from "./PricingTableModal.module.scss";
import type {TPricingPlans} from "./types";
import {noop} from "lodash";
import {userSelector} from "store/mapx/user/userSelectors";

const pricingPlans: TPricingPlans = {
	modeOne: {
		Starter: {
			buttonDisabled: true,
			buttonText: "Your current plan",
			description: "Intelligent candidate sourcing.",
			features: ["AI profile searching", "Share projects"],
			price: "Free",
			title: "Starter",
		},
		Plus: {
			title: "Plus",
			description: "Fast and intelligent sourcing without limits.",
			unit: ["per seat", "per month"],
			buttonText: "Upgrade to Plus",
			freeTrialDays: 7,
			features: [
				"Everything in Starter",
				"Larger search radius",
				"Filter your results",
				"Download results",
				"Talent pool insights",
			],
		},
		Pro: {
			title: "Pro",
			description: "For high performing Exec TA teams and Search professionals.",
			unit: ["per seat", "per month"],
			buttonText: "Contact Sales",
			features: [
				"Everything in Plus license",
				"250k Companies searchable",
				"CompanyDetails org charts",
				"Private equity database",
				"More diversity metrics",
				"CompanyDetails talent maps",
				"Custom market maps",
				"Deep web searching",
			],
		},
	},
	modeTwo: {
		Starter: {
			buttonDisabled: true,
			buttonText: "Your current plan",
			description: "Intelligent candidate sourcing.",
			features: ["AI profile searching", "Share projects"],
			price: "Free",
			title: "Starter",
		},
		Plus: {
			title: "Plus",
			description: "Fast and intelligent sourcing without limits.",
			unit: ["per seat", "per month"],
			buttonText: "Upgrade to Plus",
			freeTrialDays: 7,
			features: [
				"Everything in Starter",
				"Larger search radius",
				"Filter your results",
				"Download results",
				"Talent pool insights",
			],
		},
		Pro: {
			title: "Pro",
			description: "For high performing Exec TA teams and Search professionals.",
			unit: ["per seat", "per month"],
			buttonText: "Contact Sales",
			features: [
				"Everything in Plus license",
				"250k Companies searchable",
				"CompanyDetails org charts",
				"Private equity database",
				"More diversity metrics",
				"CompanyDetails talent maps",
				"Custom market maps",
				"Deep web searching",
			],
		},
	},
};

const PricingTableModal = () => {
	const dispatch = useAppDispatch();

	const history = useHistory();

	const location = useLocation();

	const [mode, setMode] = useState<TModeSwitchMode>("modeOne");

	const modalDisplay = useSelector(pricingTableModalSelector);

	const products = useSelector(productsSelector);

	const loading = useSelector(productsInProgressSelector);

	const openingCheckout = useSelector(openCheckoutInProgressSelector);

	const user = useSelector(userSelector);

	const {modalIsOpen, customStyles, Modal} = useModalHook(
		{content: {width: "90%", borderRadius: "8px", maxWidth: "866px"}},
		modalDisplay,
		colors.mainThemeColor,
	);

	const handleClose = useCallback(() => {
		dispatch(pricingTableModal(false));

		if (location.pathname === "/organisation-settings/pricing") {
			history.push("/organisation-settings");
		}
	}, [dispatch, history, location.pathname]);

	const handleButtonClick = useCallback(
		(id: string) => {
			dispatch(openCheckout(id));
		},
		[dispatch],
	);

	useEffect(() => {
		dispatch(getProducts());
	}, [dispatch]);

	useLockBodyScroll(modalIsOpen);

	return (
		<Modal
			title="Upgrade MapX"
			style={customStyles}
			isOpen={modalIsOpen}
			contentLabel="Modal"
			onRequestClose={handleClose}
		>
			<ModalHeader padding="24px 24px 16px 24px" title="Upgrade MapX" onClose={handleClose} />
			<ModalSeparator />
			<ModalContent padding="24px" className={styles.content}>
				<ModeSwitch mode={mode} setMode={setMode} labelOne="Monthly" labelTwo="Yearly" />
				<div className={styles.cards}>
					{products.length > 0 || !loading ? (
						<>
							<PricingCard
								key={pricingPlans[mode].Starter.title}
								title={pricingPlans[mode].Starter.title}
								description={pricingPlans[mode].Starter.description}
								price={pricingPlans[mode].Starter.price || "Free"}
								unit={pricingPlans[mode].Starter.unit}
								buttonText={pricingPlans[mode].Starter.buttonText}
								buttonAction={noop}
								buttonDisabled={pricingPlans[mode].Starter.buttonDisabled}
								freeTrialDays={pricingPlans[mode].Starter.freeTrialDays}
								features={pricingPlans[mode].Starter.features}
								regularPrice={pricingPlans[mode].Starter.regularPrice}
							/>
							{/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
							{products.map((p: any) => {
								if (!p.metadata?.license_type) return null;

								const plan = pricingPlans[mode][p.metadata.license_type];
								const interval = mode === "modeOne" ? "month" : "year";
								const price = p.prices.find(
									// eslint-disable-next-line @typescript-eslint/no-explicit-any
									(price: any) => price.recurring.interval === interval,
								);

								let regularPrice = 0;
								if (mode === "modeOne") {
									regularPrice = price.unit_amount / 100;
								} else {
									const monthlyPrice = p.prices.find(
										// eslint-disable-next-line @typescript-eslint/no-explicit-any
										(price: any) => price.recurring.interval === "month",
									);
									regularPrice = monthlyPrice.unit_amount / 100;
								}

								const action =
									p.name === "Pro"
										? () =>
												window.open(
													`https://share-eu1.hsforms.com/1E6nq5jD6Qyei811lydzk4wfkdks?email=${user.email}&firstname=${user.firstName}&lastname=${user.lastName}&company=${user.organisation.name}`,
													"_blank",
												)
										: () => handleButtonClick(price.id);

								if (!plan) return null;

								return (
									<PricingCard
										key={p.id}
										title={plan.title}
										description={plan.description}
										price={
											mode === "modeOne"
												? price.unit_amount / 100
												: price.unit_amount / 12 / 100
										}
										unit={plan.unit}
										buttonText={plan.buttonText}
										buttonAction={action}
										buttonSecondary={p.name === "Pro"}
										buttonDisabled={plan.buttonDisabled}
										freeTrialDays={plan.freeTrialDays}
										features={plan.features}
										regularPrice={regularPrice}
										loading={openingCheckout === price.id}
									/>
								);
							})}
						</>
					) : (
						<>
							<PricingSkeletonCard />
							<PricingSkeletonCard />
							<PricingSkeletonCard />
						</>
					)}
				</div>
			</ModalContent>
		</Modal>
	);
};

export default PricingTableModal;
