import type {FC} from "react";

import type {IconProps} from "./types";

const XAnimationIcon: FC<IconProps> = ({className = ""}) => (
	<svg
		width="96"
		fill="none"
		height="86"
		viewBox="0 0 96 86"
		className={className}
		xmlns="http://www.w3.org/2000/svg"
	>
		<g clipPath="url(#clip0_3_44975)">
			<path
				d="M6.03722 84.9357L31.8015 52.9443L36.9523 56.7086L20.0876 77.8762H28.5199L41.1684 62.819L54.2842 77.8762H80.0485L32.7255 19.0654H42.5597L95.9681 84.925H50.0468L41.1472 75.0503L32.7149 84.925H6.00535L6.03722 84.9357ZM52.893 66.1141H62.7272L14.9475 7.78315H41.657L53.8382 23.7788L67.8992 7.78315H75.8643L57.5871 29.42L62.7378 34.5919L91.3165 0.254517H64.1397L53.8276 12.0167L45.8625 0.254517H0.419189L52.893 66.1141Z"
				fill="#0C5850"
			/>
		</g>
		<defs>
			<clipPath id="clip0_3_44975">
				<rect width="96" height="85.5273" fill="white" transform="translate(0 0.254517)" />
			</clipPath>
		</defs>
	</svg>
);

export default XAnimationIcon;
