import type {FC} from "react";
import classNames from "classnames";

import {Error, Success} from "assets/icons";

import type {TMessageProps} from "./types";

import styles from "./Message.module.scss";

const Message: FC<TMessageProps> = ({className, level = "success", message}) =>
	message ? (
		<div className={classNames(styles.message, className, styles[`__${level}`])}>
			{level === "success" && <Success data-testid="success-icon" />}
			{level === "error" && <Error data-testid="error-icon" />}
			{message}
		</div>
	) : null;

export default Message;
