import React, {useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import config from "config";
import HttpClient from "api";
import {Loader} from "components";
import colors from "styles/themes.module.scss";
import {NavbarV2} from "mapx-components";
import {pages as errorPages} from "mapx-pages/Error";
import {sendTrackedEventsData} from "store/mapx/events/eventTrackingAsyncActions";
import {logoutUser} from "store/mapx/user/userAsyncAction";
import {Mixpanel} from "helpers/mixpanel";

function forwardToLogin() {
	let redirect = "/login";

	const pathname = window.location.pathname;
	if (pathname && !errorPages[pathname]) {
		redirect += `?redirectTo=${pathname}`;
	}

	const savedUser = window.localStorage.getItem("user");
	const user = savedUser ? JSON.parse(savedUser) : null;

	// SET MIXPANEL LOGOUT EVENT
	if (user && user?.email) {
		Mixpanel.track("logged out", {distinct_id: user.email});
		Mixpanel.reset();
	}

	window.localStorage.clear();
	window.location = redirect;
}

export function Layout({children, isAuthenticated}) {
	const dispatch = useDispatch();

	const tracked_events = useSelector((state) => state.events.tracked_events);

	const onLogoutUser = useCallback(async () => {
		if (tracked_events?.length) {
			await dispatch(sendTrackedEventsData(tracked_events));
		}

		dispatch(logoutUser());
	}, [tracked_events, dispatch]);

	return (
		<>
			{isAuthenticated && (
				<header>
					<NavbarV2 logoutAdmin={onLogoutUser} />
				</header>
			)}
			<main>{children}</main>
		</>
	);
}

let alreadyRefreshOnProcess = false;

const eventEmitter = new EventTarget();
const refreshCustomEvent = new CustomEvent("refreshCustomEvent");

function withAuth(Component) {
	return class AuthComponent extends React.Component {
		constructor(props) {
			super(props);
			this.controller = new AbortController();
			this.state = {render: false};
		}

		componentDidMount() {
			const token = window.localStorage.getItem("token");
			const refreshToken = window.localStorage.getItem("refresh_token");
			const savedUser = window.localStorage.getItem("user");
			const user = savedUser ? JSON.parse(savedUser) : null;

			if (token) {
				const expiredTime = localStorage.getItem("expires_in")
					? parseInt(localStorage.getItem("expires_in"), 10)
					: null;

				if (expiredTime && expiredTime <= new Date().getTime()) {
					if (alreadyRefreshOnProcess) {
						eventEmitter.addEventListener("refreshCustomEvent", this.handleCustomEvent);
					} else {
						alreadyRefreshOnProcess = true;

						const client = axios.create({
							baseURL: config.apiUrl,
							headers: {
								Accept: "application/json",
								"Content-Type": "application/json",
							},
						});

						client
							.post(
								"/auth/refresh",
								{refresh_token: refreshToken},
								{signal: this.controller.signal},
							)
							.then(async (response) => {
								if (response.status === 200) {
									// SET MIXPANEL NEW LOGIN
									if (user && user?.email) {
										Mixpanel.identify(user.email);
										Mixpanel.track("logged in", {distinct_id: user.email});
										Mixpanel.people.set({
											$first_name: user?.firstName,
											$last_name: user?.lastName,
											$email: user.email,
											organisation: user?.organisation?.name,
											distinct_id: user.email,
										});
									}

									window.localStorage.setItem(
										"token",
										"Bearer " + response.data.access_token,
									);

									window.localStorage.setItem(
										"refresh_token",
										response.data.refresh_token,
									);

									window.localStorage.setItem(
										"expires_in",
										new Date().getTime() + 1000 * response.data.expires_in,
									);

									await HttpClient.resetConstructor();

									this.setState({render: true});
									eventEmitter.dispatchEvent(refreshCustomEvent);
								} else {
									forwardToLogin();
								}
							})
							.catch(function (thrown) {
								if (!axios.isCancel(thrown)) {
									// Not a cancel but error
									forwardToLogin();
								}
							})
							.finally(() => {
								alreadyRefreshOnProcess = false;
							});
					}
				} else {
					this.setState({render: true});
				}
			} else {
				forwardToLogin();
			}
		}

		componentWillUnmount() {
			this.controller.abort();
			eventEmitter.removeEventListener("refreshCustomEvent", this.handleCustomEvent);
		}

		handleCustomEvent = () => {
			this.setState({render: true});
			eventEmitter.removeEventListener("refreshCustomEvent", this.handleCustomEvent);
		};

		render() {
			const {render} = this.state;

			if (!render) {
				return (
					<Loader
						displayAtCenterOfPage={true}
						type={"Rings"}
						height={80}
						width={80}
						color={colors.loaderDotColor}
					/>
				);
			}

			return <Component {...this.props} />;
		}
	};
}

export default withAuth;
