import type {FC} from "react";

import type {IconProps} from "./types";
import React from "react";

const TwoWaySortIcon: FC<IconProps> = ({className = ""}) => (
	<svg
		width="20"
		height="20"
		viewBox="0 0 20 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		className={className}
	>
		<mask
			id="mask0_137_6531"
			style={{maskType: "alpha"}}
			maskUnits="userSpaceOnUse"
			x="0"
			y="0"
			width="20"
			height="20"
		>
			<rect width="20" height="20" fill="#D9D9D9" />
		</mask>
		<g mask="url(#mask0_137_6531)">
			<path
				d="M10 3L7.11325 8L12.8868 8L10 3ZM10 17L12.8868 12L7.11325 12L10 17ZM9.5 7.5L9.5 12.5L10.5 12.5L10.5 7.5L9.5 7.5Z"
				fill="#808080"
			/>
		</g>
	</svg>
);

export default TwoWaySortIcon;
