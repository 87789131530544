import type {FC} from "react";
import React from "react";
import ContentLoader from "react-content-loader";

const Index: FC = (props) => (
	<ContentLoader
		speed={2}
		height={80}
		width={"100%"}
		backgroundColor="#EEEDEC"
		foregroundColor="#e6e6e6"
		{...props}
	>
		<circle cx="32.2" cy="43.2" r="20.3" />
		<rect x="62.9" y="34.5" width="50%" height="13" />
		<rect x="145.9" y="65.7" width="5%" height="8" />
	</ContentLoader>
);

export default Index;
