import {compareValues} from "helpers/array";
import {
	CREATE_INVITATION_DONE,
	CREATE_INVITATION_FAIL,
	CREATE_INVITATION_INIT,
	CREATE_ORGANISATION_DONE,
	CREATE_ORGANISATION_FAIL,
	CREATE_ORGANISATION_INIT,
	GET_ORGANISATIONS_DONE,
	GET_ORGANISATIONS_FAIL,
	GET_ORGANISATIONS_INIT,
	RESEND_INVITATION_DONE,
	RESEND_INVITATION_FAIL,
	RESEND_INVITATION_INIT,
	REVOKE_INVITATION_DONE,
	REVOKE_INVITATION_FAIL,
	REVOKE_INVITATION_INIT,
	REVOKE_USER_DONE,
	REVOKE_USER_FAIL,
	REVOKE_USER_INIT,
	SET_NEW_ORGANISATION_NAME,
	SORT_CURRENT_USERS,
	SORT_INVITED_USERS,
	VERIFY_INVITATION_DONE,
	VERIFY_INVITATION_FAIL,
	VERIFY_INVITATION_INIT,
	GET_ORGANIZATION_LIST_DONE,
	GET_ORGANIZATION_LIST_FAIL,
	GET_ORGANIZATION_LIST_INIT,
	GET_ORGANISATION_USERS_INIT,
	GET_ORGANISATION_USERS_FAIL,
	GET_ORGANISATION_USERS_DONE,
	UPDATE_ORGANIZATION_INIT,
	UPDATE_ORGANIZATION_DONE,
	UPDATE_ORGANIZATION_FAIL,
	DELETE_ORGANIZATION_INIT,
	DELETE_ORGANIZATION_DONE,
	DELETE_ORGANIZATION_FAIL,
	UPDATE_CREDITS_USED_KEY,
	GET_INVITATIONS_INIT,
	GET_INVITATIONS_DONE,
	GET_INVITATIONS_FAIL,
	SET_ORGANIZATION_LIST_ORDERING,
	GET_ORGANIZATION_TABLE_DATA_INIT,
	GET_ORGANIZATION_TABLE_DATA_DONE,
	GET_ORGANIZATION_TABLE_DATA_FAIL,
	USERS_UPDATE_DONE,
	GET_SEATS_INIT,
	GET_SEATS_DONE,
	GET_SEATS_FAIL,
} from "../mapXActionTypes";

export const orgInitialState = {
	organisations: [],
	invitedUsers: [],
	currentUsers: [],
	organizationsList: [],
	organizationsListOrdering: [],
	organizationTableData: [],
	organizationTableDataInProgress: false,
	organizationsListProgress: false,
	orgUsersList: [],
	orgUsersListProgress: false,
	creditsUsedKey: "all_time",
	pagination: {
		usersPagination: null,
		organisationsPagination: null,
	},
	organisationsInProgress: false,
	revokingUserInProgress: [],
	currentUsersOrderMap: {},
	invitedUsersOrderMap: {},
	revokeInviteInProgress: false,
	resendUserInvitationInProgress: false,
	invitingUserInProgress: [],
	revokingInvitationInProgress: [],
	createOrganisationInProgress: false,
	createInvitationInProgress: false,
	newOrganisationName: "",
	inviteCodeInProgress: false,
	inviteCode: null,
	deleteOrgProgress: false,
	updateOrganizationsInProgress: false,
	createInvitationSuccess: false,
	getInvitedUsersInProgress: false,
	seatsInProgress: false,
	seats: {
		cost: {
			monthly: 0,
			yearly: 0,
		},
		seats: [],
	},
};

const organisationReducer = (state = orgInitialState, action) => {
	switch (action.type) {
		case GET_SEATS_INIT:
			return {
				...state,
				seatsInProgress: true,
			};
		case GET_SEATS_DONE: {
			return {
				...state,
				seatsInProgress: false,
				seats: action.payload,
			};
		}
		case GET_SEATS_FAIL:
			return {
				...state,
				seatsInProgress: false,
			};
		case USERS_UPDATE_DONE: {
			const {data} = action;

			return {
				...state,
				orgUsersList: state.orgUsersList.map((user) =>
					user.id === data.id
						? {
								...user,
								...data,
						  }
						: user,
				),
			};
		}
		case SET_NEW_ORGANISATION_NAME:
			return {
				...state,
				newOrganisationName: action.payload,
			};
		case CREATE_INVITATION_INIT:
			return {
				...state,
				createInvitationSuccess: true,
				createInvitationInProgress: true,
			};
		case CREATE_INVITATION_DONE: {
			const {id, email, organisation_id, status, license_type} = action.payload;

			const organisation = state.organisations.find(
				(organisation) => organisation.id === organisation_id,
			);

			return {
				...state,
				createInvitationInProgress: false,
				createInvitationSuccess: false,
				invitedUsers: [
					{
						id,
						email,
						organisation_id,
						organisation: organisation?.name,
						license_type,
						status,
					},
					...state.invitedUsers,
				],
			};
		}
		case CREATE_INVITATION_FAIL:
			return {
				...state,
				createInvitationSuccess: false,
				createInvitationInProgress: false,
			};
		case CREATE_ORGANISATION_INIT:
			return {
				...state,
				createOrganisationInProgress: true,
			};
		case CREATE_ORGANISATION_DONE:
			return {
				...state,
				createOrganisationInProgress: false,
				organisations: [...state.organisations, action.payload],
				newOrganisationName: "",
			};
		case CREATE_ORGANISATION_FAIL:
			return {
				...state,
				createOrganisationInProgress: false,
			};
		case SORT_INVITED_USERS: {
			const {invitedUsers, invitedUsersOrderMap} = state;
			const key = action.payload;

			let order = "asc";
			if (invitedUsersOrderMap[key]) {
				switch (invitedUsersOrderMap[key]) {
					case "asc":
						order = "desc";
						break;
					case "desc":
						order = "";
						break;
					default:
						order = "asc";
				}
			}

			return {
				...state,
				invitedUsers: order
					? [...invitedUsers.sort(compareValues(key, order))]
					: [...invitedUsers.sort(compareValues("organisation", "asc"))],
				invitedUsersOrderMap: {
					...invitedUsersOrderMap,
					[key]: order,
				},
			};
		}
		case SORT_CURRENT_USERS: {
			const {currentUsers, currentUsersOrderMap} = state;
			const key = action.payload;

			let order = "asc";
			if (currentUsersOrderMap[key]) {
				switch (currentUsersOrderMap[key]) {
					case "asc":
						order = "desc";
						break;
					case "desc":
						order = "";
						break;
					default:
						order = "asc";
				}
			}

			return {
				...state,
				currentUsers: order
					? [...currentUsers.sort(compareValues(key, order))]
					: [...currentUsers.sort(compareValues("first_name", "asc"))],
				currentUsersOrderMap: {
					...currentUsersOrderMap,
					[key]: order,
				},
			};
		}
		case REVOKE_INVITATION_INIT:
			return {
				...state,
				revokingInvitationInProgress: [
					...state.revokingInvitationInProgress,
					action.payload,
				],
				revokeInviteInProgress: true,
			};
		case REVOKE_INVITATION_DONE:
			return {
				...state,
				revokingInvitationInProgress: state.revokingInvitationInProgress.filter(
					(id) => id !== action.payload,
				),
				invitedUsers: state.invitedUsers.filter((user) => user.id !== action.payload),
				revokeInviteInProgress: false,
			};
		case REVOKE_INVITATION_FAIL: {
			const {invitationID} = action.payload;
			const {revokingInvitationInProgress} = state;

			return {
				...state,
				revokingInvitationInProgress: revokingInvitationInProgress.filter(
					(id) => id !== invitationID,
				),
				revokeInviteInProgress: false,
			};
		}
		case VERIFY_INVITATION_INIT:
			return {
				...state,
				inviteCodeInProgress: true,
			};
		case VERIFY_INVITATION_DONE:
			return {
				...state,
				inviteCode: action.payload,
				inviteCodeInProgress: false,
			};
		case VERIFY_INVITATION_FAIL:
			return {
				...state,
				inviteCodeInProgress: false,
			};
		case RESEND_INVITATION_INIT:
			return {
				...state,
				resendUserInvitationInProgress: true,
				invitingUserInProgress: [...state.invitingUserInProgress, action.payload],
			};
		case RESEND_INVITATION_DONE: {
			const invitationID = action.payload;
			const {invitingUserInProgress} = state;

			return {
				...state,
				resendUserInvitationInProgress: false,
				invitingUserInProgress: invitingUserInProgress.filter((id) => id !== invitationID),
			};
		}
		case RESEND_INVITATION_FAIL: {
			const {invitationID} = action.payload;
			const {invitingUserInProgress} = state;

			return {
				...state,
				resendUserInvitationInProgress: false,
				invitingUserInProgress: invitingUserInProgress.filter((id) => id !== invitationID),
			};
		}
		case REVOKE_USER_INIT:
			return {
				...state,
				revokingUserInProgress: [...state.revokingUserInProgress, action.payload],
			};
		case REVOKE_USER_DONE: {
			const userID = action.payload;
			const {currentUsers, revokingUserInProgress} = state;

			return {
				...state,
				revokingUserInProgress: revokingUserInProgress.filter((id) => id !== userID),
				currentUsers: currentUsers.filter((user) => user.id !== userID),
			};
		}
		case REVOKE_USER_FAIL: {
			const {userID} = action.payload;
			const {revokingUserInProgress} = state;

			return {
				...state,
				revokingUserInProgress: revokingUserInProgress.filter((id) => id !== userID),
			};
		}
		case GET_ORGANISATIONS_INIT:
			return {
				...state,
				organisationsInProgress: true,
				organizationTableDataInProgress: true,
			};
		case GET_ORGANISATIONS_DONE: {
			return {
				...state,
				...action.payload,
				organisationsInProgress: false,
				organizationTableDataInProgress: false,
			};
		}
		case GET_ORGANISATIONS_FAIL:
			return {
				...state,
				organisationsInProgress: false,
				organizationTableDataInProgress: false,
			};

		case GET_ORGANIZATION_LIST_INIT:
			return {
				...state,
				organizationsListProgress: true,
				organizationTableDataInProgress: true,
			};

		case GET_ORGANIZATION_LIST_DONE:
			return {
				...state,
				organizationsList: action.payload,
				organizationTableData: action.payload,
				organizationsListProgress: false,
				organizationTableDataInProgress: false,
			};

		case GET_ORGANIZATION_LIST_FAIL:
			return {
				...state,
				organizationsListProgress: false,
				organizationTableDataInProgress: false,
			};

		case UPDATE_ORGANIZATION_INIT:
			return {
				...state,
				updateOrganizationsInProgress: true,
			};

		case UPDATE_ORGANIZATION_DONE: {
			const updatedOrganizationsList = state.organizationTableData.map((organization) => {
				if (organization.id === action.payload.id) {
					return {
						...organization,
						name: action.payload.name,
						is_active: action.payload.is_active,
					};
				}

				return organization;
			});

			return {
				...state,
				organizationTableData: updatedOrganizationsList,
				updateOrganizationsInProgress: false,
			};
		}

		case UPDATE_ORGANIZATION_FAIL:
			return {
				...state,
				updateOrganizationsInProgress: false,
			};

		case GET_ORGANISATION_USERS_INIT:
			return {
				...state,
				orgUsersListProgress: true,
			};

		case GET_ORGANISATION_USERS_DONE:
			return {
				...state,
				orgUsersList: action.payload,
				orgUsersListProgress: false,
			};

		case GET_ORGANISATION_USERS_FAIL:
			return {
				...state,
				orgUsersListProgress: false,
			};

		case DELETE_ORGANIZATION_INIT:
			return {
				...state,
				deleteOrgProgress: true,
			};
		case DELETE_ORGANIZATION_DONE:
			return {
				...state,
				deleteOrgProgress: false,
			};
		case DELETE_ORGANIZATION_FAIL:
			return {
				...state,
				deleteOrgProgress: false,
			};
		case UPDATE_CREDITS_USED_KEY:
			return {
				...state,
				creditsUsedKey: action.payload.updatedKey,
			};

		case GET_INVITATIONS_INIT:
			return {
				...state,
				getInvitedUsersInProgress: true,
			};
		case GET_INVITATIONS_DONE: {
			return {
				...state,
				invitedUsers: action.payload.results,
				getInvitedUsersInProgress: false,
			};
		}
		case GET_INVITATIONS_FAIL:
			return {
				...state,
				getInvitedUsersInProgress: false,
			};

		case GET_ORGANIZATION_TABLE_DATA_INIT:
			return {
				...state,
				organizationTableDataInProgress: true,
			};
		case GET_ORGANIZATION_TABLE_DATA_DONE: {
			return {
				...state,
				organizationTableData: action.payload,
				organizationTableDataInProgress: false,
			};
		}
		case GET_ORGANIZATION_TABLE_DATA_FAIL:
			return {
				...state,
				organizationTableDataInProgress: false,
			};

		case SET_ORGANIZATION_LIST_ORDERING: {
			return {
				...state,
				organizationsListOrdering: action.payload,
			};
		}

		default:
			return state;
	}
};

export default organisationReducer;
