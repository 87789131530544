import {type FC, useState} from "react";
import classNames from "classnames";

import {ArrowDown} from "assets/icons";

import type {IBarProps} from "./types";
import styles from "./Bar.module.scss";

export const Bar: FC<IBarProps> = ({regionsByCountry, colors}) => {
	const [activeRegion, setActiveRegion] = useState<string>("");

	const activeHandler = (id: string) => {
		if (id !== activeRegion) {
			setActiveRegion(id);
		} else {
			setActiveRegion("");
		}
	};

	const renderRegions = Object.keys(regionsByCountry).map((key, idx) => {
		const val = regionsByCountry[key];
		const isActiveRegion = key === activeRegion;

		const renderCountries = val.countries.map((country, idx) => (
			<div key={idx}>
				<p>{country.name}</p>
				<p>{country.candidates_percent}%</p>
			</div>
		));

		return (
			<div key={key} className={styles.region}>
				<div className={styles.regionHeader} onClick={() => activeHandler(key)}>
					<div className={styles.round} style={{background: colors[idx]}}></div>
					<div className={styles.regionContainer}>
						<span className={styles.key}>{key}</span>
						<div
							className={classNames(styles.activeButton, {
								[styles.activeArrow]: isActiveRegion,
							})}
						>
							<ArrowDown />
						</div>

						<span className={styles.bold}>{Math.round(val.percentage)}%</span>
					</div>
				</div>

				<div
					className={classNames(styles.countryList, {
						[styles.activeList]: isActiveRegion,
					})}
				>
					{renderCountries}
				</div>
			</div>
		);
	});

	return <div className={styles.regions}>{renderRegions}</div>;
};
