import {type FC} from "react";
import {Tooltip as ReactTooltip} from "react-tooltip";
import styles from "../tooltips.module.scss";

const JobTitleFilterHoverTooltip: FC = () => {
	return (
		<ReactTooltip clickable place="right" id="jobTitle" className={styles.jobTitleTooltip}>
			<div style={{fontWeight: 500, marginBottom: 8}}>Job Title</div>

			<div>
				<div style={{marginBottom: 10}}>Matches are based on current job title.</div>
				<div style={{marginBottom: 10}}>
					<b>Contains:</b> Results will contain the words inside your chosen job titles.
				</div>
				<div style={{marginBottom: 10}}>
					<b>Close Match:</b> Results will include job titles that are closely related to
					your selected job titles.
				</div>
			</div>
		</ReactTooltip>
	);
};

export default JobTitleFilterHoverTooltip;
