import type {IconProps} from "./types";

const NotchArrowDown = (props: IconProps) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={20}
		height={20}
		fill="none"
		viewBox="0 0 20 20"
		{...props}
	>
		<mask
			id="a"
			width={20}
			height={20}
			x={0}
			y={0}
			maskUnits="userSpaceOnUse"
			style={{
				maskType: "alpha",
			}}
		>
			<path fill="#D9D9D9" d="M0 0v20h20V0z" />
		</mask>
		<g mask="url(#a)">
			<path fill="#404040" d="m15 10-4.25 2.542V2h-1.5v10.542L5 10l5 8 5-8Z" />
		</g>
	</svg>
);

export default NotchArrowDown;
