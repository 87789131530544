import type {TRootState} from "types";

export const pricingTableModalSelector = (state: TRootState) => state.payment.pricing_table_modal;

export const productsInProgressSelector = (state: TRootState) => state.payment.products_in_progress;

export const productsSelector = (state: TRootState) =>
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	state.payment.products.sort((a: any, b: any) => a.metadata.index - b.metadata.index);

export const openCheckoutInProgressSelector = (state: TRootState) =>
	state.payment.open_checkout_in_progress;

export const openCustomerPortalInProgressSelector = (state: TRootState) =>
	state.payment.open_customer_portal_in_progress;

export const contactAdminModalSelector = (state: TRootState) => state.payment.contact_admin_modal;
