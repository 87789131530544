import ContentBlock from "./ContentBlock";
import ResultsBlockLoader from "./ContentBlock/ResultsBlockLoader";
import {useEffect, useState} from "react";
import {projectSelector} from "store/mapx/project-list/projectListSelectors";
import FilterBlock from "./FilterBlock";
import styles from "./styles.module.scss";
import {useAppDispatch, useAppSelector} from "hooks";
import {getAllSearchRequestsByType} from "store/mapx/additional-profiles/searchRequestAsyncActions";
import DisplaySimilarProfiles from "mapx-components/DisplaySimilarProfiles";
import {TSearchRequestResultsProps} from "mapx-components/SearchRequestResults/types";
import {allSearchRequestsSelector} from "store/mapx/additional-profiles/additionalProfilesSelectors";
import classNames from "classnames";
import {hasAccessToExtendedPagesSelector} from "store/mapx/user/userSelectors";

const SearchRequestResults = ({
	searchType,
	displayOnlySimilarProfiles,
	setLoadingSearchRequests,
	addSearchResultsTab = false,
}: TSearchRequestResultsProps) => {
	const dispatch = useAppDispatch();

	const project = useAppSelector(projectSelector);

	const allSearchRequests = useAppSelector(allSearchRequestsSelector);

	const [infoLoaded, setInfoLoaded] = useState(false);

	const [isDisplayingMatchingTo, setDisplayingMatchingTo] = useState(false);

	const hasAccessToPremium = useAppSelector(hasAccessToExtendedPagesSelector);

	useEffect(() => {
		async function init() {
			if (project && searchType !== "Additional Profiles") {
				if (setLoadingSearchRequests) {
					setLoadingSearchRequests(true);
				}

				await dispatch(getAllSearchRequestsByType(project.id, searchType));

				if (setLoadingSearchRequests) {
					setLoadingSearchRequests(false);
				}
			}
		}
		init().finally(() => setInfoLoaded(true));
	}, [dispatch, project, searchType, setLoadingSearchRequests]);

	useEffect(() => {
		if (displayOnlySimilarProfiles) {
			setDisplayingMatchingTo(displayOnlySimilarProfiles);
		} else {
			setDisplayingMatchingTo(false);
		}
	}, [displayOnlySimilarProfiles]);

	return infoLoaded ? (
		<div style={{display: "flex", flexDirection: "column", flexGrow: 1}}>
			{(hasAccessToPremium || allSearchRequests?.length > 0) && (
				<div className={styles.container}>
					<FilterBlock
						searchType={searchType}
						addSearchResultsTab={addSearchResultsTab}
						isDisplayingMatchingTo={isDisplayingMatchingTo}
						setDisplayingMatchingTo={setDisplayingMatchingTo}
					/>

					{isDisplayingMatchingTo && <DisplaySimilarProfiles />}

					{!isDisplayingMatchingTo && <ContentBlock />}
				</div>
			)}

			{!hasAccessToPremium &&
				allSearchRequests?.length === 0 &&
				searchType === "More Like This" &&
				project && (
					<div className={classNames(styles.container, styles.instructionDesc)}>
						{"You don't have any profile search history to display."}
					</div>
				)}
		</div>
	) : (
		<ResultsBlockLoader />
	);
};

export default SearchRequestResults;
