import React from "react";

const FindIcon = () => {
	return (
		<svg
			width="32"
			height="32"
			viewBox="0 0 32 32"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<mask
				id="mask0_1996_36560"
				style={{maskType: "alpha"}}
				maskUnits="userSpaceOnUse"
				x="0"
				y="0"
				width="32"
				height="32"
			>
				<rect width="32" height="32" fill="#D9D9D9" />
			</mask>
			<g mask="url(#mask0_1996_36560)">
				<path
					d="M14.6667 16.0002C13.2 16.0002 11.9444 15.4779 10.9 14.4335C9.85556 13.3891 9.33333 12.1335 9.33333 10.6668C9.33333 9.20016 9.85556 7.94461 10.9 6.90016C11.9444 5.85572 13.2 5.3335 14.6667 5.3335C16.1333 5.3335 17.3889 5.85572 18.4333 6.90016C19.4778 7.94461 20 9.20016 20 10.6668C20 12.1335 19.4778 13.3891 18.4333 14.4335C17.3889 15.4779 16.1333 16.0002 14.6667 16.0002ZM29.4667 31.3335L25.2 27.0668C24.7333 27.3335 24.2333 27.5557 23.7 27.7335C23.1667 27.9113 22.6 28.0002 22 28.0002C20.3333 28.0002 18.9164 27.4171 17.7493 26.2508C16.5831 25.0837 16 23.6668 16 22.0002C16 20.3335 16.5831 18.9166 17.7493 17.7495C18.9164 16.5833 20.3333 16.0002 22 16.0002C23.6667 16.0002 25.0836 16.5833 26.2507 17.7495C27.4169 18.9166 28 20.3335 28 22.0002C28 22.6002 27.9111 23.1668 27.7333 23.7002C27.5556 24.2335 27.3333 24.7335 27.0667 25.2002L31.3333 29.4668L29.4667 31.3335ZM22 25.3335C22.9333 25.3335 23.7222 25.0113 24.3667 24.3668C25.0111 23.7224 25.3333 22.9335 25.3333 22.0002C25.3333 21.0668 25.0111 20.2779 24.3667 19.6335C23.7222 18.9891 22.9333 18.6668 22 18.6668C21.0667 18.6668 20.2778 18.9891 19.6333 19.6335C18.9889 20.2779 18.6667 21.0668 18.6667 22.0002C18.6667 22.9335 18.9889 23.7224 19.6333 24.3668C20.2778 25.0113 21.0667 25.3335 22 25.3335ZM14.7 17.3335C13.7667 18.7113 13.3 20.2668 13.3 22.0002C13.3 23.7335 13.7667 25.2891 14.7 26.6668H4V22.9668C4 22.2113 4.18889 21.5113 4.56667 20.8668C4.94444 20.2224 5.46667 19.7335 6.13333 19.4002C7.26667 18.8224 8.54444 18.3335 9.96667 17.9335C11.3889 17.5335 12.9667 17.3335 14.7 17.3335Z"
					fill="currentColor"
				/>
			</g>
		</svg>
	);
};

export default FindIcon;
