import type {FC} from "react";

import type {TChartPartsProps} from "./types";
import styles from "./UserPreloadInsightSkeleton.module.scss";

const ChartParts: FC<TChartPartsProps> = ({title, blurImage, tabActive, deActive, blurText}) => (
	<div className={styles.charts}>
		<div className={styles.charts__head}>
			<p className={styles.charts__head_title}>{title}</p>

			<div className={styles.charts__head__tabs}>
				<span className={styles.charts__head__tabs_active}>{tabActive}</span>
				<span className={styles.charts__head__tabs_un_active}>{deActive}</span>
			</div>
		</div>

		<div className={styles.charts__diagram}>
			<span className={styles.charts__diagram__text}>{blurText}</span>

			<img src={blurImage} alt={title} className={styles.charts__diagram__img} />
		</div>
	</div>
);

export default ChartParts;
