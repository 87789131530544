import type {FC} from "react";
import {Link} from "react-router-dom";

import type {TFormInputLabelProps} from "./types";
import styles from "./FormInput.module.scss";

const FormInputLabel: FC<TFormInputLabelProps> = ({to, label, rightLabel}) => (
	<>
		{label || rightLabel ? (
			<div className={styles.container__header}>
				{label && <span className={styles.container__header_label}>{label}</span>}
				{rightLabel && to ? (
					<Link
						to={to}
						data-testid="input/right-label-link"
						className={styles.container__header_forgot}
					>
						{rightLabel}
					</Link>
				) : (
					<span className={styles.container__header_forgot}>{rightLabel}</span>
				)}
			</div>
		) : (
			<></>
		)}
	</>
);

export default FormInputLabel;
