import {MarketMapsIcon, SelectAllIcon} from "assets/icons";
import classNames from "classnames";

import {Checkbox, ResetFilters, Switch, TabSelect} from "components";
import {useAppDispatch, useAppSelector} from "hooks";
import CheckboxList from "mapx-components/Inputs/CheckboxList";
import React, {useCallback, useMemo, useState} from "react";
import {
	targetCompaniesPaginationSelector,
	targetListCompaniesSelector,
	targetListCompanyInProgressSelector,
} from "store/mapx/target-list/targetListSelectors";
import css from "./targetListFilter.module.scss";
import {STCompany} from "api/companyApi/types";
import useInfiniteScroll from "react-infinite-scroll-hook";
import {GeneralButton} from "mapx-components";
import {projectSelector} from "store/mapx/project-list/projectListSelectors";
import {addFromMarketMapModal} from "store/mapx/market-map/marketMapActions";
import {TargetListPropsForCompanySearch} from "../types";

const positionOptions = [
	{label: "Current", value: "current"},
	{label: "Previous", value: "previous"},
	{label: "Any", value: "any"},
];

const TargetListFilter = ({
	totalCompanies,
	TLFilterToggled,
	selectedCompaniesOnTL,
	handleToggleTL,
	handleTLResetClick,
	handleSelectAllTL,
	handleTLPositionChange,
	handleTLChange,
	TLPosition,
}: TargetListPropsForCompanySearch) => {
	const dispatch = useAppDispatch();

	const targetListCompanies = useAppSelector(targetListCompaniesSelector);

	const project = useAppSelector(projectSelector);

	const targetCompaniesPagination = useAppSelector(targetCompaniesPaginationSelector);

	const companiesFetchInProgress = useAppSelector(targetListCompanyInProgressSelector);

	const [page, setPage] = useState<number>(1);

	const totalDisplayNumber = useMemo(() => page * 20, [page]);

	const displayedCompanies = useMemo(() => {
		return targetListCompanies.slice(0, totalDisplayNumber);
	}, [targetListCompanies, totalDisplayNumber]);

	const [sentryRef] = useInfiniteScroll({
		loading: false,
		hasNextPage: targetCompaniesPagination?.count > totalDisplayNumber,
		onLoadMore: async () => {
			setPage((prev) => prev + 1);
		},
		disabled: targetCompaniesPagination === null,
		delayInMs: 300,
	});

	const count = useMemo(() => {
		return selectedCompaniesOnTL?.length || 0;
	}, [selectedCompaniesOnTL]);

	const isAllTargetCompaniesAreSelected = useMemo(() => {
		if (count === 0) return false;

		return count === targetListCompanies.length;
	}, [count, targetListCompanies]);

	const handleAddFromMarketMap = useCallback(() => {
		dispatch(addFromMarketMapModal(true, {targetListID: project?.target_list_id}));
	}, [dispatch, project?.target_list_id]);

	return (
		<div
			className={classNames(css.accordion, {[css.expanded]: TLFilterToggled})}
			aria-expanded={TLFilterToggled}
		>
			<div className={css.line} />

			<div className={css.header}>
				{totalCompanies > 0 ? (
					<div className={css.left}>
						<Switch
							data-testid="toggleTargetListCompanyFilter"
							checked={TLFilterToggled}
							onChange={handleToggleTL}
						/>
						<div className={css.title}>Target List</div>
						{count > 0 && <div className={css.count}>{count}</div>}
					</div>
				) : (
					<div className={css.left}></div>
				)}

				<div className={css.right}>
					<GeneralButton
						customClass={css.targetListButton}
						icon={<MarketMapsIcon color="#0c5850" />}
						title={"Add Target List"}
						onClick={handleAddFromMarketMap}
					/>
				</div>
			</div>
			{totalCompanies > 0 && (
				<div className={classNames(css.children, {[css.expanded]: TLFilterToggled})}>
					<TabSelect.LabelContainer label="All positions">
						<TabSelect
							selected={TLPosition}
							onTabChange={handleTLPositionChange}
							options={positionOptions}
						/>
					</TabSelect.LabelContainer>

					<div className={css.resetFilterContainer}>
						{totalCompanies > 0 && (
							<ResetFilters
								parentStyle={{marginBottom: 0, color: "#5A5A5A", marginRight: 19}}
								onClick={handleTLResetClick}
								displayIcon={true}
							>
								Clear Selection
							</ResetFilters>
						)}

						{!isAllTargetCompaniesAreSelected && (
							<div className={css.selectAll} onClick={handleSelectAllTL}>
								<SelectAllIcon /> Select All
							</div>
						)}
					</div>
					<CheckboxList>
						{displayedCompanies.map((item: STCompany) => (
							<Checkbox
								borderColor="#0C5850"
								containerClass={css.checkboxContainer}
								isChecked={selectedCompaniesOnTL.includes(item)}
								key={item.id}
								label={item.name}
								onChange={() => handleTLChange(item)}
								value={item.name}
							/>
						))}

						{!companiesFetchInProgress &&
							targetCompaniesPagination?.count > totalDisplayNumber && (
								<div
									ref={sentryRef}
									key="saved_companies"
									style={{width: "100%", height: "20px"}}
								/>
							)}
					</CheckboxList>
				</div>
			)}
		</div>
	);
};

export default TargetListFilter;
