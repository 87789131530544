import type {TCategoryProps} from "./types";

export const searchCategory: TCategoryProps[] = [
	{id: 1, text: "All", type: "all", placeholder: "Search for a Company or Person..."},
	{id: 2, text: "Companies", type: "companies", placeholder: "Search for a Company"},
	{id: 3, text: "People", type: "people", placeholder: "Search for a Person"},
];

export const countHandler = (activeCategory: number, company?: number, candidates?: number) => {
	switch (activeCategory) {
		case 1:
			return Number(company) > 99 ? "99+" : Number(company);
		case 2:
			return Number(candidates) > 99 ? "99+" : Number(candidates);

		default:
			return Number(company) + Number(candidates) > 99
				? "99+"
				: Number(company) + Number(candidates);
	}
};
