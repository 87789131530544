import React, {useCallback, useMemo, useState} from "react";
import {
	activeImportPercentageForCompanySelector,
	candidatesImportForCompanySelector,
	currentCompanyBulkImportURLsSelector,
	isImportInProgressForCurrentCompany,
	isImportInProgressForNonCurrentCompany,
	showBulkImportReportForCompanySelector,
	showLastBulkImportReportForCompanySelector,
} from "store/mapx/linkedin-bulk-import/linkedinBulkImportSelectors";
import {useAppDispatch, useAppSelector, useCompanyIdParam} from "hooks";
import {
	activeAddPeopleModalForProject,
	removeBulkImportDataForSource,
	resetBulkImportStatesForCompany,
	setNonClosedProjectIdForLinkedinBulkImport,
} from "store/mapx/linkedin-bulk-import/linkedInBulkImportActions";
import {toast} from "react-toastify";
import {companySelector} from "store/mapx/company/companySelectors";
import {TState} from "mapx-components/Modals/SearchByLinkedInModal/types";
import {AddPeopleByLinkedIn} from "mapx-components";
import styles from "mapx-components/AddPeopleByLinkedIn/styles.module.scss";
import {sortUrls} from "mapx-components/AddPeopleByLinkedIn/util";
import {importLinkedInProfileToCompany} from "store/mapx/linkedin-bulk-import/linkedInProfilesImportAsyncActions";

const AddPeople = () => {
	const company = useAppSelector(companySelector);

	const importPercentage = useAppSelector(activeImportPercentageForCompanySelector);

	const currentCompanyUrls = useAppSelector(currentCompanyBulkImportURLsSelector);

	const dispatch = useAppDispatch();

	const companyParamId = useCompanyIdParam();

	const inProgressForOtherCompany = useAppSelector(isImportInProgressForNonCurrentCompany);

	const inProgress = useAppSelector(isImportInProgressForCurrentCompany);

	const shouldOpenReportModal = useAppSelector(showBulkImportReportForCompanySelector);

	const handleOnSubmit = async (urls: string[]): Promise<TState> => {
		dispatch(setNonClosedProjectIdForLinkedinBulkImport(0));

		dispatch(importLinkedInProfileToCompany(urls, company));

		return "link-submit";
	};

	const onSuccessClose = async (importID: number) => {
		dispatch(resetBulkImportStatesForCompany(importID));
		dispatch(removeBulkImportDataForSource(importID));

		toast.dismiss(`import_success_toaster${importID}`);
		toast.dismiss(`import_error_toaster${importID}`);
	};

	const setInitialState = useMemo((): TState => {
		if (importPercentage === 100) {
			return "success";
		}

		return "link-submit";
	}, [importPercentage]);

	const toggleModalDisplay = useCallback(
		(displayed: boolean) => {
			if (displayed) {
				dispatch(activeAddPeopleModalForProject(0));
			} else {
				dispatch(activeAddPeopleModalForProject(null));
			}
		},
		[dispatch],
	);

	const [modalDisplay, setModalDisplay] = useState<boolean>(false);

	const candidatesImportForCompany = useAppSelector(candidatesImportForCompanySelector);

	const showLastBulkImportReportForCompany = useAppSelector(
		showLastBulkImportReportForCompanySelector,
	);

	const shouldRenderLastLinkedInBulkImportReport = useMemo(() => {
		const lastCompletedImport = candidatesImportForCompany[companyParamId];

		return lastCompletedImport?.length > 0 && showLastBulkImportReportForCompany && !inProgress;
	}, [
		companyParamId,
		showLastBulkImportReportForCompany,
		inProgress,
		candidatesImportForCompany,
	]);

	const importHistoryData = useMemo(() => {
		return sortUrls(candidatesImportForCompany[companyParamId]);
	}, [companyParamId, candidatesImportForCompany]);

	return (
		<AddPeopleByLinkedIn
			containerClass={styles.addPeopleByLinkedIn}
			onSubmit={handleOnSubmit}
			source={"company"}
			inProgress={inProgress}
			isLastBulkImportDataLoading={false}
			onSuccessClose={onSuccessClose}
			linkedInUrl={currentCompanyUrls}
			initialState={setInitialState}
			toggleModalDisplay={toggleModalDisplay}
			modalDisplay={modalDisplay}
			setModalDisplay={setModalDisplay}
			shouldOpenReportModal={shouldOpenReportModal}
			renderLastCompletedReport={shouldRenderLastLinkedInBulkImportReport}
			lastCompletedReportData={importHistoryData}
			importPercentage={importPercentage}
			lastCompletedImportID={0}
			otherImportInstancePresent={inProgressForOtherCompany}
		/>
	);
};

export default AddPeople;
