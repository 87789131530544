import React from "react";

const MaybeIcon = () => {
	return (
		<svg
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<mask
				id="mask0_1733_162956"
				style={{maskType: "alpha"}}
				maskUnits="userSpaceOnUse"
				x="0"
				y="0"
				width="20"
				height="20"
			>
				<rect width="20" height="20" fill="#D9D9D9" />
			</mask>
			<g mask="url(#mask0_1733_162956)">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M6.89583 17.375C7.86806 17.7917 8.90278 18 10 18C11.1111 18 12.1493 17.7917 13.1146 17.375C14.0799 16.9583 14.9271 16.3854 15.6562 15.6562C16.3854 14.9271 16.9583 14.0764 17.375 13.1042C17.7917 12.1319 18 11.0972 18 10C18 8.88889 17.7917 7.85069 17.375 6.88542C16.9583 5.92014 16.3854 5.07292 15.6562 4.34375C14.9271 3.61458 14.0799 3.04167 13.1146 2.625C12.1493 2.20833 11.1111 2 10 2C8.90278 2 7.86806 2.20833 6.89583 2.625C5.92361 3.04167 5.07292 3.61458 4.34375 4.34375C3.61458 5.07292 3.04167 5.92014 2.625 6.88542C2.20833 7.85069 2 8.88889 2 10C2 11.0972 2.20833 12.1319 2.625 13.1042C3.04167 14.0764 3.61458 14.9271 4.34375 15.6562C5.07292 16.3854 5.92361 16.9583 6.89583 17.375ZM6 9.25V10.75H14V9.25H6Z"
					fill="#F5A300"
				/>
			</g>
		</svg>
	);
};

export default MaybeIcon;
