import type {FC} from "react";
import ContentLoader from "react-content-loader";
import React from "react";

const IndustryServicesHeaderSkeletonLoader: FC = (props) => (
	<ContentLoader
		speed={2}
		height={40}
		width={"80%"}
		backgroundColor="#EEEDEC"
		foregroundColor="#e6e6e6"
		{...props}
	>
		<rect x="14" y="20" width="50%" height="50" />
	</ContentLoader>
);

export default IndustryServicesHeaderSkeletonLoader;
