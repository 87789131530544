import {type FC, useState, useRef, useCallback} from "react";
import classNames from "classnames";

import {Button} from "components";
import {
	creditsUsedKeySelector,
	organizationsListOrderingSelector,
} from "store/mapx/organisation/organisationSelectors";
import {OrganizationActionsDropDown} from "mapx-components";
import {useAppDispatch, useAppSelector, useOutsideClick} from "hooks";
import {updateCreditsUsedKey} from "store/mapx/organisation/organisationActions";

import type {TTableHeader} from "../types";
import OrderingButton from "../OrderingButton";

import styles from "./TableHeader.module.scss";

export const creditsUsedData = [
	{id: 0, label: "Credits Used (Alltime)", key: "all_time"},
	{id: 1, label: "Credits Used (This year)", key: "this_year"},
	{id: 2, label: "Credits Used (180 days)", key: "last_180_days"},
	{id: 3, label: "Credits Used (90 days)", key: "last_90_days"},
	{id: 4, label: "Credits Used (30 days)", key: "last_30_days"},
	{id: 5, label: "Credits Used (7 days)", key: "last_7_days"},
];

const TableHeaderStep: FC<TTableHeader> = ({id, idx, text, onSort, itemKey, isDropdown}) => {
	const ref = useRef<HTMLDivElement | null>(null);

	const dispatch = useAppDispatch();

	const [showDropDown, setShowDropDown] = useState<boolean>(false);

	const creditsUsedDynamicKey = useAppSelector(creditsUsedKeySelector);

	const organizationListOrdering = useAppSelector(organizationsListOrderingSelector);
	const detectedItemKey = itemKey || creditsUsedDynamicKey;
	const orderingKey =
		detectedItemKey &&
		organizationListOrdering.find((organizationListOrderingItem: string[]) =>
			organizationListOrderingItem.includes(detectedItemKey),
		);
	const isDescendingOrder = orderingKey?.includes("-");
	const isAscendingOrder = orderingKey && !isDescendingOrder;

	const openSorting = () => {
		if (isDropdown) {
			setShowDropDown(!showDropDown);
		}
	};

	useOutsideClick(ref, () => setShowDropDown(false));

	const handleActionsChange = useCallback((key: string) => {
		dispatch(updateCreditsUsedKey(key));
		setShowDropDown(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div
			key={id}
			ref={ref}
			className={classNames(styles.wrapper__btn, {
				[styles.wrapper__btn_left]: idx === 0 || idx === 1 || idx === 2,
				[styles.wrapper__btn_right]: idx === 3 || idx === 4,
			})}
		>
			<Button
				defaultBtnStyle
				onClick={openSorting}
				className={classNames({
					[styles.wrapper__btn_pointer]: id === 3,
				})}
				data-testid="table-header-button"
			>
				{text}
			</Button>

			<OrderingButton
				isAscendingOrder={isAscendingOrder}
				isDescendingOrder={isDescendingOrder}
				onClick={() => onSort && onSort(detectedItemKey)}
			/>

			{showDropDown && (
				<OrganizationActionsDropDown
					data={creditsUsedData}
					onChange={handleActionsChange}
					className={styles.wrapper__btn__select}
				/>
			)}
		</div>
	);
};

export default TableHeaderStep;
