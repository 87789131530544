import {FC, useMemo} from "react";
import {useAppSelector} from "hooks";
import {RoundCustomCharts} from "components";
import {
	companyEthnicDiversityDataInProgressSelector,
	companyEthnicDiversityDataSelector,
	companyGenderDiversityDataInProgressSelector,
	companyGenderDiversityDataSelector,
	companyOrgChartTrendsInProgressSelector,
	companyOrgChartTrendsSelector,
} from "store/mapx/company/companySelectors";

import styles from "../company.module.scss";
import classNames from "classnames";
import {IndustryDiversityTabComponentProps} from "mapx-pages/CompanyDetails/types";

const DiversityStats: FC<IndustryDiversityTabComponentProps> = ({isCurrentTabSelected}) => {
	const genderTrendsInProgress = useAppSelector(companyOrgChartTrendsInProgressSelector);

	const genderTrends = useAppSelector(companyOrgChartTrendsSelector);

	const ethnicDiversityInProgress = useAppSelector(companyEthnicDiversityDataInProgressSelector);

	const ethnicDiversity = useAppSelector(companyEthnicDiversityDataSelector);

	const genderDiversity = useAppSelector(companyGenderDiversityDataSelector);

	const genderDiversityInProgress = useAppSelector(companyGenderDiversityDataInProgressSelector);

	const ethnicDiversityData = useMemo(
		() =>
			ethnicDiversity
				? Object.entries(ethnicDiversity).reduce((acc, curVal) => {
						if (curVal[0] !== "white_percent") {
							acc += curVal[1] as number;
						}

						return acc;
				  }, 0)
				: 0,
		[ethnicDiversity],
	);

	return (
		<div
			className={classNames(styles.diversity_wrapper, {
				[styles.hidden]: !isCurrentTabSelected,
			})}
		>
			<RoundCustomCharts
				isLoad={ethnicDiversityInProgress || genderDiversityInProgress}
				title="Diversity"
				twoTitle="Gender Diversity"
				oneTitle="Ethnic diversity"
				twoDescription="% of people estimated female"
				oneDescription="% of people estimated ethnically diverse"
				percentOne={ethnicDiversityData}
				percentTwo={genderDiversity?.female_percent ? genderDiversity?.female_percent : 0}
			/>

			<RoundCustomCharts
				isLoad={genderTrendsInProgress}
				title="Hiring Trends"
				twoTitle="External hires"
				oneTitle="Time outside Industry"
				twoDescription="% of people hired externally for their current role"
				oneDescription="% of people with experience outside of this industry"
				percentOne={
					genderTrends?.outside_industry_time_percent
						? genderTrends?.outside_industry_time_percent
						: 0
				}
				percentTwo={
					genderTrends?.external_hires_percent ? genderTrends?.external_hires_percent : 0
				}
			/>
		</div>
	);
};

export default DiversityStats;
