import {useCallback, useEffect} from "react";
import {ToastContent} from "components";
import {toast} from "react-toastify";
import {useAppDispatch, useAppSelector} from "hooks/index";
import {activeLastCandidateAssessmentForProjectSelector} from "store/mapx/background-tasks/backgroundTaskSelectors";
import {STBackgroundTaskResponse, TBackgroundTaskStatus} from "api/backgroundTaskApi/types";
import {checkCandidateAssessmentStatus} from "store/mapx/project/projectAssessmentAsyncActions";
import {clearProjectCandidateAssessmentProcess} from "store/mapx/background-tasks/backgroundTaskActions";

/* eslint-disable  @typescript-eslint/no-explicit-any */
const intervalId = {} as any;

const useProjectCandidateScoringProgressWatcher = () => {
	const activeLastCandidateAssessmentForProject: STBackgroundTaskResponse = useAppSelector(
		activeLastCandidateAssessmentForProjectSelector,
	);

	const dispatch = useAppDispatch();

	const displayNotification = useCallback(
		async (projectId: number, status: TBackgroundTaskStatus) => {
			if (status === "Completed") {
				dispatch(clearProjectCandidateAssessmentProcess());

				toast.success(ToastContent, {
					autoClose: false,
					closeOnClick: false, // because close option available on the notification
					data: {
						title: `Candidate Assessment for project is ready`,
						description: (closeToast: () => void, linkColor: any) => (
							<>
								You can check the report by{" "}
								<span
									className="toast-link"
									style={{color: linkColor}}
									onClick={() => {
										window.open(`/project/${projectId}`);
										closeToast();
									}}
								>
									clicking here.
								</span>
							</>
						),
					},
				});
			} else if (status === "Error") {
				toast.info(ToastContent, {
					autoClose: false,
					closeOnClick: true,
					data: {
						title: "Something went wrong while assessing candidates!",
					},
				});

				dispatch(clearProjectCandidateAssessmentProcess());
			}
		},
		[dispatch],
	);

	const isTaskInProgress = (status: TBackgroundTaskStatus) => {
		return status === "Created" || status === "In Progress";
	};

	useEffect(() => {
		if (
			activeLastCandidateAssessmentForProject &&
			activeLastCandidateAssessmentForProject.type === "Candidate Assessment"
		) {
			if (activeLastCandidateAssessmentForProject.status === "Completed") {
				dispatch(clearProjectCandidateAssessmentProcess());
			} else {
				const inProgress = isTaskInProgress(activeLastCandidateAssessmentForProject.status);

				if (inProgress) {
					intervalId[activeLastCandidateAssessmentForProject.id] = setInterval(
						async () => {
							try {
								const response = await dispatch(
									checkCandidateAssessmentStatus(
										activeLastCandidateAssessmentForProject.id,
									),
								);

								await displayNotification(
									activeLastCandidateAssessmentForProject.project_id,
									response?.status,
								);

								if (response?.status === "Completed") {
									clearInterval(
										intervalId[activeLastCandidateAssessmentForProject.id],
									);
								}
							} catch (error) {
								console.error("Error checking candidate assessment status:", error);
							}
						},
						8 * 1000,
					);

					// Clear interval after 60 minutes
					setTimeout(() => {
						clearInterval(intervalId[activeLastCandidateAssessmentForProject.id]);
					}, 60 * 60 * 1000); // 60 minutes
				}
			}
		}

		return () => {
			for (const key of Object.keys(intervalId)) {
				clearInterval(intervalId[key]);
			}
		};
	}, [activeLastCandidateAssessmentForProject, dispatch, displayNotification]);
};

export default useProjectCandidateScoringProgressWatcher;
