import React, {useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";
import {DiversitySelectionFilter} from "mapx-components";
import {
	apCandidateEthnicitySelector,
	apCandidateGenderSelector,
} from "store/mapx/additional-profiles/additionalProfilesSelectors";
import {setAPMultipleFilters} from "store/mapx/additional-profiles/additionalProfilesActions";

function DiversityFilterForAP({setFilterParam}) {
	const dispatch = useDispatch();

	const genderDiversityImportance = useSelector(apCandidateGenderSelector);

	const ethnicDiversityImportance = useSelector(apCandidateEthnicitySelector);

	const handleResetClick = useCallback(() => {
		dispatch(
			setAPMultipleFilters({
				gender_diversity_importance: null,
				ethnic_diversity_importance: null,
			}),
		);
	}, [dispatch]);

	return (
		<DiversitySelectionFilter
			setFilterParam={setFilterParam}
			ethnicDiversityImportance={ethnicDiversityImportance}
			genderDiversityImportance={genderDiversityImportance}
			handleResetClick={handleResetClick}
		/>
	);
}

export default DiversityFilterForAP;
