import {useCallback, useRef, useState} from "react";
import classNames from "classnames";

import {ChevronIcon} from "assets/icons";
import {useOutsideClick} from "hooks";
import {Tooltip} from "components";

import styles from "./SelectWithTooltip.module.scss";
import type {TSelectWithTooltipOptions, ISelectWithActionProps} from "./types";

const SelectWithTooltip = ({className, options, placeholder, icon}: ISelectWithActionProps) => {
	const ref = useRef<HTMLDivElement>(null);

	const expandedPointer = useRef<boolean>(false);

	const [expanded, setExpanded] = useState<boolean>(false);

	const toggleExpand = useCallback(() => {
		expandedPointer.current = !expanded;

		setExpanded(!expanded);
	}, [expanded]);

	useOutsideClick(ref, () => setExpanded(false));

	return (
		<div className={classNames(styles.container, {[styles.expanded]: expanded}, className)}>
			<div
				ref={ref}
				role="select"
				className={classNames(styles.select, {[styles.expanded]: expanded})}
			>
				<div
					className={classNames(styles.textbox, {
						[styles.expanded]: expanded,
					})}
					suppressContentEditableWarning
					onClick={toggleExpand}
				>
					{icon}
					{placeholder}
					<Tooltip
						showArrow
						placement="bottom-end"
						open={expanded}
						allowInteraction
						crossAxis={-4}
					>
						<Tooltip.Trigger asChild>
							<span className={styles.chevronContainer}>
								<ChevronIcon
									className={classNames(styles.chevron, {
										[styles.rotated]: expanded,
									})}
								/>
							</span>
						</Tooltip.Trigger>
						<Tooltip.Content>
							{options.map((option: TSelectWithTooltipOptions) => (
								<div
									role="button"
									key={option.label}
									className={styles.option}
									onClick={(e) => {
										e.stopPropagation();
										option.action();
									}}
								>
									{option.icon}
									{option.label}
								</div>
							))}
						</Tooltip.Content>
					</Tooltip>
				</div>
			</div>
		</div>
	);
};

export default SelectWithTooltip;
