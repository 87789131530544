import React, {useCallback, useRef, useState} from "react";
import styles from "./styles.module.scss";
import {BiSearch} from "react-icons/bi";
import {GeneralButton, SearchAutogrowInput} from "mapx-components";
import {PlusIcon} from "assets/icons";
import {getSearchedCompaniesDataByDescription} from "store/mapx/filter/CompanySearchPageFilters/CompanyFilterAsyncActions";
import {useAppDispatch} from "hooks";

const CompanySearchByDescription = () => {
	const dispatch = useAppDispatch();

	const [loading, setLoading] = useState<boolean>(false);

	const [companyDescription, setCompanyDescription] = useState<string>("");

	const searchRef = useRef(null);

	const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		const value = e.target.value;
		setCompanyDescription(value);
	};

	const handleKeyboardEvent = (e: React.KeyboardEvent) => {
		if (e.key === "Enter" && !e.shiftKey) {
			// if (handleButtonClick) {
			//     handleButtonClick();
			// }
		} else if (e.key === "Enter" && e.shiftKey) {
			setCompanyDescription((prevText) => prevText + "\n");
		}
	};

	const handleButtonClick = useCallback(async () => {
		setLoading(true);
		await dispatch(getSearchedCompaniesDataByDescription(companyDescription));
		setLoading(false);
	}, [dispatch, companyDescription]);

	return (
		<div ref={searchRef} style={{position: "relative"}}>
			<div className={styles.label}>Search by description</div>

			<SearchAutogrowInput
				id={"searchInput"}
				customWrapperClass={styles.inputContainer}
				customInputClass={styles.inputClass}
				placeholder="Describe a company..."
				value={companyDescription}
				onChange={handleInputChange}
				onKeyDown={handleKeyboardEvent}
				preIcon={<BiSearch size={24} className={styles.searchIcon} color="#808080" />}
				maxHeight={450}
				disabled={loading}
			/>

			{companyDescription?.length > 0 && (
				<GeneralButton
					customClass={styles.button}
					title={""}
					handleClick={handleButtonClick}
					loadingSave={loading}
					icon={<PlusIcon color={"#fff"} />}
				/>
			)}
		</div>
	);
};

export default CompanySearchByDescription;
