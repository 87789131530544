import {components, DropdownIndicatorProps, GroupBase} from "react-select";
import React, {ReactNode} from "react";
import {ChevronIcon} from "assets/icons";

export const DropdownIndicator = <
	Option,
	IsMulti extends boolean,
	Group extends GroupBase<Option>,
>({
	hideIndicator = false,
	children,
	...props
}: {hideIndicator: boolean; children?: ReactNode} & DropdownIndicatorProps<
	Option,
	IsMulti,
	Group
>) => {
	return (
		<div>
			{hideIndicator && ""}
			{!hideIndicator && (
				<components.DropdownIndicator {...props}>
					{children ?? <ChevronIcon style={{transform: "rotateX(180deg)"}} />}
				</components.DropdownIndicator>
			)}
		</div>
	);
};
