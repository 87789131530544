import axios from "axios";

class MapXIpApi {
	async getLocation(config = {}) {
		return await axios.get("https://ipapi.co/json/", config);
	}
}

const mapXIpApi = new MapXIpApi();

export default mapXIpApi;
