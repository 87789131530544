import type {FC} from "react";
import {useState, useEffect} from "react";
import classNames from "classnames";
import usePortal from "react-useportal";

import {ArrowUp} from "assets/icons";
import {useScrollDeduct} from "hooks";

import Button from "../Button";

import styles from "./ScrollToTop.module.scss";

const ScrollToTop: FC = () => {
	const {Portal} = usePortal();

	const deductScroll = useScrollDeduct();

	const [showLoader, setShowLoader] = useState<boolean>(false);

	const clickToTopHandler = () => {
		setShowLoader(true);
		window.scrollTo({top: 0, left: 0, behavior: "smooth"});
	};

	const pageHeight = window.innerHeight;
	const isRender = deductScroll > Number(pageHeight) - 70;

	useEffect(() => setShowLoader(false), [isRender]);

	const isRenderScrollingBtn = deductScroll !== 0 && (
		<Portal>
			<div className={styles.container}>
				<Button
					defaultBtnStyle
					loaderType="Oval"
					loading={showLoader}
					onClick={clickToTopHandler}
					className={classNames(styles.container__button, {
						[styles.container__button__animation]: isRender && !showLoader,
					})}
				>
					<ArrowUp stroke="#fff" />
				</Button>
			</div>
		</Portal>
	);

	return <>{isRenderScrollingBtn}</>;
};

export default ScrollToTop;
