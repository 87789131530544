import type {FC} from "react";

import type {IconProps} from "./types";

const PlusSquareFilled: FC<IconProps> = (props) => (
	<svg
		width={25}
		height={24}
		viewBox="0 0 25 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<mask
			id="prefix__a"
			style={{
				maskType: "alpha",
			}}
			maskUnits="userSpaceOnUse"
			x={0}
			y={0}
			width={25}
			height={24}
		>
			<path fill="#D9D9D9" d="M.333 0h24v24h-24z" />
		</mask>
		<g mask="url(#prefix__a)">
			<path
				d="M7.333 13h10v-2h-10v2zm-2 8c-.55 0-1.02-.196-1.412-.587A1.926 1.926 0 013.333 19V5c0-.55.196-1.02.588-1.413A1.926 1.926 0 015.333 3h14c.55 0 1.02.196 1.413.587.391.392.587.863.587 1.413v14c0 .55-.196 1.02-.587 1.413a1.926 1.926 0 01-1.413.587h-14z"
				fill="#0C5850"
			/>
		</g>
	</svg>
);

export default PlusSquareFilled;
