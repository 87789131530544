import React, {useEffect, useState} from "react";

import css from "./checkBox.module.scss";
import {labelCapitalized} from "helpers/string";
import classNames from "classnames";

// @TODO REMOVE THIS COMPONENT AS ITS DEPRECATED, WE HAVE NEW COMPONENT OF CHECKBOX.
// @TODO CHECK ITS USAGE AND REPLACE WITH THE NEW COMPONENT FROM /components/inputs/

export default function Checkbox({
	name,
	item,
	prefix,
	setFilterParam,
	isChecked = false,
	partiallySelected = "",
}) {
	const [checked, setChecked] = useState(isChecked);

	useEffect(() => setChecked(isChecked), [isChecked]);

	const onCheckboxChange = (ev, id) => {
		ev.preventDefault();
		const checkValue = !checked;
		let data = {id, value: false};

		if (checkValue) {
			data = {...data, value: true};
		}

		setChecked(checkValue);
		setFilterParam(data);
	};

	const formatSectionTitle = (text) => {
		return text.substring(0, 33).replaceAll('"', "");
	};

	const getName = (name, limited = false) => {
		if (typeof name !== "string") return "";

		name =
			name === name.toUpperCase || name === name.toLowerCase ? labelCapitalized(name) : name;
		name = name.replaceAll("It", "IT").replaceAll("Crm", "CRM").replaceAll("Ai", "AI");

		if (limited && name.length > 35) {
			return formatSectionTitle(name) + "...";
		} else {
			return name;
		}
	};

	return (
		<div className={css.checkbox} onClick={(ev) => onCheckboxChange(ev, item.id)}>
			<label className={css.container}>
				<input
					type="checkbox"
					value={getName(item.name)}
					name={name}
					onChange={(ev) => onCheckboxChange(ev, item.id)}
					// className={css.checkboxInput}
					checked={checked}
				/>
				<span
					className={classNames(css.checkmark, {
						[css.partiallySelected]: partiallySelected,
					})}
					style={{border: partiallySelected ? "2px solid #0C5850" : "1px solid #808080"}}
					title={getName(item.name)}
				/>
			</label>

			<span title={getName(item.name)} className={css.checkboxLabel}>
				{prefix ? <span className={css.prefix}>{prefix}&nbsp;</span> : null}
				{getName(item.name, true)}
			</span>
		</div>
	);
}
