import type {FC} from "react";

import type {IconProps} from "./types";

const ResendIcon: FC<IconProps> = ({className = ""}) => (
	<svg
		width="24"
		height="24"
		fill="none"
		viewBox="0 0 24 24"
		className={className}
		xmlns="http://www.w3.org/2000/svg"
	>
		<mask
			x="0"
			y="0"
			width="24"
			height="24"
			id="mask0_4842_23764"
			maskUnits="userSpaceOnUse"
			style={{maskType: "alpha"}}
		>
			<rect width="24" height="24" fill="#D9D9D9" />
		</mask>
		<g mask="url(#mask0_4842_23764)">
			<path
				fill="#404040"
				d="M12 13L4 8V18H13V20H4C3.45 20 2.97917 19.8042 2.5875 19.4125C2.19583 19.0208 2 18.55 2 18V6C2 5.45 2.19583 4.97917 2.5875 4.5875C2.97917 4.19583 3.45 4 4 4H20C20.55 4 21.0208 4.19583 21.4125 4.5875C21.8042 4.97917 22 5.45 22 6V13H20V8L12 13ZM12 11L20 6H4L12 11ZM19 23L17.6 21.6L19.175 20H15V18H19.175L17.575 16.4L19 15L23 19L19 23ZM4 8V19V13V13.075V6V8Z"
			/>
		</g>
	</svg>
);

export default ResendIcon;
