/**
 * PROBLEM
 *
 * SEARCHING THROUGH ALL THE NODES OF ARRAY OF OBJECT,
 * Objective is to have all the child ids on the parent object, and all the parent id from children
 *
 * TEST CASE INCLUDED WITH SAMPLE
 *
 */

import {Item} from "./interfaces";

export const getAllChildIds = (item: Item): number[] => {
	let childIds: number[] = [];

	if (item.children && item.children.length > 0) {
		item.children.forEach((child) => {
			childIds.push(child.id);
			childIds = [...childIds, ...getAllChildIds(child)];
		});
	}

	return childIds;
};

export const getAllParentIdsFromChildren = (item: Item): number[] => {
	let parentIds: number[] = [];

	if (item.children && item.children.length > 0) {
		item.children.forEach((child) => {
			if (child.children && child.children.length > 0) {
				parentIds.push(child.id);
			}
			parentIds = [...parentIds, ...getAllParentIdsFromChildren(child).flat()];
		});
	}

	if (parentIds.length > 0) {
		parentIds = [...new Set(parentIds)]; // Remove duplicate IDs
	}

	return parentIds;
};

export const attachAllChildAndParentIdsOnObjectTreeOfArray = (data: Item[]): Item[] => {
	const traverseTree = (item: Item): void => {
		item.all_child_ids = getAllChildIds(item);
		item.all_parent_ids_from_children = getAllParentIdsFromChildren(item);

		if (item.children && item.children.length > 0) {
			item.children.forEach((child) => {
				traverseTree(child);
			});
		}
	};

	data.forEach((item) => {
		traverseTree(item);
	});

	return data;
};

export const flattenByChildren = (data: []) => {
	const flatArray: Item[] = [];

	const flatten = (item: Item) => {
		flatArray.push(item);

		if (item.children && item.children.length > 0) {
			item.children.forEach((child) => {
				flatten(child);
			});
		}
	};

	data.forEach((item) => {
		flatten(item);
	});

	return flatArray;
};
