import type {FC} from "react";

import type {IconProps} from "./types";

const OnboardingTooltipArrowIcon: FC<IconProps> = ({className = ""}) => (
	<svg
		width="11"
		height="9"
		fill="none"
		viewBox="0 0 11 9"
		className={className}
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fill="#2C3030"
			fillRule="evenodd"
			clipRule="evenodd"
			fillOpacity="0.95"
			d="M7.26089 1.05246C6.50595 -0.350819 4.49323 -0.350819 3.7383 1.05246L0 8.00121L10.9992 8.00121L7.26089 1.05246Z"
		/>
	</svg>
);

export default OnboardingTooltipArrowIcon;
