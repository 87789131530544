import React from "react";
import {TModalFormHeaderInformationProps} from "mapx-components/Modals/ModalFormHeaderInformation/types";
import styles from "mapx-components/Modals/ModalFormHeaderInformation/styles.module.scss";

const ModalFormHeaderInformation = ({
	title,
	description,
	icon,
}: TModalFormHeaderInformationProps) => {
	return (
		<div className={styles.header}>
			<div className={styles.icon}>{icon}</div>
			<div className={styles.info}>
				<div className={styles.title}>{title}</div>
				<div className={styles.description}>{description}</div>
			</div>
		</div>
	);
};

export default ModalFormHeaderInformation;
