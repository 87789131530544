export const chartStyle = [
	{
		id: 0,
		title: "On Average",
	},
	{
		id: 1,
		title: "By Frequency",
	},
];
