import React from "react";
import ContentLoader from "react-content-loader";
import styles from "mapx-pages/Home/ProjectList/styles.module.scss";

const ProjectSkeletonLoader = ({count = 3}) => {
	return (
		<div
			className={styles.skeletonLoaders}
			data-testid="project-item-skeleton"
			data-tooltip={"Loading" + " projects..."}
		>
			{Array.from({length: count}, (_, idx) => (
				<div className={styles.card} key={idx}>
					<ContentLoader
						speed={2}
						height={`100%`}
						width={"100%"}
						backgroundColor="#DCE7E7"
						foregroundColor="#F9F9F9"
					>
						<rect x="1%" y="1" rx="1" ry="1" width="100%" height="15" />
						<rect x="1%" y="26" rx="1" ry="1" width="85%" height="15" />
						<rect x="1%" y="51" rx="1" ry="1" width="75%" height="15" />

						<rect x="1%" y="90" rx="1" ry="1" width="25%" height="15" />
						<rect x="76%" y="90" rx="1" ry="1" width="25%" height="15" />

						<rect x="1%" y="125" rx="1" ry="1" width="125%" height="1" />

						<rect x="1%" y="140" rx="1" ry="1" width="35%" height="17" />
						<rect x="46%" y="147" rx="1" ry="1" width="55%" height="4" />
					</ContentLoader>
				</div>
			))}
		</div>
	);
};

export default ProjectSkeletonLoader;
