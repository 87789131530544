import {FC, Fragment} from "react";
import classNames from "classnames";

import {colors} from "./utils";
import type {IChartDataProps, TChartListProps} from "./types";
import styles from "./PieChart.module.scss";

const ChartList: FC<TChartListProps> = ({data, changeActive}) => {
	const dataLength = data.length;

	const renderChartDataList = data.map((chartItem: IChartDataProps, idx: number) => {
		const label = chartItem?.name;
		const percent = chartItem?.value;
		const color = colors[idx];

		if (!label) {
			return null;
		}

		return (
			<div
				key={`${color}_${idx}`}
				className={classNames(styles.container__wrapper__list_item, {
					[styles.container__wrapper__list_item_scroll]: dataLength > 6,
				})}
			>
				{label && (
					<div
						role="button"
						onClick={() =>
							!!chartItem.children?.length && changeActive(chartItem?.id, idx)
						}
						className={styles.container__wrapper__list_item_left}
					>
						<div
							className={classNames(
								styles.container__wrapper__list_item_left_content,
								{
									[styles.container__wrapper__list_item_left_content_default]:
										!chartItem?.id || !chartItem.children?.length,
								},
							)}
						>
							<div
								style={{
									backgroundColor: color,
								}}
								className={styles.container__wrapper__list_item_left_dot}
							/>

							<span className={styles.container__wrapper__list_item_left_label}>
								{label}
							</span>
						</div>
						<span className={styles.container__wrapper__list_item_right}>
							{percent}%
						</span>
					</div>
				)}
			</div>
		);
	});

	return <Fragment>{renderChartDataList}</Fragment>;
};

export default ChartList;
