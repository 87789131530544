import {useCallback} from "react";
import {Accordion} from "mapx-components";
import {setMultipleFilterForCompany} from "store/mapx/filter/filterActions";
import {useAppDispatch, useAppSelector} from "hooks";
import {setCompanyHeadcountRangeForCompany} from "store/mapx/filter/filterAsyncActions";
import {companySizesSelector} from "store/mapx/filter/filterSelectors";
import {TCompanyHeadcountRangeItem} from "mapx-components/Filters/CompanyHeadcountSearchFilter/types";
import CompanyHeadcountSearchFilter from "mapx-components/Filters/CompanyHeadcountSearchFilter";

const HeadcountFilter = () => {
	const dispatch = useAppDispatch();

	const selectedSizes = useAppSelector(companySizesSelector);

	const handleResetClick = useCallback(() => {
		dispatch(
			setMultipleFilterForCompany({
				size: [],
			}),
		);
	}, [dispatch]);

	const handleOnChange = useCallback(
		(item: TCompanyHeadcountRangeItem) => {
			dispatch(setCompanyHeadcountRangeForCompany(item));
		},
		[dispatch],
	);

	return (
		<Accordion title="Headcount" subtitle={selectedSizes?.length ?? ""}>
			<CompanyHeadcountSearchFilter
				handleOnChange={handleOnChange}
				handleResetClick={handleResetClick}
				selectedHeadcounts={selectedSizes}
			/>
		</Accordion>
	);
};

export default HeadcountFilter;
