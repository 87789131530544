import placeHolderImage from "assets/images/avatar-icon.png";
import classNames from "classnames";
import styles from "mapx-pages/CompanyDetails/OrganisationalChart/Card/index.module.scss";
import React, {useState} from "react";
import {Link, useParams} from "react-router-dom";

export const ChartCard = ({name, positions, picture, id, newlyMapped = false, onClick}) => {
	const [expanded, setExpanded] = useState(false);
	const morePositions = positions?.length - 1;

	const params = useParams();

	const onToggle = (ev) => {
		ev.preventDefault();
		setExpanded((prev) => !prev);
	};

	return (
		<Link
			to={{
				pathname: `/company/${params.id}/${id}?src=Quick View`,
			}}
			onClick={onClick}
		>
			<div
				className={classNames(styles.chartCardContainer, {
					[styles.expanded]: expanded,
					[styles.newlyMapped]: newlyMapped,
				})}
			>
				<img
					alt={name}
					src={picture ?? "#"}
					onError={(e) => {
						e.currentTarget.src = placeHolderImage;
					}}
					className={styles.logo}
				/>
				<div className={styles.about}>
					<span className={styles.name} title={name}>
						{name}
					</span>
					{!morePositions ? (
						<span className={styles.position} title={positions[0]?.job_title}>
							{positions[0]?.job_title}
						</span>
					) : null}

					<div className={styles.positions}>
						{morePositions
							? positions.map((val, idx) => {
									if (!expanded && idx > 0) {
										return null;
									}

									return (
										<span
											className={styles.position}
											key={idx}
											title={val?.job_title}
										>
											{val?.job_title}
											{!expanded && idx === 0 ? (
												<span className={styles.expand} onClick={onToggle}>
													+{positions.length - 1}
												</span>
											) : null}

											{expanded && idx + 1 === positions.length ? (
												<span className={styles.expand} onClick={onToggle}>
													Hide {positions.length - 1}
												</span>
											) : null}
										</span>
									);
							  })
							: null}
					</div>
				</div>
			</div>
		</Link>
	);
};
