import mapXCandidateApi from "api/candidateApi";
import {toast} from "react-toastify";
import {ToastContent} from "components";
import {
	getCandidatesByFilterDone,
	getCandidatesByFilterFail,
	getCandidatesByFilterInit,
	getCandidatesDone,
	getCandidatesFail,
	getCandidatesInit,
} from "store/mapx/candidate/candidateActions";
import {
	candidateJobTitleFilterModeSelector,
	showEnhancedCandidatesSelector,
} from "store/mapx/filter/filterSelectors";
import {setEnhanceCandidatesResult} from "store/mapx/filter/filterActions";
import {Dispatch} from "react";
import {TAppDispatch, TRootState} from "types";
import {ICandidateSearchApiRequestPayload} from "api/candidateApi/form";
import axios from "axios";

/* eslint-disable  @typescript-eslint/no-explicit-any */
const cancelTokens = {} as any;

const cancelTokenForPeopleSearch = () => {
	const requestKey = `PEOPLE_SEARCH_FETCH`;

	if (requestKey in cancelTokens) {
		cancelTokens[requestKey].cancel("Operation canceled due to new request.");
	}

	cancelTokens[requestKey] = axios.CancelToken.source();

	return {
		cancelToken: cancelTokens[requestKey].token,
	};
};
export const getCandidatesData =
	(pageNumber = 1) =>
	async (dispatch: Dispatch<TAppDispatch>, getState: () => TRootState) => {
		if (pageNumber === 1) {
			dispatch(getCandidatesInit());
		}

		const showEnhancedCandidates = showEnhancedCandidatesSelector(getState());

		if (showEnhancedCandidates) {
			dispatch(setEnhanceCandidatesResult(false));
		}

		let response;

		try {
			// dispatch(setRelevantCandidateKeywords(config));
			const config = cancelTokenForPeopleSearch();

			response = await mapXCandidateApi.getCandidatesForPeopleSearch(pageNumber, config);

			if (response === undefined) {
				return {cancelled: true};
			}

			const payload = {
				data: response.data.results,
				paginationInfo: response.data.pagination,
				pageNumber: pageNumber,
			};

			dispatch(getCandidatesDone(payload));
		} catch (error) {
			dispatch(getCandidatesFail({error}));

			return {error};
		}
	};
export const getFilteredCandidatesForSearch =
	(apiPayload: ICandidateSearchApiRequestPayload, config = {}) =>
	async () => {
		const response = await mapXCandidateApi.getFilteredCandidatesForPeopleSearch(
			apiPayload,
			config,
		);

		const pageNumber = apiPayload.pagination.page;

		if (response === undefined) {
			return {
				data: [],
				paginationInfo: {page: 1, pages: 1, count: 0, per_page: 20},
				pageNumber,
				status: 409,
				cancelled: true,
			};
		} else if (response.status === 400) {
			toast.error(ToastContent, {
				autoClose: false,
				closeOnClick: true,
				data: {title: response.data.detail || "Invalid LinkedIn Url!"},
			});

			return {
				data: [],
				paginationInfo: {page: 1, pages: 1, count: 0, per_page: 20},
				pageNumber: pageNumber,
				status: response.status,
				cancelled: false,
			};
		} else {
			return {
				data: response.data.results,
				paginationInfo: response.data.pagination,
				pageNumber: pageNumber,
				status: response.status,
				cancelled: false,
			};
		}
	};
export const getFilteredCandidatesData =
	(payload: Omit<ICandidateSearchApiRequestPayload, "pagination">, pageNumber: number) =>
	async (dispatch: Dispatch<TAppDispatch>, getState: TRootState) => {
		if (pageNumber === 1) {
			dispatch(getCandidatesByFilterInit());
		}

		const state = getState();

		const jobTitleFilterMode = candidateJobTitleFilterModeSelector(state);

		if (jobTitleFilterMode === "close" && payload.filters) {
			delete payload.filters?.current_job_titles_or;
		}

		const apiPayload = {
			...payload,
			pagination: {
				page: pageNumber,
				per_page: 20,
			},
		};

		try {
			const config = cancelTokenForPeopleSearch();

			// dispatch(setRelevantCandidateKeywords(config));

			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			const responsePayload: Promise<{cancelled: boolean}> = await dispatch(
				getFilteredCandidatesForSearch(apiPayload, config),
			);

			if ("cancelled" in responsePayload && responsePayload["cancelled"]) {
				return responsePayload;
			}

			dispatch(getCandidatesByFilterDone(responsePayload));

			//@todo send seen data information to BE
		} catch (error) {
			dispatch(getCandidatesByFilterFail({error}));

			return {error};
		}
	};
