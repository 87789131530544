import type {TSelectWithActionOptions} from "components/Inputs/SelectWithAction/types";

import type {TOrganizationInviteItemState, TOrganizationItemState} from "./types";

export const initialState: TOrganizationItemState = {
	showPeople: false,
	showSettings: false,
	showCreateUser: false,
	showEditOrganization: false,
	showRemoveOrganization: false,
};

export const initialInvitedState: TOrganizationInviteItemState = {
	showCopyLink: false,
	showSettings: false,
	showResendInvite: false,
	showRevokeInvite: false,
};

export const licTypeInitial: TSelectWithActionOptions = {
	label: null,
	value: null,
};
