import {FC, useMemo} from "react";
import classNames from "classnames";

import {CustomBarChart, PieChart} from "components";
import type {IChartDataProps, TCandidateProps} from "components/Charts/PieChart/types";

import {chartStyle} from "./utils";
import type {TBackgroundAnalysisContainerProps} from "./types";
import styles from "./BackgroundAnalysisContainer.module.scss";
import {orderBy} from "lodash";

const BackgroundAnalysisContainer: FC<TBackgroundAnalysisContainerProps> = ({
	data,
	title,
	isLoading,
	pieChartClass,
	setCurrentOption,
	description = "",
	currentOption = 0,
	isRoundedChartVal,
}) => {
	const sortedData = useMemo(() => {
		if (currentOption === 0) {
			return orderBy(data, "value", "desc");
		} else {
			return orderBy(data, "frequencyValue", "desc");
		}
	}, [currentOption, data]);

	const modifiedData = useMemo(() => {
		if (!!sortedData?.length || sortedData) {
			const updatedSortedData = [
				...sortedData,
				{
					id: -1,
					name: "Other",
					value: 0,
					children: [],
					candidates: [],
					frequencyValue: 0,
					candidateCount: 0,
					totalCandidateCountForSkillAnalysis:
						sortedData?.length > 0
							? sortedData[0].totalCandidateCountForSkillAnalysis
							: 0,
				},
			];

			const {mainData, otherData} = updatedSortedData.reduce(
				(
					acc: {
						mainData: IChartDataProps[];
						otherData: IChartDataProps;
					},
					curVal: IChartDataProps,
				) => {
					if (curVal?.value >= 3 && curVal.name?.toLowerCase() !== "other") {
						acc.mainData.push(curVal);
					} else if (curVal.name?.toLowerCase() === "other") {
						acc.otherData = {
							...acc.otherData,
							candidateCount: acc.otherData.candidateCount + curVal.candidateCount,
							candidates: [...acc.otherData.candidates, ...curVal.candidates],
							frequencyValue: acc.otherData.frequencyValue + curVal.frequencyValue,
							name: "Other",
							value: Number((acc.otherData.value + curVal.value).toFixed(1)),
							totalCandidateCountForSkillAnalysis:
								curVal.totalCandidateCountForSkillAnalysis,
						};
					} else {
						acc.otherData.value = Number(
							(acc.otherData.value + curVal.value).toFixed(1),
						);

						const isCandidateIncluded = acc.otherData.candidates.some(
							(candidate: TCandidateProps) =>
								curVal.candidates.some(
									(curValCandidate: TCandidateProps) =>
										candidate.id === curValCandidate.id,
								),
						);

						if (!isCandidateIncluded) {
							acc.otherData.frequencyValue += curVal.frequencyValue;
							acc.otherData.candidateCount += curVal.candidateCount;
							acc.otherData.candidates = [
								...acc.otherData.candidates,
								...curVal.candidates,
							];
						}

						if (curVal.children && acc.otherData.children) {
							acc.otherData.children = [
								...acc.otherData.children,
								...curVal.children,
							];
						}
					}

					return acc;
				},
				{
					mainData: [],
					otherData: {
						id: -1,
						name: "Other",
						value: 0,
						children: [],
						candidates: [],
						frequencyValue: 0,
						candidateCount: 0,
						totalCandidateCountForSkillAnalysis: 0,
					},
				},
			);

			const finalData = !otherData?.value ? sortedData : mainData?.concat(otherData);

			return !sortedData.length ? [] : finalData;
		}

		return [];
	}, [sortedData]);

	const changeCurrentOption = (id: number) => {
		setCurrentOption(id);
	};

	const renderItem = chartStyle.map(({id, title}) => (
		<div
			key={id}
			onClick={() => changeCurrentOption(id)}
			className={classNames(styles.container__header_select_item, {
				[styles.container__header_select_item_active]: id === currentOption,
			})}
		>
			{title}
		</div>
	));

	if (!modifiedData?.length) return null;

	return (
		<div className={styles.container}>
			<div className={styles.container__header}>
				<p className={styles.container__header_title}>{title}</p>

				{!!modifiedData?.length && (
					<div className={styles.container__header_select}>{renderItem}</div>
				)}
			</div>

			{!!modifiedData?.length && (
				<p className={styles.container_description}>{description}</p>
			)}

			{!currentOption ? (
				<PieChart pieChartClass={pieChartClass} data={modifiedData} isLoading={isLoading} />
			) : (
				<CustomBarChart isRoundedChartVal={isRoundedChartVal} data={modifiedData} />
			)}
		</div>
	);
};

export default BackgroundAnalysisContainer;
