import {useCallback} from "react";
import {useSelector} from "react-redux";
import {Accordion} from "mapx-components";
import {setFilterForCandidates} from "store/mapx/filter/filterActions";
import {candidateLanguagesSelector} from "store/mapx/filter/filterSelectors";
import {setLanguagesForCandidates} from "store/mapx/filter/filterAsyncActions";
import LanguageSearchFilter from "mapx-components/Filters/LanguageSearchFilter";
import {useAppDispatch} from "hooks";

function LanguageFilter() {
	const dispatch = useAppDispatch();

	const selectedLanguages = useSelector(candidateLanguagesSelector);

	const handleChange = useCallback(
		(name: string) => {
			dispatch(setLanguagesForCandidates({name}));
		},
		[dispatch],
	);

	const handleReset = useCallback(() => {
		dispatch(setFilterForCandidates({type: "languages", values: []}));
	}, [dispatch]);

	return (
		<Accordion title="Language" subtitle={selectedLanguages?.length}>
			<LanguageSearchFilter
				handleOnChange={handleChange}
				handleResetClick={handleReset}
				selectedLanguages={selectedLanguages}
			/>
		</Accordion>
	);
}

export default LanguageFilter;
