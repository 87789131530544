import {removeDuplicatesFromArray} from "helpers/filterHandlers";
import {createSelector} from "reselect";
import {allKeywordLabelSelector} from "../filter/keywordFilterSelectors";

export const candidatesInProgressSelector = (state) => state.candidate.candidatesInProgress;
export const filteredCandidatesInProgressSelector = (state) =>
	state.candidate.filteredCandidatesInProgress;
export const currentCandidateSelector = (state) => state.candidate.currentCandidate;

export const similarCandidatesInProgressSelector = (state) =>
	state.candidate.similarCandidatesInProgress;

export const candidatesSelector = (state) => state.candidate.candidates || [];

export const candidatesPaginationInfoSelector = (state) => state.candidate.paginationInfo;

export const similarCandidatesSelector = (state) => state.candidate.similarCandidates;

export const similarCandidatesPaginationInfoSelector = (state) =>
	state.candidate.similarCandidatesPaginationInfo;

export const importLinkedInCandidateInProgressSelector = (state) =>
	state.candidate.importLinkedInCandidateInProgress;

export const importLinkedInProfileInfoSelector = (state) =>
	state.candidate.importLinkedInProfileInfo;

export const searchCandidateForProjectSelector = (state) =>
	state.candidate.searchCandidateForProject;

export const candidateImportByLinkedInForProjectSelector = (state) =>
	state.candidate.candidateImportByLinkedInForProject;

export const isModalBeingDisplayedForProject = (state) =>
	state.candidate.activeAddPeopleModalForProject;

export const projectIdSelector = (_, projectId) => projectId;

export const isLinkedInProfileImportIsInProgress = createSelector(
	[importLinkedInProfileInfoSelector],
	(importLinkedInProfileInfo) =>
		importLinkedInProfileInfo !== null && importLinkedInProfileInfo?.status !== undefined,
);

export const getCandidateSearchInProgressByProjectId = createSelector(
	[searchCandidateForProjectSelector, projectIdSelector],
	(searchCandidateForProject, projectId) => {
		return searchCandidateForProject &&
			projectId &&
			searchCandidateForProject[projectId] !== undefined
			? searchCandidateForProject[projectId]
			: false;
	},
);

export const getCandidateImportInfoByProjectId = createSelector(
	[candidateImportByLinkedInForProjectSelector, projectIdSelector],
	(candidateImportByLinkedInForProject, projectId) => {
		return candidateImportByLinkedInForProject &&
			projectId &&
			candidateImportByLinkedInForProject[projectId] !== undefined
			? candidateImportByLinkedInForProject[projectId]
			: null;
	},
);

export const isSelectedProjectAddPeopleModalActive = createSelector(
	[isModalBeingDisplayedForProject, projectIdSelector],
	(activeModal, projectId) => {
		return activeModal === projectId;
	},
);

export const candidateSelectedKeywordLabelsSelector = createSelector(
	[allKeywordLabelSelector],
	(selectedKeyword) => {
		return removeDuplicatesFromArray([...selectedKeyword]);
	},
);
