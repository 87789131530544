import type {DragEvent} from "react";
import {useCallback, useState} from "react";
import type {FilterTagsProps} from "./types";

import css from "./filterTags.module.scss";
import {ResetFilters, TabSelect, Tag} from "components";
import classNames from "classnames";

function FilterTags({
	label,
	count,
	tags,
	onDrop,
	connectivityOptions,
	handleConnectivityOptionChange,
	selectedConnectivityLogic,
	moveDisabled,
	onClear,
	...rest
}: FilterTagsProps) {
	// Whether tags will be shown more than 3
	const [tagsExpanded, setTagsExpanded] = useState(false);

	// TODO: Use the first value (dragOver) to highlight the
	// 		 draggable area when we have a UI design for it.
	const [dragOver, setDragOver] = useState(false);

	const handleDragOver = useCallback((e: DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		setDragOver(true);
	}, []);

	const handleDragLeave = useCallback((e: DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		setDragOver(false);
	}, []);

	const handleDrop = useCallback(
		(e: DragEvent<HTMLDivElement>) => {
			if (onDrop) {
				onDrop(e);
			}
			setDragOver(false);
		},
		[onDrop],
	);

	const handleClearClick = () => {
		if (onClear) {
			onClear();
		}
	};

	return (
		<div
			className={classNames(css.container, {[css.dragOver]: dragOver})}
			onDragOver={handleDragOver}
			onDragLeave={handleDragLeave}
			onDrop={handleDrop}
			draggable={!moveDisabled}
			{...rest}
		>
			<div className={css.header}>
				<div className={css.left}>
					{label !== "" && label}
					{label !== "" && count > 0 && <span className={css.count}>{count}</span>}
					{onClear !== undefined && count > 0 && (
						<ResetFilters
							parentStyle={{color: "#5A5A5A", marginLeft: 10, marginBottom: 0}}
							onClick={handleClearClick}
							displayIcon={true}
						>
							Clear Tags
						</ResetFilters>
					)}
				</div>

				{connectivityOptions &&
					handleConnectivityOptionChange &&
					Array.isArray(connectivityOptions) && (
						<div className={css.right}>
							<TabSelect.LabelContainer style={{margin: 0}}>
								<TabSelect
									selected={selectedConnectivityLogic}
									onTabChange={handleConnectivityOptionChange}
									options={connectivityOptions}
								/>
							</TabSelect.LabelContainer>
						</div>
					)}
			</div>

			<div className={css.tags}>
				{tagsExpanded ? (
					tags
				) : (
					<>
						{tags.slice(0, 5)}
						{tags.length - 5 > 0 && (
							<Tag closable={false} onClick={() => setTagsExpanded(!tagsExpanded)}>
								{`+${tags.length - 5}`}
							</Tag>
						)}
					</>
				)}
			</div>
			{/* )} */}
		</div>
	);
}

export default FilterTags;
