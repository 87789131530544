import {type FC, useEffect} from "react";
import {useHistory, useLocation} from "react-router-dom";

import {register} from "mapx-constants";
import {AuthView, Loader} from "components";
import colors from "styles/themes.module.scss";
import {useAppDispatch, useAppSelector, useOnAuth} from "hooks";
import {verifyVerificationCode} from "store/mapx/user/userAsyncAction";
import {verificationCodeInProgressSelector} from "store/mapx/user/userSelectors";

import styles from "./index.module.scss";

const Register: FC = () => {
	const history = useHistory();
	const {search} = useLocation();
	const dispatch = useAppDispatch();

	const verificationCode = new URLSearchParams(search).get("verification_code");
	// const paymentType = new URLSearchParams(search).get("payment_type");
	// const paymentInterval = new URLSearchParams(search).get("payment_interval");

	const verifying = useAppSelector(verificationCodeInProgressSelector);

	const {loading, error, onSubmit} = useOnAuth({
		history,
		type: "register",
		redirectUrl: "/home",
		// paymentType === "plus"
		// 	? `/onboarding/home?payment_type=${paymentType}&payment_interval=${paymentInterval}`
		// 	: "/onboarding/home",
		registerVerificationCode: verificationCode as string,
		themeColor: colors.loaderDotColor,
	});

	const isError = error !== null ? String(error) : "";

	useEffect(() => {
		if (!verificationCode) {
			history.push("/login");
		} else {
			dispatch(verifyVerificationCode(verificationCode));
		}
	}, [verificationCode]); // eslint-disable-line react-hooks/exhaustive-deps

	return verifying ? (
		<div className={styles.loaderContainer}>
			<Loader height={80} width={80} type="Rings" color={colors.loaderDotColor} />
		</div>
	) : (
		<AuthView
			to="/register"
			form={register}
			error={isError}
			showErrorTooltip
			loading={loading}
			onSubmit={onSubmit}
			title="Last Step"
			submitText="Get Started!"
			rightLabel="Forgot Password?"
			description="A few more details and you'll be ready to start searching"
			coverDescription="MapX uses AI to speed up Executive talent ID, find new sources of talent and generate insights into competitors and talent pools."
		/>
	);
};

export default Register;
