import {FC} from "react";
import {IconProps} from "./types";

const IconUsers: FC<IconProps> = ({color = "#808080", ...rest}) => {
	return (
		<svg
			width="19"
			height="18"
			viewBox="0 0 19 18"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...rest}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M13.0025 9.84747C14.03 10.545 14.75 11.49 14.75 12.75V15H17.75V12.75C17.75 11.115 15.0725 10.1475 13.0025 9.84747Z"
				fill={color}
			/>
			<path
				d="M7.25 9C8.90685 9 10.25 7.65685 10.25 6C10.25 4.34315 8.90685 3 7.25 3C5.59315 3 4.25 4.34315 4.25 6C4.25 7.65685 5.59315 9 7.25 9Z"
				fill={color}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M11.75 9C13.4075 9 14.75 7.6575 14.75 6C14.75 4.3425 13.4075 3 11.75 3C11.3975 3 11.0675 3.075 10.7525 3.18C11.375 3.9525 11.75 4.935 11.75 6C11.75 7.065 11.375 8.0475 10.7525 8.82C11.0675 8.925 11.3975 9 11.75 9Z"
				fill={color}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M7.25 9.75C5.2475 9.75 1.25 10.755 1.25 12.75V15H13.25V12.75C13.25 10.755 9.2525 9.75 7.25 9.75Z"
				fill={color}
			/>
		</svg>
	);
};

export default IconUsers;
