import type {FC} from "react";

import type {IconProps} from "./types";

const ChangeApprovedIcon: FC<IconProps> = ({className = ""}) => (
	<svg
		width="64"
		height="64"
		fill="none"
		viewBox="0 0 64 64"
		className={className}
		xmlns="http://www.w3.org/2000/svg"
	>
		<mask id="mask0_2365_43636" maskUnits="userSpaceOnUse" x="0" y="0" width="64" height="64">
			<rect width="64" height="64" fill="#D9D9D9" />
		</mask>
		<g mask="url(#mask0_2365_43636)">
			<path
				fill="#3DAC57"
				d="M28.0667 43.2667L46.0667 25.2667L43.7334 23L28.0667 38.6667L20.2001 30.7333L17.9334 33.0667L28.0667 43.2667ZM32.0001 57.3333C28.5334 57.3333 25.2556 56.6667 22.1667 55.3333C19.0779 54 16.389 52.1889 14.1001 49.9C11.8112 47.6111 10.0001 44.9222 8.66675 41.8333C7.33341 38.7444 6.66675 35.4667 6.66675 32C6.66675 28.4889 7.33341 25.1889 8.66675 22.1C10.0001 19.0111 11.8112 16.3333 14.1001 14.0667C16.389 11.8 19.0779 9.99999 22.1667 8.66666C25.2556 7.33332 28.5334 6.66666 32.0001 6.66666C35.5112 6.66666 38.8112 7.33332 41.9001 8.66666C44.989 9.99999 47.6668 11.8 49.9334 14.0667C52.2001 16.3333 54.0001 19.0111 55.3334 22.1C56.6667 25.1889 57.3334 28.4889 57.3334 32C57.3334 35.4667 56.6667 38.7444 55.3334 41.8333C54.0001 44.9222 52.2001 47.6111 49.9334 49.9C47.6668 52.1889 44.989 54 41.9001 55.3333C38.8112 56.6667 35.5112 57.3333 32.0001 57.3333ZM32.0001 54.3333C38.2223 54.3333 43.5001 52.1555 47.8334 47.8C52.1667 43.4444 54.3334 38.1778 54.3334 32C54.3334 25.7778 52.1667 20.5 47.8334 16.1667C43.5001 11.8333 38.2223 9.66666 32.0001 9.66666C25.8223 9.66666 20.5556 11.8333 16.2001 16.1667C11.8445 20.5 9.66675 25.7778 9.66675 32C9.66675 38.1778 11.8445 43.4444 16.2001 47.8C20.5556 52.1555 25.8223 54.3333 32.0001 54.3333Z"
			/>
		</g>
	</svg>
);

export default ChangeApprovedIcon;
