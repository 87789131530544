import type {AnyAction} from "redux";

import {
	CREATE_LEARN_TO_RANK_DONE,
	CREATE_LEARN_TO_RANK_FAIL,
	CREATE_LEARN_TO_RANK_INIT,
	GET_LEARN_TO_RANK_DONE,
	GET_LEARN_TO_RANK_FAIL,
	GET_LEARN_TO_RANK_INIT,
	RECORD_LEARN_TO_RANK_DONE,
	RECORD_LEARN_TO_RANK_FAIL,
	RECORD_LEARN_TO_RANK_INIT,
} from "../mapXActionTypes";

export const initialState = {
	count: 0,
	create_in_progress: false,
	data: null,
	getting_in_progress: false,
	last_record_at: null,
	record_in_progress: false,
	tasks: null,
};

const learnToRankReducer = (state = initialState, action: AnyAction) => {
	switch (action.type) {
		case GET_LEARN_TO_RANK_INIT:
			return {
				...state,
				getting_in_progress: true,
			};
		case GET_LEARN_TO_RANK_DONE:
			return {
				...state,
				tasks: action.payload,
				count: state.count + (action.payload?.pagination?.count || 0),
				getting_in_progress: false,
			};
		case GET_LEARN_TO_RANK_FAIL:
			return {
				...state,
				getting_in_progress: false,
			};
		case RECORD_LEARN_TO_RANK_INIT:
			return {
				...state,
				record_in_progress: true,
			};
		case RECORD_LEARN_TO_RANK_DONE:
			return {
				...state,
				count: state.count + 1,
				last_record_at: new Date().toJSON(),
				record_in_progress: false,
			};
		case RECORD_LEARN_TO_RANK_FAIL:
			return {
				...state,
				record_in_progress: false,
			};
		case CREATE_LEARN_TO_RANK_INIT:
			return {
				...state,
				create_in_progress: true,
			};
		case CREATE_LEARN_TO_RANK_DONE:
			return {
				...state,
				create_in_progress: false,
				data: action.payload,
			};
		case CREATE_LEARN_TO_RANK_FAIL:
			return {
				...state,
				create_in_progress: false,
			};
		default:
			return state;
	}
};

export default learnToRankReducer;
