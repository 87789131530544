import type {FC} from "react";
import React from "react";
import IndexFilter from "containers/Filters/CompanyFilters/IndexFilter";
import RevenueFilter from "./RevenueFilter";
import CompanyFilter from "./CompanyFilter";
import IndustryFilter from "./IndustryFilter";
import LocationFilter from "./LocationFilter";

import styles from "./companyFilters.module.scss";
import CompanyHeadcountFilter from "containers/Filters/CompanyFilters/HeadcountFilter";
import SpecialtyClusterFilter from "./SpecialtyClusterFilter";
import CompanyTypeFilter from "./CompanyTypeFilter";

const CompanyFilters: FC = () => (
	<div className={styles.container}>
		<div className={styles.wrapper} id="filterContainer">
			<CompanyFilter />
			<IndustryFilter />
			<CompanyHeadcountFilter />
			<RevenueFilter />
			<SpecialtyClusterFilter />
			{/*<SpecialityFilter/>*/}
			<LocationFilter />
			<IndexFilter />
			<CompanyTypeFilter />
		</div>
	</div>
);

export default CompanyFilters;
