import {useCallback, type FC} from "react";
import {useSelector} from "react-redux";

import {useAppDispatch} from "hooks";
import {blockedByCountryForm} from "mapx-constants";
import {registerBlockedByCountryForm} from "store/mapx/user/userAsyncAction";
import {
	registerContactsBlockedByCountryInProgressSelector,
	registerContactsBlockedByCountrySubmittedSelector,
} from "store/mapx/user/userSelectors";
import {STContactsBlockedByCountryForm} from "api/hubspotApi/types";
import {AuthView} from "components";
import styles from "./index.module.scss";

const BlockedRegister: FC = () => {
	const dispatch = useAppDispatch();

	const registering = useSelector(registerContactsBlockedByCountryInProgressSelector);
	const submitted = useSelector(registerContactsBlockedByCountrySubmittedSelector);

	const handleSubmit = useCallback(
		(params: Record<string, unknown>) => {
			dispatch(
				registerBlockedByCountryForm({
					fields: [
						{
							name: "email",
							value: params.email,
						},
					],
				} as STContactsBlockedByCountryForm),
			);
		},
		[dispatch],
	);

	return submitted ? (
		<AuthView
			error={""}
			showErrorTooltip
			loading={registering}
			title="Thank You"
			description={
				<div className={styles.emailSentMessage}>
					<p>We&apos;ll let you know when we launch here.</p>
				</div>
			}
		/>
	) : (
		<AuthView
			description="Sorry, MapX isn't currently available in your region at the moment. Enter your email address and we'll let you know when we launch here."
			error={""}
			form={blockedByCountryForm}
			loading={registering}
			onSubmit={handleSubmit}
			showErrorTooltip
			submitText="Submit"
			title="MapX Not Available"
			to="/register/blocked"
		/>
	);
};

export default BlockedRegister;
