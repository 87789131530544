import React from "react";
import {useAppSelector} from "hooks";
import {companiesOrgChartFiltersSelector} from "store/mapx/company/companySelectors";
import OrgChartSenioritySearchFilter from "./senioritySearchFilter";
import {TOrgChartFilterProps} from "../types";

function SeniorityFilterForOrgChart({
	onSelectChange,
}: {
	onSelectChange: TOrgChartFilterProps["onSelectChange"];
}) {
	const filtersInState = useAppSelector(companiesOrgChartFiltersSelector);
	const selectedSeniority = filtersInState?.current_seniority;
	const handleResetClick = () => {
		onSelectChange("current_seniority", null);
	};

	return (
		<OrgChartSenioritySearchFilter
			handleResetClick={handleResetClick}
			handleOnChange={onSelectChange}
			selectedSeniority={selectedSeniority}
		/>
	);
}

export default SeniorityFilterForOrgChart;
