import {
	ADD_CANDIDATE_TO_TARGET_LIST_DONE,
	ADD_CANDIDATE_TO_TARGET_LIST_FAIL,
	ADD_CANDIDATE_TO_TARGET_LIST_INIT,
	ADD_COMPANIES_FROM_MARKET_MAP_TO_TARGET_LIST_DONE,
	ADD_COMPANIES_FROM_MARKET_MAP_TO_TARGET_LIST_FAIL,
	ADD_COMPANIES_FROM_MARKET_MAP_TO_TARGET_LIST_INIT,
	ADD_COMPANY_TO_TARGET_LIST_DONE,
	ADD_COMPANY_TO_TARGET_LIST_FAIL,
	ADD_COMPANY_TO_TARGET_LIST_INIT,
	CLEAR_FILTERED_SAVED_PEOPLE,
	CLEAR_TARGET_LIST_ITEMS,
	CREATE_DETACHED_TARGET_LIST_DONE,
	CREATE_DETACHED_TARGET_LIST_FAIL,
	CREATE_DETACHED_TARGET_LIST_INIT,
	FILTERED_SAVED_PEOPLE_FETCH_COMPLETED,
	FILTERED_SAVED_PEOPLE_FETCH_IN_PROGRESS,
	GET_TARGETLIST_CANDIDATES_DONE,
	GET_TARGETLIST_CANDIDATES_FAIL,
	GET_TARGETLIST_CANDIDATES_INIT,
	GET_TARGETLIST_COMPANIES_DONE,
	GET_TARGETLIST_COMPANIES_FAIL,
	GET_TARGETLIST_COMPANIES_INIT,
	GET_TARGETLISTS_BY_OWNERID_DONE,
	GET_TARGETLISTS_BY_OWNERID_FAIL,
	GET_TARGETLISTS_BY_OWNERID_INIT,
	MORE_GET_TARGETLIST_COMPANIES_INIT,
	REMOVE_CANDIDATE_FROM_TARGET_LIST_DONE,
	REMOVE_CANDIDATE_FROM_TARGET_LIST_FAIL,
	REMOVE_CANDIDATE_FROM_TARGET_LIST_INIT,
	REMOVE_CANDIDATES_FROM_TARGET_LIST_DONE,
	REMOVE_CANDIDATES_FROM_TARGET_LIST_FAIL,
	REMOVE_CANDIDATES_FROM_TARGET_LIST_INIT,
	REMOVE_COMPANIES_FROM_TARGET_LIST_DONE,
	REMOVE_COMPANIES_FROM_TARGET_LIST_FAIL,
	REMOVE_COMPANIES_FROM_TARGET_LIST_INIT,
	REMOVE_COMPANY_FROM_TARGET_LIST_DONE,
	REMOVE_COMPANY_FROM_TARGET_LIST_FAIL,
	REMOVE_COMPANY_FROM_TARGET_LIST_INIT,
	SAVE_CANDIDATES_BY_FILTERS_DONE,
	SAVE_CANDIDATES_BY_FILTERS_INIT,
	SAVE_COMPANIES_BY_FILTERS_DONE,
	SAVE_COMPANIES_BY_FILTERS_INIT,
	SET_AP_CANDIDATES_WITH_RECOMMENDED_CANDIDATES,
	UPDATE_AP_CANDIDATE_BY_KEY,
	UPDATE_FILTERED_TARGETLIST_CANDIDATES_WITH_CONTACT_DETAILS,
	UPDATE_FILTERED_TARGETLIST_CANDIDATES_WITH_SUMMARY,
	UPDATE_TARGETLIST_CANDIDATES_WITH_CONTACT_DETAILS,
	UPDATE_TARGETLIST_CANDIDATES_WITH_SUMMARY,
} from "../mapXActionTypes";

export function getTargetListsByOwnerIDInit() {
	return {type: GET_TARGETLISTS_BY_OWNERID_INIT};
}

export function getTargetListsByOwnerIDDone(data) {
	return {type: GET_TARGETLISTS_BY_OWNERID_DONE, payload: data};
}

export function getTargetListsByOwnerIDFail(error) {
	return {type: GET_TARGETLISTS_BY_OWNERID_FAIL, payload: error};
}

export function getTargetListCompaniesInit() {
	return {type: GET_TARGETLIST_COMPANIES_INIT};
}

export function moreGetTargetListCompaniesInit() {
	return {type: MORE_GET_TARGETLIST_COMPANIES_INIT};
}

export function getTargetListCompaniesDone(data) {
	return {type: GET_TARGETLIST_COMPANIES_DONE, payload: data};
}

export function getTargetListCompaniesFail(error) {
	return {type: GET_TARGETLIST_COMPANIES_FAIL, payload: error};
}

export function getTargetListCandidatesInit() {
	return {type: GET_TARGETLIST_CANDIDATES_INIT};
}

export function getTargetListCandidatesDone(data) {
	return {type: GET_TARGETLIST_CANDIDATES_DONE, payload: data};
}

export function filteredSavedPeopleFetchIsInProgress(payload) {
	return {type: FILTERED_SAVED_PEOPLE_FETCH_IN_PROGRESS, payload};
}

export function filteredSavedPeopleFetchCompleted(payload) {
	return {type: FILTERED_SAVED_PEOPLE_FETCH_COMPLETED, payload};
}

export function clearFilteredSavedPeople() {
	return {type: CLEAR_FILTERED_SAVED_PEOPLE};
}

export function setAPCandidatesWithUpdatedRecommendedCandidates(payload) {
	return {type: SET_AP_CANDIDATES_WITH_RECOMMENDED_CANDIDATES, payload};
}

export function updateAPCandidateByKey(payload) {
	return {type: UPDATE_AP_CANDIDATE_BY_KEY, payload};
}

export function updateTargetListCandidatesWithSummary(payload) {
	return {type: UPDATE_TARGETLIST_CANDIDATES_WITH_SUMMARY, payload};
}

export function updateFilteredTargetListCandidatesWithSummary(payload) {
	return {type: UPDATE_FILTERED_TARGETLIST_CANDIDATES_WITH_SUMMARY, payload};
}

export function updateTargetListCandidatesWithContactDetails(payload) {
	return {type: UPDATE_TARGETLIST_CANDIDATES_WITH_CONTACT_DETAILS, payload};
}

export function updateFilteredTargetListCandidatesWithContactDetails(payload) {
	return {type: UPDATE_FILTERED_TARGETLIST_CANDIDATES_WITH_CONTACT_DETAILS, payload};
}

export function getTargetListCandidatesFail(error) {
	return {type: GET_TARGETLIST_CANDIDATES_FAIL, payload: error};
}

export function addCandidateToTargetListInit(candidateID) {
	return {type: ADD_CANDIDATE_TO_TARGET_LIST_INIT, payload: candidateID};
}

export function addCandidateToTargetListDone(candidate, targetListID) {
	return {type: ADD_CANDIDATE_TO_TARGET_LIST_DONE, payload: {candidate, targetListID}};
}

export function addCandidateToTargetListFail(payload) {
	return {type: ADD_CANDIDATE_TO_TARGET_LIST_FAIL, payload};
}

export function addCompanyToTargetListInit(companyID) {
	return {type: ADD_COMPANY_TO_TARGET_LIST_INIT, payload: companyID};
}

export function addCompanyToTargetListDone(company, targetListID) {
	return {type: ADD_COMPANY_TO_TARGET_LIST_DONE, payload: {company, targetListID}};
}

export function addCompanyToTargetListFail(payload) {
	return {type: ADD_COMPANY_TO_TARGET_LIST_FAIL, payload};
}

export function removeCandidateFromTargetListInit(candidateID) {
	return {type: REMOVE_CANDIDATE_FROM_TARGET_LIST_INIT, payload: candidateID};
}

export function removeCandidateFromTargetListDone(candidateID, targetListID) {
	return {type: REMOVE_CANDIDATE_FROM_TARGET_LIST_DONE, payload: {candidateID, targetListID}};
}

export function removeCandidateFromTargetListFail(payload) {
	return {type: REMOVE_CANDIDATE_FROM_TARGET_LIST_FAIL, payload};
}

export function removeCompanyFromTargetListInit(companyID) {
	return {type: REMOVE_COMPANY_FROM_TARGET_LIST_INIT, payload: companyID};
}

export function removeCompanyFromTargetListDone(companyID, targetListID) {
	return {type: REMOVE_COMPANY_FROM_TARGET_LIST_DONE, payload: {companyID, targetListID}};
}

export function removeCompanyFromTargetListFail(payload) {
	return {type: REMOVE_COMPANY_FROM_TARGET_LIST_FAIL, payload};
}

export function removeCandidatesFromTargetListInit() {
	return {type: REMOVE_CANDIDATES_FROM_TARGET_LIST_INIT};
}

export function removeCandidatesFromTargetListDone(targetListID) {
	return {type: REMOVE_CANDIDATES_FROM_TARGET_LIST_DONE, payload: {targetListID}};
}

export function removeCandidatesFromTargetListFail(error) {
	return {type: REMOVE_CANDIDATES_FROM_TARGET_LIST_FAIL, payload: error};
}

export function removeCompaniesFromTargetListInit() {
	return {type: REMOVE_COMPANIES_FROM_TARGET_LIST_INIT};
}

export function removeCompaniesFromTargetListDone(targetListID) {
	return {type: REMOVE_COMPANIES_FROM_TARGET_LIST_DONE, payload: {targetListID}};
}

export function removeCompaniesFromTargetListFail(error) {
	return {type: REMOVE_COMPANIES_FROM_TARGET_LIST_FAIL, payload: error};
}

export function createDetachedTargetListInit() {
	return {type: CREATE_DETACHED_TARGET_LIST_INIT};
}

export function createDetachedTargetListDone(data) {
	return {type: CREATE_DETACHED_TARGET_LIST_DONE, payload: data};
}

export function createDetachedTargetListFail(error) {
	return {type: CREATE_DETACHED_TARGET_LIST_FAIL, payload: error};
}

export function saveCompaniesByFiltersInit() {
	return {type: SAVE_COMPANIES_BY_FILTERS_INIT};
}

export function saveCompaniesByFiltersDone() {
	return {type: SAVE_COMPANIES_BY_FILTERS_DONE};
}

export function saveCandidatesByFiltersInit() {
	return {type: SAVE_CANDIDATES_BY_FILTERS_INIT};
}

export function saveCandidatesByFiltersDone() {
	return {type: SAVE_CANDIDATES_BY_FILTERS_DONE};
}

export function addCompaniesFromMarketMapToTargetListInit() {
	return {type: ADD_COMPANIES_FROM_MARKET_MAP_TO_TARGET_LIST_INIT};
}

export function addCompaniesFromMarketMapToTargetListDone(data) {
	return {type: ADD_COMPANIES_FROM_MARKET_MAP_TO_TARGET_LIST_DONE, payload: data};
}

export function addCompaniesFromMarketMapToTargetListFail(error) {
	return {type: ADD_COMPANIES_FROM_MARKET_MAP_TO_TARGET_LIST_FAIL, payload: error};
}

export function clearTargetListItems() {
	return {type: CLEAR_TARGET_LIST_ITEMS};
}
