import {type FC, Fragment, useEffect} from "react";
import {isEmpty} from "lodash";
import {Tooltip} from "react-tooltip";

import {ButtonsRow} from "mapx-components";
import {useAppDispatch, useAppSelector} from "hooks";
import type {STSpecialty} from "api/filterOptionsApi/SpecialityApi/types";
import {formatRevenueNumber, formatSizeBand} from "helpers/string";
import {clearSingleCompanyDone} from "store/mapx/company/companyActions";
import {CompanySkeletonTooltip, Monetization, PersonFilled} from "assets/icons";
import {tooltipCompaniesProgressSelector} from "store/mapx/company/companySelectors";

import type {TCompanyInfoTooltipProps} from "./types";
import styles from "./CompanyInfoTooltip.module.scss";

const CompanyInfoTooltip: FC<TCompanyInfoTooltipProps> = ({
	id,
	company,
	isInCompanyPage,
	...rest
}) => {
	const dispatch = useAppDispatch();
	const tooltipCompaniesProgress = useAppSelector(tooltipCompaniesProgressSelector);

	useEffect(() => {
		if (!isInCompanyPage) {
			dispatch(clearSingleCompanyDone());
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const isNullCompany = Boolean(company);

	return (
		<Tooltip
			float
			id={id}
			clickable
			place="right"
			delayShow={500}
			className={styles.wrapper}
			{...rest}
		>
			{tooltipCompaniesProgress || !isNullCompany ? (
				<CompanySkeletonTooltip />
			) : (
				<Fragment>
					<div className={styles.wrapper__info}>
						<img
							alt="Company Logo"
							src={
								company?.logo_url
									? company.logo_url
									: "https://storage.googleapis.com/nebula-static/logos100x100/empty_logo.png"
							}
							className={styles.wrapper__info_logo}
						/>
						<div className={styles.wrapper__info__content}>
							<p className={styles.wrapper__info__content_name}>{company?.name}</p>

							<div className={styles.wrapper__info__content__country}>
								{company?.headquarters_country && (
									<p className={styles.wrapper__info__content__country_item}>
										{company?.headquarters_country?.code},
										{company?.headquarters_country?.name}
									</p>
								)}
							</div>
						</div>
					</div>

					<p className={styles.wrapper__subtitle}>{company?.industry?.name}</p>

					<div className={styles.wrapper__specialties}>
						{!isEmpty(company?.specialties) && (
							<ButtonsRow
								items={company?.specialties as STSpecialty[]}
								className={styles.wrapper__specialties_items}
								itemClassContainer={styles.wrapper__specialties__container}
							/>
						)}
					</div>

					<div className={styles.wrapper__size}>
						{company?.size && (
							<div className={styles.wrapper__size__item}>
								<PersonFilled />

								<p className={styles.wrapper__size__item_text}>
									{formatSizeBand(company?.size)}
								</p>
							</div>
						)}

						{company?.revenue && (
							<div className={styles.wrapper__size__item}>
								<Monetization />

								<p className={styles.wrapper__size__item_text}>
									{formatRevenueNumber(company?.revenue)}
								</p>
							</div>
						)}
					</div>
				</Fragment>
			)}
		</Tooltip>
	);
};

export default CompanyInfoTooltip;
