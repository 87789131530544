import React, {useCallback, useEffect, useRef, useState} from "react";
import {SearchInput} from "mapx-components";
import css from "../SpecialityFilter/companySpecialityFilter.module.scss";
import useFetchListPaginateOptions from "hooks/useFetchListPaginateOptions";
import {setSpecialtyClusterSearchQuery} from "store/mapx/search/searchActions";
import {removeDuplicateObjectFromArray, removeDuplicatesFromArray} from "helpers/filterHandlers";
import {Checkbox, CheckboxSkeletonLoader, Loader, ResetFilters, TabSelect} from "components";
import {setCompanySpecialitiesSelectedConnectivityLogic} from "store/mapx/filter/filterActions";
import Tags from "../SpecialtyClusterFilter/Tags";
import {useSelector} from "react-redux";
import {companiesSelector} from "store/mapx/company/companySelectors";
import {
	companySpecialitiesConnectivityLogicSelector,
	filterConnectivityLogicOptionsSelector,
	selectedCompaniesSelector,
} from "store/mapx/filter/filterSelectors";
import {useAppDispatch} from "hooks";
import CheckboxList from "mapx-components/Inputs/CheckboxList";
import ExpandableArrow from "mapx-components/ExpandableArrow";
import {TGetExpandableDataResult} from "types";
import {getName} from "helpers/string";
import {arrayIntersected, removeInputArrayIdentifiersFromAnotherArray} from "helpers/array";
import useInfiniteScroll from "react-infinite-scroll-hook";
import {STSpecialtyCluster, TCompanySpecialityClusterSearchFilter} from "./types";
import {
	getClusterSpecialities,
	setBulkSpecialityClusterForCompanies,
} from "store/mapx/filter/companySpecialtyClusterFilterAsyncActions";
import {specialtyClusterSearchQuerySelector} from "store/mapx/filter/companySpecialtyCLusterSelectors";
import {
	specialtyClusterOptionsInProgressSelector,
	specialtyClusterOptionsSelector,
	specialtyClusterSearchInProgressSelector,
} from "store/mapx/search/searchSelectors";

const SpecialityClusterSearchFilter = ({
	totalSpecialities,
	handleResetClick,
	handleSpecialitiesChange,
	selectedSpecialityIds,
}: TCompanySpecialityClusterSearchFilter) => {
	const dispatch = useAppDispatch();
	const [filteredData, setFilteredData] = useState<STSpecialtyCluster[]>([]);
	const [allSpecialtiesData, setAllSpecialtiesData] = useState<STSpecialtyCluster[]>([]);
	const companies = useSelector(companiesSelector);
	const selected_companies = useSelector(selectedCompaniesSelector);
	const filterConnectivityLogicOptions = useSelector(filterConnectivityLogicOptionsSelector);

	const companySpecialitiesConnectivityLogic = useSelector(
		companySpecialitiesConnectivityLogicSelector,
	);

	const specialtyClusterSearchQuery = useSelector(specialtyClusterSearchQuerySelector);

	const specialtyClusterOptions = useSelector(specialtyClusterOptionsSelector);

	const specialtyClusterOptionsInProgress = useSelector(
		specialtyClusterOptionsInProgressSelector,
	);

	const specialtyClusterSearchInProgress = useSelector(specialtyClusterSearchInProgressSelector);

	const {loading, onFilterChanged, data, page, setPage, searchTerm} = useFetchListPaginateOptions(
		{
			options: specialtyClusterOptions,
			setFilteredData,
			initialSearchQuery: specialtyClusterSearchQuery,
			setQueryCallbackOnState: setSpecialtyClusterSearchQuery,
			apiCall: getClusterSpecialities,
		},
	);

	const specialityClustersFromSelectedCompanies = useCallback(() => {
		let selectedSpecialtyClusters: STSpecialtyCluster[] = [];
		const selectedCompanies = selected_companies || [];
		const targetCompanies = companies || [];

		[...selectedCompanies, ...targetCompanies].forEach((item) => {
			if (item.specialties) {
				selectedSpecialtyClusters = [...selectedSpecialtyClusters, ...item.specialties];
			}
		});

		return selectedSpecialtyClusters;
	}, [selected_companies, companies]);

	const prepareSpecialtyClusterArray = useCallback(() => {
		let updatedSpecialtyClusters = [];
		if (!searchTerm) {
			updatedSpecialtyClusters = [...specialityClustersFromSelectedCompanies()];
		} else {
			updatedSpecialtyClusters = [...data];
		}

		return removeDuplicateObjectFromArray(updatedSpecialtyClusters, "id");
	}, [searchTerm, specialityClustersFromSelectedCompanies, data]);

	const [sentryRef] = useInfiniteScroll({
		loading: false,
		hasNextPage: true,
		onLoadMore: async () => {
			setPage(page + 1);
		},
		disabled: filteredData === null,
		delayInMs: 300,
	});

	const listRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const updatedSpecialtyClusters = prepareSpecialtyClusterArray();

		const selectedItems = updatedSpecialtyClusters.filter((s) =>
			selectedSpecialityIds.includes(s.id),
		);

		const totalData = removeDuplicateObjectFromArray([...selectedItems, ...data]);
		const filteredData = totalData.filter((f) => f.specialties?.length > 0);

		setFilteredData(removeDuplicateObjectFromArray(filteredData, "id"));

		const dataWithSelectedFromCompanies = removeDuplicateObjectFromArray([
			...selectedItems,
			...data,
			...specialityClustersFromSelectedCompanies(),
			...specialtyClusterOptions,
		]);

		const restructuredDataForTags = dataWithSelectedFromCompanies.reduce((acc, item) => {
			if (!item.cluster) {
				acc.push(item);
			}

			const {cluster, ...specialty} = item;
			const existingCluster = acc.find((c: {id: number}) => c.id === cluster?.id);

			if (existingCluster) {
				existingCluster.specialties.push(specialty);
			} else {
				acc.push({
					...cluster,
					specialties: [specialty],
				});
			}

			return acc;
		}, [] as STSpecialtyCluster[]);

		setAllSpecialtiesData(removeDuplicateObjectFromArray(restructuredDataForTags, "id"));
	}, [
		selectedSpecialityIds,
		data,
		prepareSpecialtyClusterArray,
		specialityClustersFromSelectedCompanies,
		specialtyClusterOptions,
	]);

	const [expanded, setExpanded] = useState<TGetExpandableDataResult>({});
	const expand = useCallback(
		(id: number) => {
			setExpanded({
				...expanded,
				[id]: !expanded[id],
			});
		},
		[expanded],
	);

	const specialityIdsByCluster = useCallback((cluster: STSpecialtyCluster) => {
		return cluster.specialties?.map(({id}) => id);
	}, []);

	const handleBulkUpdate = useCallback(
		(ids: number[]) => {
			dispatch(
				setBulkSpecialityClusterForCompanies(companySpecialitiesConnectivityLogic, ids),
			);
		},
		[dispatch, companySpecialitiesConnectivityLogic],
	);

	const hasSpecialitySelected = useCallback(
		(jobFunction: STSpecialtyCluster) =>
			arrayIntersected(selectedSpecialityIds, specialityIdsByCluster(jobFunction)),
		[selectedSpecialityIds, specialityIdsByCluster],
	);

	const handleOnChange = useCallback(
		(selected: STSpecialtyCluster) => {
			const ids = specialityIdsByCluster(selected);
			let updatedIds;

			if (hasSpecialitySelected(selected)) {
				updatedIds = removeInputArrayIdentifiersFromAnotherArray(
					ids,
					selectedSpecialityIds,
				);
			} else {
				updatedIds = removeDuplicatesFromArray([...selectedSpecialityIds, ...ids]);
			}

			handleBulkUpdate(updatedIds);
		},
		[selectedSpecialityIds, handleBulkUpdate, hasSpecialitySelected, specialityIdsByCluster],
	);

	const hasSpecialityPartiallySelected = useCallback(
		(specialty: STSpecialtyCluster) =>
			!hasSpecialitySelected(specialty) &&
			specialty.specialties?.some((s) => selectedSpecialityIds.includes(s.id)),
		[hasSpecialitySelected, selectedSpecialityIds],
	);

	// useEffect(() => {
	// 	if (listRef.current && page === 1) {
	// 		listRef.current.scrollTop = 0;
	// 	}
	// }, [page]);

	return (
		<div>
			<div className={css.logicWrapper}>
				<span className={css.logicLabel}>Logic</span>

				<TabSelect.LabelContainer style={{margin: 0}}>
					<TabSelect
						data-testid={`${
							companySpecialitiesConnectivityLogic || "or"
						}TabSpecialtyCluster`}
						selected={companySpecialitiesConnectivityLogic || "or"}
						onTabChange={(e) =>
							dispatch(setCompanySpecialitiesSelectedConnectivityLogic(e))
						}
						options={filterConnectivityLogicOptions}
					/>
				</TabSelect.LabelContainer>
			</div>
			<SearchInput
				className={css.searchBox}
				defaultValue={specialtyClusterSearchQuery}
				isLoading={loading}
				onChange={onFilterChanged}
				placeholder="Search for a Specialty"
				type="text"
				errorText={undefined}
				errorClass={undefined}
			/>

			<ResetFilters
				parentStyle={{color: "#5A5A5A", marginRight: 19}}
				onClick={handleResetClick}
				displayIcon={true}
			>
				Clear Selection
			</ResetFilters>

			{specialtyClusterSearchInProgress && <CheckboxSkeletonLoader />}

			<div>
				{!specialtyClusterSearchInProgress && (
					<CheckboxList ref={listRef}>
						{filteredData.map((f) => {
							const isSpecialitySelected = hasSpecialitySelected(f);
							const isSpecialitiesPartiallySelected =
								hasSpecialityPartiallySelected(f);

							return (
								<CheckboxList.Accordion key={f.id}>
									<CheckboxList.AccordionHeader>
										<ExpandableArrow
											data-testid={"expandArrowSpecialtyClusterFilter"}
											onClick={() => expand(f.id)}
											rotated={!expanded[f.id]}
										/>

										<Checkbox
											data-testid="upperCheckboxSpecialtyClusterFilter"
											borderColor="#0C5850"
											isChecked={isSpecialitySelected}
											key={f.id}
											label={`${getName(f.name)} (${f.specialties?.length})`}
											onChange={() => handleOnChange(f)}
											value={f.id.toString()}
											partiallySelected={isSpecialitiesPartiallySelected}
										/>
									</CheckboxList.AccordionHeader>

									<CheckboxList.AccordionContent expanded={expanded[f.id]}>
										{f.specialties?.map((s) => (
											<Checkbox
												borderColor="#0C5850"
												isChecked={selectedSpecialityIds.includes(s.id)}
												key={s.id}
												label={getName(s.name)}
												onChange={() => handleSpecialitiesChange(s.id)}
												value={getName(s.name)}
											/>
										))}
									</CheckboxList.AccordionContent>
									{!specialtyClusterOptionsInProgress && (
										<div
											ref={sentryRef}
											key={`specialty_cluster_filter${f.id}`}
											style={{width: "100%", height: "0px"}}
										/>
									)}
								</CheckboxList.Accordion>
							);
						})}
					</CheckboxList>
				)}

				{filteredData.length < 1 && specialtyClusterOptionsInProgress && (
					<CheckboxSkeletonLoader />
				)}

				{filteredData.length > 0 &&
					specialtyClusterOptionsInProgress &&
					!specialtyClusterSearchInProgress && (
						<div
							data-testid={"loader_3dots_companySpecialtyFilter"}
							style={{textAlign: "center"}}
						>
							<Loader
								width={30}
								height={30}
								type="ThreeDots"
								color="#0c5850"
								displayAtCenterOfPage={false}
							/>
						</div>
					)}
			</div>

			{totalSpecialities > 0 && (
				<div style={{display: "flex", flexDirection: "column", gap: 8, marginTop: 8}}>
					<Tags
						key={"and"}
						options={allSpecialtiesData.flatMap((f) => f.specialties)}
						tagLabel={"AND"}
						tagValue={"and"}
					/>

					<Tags
						key={"or"}
						options={allSpecialtiesData.flatMap((f) => f.specialties)}
						tagLabel={"OR"}
						tagValue={"or"}
					/>

					<Tags
						key={"not"}
						options={allSpecialtiesData.flatMap((f) => f.specialties)}
						tagLabel={"NOT"}
						tagValue={"not"}
					/>
				</div>
			)}
		</div>
	);
};

export default SpecialityClusterSearchFilter;
