import {useSelector} from "react-redux";
import classNames from "classnames";

import {FeatureBlockArrow} from "assets/icons";
import {useAppDispatch} from "hooks";
import {orgPlanDistributionSelector} from "store/mapx/organisation/organisationSelectors";
import {companyLicenseTypesSelector} from "store/mapx/company/companySelectors";
import {userUpdate} from "store/mapx/user/userAsyncAction";
import {userOrganisationSelector} from "store/mapx/user/userSelectors";

import styles from "./UserPlansDropDown.module.scss";
import type {IUserPlansDropDownProps} from "./types";

const UserPlansDropDown = ({user}: IUserPlansDropDownProps) => {
	const dispatch = useAppDispatch();

	const licenseTypes = useSelector(companyLicenseTypesSelector);
	const seatsDistribution = useSelector(orgPlanDistributionSelector);

	const organisation = useSelector(userOrganisationSelector);

	const handleClick = (id: number) => {
		dispatch(userUpdate(user.id, {license_type_id: id}, organisation));
	};

	return (
		<div className={styles.wrapper}>
			<FeatureBlockArrow className={styles.arrow} />
			{/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
			{licenseTypes.map((l: any) => {
				let dist = seatsDistribution.find((sd) => sd.license_type === l.name);

				if (!dist && l.name === "Starter") {
					dist = {
						license_type: "Starter",
						used: 0,
						total: 0,
					};
				} else if (!dist || l.name === "Prospect") {
					return null;
				}

				return (
					<div
						key={l.id}
						role="button"
						onClick={() => handleClick(l.id)}
						className={classNames(styles.item, {
							[styles.disabled]:
								l.superuser_only ||
								(l.name !== "Starter" && dist.total <= dist.used),
						})}
					>
						<span className={styles.name}>{`${l.name} License`}</span>
						<span className={styles.seats}>
							{dist.license_type === "Starter" || dist.license_type === "Prospect" ? (
								<>{dist.used}</>
							) : (
								<>{`${dist.used}/${dist.total}`}</>
							)}
						</span>
					</div>
				);
			})}
		</div>
	);
};

export default UserPlansDropDown;
