import {type FC, useEffect} from "react";
import {useLocation} from "react-router-dom";

import {MainWrapper} from "mapx-components";
import {useAppDispatch, useAppSelector, useLockBodyScroll} from "hooks";
import {setOnboardingVisibility} from "store/mapx/project/projectActions";
import {
	userLicenseTypeNameSelector,
	userOrganisationRoleSelector,
} from "store/mapx/user/userSelectors";
import {onboardingStepSelector} from "store/mapx/project/projectSelectors";
import {getProducts, openCheckout} from "store/mapx/payment/paymentAsyncActions";
import {productsSelector} from "store/mapx/payment/paymentSelectors";
import {
	orgUsersListProgressSelector,
	organisationAdminSelector,
	organisationHasPaidPlanSelector,
	seatsInProgressSelector,
} from "store/mapx/organisation/organisationSelectors";
import {contactAdminModal} from "store/mapx/payment/paymentActions";

import OnboardingHome from "./OnboardingHome";
import OnboardingProjects from "./OnboardingProjects";

const Onboarding: FC = () => {
	const {pathname, search} = useLocation();
	const dispatch = useAppDispatch();
	const isOnboardingHome = pathname === "/onboarding/home";
	const onboardingStep = useAppSelector(onboardingStepSelector);
	const userLicenseTypeName = useAppSelector(userLicenseTypeNameSelector);
	const products = useAppSelector(productsSelector);
	const organisationHasPaidPlan = useAppSelector(organisationHasPaidPlanSelector);
	const organisationRole = useAppSelector(userOrganisationRoleSelector);
	const admin = useAppSelector(organisationAdminSelector);
	const seatsInProgress = useAppSelector(seatsInProgressSelector);
	const userListInProgress = useAppSelector(orgUsersListProgressSelector);

	const paymentType = new URLSearchParams(search).get("payment_type");
	const paymentInterval = new URLSearchParams(search).get("payment_interval") || "month";

	useEffect(() => {
		dispatch(setOnboardingVisibility(true));

		if (paymentType) {
			dispatch(getProducts());
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		// Wait for seat, user, and product fetch requests to be done.
		if (
			paymentType &&
			paymentInterval &&
			products.length > 0 &&
			!userListInProgress &&
			!seatsInProgress
		) {
			if (!organisationHasPaidPlan && (admin == null || organisationRole === "Admin")) {
				const product = products.find(
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					(p: any) => p.metadata.license_type.toLowerCase() === paymentType,
				);

				if (product) {
					const price = product.prices.find(
						// eslint-disable-next-line @typescript-eslint/no-explicit-any
						(price: any) => price.recurring.interval === paymentInterval,
					);

					if (price) {
						dispatch(openCheckout(price.id));
					}
				}
			} else {
				dispatch(contactAdminModal(true));
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [products, userListInProgress, seatsInProgress]);

	useLockBodyScroll(true);

	const showBoardingPage = isOnboardingHome ? (
		<OnboardingHome step={onboardingStep} />
	) : (
		<OnboardingProjects step={onboardingStep} userLicenseTypeName={userLicenseTypeName} />
	);

	return <MainWrapper>{showBoardingPage}</MainWrapper>;
};

export default Onboarding;
