import {useCallback, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {IndustrySearchFilter} from "mapx-components";
import {
	apCandidateAllIndustriesCountSelector,
	apCandidateIndustriesByPositionSelector,
} from "store/mapx/additional-profiles/additionalProfilesSelectors";
import {setAPMultipleFilters} from "store/mapx/additional-profiles/additionalProfilesActions";
import {setAPIndustry} from "store/mapx/additional-profiles/additionalProfilesAsyncActions";
import {TabSelect} from "components";
import AnyTags from "./Tags/AnyTags";
import CurrentTags from "./Tags/CurrentTags";
import PreviousTags from "./Tags/PreviousTags";

const positionOptions = [
	{label: "Current", value: "current"},
	{label: "Previous", value: "previous"},
	{label: "Any", value: "any"},
];

function IndustryFilterForAP() {
	const dispatch = useDispatch();

	const [position, setPosition] = useState("current");

	const count = useSelector(apCandidateAllIndustriesCountSelector);

	const industries = useSelector((state) =>
		apCandidateIndustriesByPositionSelector(state, position),
	);

	const handlePositionChange = useCallback((value) => {
		setPosition(value);
	}, []);

	const handleChange = useCallback(
		(nextIds) => {
			dispatch(setAPIndustry({nextIds, position}));
		},
		[dispatch, position],
	);

	const handleResetClick = useCallback(() => {
		dispatch(
			setAPMultipleFilters({
				current_industries: [],
				previous_industries: [],
				industries: [],
			}),
		);
	}, [dispatch]);

	return (
		<div>
			<TabSelect.LabelContainer label="Position">
				<TabSelect
					selected={position}
					onTabChange={handlePositionChange}
					options={positionOptions}
				/>
			</TabSelect.LabelContainer>

			<IndustrySearchFilter
				selectedIndustries={industries}
				handleResetClick={handleResetClick}
				handleOnChange={handleChange}
			/>

			{count !== 0 && (
				<>
					<AnyTags />

					<CurrentTags />

					<PreviousTags />
				</>
			)}
		</div>
	);
}

export default IndustryFilterForAP;
