import {createSelector} from "reselect";
import {TRootState} from "types";
import {
	TCompanyHeadcountPosition,
	TCompanyHeadcountRangeItem,
} from "mapx-components/Filters/CompanyHeadcountSearchFilter/types";
import {companyHeadcountRangeOptionsSelector} from "store/mapx/search/searchSelectors";

export const candidateAnyCompanySizeSelector = (state: TRootState) =>
	state.filter.candidate_filters.company_size;

export const candidateCurrentCompanySizeSelector = (state: TRootState) =>
	state.filter.candidate_filters.current_company_size;

export const candidatePreviousCompanySizeSelector = (state: TRootState) =>
	state.filter.candidate_filters.previous_company_size;

export const candidateCompanyHeadcountFilterConfigSelector = (state: TRootState) =>
	state.filter.candidate_company_headcount_filter_config;

export const candidateCompanyHeadcountFilterActivePositionSelector = (state: TRootState) =>
	state.filter.candidate_company_headcount_filter_config.active_position;

const getCompanyHeadcountPosition = (_: TRootState, position: TCompanyHeadcountPosition) =>
	position;

export const selectedCurrentCompanyHeadcountTagsSelector = createSelector(
	[candidateCurrentCompanySizeSelector, companyHeadcountRangeOptionsSelector],
	(selectedSizes, options) => {
		const current: TCompanyHeadcountRangeItem[] = [];

		options.forEach((item: TCompanyHeadcountRangeItem) => {
			if (selectedSizes.includes(item.id)) {
				current.push(item);
			}
		});

		return current;
	},
);

export const selectedAnyCompanyHeadcountTagsSelector = createSelector(
	[candidateAnyCompanySizeSelector, companyHeadcountRangeOptionsSelector],
	(selectedSizes, options) => {
		const current: TCompanyHeadcountRangeItem[] = [];

		options.forEach((item: TCompanyHeadcountRangeItem) => {
			if (selectedSizes.includes(item.id)) {
				current.push(item);
			}
		});

		return current;
	},
);

export const selectedPreviousCompanyHeadcountTagsSelector = createSelector(
	[candidatePreviousCompanySizeSelector, companyHeadcountRangeOptionsSelector],
	(selectedSizes, options) => {
		const current: TCompanyHeadcountRangeItem[] = [];

		options.forEach((item: TCompanyHeadcountRangeItem) => {
			if (selectedSizes.includes(item.id)) {
				current.push(item);
			}
		});

		return current;
	},
);

export const companyHeadcountFilterKeyByPosition = createSelector(
	getCompanyHeadcountPosition,
	(position) => {
		switch (position) {
			case "current":
				return "current_company_size";
			case "previous":
				return "previous_company_size";
			case "any":
			default:
				return "company_size";
		}
	},
);

export const candidateCompanyHeadcountByPositionSelector = createSelector(
	[
		candidateCurrentCompanySizeSelector,
		candidatePreviousCompanySizeSelector,
		candidateAnyCompanySizeSelector,
		(_, position) => position,
	],
	(currentHeadcount, previousHeadcount, anyHeadcount, position) => {
		switch (position) {
			case "current":
				return currentHeadcount || null;
			case "previous":
				return previousHeadcount || null;
			case "any":
				return anyHeadcount || null;
			default:
				return [];
		}
	},
);

export const candidatesAllCompanyHeadcountCountSelector = createSelector(
	[
		candidateCurrentCompanySizeSelector,
		candidatePreviousCompanySizeSelector,
		candidateAnyCompanySizeSelector,
	],
	(current, previous, any) => current.length + previous.length + any.length,
);
