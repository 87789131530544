import {forwardRef, useMemo, useState} from "react";
import classNames from "classnames";

import IconError from "assets/icons/IconError16";
import GreenCheckMark from "assets/images/green_circle.png";
import EmptyCheckCircle from "assets/images/check_circle.png";

import type {ITextInputProps} from "./types";
import css from "./index.module.scss";

const TextInput = forwardRef<HTMLInputElement, ITextInputProps>(
	(
		{
			label = "",
			errorText = "",
			customClass = "",
			inputWrapper = "",
			passwordValidations,
			rightIcon = null,
			onBlur,
			...restProps
		},
		ref,
	) => {
		const [hasFocus, setHasFocus] = useState<boolean | null>(null);

		const hasError = useMemo(
			() => passwordValidations?.some((rule) => !rule.isValid),
			[passwordValidations],
		);

		return (
			<div
				className={classNames(
					css.inputContainer,
					customClass && customClass,
					{
						[css.invalid]: errorText,
					},
					"valid-input",
				)}
			>
				{label && <label className={css.label}>{label}</label>}

				<div className={classNames(css.inputWrapper, inputWrapper)}>
					<input
						{...restProps}
						ref={ref}
						onBlur={(e) => {
							if (onBlur) onBlur(e);
							setHasFocus(false);
						}}
						onFocus={() => setHasFocus(true)}
						className={classNames(css.input, {
							[css.invalidInput]: hasError && !hasFocus,
						})}
					/>
					{hasError && !hasFocus ? (
						<span className={classNames(css.iconError, css.rightIcon, "error-icon")}>
							<IconError />
						</span>
					) : (
						<span className={css.rightIcon}>{rightIcon}</span>
					)}
				</div>

				{errorText && (
					<span className={css.errorTextSpan} style={{opacity: errorText ? 1 : 0}}>
						{errorText}&nbsp;
					</span>
				)}

				{passwordValidations && hasFocus && (
					<div className="password-hints">
						<span>Password Requirements</span>
						{passwordValidations?.map(({isValid, label}, i) => (
							<div
								key={i}
								className={classNames("hint", {
									["valid"]: isValid,
									["invalid"]: !isValid,
								})}
							>
								<img src={isValid ? GreenCheckMark : EmptyCheckCircle} /> {label}
							</div>
						))}
					</div>
				)}
			</div>
		);
	},
);

TextInput.displayName = "TextInput";

export default TextInput;
