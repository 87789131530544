import {Tag} from "components";
import FilterTags from "mapx-components/FilterTags";
import React, {DragEvent, useCallback} from "react";
import {TCompanyRevenueTagProps} from "./types";
import {
	TCompanyRevenuePosition,
	TCompanyRevenueRangeItem,
} from "mapx-components/Filters/CompanyRevenueSearchFilter/types";

function Tags({
	tagLabel,
	tagValue,
	tags,
	handleTagClick,
	clearTagsByPosition,
	moveRevenueTagPosition,
}: TCompanyRevenueTagProps) {
	const handleTagDragStart = useCallback(
		(e: React.DragEvent, label: string) => {
			if (tagValue) {
				e.dataTransfer.setData("id", label);
				e.dataTransfer.setData("from", tagValue);
			}
		},
		[tagValue],
	);

	const handleDrop = useCallback(
		(e: DragEvent<HTMLDivElement>) => {
			const value = e.dataTransfer.getData("id");
			const from = e.dataTransfer.getData("from") as TCompanyRevenuePosition;

			moveRevenueTagPosition(value, from, tagValue);
		},
		[moveRevenueTagPosition, tagValue],
	);

	return (
		<FilterTags
			onDrop={handleDrop}
			label={tagLabel || ""}
			count={tags?.length}
			moveDisabled={true}
			tags={tags?.map((item: TCompanyRevenueRangeItem) => (
				<Tag
					key={item.id}
					onClick={() => handleTagClick(item)}
					draggable={true}
					onDragStart={(e) => handleTagDragStart(e, item.id)}
					onDragOver={(e) => e.preventDefault()}
				>
					{`${item.name}` || ""}
				</Tag>
			))}
			onClear={() => clearTagsByPosition(tagValue)}
		/>
	);
}

export default React.memo(Tags);
