import {FC, useEffect} from "react";
import {Redirect} from "react-router-dom";

import {MainWrapper} from "mapx-components";
import {useAppDispatch, useAppSelector} from "hooks";
import {getOrganizationsData} from "store/mapx/organisation/organisationAsyncFunctions";
import {isSuperUserSelector, userOrganisationTypeSelector} from "store/mapx/user/userSelectors";

import styles from "./Organizations.module.scss";
import OrganizationList from "./OrganizationList";
import ProjectPerOrganization from "./ProjectPerOrganization";

const Organizations: FC = () => {
	const dispatch = useAppDispatch();

	const isSuperUser = useAppSelector(isSuperUserSelector);
	const organisationType = useAppSelector(userOrganisationTypeSelector);

	useEffect(() => {
		const getOrgData = async () => {
			await dispatch(getOrganizationsData());
		};

		getOrgData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (!isSuperUser || organisationType === "Client") {
		return <Redirect to="/403" />;
	}

	return (
		<MainWrapper>
			<div className={styles.wrapper}>
				<h1 className={styles.title}>Organizations Settings</h1>

				<ProjectPerOrganization />

				<OrganizationList />
			</div>
		</MainWrapper>
	);
};

export default Organizations;
