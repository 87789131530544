import React from "react";
import ContentLoader from "react-content-loader";

const CheckboxSkeletonLoader = () => {
	return (
		<ContentLoader
			speed={3}
			height={160}
			width={"100%"}
			backgroundColor="#DCE7E7"
			foregroundColor="#F9F9F9"
		>
			<rect x="0.9" y="5" width="69%" height="20" />
			<rect x="0.9" y="35" width="78%" height="20" />
			<rect x="0.9" y="65" width="73%" height="20" />
			<rect x="0.9" y="95" width="84%" height="20" />
			<rect x="0.9" y="125" width="74%" height="20" />
		</ContentLoader>
	);
};

export default CheckboxSkeletonLoader;
