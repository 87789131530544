import type {ToastContentProps} from "react-toastify";

import type {TToastData} from "./types";

import {
	ToastCloseIcon,
	ToastErrorIcon,
	ToastInfoIcon,
	ToastSuccessIcon,
	ToastWarningIcon,
} from "assets/icons";

import styles from "./toast.module.scss";

const ToastContent = ({closeToast, data, toastProps}: ToastContentProps<TToastData>) => {
	const {type} = toastProps;

	const iconMap = {
		success: <ToastSuccessIcon onClick={closeToast} />,
		error: <ToastErrorIcon onClick={closeToast} />,
		warning: <ToastWarningIcon onClick={closeToast} />,
		info: <ToastInfoIcon onClick={closeToast} />,
		default: <ToastInfoIcon onClick={closeToast} />,
	};

	const color = {
		success: {
			close: "#194D33",
			button: "#309161",
			background: "#ECF9F2",
			icon: "#39AC73",
			link: "#39AC73",
		},
		error: {
			close: "#610505",
			button: "#C20A0A",
			background: "#FEE7E7",
			icon: "#F20D0D",
			link: "#F20D0D",
		},
		warning: {
			close: "#664400",
			button: "#CC8800",
			background: "#FFEECC",
			icon: "#F5A300",
			link: "#F5A300",
		},
		info: {
			close: "#003B66",
			button: "#0076CC",
			background: "#E5F4FF",
			icon: "#0095FF",
			link: "#0095FF",
		},
		default: {
			close: "#003B66",
			button: "#0076CC",
			background: "#E5F4FF",
			icon: "#0095FF",
			link: "#0095FF",
		},
	}[type];

	return (
		<div className={styles.toast} style={{background: color.background}}>
			{iconMap[type]}
			<div className={styles.textContainer}>
				<div className={styles.title}>{data?.title}</div>
				{data?.description &&
					(typeof data.description === "function" ? (
						data.description(closeToast, color.link)
					) : (
						<div className={styles.description}>{data.description}</div>
					))}
			</div>
			<ToastCloseIcon
				className={styles.close}
				color={color.close}
				onClick={closeToast}
				data-testid="close-icon"
			/>
		</div>
	);
};

export default ToastContent;
