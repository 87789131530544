import {LOGOUT} from "store/mapx/mapXActionTypes";
import {
	CLEAR_TRACKED_EVENT_DATA,
	SET_SYNCED_EVENTS_DATA,
	SET_TRACKED_EVENT_DATA,
} from "../eventTrackingActionTypes";

const initialState = {
	tracked_events: [],
	already_synced_events: [],
};

const eventTrackingReducers = (state = initialState, action) => {
	switch (action.type) {
		case SET_TRACKED_EVENT_DATA:
			return {...state, tracked_events: [...state.tracked_events, action.payload]}; // sets object
		case CLEAR_TRACKED_EVENT_DATA:
			return {...state, tracked_events: []};

		case SET_SYNCED_EVENTS_DATA:
			return {
				...state,
				already_synced_events: [...state.already_synced_events, ...action.payload], // sets array
			};
		case LOGOUT:
			return {
				...initialState,
			};
		default:
			return state;
	}
};

export default eventTrackingReducers;
