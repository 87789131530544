import {type FC, Fragment, useCallback, useRef, useState} from "react";
import {noop} from "lodash";
import classNames from "classnames";
import {toast} from "react-toastify";

import {ToastContent} from "components";
import {DeleteUserModal, OrganizationActionsDropDown} from "mapx-components";
import {CopyLinkIcon, ResendIcon, RevokeIcon, ThreeDotsHorizontal} from "assets/icons";
import {useAppDispatch, useAppSelector, useCopyToClipboard, useOutsideClick} from "hooks";
import {
	resendInvitation,
	revokeInvitation,
} from "store/mapx/organisation/organisationAsyncFunctions";
import {
	resendUserInvitationInProgressSelector,
	revokeInviteInProgressSelector,
} from "store/mapx/organisation/organisationSelectors";

import {initialInvitedState} from "./utils";
import type {TInvitedUsersProps, TOrganizationInviteItemState} from "./types";
import styles from "../ChildeItem/ChildeItem.module.scss";

const InvitedUsers: FC<TInvitedUsersProps> = ({invitedUsers, closeDropdownHandler = noop}) => {
	const ref = useRef<HTMLDivElement | null>(null);

	const dispatch = useAppDispatch();
	const revokeInviteInProgress = useAppSelector(revokeInviteInProgressSelector);
	const resentInvitation = useAppSelector(resendUserInvitationInProgressSelector);

	const [copy] = useCopyToClipboard();

	const [invitedItemBoolState, setInvitedItemBoolState] = useState(initialInvitedState);

	const onToggle = useCallback((key: keyof TOrganizationInviteItemState, value: boolean) => {
		if (value) {
			closeDropdownHandler();
		}

		setInvitedItemBoolState((prevItemBoolState) => ({
			...prevItemBoolState,
			[key]: value,
		}));

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const resendInviteHandler = () => {
		if (invitedUsers?.id) {
			dispatch(resendInvitation(invitedUsers?.id));
		}
	};

	const revokeInviteHandler = () => {
		dispatch(revokeInvitation(invitedUsers?.id));
	};

	const copyLinkHandler = async () => {
		await copy(invitedUsers?.invitation_link as string);
		toast.success(ToastContent, {data: {title: "Link copied to clipboard"}});
		onToggle("showCopyLink", true);
	};

	const modalData = [
		{
			id: 0,
			Icon: ResendIcon,
			label: "Resend Invite",
			onClick: () => onToggle("showResendInvite", true),
		},
		{
			id: 1,
			Icon: CopyLinkIcon,
			label: "Copy Invitation Link",
			onClick: copyLinkHandler,
		},
		{
			id: 2,
			Icon: RevokeIcon,
			isRemove: true,
			label: "Revoke Invite",
			onClick: () => onToggle("showRevokeInvite", true),
		},
	];

	const rows = ["", invitedUsers?.email, invitedUsers?.license_type?.name || "-", "", "", ""];
	const renderSteps = rows?.map((row, idx: number) => (
		<div
			title={row}
			key={`${row}-${idx}`}
			className={classNames(styles.wrapper__btn, {
				[styles.wrapper__btn_right]: idx === 5,
				[styles.wrapper__btn_center]: idx === 4,
			})}
		>
			<p className={styles.wrapper__btn_invite}>{row}</p>
		</div>
	));

	useOutsideClick(ref, () => onToggle("showSettings", false));

	return (
		<Fragment>
			<div className={styles.wrapper}>
				<div className={styles.wrapper__container}>{renderSteps}</div>

				<div
					ref={ref}
					role="button"
					className={styles.wrapper__three}
					onClick={() => onToggle("showSettings", !invitedItemBoolState.showSettings)}
				>
					<ThreeDotsHorizontal className={styles.wrapper__three_dot} />

					{invitedItemBoolState.showSettings && (
						<OrganizationActionsDropDown
							data={modalData}
							className={styles.wrapper__three_modal}
						/>
					)}
				</div>
			</div>

			<DeleteUserModal
				submitText="Resend"
				loading={resentInvitation}
				deleteHandler={resendInviteHandler}
				isOpen={invitedItemBoolState.showResendInvite}
				setIsOpen={(bool) => onToggle("showResendInvite", bool)}
				titleText={`Would you like to resend invitation to “${invitedUsers?.email}”?`}
			/>

			<DeleteUserModal
				submitText="Revoke"
				deleteHandler={revokeInviteHandler}
				loading={revokeInviteInProgress}
				isOpen={invitedItemBoolState.showRevokeInvite}
				setIsOpen={(bool) => onToggle("showRevokeInvite", bool)}
				titleText={`Would you like to revoke “${invitedUsers?.email}” invitation?`}
			/>
		</Fragment>
	);
};

export default InvitedUsers;
