import type {FC, Key} from "react";
import {useMemo} from "react";
import {noop} from "lodash";
import classNames from "classnames";
import {Tooltip} from "react-tooltip";
import {BiUser} from "react-icons/bi";
import {RecycleBin} from "assets/icons";
import {Button, CompanyTooltip, LazyLoadImageWrapper} from "components";

import type {TProjectsListProps} from "./types";
import styles from "./HeaderDropDown.module.scss";

type itemType = {
	logo_url?: string;
	avatar_url?: string;
	id?: number | Key | null;
	name?: string | number | boolean | null;
	full_name?: string | boolean | null;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	experience?: any;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	positions?: any;
};

const ProjectsList: FC<TProjectsListProps> = ({
	project,
	pathName,
	showItem,
	scrollList,
	deleteItem,
	onClick = noop,
	handleTrashClick = noop,
	onHoverTooltip,
}) => {
	const isCompanyItem = useMemo(() => {
		return scrollList.length > 0 && scrollList[0].size !== undefined;
	}, [scrollList]);

	const mappedTitle = (item: itemType) => {
		if (item) {
			if (item?.name && typeof item.name === "string") {
				return item.name.length > 35 ? item.name.slice(0, 32) + "..." : item.name;
			} else if (item?.full_name && typeof item.full_name === "string") {
				return item.full_name.length > 35
					? item.full_name.slice(0, 32) + "..."
					: item.full_name;
			} else {
				return item.name;
			}
		} else {
			return "";
		}
	};

	const renderScrollList = scrollList?.map((item: itemType) => {
		const projectIdItemID: boolean = project?.id === item.id;

		const positions = item?.positions || item?.experience;

		const currentJob = positions?.map((val: {job_title?: string}) => val?.job_title);

		return (
			<div key={item.id}>
				<Button
					defaultBtnStyle
					className={classNames(styles.list__scroll__item, {
						[styles.list__scroll__item_order]: projectIdItemID,
					})}
					to={showItem ? "" : `/${pathName}/${item.id}`}
					onClick={() => onClick(item)}
				>
					<div className={styles.list__scroll__item_left}>
						{(item.logo_url || item.avatar_url) && (
							<LazyLoadImageWrapper
								alt={item.name?.toString() || item.full_name?.toString() || ""}
								image={
									item.logo_url ||
									item.avatar_url ||
									"https://storage.googleapis.com/nebula-static/logos100x100/empty_logo.png"
								}
								className={styles.list__scroll__item_left_img}
							/>
						)}

						{item.avatar_url === null && (
							<BiUser className={styles.list__scroll__item_left_img} />
						)}

						<div className={styles.list__scroll__item_left_texts}>
							<div
								data-tooltip-id={`${item.name}`}
								className={styles.list__scroll__item_left_texts_cont}
							>
								{projectIdItemID && (
									<span
										className={styles.list__scroll__item_left_texts_cont_badge}
									/>
								)}

								<p
									className={classNames(
										styles.list__scroll__item_left_texts_cont_name,
										{
											[styles.list__scroll__item_left_texts_cont_name_active]:
												projectIdItemID,
										},
									)}
								>
									{mappedTitle(item)}
								</p>
							</div>

							{positions && (
								<span className={styles.list__scroll__item_left_texts_sub_name}>
									{currentJob[0]}
								</span>
							)}
						</div>
					</div>

					<div className={styles.list__scroll__item_right}>
						{deleteItem && <RecycleBin onClick={(e) => handleTrashClick(e, item.id)} />}
					</div>
				</Button>

				{onHoverTooltip && isCompanyItem && (
					<Tooltip
						clickable
						place="left"
						delayShow={500}
						id={`${item.name}`}
						className={styles.tooltip}
					>
						<CompanyTooltip company={item} />
					</Tooltip>
				)}
			</div>
		);
	});

	return <>{scrollList?.length ? renderScrollList : <></>}</>;
};

export default ProjectsList;
