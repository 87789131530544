import type {FC} from "react";

import type {IconProps} from "./types";

const ExternalLinkIcon: FC<IconProps> = ({color = "#4E5555", ...rest}) => {
	return (
		<svg
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...rest}
		>
			<path
				d="M5.33333 4C4.60462 4 4 4.60462 4 5.33333V14.6667C4 15.3954 4.60462 16 5.33333 16H14.6667C15.3954 16 16 15.3954 16 14.6667V10H14.6667V14.6667H5.33333V5.33333H10V4H5.33333ZM11.3333 4V5.33333H13.724L7.52865 11.5286L8.47135 12.4714L14.6667 6.27604V8.66667H16V4H11.3333Z"
				fill={color}
			/>
		</svg>
	);
};

export default ExternalLinkIcon;
