import {TExperienceCalculationObj} from "mapx-components/Cards/CandidateCard/CandidateExpertise/types";

export const formatData = (data: TExperienceCalculationObj) => {
	const arr = [];

	for (const key in data) {
		arr.push({
			id: key,
			name: key,
			secondText: `${data[key]}%`,
			is_checked: true,
			percentage: data[key],
		});
	}

	return arr.sort((a, b) => b.percentage - a.percentage);
};
