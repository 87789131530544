import ContentLoader from "react-content-loader";
import type {FC} from "react";

const MarketMapSkeletonCard: FC = (props) => (
	<ContentLoader
		title=""
		speed={2}
		width="100%"
		height={120}
		backgroundColor="#DCE7E7"
		foregroundColor="#F9F9F9"
		{...props}
	>
		<rect x="10" y="8" rx="3" ry="3" width="25%" height="18" />
		<rect x="11" y="42" rx="3" ry="3" width="80%" height="10" />
		<rect x="11" y="58" rx="3" ry="3" width="30%" height="10" />
		<circle cx="25" cy="100" r="16" />
		<circle cx="50" cy="100" r="16" />
		<circle cx="75" cy="100" r="16" />
		<circle cx="100" cy="100" r="16" />
		<circle cx="125" cy="100" r="16" />
	</ContentLoader>
);

export default MarketMapSkeletonCard;
