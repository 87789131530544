import {type FC, Fragment, useEffect, useRef, useState} from "react";

import {Button} from "components";
import {DeleteUserIcon} from "assets/icons";
import {useLockBodyScroll, useOutsideClick} from "hooks";

import type {TResetPasswordModalProps} from "./types";
import styles from "./ResetPasswordModal.module.scss";

const ResetPasswordModal: FC<TResetPasswordModalProps> = ({
	email,
	isOpen,
	loading,
	setIsOpen,
	resetPassHandler,
}) => {
	const ref = useRef<HTMLDivElement | null>(null);
	const [isSended, setIsSended] = useState<boolean>(true);

	const sendVerifyModalHandler = () => {
		setIsSended(true);
		resetPassHandler?.();
	};

	const closeModalHandler = () => {
		setIsOpen(false);
	};

	useEffect(() => {
		if (!loading && isOpen && isSended) {
			closeModalHandler();
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loading]);

	useLockBodyScroll(isOpen);
	useOutsideClick(ref, closeModalHandler);

	return (
		<Fragment>
			{isOpen && (
				<div className={styles.container} data-testid="reset-password-modal">
					<div ref={ref} className={styles.wrapper}>
						<DeleteUserIcon className={styles.wrapper__icon} fill="#f5a300" />
						<p className={styles.wrapper__title}>Send reset password e-mail?</p>
						<div
							className={styles.wrapper__subtitle}
							data-testid="reset-password-modal-text"
						>
							We will send reset password e-mail to
							<p className={styles.wrapper__subtitle_email}>{email}</p>
						</div>
						<div className={styles.wrapper__buttons}>
							<Button
								className={styles.wrapper__buttons_cancel}
								onClick={closeModalHandler}
							>
								Back
							</Button>
							<Button
								loading={loading}
								onClick={sendVerifyModalHandler}
								className={styles.wrapper__buttons_delete}
							>
								Reset
							</Button>
						</div>
					</div>
				</div>
			)}
		</Fragment>
	);
};

export default ResetPasswordModal;
