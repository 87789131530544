/* eslint-disable no-useless-escape */
import {object, ref, string} from "yup";

import type {Field, TForm} from "types";
import {EyeShowIcon, EyeHideIcon} from "assets/icons";

const fields: Field[] = [
	{
		type: "password",
		name: "currentPassword",
		label: "Current Password",
		placeholder: "Current Password",
		RightIcon: EyeHideIcon,
		RightToggledIcon: EyeShowIcon,
	},
	{
		type: "password",
		name: "newPassword",
		label: "New Password",
		placeholder: "New Password",
		RightIcon: EyeHideIcon,
		RightToggledIcon: EyeShowIcon,
	},
	{
		type: "password",
		name: "repeatNewPassword",
		label: "Repeat New Password",
		placeholder: "Repeat New Password",
		RightIcon: EyeHideIcon,
		RightToggledIcon: EyeShowIcon,
	},
];

const schema = object().shape({
	currentPassword: string().required("This is a required field"),
	newPassword: string()
		.required("New password is a required field")
		.matches(/^[^\s]+$/, "Must not contain whitespace.")
		.matches(/[A-Z]/, "Must have at least one Uppercase.")
		.matches(/[a-z]/, "Must have at least one Lowercase.")
		.matches(/[!@#\$%^&*(),.?":{}|<>]/, "Must have at least one Special Symbol.")
		.min(8, "Must be minimum 8 characters long.")
		.max(20, "Must be maximum 20 characters long.")
		.notOneOf(
			[ref("currentPassword"), null],
			"The new password must not match the previous one",
		),

	repeatNewPassword: string()
		.required("This field is required.")
		.oneOf([ref("newPassword"), null], "Passwords do not match."),
});

const changePassForm: TForm = {
	fields,
	schema,
};

export default changePassForm;
