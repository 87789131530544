import {
	ElementType,
	cloneElement,
	createContext,
	forwardRef,
	isValidElement,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import {
	autoUpdate,
	flip,
	FloatingPortal,
	offset,
	shift,
	useDelayGroup,
	useDelayGroupContext,
	useDismiss,
	useFloating,
	useFocus,
	useHover,
	useId,
	useInteractions,
	useMergeRefs,
	useRole,
	useTransitionStyles,
	useClientPoint,
	safePolygon,
} from "@floating-ui/react";
import {arrow, autoPlacement} from "@floating-ui/react-dom";

import type {ReactElement, HTMLProps, ReactNode} from "react";

import type {TTooltipOptions} from "./types";

import StandardTooltip from "components/TooltipComponents/StandardTooltip";
import {ITooltipComponent} from "components/TooltipComponents/types";

export function useTooltip({
	allowInteraction = false,
	crossAxis = 0,
	followMouse = false,
	initialOpen = false,
	mainAxis = 5,
	onOpenChange: setControlledOpen,
	open: controlledOpen,
	placement,
	showArrow = false,
}: TTooltipOptions & {mainAxis?: number; crossAxis?: number} = {}) {
	const [uncontrolledOpen, setUncontrolledOpen] = useState(initialOpen);

	const arrowRef = useRef<HTMLDivElement | null>(null);
	const [arrowPosition, setArrowPosition] = useState({x: 0, y: 0, placement: "top"});

	const open = controlledOpen ?? uncontrolledOpen;
	const setOpen = setControlledOpen ?? setUncontrolledOpen;

	const {delay} = useDelayGroupContext();

	const middlewares = [offset({mainAxis, crossAxis}), flip(), shift()];

	if (!placement) {
		middlewares.push(autoPlacement());
	}

	if (showArrow) {
		middlewares.push(arrow({element: arrowRef.current!})); // eslint-disable-line @typescript-eslint/no-non-null-assertion
	}

	const data = useFloating({
		placement,
		open,
		onOpenChange: setOpen,
		whileElementsMounted: autoUpdate,
		middleware: middlewares,
	});

	useEffect(() => {
		if (data.middlewareData.arrow) {
			const {x, y} = data.middlewareData.arrow;
			setArrowPosition({x: x ?? 0, y: y ?? 0, placement: data.placement});
		}
	}, [data.middlewareData, data.placement]);

	const context = data.context;

	const hover = useHover(context, {
		move: true,
		enabled: controlledOpen == null,
		delay,
		handleClose: allowInteraction ? safePolygon({blockPointerEvents: true}) : undefined,
	});
	const focus = useFocus(context, {
		enabled: controlledOpen == null,
	});
	const dismiss = useDismiss(context);
	const role = useRole(context, {role: "tooltip"});

	if (followMouse) {
		useClientPoint(context);
	}

	const list = [hover, focus, dismiss, role];

	const interactions = useInteractions(list);

	return useMemo(
		() => ({
			open,
			setOpen,
			arrowRef,
			showArrow,
			arrowPosition,
			allowInteraction,
			...interactions,
			...data,
		}),
		[open, setOpen, showArrow, arrowPosition, allowInteraction, interactions, data],
	);
}

type ContextType = ReturnType<typeof useTooltip> | null;

const TooltipContext = createContext<ContextType>(null);

export const useTooltipState = () => {
	const context = useContext(TooltipContext);

	if (context == null) {
		throw new Error("Tooltip components must be wrapped in <Tooltip />");
	}

	return context;
};

export function Tooltip({
	children,
	...options
}: {
	children: ReactNode;
	mainAxis?: number;
	crossAxis?: number;
} & TTooltipOptions) {
	// This can accept any props as options, e.g. `placement`,
	// or other positioning options.
	const tooltip = useTooltip(options);

	return <TooltipContext.Provider value={tooltip}>{children}</TooltipContext.Provider>;
}

export const TooltipTrigger = forwardRef<HTMLElement, HTMLProps<HTMLElement> & {asChild?: boolean}>(
	function TooltipTrigger({children, asChild = false, ...props}, propRef) {
		const state = useTooltipState();

		const childrenRef = (children as ReactElement).props.ref;
		const ref = useMergeRefs([state.refs.setReference, propRef, childrenRef]);

		// `asChild` allows the user to pass any element as the anchor
		if (asChild && isValidElement(children)) {
			return cloneElement(
				children,
				state.getReferenceProps({
					ref,
					...props,
					...children.props,
					"data-state": state.open ? "open" : "closed",
				}),
			);
		}

		return (
			<button
				ref={ref}
				// The user can style the trigger based on the state
				data-state={state.open ? "open" : "closed"}
				{...state.getReferenceProps(props)}
			>
				{children}
			</button>
		);
	},
);

export const TooltipContent = forwardRef<
	HTMLDivElement,
	{TooltipComponent?: ElementType<ITooltipComponent>} & HTMLProps<HTMLDivElement>
>(function TooltipContent({TooltipComponent = StandardTooltip, ...rest}, propRef) {
	const state = useTooltipState();
	const id = useId();
	const {isInstantPhase, currentId} = useDelayGroupContext();
	const ref = useMergeRefs([state.refs.setFloating, propRef]);

	useDelayGroup(state.context, {id});

	const instantDuration = 0;
	const duration = 250;

	const {isMounted, styles} = useTransitionStyles(state.context, {
		duration: isInstantPhase
			? {
					open: instantDuration,
					// `id` is this component's `id`
					// `currentId` is the current group's `id`
					close: currentId === id ? duration : instantDuration,
			  }
			: duration,
		initial: {
			opacity: 0,
		},
	});

	return (
		<FloatingPortal>
			{isMounted && (
				<div
					ref={ref}
					style={{
						left: state.x ?? 0,
						pointerEvents: state.allowInteraction ? "auto" : "none",
						position: state.strategy,
						top: state.y ?? 0,
						visibility: state.x == null ? "hidden" : "visible",
						zIndex: 110,
						...rest.style,
						...styles,
					}}
					{...state.getFloatingProps(rest)}
				>
					<TooltipComponent
						arrowPosition={state.arrowPosition}
						ref={state.arrowRef}
						showArrow={state.showArrow}
					>
						{rest.children}
					</TooltipComponent>
				</div>
			)}
		</FloatingPortal>
	);
});

Tooltip.Content = TooltipContent;

Tooltip.Trigger = TooltipTrigger;

export default Tooltip;
