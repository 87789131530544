import HttpClient from "api/index";
import {AxiosRequestConfig} from "axios";
import {STLLMJobTitleSuggestionPayload} from "api/filterOptionsApi/JobTitleApi/types";

class MapxJobTitleApi extends HttpClient {
	async get(pageNumber: number, config = {}) {
		return this.doGet(`/job-titles?page=${pageNumber}&per_page=40`, config);
	}

	async search(searchTerm: string, pageNumber: number, config = {}) {
		return this.doGet(
			`/job-titles?search=${searchTerm}&page=${pageNumber}&per_page=40`,
			config,
		);
	}

	async suggestion(candidateIds: number[], config = {}) {
		return this.doGet(`/job-title-suggestions?candidates=${candidateIds.join(",")}`, config);
	}

	async classifications(payload: {job_titles: string[]}, config?: AxiosRequestConfig) {
		return this.doPost(`/job-title-classifications`, payload, config);
	}

	async getLLMJobTitleSuggestions(
		payload: STLLMJobTitleSuggestionPayload,
		config?: AxiosRequestConfig,
	) {
		return this.doPost(`/get-job-title-llm-suggestions`, payload, config);
	}
}

const mapxJobTitleApi = new MapxJobTitleApi();

export default mapxJobTitleApi;
