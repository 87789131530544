import type {FC} from "react";

import {Button} from "components";
import {OnboardingTooltip} from "mapx-components";
import {LockIcon, IconFilter} from "assets/icons";
import {filterData} from "mapx-components/OnboardingTooltip/utils";
import {EOnboardingTooltipSteps} from "mapx-components/OnboardingTooltip/types";

import type {TStepBoardingType} from "../../types";

import styles from "./FakeResults.module.scss";

const FakeFilter: FC<TStepBoardingType> = ({step}) => (
	<div className={styles.fake_filter}>
		<div className={styles.fake_filter__head}>
			<IconFilter />

			<p className={styles.fake_filter__head_text}>Filters</p>
		</div>

		<div className={styles.fake_filter__filters}>
			{filterData.map(({id, name}) => (
				<Button
					key={id}
					defaultBtnStyle
					RightIcon={LockIcon}
					className={styles.fake_filter__filters_item}
				>
					{name}
				</Button>
			))}
		</div>

		<OnboardingTooltip
			step={step === EOnboardingTooltipSteps.Fourth ? step : null}
			className={styles.fake_filter__boarding}
		/>
	</div>
);

export default FakeFilter;
