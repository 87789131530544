import React, {FC} from "react";
import classNames from "classnames";
import css from "mapx-pages/Project/Overview/ProjectStats/StatsSkeletonLoader/statsSkeletonLoader.module.scss";
import {TProjectStatsSkeletonLoaderProps} from "mapx-pages/Project/Overview/ProjectStats/StatsSkeletonLoader/types";
import IndustriesStatsSkeleton from "mapx-pages/Project/Overview/ProjectStats/Loader/ProjectStatsSkeleton";

const SkeletonLoaderProjectStats: FC<TProjectStatsSkeletonLoaderProps> = ({
	count,
	customClass = "",
}) => {
	return (
		<div className={classNames(css.block, {[customClass]: customClass})}>
			{Array.from({length: count}, (_, idx) => (
				<div key={idx} className={css.loaderElement}>
					<IndustriesStatsSkeleton />
				</div>
			))}
		</div>
	);
};

export default SkeletonLoaderProjectStats;
