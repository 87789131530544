import {useCallback, useRef, useState} from "react";
import classNames from "classnames";

import colors from "styles/themes.module.scss";
import {ChevronIcon, CloseIcon} from "assets/icons";

import {
	TSelectWithInfiniteScrollOptions,
	ISelectWithInfiniteScrollProps,
	TSelectWithActionGroup,
} from "./types";

import styles from "./index.module.scss";
import {useOutsideClick} from "hooks";
import Loader from "components/Plugins/Loader";
import useInfiniteScroll from "react-infinite-scroll-hook";

const SelectWithInfiniteScroll = ({
	action,
	actionClick,
	className,
	clearable = false,
	contentEditable = false,
	dropdownHeight = 252,
	isSelected = false,
	loading,
	onScroll,
	options,
	pagination,
	placeholder,
	selected,
	setSelected,
}: ISelectWithInfiniteScrollProps) => {
	const ref = useRef<HTMLDivElement>(null);

	const expandedPointer = useRef<boolean>(false);

	const [expanded, setExpanded] = useState<boolean>(false);

	const toggleExpand = useCallback(() => {
		expandedPointer.current = !expanded;

		setExpanded(!expanded);
	}, [expanded]);

	const handleSelect = useCallback(
		(option: TSelectWithInfiniteScrollOptions) => {
			setSelected(option);
			toggleExpand();
		},
		[setSelected, toggleExpand],
	);

	const handleActionClick = useCallback(() => {
		actionClick && actionClick();
		toggleExpand();
	}, [actionClick, toggleExpand]);

	useOutsideClick(ref, () => setExpanded(false));

	const hasNextPage = pagination.pages > pagination.page && !loading;

	const [sentryRef] = useInfiniteScroll({
		loading,
		onLoadMore: () => onScroll(),
		rootMargin: "0px 0px 400px 0px",
		hasNextPage,
	});

	return (
		<div className={classNames(styles.container, {[styles.expanded]: expanded}, className)}>
			<div
				ref={ref}
				role="select"
				className={classNames(styles.select, {[styles.expanded]: expanded})}
			>
				<div
					className={classNames(styles.textbox, {
						[styles.expanded]: expanded,
						[styles.editable]: contentEditable,
						[styles.data]: selected,
					})}
					suppressContentEditableWarning
					contentEditable={contentEditable}
					role="textbox"
					onClick={toggleExpand}
				>
					<div
						className={classNames(styles.text, {
							[styles.selectedText]: selected && !!selected?.label,
						})}
					>
						{(selected && selected.label) || placeholder}
					</div>
					{clearable && !!(selected && selected.label) ? (
						<CloseIcon
							width={18}
							height={18}
							color="#A39C9A"
							onClick={(e) => {
								e.stopPropagation();
								setSelected({value: null, label: null});
							}}
						/>
					) : (
						<ChevronIcon />
					)}
				</div>
				<div
					className={classNames(styles.dropdown, {
						[styles.expanded]: expanded,
						[styles.withAction]: action && !isSelected,
					})}
					style={{
						maxHeight: dropdownHeight,
					}}
				>
					{options.map((option: TSelectWithInfiniteScrollOptions) => (
						<div
							role="button"
							key={option.value}
							className={styles.option}
							onClick={() => handleSelect(option)}
						>
							{option.label}
						</div>
					))}
					{action && !isSelected && (
						<div role="button" className={styles.action} onClick={handleActionClick}>
							{action}
						</div>
					)}

					{hasNextPage && (
						<div
							ref={sentryRef}
							style={{
								width: "100%",
								height: 1,
								display: "flex",
								justifyContent: "center",
							}}
						>
							<Loader
								width={25}
								height={25}
								type={"ThreeDots"}
								data-testid="select-loader"
								color={colors.loaderDotColor}
								displayAtCenterOfPage={false}
							/>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

// To not set z-index for each SelectWithAction, group them together
// in reverse order by wrapping with <SelectWithAction.Group>
const SelectWithActionGroup = ({children, ...rest}: TSelectWithActionGroup) => (
	<div className={styles.group} {...rest}>
		{children}
	</div>
);

SelectWithInfiniteScroll.Group = SelectWithActionGroup;

export default SelectWithInfiniteScroll;
