// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck TODO: Add state type

import {createSelector} from "reselect";
import {userSelector} from "../user/userSelectors";

export const revokingUserInProgressSelector = (state) => state.organisation.revokingUserInProgress;

export const currentUsersSelector = (state) => state.organisation.currentUsers;

export const currentUsersOrderMapSelector = (state) => state.organisation.currentUsersOrderMap;

export const invitedUsersOrderMapSelector = (state) => state.organisation.invitedUsersOrderMap;

export const invitedUsersSelector = (state) => state.organisation.invitedUsers;

export const invitingUserInProgressSelector = (state) => state.organisation.invitingUserInProgress;

export const revokingInvitationInProgressSelector = (state) =>
	state.organisation.revokingInvitationInProgress;

export const organisationsSelector = (state) => state.organisation.organisations;

export const organizationsListSelector = (state) => state.organisation.organizationsList;
export const organizationsListProgressSelector = (state) =>
	state.organisation.organizationsListProgress;

export const orgUsersListSelector = (state) => state.organisation.orgUsersList;

export const organizationTableDataSelector = (state) => state.organisation.organizationTableData;
export const organizationTableDataInProgressSelector = (state) =>
	state.organisation.organizationTableDataInProgress;
export const organizationsListOrderingSelector = (state) =>
	state.organisation.organizationsListOrdering;

export const orgUsersListProgressSelector = (state) => state.organisation.orgUsersListProgress;

export const getInvitedUsersInProgressSelector = (state) =>
	state.organisation.getInvitedUsersInProgress;

export const organisationsInProgressSelector = (state) =>
	state.organisation.organisationsInProgress;

export const updateOrganizationsInProgressSelector = (state) =>
	state.organisation.updateOrganizationsInProgress;

export const deleteOrgProgressSelector = (state) => state.organisation.deleteOrgProgress;

export const createOrganisationInProgressSelector = (state) =>
	state.organisation.createOrganisationInProgress;

export const createInvitationInProgressSelector = (state) =>
	state.organisation.createInvitationInProgress;

export const createInvitationSuccessSelector = (state) =>
	state.organisation.createInvitationSuccess;

export const newOrganisationNameSelector = (state) => state.organisation.newOrganisationName;

export const organizationPaginationSelector = (state) => state.organisation.pagination;

export const resendUserInvitationInProgressSelector = (state) =>
	state.organisation.resendUserInvitationInProgress;

export const revokeInviteInProgressSelector = (state) => state.organisation.revokeInviteInProgress;

export const creditsUsedKeySelector = (state) => state.organisation.creditsUsedKey;

export const organisationSeatsSelector = (state) => state.organisation.seats;

export const seatsInProgressSelector = (state) => state.organisation.seatsInProgress;

export const organisationAdminSelector = createSelector([orgUsersListSelector], (users) =>
	users.find((user) => user.organisation_role === "Admin"),
);

export const orgPlanDistributionSelector = createSelector(
	[orgUsersListSelector, invitedUsersSelector, organisationSeatsSelector],
	(users, invitedUsers, seats) => {
		const distribution = {};

		users.forEach((user) => {
			const name = user.license ? user.license.type.name : "None";

			distribution[name] = (distribution[name] || 0) + 1;
		});

		invitedUsers.forEach((user) => {
			const name = user.license_type ? user.license_type.name : "None";

			distribution[name] = (distribution[name] || 0) + 1;
		});

		seats.seats.forEach((seat) => {
			if (!distribution[seat.license_type]) {
				distribution[seat.license_type] = 0;
			}
		});

		return Object.entries(distribution).map(([key, value]) => {
			const seat = seats.seats.find((s) => s.license_type === key);

			// @TODO: No need to define "value as number" here once the server types are properly defined.
			return {license_type: key, used: value as number, total: seat ? seat.seats : 0};
		});
	},
);

export const organisationHasPaidPlanSelector = createSelector(
	[orgUsersListSelector, userSelector, organisationSeatsSelector],
	(users, user, seats) => {
		if (user.organisation_role === "Admin") {
			return seats.seats.some(
				(s) => s.license_type !== "Starter" && s.license_type !== "Prospect",
			);
		} else {
			return users.some((user) => user.organisation_role === "Admin");
		}
	},
);
