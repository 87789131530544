import {type FC, useState} from "react";

import {useAppSelector} from "hooks";
import {marketMapCreatedBySelector} from "store/mapx/market-map/marketMapSelectors";
import type {TMultiSelectOptions} from "components/Inputs/MultiSelectWithAction/types";

import List from "./List";
import Header, {options} from "./Header";
import styles from "./styles.module.scss";

const MarketMaps: FC = () => {
	const createdBy = useAppSelector(marketMapCreatedBySelector);

	const [search, setSearch] = useState("");
	const [selected, setSelected] = useState<TMultiSelectOptions[]>(
		createdBy.length === 0 ? options : options.filter((item) => createdBy.includes(item.value)),
	);

	return (
		<div className={styles.container}>
			<Header
				search={search}
				setSearch={setSearch}
				createdBy={selected}
				setCreatedBy={setSelected}
			/>
			<List search={search} createdBy={selected} />
		</div>
	);
};

export default MarketMaps;
