import type {FC} from "react";

import type {IconProps} from "./types";

const UnknownError: FC<IconProps> = (props) => {
	return (
		<svg
			width="256"
			height="256"
			viewBox="0 0 256 256"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<g clipPath="url(#clip0_5378_5955)">
				<path
					d="M128 256C198.692 256 256 198.692 256 128C256 57.3075 198.692 0 128 0C57.3075 0 0 57.3075 0 128C0 198.692 57.3075 256 128 256Z"
					fill="#F4F4F4"
				/>
				<rect x="36" y="58" width="184" height="140" rx="8" fill="#CBC5C2" />
				<path
					d="M47.9062 74C50.1154 74 51.9062 72.2091 51.9062 70C51.9062 67.7909 50.1154 66 47.9062 66C45.6971 66 43.9062 67.7909 43.9062 70C43.9062 72.2091 45.6971 74 47.9062 74Z"
					fill="#0C5850"
				/>
				<path
					d="M59.9062 74C62.1154 74 63.9062 72.2091 63.9062 70C63.9062 67.7909 62.1154 66 59.9062 66C57.6971 66 55.9062 67.7909 55.9062 70C55.9062 72.2091 57.6971 74 59.9062 74Z"
					fill="#0C5850"
				/>
				<path
					d="M71.9062 74C74.1154 74 75.9062 72.2091 75.9062 70C75.9062 67.7909 74.1154 66 71.9062 66C69.6971 66 67.9062 67.7909 67.9062 70C67.9062 72.2091 69.6971 74 71.9062 74Z"
					fill="#0C5850"
				/>
				<path
					d="M212 72C212 73.1 211.025 74 209.833 74H84.1667C82.975 74 82 73.1 82 72V68C82 66.9 82.975 66 84.1667 66H209.833C211.025 66 212 66.9 212 68V72Z"
					fill="#F5F5F5"
				/>
				<path
					d="M212 186C212 188.2 210.2 190 208 190H48C45.8 190 44 188.2 44 186V86C44 83.8 45.8 82 48 82H208C210.2 82 212 83.8 212 86V186Z"
					fill="#F5F5F5"
				/>
				<mask
					id="mask0_5378_5955"
					style={{maskType: "alpha"}}
					maskUnits="userSpaceOnUse"
					x="104"
					y="112"
					width="48"
					height="48"
				>
					<rect x="104" y="112" width="48" height="48" fill="#D9D9D9" />
				</mask>
				<g mask="url(#mask0_5378_5955)">
					<path
						d="M128.002 146.153C128.512 146.153 128.952 145.967 129.325 145.595C129.697 145.222 129.884 144.781 129.884 144.272C129.884 143.765 129.697 143.323 129.325 142.945C128.952 142.567 128.512 142.378 128.002 142.378C127.493 142.378 127.052 142.567 126.679 142.945C126.307 143.323 126.121 143.765 126.121 144.272C126.121 144.778 126.307 145.218 126.679 145.592C127.052 145.966 127.493 146.153 128.002 146.153ZM126.43 139.001H129.574V125.433H126.43V139.001ZM120.382 154.299L109.703 143.622V128.368L120.38 117.689H135.634L146.313 128.366V143.62L135.636 154.299H120.382ZM121.795 150.892H134.204L142.894 142.207V129.798L134.209 121.108H121.8L113.11 129.793V142.202L121.795 150.892Z"
						fill="#0C5850"
					/>
				</g>
			</g>
			<defs>
				<clipPath id="clip0_5378_5955">
					<rect width="256" height="256" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};

export default UnknownError;
