import {FC, Fragment} from "react";
import ContentLoader from "react-content-loader";

const emptyArray = new Array(9).fill("");

const OrganizationTableSkeleton: FC = () => (
	<ContentLoader
		width={"100%"}
		height={"100%"}
		viewBox="0 0 1200 400"
		backgroundColor="#f3f3f3"
		foregroundColor="#ecebeb"
	>
		<rect x="0" y="0" rx="5" ry="5" r="0 4 0 4" width="100%" height="40" />

		{emptyArray.map((_, i) => {
			const renderYCount = i > 1 ? 50 * i : 50;

			return (
				<Fragment key={i}>
					<rect x="0" y={renderYCount} rx="4" ry="4" r="0" width="40" height="40" />
					<rect x="4%" y={renderYCount} rx="4" ry="4" r="0" width="34.8%" height="40" />
					<rect x="39.3%" y={renderYCount} rx="4" ry="4" width="13.95%" height="40" />
					<rect x="53.75%" y={renderYCount} rx="4" ry="4" width="13.17%" height="40" />
					<rect x="66.92%" y={renderYCount} rx="4" ry="4" width="21.7%" height="40" />
					<rect x="89.12%" y={renderYCount} rx="4" ry="4" width="16.2%" height="40" />
				</Fragment>
			);
		})}
	</ContentLoader>
);

export default OrganizationTableSkeleton;
