import React, {useCallback} from "react";
import {Tag} from "components";
import FilterTags from "mapx-components/FilterTags";
import {useDispatch, useSelector} from "react-redux";
import {
	moveIndustriesForCandidate,
	setIndustryForCandidate,
} from "store/mapx/filter/industriesFilterAsyncActions";
import {
	candidatePreviousIndustriesSelector,
	selectedPreviousIndustriesSelector,
} from "store/mapx/filter/industriesFilterSelectors";

function PreviousTags() {
	const dispatch = useDispatch();

	const industries = useSelector(selectedPreviousIndustriesSelector);

	const selectedPrevIndustryIds = useSelector(candidatePreviousIndustriesSelector);

	const handleTagClick = useCallback(
		(id) => {
			const nextIds = [...selectedPrevIndustryIds].filter((i) => i !== id);

			dispatch(setIndustryForCandidate({position: "previous", nextIds}));
		},
		[dispatch, selectedPrevIndustryIds],
	);

	const handleTagDragStart = useCallback((e, id) => {
		e.dataTransfer.setData("id", id);
		e.dataTransfer.setData("from", "previous");
	}, []);

	const handleDrop = useCallback(
		(e) => {
			const id = parseInt(e.dataTransfer.getData("id"), 10);
			const from = e.dataTransfer.getData("from");

			dispatch(
				moveIndustriesForCandidate({
					from,
					to: "previous",
					ids: [id],
				}),
			);
		},
		[dispatch],
	);

	return (
		<FilterTags
			onDrop={handleDrop}
			label="Previous"
			count={industries.length}
			tags={industries.map((item) => (
				<Tag
					key={item.id}
					onClick={() => handleTagClick(item.id)}
					draggable
					onDragStart={(e) => handleTagDragStart(e, item.id)}
					onDragOver={(e) => e.preventDefault()}
				>
					{item.name}
				</Tag>
			))}
		/>
	);
}

export default React.memo(PreviousTags);
