import {Dispatch} from "react";
import {TAppDispatch, TRootState} from "types";
import mapXCandidateApi from "api/candidateApi";
import {SICandidate, TGenerateContactDetailsApiPayload} from "api/candidateApi/types";
import {successResponse} from "helpers/map";
import {setGenerateContactDetailsCandidateIdsForAllProject} from "store/mapx/project/projectActions";
import {ICandidateSearchApiRequestPayload} from "api/candidateApi/form";
import axios from "axios";
import {generateCandidatesContactDetailsFlattenedForAllProjectSelector} from "./generateCandidateContactDetailsSelectors";
import {
	filteredSavedCandidatesSelector,
	targetListCandidatesSelector,
} from "../target-list/targetListSelectors";
import {
	updateFilteredTargetListCandidatesWithContactDetails,
	updateTargetListCandidatesWithContactDetails,
} from "../target-list/targetListActions";
import {updateCandidateProfileAfterRefresh} from "../refresh-candidate/refreshCandidateActions";
import {getTargetListCandidates} from "../target-list/targetListCandidatesAsyncActions";

/* eslint-disable  @typescript-eslint/no-explicit-any */
const cancelTokens = {} as any;

export const generateCandidatesContactDetails =
	(candidateIds: number[]) => async (dispatch: Dispatch<TAppDispatch>, getState: TRootState) => {
		try {
			const state = getState();

			const allIds = generateCandidatesContactDetailsFlattenedForAllProjectSelector(state);

			let ids = [];

			if (allIds && Array.isArray(allIds)) {
				ids = allIds;
			}
			ids = [...candidateIds, ...ids];

			const candidateIdsByProject = ids;

			dispatch(setGenerateContactDetailsCandidateIdsForAllProject(candidateIdsByProject));

			const apiPayload: TGenerateContactDetailsApiPayload = {
				candidate_ids: ids,
			};

			await mapXCandidateApi.generateContactDetails(apiPayload);
		} catch (e) {
			console.error(e);
		}
	};

export const checkCandidateContactDetailsGeneratedStatus =
	(candidateIds: number[]) => async (dispatch: Dispatch<TAppDispatch>, getState: TRootState) => {
		const requestKey = `GET_GENERATE_CONTACT_DETAILS_CANDIDATE_STATUS`;

		if (requestKey in cancelTokens) {
			cancelTokens[requestKey].cancel("Operation canceled due to new request.");
		}

		cancelTokens[requestKey] = axios.CancelToken.source();

		const config = {
			cancelToken: cancelTokens[requestKey].token,
		};

		const state = getState();

		try {
			const apiPayload: ICandidateSearchApiRequestPayload = {
				pagination: {
					page: 1,
					per_page: 40,
				},
				filters: {
					candidates: candidateIds,
				},
			};

			const response = await mapXCandidateApi.getCandidatesByFilter(apiPayload, config);

			if (successResponse(response, 200)) {
				const results = response.data.results;

				const savedPeoplesInState = targetListCandidatesSelector(state);
				const filteredSavedPeoplesInState = filteredSavedCandidatesSelector(state);

				let updatedIds = [...candidateIds];

				const updatedPeopleForState: SICandidate[] = [];
				const updatedFilteredPeopleForState: SICandidate[] = [];

				// update states for targetlist people
				savedPeoplesInState.forEach((candidate: SICandidate) => {
					const candidateFromResults = results.find(
						(c: SICandidate) => c.id === candidate.id,
					);
					if (candidateFromResults) {
						candidateFromResults.summary = candidate.summary;
					}
					const updatedCandidate = candidateFromResults ?? candidate;

					if (updatedCandidate.contact_details !== null) {
						dispatch(updateCandidateProfileAfterRefresh(updatedCandidate));
						updatedIds = updatedIds.filter((id) => id !== candidate.id);
					}

					updatedPeopleForState.push(updatedCandidate);

					if (
						filteredSavedPeoplesInState.some((c: SICandidate) => c.id === candidate.id)
					) {
						updatedFilteredPeopleForState.push(updatedCandidate);
					}
				});

				dispatch(updateTargetListCandidatesWithContactDetails(updatedPeopleForState));

				dispatch(
					updateFilteredTargetListCandidatesWithContactDetails(
						updatedFilteredPeopleForState,
					),
				);

				// update states for all candidates list
				results.forEach((candidate: SICandidate) => {
					if (candidate.contact_details !== null) {
						const candidateInfoFromTL = savedPeoplesInState.find(
							(tc: {id: number}) => tc.id === candidate.id,
						);
						if (candidateInfoFromTL?.summary) {
							candidate.summary = candidateInfoFromTL?.summary;
						}
						dispatch(updateCandidateProfileAfterRefresh(candidate));
						updatedIds = updatedIds.filter((id) => id !== candidate.id);
					}
				});

				dispatch(setGenerateContactDetailsCandidateIdsForAllProject([...updatedIds]));
				dispatch(updateTargetListCandidatesWithContactDetails(updatedPeopleForState));

				if (updatedIds.length === 0) {
					dispatch(getTargetListCandidates());
				}
			}
		} catch (e) {
			console.error(e);
		}
	};
