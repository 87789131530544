import HttpClient from "api/index";
import {TSearchRequestCreate, TSearchRequestType} from "api/projectApi/searchRequestApi/types";

class SearchRequestApi extends HttpClient {
	async createSearchRequest(formData: TSearchRequestCreate, config = {}) {
		return this.doPost(`/search-requests`, formData, config);
	}

	async getAllSearchRequestsByProject(
		projectId: number,
		pagination = {page: 1, perPage: 20},
		config = {},
	) {
		return this.doGet(
			`/search-requests?project_id=${projectId}&page=${pagination.page}&per_page=${pagination.perPage}`,
			config,
		);
	}

	async getAllSearchRequestByTypeForProject(
		projectId: number,
		searchType: TSearchRequestType,
		pagination = {page: 1, perPage: 10},
		config = {},
	) {
		let type: string = searchType;

		if (searchType === "Free Text Input Search") {
			type = "Free Text Input Search,Generate More Results";
		}

		return this.doGet(
			`/search-requests?project_id=${projectId}&search_request_type=${type}&page=${pagination.page}&per_page=${pagination.perPage}`,
			config,
		);
	}

	async getSearchDetailsById(searchRequestId: number, config = {}) {
		return this.doGet(`/search-requests/${searchRequestId}`, config);
	}

	async exportSearchRequestLog(searchRequestId: number, config = {responseType: "arraybuffer"}) {
		return this.doGet(`/search-requests/${searchRequestId}/export`, config);
	}
}

const searchRequestApi = new SearchRequestApi();

export default searchRequestApi;
