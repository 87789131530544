import {type FC} from "react";

import {useAppSelector} from "hooks";
import {userLicenseTypeNameSelector} from "store/mapx/user/userSelectors";

import Locations from "./Locations";
// import {HeadcountChart} from "./HeadcountChart";
import ProjectFreqHiredFrom from "./ProjectFreqHiredFrom";
import {GenderDiversityChart} from "./GenderDiversityChart";
// import {CompanyRevenueChart} from "./CompanyRevenueChart";
import {EthnicDiversityChart} from "./EthniticDiversityChart";
import UserPreloadInsightSkeleton from "./UserPreloadInsightSkeleton";
import JobFunctionDistributionChart from "./JobFunctionDistributionChart";
import styles from "./styles.module.scss";

export const Insights: FC = () => {
	const licenseName = useAppSelector(userLicenseTypeNameSelector);

	return (
		<div>
			{licenseName === "Starter" ? (
				<UserPreloadInsightSkeleton />
			) : (
				<div className={styles.insight_container}>
					<div className={styles.insight_container__genders}>
						<GenderDiversityChart />
						<EthnicDiversityChart />
					</div>
					<Locations />

					<JobFunctionDistributionChart />

					<ProjectFreqHiredFrom />

					{/* <CompanyRevenueChart />
        <HeadcountChart /> */}
				</div>
			)}
		</div>
	);
};
