import type {FC} from "react";
import classNames from "classnames";

import {Button} from "components";

import {fakeTabs} from "../utils";
import styles from "./OnboardingProjects.module.scss";

const FakeTabs: FC = () => (
	<div className={styles.tabs}>
		{fakeTabs?.map(({id, title, Icon}, idx) => (
			<Button
				key={id}
				defaultBtnStyle
				LeftIcon={Icon}
				className={classNames({
					[styles.tabs__active]: 4 === idx,
				})}
			>
				{title}
			</Button>
		))}
	</div>
);

export default FakeTabs;
