import {useCallback} from "react";
import {useDispatch} from "react-redux";
import type {ChangeEvent} from "react";

import {CreatePlusIcon} from "assets/icons";
import {Button, MultiSelectWithAction} from "components";
import {SearchInput} from "mapx-components";
import {createMarketMapModal} from "store/mapx/market-map/marketMapActions";

import css from "./Header.module.scss";
import type {THeader} from "./types";

export const options = [
	{label: "MapX", value: "mapx"},
	{label: "Your Organisation", value: "organisation"},
	{label: "You", value: "self"},
];

const Header = ({search, setSearch, createdBy, setCreatedBy}: THeader) => {
	const dispatch = useDispatch();

	const handleOnChange = useCallback(
		(e: ChangeEvent<HTMLInputElement>) => {
			setSearch(e.target.value);
		},
		[setSearch],
	);

	const handleModalDisplay = useCallback(
		(open: boolean) => {
			dispatch(createMarketMapModal(open, {}));
		},
		[dispatch],
	);

	return (
		<div className={css.header}>
			<h1>Target Lists</h1>
			<div className={css.buttonGroup}>
				{/* @TODO: Convert SearchInput into TS */}
				{/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
				{/* @ts-ignore */}
				<SearchInput
					autoComplete="off"
					customClass={css.search}
					onChange={handleOnChange}
					placeholder="Search a Target list"
					type="text"
					value={search}
					margin="0"
				/>
				<MultiSelectWithAction
					className={css.multiSelect}
					isSelected={createdBy.length > 0}
					options={options}
					placeholder="Created by"
					selected={createdBy}
					setSelected={setCreatedBy}
				/>
				<Button
					className={css.createButton}
					LeftIcon={CreatePlusIcon}
					onClick={() => handleModalDisplay(true)}
				>
					Create a Target List
				</Button>
			</div>
		</div>
	);
};

export default Header;
