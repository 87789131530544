import {Fragment, forwardRef} from "react";
import {Loader} from "components";
import colors from "styles/themes.module.scss";

import styles from "./styles.module.scss";

export const SuggestionsListComponent = forwardRef(
	({SuggestionListItemComponent, suggestions, onClick, displayLoader}, ref) =>
		suggestions.length > 0 && (
			<div className={styles.suggestionContainer}>
				<div className={styles.suggestionList}>
					{suggestions.map((suggestion, index) => (
						<Fragment key={index}>
							{SuggestionListItemComponent ? (
								<SuggestionListItemComponent
									suggestion={suggestion}
									index={index}
									onClick={onClick}
								/>
							) : (
								<p
									className={styles.suggestionItem}
									onClick={() => onClick(suggestion)}
								>
									{suggestion.name}
								</p>
							)}
						</Fragment>
					))}
					{!displayLoader && <div ref={ref} style={{width: "100%", height: 1}} />}
					{displayLoader && (
						<div className={styles.loaderWrapper}>
							<Loader
								height={40}
								width={40}
								type="ThreeDots"
								color={colors.loaderDotColor}
							/>
						</div>
					)}
				</div>
			</div>
		),
);

SuggestionsListComponent.displayName = "SuggestionsListComponent";
