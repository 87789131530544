import React from "react";

const PlusIcon = ({color = "#3C6662", ...rest}) => (
	<svg
		width={16}
		height={17}
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		role="img"
		viewBox="0 0 16 17"
		{...rest}
	>
		<path
			d="M8 3.833v9.334M3.333 8.5h9.334"
			stroke={color}
			strokeWidth={2}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);

export default PlusIcon;
