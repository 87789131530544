import type {FC} from "react";

import type {IconProps} from "./types";

const CreatePerson: FC<IconProps> = ({className = ""}) => (
	<svg
		width="20"
		height="20"
		fill="none"
		viewBox="0 0 20 20"
		className={className}
		xmlns="http://www.w3.org/2000/svg"
	>
		<mask
			x="0"
			y="0"
			width="20"
			height="20"
			id="mask0_2524_29504"
			style={{maskType: "alpha"}}
			maskUnits="userSpaceOnUse"
		>
			<rect width="20" height="20" fill="#D9D9D9" />
		</mask>
		<g mask="url(#mask0_2524_29504)">
			<path
				fill="#0C5850"
				d="M14.8398 10.7858V9.07565H13.1297V7.49594H14.8398V5.78579H16.4195V7.49594H18.1297V9.07565H16.4195V10.7858H14.8398ZM8.01508 9.91533C7.13193 9.91533 6.38291 9.60789 5.76804 8.993C5.15315 8.37811 4.8457 7.62909 4.8457 6.74594C4.8457 5.86279 5.15315 5.1146 5.76804 4.50138C6.38291 3.88815 7.13193 3.58154 8.01508 3.58154C8.89823 3.58154 9.64725 3.88815 10.2621 4.50138C10.877 5.1146 11.1845 5.86279 11.1845 6.74594C11.1845 7.62909 10.877 8.37811 10.2621 8.993C9.64725 9.60789 8.89823 9.91533 8.01508 9.91533ZM1.8457 16.2541V14.1581C1.8457 13.7691 1.94113 13.4116 2.13199 13.0856C2.32287 12.7596 2.58538 12.4995 2.91952 12.3053C3.69338 11.8575 4.51049 11.5139 5.37085 11.2745C6.23122 11.0351 7.11263 10.9153 8.01508 10.9153C8.9239 10.9153 9.80856 11.0342 10.6691 11.272C11.5296 11.5098 12.3434 11.8525 13.1106 12.3003C13.4448 12.494 13.7073 12.7534 13.8982 13.0785C14.089 13.4036 14.1845 13.7634 14.1845 14.1578V16.2541H1.8457ZM3.57487 14.5249H12.4553V14.183C12.4553 14.0963 12.4343 14.0176 12.3923 13.9467C12.3503 13.8758 12.2949 13.8207 12.2261 13.7813C11.5933 13.4196 10.9207 13.1397 10.2085 12.9416C9.49621 12.7435 8.76508 12.6445 8.01508 12.6445C7.27173 12.6445 6.54061 12.7435 5.8217 12.9416C5.1028 13.1397 4.43025 13.4196 3.80404 13.7813C3.73529 13.8207 3.6799 13.8758 3.63789 13.9467C3.59588 14.0176 3.57487 14.0963 3.57487 14.183V14.5249ZM8.01933 8.18615C8.41656 8.18615 8.7552 8.04472 9.03525 7.76186C9.31529 7.47898 9.45531 7.13893 9.45531 6.74169C9.45531 6.34447 9.31387 6.00666 9.031 5.72827C8.74812 5.4499 8.40807 5.31071 8.01083 5.31071C7.61361 5.31071 7.27497 5.45168 6.99493 5.73361C6.71489 6.01554 6.57487 6.35445 6.57487 6.75036C6.57487 7.14763 6.71631 7.48625 6.99918 7.76621C7.28206 8.04617 7.62211 8.18615 8.01933 8.18615Z"
			/>
		</g>
	</svg>
);

export default CreatePerson;
