import {Checkbox, ResetFilters} from "components";
import {useAppSelector} from "hooks";
import useFetchListOptions from "hooks/useFetchListOptions";
import {getCompanyHeadcountRanges} from "store/mapx/search/searchAsyncActions";
import css from "containers/Filters/CompanyFilters/IndexFilter/companyIndexFilter.module.scss";
import CheckboxList from "mapx-components/Inputs/CheckboxList";
import {companyHeadcountRangeOptionsSelector} from "store/mapx/search/searchSelectors";
import {
	TCompanyHeadcountRangeItem,
	TCompanyHeadcountSearchFilterProps,
} from "mapx-components/Filters/CompanyHeadcountSearchFilter/types";

const CompanyHeadcountSearchFilter = ({
	handleResetClick,
	handleOnChange,
	selectedHeadcounts,
}: TCompanyHeadcountSearchFilterProps) => {
	const companyHeadcountRanges = useAppSelector(companyHeadcountRangeOptionsSelector);

	const {filteredData} = useFetchListOptions({
		options: companyHeadcountRanges,
		callbackMethod: getCompanyHeadcountRanges,
	});

	return (
		<div>
			<ResetFilters
				parentStyle={{color: "#5A5A5A", marginRight: 19, marginTop: 10}}
				onClick={handleResetClick}
				displayIcon={true}
			>
				Clear Selection
			</ResetFilters>

			<CheckboxList style={{maxHeight: 110}}>
				{filteredData.map((item: TCompanyHeadcountRangeItem) => (
					<Checkbox
						borderColor="#0C5850"
						containerClass={css.checkboxContainer}
						isChecked={selectedHeadcounts?.includes(item.id)}
						key={item.id}
						label={item.name}
						onChange={() => handleOnChange(item)}
						value={item.name}
					/>
				))}
			</CheckboxList>
		</div>
	);
};

export default CompanyHeadcountSearchFilter;
