import {FC, useRef, useEffect, useLayoutEffect, useState} from "react";
import {XAxis, CartesianGrid, YAxis, BarChart, Bar, ResponsiveContainer} from "recharts";

import {
	organizationsListSelector,
	organizationPaginationSelector,
} from "store/mapx/organisation/organisationSelectors";
import {useAppDispatch, useAppSelector, useWindowSize} from "hooks";
import {getOrganizationsList} from "store/mapx/organisation/organisationAsyncFunctions";

import Totals from "./Totals";
import type {TChartData} from "./types";
import CustomizedAxisTick from "./AxisTick";
import styles from "./ProjectPerOrganization.module.scss";

const OrganizationStatistic: FC = () => {
	const {width} = useWindowSize();
	const ref = useRef<HTMLDivElement>(null);

	const dispatch = useAppDispatch();
	const {organisationsPagination, usersPagination} = useAppSelector(
		organizationPaginationSelector,
	);
	const organizations = useAppSelector(organizationsListSelector);

	const [containerWidth, setContainerWidth] = useState<number>(0);

	useEffect(() => {
		const getOrganizationList = async () => {
			await dispatch(getOrganizationsList());
		};

		getOrganizationList();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useLayoutEffect(() => {
		if (ref.current) {
			setContainerWidth(ref.current.offsetWidth - 1);
		}
	}, [width]);

	const projectsCount = organizations?.reduce((accumulator: number, object: TChartData) => {
		return accumulator + (Number(object.projects_count) || 0);
	}, 0);

	const generateHeight =
		organizations?.length &&
		(organizations?.length * 50 > 330 ? organizations?.length * 50 : 330);

	return (
		<div className={styles.statistic}>
			<div className={styles.statistic__chart} ref={ref}>
				<ResponsiveContainer height={generateHeight} minHeight={330}>
					<BarChart
						layout="vertical"
						margin={{left: 15}}
						data={organizations}
						width={containerWidth}
					>
						<XAxis type="number" />

						<YAxis
							width={150}
							dataKey="name"
							type="category"
							minTickGap={20}
							overflow={"scroll"}
							tick={CustomizedAxisTick}
						/>

						<CartesianGrid strokeDasharray="2" horizontal={false} vertical={true} />

						<Bar
							barSize={16}
							fill="#50BEB8"
							radius={[0, 4, 4, 0]}
							dataKey="projects_count"
						/>
					</BarChart>
				</ResponsiveContainer>
			</div>

			<div className={styles.statistic__totals}>
				<div className={styles.statistic__totals_container}>
					<Totals title="Total Organizations" value={organisationsPagination?.count} />
					<Totals title="Total AP Request" value={"-"} />
					<Totals title="Total User" value={usersPagination?.count} />
					<Totals title="Total Projects" value={projectsCount} />
				</div>
			</div>
		</div>
	);
};

export default OrganizationStatistic;
