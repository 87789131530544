import {useEffect, useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Tooltip as ReactTooltip} from "react-tooltip";
import {Annotation, ComposableMap, Geographies, Geography, ZoomableGroup} from "react-simple-maps";

import {Loader} from "components";
import {getCountryData} from "helpers";
import {ZoomIn, ZoomOut} from "assets/icons";
import {SectionWrapper} from "mapx-components";
import {getCountryReport} from "store/mapx/project/projectAsyncActions";

import {Bar} from "./Bar";
import {colors, geographyStyle, getRegionByCountry, width} from "./utils";
import styles from "./styles.module.scss";
import {projectSelector} from "store/mapx/project-list/projectListSelectors";

const Locations = () => {
	const dispatch = useDispatch();

	const project = useSelector(projectSelector);

	const countryReport = useSelector((state) => state.mapxProject.insights.countryReport);

	const [tooltipPosition, setTooltipPosition] = useState({
		x: null,
		y: null,
	});

	const [activeTitle, setActiveTitle] = useState("");

	const [tooltipTitle, setTooltipTitle] = useState("");

	const [loading, setLoading] = useState(false);

	const [position, setPosition] = useState({coordinates: [0, 0], zoom: 1});

	const handleZoomIn = () => {
		setPosition((pos) => ({...pos, zoom: pos.zoom * 2}));
	};

	const handleZoomOut = () => {
		setPosition((pos) => ({...pos, zoom: pos.zoom / 2}));
	};

	const handleMoveEnd = (position) => {
		setPosition(position);
	};

	useEffect(() => {
		if (project?.id) {
			setLoading(true);
			dispatch(getCountryReport(project.id), setLoading(false));
		}
	}, [project?.id, dispatch]);

	const countries = countryReport ? countryReport.map((val) => val?.name) : [];

	const regionsByCountry = useMemo(() => {
		if (!countryReport) {
			return {};
		}

		const regions = {};

		for (const country of countryReport) {
			if (regions[country.region]) {
				regions[country.region] = {
					percentage: regions[country.region].percentage + country.candidates_percent,
					countries: [
						...regions[country.region].countries,
						{name: country.name, candidates_percent: country.candidates_percent},
					],
				};
			} else {
				regions[country.region] = {
					percentage: country.candidates_percent,
					countries: [
						{name: country.name, candidates_percent: country.candidates_percent},
					],
				};
			}
		}

		return regions;
	}, [countryReport]);

	const handleFilter = (event) => {
		return event?.constructor?.name === "MouseEvent";
	};

	const handleMouseMove = (e) => {
		setTooltipPosition({x: e.clientX, y: e.clientY});
	};

	return (
		<SectionWrapper
			label="Locations"
			className={styles.sectionWrapper}
			childrenWrapperClassName={styles.childrenWrapper}
		>
			{loading && (
				<Loader height={30} width={30} type="ThreeDots" color={colors.loaderDotColor} />
			)}

			{!loading && (
				<div>
					<Bar regionsByCountry={regionsByCountry} colors={colors} />

					<div className={styles.zoom}>
						<span>Zoom</span>
						{position.zoom <= 1 ? (
							<div onClick={handleZoomIn}>
								<ZoomIn />
							</div>
						) : null}
						{position.zoom > 1 && position.zoom <= 2 ? (
							<>
								<div onClick={handleZoomIn} className={styles.zoomIn}>
									<ZoomIn />
								</div>
								<div onClick={handleZoomOut}>
									<ZoomOut />
								</div>
							</>
						) : null}
						{position.zoom > 2 ? (
							<div onClick={handleZoomOut}>
								<ZoomOut />
							</div>
						) : null}
					</div>

					<ComposableMap
						key={project?.id}
						projection={"geoMercator"}
						width={width}
						height={width / 1.25}
						projectionConfig={{
							scale: 110,
							rotate: [-11, 0],
						}}
						onMouseMove={(e) => handleMouseMove(e)}
						style={{display: "flex", flexDirection: "center", width: "100%"}}
					>
						<ZoomableGroup
							zoom={position.zoom}
							center={position.coordinates}
							onMoveEnd={handleMoveEnd}
							filterZoomEvent={handleFilter}
							translateExtent={[
								[0, -width / 1.25],
								[width, width / 1.25],
							]}
						>
							<Geographies
								geography="/features.json"
								fill="#D6D6DA"
								stroke="#FFFFFF"
								strokeWidth={0.5}
							>
								{({geographies}) =>
									geographies.map((geo) => {
										const currentGeoRegion = getRegionByCountry(
											geo.properties.name,
											regionsByCountry,
										);
										const regionIdx = countryReport?.findIndex(
											(val) => val.region === currentGeoRegion,
										);

										const color = colors[regionIdx];

										return (
											<Geography
												key={geo.rsmKey}
												onMouseOver={() => {
													if (countries.includes(geo.properties.name)) {
														setActiveTitle(geo.properties.name);
														setTooltipTitle("");
													} else {
														setTooltipTitle(geo.properties.name);
														setActiveTitle("");
													}
												}}
												onMouseLeave={() => {
													setActiveTitle("");
													setTooltipTitle("");
												}}
												geography={geo}
												style={geographyStyle(
													countries.includes(geo.properties.name),
													color,
												)}
												data-tooltip-id={geo.properties.name}
											/>
										);
									})
								}
							</Geographies>

							{countryReport?.map((country, idx) => {
								const countryData = getCountryData(country.code);

								if (!countryData || country.name !== activeTitle) {
									return null;
								}

								const labelPosition = countryData.center_lng > 15 ? 30 : 0;

								return (
									<Annotation
										key={`${country.code}_${idx}`}
										subject={[
											Number(countryData.center_lng),
											Number(countryData.center_lat),
										]}
										dx={-90 / position.zoom}
										dy={labelPosition}
										connectorProps={{
											stroke: "#000000",
											strokeWidth: 1 / position.zoom,
											strokeLinecap: "round",
										}}
									>
										<text
											x="-8"
											textAnchor="end"
											alignmentBaseline="middle"
											y="-2"
											fill="#000"
											fontSize={10}
											style={{transform: `scale(${1 / position.zoom})`}}
										>
											{"Percent of total"}
										</text>
										<text
											x="-8"
											y="-20"
											textAnchor="end"
											alignmentBaseline="middle"
											fill="#0C5850"
											style={{transform: `scale(${1 / position.zoom})`}}
										>
											{country.name}
										</text>
										<text
											x="-8"
											y="20"
											textAnchor="end"
											alignmentBaseline="middle"
											fill="#0C5850"
											style={{transform: `scale(${1 / position.zoom})`}}
										>
											{country.candidates_percent}%
										</text>
									</Annotation>
								);
							})}
						</ZoomableGroup>
					</ComposableMap>
				</div>
			)}
			{tooltipTitle && <ReactTooltip>{tooltipTitle}</ReactTooltip>}

			{tooltipTitle !== "" && (
				<ReactTooltip
					id={tooltipTitle}
					content={tooltipTitle}
					clickable
					isOpen={true}
					position={tooltipPosition}
					positionStrategy={"fixed"}
				/>
			)}
		</SectionWrapper>
	);
};

export default Locations;
