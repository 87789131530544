import EnhanceIcon from "assets/icons/EnhanceIcon";

import classNames from "classnames";
import {decodeCharacterReferences, truncateSentenceEnd} from "helpers/string";

import useEventMonitor from "hooks/mapx/useEventMonitor";
import useUserBehaviorTrackHook from "hooks/mapx/useUserBehaviorTrackHook";

import {ButtonsRow, CompanyInformation, CompanyStatistics, GeneralButton} from "mapx-components";
import React, {useCallback, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
	companySpecialitiesConnectivityLogicSelector,
	specialtiesByLogicSelector,
} from "store/mapx/filter/filterSelectors";
import {addSpecialityClusterToList} from "store/mapx/search/searchActions";
import {
	addCompanyToTargetList,
	removeCompanyFromTargetList,
} from "store/mapx/target-list/targetListAsyncActions";
import {
	isCompanyBeingAddedToTargetListSelector,
	isCompanyBeingRemovedFromTargetListSelector,
	isCompanySavedSelector,
	targetListIDSelector,
} from "store/mapx/target-list/targetListSelectors";
import css from "./companyCard.module.scss";
import {setActiveAccordionOnFilter} from "store/mapx/filter/filterActions";
import {useAppSelector} from "hooks";
import {specialtyClusterOptionsSelector} from "store/mapx/search/searchSelectors";
import {
	getClusterSpecialities,
	setSpecialityClustersForCompany,
} from "store/mapx/filter/companySpecialtyClusterFilterAsyncActions";

export default function CompanyCard({
	company,
	isRecommended = false,
	position = 1,
	type = "listing",
	shouldTrack = true,
}) {
	const dispatch = useDispatch();

	const targetListID = useSelector(targetListIDSelector);

	const [loadingSpecialities, setLoadingSpecialities] = useState(false);

	const {trackStart, trackComplete, itemEventDetails, setEvent, setTargetListId} =
		useUserBehaviorTrackHook({
			itemType: "company",
			itemId: company.id,
			source: type,
			isRecommended,
			position,
		});

	/**
	 ** USER BEHAVIOR TRACKING LOGICS
	 */
	const containerRef = useEventMonitor({shouldTrack, itemEventDetails});

	/**
	 ** SPECIALTY FILTER MATCH & CONTROL
	 */
	const companySpecialitiesConnectivityLogic = useSelector(
		companySpecialitiesConnectivityLogicSelector,
	);

	const specialties = useSelector((state) =>
		specialtiesByLogicSelector(state, companySpecialitiesConnectivityLogic || "and"),
	);

	const specialityOptions = useAppSelector(specialtyClusterOptionsSelector);

	const isSaved = useSelector((state) => isCompanySavedSelector(state, company.id));

	const isCompanyBeingAdded = useSelector((state) =>
		isCompanyBeingAddedToTargetListSelector(state, company.id),
	);

	const isCompanyBeingRemoved = useSelector((state) =>
		isCompanyBeingRemovedFromTargetListSelector(state, company.id),
	);

	const loadingSave = isCompanyBeingAdded || isCompanyBeingRemoved;

	const trackEventReport = useCallback(
		(type) => {
			if (shouldTrack) {
				setTargetListId(targetListID);
				setEvent(type);
			}
		},
		[setEvent, setTargetListId, shouldTrack, targetListID],
	);

	const handleSaveItem = useCallback(() => {
		if (isSaved) {
			dispatch(removeCompanyFromTargetList(company.id));

			trackEventReport("remove");
		} else {
			dispatch(addCompanyToTargetList(company.id));

			trackEventReport("add");
		}
	}, [company.id, dispatch, isSaved, trackEventReport]);

	const handleSelectedSpecialities = async (item) => {
		if (specialityOptions.length === 0) {
			setLoadingSpecialities(true);

			dispatch(setActiveAccordionOnFilter("Specialities"));

			dispatch(getClusterSpecialities());

			setLoadingSpecialities(false);
		}

		const currentSpecialties = specialties ? [...specialties] : [];

		if (!currentSpecialties.includes(item.id)) {
			dispatch(addSpecialityClusterToList(item));
		}

		dispatch(setSpecialityClustersForCompany([item.id], companySpecialitiesConnectivityLogic));

		trackEventReport("click"); // event report for recommendation
	};

	return (
		<div
			data-testid={"companyCard"}
			onMouseEnter={trackStart}
			onMouseLeave={trackComplete}
			ref={containerRef}
		>
			<div className={classNames(css.company, {[css.recommended]: isRecommended})}>
				{isRecommended && (
					<span className={css.enhanceIcon}>
						<EnhanceIcon />
					</span>
				)}

				<div className={css.topRow}>
					<CompanyInformation
						item={company}
						from={type}
						isRecommended={isRecommended}
						position={position}
					/>
					<CompanyStatistics size={company?.size} revenue={company?.revenue} />
				</div>

				{company.description != null ? (
					<div className={css.infoCategory}>
						<span className={classNames(css.infoCompanyDescription, {})}>
							{decodeCharacterReferences(
								truncateSentenceEnd(company.description, 171, 173),
							)}
						</span>
					</div>
				) : (
					<div className={css.infoCategory} style={{minHeight: 20}} />
				)}

				<div className={css.bottomRow}>
					{company.specialties && company.specialties?.length > 0 ? (
						<ButtonsRow
							selectedItems={specialties || []}
							items={company.specialties}
							handleSelectedItem={handleSelectedSpecialities}
							trackEventReport={trackEventReport}
							disabled={loadingSpecialities}
						/>
					) : (
						<span />
					)}

					<div className={css.rightContainer}>
						<GeneralButton
							isClicked={isSaved}
							handleClick={handleSaveItem}
							loadingSave={loadingSave}
							title={type === "project" ? "Remove" : isSaved ? "Saved" : "Save"}
							customClass={css.buttonWidth}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}
