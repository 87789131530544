// @TODO: Need to refactoring for change type to real types
/* eslint-disable @typescript-eslint/no-explicit-any */

import config from "config";
import axios, {AxiosInstance, AxiosResponse, CancelToken} from "axios";

import {Mixpanel} from "helpers/mixpanel";
import {STConfigParam, STError} from "api/types";

let refreshTokenPromise: any;
let accessToken: CancelToken | null | string = null;

class HttpClient {
	private client: AxiosInstance;
	private alreadyRefreshOnProcess: boolean;

	constructor() {
		this.alreadyRefreshOnProcess = false;
		this.client = axios.create({
			baseURL: config.apiUrl,
			// withCredentials: true,
			headers: {
				"Access-Control-Allow-Origin": config.apiUrl as string,
				Accept: "application/json",
				"Content-Type": "application/json",
			},
		});

		this.client.interceptors.request.use((configParam: STConfigParam) => {
			if (accessToken && configParam.headers) {
				configParam.headers.common["Authorization"] = accessToken;
			}

			return configParam;
		});

		const savedToken = window.localStorage.getItem("token");

		this.client.interceptors.request.use((configParam?: STConfigParam) => {
			if (configParam && configParam?.headers && configParam?.headers.common) {
				configParam.headers.common["Authorization"] = savedToken;
			}

			return configParam;
		});

		this.client.interceptors.response.use(
			(response: AxiosResponse) => response,
			async (error: STError) => {
				if (error.config && error.response) {
					switch (error.response.status) {
						case 401: {
							const expiredTime = localStorage.getItem("expires_in")
								? parseInt(String(localStorage.getItem("expires_in")), 10)
								: null;

							if (expiredTime && expiredTime <= new Date().getTime()) {
								if (!refreshTokenPromise) {
									refreshTokenPromise = this.refreshToken().then(() => {
										refreshTokenPromise = null;

										return accessToken;
									});
								}

								return refreshTokenPromise.then((token: string) => {
									error.config.headers["Authorization"] = token;

									return this.client.request(error.config);
								});
							}

							break;
						}
						case 503:
							if (window.location.pathname !== "/503") {
								window.location.replace("/503");
							}
							break;
						case 500:
							if (window.location.pathname !== "/500") {
								window.location.replace("/500");
							}
							break;
						default:
					}
				}

				return Promise.reject(error);
			},
		);
	}

	static resetConstructor() {
		accessToken = window.localStorage.getItem("token");

		return new HttpClient();
	}

	redirectToLogin(redirectToPrev = false) {
		let redirect = "/login";

		if (redirectToPrev) {
			const history = window.location.pathname;
			const redirectTo = `?redirectTo=${history}`;

			redirect += `${redirectTo || ""}`;
		}

		window.localStorage.clear();
		window.location.href = redirect;
	}

	async refreshToken() {
		if (this.alreadyRefreshOnProcess) return;

		if (window.localStorage.getItem("token")) {
			const expiredTime = localStorage.getItem("expires_in")
				? parseInt(String(localStorage.getItem("expires_in")), 10)
				: null;

			if (expiredTime && expiredTime <= new Date().getTime()) {
				this.alreadyRefreshOnProcess = true;

				const response = await this.doPost("/auth/refresh", {
					refresh_token: window.localStorage.getItem("refresh_token"),
				});

				const savedUser = window.localStorage.getItem("user");
				const user = savedUser ? JSON.parse(savedUser) : null;

				if (response.status === 200) {
					window.localStorage.setItem("token", "Bearer " + response.data.access_token);
					window.localStorage.setItem("refresh_token", response.data.refresh_token);
					window.localStorage.setItem(
						"expires_in",
						String(new Date().getTime() + 1000 * response.data.expires_in),
					);

					this.alreadyRefreshOnProcess = false;
					await HttpClient.resetConstructor();

					// SET MIXPANEL NEW LOGIN
					if (user && user?.email) {
						Mixpanel.identify(user.email);
						Mixpanel.track("logged in", {distinct_id: user.email});
						Mixpanel.people.set({
							$first_name: user?.firstName,
							$last_name: user?.lastName,
							$email: user.email,
							organisation: user?.organisation?.name,
							distinct_id: user.email,
						});
					}
				} else {
					// SET MIXPANEL LOGOUT EVENT
					if (user && user?.email) {
						Mixpanel.track("logged out", {distinct_id: user.email});
						Mixpanel.reset();
					}

					this.redirectToLogin(true);
				}
			}
		} else {
			this.alreadyRefreshOnProcess = false;
		}
	}

	async doGet(url: string, config?: any) {
		return await this.client
			.get(url, config)
			.then((res: any) => res)
			.catch((err: any) => err.response);
	}

	async doPost(url: string, body?: any, config?: any, hasMultimedia = false) {
		let formData;

		if (hasMultimedia) {
			config = {
				...config,
				headers: {
					"Content-Type": "multipart/form-data",
				},
			};
			formData = new FormData();

			for (const [key, value] of Object.entries(body)) {
				formData.append(key, value as any);
			}
		} else {
			formData = body;
		}

		return await this.client
			.post(url, formData, config)
			.then((res: any) => res)
			.catch((err: any) => err.response);
	}

	async doPatch(url: string, body?: any, config?: any) {
		return await this.client
			.patch(url, body, config)
			.then((res: any) => res)
			.catch((err: any) => err.response);
	}

	async doPut(url: string, body?: any, config?: any) {
		return await this.client
			.put(url, body, config)
			.then((res: any) => res)
			.catch((err: any) => err.response);
	}

	async doDelete(url: string, body = {}) {
		return await this.client
			.delete(url, {data: body})
			.then((res: any) => res)
			.catch((err: any) => err.response);
	}
}

export default HttpClient;
