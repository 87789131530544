import type {FC} from "react";

import type {IconProps} from "./types";

const MapAnimationIcon: FC<IconProps> = ({className = ""}) => (
	<svg
		width="184"
		fill="none"
		height="101"
		className={className}
		viewBox="0 0 184 101"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M15.5 10.9958C15.5 10.4399 15.8727 10.0657 16.4264 10.0657H24.6996C25.5408 10.0657 25.9987 10.3437 26.2862 10.9958L43.6846 50.1269H44.0572L61.1681 10.9958C61.445 10.3437 61.9135 10.0657 62.744 10.0657H70.9321C71.4858 10.0657 71.8585 10.4399 71.8585 10.9958V72.6327C71.8585 73.1886 71.4858 73.5628 70.9321 73.5628H62.8398C62.2862 73.5628 61.9135 73.1886 61.9135 72.6327V33.7902H61.5408L48.146 63.6731C47.7733 64.5178 47.2196 64.892 46.3784 64.892H40.98C40.1389 64.892 39.5852 64.5178 39.2125 63.6731L25.8283 33.7902H25.4556V72.6434C25.4556 73.1993 25.083 73.5735 24.5293 73.5735H16.4264C15.8727 73.5735 15.5 73.1993 15.5 72.6434V10.9958Z"
			fill="black"
		/>
		<path
			d="M109.692 73.5736C109.139 73.5736 108.766 73.1994 108.766 72.6435V69.3719H108.67C106.53 72.451 102.345 74.6 96.0207 74.6C87.8325 74.6 80.9541 70.302 80.9541 60.8721C80.9541 51.4421 87.8325 46.5775 98.9062 46.5775H108.021C108.393 46.5775 108.574 46.3957 108.574 46.0215V43.8725C108.574 38.7405 106.157 36.3991 98.6187 36.3991C93.593 36.3991 90.5264 37.7997 88.3862 39.4783C87.9177 39.8525 87.364 39.7562 87.0872 39.2965L83.648 33.1275C83.3711 32.657 83.467 32.1973 83.829 31.9086C87.4599 29.2892 92.3898 27.5144 99.7367 27.5144C113.408 27.5144 118.434 32.1866 118.434 43.2096V72.6328C118.434 73.1887 118.061 73.5629 117.508 73.5629H109.692V73.5736ZM108.574 58.4451V54.9917C108.574 54.6175 108.393 54.4358 108.021 54.4358H100.578C93.8805 54.4358 90.9097 56.3068 90.9097 60.5086C90.9097 64.2399 93.6036 66.1109 98.6293 66.1109C105.05 66.1109 108.585 63.5877 108.585 58.4558L108.574 58.4451Z"
			fill="black"
		/>
		<path
			d="M130.187 90.3915C129.633 90.3915 129.26 90.0173 129.26 89.4613V29.4924C129.26 28.9364 129.633 28.5622 130.187 28.5622H138.748C139.301 28.5622 139.674 28.9364 139.674 29.4924V32.8602H139.77C141.91 29.7811 145.626 27.5358 151.674 27.5358C159.298 27.5358 164.877 31.1816 167.209 38.3664C168.327 41.8197 168.796 44.9951 168.796 51.0679C168.796 57.1407 168.327 60.3161 167.209 63.7695C164.888 70.9649 159.308 74.6001 151.674 74.6001C145.626 74.6001 141.91 72.3548 139.77 69.2757H139.674V89.4506C139.674 90.0066 139.301 90.3808 138.748 90.3808H130.187V90.3915ZM157.253 59.4715C157.903 57.4187 158.18 55.1735 158.18 51.0679C158.18 46.9624 157.903 44.7172 157.253 42.6644C156.04 38.933 153.165 36.8696 148.98 36.8696C144.796 36.8696 141.814 38.9223 140.611 42.6644C139.961 44.7172 139.685 46.9624 139.685 51.0679C139.685 55.1735 139.961 57.4187 140.611 59.4715C141.825 63.2029 144.7 65.2663 148.98 65.2663C153.261 65.2663 156.05 63.2136 157.253 59.4715Z"
			fill="black"
		/>
	</svg>
);

export default MapAnimationIcon;
