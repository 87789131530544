import type {FC} from "react";

import type {IconProps} from "./types";

const CloseOnboardingTooltip: FC<IconProps> = ({className = ""}) => (
	<svg
		width="20"
		fill="none"
		height="20"
		viewBox="0 0 20 20"
		className={className}
		xmlns="http://www.w3.org/2000/svg"
	>
		<mask
			x="0"
			y="0"
			width="20"
			height="20"
			id="mask0_236_3669"
			maskUnits="userSpaceOnUse"
			style={{maskType: "alpha"}}
		>
			<rect width="20" height="20" fill="#D9D9D9" />
		</mask>
		<g mask="url(#mask0_236_3669)">
			<path
				fill="#EEEDEC"
				d="M6.0625 15L5 13.9375L8.9375 10L5 6.0625L6.0625 5L10 8.9375L13.9375 5L15 6.0625L11.0625 10L15 13.9375L13.9375 15L10 11.0625L6.0625 15Z"
			/>
		</g>
	</svg>
);

export default CloseOnboardingTooltip;
