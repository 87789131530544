import React from "react";
import type {FC} from "react";

import ChartImage from "assets/images/chart-image.png";
import {Rule1, Rule2, Rule3, AuthCoverBottomSVG} from "assets/icons";

import LoginCard from "./LoginCard";
import type {TAuthCoverProps} from "./types";
import {emptyData, users1, users2, users3} from "./utils";

import styles from "./AuthView.module.scss";

const LoginCover: FC<TAuthCoverProps> = ({coverDescription = false}) => (
	<div className={styles.ui}>
		<div className={styles.ui_f_container}>
			<Rule1 className={styles.ui_f_container_rule1} />
			<Rule2 className={styles.ui_f_container_rule2} />

			<LoginCard
				chart
				emptyData={emptyData}
				title="Industry Background"
				cardClassNames={styles.ui_f_container_first_card}
			>
				<img className={styles.ui_f_container_first_card_chart} src={ChartImage} />
			</LoginCard>

			<div className={styles.ui__right_container}>
				<LoginCard
					users={users1}
					badge="Matched Candidate"
					cardClassNames={styles.ui__right_container_second}
				/>
				<LoginCard
					users={users2}
					badge="Matched Candidate"
					cardClassNames={styles.ui__right_container_third}
				/>
			</div>
		</div>

		<div className={styles.ui__descriptions}>
			<h4 className={styles.ui__descriptions_subtitle}>Next-Generation</h4>

			<h2 className={styles.ui__descriptions_title}>AI Executive Talent Mapping</h2>

			{coverDescription && (
				<h6 className={styles.ui__descriptions__description}>{coverDescription}</h6>
			)}
		</div>

		<div className={styles.ui__bottom_block}>
			<Rule3 className={styles.ui__bottom_block_rule3} />

			<LoginCard
				users={users3}
				badge="Matched Candidate"
				cardClassNames={styles.ui__bottom_block_first}
			/>
			<LoginCard
				chartPerson
				title="Organisational Chart"
				cardClassNames={styles.ui__bottom_block_second}
			/>
		</div>

		<AuthCoverBottomSVG className={styles.ui__bottom_icon} />
	</div>
);

export default LoginCover;
