import type {FC} from "react";

import type {IconProps} from "./types";

const Rule1: FC<IconProps> = ({fill = "url(#paint0_linear_1686_29960)", className = ""}) => (
	<svg
		width="131"
		height="123"
		fill="none"
		viewBox="0 0 131 123"
		className={className}
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fill={fill}
			d="M130.5 61.5C130.5 95.4655 95.4655 123 61.5 123C27.5345 123 0 95.4655 0 61.5C0 27.5345 27.5345 0 61.5 0C95.4655 0 130.5 27.5345 130.5 61.5Z"
		/>
		<defs>
			<linearGradient
				y2="123"
				x1="61.5017"
				x2="61.5017"
				y1="-4.06057e-07"
				id="paint0_linear_1686_29960"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#DEDABA" />
				<stop offset="1" stopColor="#236978" />
			</linearGradient>
		</defs>
	</svg>
);

export default Rule1;
