import {useAppDispatch, useAppSelector} from "hooks";
import {Accordion} from "mapx-components";
import {useCallback, useMemo} from "react";
import {setMultipleFilterForCandidates} from "store/mapx/filter/filterActions";
import DealExperienceSearchFilter from "mapx-components/Filters/DealExperienceSearchFilter";
import {getCandidateCompanyEventsList} from "store/mapx/search/searchAsyncActions";
import {setCandidateCompanyEventsSearchQuery} from "store/mapx/search/searchActions";
import {
	CandidateCompanyDateRangeOptionsSelector,
	candidateCompanyEventPaginationInfoSelector,
	candidateCompanyEventSizeSelector,
	candidateCompanyEventsSearchQuerySelector,
	CandidateCompanyEventsSelector,
	candidateCompanyEventsSelector,
	candidateCompanyEventTypesSelector,
} from "store/mapx/filter/DealExperienceFilterSelectors";
import {
	setCompanyEventSizeFilterForCandidate,
	setCompanyEventTypesFilterForCandidate,
} from "store/mapx/filter/DealExperienceFilterAsyncActions";
import {
	TCandidateCompanyDateRangeItem,
	TCandidateCompanyEventSizeItem,
	TCandidateCompanyEventTypeItem,
	TDEDateRange,
} from "mapx-components/Filters/DealExperienceSearchFilter/types";
import {format} from "date-fns";

function DealExperienceFilter() {
	const dispatch = useAppDispatch();

	const candidateCompanyEventPaginationInfo = useAppSelector(
		candidateCompanyEventPaginationInfoSelector,
	);

	const candidateCompanyEventsSearchQuery = useAppSelector(
		candidateCompanyEventsSearchQuerySelector,
	);

	const candidateCompanyEventsFilter = useAppSelector(candidateCompanyEventsSelector);

	const selectedCandidateCompanyEvents = useAppSelector(candidateCompanyEventTypesSelector);

	const selectedCandidateCompanyEventSize = useAppSelector(candidateCompanyEventSizeSelector);

	const handleResetClick = useCallback(
		(type: string) => {
			let resetOption;

			if (type === "size") {
				resetOption = {
					...candidateCompanyEventsFilter,
					size: [],
				};
			} else if (type === "types") {
				resetOption = {
					...candidateCompanyEventsFilter,
					types: [],
				};
			} else if (type === "dates") {
				resetOption = {
					...candidateCompanyEventsFilter,
					dates: [],
				};
			} else {
				resetOption = {
					types: [],
					size: [],
					dates: [],
				};
			}

			dispatch(
				setMultipleFilterForCandidates({
					company_events: resetOption,
				}),
			);
		},
		[dispatch, candidateCompanyEventsFilter],
	);

	const handleEventTypeChange = useCallback(
		(eventItem: TCandidateCompanyEventTypeItem) => {
			dispatch(setCompanyEventTypesFilterForCandidate({eventItem}));
		},
		[dispatch],
	);

	const handleOnEventSizeChange = useCallback(
		(eventSizeItem: TCandidateCompanyEventSizeItem) => {
			dispatch(setCompanyEventSizeFilterForCandidate({eventSizeItem}));
		},
		[dispatch],
	);

	const CandidateCompanyDateRangeOptions = useAppSelector(
		CandidateCompanyDateRangeOptionsSelector,
	);

	const CandidateCompanyEventOptions = useAppSelector(CandidateCompanyEventsSelector);

	const filterDateRanges = (
		existingDates: TDEDateRange[],
		newDateRanges: {from_date: string; to_date: string}[],
	) => {
		let updatedDates = [...existingDates];

		newDateRanges.forEach(({from_date, to_date}) => {
			const dateExists = updatedDates.some(
				(date) => date.from_date === from_date && date.to_date === to_date,
			);

			if (dateExists) {
				updatedDates = updatedDates.filter(
					(date) => date.from_date !== from_date || date.to_date !== to_date,
				);
			} else {
				updatedDates.push({from_date, to_date});
			}
		});

		return updatedDates;
	};

	const handleOnDateChange = useCallback(
		(date: TCandidateCompanyDateRangeItem) => {
			if (date) {
				const min_value = date.min_value
					? format(new Date(date.min_value), "yyyy-MM-dd")
					: "";
				const max_value = date.max_value
					? format(new Date(date.max_value), "yyyy-MM-dd")
					: "";

				const updatedDates = filterDateRanges(candidateCompanyEventsFilter.dates || [], [
					{
						from_date: min_value,
						to_date: max_value,
					},
				]);

				dispatch(
					setMultipleFilterForCandidates({
						company_events: {
							...candidateCompanyEventsFilter,
							dates: updatedDates,
						},
					}),
				);
			}
		},
		[dispatch, candidateCompanyEventsFilter],
	);

	function dispatchAllDateFilter() {
		const dates = CandidateCompanyDateRangeOptions;

		let data: TDEDateRange[] = [];

		if (Array.isArray(dates) && dates.length > 0) {
			const formattedDates = dates.map((date) => {
				const min_value = date.min_value
					? format(new Date(date.min_value), "yyyy-MM-dd")
					: "";
				const max_value = date.max_value
					? format(new Date(date.max_value), "yyyy-MM-dd")
					: "";

				return {
					from_date: min_value,
					to_date: max_value,
				};
			});

			data = filterDateRanges([], formattedDates);
		}

		dispatch(
			setMultipleFilterForCandidates({
				company_events: {
					...candidateCompanyEventsFilter,
					dates: data,
				},
			}),
		);
	}

	function dispatchAllEventTypesFilter() {
		const types = CandidateCompanyEventOptions;

		let formattedEvents: string[] = [];
		if (Array.isArray(types) && types.length > 0) {
			formattedEvents = types.map((type) => {
				return type.name;
			});
		}

		dispatch(
			setMultipleFilterForCandidates({
				company_events: {
					...candidateCompanyEventsFilter,
					types: formattedEvents,
				},
			}),
		);
	}

	const subtitle = useMemo(() => {
		return (
			selectedCandidateCompanyEventSize.length +
			selectedCandidateCompanyEvents.length +
			candidateCompanyEventsFilter.dates.length
		);
	}, [
		selectedCandidateCompanyEventSize.length,
		selectedCandidateCompanyEvents.length,
		candidateCompanyEventsFilter.dates,
	]);

	return (
		<Accordion title="Deal Experience" subtitle={subtitle}>
			<DealExperienceSearchFilter
				handleResetClick={handleResetClick}
				handleChange={{
					typeChange: handleEventTypeChange,
					sizeChange: handleOnEventSizeChange,
					dateChange: handleOnDateChange,
				}}
				handleSelectAll={{
					selectTypes: dispatchAllEventTypesFilter,
					selectDates: dispatchAllDateFilter,
				}}
				displayResetFilterOption={
					selectedCandidateCompanyEvents.length > 0 ||
					selectedCandidateCompanyEventSize.length > 0 ||
					candidateCompanyEventsFilter.dates.length > 0
				}
				CandidateCompanyEventPaginationInfo={candidateCompanyEventPaginationInfo}
				setCandidateCompanyTypesQueryOnState={setCandidateCompanyEventsSearchQuery}
				candidateCompanyTypesSearchQuery={candidateCompanyEventsSearchQuery}
				apiCall={getCandidateCompanyEventsList}
				CandidateCompanyEventsFilter={candidateCompanyEventsFilter}
			/>
		</Accordion>
	);
}

export default DealExperienceFilter;
