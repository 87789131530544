import {useEffect, useMemo, useState} from "react";
import {useAppDispatch, useAppSelector} from "hooks";
import {flattenCountriesSelector} from "store/mapx/filter/filterSelectors";
import {getCountries} from "store/mapx/search/searchAsyncActions";

export type countryOption = {
	id: number | string;
	name: string;
};

const useLocationSearchService = () => {
	const dispatch = useAppDispatch();
	const [loadingLocations, setLoadingLocations] = useState<boolean>(false);
	const flattenLocations = useAppSelector(flattenCountriesSelector);

	const locations = useMemo(() => {
		let locationData: countryOption[] = [];

		flattenLocations.forEach((location) => {
			if (location?.regions?.length > 0) {
				location.regions.forEach((region: countryOption) => {
					locationData = [
						...locationData,
						{
							id: `${region.name}, ${location.name}`,
							name: `${region.name}, ${location.name}`,
						},
					];
				});
			} else {
				locationData = [...locationData, {id: location.name, name: location.name}];
			}
		});

		return locationData;
	}, [flattenLocations]);

	useEffect(() => {
		async function get() {
			setLoadingLocations(true);
			await dispatch(getCountries());
		}

		if (locations?.length === 0) {
			get().finally(() => setLoadingLocations(false));
		}
	}, [dispatch, locations?.length]);

	return {
		loadingLocations,
		locations,
		flattenLocations,
	};
};

export default useLocationSearchService;
