import {useCallback, useMemo, useState} from "react";
import axios from "axios";
import {filter, isEqual} from "lodash";
import useDebouncedEffect from "use-debounced-effect";

import type {TValue} from "components/MultiSelect/types";
import {getCompanyOrgChartFiltering} from "store/mapx/company/companyActions";
import {getProjectFrequentlyHiredFromData} from "store/mapx/project/projectAsyncActions";

import {useAppDispatch, useCompanyIdParam, usePrevious} from "../index";

import type {TProjectInsightsFiltersReturn} from "./types";
import {TReportFilterParams, TReportParams} from "api/companyApi/types";

const initialSelectedFilters = {
	current_specialisms: [],
	current_job_functions: [],
};

const useProjectInsightsFilters = (): TProjectInsightsFiltersReturn => {
	const dispatch = useAppDispatch();
	const paramId = useCompanyIdParam();

	const [cancelTokenSources, setCancelTokenSources] = useState({
		frequentlyHiredFrom: axios.CancelToken.source(),
	});
	const [selectedFilters, setSelectedFilters] = useState(initialSelectedFilters);
	const [resettingInProgress, setResettingInProgress] = useState<boolean>(false);
	const prevSelectedFilters = usePrevious(selectedFilters);

	const paginationParams = {
		per_page: 9,
		page: 1,
	};

	const modifiedSelectedFilters = useMemo(
		() =>
			Object.entries(selectedFilters).reduce(
				// @TODO: Refactor to real type
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				(acc, currentValue: any) => {
					acc[currentValue[0] as keyof TReportParams] =
						currentValue[1].join(",") || undefined;

					return acc;
				},
				{} as TReportParams,
			) as TReportFilterParams,
		[selectedFilters],
	);

	const applyFilteringInProgress = (inProgress: boolean) =>
		dispatch(getCompanyOrgChartFiltering(inProgress));

	useDebouncedEffect(
		() => {
			const prevFilterApplied =
				prevSelectedFilters &&
				Object.values(prevSelectedFilters).some((filters) => filters.length);
			const filterApplied = Object.values(selectedFilters).some(
				(filters) => !!filters.length,
			);

			if (
				paramId &&
				!isEqual(selectedFilters, prevSelectedFilters) &&
				(filterApplied || prevFilterApplied)
			) {
				const applyOrgChartFilters = async () => {
					Object.values(cancelTokenSources).forEach((source) => source.cancel());

					const newCancelTokenSources = {
						frequentlyHiredFrom: axios.CancelToken.source(),
					};
					setCancelTokenSources(newCancelTokenSources);

					applyFilteringInProgress(true);

					await dispatch(
						getProjectFrequentlyHiredFromData(
							paramId,
							{
								...modifiedSelectedFilters,
								...paginationParams,
							},
							{
								cancelToken: newCancelTokenSources.frequentlyHiredFrom.token,
							},
						),
					);

					applyFilteringInProgress(false);
				};

				if (!resettingInProgress) {
					applyOrgChartFilters();
				}
			}
		},
		10,
		[paramId, prevSelectedFilters, selectedFilters, resettingInProgress],
	);

	const handleSelectChange: TProjectInsightsFiltersReturn["onSelectChange"] = useCallback(
		(name, id, checked, bulkChangeIds) => {
			setSelectedFilters((prevSelectedFilters) => {
				const filterName = name as keyof TReportFilterParams;
				const currentFilterState = prevSelectedFilters[
					filterName as keyof typeof prevSelectedFilters
				] as TValue[];
				const updatedFilterValues = checked
					? bulkChangeIds
						? [...currentFilterState, ...bulkChangeIds]
						: [...currentFilterState, id]
					: filter(currentFilterState, (filterValue) =>
							bulkChangeIds
								? !bulkChangeIds.includes(filterValue as number)
								: filterValue !== id,
					  );

				return {
					...prevSelectedFilters,
					[filterName]: updatedFilterValues,
				};
			});
		},
		[],
	);

	const handleResetFilters = async () => {
		setResettingInProgress(true);
		applyFilteringInProgress(true);
		setSelectedFilters(initialSelectedFilters);

		await dispatch(getProjectFrequentlyHiredFromData(paramId, paginationParams));

		applyFilteringInProgress(false);
		setResettingInProgress(false);
	};

	return {
		selectedFilters,
		modifiedSelectedFilters,
		resetFilters: handleResetFilters,
		onSelectChange: handleSelectChange,
	};
};

export default useProjectInsightsFilters;
