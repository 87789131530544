/**
 * HANDLING PR FILTERS
 */
import {
	prCandidateSelector,
	prCompaniesSelector,
	prSelectedJobTitlesSelector,
} from "store/mapx/additional-profiles/additionalProfilesSelectors";
import {setPRFilter} from "store/mapx/additional-profiles/additionalProfilesActions";
import {Dispatch} from "react";
import {TAppDispatch, TRootState} from "types";

export const setCompanyForPRFilters =
	(id: number) => async (dispatch: Dispatch<TAppDispatch>, getState: TRootState) => {
		const state = getState();

		const ids = prCompaniesSelector(state);

		const index = ids.indexOf(id);

		const alreadySelected = index !== -1;

		const nextIds = alreadySelected
			? [...ids.slice(0, index), ...ids.slice(index + 1)]
			: [...ids, id];

		dispatch(setPRFilter({type: "company_ids", values: nextIds}));
	};
export const setCandidateForPRFilters =
	(id: number) => (dispatch: Dispatch<TAppDispatch>, getState: TRootState) => {
		const state = getState();

		const prevId = prCandidateSelector(state);

		const alreadySelected = prevId === id;

		dispatch(setPRFilter({type: "candidate_id", values: alreadySelected ? null : id}));
	};
export const setLocationForPRFilters = (ids: number[]) => (dispatch: Dispatch<TAppDispatch>) => {
	dispatch(setPRFilter({type: "country_ids", values: ids}));
};
export const setSelectedJobTitlesForPRFilters =
	(title: string) => async (dispatch: Dispatch<TAppDispatch>, getState: TRootState) => {
		const state = getState();

		const titles = prSelectedJobTitlesSelector(state);

		const index = titles.indexOf(title);

		const alreadySelected = index !== -1;

		const nextIds = alreadySelected
			? [...titles.slice(0, index), ...titles.slice(index + 1)]
			: [...titles, title];

		dispatch(setPRFilter({type: "selected_job_titles", values: nextIds}));
	};
export const setSearchWidthForPRFilters = (width: string) => (dispatch: Dispatch<TAppDispatch>) => {
	dispatch(setPRFilter({type: "search_width", values: width}));
};
