import {useEffect, useMemo, useState} from "react";
import css from "./buttonsRow.module.scss";

import {InfoButton, LessButton, MoreButton} from "mapx-components";
import useWindowSize from "hooks/useWindowSize";
import classNames from "classnames";
import {TButtonCommonProperties, TButtonsRowProps} from "mapx-components/Buttons/ButtonsRow/type";

export const ButtonsRow = <T extends TButtonCommonProperties>({
	className = "",
	disabled = false,
	handleSelectedItem,
	items = [],
	label = "Specialities",
	selectedItems,
	titleClass = "",
	itemClassContainer = "",
	trackEventReport = null,
	printRequested = false,
}: TButtonsRowProps<T>) => {
	const [showMore, setShowMore] = useState(true);
	const {width} = useWindowSize();

	const showAll = () => {
		setShowMore((prev) => !prev);

		if (trackEventReport) {
			trackEventReport("expanded");
		}
	};

	const showLess = () => {
		setShowMore((prev) => !prev);
	};

	const getLimitByScreenSize = useMemo(() => {
		const screenSizes = [768, 992, 1200, 1980];

		if (width < screenSizes[0]) {
			return 2;
		} else if (width <= screenSizes[1]) {
			return 3;
		} else if (width <= screenSizes[2]) {
			return 4;
		} else if (width < screenSizes[3]) {
			return 5;
		} else if (width >= screenSizes[3]) {
			return 6;
		}

		return 3;
	}, [width]);

	const limitCount = useMemo(() => {
		let limit;

		if (!showMore || printRequested) {
			limit = items.length;
		} else {
			limit = getLimitByScreenSize;
		}

		return limit;
	}, [showMore, printRequested, items.length, getLimitByScreenSize]);

	const moreCount = useMemo(() => {
		let more = 0;

		if (items && items.length > limitCount) {
			more = items.length - limitCount;
		}

		return more;
	}, [items, limitCount]);

	const itemName = (item: T) => {
		return item.name;
	};

	useEffect(() => {
		if (printRequested) {
			setShowMore(true);
		}
	}, [printRequested]);

	return (
		<div className={classNames(css.specialities, className)}>
			{label && <span className={classNames(css.title, titleClass)}>{label}</span>}

			<span
				className={classNames(css.specList, {
					[itemClassContainer]: itemClassContainer,
				})}
			>
				{items.slice(0, limitCount).map((item: T, index: number) => {
					return (
						<InfoButton
							key={index}
							name={itemName(item)}
							handleClick={() => handleSelectedItem && handleSelectedItem(item)}
							active={selectedItems && !disabled && selectedItems.includes(item.id)}
							secondText={item.secondText}
							disabled={disabled}
						/>
					);
				})}
				{moreCount > 0 && showMore && (
					<MoreButton count={moreCount} handleClick={showAll} />
				)}
				{moreCount > 0 && !showMore && <LessButton handleClick={showLess} />}
			</span>
		</div>
	);
};

export default ButtonsRow;
