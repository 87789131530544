import {Tag} from "components";
import FilterTags from "mapx-components/FilterTags";
import React, {DragEvent, useCallback} from "react";
import {TKeywordTagProps} from "mapx-components/Filters/KeywordSearchFilter/Tags/types";
import {labelCapitalized} from "helpers/string";
import {TJobFunctionPositionConnectivityLogic} from "api/filterOptionsApi/JobFunctionApi/types";
import {useSelector} from "react-redux";
import {filterConnectivityLogicOptionsSelector} from "store/mapx/filter/filterSelectors";
import {TKeywordPosition} from "api/filterOptionsApi/KeywordApi/types";

function Tags({
	tagLabel,
	tagValue,
	tags,
	handleTagClick,
	handleConnectivityOptionChange,
	selectedLogic,
	moveKeywordTagPosition,
	clearTagsByPosition,
}: TKeywordTagProps) {
	const filterConnectivityLogicOptions = useSelector(filterConnectivityLogicOptionsSelector);

	const handleTagDragStart = useCallback(
		(e: React.DragEvent, label: string) => {
			if (tagValue) {
				e.dataTransfer.setData("id", label);
				e.dataTransfer.setData("from", tagValue);
			}
		},
		[tagValue],
	);

	const handleDrop = useCallback(
		(e: DragEvent<HTMLDivElement>) => {
			const value = e.dataTransfer.getData("id");
			const from = e.dataTransfer.getData("from") as TKeywordPosition;

			moveKeywordTagPosition(value, from, tagValue);
		},
		[moveKeywordTagPosition, tagValue],
	);

	return (
		<FilterTags
			onDrop={handleDrop}
			label={tagLabel || ""}
			count={tags?.length}
			connectivityOptions={filterConnectivityLogicOptions}
			handleConnectivityOptionChange={(logic: string) =>
				handleConnectivityOptionChange(
					tagValue,
					logic as TJobFunctionPositionConnectivityLogic,
				)
			}
			selectedConnectivityLogic={selectedLogic}
			moveDisabled={true}
			tags={tags?.map((label: string) => (
				<Tag
					key={label}
					onClick={() => handleTagClick(label, tagValue, selectedLogic)}
					draggable
					onDragStart={(e) => handleTagDragStart(e, label)}
					onDragOver={(e) => e.preventDefault()}
				>
					{`${labelCapitalized(label)}` || ""}
				</Tag>
			))}
			onClear={() => clearTagsByPosition(tagValue)}
		/>
	);
}

export default React.memo(Tags);
