import type {FC} from "react";
import classNames from "classnames";
import {useHistory, useLocation} from "react-router-dom";

import {Button} from "components";
import {useAppSelector} from "hooks";
import {GeneralButton} from "mapx-components";
import {CreatePlusIcon, EnhanceIcon, ProjectIcon} from "assets/icons";
import {
	hasAccessToExtendedPagesSelector,
	hasAccessToManualSearchSelector,
} from "store/mapx/user/userSelectors";

import type {TProjectsEmptyProps} from "./types";
import styles from "../Home/HomeStyles.module.scss";
import {createProjectModal} from "store/mapx/project-list/projectListActions";
import {useDispatch} from "react-redux";

const ProjectsEmpty: FC<TProjectsEmptyProps> = ({className = ""}) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const {pathname} = useLocation();

	const hasAccessToExtendedPages = useAppSelector(hasAccessToExtendedPagesSelector);
	const hasAccessToManualSearch = useAppSelector(hasAccessToManualSearchSelector);

	const handleCreateProjectClick = () => {
		dispatch(createProjectModal(true));
	};

	return (
		<div
			className={classNames(styles.empty, {
				[className]: className,
			})}
		>
			<div className={styles.content__bottom__empty__icon_bg}>
				<ProjectIcon />
			</div>
			<p className={styles.content__bottom__empty__title}>No projects created yet</p>
			<p className={styles.content__bottom__empty__description}>
				{!hasAccessToExtendedPages
					? "Start a search to get started."
					: "You can start your first project."}
			</p>

			{!hasAccessToExtendedPages ? (
				<div className={styles.content__bottom__empty__skeleton} />
			) : (
				<Button
					LeftIcon={CreatePlusIcon}
					onClick={handleCreateProjectClick}
					className={classNames(
						styles.content__bottom__info__buttons_create,
						styles.content__bottom__empty__btn,
					)}
					leftIconClasses={styles.content__bottom__info__buttons_create_icon}
				>
					Create a New Project
				</Button>
			)}

			{!hasAccessToManualSearch && pathname.includes("project") && (
				<GeneralButton
					title="Start New Search"
					icon={<EnhanceIcon color="white" />}
					onClick={() => history.push("/home")}
					customClass={styles.quickSearchButton}
				/>
			)}
		</div>
	);
};

export default ProjectsEmpty;
