import type {FC} from "react";
import {useCallback, useState} from "react";
import classNames from "classnames";

import Button from "../shared/Button";

import type {TContentTabsData, TContentTabsProps} from "./types";
import styles from "./ContentTabs.module.scss";

const ContentTabs: FC<TContentTabsProps> = ({content}) => {
	const [currentOption, setCurrentOption] = useState<number>(0);

	const clickHandler = useCallback((id: number) => {
		setCurrentOption(id);
	}, []);

	return (
		<div className={styles.container}>
			<div className={styles.container__wrapper}>
				{content?.map((item: TContentTabsData, idx: number) => (
					<Button
						key={item.id}
						defaultBtnStyle
						onClick={() => clickHandler(item.id)}
						className={classNames(styles.container__wrapper__items, {
							[styles.container__wrapper__items__first]: idx === 0,
							[styles.container__wrapper__items__active]: item.id === currentOption,
						})}
					>
						{item.name}
					</Button>
				))}
			</div>

			<div className={styles.container__border} />

			<div className={styles.container__content}>
				{content?.map(({id, ContentComponent}: TContentTabsData) => (
					<div key={id}>
						{<ContentComponent isCurrentTabSelected={id === currentOption} />}
					</div>
				))}
			</div>
		</div>
	);
};

export default ContentTabs;
