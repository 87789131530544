import React, {useState} from "react";
import styles from "./styles.module.scss";
import {TAPCandidatesFilterProps, TFilterOptions} from "./types";
import classNames from "classnames";
import InputProfiles from "./InputProfiles";
import JobTitles from "./JobTitles";
import {useSelector} from "react-redux";
import {
	prCandidateSelector,
	prSelectedJobTitlesSelector,
} from "store/mapx/additional-profiles/additionalProfilesSelectors";

const APCandidatesFilter = ({options}: TAPCandidatesFilterProps) => {
	const [activeFilter, setActiveFilter] = useState<TFilterOptions>("input_profiles");

	const selectedJobTitles = useSelector(prSelectedJobTitlesSelector);

	const selectedCandidate = useSelector(prCandidateSelector);

	return (
		<div>
			<div className={styles.filterBy}>
				<div className={styles.filters}>
					<div
						className={classNames(styles.filterType, {
							[styles.active]: activeFilter === "input_profiles",
						})}
						onClick={() => setActiveFilter("input_profiles")}
					>
						<span className={styles.filterLabel}>
							Input Profiles {selectedCandidate !== null && <span>1/1</span>}
						</span>
					</div>
					<div
						className={classNames(styles.filterType, {
							[styles.active]: activeFilter === "job_titles",
						})}
						onClick={() => setActiveFilter("job_titles")}
					>
						<span className={styles.filterLabel}>
							Job Titles{" "}
							{selectedJobTitles?.length > 0 && (
								<span>{selectedJobTitles?.length}/10</span>
							)}
						</span>
					</div>
				</div>
			</div>

			<div className={styles.filtersContent}>
				{activeFilter === "input_profiles" && <InputProfiles options={options} />}

				{activeFilter === "job_titles" && <JobTitles />}
			</div>
		</div>
	);
};

export default APCandidatesFilter;
