import type {FC} from "react";
import ContentLoader from "react-content-loader";

const PricingSkeletonCard: FC = (props) => (
	<div style={{borderRadius: 4, border: "1px solid #DCE7E7"}}>
		<ContentLoader
			speed={2}
			width="100%"
			height={526}
			viewBox="0 0 262 526"
			backgroundColor="#DCE7E7"
			foregroundColor="#F9F9F9"
			{...props}
		>
			<rect x="10" y="16" rx="3" ry="3" width="100" height="28" />
			<rect x="10" y="84" rx="3" ry="3" width="139" height="8" />
			<rect x="10" y="62" rx="3" ry="3" width="178" height="8" />
			<rect x="10" y="130" rx="3" ry="3" width="88" height="44" />
			<rect x="10" y="190" rx="3" ry="3" width="230" height="48" />
			<rect x="15" y="312" rx="3" ry="3" width="134" height="8" />
			<rect x="40" y="344" rx="3" ry="3" width="165" height="8" />
			<circle cx="25" cy="348" r="8" />
			<rect x="40" y="379" rx="3" ry="3" width="146" height="8" />
			<circle cx="25" cy="383" r="8" />
			<rect x="40" y="414" rx="3" ry="3" width="142" height="8" />
			<circle cx="25" cy="418" r="8" />
		</ContentLoader>
	</div>
);

export default PricingSkeletonCard;
