import {Tooltip} from "react-tooltip";

import type {TDealExperienceTooltipProps} from "./types";
import styles from "components/Other/DealExperience/dealExperienceInfo.module.scss";
import DealExperienceInfo from "../Other/DealExperience/dealExperienceInfo";

const DealExperienceToolTip = ({events, id}: TDealExperienceTooltipProps) => {
	return (
		<Tooltip float id={id} place="bottom" delayShow={500} className={styles.wrapper}>
			{events.length > 0 && <DealExperienceInfo events={events} />}
		</Tooltip>
	);
};

export default DealExperienceToolTip;
