import {useEffect} from "react";
import config from "config";
import ReactGA from "react-ga4";
import {hotjar} from "react-hotjar";
import {Mixpanel} from "helpers/mixpanel";
import {userEmailSelector} from "store/mapx/user/userSelectors";
import {useSelector} from "react-redux";
import {useLocation} from "react-router-dom";

const pageTitleForCompaniesSearch = () => {
	const {pathname} = useLocation();

	const userEmail = useSelector(userEmailSelector);

	useEffect(() => {
		try {
			let pageTitle = "";

			if (pathname === "/companies") {
				pageTitle = `Company Search`;
			} else {
				pageTitle = `Target Lists`;
			}

			window.document.title = `${pageTitle} | ${config.pageTitlePostFix}`;

			ReactGA.send({
				hitType: "pageview",
				page: window.location.pathname,
				title: `${pageTitle} | ${config.pageTitlePostFix}`,
			});

			if (hotjar.initialized()) {
				hotjar.stateChange(window.location.pathname);
			}

			Mixpanel.track(`${pageTitle} Page Viewed`, {
				pageTitle: `${pageTitle} Page`,
				url: window.location.pathname,
				distinct_id: userEmail,
			});
		} catch (e) {
			console.log(e);
		}
	}, [pathname, userEmail]);
};

export default pageTitleForCompaniesSearch;
