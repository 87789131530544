import React, {useEffect, useMemo, useState} from "react";
import css from "./diversityFilter.module.scss";
import Slider from "rc-slider";
import classNames from "classnames";
import "rc-slider/assets/index.css";
import {ResetFilters} from "components";

function DiversitySelectionFilter(props) {
	const {handleResetClick, genderDiversityImportance, ethnicDiversityImportance, setFilterParam} =
		props;

	const [genderValue, setGenderValue] = useState(0);

	const [ethnicityValue, setEthnicityValue] = useState(0);

	const sliderLabelByValue = (value) => {
		if (value === 33) {
			return "Low";
		} else if (value === 66) {
			return "Medium";
		} else if (value === 99) {
			return "High";
		} else {
			return "None";
		}
	};

	const genderTitle = useMemo(() => {
		return sliderLabelByValue(genderValue);
	}, [genderValue]);

	const ethnicityTitle = useMemo(() => {
		return sliderLabelByValue(ethnicityValue);
	}, [ethnicityValue]);

	const onFilterChanged = (type = "gender", value) => {
		const title = sliderLabelByValue(value);

		// If value is not changed handled
		if (type === "gender" && title !== genderDiversityImportance) {
			setFilterParam("gender_diversity_importance", title === "None" ? null : title);
		} else if (type === "ethnicity" && title !== ethnicDiversityImportance) {
			setFilterParam("ethnic_diversity_importance", title === "None" ? null : title);
		}
	};

	const getSliderValueByTextValue = (textValue) => {
		switch (textValue) {
			case "High":
				return 99;
			case "Medium":
				return 66;
			case "Low":
				return 33;
			default:
				return 0;
		}
	};

	useEffect(() => {
		if (genderDiversityImportance) {
			const value = getSliderValueByTextValue(genderDiversityImportance);
			setGenderValue(value);
		} else {
			setGenderValue(0);
		}
	}, [genderDiversityImportance]);

	useEffect(() => {
		if (ethnicDiversityImportance) {
			const value = getSliderValueByTextValue(ethnicDiversityImportance);
			setEthnicityValue(value);
		} else {
			setEthnicityValue(0);
		}
	}, [ethnicDiversityImportance]);

	const resetClick = () => {
		setGenderValue(0);
		setEthnicityValue(0);
		handleResetClick();
	};

	return (
		<div className={css.diversityContainer}>
			<ResetFilters
				parentStyle={{color: "#5A5A5A", marginRight: 19}}
				onClick={resetClick}
				displayIcon={true}
			>
				Clear Selection
			</ResetFilters>

			<div className={css.rangeTitle}>Gender</div>
			<Slider
				dots
				key={"genderSlider"}
				className={classNames(css.slide, css.slideContainer)}
				step={33}
				max={99}
				value={genderValue}
				onChange={(value) => {
					setGenderValue(value);
					onFilterChanged("gender", value);
				}}
			/>

			<div className={css.rangeResult}>{genderTitle || "None"}</div>

			<div className={css.rangeTitle}>Ethnicity</div>
			<Slider
				key={"ethnicitySlider"}
				className={classNames(css.slide, css.slideContainer)}
				step={33}
				max={99}
				value={ethnicityValue}
				onChange={(value) => {
					setEthnicityValue(value);
					onFilterChanged("ethnicity", value);
				}}
			/>

			<div className={css.rangeResult}>{ethnicityTitle || "None"}</div>
		</div>
	);
}

export default DiversitySelectionFilter;
