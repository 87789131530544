import type {FC} from "react";

import OverviewSkeleton from "./OverviewSkeleton";
import LocationSkeleton from "./LocationSkeleton";
import CompetitorsSkeleton from "./CompetitorsSkeleton";
import RoundChartGroupSkeleton from "./RoundChartGroupSkeleton";
import OrganizationChartSkeleton from "./OrganizationChartSkeleton";
import OrganizationalChartSectionSkeleton from "./OrganizationalChartSectionSkeleton";

const CompanySkeletons: FC = () => (
	<div style={{backgroundColor: "#FFF", padding: 30}}>
		<OverviewSkeleton />
		<OrganizationalChartSectionSkeleton />
		<OrganizationChartSkeleton />
		<RoundChartGroupSkeleton />
		<LocationSkeleton />
		<CompetitorsSkeleton />
	</div>
);

export default CompanySkeletons;
