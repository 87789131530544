import {setMultipleFilterForCandidates} from "store/mapx/filter/filterActions";
import {Dispatch} from "redux";
import {TAppDispatch, TRootState} from "types";
import {
	candidateCompanyHeadcountByPositionSelector,
	companyHeadcountFilterKeyByPosition,
} from "store/mapx/filter/companyHeadcountFilterSelectors";
import {
	TCompanyHeadcountPosition,
	TCompanyHeadcountRangeItem,
} from "mapx-components/Filters/CompanyHeadcountSearchFilter/types";

export const setCompanyHeadcountForCandidate =
	({
		position,
		headcountItem,
	}: {
		position: TCompanyHeadcountPosition;
		headcountItem: TCompanyHeadcountRangeItem;
	}) =>
	async (dispatch: Dispatch<TAppDispatch>, getState: () => TRootState) => {
		const state = getState();

		const headCountFilterKey = companyHeadcountFilterKeyByPosition(state, position);

		const idsOnState = candidateCompanyHeadcountByPositionSelector(state, position);

		const index = idsOnState.indexOf(headcountItem.id);

		const alreadySelected = index !== -1;

		const nextIds = alreadySelected
			? [...idsOnState.slice(0, index), ...idsOnState.slice(index + 1)]
			: [...idsOnState, headcountItem.id];

		dispatch(
			setMultipleFilterForCandidates({
				[headCountFilterKey]: nextIds,
			}),
		);
	};

export const moveHeadcountForCandidate =
	({
		from,
		to,
		ids,
	}: {
		from: TCompanyHeadcountPosition;
		to: TCompanyHeadcountPosition;
		ids: string[];
	}) =>
	async (dispatch: Dispatch<TAppDispatch>, getState: () => TRootState) => {
		const idsSet = new Set(ids);

		const state = getState();

		const fromFilterKey = companyHeadcountFilterKeyByPosition(state, from);

		const toFilterKey = companyHeadcountFilterKeyByPosition(state, to);

		const fromIds = candidateCompanyHeadcountByPositionSelector(state, from);

		const toIds = candidateCompanyHeadcountByPositionSelector(state, to);

		dispatch(
			setMultipleFilterForCandidates({
				[fromFilterKey]: fromIds.filter((id: string) => !idsSet.has(id)),
				[toFilterKey]: [
					...toIds.filter((id: string) => !idsSet.has(id)), // prevent duplication
					...fromIds.filter((id: string) => idsSet.has(id)),
				],
			}),
		);
	};
