import type {FC} from "react";
import classNames from "classnames";

import type {TResetFiltersProps} from "./types";

import styles from "./resetFilters.module.scss";
import {ClearSelectionIcon} from "assets/icons";

const ResetFilters: FC<TResetFiltersProps> = ({
	children = "Reset Filters",
	className,
	customIcon = null,
	displayIcon = false,
	onClick,
	parentStyle,
	style,
	...rest
}) => (
	<div className={classNames(styles.container, className)} style={parentStyle} {...rest}>
		<span role="button" className={styles.resetFilters} onClick={onClick} style={style}>
			{displayIcon && (customIcon ? customIcon : <ClearSelectionIcon />)} {children}
		</span>
	</div>
);

export default ResetFilters;
