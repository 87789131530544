import {memo, useCallback, useMemo} from "react";
import {
	clearSpecialismsByPositionForCandidate,
	moveSpecialismForCandidate,
	setBulkSpecialismsForCandidates,
	setSpecialismForCandidate,
	switchSpecialismsConnectorForCandidatePosition,
} from "store/mapx/filter/jobFunctionAsyncAction";
import {
	allSpecialismsCountSelector,
	anyJobFunctionTagsSelector,
	anyPositionConnectivityLogicSelector,
	currentJobFunctionTagsSelector,
	currentPositionConnectivityLogicSelector,
	jobFunctionCandidatePositionSelector,
	jobFunctionFilterConfigSelector,
	jobFunctionSelectionTreeSelector,
	previousJobFunctionTagsSelector,
	previousPositionConnectivityLogicSelector,
	specialismsByPositionSelector,
} from "store/mapx/filter/jobFunctionFilterSelectors";
import {TabSelect} from "components";
import {Accordion, FunctionNSpecialismSearchFilter} from "mapx-components";
import {
	setJobFunctionFilterConfig,
	setMultipleFilterForCandidates,
} from "store/mapx/filter/filterActions";
import {TJobFunctionFilterProps} from "containers/Filters/PeopleFilters/JobFunctionFilter/types";
import {useAppDispatch, useAppSelector} from "hooks";
import {
	TJobFunctionPosition,
	TJobFunctionPositionConnectivityLogic,
} from "api/filterOptionsApi/JobFunctionApi/types";
import {filterPositionOptionsSelector} from "store/mapx/filter/filterSelectors";
import {TRootState} from "types";

function JobFunctionFilter({disabled}: TJobFunctionFilterProps) {
	const dispatch = useAppDispatch();

	const jobFunctionFilterConfig = useAppSelector(jobFunctionFilterConfigSelector);

	const position = useAppSelector<TJobFunctionPosition>(jobFunctionCandidatePositionSelector);

	const currentPositionConnectivityLogic = useAppSelector<TJobFunctionPositionConnectivityLogic>(
		currentPositionConnectivityLogicSelector,
	);

	const previousPositionConnectivityLogic = useAppSelector<TJobFunctionPositionConnectivityLogic>(
		previousPositionConnectivityLogicSelector,
	);

	const anyPositionConnectivityLogic = useAppSelector<TJobFunctionPositionConnectivityLogic>(
		anyPositionConnectivityLogicSelector,
	);

	const logicByCurrentlySelectedPosition = useMemo(() => {
		if (position === "current") {
			return currentPositionConnectivityLogic;
		} else if (position === "previous") {
			return previousPositionConnectivityLogic;
		} else {
			return anyPositionConnectivityLogic;
		}
	}, [
		anyPositionConnectivityLogic,
		currentPositionConnectivityLogic,
		position,
		previousPositionConnectivityLogic,
	]);

	const filterPositionOptions = useAppSelector(filterPositionOptionsSelector);

	const selectionTree = useAppSelector((state: TRootState) =>
		jobFunctionSelectionTreeSelector(state, position, logicByCurrentlySelectedPosition),
	);

	const count = useAppSelector(allSpecialismsCountSelector);

	const anyTags = useAppSelector(anyJobFunctionTagsSelector);

	const currentTags = useAppSelector(currentJobFunctionTagsSelector);

	const previousTags = useAppSelector(previousJobFunctionTagsSelector);

	const selectedSpecialismIds = useAppSelector((state) =>
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		specialismsByPositionSelector(state, position, logicByCurrentlySelectedPosition),
	);

	const handlePositionChange = useCallback(
		(updatedPositionValue: string) => {
			dispatch(
				setJobFunctionFilterConfig({
					...jobFunctionFilterConfig,
					active_position: updatedPositionValue,
				}),
			);
		},
		[dispatch, jobFunctionFilterConfig],
	);

	const handleSpecialismChange = useCallback(
		(id: number) => {
			dispatch(
				setSpecialismForCandidate({
					position: position,
					logic: logicByCurrentlySelectedPosition,
					id: id,
				}),
			);
		},
		[dispatch, logicByCurrentlySelectedPosition, position],
	);

	const handleTagClick = useCallback(
		(
			id: number,
			position: TJobFunctionPosition,
			logic: TJobFunctionPositionConnectivityLogic,
		) => {
			dispatch(
				setSpecialismForCandidate({
					position,
					logic,
					id,
				}),
			);
		},
		[dispatch],
	);

	const getLogicConnectorByPosition = useCallback(
		(positionParam: TJobFunctionPosition) => {
			let logic: TJobFunctionPositionConnectivityLogic;

			switch (positionParam) {
				case "current":
					logic = currentPositionConnectivityLogic;
					break;
				case "previous":
					logic = previousPositionConnectivityLogic;
					break;
				case "any":
					logic = anyPositionConnectivityLogic;
					break;
				default:
					throw Error("This position doesn't exist");
			}

			return logic;
		},
		[
			anyPositionConnectivityLogic,
			currentPositionConnectivityLogic,
			previousPositionConnectivityLogic,
		],
	);

	const moveSpecialismTagPosition = useCallback(
		(id: number, fromPosition: TJobFunctionPosition, toPosition: TJobFunctionPosition) => {
			const fromLogic = getLogicConnectorByPosition(fromPosition);
			const toLogic = getLogicConnectorByPosition(toPosition);

			dispatch(
				moveSpecialismForCandidate({
					from: fromPosition,
					to: toPosition,
					fromLogic: fromLogic,
					toLogic: toLogic,
					id: id,
				}),
			);
		},
		[dispatch, getLogicConnectorByPosition],
	);

	const handleBulkUpdate = useCallback(
		(ids: number[]) => {
			dispatch(
				setBulkSpecialismsForCandidates(position, logicByCurrentlySelectedPosition, ids),
			);
		},
		[dispatch, logicByCurrentlySelectedPosition, position],
	);

	const handleClearTagClickByPosition = useCallback(
		(position: TJobFunctionPosition) => {
			dispatch(clearSpecialismsByPositionForCandidate(position));
		},
		[dispatch],
	);

	const handleResetClick = useCallback(() => {
		dispatch(
			setMultipleFilterForCandidates({
				specialisms_and: [],
				specialisms_or: [],
				specialisms_not: [],
				current_specialisms_and: [],
				current_specialisms_or: [],
				current_specialisms_not: [],
				previous_specialisms_and: [],
				previous_specialisms_or: [],
				previous_specialisms_not: [],
			}),
		);
	}, [dispatch]);

	const handleConnectivityOptionChange = useCallback(
		(position: TJobFunctionPosition, toLogic: TJobFunctionPositionConnectivityLogic) => {
			let fromLogic: TJobFunctionPositionConnectivityLogic;

			switch (position) {
				case "current":
					fromLogic = currentPositionConnectivityLogic;

					dispatch(
						setJobFunctionFilterConfig({
							...jobFunctionFilterConfig,
							active_current_position_connect_logic: toLogic,
						}),
					);
					break;
				case "previous":
					fromLogic = previousPositionConnectivityLogic;

					dispatch(
						setJobFunctionFilterConfig({
							...jobFunctionFilterConfig,
							active_previous_position_connect_logic: toLogic,
						}),
					);
					break;
				case "any":
					fromLogic = anyPositionConnectivityLogic;

					dispatch(
						setJobFunctionFilterConfig({
							...jobFunctionFilterConfig,
							active_any_position_connect_logic: toLogic,
						}),
					);
					break;
				default:
					throw Error("This position doesn't exist");
			}

			dispatch(
				switchSpecialismsConnectorForCandidatePosition({
					position,
					fromLogic,
					toLogic,
				}),
			);
		},
		[
			anyPositionConnectivityLogic,
			currentPositionConnectivityLogic,
			dispatch,
			jobFunctionFilterConfig,
			previousPositionConnectivityLogic,
		],
	);

	return (
		<Accordion title="Function & Specialism" subtitle={count} disabled={disabled}>
			<TabSelect.LabelContainer label="Position">
				<TabSelect
					selected={position}
					onTabChange={handlePositionChange}
					options={filterPositionOptions}
				/>
			</TabSelect.LabelContainer>

			<FunctionNSpecialismSearchFilter
				selectionTree={selectionTree}
				handleTagClick={handleTagClick}
				handleResetClick={handleResetClick}
				handleBulkUpdate={handleBulkUpdate}
				handleSpecialismChange={handleSpecialismChange}
				displayResetFilterOption={count > 0}
				selectedSpecialismIds={selectedSpecialismIds}
				moveSpecialismTagPosition={moveSpecialismTagPosition}
				selectedSpecialismTags={{
					current: currentTags,
					previous: previousTags,
					any: anyTags,
				}}
				handleConnectivityOptionChange={handleConnectivityOptionChange}
				connectionLogics={{
					current: currentPositionConnectivityLogic,
					previous: previousPositionConnectivityLogic,
					any: anyPositionConnectivityLogic,
				}}
				clearTagsByPosition={handleClearTagClickByPosition}
			/>
		</Accordion>
	);
}

export default memo(JobFunctionFilter);
