import {FC, useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Tooltip} from "react-tooltip";

import styles from "./upgradeNowTooltip.module.scss";
import type {TUpgradeNowTooltipMessage} from "./types";
import {contactAdminModal, pricingTableModal} from "store/mapx/payment/paymentActions";
import {userOrganisationRoleSelector} from "store/mapx/user/userSelectors";
import {
	organisationAdminSelector,
	organisationHasPaidPlanSelector,
} from "store/mapx/organisation/organisationSelectors";

const UpgradeNowTooltip: FC<TUpgradeNowTooltipMessage> = ({id, type = "general"}) => {
	const dispatch = useDispatch();

	const organisationRole = useSelector(userOrganisationRoleSelector);

	const admin = useSelector(organisationAdminSelector);

	const organisationHasPaidPlan = useSelector(organisationHasPaidPlanSelector);

	const handleUpgrade = useCallback(() => {
		if (!organisationHasPaidPlan && (admin == null || organisationRole === "Admin")) {
			dispatch(pricingTableModal(true));
		} else {
			dispatch(contactAdminModal(true));
		}
	}, [admin, dispatch, organisationHasPaidPlan, organisationRole]);

	return (
		<Tooltip clickable place="right" id={id} delayShow={100} className={styles.infoTooltip}>
			<h3 className={styles.infoTooltip_title}>Premium Features</h3>

			{type === "general" && (
				<div className={styles.infoTooltip__description}>
					Your plan does not include premium features.{" "}
					<b className={styles.infoTooltip__description_green} onClick={handleUpgrade}>
						Upgrade
					</b>{" "}
					now to access them.
				</div>
			)}

			{type === "more_like_this_limit" && (
				<div className={styles.infoTooltip__description}>
					You have reached the maximum limit of 3 searches per project.{" "}
					<b className={styles.infoTooltip__description_green} onClick={handleUpgrade}>
						Upgrade
					</b>{" "}
					to Plus (free for 7 days) to unlock unlimited searching!
				</div>
			)}
		</Tooltip>
	);
};

export default UpgradeNowTooltip;
