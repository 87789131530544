import type {FC} from "react";
import classNames from "classnames";

import type {TCheckbox} from "./types";
import styles from "./checkbox.module.scss";
import {LockIcon} from "assets/icons";
import {UpgradeNowTooltip} from "../../index";

const Checkbox: FC<TCheckbox> = ({
	label,
	value,
	onChange,
	className,
	containerClass,
	isChecked = false,
	partiallySelected,
	borderColor = "#808080",
	disabled = false,
	isRecommended = false,
	readOnly = false,
	isPremiumOption = false,
	...rest
}) => (
	<label
		className={classNames(styles.container, containerClass, {
			[styles.disabledCheckbox]: disabled,
		})}
	>
		<input
			type="checkbox"
			value={value}
			onChange={onChange}
			checked={isChecked}
			className={styles.checkbox}
			disabled={disabled}
			readOnly={readOnly}
			{...rest}
		/>
		<span
			className={classNames(styles.checkmark, className, {
				[styles.partiallySelected]: partiallySelected,
				[styles.disabled]: disabled,
			})}
			style={{
				border: partiallySelected ? "2px solid #0C5850" : `1px solid ${borderColor}`,
			}}
			title={value}
		/>
		<div
			title={value}
			className={classNames(styles.label, {[styles.recommended]: isRecommended})}
		>
			{label}
		</div>

		{isPremiumOption && (
			<div className={styles.lockOption}>
				<div data-testid="premium-test-id" data-tooltip-id={`checkboxTooltip`}>
					<LockIcon />

					<UpgradeNowTooltip id={"checkboxTooltip"} />
				</div>
			</div>
		)}
	</label>
);

export default Checkbox;
