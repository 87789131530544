import type {FC} from "react";

import type {IconProps} from "./types";

const CreditCardIcon: FC<IconProps> = ({className, color = "#383838"}) => (
	<svg
		width="24"
		height="24"
		fill="none"
		viewBox="0 0 24 24"
		className={className}
		xmlns="http://www.w3.org/2000/svg"
	>
		<mask
			x="0"
			y="0"
			width="24"
			height="24"
			id="mask0_2775_72083"
			maskUnits="userSpaceOnUse"
			style={{maskType: "alpha"}}
		>
			<rect width="24" height="24" fill="#D9D9D9" />
		</mask>
		<g mask="url(#mask0_2775_72083)">
			<path
				strokeWidth="2"
				stroke={color}
				d="M21.5069 18.5333C21.205 18.826 20.7927 18.9961 20.3572 18.9999H3.63269C3.19136 18.9999 2.77326 18.8288 2.46895 18.5323L2.46876 18.5321C2.16557 18.2369 2 17.8413 2 17.4344V6.56554C2 6.15866 2.16555 5.76313 2.4688 5.46777L2.46899 5.46759C2.7732 5.17114 3.19132 5 3.63269 5H20.3623C20.8044 5 21.2234 5.17115 21.5286 5.46794C21.8326 5.76368 21.999 6.15924 22 6.56649C22 6.56679 22 6.56708 22 6.56738L22 9.0409H17.102H17.0855L17.069 9.04144C16.4821 9.06079 15.9713 9.5267 15.9494 10.1554L15.9488 10.1727L15.9488 10.19L15.9482 13.8506L15.9482 13.868L15.9488 13.8853C15.9706 14.5159 16.4829 14.9801 17.0684 14.9995L17.0849 15H17.1014H21.9791L21.9796 17.4373C21.9774 17.8436 21.8106 18.2382 21.5069 18.5333ZM21.979 14.7557V14.7559V14.7557Z"
			/>
			<path
				fill={color}
				d="M12.1782 15.9869H5.82179C5.6167 16.0342 5.40761 15.9522 5.24937 15.762C5.09112 15.5722 5 15.2935 5 14.9999C5 14.7063 5.09112 14.4276 5.24937 14.2378C5.40762 14.0476 5.6167 13.9656 5.82179 14.0133H12.1782C12.383 13.9656 12.5924 14.0476 12.7503 14.2378C12.9086 14.4276 13 14.7063 13 14.9999C13 15.2935 12.9086 15.5722 12.7503 15.762C12.5924 15.9522 12.383 16.0342 12.1782 15.9869Z"
			/>
			<path
				fill={color}
				d="M19 12C19 12.5521 19.4476 13 20 13C20.5521 13 21 12.5521 21 12C21 11.4476 20.5521 11 20 11C19.4476 11 19 11.4476 19 12Z"
			/>
		</g>
	</svg>
);

export default CreditCardIcon;
