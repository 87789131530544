import HttpClient from "api/index";

class MapxIndexApi extends HttpClient {
	async getIndexes(config = {}) {
		return this.doGet("/mapx/indexes", config);
	}
}

const mapxIndexApi = new MapxIndexApi();

export default mapxIndexApi;
