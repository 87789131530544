import {useCallback, useEffect, useRef, useState} from "react";

import colors from "styles/themes.module.scss";
import {ThreeDotsVerticalIcon} from "assets/icons";
import {Loader, Tooltip} from "components";

import css from "./options.module.scss";
import type {TOptions} from "./types";

const Options = ({options, loading}: TOptions) => {
	const ref = useRef<HTMLDivElement>(null);
	const buttonRef = useRef<HTMLDivElement>(null);

	const [expanded, setExpanded] = useState(false);

	const toggleExpand = useCallback(() => setExpanded(!expanded), [expanded]);

	useEffect(() => {
		const handleClickOutside = (event: Event) => {
			if (
				ref.current &&
				!ref.current.contains(event.target as Node) &&
				buttonRef.current &&
				!buttonRef.current.contains(event.target as Node) &&
				buttonRef.current &&
				buttonRef.current !== event.target
			) {
				setExpanded(false);
			}
		};

		document.addEventListener("mousedown", handleClickOutside);

		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	return (
		<Tooltip showArrow placement="bottom" open={expanded && !loading} allowInteraction>
			<Tooltip.Trigger asChild>
				<div className={css.trigger}>
					{loading ? (
						<Loader
							width={24}
							height={24}
							type="TailSpin"
							color={colors.loaderDotColor}
						/>
					) : (
						<div className={css.threeDots} onClick={toggleExpand} ref={buttonRef}>
							<ThreeDotsVerticalIcon />
						</div>
					)}
				</div>
			</Tooltip.Trigger>
			<Tooltip.Content ref={ref}>
				{options.map((option) => (
					<div
						className={css.option}
						role="button"
						key={option.name}
						onClick={() => {
							option.action();
							setExpanded(false);
						}}
						style={{color: option.color || "inherit"}}
					>
						{option.icon}
						{option.name}
					</div>
				))}
			</Tooltip.Content>
		</Tooltip>
	);
};

export default Options;
