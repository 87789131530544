import {createSelector} from "reselect";
import {flattenCountriesSelector} from "store/mapx/filter/filterSelectors";
import {TRootState} from "types";
import {TLocationPosition} from "containers/Filters/PeopleFilters/LocationFilter/types";
import {TFilterCountry} from "api/filterOptionsApi/CountryApi/types";
import {TRegion} from "../../../mapx-components/Filters/LocationSearchFilter/types";

export const candidateAnyCountryIDsSelector = (state: TRootState) =>
	state.filter.candidate_filters.countries_or;
export const candidateCurrentCountryIDsSelector = (state: TRootState) =>
	state.filter.candidate_filters.current_countries_or;
export const candidatePreviousCountryIDsSelector = (state: TRootState) =>
	state.filter.candidate_filters.previous_countries_or;

export const candidateAnyRegionIDsSelector = (state: TRootState) =>
	state.filter.candidate_filters.regions_or;
export const candidateCurrentRegionIDsSelector = (state: TRootState) =>
	state.filter.candidate_filters.current_regions_or;
export const candidatePreviousRegionIDsSelector = (state: TRootState) =>
	state.filter.candidate_filters.previous_regions_or;

export const candidateLocationsSelector = (
	state: TRootState,
	type: "current" | "previous" | "any",
) => {
	switch (type) {
		case "current":
			return state.filter.candidate_filters.current_countries_or;
		case "previous":
			return state.filter.candidate_filters.previous_countries_or;
		case "any":
			return state.filter.candidate_filters.countries_or;
		default:
			throw new Error(`Invalid type: ${type}. Expected "current", "previous", or "any".`);
	}
};

// export const candidateRegionsByPositionSelector = (state: TRootState) =>
// 	state.filter.candidate_filters.regions;

export const candidateRegionsByPositionSelector = (
	state: TRootState,
	type: "current" | "previous" | "any",
) => {
	switch (type) {
		case "current":
			return state.filter.candidate_filters.current_regions_or;
		case "previous":
			return state.filter.candidate_filters.previous_regions_or;
		case "any":
			return state.filter.candidate_filters.regions_or;
		default:
			throw new Error(`Invalid type: ${type}. Expected "current", "previous", or "any".`);
	}
};

export const locationFilterConfigSelector = (state: TRootState) =>
	state.filter.candidate_location_filter_config;
export const locationCandidatePositionSelector = (state: TRootState) =>
	state.filter.candidate_location_filter_config.active_position;

const getLocationPosition = (_: TRootState, position: TLocationPosition) => position;

export const getSelectedCountriesAndRegions = (
	countryIDs: number[],
	regionIDs: number[],
	options: {id: number; name: string; regions?: TRegion[]}[],
): TFilterCountry[] => {
	const selected: TFilterCountry[] = [];

	const countrySet = new Set(countryIDs);
	const regionSet = new Set(regionIDs);

	options.forEach((o) => {
		const isCountryMatch = countrySet.has(o.id);

		if (o.regions && o.regions.length > 0) {
			if (isCountryMatch) {
				selected.push({
					id: o.id,
					name: o.name,
				});
			} else {
				const matchingRegions = o.regions.filter((region: TRegion) =>
					regionSet.has(region.id),
				);

				matchingRegions.forEach((region: TRegion) => {
					selected.push({
						id: o.id,
						name: `${o.name}: ${region.name}`,
						region_id: region.id,
					});
				});
			}
		} else if (isCountryMatch) {
			selected.push({
				id: o.id,
				name: o.name,
			});
		}
	});

	return selected;
};

export const selectedAnyCountriesSelector = createSelector(
	[candidateAnyCountryIDsSelector, candidateAnyRegionIDsSelector, flattenCountriesSelector],
	(countries, regions, options) => getSelectedCountriesAndRegions(countries, regions, options),
);

export const selectedPreviousCountriesSelector = createSelector(
	[
		candidatePreviousCountryIDsSelector,
		candidatePreviousRegionIDsSelector,
		flattenCountriesSelector,
	],
	(countries, regions, options) => getSelectedCountriesAndRegions(countries, regions, options),
);

export const selectedCurrentCountriesSelector = createSelector(
	[
		candidateCurrentCountryIDsSelector,
		candidateCurrentRegionIDsSelector,
		flattenCountriesSelector,
	],
	(countries, regions, options) => getSelectedCountriesAndRegions(countries, regions, options),
);

export const allCountriesCountSelector = createSelector(
	[
		candidateCurrentCountryIDsSelector,
		candidatePreviousCountryIDsSelector,
		candidateAnyCountryIDsSelector,
		candidateCurrentRegionIDsSelector,
		candidatePreviousRegionIDsSelector,
		candidateAnyRegionIDsSelector,
	],
	(
		currentCountries,
		previousCountries,
		anyCountries,
		currentRegions,
		previousRegions,
		anyRegions,
	) =>
		currentCountries?.length +
		previousCountries?.length +
		anyCountries?.length +
		currentRegions?.length +
		previousRegions?.length +
		anyRegions?.length,
);

export const locationFilterKeyByPosition = createSelector(getLocationPosition, (position) => {
	switch (position) {
		case "current":
			return "current_countries_or";
		case "previous":
			return "previous_countries_or";
		case "any":
		default:
			return "countries_or";
	}
});

export const regionFilterKeyByPosition = createSelector(getLocationPosition, (position) => {
	switch (position) {
		case "current":
			return "current_regions_or";
		case "previous":
			return "previous_regions_or";
		case "any":
		default:
			return "regions_or";
	}
});

export const candidateCountryIdsByPositionSelector = createSelector(
	[
		candidateCurrentCountryIDsSelector,
		candidatePreviousCountryIDsSelector,
		candidateAnyCountryIDsSelector,
		(_, position) => position,
	],
	(currentCountries, previousCountries, anyCountries, position) => {
		switch (position) {
			case "current":
				return currentCountries || [];
			case "previous":
				return previousCountries || [];
			case "any":
				return anyCountries || [];
			default:
				return [];
		}
	},
);

export const candidateCountriesByPositionSelector = createSelector(
	[
		selectedCurrentCountriesSelector,
		selectedPreviousCountriesSelector,
		selectedAnyCountriesSelector,
		(_, position) => position,
	],
	(currentCountries, previousCountries, anyCountries, position) => {
		switch (position) {
			case "current":
				return currentCountries || [];
			case "previous":
				return previousCountries || [];
			case "any":
				return anyCountries || [];
			default:
				return [];
		}
	},
);

export const candidateRegionIdsByPositionSelector = createSelector(
	[
		candidateCurrentRegionIDsSelector,
		candidatePreviousRegionIDsSelector,
		candidateAnyRegionIDsSelector,
		(_, position) => position,
	],
	(currentRegions, previousRegions, anyRegions, position) => {
		switch (position) {
			case "current":
				return currentRegions || [];
			case "previous":
				return previousRegions || [];
			case "any":
				return anyRegions || [];
			default:
				return [];
		}
	},
);
