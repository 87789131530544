import {useCallback, useMemo, useState} from "react";
import useDebouncedEffect from "use-debounced-effect";
import {useSelector} from "react-redux";
import useFetchListOptions from "hooks/useFetchListOptions";
import {Checkbox, CheckboxSkeletonLoader, ResetFilters} from "components";
import {SearchInput} from "mapx-components";
import {getCountries} from "store/mapx/search/searchAsyncActions";
import {countryOptionsSelector} from "store/mapx/search/searchSelectors";
import CheckboxList from "mapx-components/Inputs/CheckboxList";
import {getName} from "helpers/string";
import ExpandableArrow from "mapx-components/ExpandableArrow";
import {
	arrayIntersected,
	hasCommonItems,
	removeInputArrayIdentifiersFromAnotherArray,
} from "helpers/array";
import {removeDuplicatesFromArray} from "helpers/filterHandlers";

import {getLocationExpandableData} from "./utils";
import LocationFilterTags from "mapx-components/Filters/LocationSearchFilter/Tags";
import {
	LocationSearchFilterProps,
	TContinent,
	TCountry,
	TGetLocationExpandableDataResult,
	TRegion,
} from "./types";

const LocationSearchFilter = ({
	filterType = "candidates",
	handleResetClick,
	handleOnChange,
	handleBulkUpdate,
	selectedLocation = [],
	selectedCountryRegions = [],
	disabled = false,
	handleRegionChange,
	handleTagClick,
	handleMoveLocationTag,
	selectedCountries,
	allSelectedCountriesCount = 0,
}: LocationSearchFilterProps) => {
	const countryOptions = useSelector(countryOptionsSelector);

	const {filteredData, onFilterChanged, searchTerm, loading} = useFetchListOptions({
		options: countryOptions,
		callbackMethod: getCountries,
		childFilterKey: "countries",
		nestedChildFilterKey: "regions",
	});

	const [expanded, setExpanded] = useState<TGetLocationExpandableDataResult>({});

	const shouldRenderCountryRegions = useMemo(
		() => filterType === "candidates" || filterType === "ap_candidates",
		[filterType],
	);

	const shouldRenderTags = useMemo(
		() =>
			(filterType === "candidates" || filterType === "ap_candidates") &&
			allSelectedCountriesCount !== 0,
		[filterType, allSelectedCountriesCount],
	);

	useDebouncedEffect(
		() => {
			if (!searchTerm) {
				setExpanded({});

				return;
			}

			const expandableFilteredData = getLocationExpandableData(
				filteredData,
			) as TGetLocationExpandableDataResult;

			setExpanded(expandableFilteredData);
		},
		500,
		[searchTerm],
	);

	const countryIdsByContinent = useCallback((continent: TContinent) => {
		return continent?.countries?.map(({id}) => id);
	}, []);

	const hasContinentSelected = useCallback(
		(continent: TContinent) =>
			arrayIntersected(selectedLocation, countryIdsByContinent(continent)),
		[countryIdsByContinent, selectedLocation],
	);

	const hasChildren = useCallback(
		(item: TCountry) =>
			shouldRenderCountryRegions && item.regions !== undefined && item.regions?.length > 0,
		[shouldRenderCountryRegions],
	);

	const isCountryPartiallySelected = useCallback(
		(country: TCountry) => {
			const selectedLocationSet = new Set(selectedLocation);

			if (
				hasChildren(country) &&
				selectedCountryRegions?.length > 0 &&
				!selectedLocationSet.has(country.id)
			) {
				const allRegionIdsForCurrentCountry = country.regions.map((i) => i.id);

				return hasCommonItems(selectedCountryRegions, allRegionIdsForCurrentCountry);
			}

			return false;
		},
		[hasChildren, selectedCountryRegions, selectedLocation],
	);

	const hasContinentPartiallySelected = useCallback(
		(continent: TContinent) => {
			if (hasContinentSelected(continent)) return false;

			const selectedLocationSet = new Set(selectedLocation);

			for (const country of continent.countries) {
				if (selectedLocationSet.has(country.id) || isCountryPartiallySelected(country)) {
					return true;
				}
			}

			return false;
		},
		[hasContinentSelected, isCountryPartiallySelected, selectedLocation],
	);

	const handleContinentChange = useCallback(
		(continent: TContinent) => {
			const ids = countryIdsByContinent(continent);
			let updatedIds;

			if (hasContinentSelected(continent)) {
				updatedIds = removeInputArrayIdentifiersFromAnotherArray(ids, selectedLocation);
			} else {
				updatedIds = removeDuplicatesFromArray([...selectedLocation, ...ids]);
			}

			handleBulkUpdate(updatedIds);
		},
		[countryIdsByContinent, handleBulkUpdate, hasContinentSelected, selectedLocation],
	);

	const displayOverlay = useMemo(() => {
		return disabled;
	}, [disabled]);

	const toggleExpandItem = useCallback(
		(item: TContinent | TRegion | TCountry) => {
			if (expanded[item.id] === undefined) {
				setExpanded({
					...expanded,
					[item.id]: true,
				});
			} else {
				let toBeUpdatedList = {...expanded};

				if (expanded[item.id]) {
					toBeUpdatedList = {...toBeUpdatedList, [item.id]: false};

					if ("countries" in item && item?.countries) {
						// If country has regions, and it might be expanded
						// so, we collapse it if continent is collapsed
						for (const country of item.countries) {
							if (country?.regions.length > 0) {
								toBeUpdatedList = {...toBeUpdatedList, [country.id]: false};
							}
						}
					}
				} else {
					toBeUpdatedList = {...toBeUpdatedList, [item.id]: true};
				}

				setExpanded(toBeUpdatedList);
			}
		},
		[expanded],
	);

	const isCountryRegionSelected = useCallback(
		(regionId: number, countryId: number) => {
			let selected = selectedCountryRegions.includes(regionId);

			if (!selected) {
				selected = selectedLocation.includes(countryId); // if country is selected means region is selected
			}

			return selected;
		},
		[selectedCountryRegions, selectedLocation],
	);

	return (
		<div>
			<SearchInput
				onChange={onFilterChanged}
				placeholder="Search for a region or country"
				type="text"
				isLoading={loading}
			/>

			<ResetFilters
				parentStyle={{color: "#5A5A5A", marginRight: 19}}
				onClick={handleResetClick}
				displayIcon={true}
			>
				Clear Selection
			</ResetFilters>

			<CheckboxList
				displayOverlay={displayOverlay}
				overlayText={"Adjusting search parameters"}
			>
				{filteredData.map((cn: TContinent) => {
					const {id: continent, countries} = cn;
					const isContinentSelected = hasContinentSelected(cn);
					const isContinentPartiallySelected = hasContinentPartiallySelected(cn);

					return (
						<CheckboxList.Accordion key={continent}>
							<CheckboxList.AccordionHeader>
								<ExpandableArrow
									onClick={() => toggleExpandItem(cn)}
									rotated={!expanded[continent]}
								/>

								<Checkbox
									borderColor="#0C5850"
									isChecked={isContinentSelected}
									key={continent}
									label={`${getName(String(continent))} (${countries.length})`}
									onChange={() => handleContinentChange(cn)}
									value={String(continent)}
									partiallySelected={isContinentPartiallySelected}
									disabled={disabled}
								/>
							</CheckboxList.AccordionHeader>

							{countries?.map((country) => (
								<div key={country.id}>
									<CheckboxList.AccordionChildrenContent
										expanded={expanded[continent]}
										key={country.id}
										childrenExpanded={expanded[country.id]}
										handleExpandClick={() => toggleExpandItem(country)}
										hasChildren={hasChildren(country)}
										treeIndex={1}
										item={country}
									>
										<Checkbox
											borderColor="#0C5850"
											isChecked={selectedLocation.includes(country.id)}
											partiallySelected={isCountryPartiallySelected(country)}
											key={country.id}
											label={getName(country.name)}
											onChange={() => handleOnChange(country.id)}
											value={getName(country.name)}
											disabled={disabled}
										/>
									</CheckboxList.AccordionChildrenContent>

									{shouldRenderCountryRegions &&
										hasChildren(country) &&
										country.regions.map((region) => (
											<CheckboxList.AccordionChildrenContent
												expanded={expanded[country.id]}
												key={region.id}
												childrenExpanded={false}
												handleExpandClick={() => toggleExpandItem(region)}
												hasChildren={false}
												treeIndex={2}
												item={region}
											>
												<Checkbox
													borderColor="#0C5850"
													isChecked={isCountryRegionSelected(
														region.id,
														country.id,
													)}
													key={region.id}
													label={getName(region.name)}
													onChange={() => {
														if (handleRegionChange) {
															handleRegionChange(region.id, country);
														}
													}}
													value={getName(region.name)}
													disabled={disabled}
												/>
											</CheckboxList.AccordionChildrenContent>
										))}
								</div>
							))}
						</CheckboxList.Accordion>
					);
				})}
			</CheckboxList>

			{loading && <CheckboxSkeletonLoader />}

			{shouldRenderTags && (
				<div style={{marginTop: "10px"}} data-testid="LocationFilterTags">
					<LocationFilterTags
						label="Current"
						position="current"
						selectedCountries={selectedCountries?.current}
						handleTagClick={handleTagClick}
						handleMoveLocationTag={handleMoveLocationTag}
					/>

					<LocationFilterTags
						label="Previous"
						position="previous"
						selectedCountries={selectedCountries?.previous}
						handleTagClick={handleTagClick}
						handleMoveLocationTag={handleMoveLocationTag}
					/>

					<LocationFilterTags
						label="Any"
						position="any"
						selectedCountries={selectedCountries?.any}
						handleTagClick={handleTagClick}
						handleMoveLocationTag={handleMoveLocationTag}
					/>
				</div>
			)}
		</div>
	);
};

export default LocationSearchFilter;
