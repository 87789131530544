import React, {useCallback} from "react";
import {useSelector} from "react-redux";
import LocationSearchFilter from "./locationSearchFilter";
import LocationFilterHelper from "mapx-components/Filters/LocationSearchFilter/LocationFilterHelper";
import {flattenCountriesSelector} from "store/mapx/filter/filterSelectors";
import {useAppSelector} from "hooks";
import {companiesOrgChartFiltersSelector} from "store/mapx/company/companySelectors";
import {TOrgChartFilterProps} from "../types";
import {TCountry, TRegion} from "mapx-components/Filters/LocationSearchFilter/types";

function LocationFilterForOrgChart({
	onSelectChange,
}: {
	onSelectChange: TOrgChartFilterProps["onSelectChange"];
}) {
	const filtersInState = useAppSelector(companiesOrgChartFiltersSelector);
	const selectedCountries = filtersInState?.countries;
	const selectedCountryRegions = filtersInState?.regions;

	const flattenCountries = useSelector(flattenCountriesSelector);

	const {handleRegionCheckChange} = LocationFilterHelper({
		selectedCountries,
		selectedCountryRegions,
		flattenCountries,
	});

	const handleChange = useCallback(
		(country: TCountry, checked: boolean) => {
			onSelectChange("countries", String(country.id), checked);
			if (country.regions.length > 0) {
				if (checked) {
					onSelectChange(
						"regions",
						country.regions.map((item) => item.id).join(","),
						true,
					);
				} else {
					onSelectChange("regions", null);
				}
			}
		},
		[onSelectChange],
	);

	const handleResetClick = useCallback(() => {
		onSelectChange("countries", null);
		onSelectChange("regions", null);
	}, [onSelectChange]);

	const handleBulkUpdate = useCallback(
		(countries: TCountry[], regions: TRegion[][], checked?: boolean) => {
			onSelectChange("countries", countries.map((item) => item.id).join(","), checked);

			if (checked) {
				regions.map((r) => {
					if (r.length > 0) {
						onSelectChange("regions", r.map((item: {id: number}) => item.id).join(","));
					}
				});
			} else {
				onSelectChange("regions", null);
			}
		},
		[onSelectChange],
	);

	const handleRegionChange = useCallback(
		(regionId: number, country: TCountry, checked: boolean | undefined) => {
			const {toBeSetRegionIds} = handleRegionCheckChange(regionId, country);
			onSelectChange("countries", [country.id].map((item) => item).join(","), false);
			if (toBeSetRegionIds.length > 0) {
				onSelectChange("regions", [regionId].map((item) => item).join(","), checked);
			}
		},
		[handleRegionCheckChange, onSelectChange],
	);

	return (
		<LocationSearchFilter
			filterType={"ap_candidates"}
			handleResetClick={handleResetClick}
			handleOnChange={handleChange}
			selectedLocation={selectedCountries}
			selectedCountryRegions={selectedCountryRegions}
			handleBulkUpdate={handleBulkUpdate}
			handleRegionChange={handleRegionChange}
		/>
	);
}

export default LocationFilterForOrgChart;
