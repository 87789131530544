import {Dispatch} from "react";
import {TAppDispatch, TRootState} from "types";
import mapxProjectApi from "api/projectApi";
import axios from "axios";
import {successResponse} from "helpers/map";
import {projectSuccessChecklistOptionsSelector} from "store/mapx/project/projectSelectors";
import {
	TProjectSuccessAPIAttribute,
	TProjectSuccessCheckOption,
} from "mapx-components/Layouts/ProjectChecklist/types";
import {updateProjectSuccessChecklist} from "store/mapx/project/projectActions";
import {TProjectChecklistForm} from "api/projectApi/types";
import {projectSelector} from "store/mapx/project-list/projectListSelectors";

/* eslint-disable  @typescript-eslint/no-explicit-any */
const cancelTokens = {} as any;
export const getProjectSuccessChecklist =
	(projectId: number) => async (dispatch: Dispatch<TAppDispatch>, getState: TRootState) => {
		try {
			const requestKey = `GET_PROJECT_SUCCESS_CHECKLIST`;

			if (requestKey in cancelTokens) {
				cancelTokens[requestKey].cancel("Operation canceled due to new request.");
			}

			cancelTokens[requestKey] = axios.CancelToken.source();

			const config = {
				cancelToken: cancelTokens[requestKey].token,
			};

			const state = getState();

			const response = await mapxProjectApi.getProjectSuccessChecklistInfo(projectId, config);

			if (response && successResponse(response, 200)) {
				const checklistResponse = response.data;

				const options: TProjectSuccessCheckOption[] =
					projectSuccessChecklistOptionsSelector(state);

				const updatedOptions = options?.map((option: TProjectSuccessCheckOption) => {
					option.checked = checklistResponse[option.attribute];

					return option;
				});

				dispatch(updateProjectSuccessChecklist(updatedOptions));

				return updatedOptions;
			}
		} catch (e) {
			console.error(e);
		}
	};

export const updateProjectSuccessChecklistItem =
	({attribute, value}: {attribute: TProjectSuccessAPIAttribute; value: boolean}) =>
	async (dispatch: Dispatch<TAppDispatch>, getState: TRootState) => {
		try {
			const state = getState();

			const options: TProjectSuccessCheckOption[] =
				projectSuccessChecklistOptionsSelector(state);

			// only if previously not checked
			const selectedOption = options.find((op) => op.attribute === attribute);

			if (selectedOption && selectedOption?.checked === false) {
				let apiPayload: TProjectChecklistForm = {
					has_saved_companies: false,
					has_saved_people: false,
					has_shared_project: false,
					has_viewed_insights: false,
				};

				for (const option of options) {
					if (attribute === option.attribute) {
						apiPayload = {...apiPayload, [attribute]: value};
					} else {
						apiPayload = {...apiPayload, [option.attribute]: option.checked};
					}
				}

				dispatch(projectSuccessChecklistFormUpdate(apiPayload));
			}
		} catch (e) {
			console.error(e);
		}
	};

export const projectSuccessChecklistFormUpdate =
	(apiPayload: TProjectChecklistForm) =>
	async (dispatch: Dispatch<TAppDispatch>, getState: TRootState) => {
		try {
			const requestKey = `PATCH_PROJECT_SUCCESS_CHECKLIST`;

			if (requestKey in cancelTokens) {
				cancelTokens[requestKey].cancel("Operation canceled due to new request.");
			}

			cancelTokens[requestKey] = axios.CancelToken.source();

			const config = {
				cancelToken: cancelTokens[requestKey].token,
			};

			const state = getState();

			const project = projectSelector(state);

			if (project) {
				const response = await mapxProjectApi.updateProjectChecklistInfo(
					project.id,
					apiPayload,
					config,
				);

				if (response && successResponse(response, 200)) {
					const checklistResponse = response.data;

					const options: TProjectSuccessCheckOption[] =
						projectSuccessChecklistOptionsSelector(state);

					const updatedOptions = options.map((option: TProjectSuccessCheckOption) => {
						option.checked = checklistResponse[option.attribute];

						return option;
					});

					dispatch(updateProjectSuccessChecklist(updatedOptions));
				}
			}
		} catch (e) {
			console.error(e);
		}
	};
