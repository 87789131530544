import type {FC} from "react";
import {noop} from "lodash";
import classNames from "classnames";

import {FeatureBlockArrow} from "assets/icons";

import LocalItems from "./LocalItems";
import ProjectsList from "./ProjectsList";
import type {TSetPassModal} from "./types";
import styles from "./HeaderDropDown.module.scss";

const FeatureList: FC<TSetPassModal> = ({
	project,
	pathName,
	children,
	showItem,
	scrollList,
	deleteItem,
	onClick = noop,
	featureUserList,
	listClasses = "",
	topBorder = true,
	closeHandler = noop,
	handleTrashClick = noop,
	onHoverTooltip,
}) => (
	<div className={classNames(styles.list, {[listClasses]: listClasses})}>
		<FeatureBlockArrow className={styles.list__arrow} />

		{!!scrollList?.length && (
			<div className={styles.list__scroll}>
				<ProjectsList
					project={project}
					onClick={onClick}
					pathName={pathName}
					showItem={showItem}
					scrollList={scrollList}
					deleteItem={deleteItem}
					closeHandler={closeHandler}
					handleTrashClick={handleTrashClick}
					onHoverTooltip={onHoverTooltip}
				/>

				<div className={styles.list__scroll__children}>{children}</div>
			</div>
		)}

		<div
			className={classNames({
				[styles.list__nth]: !topBorder,
				[styles.list__gap]: !scrollList?.length,
				[styles.list__border]: topBorder || scrollList?.length,
			})}
		>
			<LocalItems featureUserList={featureUserList} />
		</div>
	</div>
);

export default FeatureList;
