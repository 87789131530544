import React from "react";

const JobTitleIcon = () => {
	return (
		<svg
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<mask
				id="mask0_957_50723"
				style={{maskType: "alpha"}}
				maskUnits="userSpaceOnUse"
				x="0"
				y="0"
				width="20"
				height="20"
			>
				<rect width="20" height="20" fill="#D9D9D9" />
			</mask>
			<g mask="url(#mask0_957_50723)">
				<path
					d="M4.5 17C4.0875 17 3.73437 16.8531 3.44062 16.5594C3.14687 16.2656 3 15.9125 3 15.5V4.5C3 4.0875 3.14687 3.73438 3.44062 3.44063C3.73437 3.14688 4.0875 3 4.5 3H8.0625C8.17361 2.55556 8.40993 2.19444 8.77146 1.91667C9.13299 1.63889 9.54271 1.5 10.0006 1.5C10.4585 1.5 10.8681 1.63889 11.2292 1.91667C11.5903 2.19444 11.8264 2.55556 11.9375 3H15.5C15.9125 3 16.2656 3.14688 16.5594 3.44063C16.8531 3.73438 17 4.0875 17 4.5V15.5C17 15.9125 16.8531 16.2656 16.5594 16.5594C16.2656 16.8531 15.9125 17 15.5 17H4.5ZM10 4C10.1444 4 10.2639 3.95278 10.3583 3.85833C10.4528 3.76389 10.5 3.64444 10.5 3.5C10.5 3.35556 10.4528 3.23611 10.3583 3.14167C10.2639 3.04722 10.1444 3 10 3C9.85556 3 9.73611 3.04722 9.64167 3.14167C9.54722 3.23611 9.5 3.35556 9.5 3.5C9.5 3.64444 9.54722 3.76389 9.64167 3.85833C9.73611 3.95278 9.85556 4 10 4ZM4.5 14.3958C5.27778 13.7569 6.13889 13.2812 7.08333 12.9688C8.02778 12.6562 9 12.5 10 12.5C11 12.5 11.9722 12.6528 12.9167 12.9583C13.8611 13.2639 14.7222 13.7431 15.5 14.3958V4.5H4.5V14.3958ZM10.005 11.5C10.835 11.5 11.5417 11.2067 12.125 10.62C12.7083 10.0333 13 9.325 13 8.495C13 7.665 12.7067 6.95833 12.12 6.375C11.5333 5.79167 10.825 5.5 9.995 5.5C9.165 5.5 8.45833 5.79333 7.875 6.38C7.29167 6.96667 7 7.675 7 8.505C7 9.335 7.29333 10.0417 7.88 10.625C8.46667 11.2083 9.175 11.5 10.005 11.5ZM5.52083 15.5H14.4792C13.8403 15.0139 13.1389 14.6424 12.375 14.3854C11.6111 14.1285 10.8194 14 10 14C9.18056 14 8.39236 14.1285 7.63542 14.3854C6.87847 14.6424 6.17361 15.0139 5.52083 15.5ZM10 10C9.58333 10 9.22917 9.85417 8.9375 9.5625C8.64583 9.27083 8.5 8.91667 8.5 8.5C8.5 8.08333 8.64583 7.72917 8.9375 7.4375C9.22917 7.14583 9.58333 7 10 7C10.4167 7 10.7708 7.14583 11.0625 7.4375C11.3542 7.72917 11.5 8.08333 11.5 8.5C11.5 8.91667 11.3542 9.27083 11.0625 9.5625C10.7708 9.85417 10.4167 10 10 10Z"
					fill="#0C5850"
				/>
			</g>
		</svg>
	);
};

export default JobTitleIcon;
