import {
	setCandidateCompanyPosition,
	setCandidateTargetListPosition,
	setFilterForCandidates,
	setFilterForCompanies,
	setMultipleFilterForCandidates,
	setMultipleFilterForCompany,
} from "./filterActions";
import {
	candidateCompanyPositionSelector,
	candidateLanguagesSelector,
	candidateTargetListPositionSelector,
	candidateTLCompaniesByPositionSelector,
	companiesByPositionSelector,
	companiesFilterKeyByPositionSelector,
	companyCountriesSelector,
	companyHQCountriesSelector,
	companyIndexesSelector,
	companyRevenuesSelector,
	companySizesSelector,
	companySpecialitiesFilterKeyByLogic,
	currentSenioritySelector,
	specialtiesByLogicSelector,
	tlCompaniesByTargetListPositionSelector,
	tlCompaniesFilterKeyByPositionSelector,
} from "./filterSelectors";

/**
 * Sometimes we have searched some company and picked it as filterOptionsApi
 * Now if we search the same company and save it as targetListApi list company,
 * that would remove filterOptionsApi without force add param
 *
 * @param {company | company[]} company
 * @returns {(function(*, *): void)|*}
 */

export const setCompaniesOnCandidateFilter = (company) => (dispatch, getState) => {
	const state = getState();

	const position = candidateCompanyPositionSelector(state);

	const companiesByPosition = companiesByPositionSelector(state);

	const companyIds = new Set(companiesByPosition.map((c) => c.id));

	const companiesFilterKey = companiesFilterKeyByPositionSelector(state, position);

	const companies = Array.isArray(company) ? company : [company];

	let newCompanies = [...companiesByPosition];

	companies.forEach((c) => {
		const exist = companyIds.has(c.id);

		if (exist) {
			newCompanies = newCompanies.filter((nc) => nc.id !== c.id);
		} else {
			newCompanies.unshift(c);
		}
	});

	dispatch(setMultipleFilterForCandidates({[companiesFilterKey]: newCompanies}));
};

export const addTargetListCompanyToCandidateTLCompanyFilter = (company) => (dispatch, getState) => {
	const state = getState();

	const position = candidateTargetListPositionSelector(state);

	const filterKey = tlCompaniesFilterKeyByPositionSelector(state, position);

	const companies = tlCompaniesByTargetListPositionSelector(state);

	const isAlreadyExist = companies.some((c) => c.id === company.id);

	dispatch(
		setMultipleFilterForCandidates({
			[filterKey]: isAlreadyExist
				? companies.filter((c) => c.id !== company.id)
				: [...companies, company],
		}),
	);
};

export const removeAllTargetListCompanies = () => (dispatch, getState) => {
	const state = getState();

	const position = candidateTargetListPositionSelector(state);

	const filterKey = tlCompaniesFilterKeyByPositionSelector(state, position);

	dispatch(
		setMultipleFilterForCandidates({
			[filterKey]: [],
		}),
	);
};

export const removeAllCompaniesFromCandidateFilter = () => (dispatch, getState) => {
	const state = getState();

	const allCompanies = companiesByPositionSelector(state);

	const companies = companiesByPositionSelector(state);

	const position = candidateCompanyPositionSelector(state);

	const filterKey = companiesFilterKeyByPositionSelector(state, position);

	dispatch(
		setMultipleFilterForCandidates({
			[filterKey]: allCompanies.filter((c) => !companies.some(({id}) => id === c.id)),
		}),
	);
};

export const moveAllCompaniesToCandidate = (position) => async (dispatch, getState) => {
	const state = getState();

	const prevPosition = candidateCompanyPositionSelector(state);

	const allCompaniesInFrom = companiesByPositionSelector(state, prevPosition);

	const allCompaniesInTo = companiesByPositionSelector(state, position);

	const filteredCompanies = companiesByPositionSelector(state);

	const fromCompaniesFilterKey = companiesFilterKeyByPositionSelector(state, prevPosition);

	const toCompaniesFilterKey = companiesFilterKeyByPositionSelector(state, position);

	if (filteredCompanies.length > 0) {
		dispatch(
			setMultipleFilterForCandidates({
				[fromCompaniesFilterKey]: allCompaniesInFrom.filter(
					(c) => !filteredCompanies.some(({id}) => id === c.id),
				),
				[toCompaniesFilterKey]: [...allCompaniesInTo, ...filteredCompanies],
			}),
		);
	}

	dispatch(setCandidateCompanyPosition(position));
};

export const moveAllTagetListCompaniesToCandidate = (position) => async (dispatch, getState) => {
	const state = getState();

	const prevPosition = candidateTargetListPositionSelector(state);

	const companiesFromFrom = candidateTLCompaniesByPositionSelector(state, prevPosition);

	const targetListCompanies = candidateTLCompaniesByPositionSelector(state, prevPosition);

	const fromCompaniesFilterKey = tlCompaniesFilterKeyByPositionSelector(state, prevPosition);

	const toCompaniesFilterKey = tlCompaniesFilterKeyByPositionSelector(state, position);

	if (targetListCompanies.length > 0) {
		dispatch(
			setMultipleFilterForCandidates({
				[fromCompaniesFilterKey]: [],
				[toCompaniesFilterKey]: [...companiesFromFrom],
			}),
		);
	}

	dispatch(setCandidateTargetListPosition(position));
};

export const moveCompaniesForCandidate =
	({from, to, ids}) =>
	async (dispatch, getState) => {
		const idsSet = new Set(ids);

		const state = getState();

		const fromCompaniesFilterKey = companiesFilterKeyByPositionSelector(state, from);

		const toCompaniesFilterKey = companiesFilterKeyByPositionSelector(state, to);

		const fromCompanies = companiesByPositionSelector(state, from);

		const toCompanies = companiesByPositionSelector(state, to);

		dispatch(
			setMultipleFilterForCandidates({
				[fromCompaniesFilterKey]: fromCompanies.filter((c) => !idsSet.has(c.id)),
				[toCompaniesFilterKey]: [
					...toCompanies.filter((c) => !idsSet.has(c.id)), // prevent duplication
					...fromCompanies.filter((c) => idsSet.has(c.id)),
				],
			}),
		);
	};

export const setSeniorityForCandidates =
	({id}) =>
	async (dispatch, getState) => {
		const state = getState();

		const seniority = currentSenioritySelector(state);

		const index = seniority.indexOf(id);

		const alreadySelected = index !== -1;

		const nextIds = alreadySelected
			? [...seniority.slice(0, index), ...seniority.slice(index + 1)]
			: [...seniority, id];

		dispatch(setFilterForCandidates({type: "current_seniority", values: nextIds}));
	};

export const setSpecialityForCompany =
	({id}, logic) =>
	async (dispatch, getState) => {
		const state = getState();

		const specialities = specialtiesByLogicSelector(state, logic);

		const index = specialities.indexOf(id);

		const alreadySelected = index !== -1;

		const nextSpecialities = alreadySelected
			? [...specialities.slice(0, index), ...specialities.slice(index + 1)]
			: [...specialities, id];

		const specialtyFilterKey = companySpecialitiesFilterKeyByLogic(state, logic);

		dispatch(setFilterForCompanies({type: specialtyFilterKey, values: nextSpecialities}));
	};

export const moveSpecialtiesForCompanies =
	({from, to, ids}) =>
	async (dispatch, getState) => {
		const idsSet = new Set(ids);

		const state = getState();

		const fromFilterKey = companySpecialitiesFilterKeyByLogic(state, from);

		const toFilterKey = companySpecialitiesFilterKeyByLogic(state, to);

		const fromIds = specialtiesByLogicSelector(state, from);

		const toIds = specialtiesByLogicSelector(state, to);

		dispatch(
			setMultipleFilterForCompany({
				[fromFilterKey]: fromIds.filter((id) => !idsSet.has(id)),
				[toFilterKey]: [
					...toIds.filter((id) => !idsSet.has(id)), // prevent duplication
					...fromIds.filter((id) => idsSet.has(id)),
				],
			}),
		);
	};

export const setCountryForCompany =
	({id, officeLocation = "ALL"}) =>
	async (dispatch, getState) => {
		const state = getState();

		let countries = [];

		if (officeLocation === "ALL") {
			countries = companyCountriesSelector(state);
		} else {
			countries = companyHQCountriesSelector(state);
		}

		const index = countries.indexOf(id);

		const alreadySelected = index !== -1;

		const nextCountries = alreadySelected
			? [...countries.slice(0, index), ...countries.slice(index + 1)]
			: [...countries, id];

		// dispatch(setFilterForCompanies({type: "countries", values: nextCountries}));

		if (officeLocation === "ALL") {
			dispatch(
				setMultipleFilterForCompany({
					countries: [...nextCountries],
					headquarters_countries: [],
				}),
			);
		} else {
			dispatch(
				setMultipleFilterForCompany({
					countries: [],
					headquarters_countries: [...nextCountries],
				}),
			);
		}
	};

export const setIndexForCompany =
	({id}) =>
	async (dispatch, getState) => {
		const state = getState();

		const indexes = companyIndexesSelector(state);

		const index = indexes.indexOf(id);

		const alreadySelected = index !== -1;

		const nextIndexes = alreadySelected
			? [...indexes.slice(0, index), ...indexes.slice(index + 1)]
			: [...indexes, id];

		dispatch(setFilterForCompanies({type: "indexes", values: nextIndexes}));
	};

export const setCompanyHeadcountRangeForCompany = (headcountItem) => async (dispatch, getState) => {
	const state = getState();

	const sizes = companySizesSelector(state);

	const sizeIndex = sizes.indexOf(headcountItem.id);

	const alreadySelected = sizeIndex !== -1;

	const nextIds = alreadySelected
		? [...sizes.slice(0, sizeIndex), ...sizes.slice(sizeIndex + 1)]
		: [...sizes, headcountItem.id];

	dispatch(setFilterForCompanies({type: "size", values: nextIds}));
};

export const setCompanyRevenueRangeForCompany = (revenueItem) => async (dispatch, getState) => {
	const state = getState();

	const revenues = companyRevenuesSelector(state);

	const revenueIndex = revenues.indexOf(revenueItem.id);

	const alreadySelected = revenueIndex !== -1;

	const nextIds = alreadySelected
		? [...revenues.slice(0, revenueIndex), ...revenues.slice(revenueIndex + 1)]
		: [...revenues, revenueItem.id];

	dispatch(setFilterForCompanies({type: "revenue", values: nextIds}));
};

export const setLanguagesForCandidates =
	({name}) =>
	async (dispatch, getState) => {
		const state = getState();

		const languages = candidateLanguagesSelector(state);

		const index = languages.indexOf(name);
		const alreadySelected = index !== -1;

		const nextItems = alreadySelected
			? [...languages.slice(0, index), ...languages.slice(index + 1)]
			: [...languages, name];

		dispatch(setFilterForCandidates({type: "languages", values: nextItems}));
	};
