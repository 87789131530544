import {ChangeEvent, useMemo} from "react";
import {useSelector} from "react-redux";
import css from "containers/Filters/CompanyFilters/IndexFilter/companyIndexFilter.module.scss";
import {SearchInput} from "mapx-components";
import {Checkbox, CheckboxSkeletonLoader, ResetFilters} from "components";
import useFetchListOptions from "hooks/useFetchListOptions";
import {getIndexes} from "store/mapx/search/searchAsyncActions";
import CheckboxList from "mapx-components/Inputs/CheckboxList";
import {
	TIndexFilteredData,
	TIndexFiltereState,
	TIndexSearchFilter,
} from "containers/Filters/CompanyFilters/IndexFilter/types";

const IndexSearchFilter = ({
	companyIndexes,
	handleOnChange,
	handleResetClick,
}: TIndexSearchFilter) => {
	const indexOptions = useSelector((state: TIndexFiltereState) => state.search.indexOptions);

	const {filteredData, onFilterChanged, loading} = useFetchListOptions({
		options: indexOptions,
		callbackMethod: getIndexes,
	});

	const sortedFilteredData = useMemo(() => {
		const set = new Set(companyIndexes);

		return filteredData.sort((a: TIndexFilteredData, b: TIndexFilteredData) => {
			const isASelected = set.has(a.id);

			const isBSelected = set.has(b.id);

			if (isASelected) {
				return -1;
			}

			if (isBSelected) {
				return 1;
			}

			return 0;
		});
	}, [filteredData, companyIndexes]);

	return (
		<div>
			<SearchInput
				onChange={(e: ChangeEvent) => onFilterChanged(e)}
				placeholder="Search for an Index"
				type="text"
				errorText=""
				errorClass=""
				isLoading={loading}
			/>

			<ResetFilters
				parentStyle={{color: "#5A5A5A", marginRight: 19}}
				onClick={handleResetClick}
				displayIcon={true}
			>
				Clear Selection
			</ResetFilters>

			<CheckboxList>
				{sortedFilteredData.map((item: TIndexFilteredData) => (
					<Checkbox
						borderColor="#0C5850"
						containerClass={css.checkboxContainer}
						isChecked={companyIndexes.includes(item.id)}
						key={item.id}
						label={item.name}
						onChange={() => handleOnChange(item.id)}
						value={item.name}
					/>
				))}
			</CheckboxList>

			{loading && <CheckboxSkeletonLoader />}
		</div>
	);
};

export default IndexSearchFilter;
