import type {FC} from "react";
import {useMemo} from "react";
import {noop} from "lodash";
import {Tooltip} from "react-tooltip";
import classNames from "classnames";
import {differenceInMinutes} from "date-fns";

import {Button} from "components";
import {useAppSelector} from "hooks";
import {userLicenseTypeNameSelector} from "store/mapx/user/userSelectors";

import {renderMintConvertValue} from "./utils";
import type {TInfoTooltipProps} from "./types";
import styles from "../HomeStyles.module.scss";

//@TODO: MAKE THIS COMPONENT FOR GLOBAL USE to use on credit information being shown

const InfoTooltip: FC<TInfoTooltipProps> = ({
	id,
	creditLeft,
	nextRefresh,
	creditLeftHandler = noop,
	nextRefreshHandler = noop,
	title = "Credits",
	tooltipFor = "other",
}) => {
	const userLicenseTypeName = useAppSelector(userLicenseTypeNameSelector);

	const nextRefreshCount = renderMintConvertValue(
		Math.abs(differenceInMinutes(new Date(nextRefresh as string), new Date())),
	);

	const isCreditsExist = !!creditLeft && creditLeft !== "0";

	const isNextRefreshCount = !!nextRefresh && nextRefresh !== "0";

	const licenseType = useMemo(() => {
		if (userLicenseTypeName) {
			return userLicenseTypeName;
		} else {
			return "Pro";
		}
	}, [userLicenseTypeName]);

	const tooltipDescriptionForOther = useMemo(
		() => (
			<>
				{licenseType === "Starter" && (
					<div>
						Your<b className={styles.info_tooltip__description_dark}> Starter </b>
						package includes{" "}
						<b className={styles.info_tooltip__description_dark}>
							250 AI Search Credits
						</b>{" "}
						and refreshes weekly. You can have a maximum of 500 credits in your account
						on this plan.{" "}
						<span className={styles.info_tooltip__description_green}>Upgrade</span> for
						more credits today.
					</div>
				)}

				{licenseType === "Prospect" && (
					<div>
						Your<b className={styles.info_tooltip__description_dark}> Free Trial </b>
						includes{" "}
						<b className={styles.info_tooltip__description_dark}>
							5,000 AI Search Credits
						</b>
						. <span className={styles.info_tooltip__description_green}>Upgrade</span>{" "}
						for more credits today.
					</div>
				)}

				{licenseType === "Pro" && (
					<>
						As an
						<span className={styles.info_tooltip__description_dark}>
							{" "}
							Enterprise user,{" "}
						</span>
						you will be granted{" "}
						<span
							className={styles.info_tooltip__description_green}
							style={{textDecoration: "none"}}
						>
							monthly 5000 Credits{" "}
						</span>
						for your AI Web Search.
					</>
				)}
			</>
		),
		[licenseType],
	);

	const tooltipDescriptionForAPR = useMemo(
		() => (
			<>
				You need more credits to run this search.{" "}
				<span className={styles.info_tooltip__description_green}>Upgrade</span> for more
				credits today.
			</>
		),
		[],
	);

	return (
		<Tooltip clickable place="right" id={id} delayShow={100} className={styles.info_tooltip}>
			<p className={styles.info_tooltip_title}>{title}</p>

			<span className={styles.info_tooltip__description}>
				{tooltipFor === "other" ? tooltipDescriptionForOther : tooltipDescriptionForAPR}
			</span>

			{licenseType !== "Pro" && (
				<div className={styles.info_tooltip__buttons}>
					{isCreditsExist && (
						<Button
							defaultBtnStyle
							className={classNames(styles.info_tooltip__buttons_left, {
								[styles.info_tooltip__buttons_left_one]: !nextRefresh,
							})}
							onClick={creditLeftHandler}
						>
							Credits left: {creditLeft}
						</Button>
					)}
					{isNextRefreshCount && (
						<Button
							defaultBtnStyle
							className={classNames(styles.info_tooltip__buttons_right, {
								[styles.info_tooltip__buttons_right_once]: !creditLeft,
							})}
							onClick={nextRefreshHandler}
						>
							Next refresh in: {nextRefreshCount}
						</Button>
					)}
				</div>
			)}
		</Tooltip>
	);
};

export default InfoTooltip;
