import {useEffect} from "react";
import {useLocation} from "react-router-dom";
import ReactGA from "react-ga4";
import {hotjar} from "react-hotjar";
import {Mixpanel} from "helpers/mixpanel";

const TRACKING_ID = process.env.REACT_APP_GTAG_ID;
const HOTJAR_TRACKING_ID = process.env.REACT_APP_HOTJAR_ID;
const HOTJAR_VERSION = process.env.REACT_APP_HOTJAR_VERSION || 6;

const initialiseAnalytics = () => {
	if (TRACKING_ID) {
		ReactGA.initialize(TRACKING_ID);
	}

	if (HOTJAR_TRACKING_ID) {
		hotjar.initialize(HOTJAR_TRACKING_ID, HOTJAR_VERSION);
	}

	Mixpanel.init();
};

const usePageTrackingHook = () => {
	const location = useLocation();
	// const location = useLocation();
	// const [initialized, setInitialized] = useState(false);

	useEffect(() => {
		initialiseAnalytics();
		// setInitialized(true);
	}, []);

	useEffect(() => {
		if (location.pathname) {
			const _hsq = (window._hsq = window._hsq || []);
			if (_hsq) {
				_hsq.push(["setPath", location.pathname]);
				_hsq.push(["trackPageView"]);
			}
		}
	}, [location.pathname]);

	// useEffect(() => {
	// 	if (initialized && TRACKING_ID) {
	// 		ReactGA?.pageview(location.pathname + location.search);
	// 	}
	// }, [initialized, location]);
};

export default usePageTrackingHook;
