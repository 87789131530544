import {useMemo} from "react";

import type {FC, MouseEvent} from "react";

import {Loader} from "components";
import {ButtonsRow} from "mapx-components";
import colors from "styles/themes.module.scss";
import {EMPTY_COMPANY_LOGO} from "mapx-constants";
import {useAppDispatch, useAppSelector, useOpenLink} from "hooks";
import {
	targetListCompaniesSelector,
	isCompanyBeingAddedToTargetListSelector,
	isCompanyBeingRemovedFromTargetListSelector,
} from "store/mapx/target-list/targetListSelectors";
import {
	addCompanyToTargetList,
	removeCompanyFromTargetList,
} from "store/mapx/target-list/targetListAsyncActions";
import {Monetization, PersonFilled, MinusSquareFilled, PlusSquare} from "assets/icons";
import {formatRevenueNumber, formatSizeBand, truncateSentenceEnd} from "helpers/string";

import type {TCompanyTooltipProps} from "./types";
import styles from "./CompanyTooltip.module.scss";

const CompanyTooltip: FC<TCompanyTooltipProps> = ({company, isTooltipSelect = true}) => {
	const openLink = useOpenLink();
	const dispatch = useAppDispatch();
	const targetListCompanies = useAppSelector(targetListCompaniesSelector);

	const inTargetList = useMemo(
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		() => new Set(targetListCompanies.map((company) => company.id)),
		[targetListCompanies],
	);

	const isBeingAdded = useAppSelector((state) =>
		isCompanyBeingAddedToTargetListSelector(state, company.id),
	);

	const isBeingRemoved = useAppSelector((state) =>
		isCompanyBeingRemovedFromTargetListSelector(state, company.id),
	);

	const handleItemClick = (event: MouseEvent, companyId: string) => {
		const link = `/company/${companyId}`;
		openLink(event, link);
	};

	const handleAddClick = (companyId: string) => {
		dispatch(addCompanyToTargetList(companyId));
	};

	const handleRemoveClick = (companyId: string) => {
		dispatch(removeCompanyFromTargetList(companyId));
	};

	return (
		<div className={styles.tooltipContent}>
			<div className={styles.companyMainDetails}>
				<div className={styles.left} onClick={(e) => handleItemClick(e, company.id)}>
					<img
						alt={company.name}
						className={styles.logo}
						loading="lazy"
						src={company.logo_url || EMPTY_COMPANY_LOGO}
						onError={(e) => {
							(e.target as HTMLImageElement).src = EMPTY_COMPANY_LOGO;
						}}
					/>
				</div>

				<div className={styles.right}>
					<div
						className={styles.name}
						onClick={(event) => handleItemClick(event, company.id)}
					>
						{company.name}
					</div>
					<div className={styles.industry}>
						{company.headquarters}
						{company?.headquarters_country &&
							(company?.headquarters && ", ") + company.headquarters_country?.name}
					</div>
				</div>

				{isTooltipSelect && (
					<div className={styles.rightest}>
						{isBeingAdded || isBeingRemoved ? (
							<Loader
								width={24}
								height={24}
								type="TailSpin"
								color={colors.loaderDotColor}
							/>
						) : (
							<>
								{inTargetList.has(company.id) ? (
									<div
										className={styles.remove}
										onClick={() => handleRemoveClick(company.id)}
									>
										<MinusSquareFilled />
									</div>
								) : (
									<div
										className={styles.add}
										onClick={() => handleAddClick(company.id)}
									>
										<PlusSquare />
									</div>
								)}
							</>
						)}
					</div>
				)}
			</div>

			{company.industry && (
				<div className={styles.industry}>
					<ButtonsRow
						label=""
						titleClass={styles.titleClass}
						className={styles.specialitiesButtons}
						items={[company.industry]}
					/>
				</div>
			)}

			<div className={styles.description}>
				{truncateSentenceEnd(company.description, 120, 220)}
			</div>

			{company.specialties && (
				<div className={styles.specialities}>
					<ButtonsRow
						titleClass={styles.titleClass}
						className={styles.specialitiesButtons}
						items={company.specialties || []}
					/>
				</div>
			)}

			<div className={styles.footer}>
				{company.size != null && (
					<div className={styles.stat}>
						<PersonFilled />
						{formatSizeBand(company.size)}
					</div>
				)}
				{company.revenue != null && (
					<div className={styles.stat}>
						<Monetization />
						{formatRevenueNumber(company.revenue)}
					</div>
				)}
			</div>
		</div>
	);
};

export default CompanyTooltip;
