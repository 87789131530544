import classNames from "classnames";

import css from "./tabSelect.module.scss";
import type {TLabelContainer, TTabSelect} from "./types";

const TabSelect = ({options, selected, onTabChange, ...rest}: TTabSelect) => {
	return (
		<div className={css.tabs} {...rest}>
			{options.map((o) => {
				return (
					<div
						className={classNames(css.tabs__tab, {
							[css.tabs__tab__selected]: selected && selected === o.value,
						})}
						key={o.value}
						onClick={() => onTabChange(o.value)}
					>
						{o.label}
					</div>
				);
			})}
		</div>
	);
};

const LabelContainer = ({children, label, ...rest}: TLabelContainer) => (
	<div className={css.container} {...rest}>
		{label && <div className={css.container__label}>{label}</div>}
		{children}
	</div>
);

TabSelect.LabelContainer = LabelContainer;

export default TabSelect;
