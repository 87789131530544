import {type FC, type SyntheticEvent, useEffect, useRef} from "react";

import {Button} from "components";
import {useLockBodyScroll, useOutsideClick} from "hooks";
import SelectWithAction from "components/Inputs/SelectWithAction";
import {TUserDataProp} from "mapx-pages/Organizations/OrganizationList/types";

import type {TTransferOwnershipModalProps} from "./types";
import styles from "./TransferOwnershipModal.module.scss";

const TransferOwnershipModal: FC<TTransferOwnershipModalProps> = ({
	isOpen,
	options,
	loading,
	disabled,
	onSubmit,
	setIsOpen,
	selectedUser,
	transferName,
	setSelectedUser,
}) => {
	const ref = useRef<HTMLDivElement | null>(null);

	const transformOptionsData = options?.map((item: TUserDataProp) => {
		return {
			label: `${item?.first_name} ${item?.last_name}`,
			value: item?.id,
		};
	});

	const closeModalHandler = () => {
		setIsOpen(!isOpen);
	};

	const submitHandler = (e: SyntheticEvent) => {
		e.preventDefault();
		onSubmit?.(e);
	};

	useEffect(() => {
		if (!loading && isOpen && selectedUser?.label) {
			closeModalHandler();
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loading, options]);

	useLockBodyScroll(isOpen);
	useOutsideClick(ref, closeModalHandler);

	return isOpen ? (
		<div className={styles.container}>
			<div className={styles.wrapper} ref={ref}>
				<p className={styles.wrapper__title}>Move All Project Ownership to</p>
				<form className={styles.wrapper__content} onSubmit={submitHandler}>
					<div className={styles.wrapper__content_item}>
						<p className={styles.wrapper__content_item_title}>From</p>

						<p className={styles.wrapper__content_item__transfer_name}>
							{transferName}
						</p>
					</div>
					<div className={styles.wrapper__content_item}>
						<p className={styles.wrapper__content_item_title}>To</p>
						<SelectWithAction
							placeholder="To"
							selected={selectedUser}
							setSelected={setSelectedUser}
							options={transformOptionsData}
						/>
					</div>
					<Button
						type="submit"
						loading={loading}
						className={styles.wrapper__content_button}
						disabled={Boolean(!selectedUser?.label && !selectedUser?.value) || disabled}
					>
						Change Ownership
					</Button>
				</form>
			</div>
		</div>
	) : null;
};

export default TransferOwnershipModal;
