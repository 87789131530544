import React, {useCallback} from "react";
import {Tag} from "components";
import {
	LocationTagsProps,
	TLocationPosition,
} from "containers/Filters/PeopleFilters/LocationFilter/types";
import {TFilterCountry} from "api/filterOptionsApi/CountryApi/types";
import FilterTags from "../../../FilterTags";

function LocationFilterTags({
	label,
	position,
	selectedCountries,
	handleTagClick,
	handleMoveLocationTag,
}: LocationTagsProps) {
	const handleTagDragStart = useCallback(
		(e: React.DragEvent<HTMLSpanElement>, id: number, region_id: number | undefined) => {
			e.dataTransfer.setData("id", id.toString());
			e.dataTransfer.setData("from", position);
			if (region_id) {
				e.dataTransfer.setData("region_id", String(region_id));
			}
		},
		[position],
	);

	const selectedCountryIds = Array.from(new Set(selectedCountries?.flatMap((c) => c?.id)));

	const handleTagDrop = (e: React.DragEvent<HTMLSpanElement>, toPosition: TLocationPosition) => {
		const country_id = parseInt(e.dataTransfer.getData("id"), 10);
		const fromPosition = e.dataTransfer.getData("from") as TLocationPosition;
		const region_id = e.dataTransfer.getData("region_id");

		handleMoveLocationTag &&
			handleMoveLocationTag(country_id, region_id, fromPosition, toPosition);
	};

	return (
		<div>
			{selectedCountries && (
				<FilterTags
					onDrop={(e) => handleTagDrop(e, position)}
					label={label}
					count={selectedCountries.length}
					tags={selectedCountries.map((item: TFilterCountry, index: number) => (
						<Tag
							key={item.id + index}
							onClick={() =>
								handleTagClick &&
								handleTagClick(
									item.id,
									position,
									selectedCountryIds ?? [],
									item?.region_id,
								)
							}
							draggable
							onDragStart={(e) => handleTagDragStart(e, item.id, item?.region_id)}
							onDragOver={(e) => e.preventDefault()}
						>
							{item.name}
						</Tag>
					))}
				/>
			)}
		</div>
	);
}

export default React.memo(LocationFilterTags);
