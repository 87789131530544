import {type FC, useCallback, useState} from "react";
import classNames from "classnames";
import {groupBy} from "lodash";
import useDebouncedEffect from "use-debounced-effect";

import {ImageIcon} from "assets/icons";
import colors from "styles/themes.module.scss";
import {EMPTY_COMPANY_LOGO} from "mapx-constants";
import GeneralButton from "mapx-components/Buttons/GeneralButton";
import {ModalContent, ModalHeader, CompanyInfoTooltip} from "components";
import {tooltipCompaniesSelector} from "store/mapx/company/companySelectors";
import {updateTooltipCompaniesList} from "store/mapx/company/companyAsyncActions";
import {downloadMarketMapsPng} from "store/mapx/market-map/marketMapAsyncFunctions";
import {useAppDispatch, useAppSelector, useLockBodyScroll, useModalHook} from "hooks";
import {downloadingMarketMapPngSelector} from "store/mapx/market-map/marketMapSelectors";

import type {TMarketMapModal} from "./types";
import styles from "./marketMapModal.module.scss";

const MarketMapModal: FC<TMarketMapModal> = ({
	modalProps,
	setModalDisplay,
	modalDisplay = true,
}) => {
	const dispatch = useAppDispatch();

	const {modalIsOpen, customStyles, Modal} = useModalHook(
		{content: {width: "90%", borderRadius: "8px", maxWidth: "1200px"}},
		modalDisplay,
		colors.mainThemeColor,
	);

	const [companyId, setCompanyId] = useState<string | null>(null);
	const [isMouseEnter, setIsMouseEnter] = useState<boolean>(false);

	const tooltipCompanies = useAppSelector(tooltipCompaniesSelector);
	const downloading = useAppSelector(downloadingMarketMapPngSelector);

	const companyToWatch = companyId ? tooltipCompanies[companyId] : null;

	const handlePngClick = useCallback(() => {
		if (modalProps) {
			dispatch(downloadMarketMapsPng(modalProps.id, modalProps.name));
		}
	}, [dispatch, modalProps]);

	useDebouncedEffect(
		() => {
			if (companyId && isMouseEnter) {
				dispatch(updateTooltipCompaniesList(companyId));
			}
		},
		1000,
		[companyId, isMouseEnter],
	);

	const mouseEnterHandler = (id: string) => {
		setIsMouseEnter(true);
		setCompanyId(id);
	};

	useLockBodyScroll(modalIsOpen);

	if (!modalDisplay || !modalProps) return null;

	const groupedCompanies = groupBy(
		modalProps.companies,
		(company) => company.industry?.name || "Other",
	);

	const groupedCompaniesArray = Object.entries(groupedCompanies)
		.map(([industryName, companies]) => ({industryName, companies}))
		.sort((a, b) => b.companies.length - a.companies.length);

	return (
		<>
			<Modal
				title={modalProps.name}
				style={customStyles}
				isOpen={modalIsOpen}
				contentLabel="Modal"
				onRequestClose={() => setModalDisplay(false)}
			>
				<ModalHeader
					padding="24px 24px 0 24px"
					title={modalProps.name}
					onClose={() => setModalDisplay(false)}
				/>
				<ModalContent padding="8px 24px 0 24px">
					<div className={styles.descriptionWrapper}>
						<div className={styles.description}>{modalProps.description}</div>
						{modalProps.companies.length <= 50 && (
							<GeneralButton
								customClass={styles.button}
								handleClick={handlePngClick}
								icon={<ImageIcon width={20} height={20} />}
								loadingSave={downloading}
								title="Save as PNG"
							/>
						)}
					</div>
				</ModalContent>
				<ModalContent padding="0 24px 24px 24px" className={styles.content}>
					<div className={styles.grids}>
						{groupedCompaniesArray.map(({industryName, companies}) => (
							<div
								key={industryName}
								className={classNames(styles.industry, {
									[styles.singleGrid]: groupedCompaniesArray.length === 1,
								})}
							>
								<div className={styles.industryName}>{industryName}</div>
								<div className={styles.companies}>
									{companies.map((company) => (
										<span
											key={String(company.id)}
											className={styles.company}
											data-tooltip-delay-show={1000}
											data-tooltip-id={String(modalProps.id)}
											onMouseOut={() => setIsMouseEnter(false)}
											onMouseEnter={() =>
												mouseEnterHandler(String(company?.id))
											}
											onClick={() =>
												window.open(`/company/${company.id}`, "_blank")
											}
										>
											<div
												className={styles.company__skeleton}
												onMouseOut={() => setIsMouseEnter(false)}
												onMouseEnter={() =>
													mouseEnterHandler(String(company?.id))
												}
											/>
											<img
												loading="lazy"
												alt={company.name}
												data-tooltip-id={String(modalProps.id)}
												className={styles.companyImage}
												src={company?.logo_url || EMPTY_COMPANY_LOGO}
												onError={(e) => {
													(e.target as HTMLImageElement).src =
														EMPTY_COMPANY_LOGO;
												}}
											/>
											<div
												data-tooltip-id={String(modalProps.id)}
												className={styles.companyName}
											>
												{company.name}
											</div>
										</span>
									))}
								</div>
							</div>
						))}
					</div>
				</ModalContent>
			</Modal>

			<CompanyInfoTooltip
				company={companyToWatch}
				float={false}
				id={String(modalProps.id)}
				isInCompanyPage
			/>
		</>
	);
};

export default MarketMapModal;
