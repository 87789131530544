export const JOB_TITLE_SUGGESTIONS_LOADING = "JOB_TITLE_SUGGESTIONS_LOADING"; // replaces previous filterOptionsApi as whole
export const SET_PR_FILTERS = "SET_PR_FILTERS"; // replaces previous filterOptionsApi as whole
export const SET_PR_FILTER = "SET_PR_FILTER"; // replaces single filterOptionsApi, keeps others unchanged
export const CLEAR_PR_FILTER = "CLEAR_PR_FILTER"; // replaces single filterOptionsApi, keeps others unchanged

export const SET_AP_MULTIPLE_FILTERS = "SET_AP_MULTIPLE_FILTERS"; // replaces previous filterOptionsApi as whole

export const SET_MULTIPLE_FILTER_FOR_AP_CANDIDATE = "SET_MULTIPLE_FILTER_FOR_AP_CANDIDATE";
export const SET_AP_FILTER = "SET_AP_FILTER"; // replaces single filterOptionsApi, keeps others unchanged

export const CLEAR_AP_FILTER = "CLEAR_AP_FILTER"; // replaces single filterOptionsApi, keeps others unchanged

export const SET_AP_JOB_FUNCTION_FILTER_CONFIG = "SET_AP_JOB_FUNCTION_FILTER_CONFIG";

export const SET_AP_KEYWORD_FILTER_CONFIG = "SET_AP_KEYWORD_FILTER_CONFIG";
export const SET_AP_HEADCOUNT_FILTER_CONFIG = "SET_AP_HEADCOUNT_FILTER_CONFIG";
export const SET_AP_REVENUE_FILTER_CONFIG = "SET_AP_REVENUE_FILTER_CONFIG";
export const SET_AP_COMPANY_TYPE_FILTER_CONFIG = "SET_AP_COMPANY_TYPE_FILTER_CONFIG";

export const SET_AP_COMPANY_POSITION = "SET_AP_COMPANY_POSITION";

export const SET_AP_COMPANY_TL_POSITION = "SET_AP_COMPANY_TL_POSITION";

export const SET_AP_ACTIVE_RESULT = "SET_AP_ACTIVE_RESULT";
export const SET_AP_MORE_RESULT = "SET_AP_MORE_RESULT";
export const CLEAR_AP_ACTIVE_RESULT = "CLEAR_AP_ACTIVE_RESULT";
export const PROJECT_RESULTS_TAB_ACTIVE_CONTENT = "PROJECT_RESULTS_TAB_ACTIVE_CONTENT";
export const DISPLAY_REFRESH_AP_RESULT = "DISPLAY_REFRESH_AP_RESULT";
export const SET_REJECTED_PROFILES_RESULT = "SET_REJECTED_PROFILES_RESULT";
export const SET_MORE_REJECTED_PROFILES_RESULT = "SET_MORE_REJECTED_PROFILES_RESULT";
export const SET_INPUT_PROFILES_RESULT = "SET_INPUT_PROFILES_RESULT";
export const CLEAR_AP_WORKFLOW_STATE = "CLEAR_AP_WORKFLOW_STATE";

export const DISPLAY_AP_RESULTS = "DISPLAY_AP_RESULTS";

export const REMOVE_CURRENT_SEARCH_REQUEST_ON_PW = "REMOVE_CURRENT_SEARCH_REQUEST_ON_PW";
export const CLEAR_ACTIVE_SEARCH_REQUEST = "CLEAR_ACTIVE_SEARCH_REQUEST";
export const SEARCH_REQUEST_DETAILS_LOADING = "SEARCH_REQUEST_DETAILS_LOADING";
export const SET_ACTIVE_SEARCH_REQUEST = "SET_ACTIVE_SEARCH_REQUEST";
export const ADD_SEARCH_REQUEST_TO_THE_LIST = "ADD_SEARCH_REQUEST_TO_THE_LIST";
export const SET_ALL_SEARCH_REQUESTS = "SET_ALL_SEARCH_REQUESTS";
