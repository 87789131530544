import {
	AcceptInvitation,
	ForgotPassword,
	Login,
	NotFound,
	Profile,
	Register,
	ResetPassword,
	VerifyEmail,
} from "containers";
import BlockedRegister from "containers/Auth/BlockedRegister";
import {
	CandidateDetails,
	CompanyDetails,
	Error,
	FlaggedCandidatesPage,
	Home,
	LearnToRank,
	MarketMap,
	Onboarding,
	Organisation,
	Organizations,
	ProjectEntry,
	ProjectsV2,
} from "mapx-pages";
import type {TRouteProps} from "types";
import CandidateSearch from "mapx-pages/CandidateSearch";
import CompanySearch from "mapx-pages/CompanySearch";

const homePage = {
	path: ["/", "/home", "/home/watchDemo", "/home/:modal"],
	component: Home,
	exact: true,
	private: true,
};

const routes: (isShowOnBoarding: boolean) => TRouteProps[] = (isShowOnBoarding: boolean) => {
	return [
		{
			path: "/login",
			pathname: "login",
			component: Login,
			exact: true,
		},
		{
			path: "/forgot-password",
			component: ForgotPassword,
			exact: true,
		},
		{
			path: "/reset-password",
			component: ResetPassword,
			exact: true,
		},
		{
			path: "/accept-invitation",
			component: AcceptInvitation,
			exact: true,
		},
		{
			path: "/register/blocked",
			component: BlockedRegister,
			exact: true,
		},
		{
			path: "/register/verify-email",
			component: VerifyEmail,
			exact: true,
		},
		{
			path: "/register",
			component: Register,
			exact: true,
		},

		// Pages

		homePage,

		{
			path: "/profile",
			component: Profile,
			exact: true,
			private: true,
		},
		isShowOnBoarding
			? {
					path: ["/onboarding/home", "/onboarding/projects"],
					component: Onboarding,
					exact: true,
					private: true,
			  }
			: homePage,

		{
			path: "/projects",
			component: ProjectsV2,
			exact: true,
			private: true,
		},
		{
			path: [
				"/project/:id",
				"/project/:id/:candidateId",
				"/project/:id/candidate/:candidateId",
			],
			component: ProjectEntry,
			exact: true,
			private: true,
		},

		{
			path: ["/organisation-settings", "/organisation-settings/:modal"],
			component: Organisation,
			exact: true,
			private: true,
		},

		{
			path: ["/companies", "/market-maps"],
			component: CompanySearch,
			exact: true,
			private: true,
		},

		{
			path: [
				"/candidates-filter-search",
				"/candidates-filter-search/:candidateId",

				"/candidates-text-search",
				"/candidates-text-search/:candidateId",

				"/candidates-profile-search",
				"/candidates-profile-search/:candidateId",

				"/candidates-ai-web-search",
				"/candidates-ai-web-search/:candidateId",
			],
			component: CandidateSearch,
			exact: true,
			private: true,
		},

		{
			path: "/candidate/:id",
			component: CandidateDetails,
			exact: true,
			private: true,
		},

		{
			path: ["/company/:id", "/company/:id/:candidateId"],
			component: CompanyDetails,
			exact: true,
			private: true,
		},

		// {
		// 	path: "/market-maps",
		// 	component: MarketMaps,
		// 	exact: true,
		// 	private: true,
		// },

		{
			path: "/market-maps/:id/:token",
			component: MarketMap,
			exact: true,
			private: false,
		},
		{
			path: ["/organisations", "/organisations/invited"],
			component: Organizations,
			exact: true,
			private: true,
		},
		{
			path: "/learn-to-rank",
			component: LearnToRank,
			exact: true,
			private: true,
		},

		{
			path: ["/flagged-candidates", "/flagged-candidates/candidate/:candidateId"],
			component: FlaggedCandidatesPage,
			exact: true,
			private: true,
		},

		// Not found
		{
			path: ["/403", "/404", "/503", "/500", "/invitation-invalid", "/unknown-error"],
			component: Error,
			exact: true,
		},

		{
			path: "*",
			component: NotFound,
		},
	];
};

export default routes;
