import React, {ChangeEvent, useCallback, useMemo} from "react";
import useFetchListOptions from "hooks/useFetchListOptions";
import {SearchInput} from "mapx-components";

import styles from "./styles.module.scss";
import {useSelector} from "react-redux";
import {
	prCandidateSelector,
	prJobTitleSuggestionLoadingSelector,
} from "store/mapx/additional-profiles/additionalProfilesSelectors";
import {Checkbox, PersonItem, ResetFilters} from "components";
import {setPRFilter} from "store/mapx/additional-profiles/additionalProfilesActions";
import useCheckboxListSortHook from "hooks/useCheckboxListSortHook";
import CheckboxList from "mapx-components/Inputs/CheckboxList";
import {useAppDispatch} from "hooks";
import {SICandidate} from "api/candidateApi/types";
import {setCandidateForPRFilters} from "store/mapx/additional-profiles/profileRequestConfigurator";
import {TAPCandidatesFilterProps} from "../types";
import {getJobTitlesSuggestionForProfileRequest} from "store/mapx/search/jobTitlesAsyncActions";
import {noop} from "lodash";

const InputProfiles = ({options}: TAPCandidatesFilterProps) => {
	const {filteredData, onFilterChanged} = useFetchListOptions({
		options,
		childFilterKey: "company",
		nestedChildFilterKey: "",
		callbackMethod: () => noop(),
	});
	const selectedCandidate = useSelector(prCandidateSelector);

	const dispatch = useAppDispatch();

	const sortedFilteredData = useCheckboxListSortHook(filteredData);

	const isPRSaving = useSelector(prJobTitleSuggestionLoadingSelector);

	const handleChange = useCallback(
		async (id: number) => {
			dispatch(setCandidateForPRFilters(id));

			dispatch(getJobTitlesSuggestionForProfileRequest(id, false));
		},
		[dispatch],
	);

	const handleResetClick = useCallback(() => {
		dispatch(setPRFilter({type: "candidate_id", values: null}));
		dispatch(setPRFilter({type: "selected_job_titles", values: []}));
	}, [dispatch]);

	const displayReset = useMemo(() => {
		return selectedCandidate !== null;
	}, [selectedCandidate]);

	const displayOverlay = useMemo(() => {
		return isPRSaving;
	}, [isPRSaving]);

	return (
		<div>
			<SearchInput
				name="people"
				type="text"
				placeholder="Search for a Person or a Company"
				className={styles.searchBox}
				onChange={(e: ChangeEvent<HTMLInputElement>) => onFilterChanged(e)}
				errorText={undefined}
				errorClass={undefined}
				margin="0 auto"
			/>

			{/*<ResetFilters onClick={handleResetClick} />*/}

			<div className={styles.resetFilterContainer}>
				<div className={styles.resetArea}>
					{displayReset && (
						<ResetFilters
							parentStyle={{marginBottom: 0, color: "#5A5A5A", marginRight: 19}}
							onClick={handleResetClick}
							displayIcon={true}
						>
							Clear Selection
						</ResetFilters>
					)}
				</div>
			</div>

			<CheckboxList
				style={{paddingRight: 0}}
				displayOverlay={displayOverlay}
				overlayText={"Calculating Job Titles"}
			>
				{sortedFilteredData.map((item: SICandidate) => (
					<Checkbox
						borderColor="#0C5850"
						containerClass={styles.personContainer}
						isChecked={selectedCandidate === item.id}
						key={item.id}
						label={<PersonItem candidate={item} key={item.id} />}
						onChange={() => handleChange(item.id)}
						value={item.name}
						disabled={isPRSaving}
					/>
				))}
			</CheckboxList>
		</div>
	);
};

export default InputProfiles;
