/** @format */

import {useEffect} from "react";

const useAuthRedirect = (user, history, redirectUrl) => {
	// const {pathname} = useLocation();

	useEffect(() => {
		if (user.email) {
			// const shouldRedirectOnboarding = user.license_type?.type?.name === "Starter" && pathname.includes("register");
			const shouldRedirectOnboarding = false;

			history.push(shouldRedirectOnboarding ? "/onboarding/home" : redirectUrl);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [history, user, redirectUrl]);

	return true;
};

export default useAuthRedirect;
