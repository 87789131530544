import React, {useEffect, useMemo, useState} from "react";
import styles from "./loader.module.scss";
import {Loader, ProgressBar, SkeletonLoaderCardAsList} from "components";
import {BrainFunctionIcon, FilterAIResult, SearchAIResults} from "assets/icons";
import {TResultSkeletonLoaderProps} from "mapx-components/SearchRequestResults/ContentBlock/ResultSkeletonLoader/types";

const ResultSkeletonLoader = ({
	searchType,
	results,
	progress,
	hideProgressBar,
	uniqueUrlsCount,
	searchRequestStatus,
	onlyProgressBar = false,
	uniqueUrlsRejectedCount,
}: TResultSkeletonLoaderProps) => {
	const [progressStage, setProgressStage] = useState<number>(3);

	const progressPercentage: number = useMemo(() => {
		try {
			if (searchRequestStatus === "Completed") return 100;

			if (progress === null) return 0;

			return progress === 0 ? 0 : progress;
		} catch (e) {
			console.log(`Error parsing AP progress percentage`, e);

			return 100;
		}
	}, [progress, searchRequestStatus]);

	const isAIManualSearch = useMemo(() => searchType === "Additional Profiles", [searchType]);

	useEffect(() => {
		if (!isAIManualSearch && searchRequestStatus !== "Completed") {
			setProgressStage(1);

			setTimeout(() => {
				setProgressStage(2);
			}, 1000 * 10);
		} else if (!isAIManualSearch && searchRequestStatus === "Completed") {
			setProgressStage(2);

			setTimeout(() => {
				setProgressStage(3);
			}, 1000 * 2);
		}
	}, [isAIManualSearch, searchRequestStatus]);

	useEffect(() => {
		if (!isAIManualSearch) {
			if (parseInt(results) > 0 && searchRequestStatus !== "Completed") {
				setProgressStage(3);
			}
		}
	}, [isAIManualSearch, results, searchRequestStatus]);

	return (
		<div className={styles.container}>
			{!hideProgressBar && isAIManualSearch && (
				<div className={styles.progressBarSection}>
					{/*<span>{progressBarMessage}</span>*/}
					<div className={styles.progressInfo}>
						<div>Search Progress: {progressPercentage}%</div>
						<div>
							<span>
								Profiles found: <b>{uniqueUrlsCount}</b>
							</span>
							<span>
								Profiles filtered: <b>{uniqueUrlsRejectedCount}</b>
							</span>
							<span>
								Results: <b>{results}</b>
							</span>
						</div>
					</div>

					<ProgressBar background={`#0C5850`} value={progressPercentage} />
				</div>
			)}

			{!isAIManualSearch && (
				<div className={styles.interactiveProgressInfo}>
					{progressStage === 1 && (
						<div className={styles.textInstruction}>
							<BrainFunctionIcon />
							Deciding on search strategy...
						</div>
					)}

					{progressStage === 2 && (
						<div className={styles.textInstruction}>
							<SearchAIResults />
							Searching through 1,000s of possible matches...
						</div>
					)}

					{progressStage === 3 && (
						<div className={styles.textInstruction}>
							<FilterAIResult />
							Filtering results...
						</div>
					)}

					<div>
						<Loader color={"#a0dfbf"} />
					</div>
				</div>
			)}

			{!onlyProgressBar && (
				<div className={styles.skeletonCards}>
					<SkeletonLoaderCardAsList count={5} />
				</div>
			)}
		</div>
	);
};

export default ResultSkeletonLoader;
