export function roundToSignificantFigures(num: number): number {
	if (num === 0) {
		return 0;
	}

	const orderOfMagnitude = Math.floor(Math.log10(Math.abs(num)));
	const roundingFactor = Math.pow(10, orderOfMagnitude - 2);

	return Math.floor(num / roundingFactor) * roundingFactor;
}

export function toShortNotation(value: number): string {
	if (value < 1000) {
		return value.toString();
	} else if (value < 1000000) {
		return (Math.round(value / 100) / 10).toString() + "k";
	} else if (value < 1000000000) {
		return (Math.round(value / 100000) / 10).toString() + "m";
	} else {
		return (Math.round(value / 100000000) / 10).toString() + "b";
	}
}
