import {Dispatch} from "redux";
import {TAppDispatch, TRootState} from "types";
import {
	TCompanyHeadcountPosition,
	TCompanyHeadcountRangeItem,
} from "mapx-components/Filters/CompanyHeadcountSearchFilter/types";
import {setAPMultipleFilters} from "store/mapx/additional-profiles/additionalProfilesActions";
import {
	apCandidateCompanyHeadcountByPositionSelector,
	apCompanyHeadcountFilterKeyByPosition,
} from "store/mapx/additional-profiles/apCompanyHeadcountFilterSelectors";

export const setCompanyHeadcountForAPCandidate =
	({
		position,
		headcountItem,
	}: {
		position: TCompanyHeadcountPosition;
		headcountItem: TCompanyHeadcountRangeItem;
	}) =>
	async (dispatch: Dispatch<TAppDispatch>, getState: () => TRootState) => {
		const state = getState();

		const headCountFilterKey = apCompanyHeadcountFilterKeyByPosition(state, position);

		const idsOnState = apCandidateCompanyHeadcountByPositionSelector(state, position);

		const index = idsOnState.indexOf(headcountItem.id);

		const alreadySelected = index !== -1;

		const nextIds = alreadySelected
			? [...idsOnState.slice(0, index), ...idsOnState.slice(index + 1)]
			: [...idsOnState, headcountItem.id];

		dispatch(
			setAPMultipleFilters({
				[headCountFilterKey]: nextIds,
			}),
		);
	};

export const moveHeadcountForAPCandidate =
	({
		from,
		to,
		ids,
	}: {
		from: TCompanyHeadcountPosition;
		to: TCompanyHeadcountPosition;
		ids: string[];
	}) =>
	async (dispatch: Dispatch<TAppDispatch>, getState: () => TRootState) => {
		const idsSet = new Set(ids);

		const state = getState();

		const fromFilterKey = apCompanyHeadcountFilterKeyByPosition(state, from);

		const toFilterKey = apCompanyHeadcountFilterKeyByPosition(state, to);

		const fromIds = apCandidateCompanyHeadcountByPositionSelector(state, from);

		const toIds = apCandidateCompanyHeadcountByPositionSelector(state, to);

		dispatch(
			setAPMultipleFilters({
				[fromFilterKey]: fromIds.filter((id: string) => !idsSet.has(id)),
				[toFilterKey]: [
					...toIds.filter((id: string) => !idsSet.has(id)), // prevent duplication
					...fromIds.filter((id: string) => idsSet.has(id)),
				],
			}),
		);
	};
