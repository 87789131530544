import * as ReactLoader from "react-loader-spinner";

import type {TLoader} from "./types";

import theme from "styles/themes.module.scss";

const Loader = ({
	type = "ThreeDots",
	color = theme.mainThemeColor || "#0c5850",
	height = 50,
	width = 50,
	displayAtCenterOfPage = false,
	customStyle,
	isCenter = false,
	...rest
}: TLoader) => {
	const centerOfPage = {
		position: "absolute",
		top: "50%",
		right: "45%",
	};

	const inlineBlock = {
		display: "inline",
	};

	const flexBlock = {
		display: "flex",
		justifyContent: "center",
	};

	const itemCenter = isCenter ? flexBlock : "";

	const style = customStyle ? customStyle : displayAtCenterOfPage ? centerOfPage : inlineBlock;

	const LoaderSpinner = ReactLoader[type];

	return (
		<div data-testid="loader-element">
			<LoaderSpinner
				wrapperStyle={{...style, zIndex: "2", ...itemCenter}}
				color={color}
				height={height}
				width={width}
				{...rest}
			/>
		</div>
	);
};

export default Loader;
