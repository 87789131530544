import type {FC} from "react";
import {IconProps} from "assets/icons/types";

const AssessmentEditIcon: FC<IconProps> = ({color = "#C1B1F", height = 24, width = 24}) => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 16 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<mask
			id="mask0_1633_1347"
			style={{maskType: "alpha"}}
			maskUnits="userSpaceOnUse"
			x="0"
			y="0"
			width={width}
			height={height}
		>
			<rect width={width} height={height} fill={color} />
		</mask>
		<g mask="url(#mask0_1633_1347)">
			<path
				d="M3.5999 12.4001H4.4499L10.6999 6.15015L9.8499 5.30015L3.5999 11.5501V12.4001ZM2.3999 13.6001V11.0501L10.6999 2.75015C10.8221 2.62792 10.9546 2.53904 11.0972 2.48348C11.2398 2.42792 11.3898 2.40015 11.5472 2.40015C11.7046 2.40015 11.8555 2.42792 11.9999 2.48348C12.1443 2.53904 12.2777 2.62792 12.3999 2.75015L13.2499 3.60015C13.3721 3.72237 13.461 3.8557 13.5166 4.00015C13.5721 4.14459 13.5999 4.29204 13.5999 4.44248C13.5999 4.60304 13.572 4.75604 13.5162 4.90148C13.4605 5.04692 13.3717 5.17981 13.2499 5.30015L4.9499 13.6001H2.3999ZM10.2674 5.73265L9.8499 5.30015L10.6999 6.15015L10.2674 5.73265Z"
				fill={color}
			/>
		</g>
	</svg>
);

export default AssessmentEditIcon;
