import type {Dispatch} from "redux";

import mapXPaymentApi from "api/paymentApi";

import {
	getProductsInit,
	getProductsDone,
	getProductsFail,
	openCheckoutFail,
	openCheckoutInit,
	openCheckoutDone,
	openCustomerPortalInit,
	openCustomerPortalDone,
	openCustomerPortalFail,
} from "./paymentActions";

export const getProducts = () => async (dispatch: Dispatch) => {
	try {
		dispatch(getProductsInit());

		const {data} = await mapXPaymentApi.getProducts();

		dispatch(getProductsDone(data.products));
	} catch (e) {
		dispatch(getProductsFail(e));
	}
};

export const openCheckout = (priceID: string) => async (dispatch: Dispatch) => {
	try {
		dispatch(openCheckoutInit(priceID));
		const {data} = await mapXPaymentApi.checkoutUrl(priceID);
		dispatch(openCheckoutDone());
		window.open(data.url, "_self");
	} catch (e) {
		dispatch(openCheckoutFail(e));
	}
};

export const openCustomerPortal = () => async (dispatch: Dispatch) => {
	try {
		dispatch(openCustomerPortalInit());
		const {data} = await mapXPaymentApi.customerPortal();
		dispatch(openCustomerPortalDone());
		window.open(data.url, "_self");
	} catch (e) {
		dispatch(openCustomerPortalFail(e));
	}
};
