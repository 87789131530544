import type {FC} from "react";

import type {IconProps} from "./types";

const SortArrowsIcon: FC<IconProps> = ({className}) => (
	<svg
		width="20"
		height="20"
		fill="none"
		viewBox="0 0 20 20"
		className={className}
		xmlns="http://www.w3.org/2000/svg"
	>
		<mask
			x="0"
			y="0"
			width="20"
			height="20"
			id="mask0_5153_23769"
			style={{maskType: "alpha"}}
			maskUnits="userSpaceOnUse"
		>
			<rect width="20" height="20" fill="#D9D9D9" />
		</mask>
		<g mask="url(#mask0_5153_23769)">
			<path
				d="M10 3L7.11325 8L12.8868 8L10 3ZM10 17L12.8868 12L7.11325 12L10 17ZM9.5 7.5L9.5 12.5L10.5 12.5L10.5 7.5L9.5 7.5Z"
				fill="#B0B5B4"
			/>
		</g>
	</svg>
);

export default SortArrowsIcon;
