import HttpClient from "api/index";

class LanguageApi extends HttpClient {
	async get(config = {}) {
		return this.doGet("/languages", config);
	}
}

const mapxLanguageApi = new LanguageApi();

export default mapxLanguageApi;
