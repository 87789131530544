import type {FC} from "react";

import type {IconProps} from "./types";

const LoginBottomSVG: FC<IconProps> = ({className = ""}) => (
	<svg
		fill="none"
		width="228"
		height="195"
		viewBox="0 0 228 195"
		className={className}
		xmlns="http://www.w3.org/2000/svg"
	>
		<g clipPath="url(#clip0_1718_29090)">
			<path
				stroke="#D2D5D4"
				strokeWidth="1.90736"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
				d="M6.29372 185.979C3.35639 185.979 0.953125 188.382 0.953125 191.32C0.953125 194.257 3.35639 196.66 6.29372 196.66C9.23105 196.66 11.6343 194.257 11.6343 191.32C11.5962 188.382 9.23105 185.979 6.29372 185.979Z"
			/>
			<path
				stroke="#D2D5D4"
				strokeWidth="1.90736"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
				d="M6.29372 155.26C9.24326 155.26 11.6343 157.651 11.6343 160.601C11.6343 163.55 9.24326 165.941 6.29372 165.941C3.34419 165.941 0.953125 163.55 0.953125 160.601C0.953125 157.651 3.34419 155.26 6.29372 155.26Z"
			/>
			<path
				d="M6.29372 124.579C3.35639 124.579 0.953125 126.982 0.953125 129.919C0.953125 132.857 3.35639 135.26 6.29372 135.26C9.23105 135.26 11.6343 132.857 11.6343 129.919C11.5962 126.982 9.23105 124.579 6.29372 124.579Z"
				stroke="#D2D5D4"
				strokeWidth="1.90736"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				stroke="#D2D5D4"
				strokeWidth="1.90736"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
				d="M6.29372 93.8974C9.24326 93.8974 11.6343 96.2885 11.6343 99.238C11.6343 102.188 9.24326 104.579 6.29372 104.579C3.34419 104.579 0.953125 102.188 0.953125 99.238C0.953125 96.2885 3.34419 93.8974 6.29372 93.8974Z"
			/>
			<path
				d="M6.29372 63.2163C3.35639 63.2163 0.953125 65.6195 0.953125 68.5569C0.953125 71.4942 3.35639 73.8975 6.29372 73.8975C9.23105 73.8975 11.6343 71.4942 11.6343 68.5569C11.5962 65.6195 9.23105 63.2163 6.29372 63.2163Z"
				stroke="#D2D5D4"
				strokeWidth="1.90736"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				stroke="#D2D5D4"
				strokeWidth="1.90736"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
				d="M6.29372 32.5351C9.24326 32.5351 11.6343 34.9262 11.6343 37.8757C11.6343 40.8252 9.24326 43.2163 6.29372 43.2163C3.34419 43.2163 0.953125 40.8252 0.953125 37.8757C0.953125 34.9262 3.34419 32.5351 6.29372 32.5351Z"
			/>
			<path
				stroke="#D2D5D4"
				strokeWidth="1.90736"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
				d="M6.29372 1.85382C3.35639 1.85382 0.953125 4.2571 0.953125 7.19443C0.953125 10.1318 3.35639 12.535 6.29372 12.535C9.23105 12.535 11.6343 10.1318 11.6343 7.19443C11.5962 4.21895 9.23105 1.85382 6.29372 1.85382Z"
			/>
			<path
				fill="#A3E0D8"
				stroke="#A3E0D8"
				strokeWidth="1.90736"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
				d="M36.9754 185.979C34.038 185.979 31.6348 188.382 31.6348 191.32C31.6348 194.257 34.038 196.66 36.9754 196.66C39.9127 196.66 42.316 194.257 42.316 191.32C42.316 188.382 39.9127 185.979 36.9754 185.979Z"
			/>
			<path
				fill="#A3E0D8"
				stroke="#A3E0D8"
				strokeWidth="1.90736"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
				d="M36.9754 155.26C39.9249 155.26 42.316 157.651 42.316 160.601C42.316 163.55 39.9249 165.941 36.9754 165.941C34.0258 165.941 31.6348 163.55 31.6348 160.601C31.6348 157.651 34.0258 155.26 36.9754 155.26Z"
			/>
			<path
				fill="#A3E0D8"
				stroke="#A3E0D8"
				strokeWidth="1.90736"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
				d="M36.9754 124.579C34.038 124.579 31.6348 126.982 31.6348 129.919C31.6348 132.857 34.038 135.26 36.9754 135.26C39.9127 135.26 42.316 132.857 42.316 129.919C42.316 126.982 39.9127 124.579 36.9754 124.579Z"
			/>
			<path
				stroke="#D2D5D4"
				strokeWidth="1.90736"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
				d="M36.9754 93.8974C39.9249 93.8974 42.316 96.2885 42.316 99.238C42.316 102.188 39.9249 104.579 36.9754 104.579C34.0258 104.579 31.6348 102.188 31.6348 99.238C31.6348 96.2885 34.0258 93.8974 36.9754 93.8974Z"
			/>
			<path
				stroke="#D2D5D4"
				strokeWidth="1.90736"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
				d="M36.9754 63.2163C34.038 63.2163 31.6348 65.6195 31.6348 68.5569C31.6348 71.4942 34.038 73.8975 36.9754 73.8975C39.9127 73.8975 42.316 71.4942 42.316 68.5569C42.316 65.6195 39.9127 63.2163 36.9754 63.2163Z"
			/>
			<path
				stroke="#D2D5D4"
				strokeWidth="1.90736"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
				d="M36.9754 32.5351C39.9249 32.5351 42.316 34.9262 42.316 37.8757C42.316 40.8252 39.9249 43.2163 36.9754 43.2163C34.0258 43.2163 31.6348 40.8252 31.6348 37.8757C31.6348 34.9262 34.0258 32.5351 36.9754 32.5351Z"
			/>
			<path
				fill="#A3E0D8"
				stroke="#A3E0D8"
				strokeWidth="1.90736"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
				d="M67.657 185.979C64.7197 185.979 62.3164 188.382 62.3164 191.32C62.3164 194.257 64.7197 196.66 67.657 196.66C70.5943 196.66 72.9976 194.257 72.9976 191.32C72.9976 188.382 70.5943 185.979 67.657 185.979Z"
			/>
			<path
				stroke="#D2D5D4"
				strokeWidth="1.90736"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
				d="M67.657 155.298C64.7197 155.298 62.3164 157.701 62.3164 160.639C62.3164 163.576 64.7197 165.979 67.657 165.979C70.5943 165.979 72.9976 163.576 72.9976 160.639C72.9976 157.663 70.5943 155.298 67.657 155.298Z"
			/>
			<path
				fill="#A3E0D8"
				stroke="#A3E0D8"
				strokeWidth="1.90736"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
				d="M67.657 124.579C64.7197 124.579 62.3164 126.982 62.3164 129.919C62.3164 132.857 64.7197 135.26 67.657 135.26C70.5943 135.26 72.9976 132.857 72.9976 129.919C72.9976 126.982 70.5943 124.579 67.657 124.579Z"
			/>
			<path
				fill="#A3E0D8"
				stroke="#A3E0D8"
				strokeWidth="1.90736"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
				d="M67.657 93.8974C64.7197 93.8974 62.3164 96.3007 62.3164 99.238C62.3164 102.175 64.7197 104.579 67.657 104.579C70.5943 104.579 72.9976 102.175 72.9976 99.238C72.9976 96.2625 70.5943 93.8974 67.657 93.8974Z"
			/>
			<path
				fill="#A3E0D8"
				stroke="#A3E0D8"
				strokeWidth="1.90736"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
				d="M67.657 63.2163C64.7197 63.2163 62.3164 65.6195 62.3164 68.5569C62.3164 71.4942 64.7197 73.8975 67.657 73.8975C70.5943 73.8975 72.9976 71.4942 72.9976 68.5569C72.9976 65.6195 70.5943 63.2163 67.657 63.2163Z"
			/>
			<path
				fill="#A3E0D8"
				stroke="#A3E0D8"
				strokeWidth="1.90736"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
				d="M98.3386 185.979C95.4013 185.979 92.998 188.382 92.998 191.32C92.998 194.257 95.4013 196.66 98.3386 196.66C101.276 196.66 103.679 194.257 103.679 191.32C103.679 188.382 101.276 185.979 98.3386 185.979Z"
			/>
			<path
				stroke="#D2D5D4"
				strokeWidth="1.90736"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
				d="M98.3386 155.26C101.288 155.26 103.679 157.651 103.679 160.601C103.679 163.55 101.288 165.941 98.3386 165.941C95.3891 165.941 92.998 163.55 92.998 160.601C92.998 157.651 95.3891 155.26 98.3386 155.26Z"
			/>
			<path
				stroke="#D2D5D4"
				strokeWidth="1.90736"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
				d="M98.3386 124.579C95.4013 124.579 92.998 126.982 92.998 129.919C92.998 132.857 95.4013 135.26 98.3386 135.26C101.276 135.26 103.679 132.857 103.679 129.919C103.679 126.982 101.276 124.579 98.3386 124.579Z"
			/>
			<path
				d="M98.3386 93.8974C101.288 93.8974 103.679 96.2885 103.679 99.238C103.679 102.188 101.288 104.579 98.3386 104.579C95.3891 104.579 92.998 102.188 92.998 99.238C92.998 96.2885 95.3891 93.8974 98.3386 93.8974Z"
				stroke="#D2D5D4"
				strokeWidth="1.90736"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				fill="#A3E0D8"
				stroke="#A3E0D8"
				strokeWidth="1.90736"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
				d="M129.02 185.979C126.083 185.979 123.68 188.382 123.68 191.32C123.68 194.257 126.083 196.66 129.02 196.66C131.958 196.66 134.361 194.257 134.361 191.32C134.361 188.382 131.996 185.979 129.02 185.979Z"
			/>
			<path
				stroke="#D2D5D4"
				strokeWidth="1.90736"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
				d="M129.02 155.26C131.97 155.26 134.361 157.651 134.361 160.601C134.361 163.55 131.97 165.941 129.02 165.941C126.071 165.941 123.68 163.55 123.68 160.601C123.68 157.651 126.071 155.26 129.02 155.26Z"
			/>
			<path
				stroke="#D2D5D4"
				strokeWidth="1.90736"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
				d="M129.02 124.579C126.083 124.579 123.68 126.982 123.68 129.919C123.68 132.857 126.083 135.26 129.02 135.26C131.958 135.26 134.361 132.857 134.361 129.919C134.361 126.982 131.996 124.579 129.02 124.579Z"
			/>
			<path
				stroke="#D2D5D4"
				strokeWidth="1.90736"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
				d="M159.702 185.979C156.765 185.979 154.361 188.382 154.361 191.32C154.361 194.257 156.765 196.66 159.702 196.66C162.639 196.66 165.043 194.257 165.043 191.32C165.004 188.382 162.639 185.979 159.702 185.979Z"
			/>
			<path
				stroke="#D2D5D4"
				strokeWidth="1.90736"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
				d="M159.702 155.26C162.651 155.26 165.043 157.651 165.043 160.601C165.043 163.55 162.651 165.941 159.702 165.941C156.752 165.941 154.361 163.55 154.361 160.601C154.361 157.651 156.752 155.26 159.702 155.26Z"
			/>
			<path
				stroke="#D2D5D4"
				strokeWidth="1.90736"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
				d="M190.384 185.979C187.446 185.979 185.043 188.382 185.043 191.32C185.043 194.257 187.446 196.66 190.384 196.66C193.321 196.66 195.724 194.257 195.724 191.32C195.686 188.382 193.321 185.979 190.384 185.979Z"
			/>
		</g>
		<defs>
			<clipPath id="clip0_1718_29090">
				<rect width="228" height="229" fill="white" transform="matrix(1 0 0 -1 0 229)" />
			</clipPath>
		</defs>
	</svg>
);

export default LoginBottomSVG;
