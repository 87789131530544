import {generateCompanyAttributeAsFilterSet} from "helpers/Filters/extractCompaniesAttributeAsFilter";
import {useMemo} from "react";
import {useSelector} from "react-redux";
import {anyCompaniesSelector, selectedCompaniesSelector} from "store/mapx/filter/filterSelectors";

const useGenerateFilterSetByCompanies = (props: {
	useSelectedCompanies?: boolean;
	extractAttribute?: boolean;
	extractCompanyId?: boolean;
}) => {
	const {useSelectedCompanies = false, extractAttribute = true, extractCompanyId = false} = props;

	const companies = useSelector(anyCompaniesSelector);

	const selectedCompanies = useSelector(selectedCompaniesSelector);

	return useMemo(() => {
		const dataSet = useSelectedCompanies ? [...selectedCompanies] : [...companies];

		if (!Array.isArray(dataSet)) {
			return null;
		} else {
			return generateCompanyAttributeAsFilterSet(dataSet, extractAttribute, extractCompanyId);
		}
	}, [companies, extractAttribute, extractCompanyId, selectedCompanies, useSelectedCompanies]);
};

export default useGenerateFilterSetByCompanies;
