import type {FC} from "react";
import {useCallback, useRef, useState} from "react";
import classNames from "classnames";

import {SearchIcon} from "assets/icons";
import {useCompanyPeopleSearch, useOutsideClick} from "hooks";

import {searchCategory} from "./utils";
import SearchCategory from "./SearchCategory";
import styles from "./Search.module.scss";
import {SelectFilterType, TOnSelect} from "hooks/useCompanyPeopleSearch/types";
import {useHistory} from "react-router-dom";

const Search: FC = () => {
	const {
		companies,
		candidates,
		isLoading,
		activeCategory,
		setActiveCategory,
		onChange,
		searchTerm,
	} = useCompanyPeopleSearch();

	const history = useHistory();

	const ref = useRef<HTMLDivElement | null>(null);

	const [searchActive, setSearchActive] = useState<boolean>(false);

	const searchActiveHandler = () => {
		setSearchActive(true);
	};

	useOutsideClick(ref, () => setSearchActive(false));

	const onSelect: TOnSelect = useCallback(
		(filterType, selectedItem) => {
			switch (filterType) {
				case SelectFilterType.Companies: {
					history.push(`/company/${selectedItem.id}`);

					setTimeout(() => setSearchActive(false), 300);

					break;
				}
				case SelectFilterType.Candidates: {
					history.push(`/candidate/${selectedItem.id}`);

					setTimeout(() => setSearchActive(false), 300);

					break;
				}
				default: {
					break;
				}
			}
		},
		[history],
	);

	return (
		<div ref={ref} className={styles.wrapper}>
			<div
				role="button"
				onClick={searchActiveHandler}
				className={classNames(styles.wrapper__container, {
					[styles.wrapper__container__active]: searchActive,
				})}
			>
				<SearchIcon width="20" height="20" className={styles.wrapper__container__icon} />

				<input
					className={styles.wrapper__container__inp}
					onChange={(event) => onChange(event.target.value)}
					placeholder={searchCategory[activeCategory].placeholder}
				/>
			</div>

			{searchActive && !!searchTerm?.length && (
				<SearchCategory
					onSelect={onSelect}
					searchTerm={searchTerm}
					companies={companies}
					candidates={candidates}
					isLoading={isLoading}
					activeCategory={activeCategory}
					setActiveCategory={setActiveCategory}
				/>
			)}
		</div>
	);
};

export default Search;
