import {useHistory} from "react-router-dom";

const useOpenLink = () => {
	const history = useHistory();

	return (event, navigate, newWindow = false) => {
		event.persist();

		if (event.ctrlKey || event.metaKey || event.ctrlKey || event.altKey || newWindow) {
			window.open(navigate, "_blank");
		} else {
			history.push(navigate);
		}
	};
};

export default useOpenLink;
