import css from "./simpleSelect.module.scss";

function SimpleSelect({selected, onChange, options}) {
	return (
		<select className={css.select} onChange={onChange} value={selected}>
			{options.map((o) => {
				return (
					<option key={o.value} value={o.value}>
						{o.label}
					</option>
				);
			})}
		</select>
	);
}

function SimpleSelectLabelContainer({children, label}) {
	return (
		<div className={css.positionContainer}>
			<div className={css.positionLabel}>{label}</div>
			{children}
		</div>
	);
}

SimpleSelect.LabelContainer = SimpleSelectLabelContainer;

export default SimpleSelect;
