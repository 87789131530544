import React from "react";

const AssessmentIcon = () => {
	return (
		<svg
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<mask
				id="mask0_1733_174917"
				style={{maskType: "alpha"}}
				maskUnits="userSpaceOnUse"
				x="0"
				y="0"
				width="20"
				height="20"
			>
				<rect width="20" height="20" fill="#D9D9D9" />
			</mask>
			<g mask="url(#mask0_1733_174917)">
				<path
					d="M3.5 17C3.0875 17 2.73438 16.8531 2.44063 16.5594C2.14688 16.2656 2 15.9125 2 15.5V4.5C2 4.0875 2.14688 3.73438 2.44063 3.44063C2.73438 3.14688 3.0875 3 3.5 3H16.5C16.9125 3 17.2656 3.14688 17.5594 3.44063C17.8531 3.73438 18 4.0875 18 4.5V15.5C18 15.9125 17.8531 16.2656 17.5594 16.5594C17.2656 16.8531 16.9125 17 16.5 17H3.5ZM3.5 15.5H16.5V4.5H3.5V15.5ZM4.39583 14.0208H8.39583V12.5208H4.39583V14.0208ZM12.125 12.5L15.6667 8.95833L14.6042 7.89583L12.125 10.375L11.0625 9.3125L10 10.375L12.125 12.5ZM4.39583 10.75H8.39583V9.25H4.39583V10.75ZM4.39583 7.5H8.39583V6H4.39583V7.5Z"
					fill="#1C1B1F"
				/>
			</g>
		</svg>
	);
};

export default AssessmentIcon;
