import {Tag} from "components";
import {getName} from "helpers/string";
import FilterTags from "mapx-components/FilterTags";
import React, {useCallback, useMemo, useState} from "react";
import {TJobFunctionNSpecialismTag, TSelectedTags, TTagDragEvent, TTagsProps} from "../types";
import {
	TJobFunctionPosition,
	TJobFunctionPositionConnectivityLogic,
} from "api/filterOptionsApi/JobFunctionApi/types";
import {useSelector} from "react-redux";
import {filterConnectivityLogicOptionsSelector} from "store/mapx/filter/filterSelectors";

const Tags = ({
	tagLabel,
	tagValue,
	tags,
	handleConnectivityOptionChange,
	selectedLogic,
	handleTagClick,
	moveSpecialismTagPosition,
	clearTagsByPosition,
}: TTagsProps) => {
	const [selectedTags, setSelectedTags] = useState<TSelectedTags>({});

	const filterConnectivityLogicOptions = useSelector(filterConnectivityLogicOptionsSelector);

	const handleTagDragStart = useCallback(
		(e: TTagDragEvent, id: string) => {
			e.dataTransfer.setData("id", id);
			e.dataTransfer.setData("from", tagValue);

			// Reset selected tags.
			setSelectedTags({});
		},
		[tagValue],
	);

	const handleDrop = useCallback(
		(e: TTagDragEvent) => {
			const id = parseInt(e.dataTransfer.getData("id"), 10);
			const from = e.dataTransfer.getData("from") as TJobFunctionPosition;

			moveSpecialismTagPosition(id, from, tagValue);
		},
		[moveSpecialismTagPosition, tagValue],
	);

	const moveDisabled = useMemo(() => {
		let disabled = true;

		for (const [, selectedSpecialisms] of Object.entries(selectedTags)) {
			if (selectedSpecialisms.length > 0) {
				disabled = false;
				break;
			}
		}

		return disabled;
	}, [selectedTags]);

	return (
		<FilterTags
			onDrop={handleDrop}
			label={tagLabel}
			count={tags.length}
			moveDisabled={moveDisabled}
			connectivityOptions={filterConnectivityLogicOptions}
			handleConnectivityOptionChange={(logic: string) =>
				handleConnectivityOptionChange(
					tagValue,
					logic as TJobFunctionPositionConnectivityLogic,
				)
			}
			selectedConnectivityLogic={selectedLogic}
			tags={tags.map((item: TJobFunctionNSpecialismTag) => (
				<Tag
					key={item.id}
					onClick={() => handleTagClick(item.id, tagValue, selectedLogic)}
					draggable
					onDragStart={(e) => handleTagDragStart(e, item.id.toString())}
					onDragOver={(e) => e.preventDefault()}
				>
					{item.jobFunctionName
						? `${getName(item.jobFunctionName)}: ${getName(item.name)}`
						: getName(item.name)}
				</Tag>
			))}
			onClear={() => clearTagsByPosition(tagValue)}
		/>
	);
};

export default React.memo(Tags);
