import {Checkbox, CheckboxSkeletonLoader, ResetFilters} from "components";

import {getName} from "helpers/string";
import {flattenByChildren} from "helpers/TreeModification";
import useFetchListOptions from "hooks/useFetchListOptions";
import {SearchInput} from "mapx-components";
import ExpandableArrow from "mapx-components/ExpandableArrow";
import CheckboxList from "mapx-components/Inputs/CheckboxList";
import {useCallback, useMemo, useState} from "react";
import {useSelector} from "react-redux";
import {getIndustries} from "store/mapx/search/searchAsyncActions";
import {industryOptionsSelector} from "store/mapx/search/searchSelectors";
import useDebouncedEffect from "use-debounced-effect";

import {getIndustryExpandableData, getUpdatedIndustryIdsByCurrentSelectedItem} from "./utils";
import {
	TChildrenCheckboxList,
	TIndustrySearchFilter,
} from "mapx-components/Filters/IndustrySearchFilter/types";
import {TGetExpandableDataResult} from "types";
import {TChildIndustryForFilter, TIndustryForFilter} from "api/filterOptionsApi/IndustryApi/types";

const ChildrenCheckboxListComponent = ({
	treeIndex,
	parentItem,
	childrenItems,
	expandedItems,
	handleExpandClick,
	selectedItems,
	handleOnSelect,
	partiallyCheckMethod,
}: TChildrenCheckboxList) => {
	const hasChildren = (item: TIndustryForFilter) =>
		item.children !== undefined && item.children?.length > 0;

	return childrenItems.map((item: TIndustryForFilter) => (
		<div key={item.id}>
			<CheckboxList.AccordionChildrenContent
				key={item.id}
				expanded={expandedItems[parentItem.id]}
				childrenExpanded={expandedItems[item.id]}
				handleExpandClick={() => handleExpandClick(item)}
				hasChildren={hasChildren(item)}
				treeIndex={treeIndex}
				/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
				// @ts-ignore
				item={item}
			>
				<Checkbox
					borderColor="#0C5850"
					isChecked={selectedItems.includes(item.id)}
					key={item.id}
					label={`${getName(item.name)}`}
					onChange={() => handleOnSelect(item)}
					value={getName(item.name)}
					partiallySelected={partiallyCheckMethod(item)}
				/>
			</CheckboxList.AccordionChildrenContent>

			{hasChildren(item) && (
				/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
				// @ts-ignore
				<ChildrenCheckboxListComponent
					treeIndex={treeIndex + 1}
					/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
					// @ts-ignore
					childrenItems={item.children}
					parentItem={item}
					expandedItems={expandedItems}
					handleExpandClick={handleExpandClick}
					selectedItems={selectedItems}
					handleOnSelect={handleOnSelect}
					partiallyCheckMethod={partiallyCheckMethod}
				/>
			)}
		</div>
	));
};

const IndustrySearchFilter = ({
	handleOnChange,
	handleResetClick,
	selectedIndustries,
}: TIndustrySearchFilter) => {
	const industryOptions = useSelector(industryOptionsSelector);

	const [expanded, setExpanded] = useState<TGetExpandableDataResult>({});

	const allIndustryOptionsWithoutChild = useMemo(
		() => flattenByChildren(industryOptions),
		[industryOptions],
	);

	const {filteredData, searchTerm, onFilterChanged, loading} = useFetchListOptions({
		options: industryOptions,
		callbackMethod: getIndustries,
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		childFilterKey: "children",
	});

	useDebouncedEffect(
		() => {
			if (!searchTerm) {
				setExpanded({});

				return;
			}

			const expandableFilteredData = getIndustryExpandableData(filteredData);

			setExpanded(expandableFilteredData as TGetExpandableDataResult);
		},
		500,
		[searchTerm],
	);

	const toggleExpandItem = useCallback(
		(item: TIndustryForFilter) => {
			if (expanded && expanded[item.id] === undefined) {
				setExpanded({
					...expanded,
					[item.id]: true,
				});
			} else {
				let toBeUpdatedList = {...expanded};

				if (item.all_parent_ids_from_children && expanded && expanded[item.id]) {
					toBeUpdatedList = {...toBeUpdatedList, [item.id]: false};

					// this ensures when parent item is collapsed, parent items are also collapsed
					for (const id of item.all_parent_ids_from_children) {
						toBeUpdatedList = {...toBeUpdatedList, [id]: false};
					}
				} else {
					toBeUpdatedList = {...toBeUpdatedList, [item.id]: true};
				}

				setExpanded(toBeUpdatedList);
			}
		},
		[expanded],
	);

	const handleOnSelect = (item: TIndustryForFilter) => {
		const nextIndustries = getUpdatedIndustryIdsByCurrentSelectedItem(
			item,
			[...selectedIndustries],
			allIndustryOptionsWithoutChild as TChildIndustryForFilter[],
		);

		handleOnChange(nextIndustries);
	};

	const isItemPartiallySelected = (item: TIndustryForFilter) => {
		if (item.all_child_ids && item?.all_child_ids?.length > 0) {
			const areArraysEqual =
				selectedIndustries.length === item.all_child_ids.length &&
				item.all_child_ids.every((id) => selectedIndustries.includes(id));

			if (areArraysEqual || selectedIndustries.includes(item.id)) {
				return false;
			}

			// need to check if the array are equal first to ensure It's partial or not.
			const containsPartialItems = item.all_child_ids.some((id) =>
				selectedIndustries.includes(id),
			);

			if (containsPartialItems) {
				return true;
			}
		}

		return false;
	};

	return (
		<div>
			<SearchInput
				onChange={onFilterChanged}
				placeholder="Search for an Industry"
				type="text"
				errorText={undefined}
				errorClass={undefined}
				isLoading={loading}
			/>

			<ResetFilters
				parentStyle={{color: "#5A5A5A", marginRight: 19}}
				onClick={handleResetClick}
				displayIcon={true}
			>
				Clear Selection
			</ResetFilters>

			<CheckboxList>
				{filteredData?.length > 0 &&
					filteredData.map((item: TIndustryForFilter) => {
						const {id, name, children} = item;

						return (
							<CheckboxList.Accordion key={id}>
								<CheckboxList.AccordionHeader>
									{children && children?.length > 0 ? (
										<ExpandableArrow
											onClick={() => toggleExpandItem(item)}
											rotated={!expanded[id]}
										/>
									) : (
										<span style={{width: 21}} />
									)}

									<Checkbox
										borderColor="#0C5850"
										isChecked={selectedIndustries.includes(id)}
										key={id}
										label={`${getName(name)}`}
										onChange={() => handleOnSelect(item)}
										value={id.toString()}
										partiallySelected={isItemPartiallySelected(item)}
									/>
								</CheckboxList.AccordionHeader>

								{children && children?.length > 0 && (
									/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
									// @ts-ignore
									<ChildrenCheckboxListComponent
										treeIndex={1}
										childrenItems={children}
										expandedItems={expanded}
										parentItem={item}
										handleExpandClick={(paramItem: TIndustryForFilter) =>
											toggleExpandItem(paramItem)
										}
										selectedItems={selectedIndustries}
										handleOnSelect={handleOnSelect}
										partiallyCheckMethod={isItemPartiallySelected}
									/>
								)}
							</CheckboxList.Accordion>
						);
					})}
			</CheckboxList>

			{(loading || industryOptions.length === 0) && <CheckboxSkeletonLoader />}
		</div>
	);
};

export default IndustrySearchFilter;
