import type {FC} from "react";
import {useMemo, useState} from "react";
import classNames from "classnames";

import {Outsider} from "components";
import {Flag, Check, Triangle} from "assets/icons";
import {useAppDispatch, useAppSelector} from "hooks";
import {Checkbox, GeneralButton} from "mapx-components";
import mapXCandidateApi from "api/candidateApi";
import {flagCandidate} from "store/mapx/candidate/candidateActions";

import {defaultOptions} from "./utils";
import type {TDefaultOption, TCheckboxEvent, TFinishedHook, TFeedback} from "./types";
import styles from "./styles.module.scss";

export const Feedback: FC<TFeedback> = ({
	type,
	candidateId,
	opposite = false,
	quickView = false,
	is_data_flagged_wrong = false,
}) => {
	const [loading, setLoading] = useState<boolean>(false);
	const [feedbackModal, setFeedbackModal] = useState<boolean>(false);
	const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
	const [options, setOptions] = useState<TDefaultOption[]>(defaultOptions);
	const [feedbackSubmitted, setFeedbackSubmitted] = useState<boolean>(is_data_flagged_wrong);
	const [finished, setFinished] = useState<TFinishedHook>({
		status: false,
		message: "",
		caughtError: false,
	});

	const dispatch = useAppDispatch();
	const {currentCandidate} = useAppSelector((state) => state.candidate);

	const handleCheckboxChange = async (item: TCheckboxEvent) => {
		const option = options.find((val) => val.id === item.id);
		setOptions(
			options.map((option) =>
				option.id === item.id ? {...option, is_checked: !option.is_checked} : option,
			),
		);
		if (!item.value) {
			setSelectedOptions(selectedOptions?.filter((val) => val !== option?.message));
		} else {
			if (option) {
				const newList = [...selectedOptions, option.message];
				setSelectedOptions(newList);
			}
		}
	};

	const shouldUpdateFlagStatusOnState = useMemo(() => {
		return type === "profile" || type === "quick-view" || currentCandidate?.id === candidateId;
	}, [candidateId, currentCandidate?.id, type]);

	const handleSend = async () => {
		try {
			setLoading(true);
			for (const message of selectedOptions) {
				await mapXCandidateApi.reportIssue(candidateId, message);
			}
			setLoading(false);
			setFeedbackSubmitted(true);
			setFinished({
				status: true,
				message: `Thank you for the feedback, we are constantly working to
				improve our database with your help.`,
				caughtError: false,
			});

			if (shouldUpdateFlagStatusOnState) {
				dispatch(flagCandidate(true));
			}
		} catch (e) {
			setFinished({
				status: true,
				message: `Something went wrong. Try again later.`,
				caughtError: true,
			});
			setFeedbackSubmitted(false);

			if (shouldUpdateFlagStatusOnState) {
				dispatch(flagCandidate(false));
			}
		}
	};

	return (
		<div className={styles.feedbackWrapper}>
			<div onClick={() => setFeedbackModal(true)} className={styles.flagWrapper}>
				<Flag isFlagged={feedbackSubmitted} />
			</div>

			{feedbackModal ? (
				<Outsider onOutside={() => setFeedbackModal(false)}>
					<div
						className={classNames(styles.feedbackModalWrapper, {
							[styles.quickViewModal]: quickView,
							[styles.opposite]: opposite,
						})}
					>
						<div
							className={classNames(styles.triangleWrapper, {
								[styles.quivkViewTriangle]: quickView,
							})}
						>
							<Triangle />
						</div>
						<div
							className={classNames(styles.feedbackModal, {
								[styles.sentModal]: feedbackSubmitted,
							})}
						>
							{is_data_flagged_wrong && !finished.status ? (
								<div className={styles.feedbackSent}>
									<span>
										We already have received feedback for this profile. After
										reviewing, we will update the data accordingly.
									</span>
								</div>
							) : (
								<>
									<p>Help us to improve the database</p>
									{finished.status ? (
										<div className={styles.successMessage}>
											<div>{finished.caughtError ? null : <Check />}</div>
											<span>{finished.message}</span>
										</div>
									) : (
										// @TODO CHANGE THIS CHECKBOX TO NEW CHECKBOX COMPONENT FROM /components/inputs/
										options.map((option, idx) => (
											<Checkbox
												key={`${option.id}_${idx}`}
												isChecked={option.is_checked}
												name={option.name}
												item={option}
												prefix={option.prefix}
												setFilterParam={handleCheckboxChange}
											/>
										))
									)}
									{finished.status ? null : (
										<GeneralButton
											title="Send"
											handleClick={handleSend}
											loadingSave={loading}
											disabled={!selectedOptions.length}
										/>
									)}
								</>
							)}
						</div>
					</div>
				</Outsider>
			) : null}
		</div>
	);
};
