import type {FC} from "react";

import type {IconProps} from "./types";

const ClearSelectionIcon: FC<IconProps> = ({width = 15, height = 12, color = "#868D8D"}) => (
	<svg
		width={width}
		height={height}
		viewBox={`0 0 ${width} ${height}`}
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M6.92308 9.6L9.23077 7.2L11.5385 9.6L12.6923 8.4L10.3846 6L12.6923 3.6L11.5385 2.4L9.23077 4.8L6.92308 2.4L5.76923 3.6L8.07692 6L5.76923 8.4L6.92308 9.6ZM0 6L4.08692 0.6744C4.26308 0.4584 4.46731 0.292 4.69962 0.1752C4.93192 0.0583999 5.18423 0 5.45654 0H13.2692C13.75 0 14.1585 0.1752 14.4946 0.5256C14.8315 0.8752 15 1.3 15 1.8V10.2C15 10.7 14.8315 11.1248 14.4946 11.4744C14.1585 11.8248 13.75 12 13.2692 12H5.45654C5.18423 12 4.93192 11.9416 4.69962 11.8248C4.46731 11.708 4.26308 11.5416 4.08692 11.3256L0 6Z"
			fill={color}
		/>
	</svg>
);

export default ClearSelectionIcon;
