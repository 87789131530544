import {FC, BaseSyntheticEvent, useRef, useState, useCallback, useMemo} from "react";
import classNames from "classnames";
import {format} from "date-fns";

import {
	ArrowDown,
	CreatePerson,
	ProjectEditIcon,
	RemoveOrganization,
	ThreeDotsHorizontal,
} from "assets/icons";
import {
	CreateUserModal,
	DeleteUserModal,
	CreateOrganizationModal,
	OrganizationActionsDropDown,
} from "mapx-components";
import {Button} from "components";
import {
	invitedUsersSelector,
	creditsUsedKeySelector,
	deleteOrgProgressSelector,
	createInvitationSuccessSelector,
	updateOrganizationsInProgressSelector,
} from "store/mapx/organisation/organisationSelectors";
import {
	getInvitedUsers,
	createInvitation,
	deleteOrganization,
	updateOrganization,
	getOrganisationUsers,
} from "store/mapx/organisation/organisationAsyncFunctions";
import {deleteUser} from "store/mapx/user/userAsyncAction";
import {useOutsideClick, useAppDispatch, useAppSelector} from "hooks";
import {TLicenseType} from "mapx-components/Modals/CreateUserModal/types";
import {companyLicenseTypesSelector} from "store/mapx/company/companySelectors";
import type {TSelectWithActionOptions} from "components/Inputs/SelectWithAction/types";

import SubHeader from "../SubHeader";
import ChildeItem from "../ChildeItem";
import type {TUserDataProp} from "../types";
import {getUpdatedOrderingData} from "../utils";
import {creditsUsedData} from "../TableHeader/TableHeaderStep";

import Badge from "./Badge";
import InvitedUsers from "./InvitedUsers";
import {initialState, licTypeInitial} from "./utils";
import OrganizationTableSkeleton from "./OrganizationTableSkeleton";
import type {IOrganizationItemProps, TInvitedUsers, TOrganizationItemState} from "./types";
import styles from "./OrganizationItem.module.scss";

const OrganizationItem: FC<IOrganizationItemProps> = ({item}) => {
	const ref = useRef<HTMLDivElement | null>(null);

	const dispatch = useAppDispatch();

	const invitedUsers = useAppSelector(invitedUsersSelector);

	const creditsUsedKey = useAppSelector(creditsUsedKeySelector);
	const getLicenseTypeSelect = useAppSelector(companyLicenseTypesSelector);
	const createUserSuccess = useAppSelector(createInvitationSuccessSelector);
	const removeOrgProgressSelector = useAppSelector(deleteOrgProgressSelector);
	const updateOrgInProgressSelector = useAppSelector(updateOrganizationsInProgressSelector);

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [organizationEmail, setOrganizationEmail] = useState<string>("");
	const [orgUserData, setOrgUserData] = useState<TUserDataProp[] | []>([]);
	const [orgUserDataOrdering, setOrgUserDataOrdering] = useState<string[]>([]);
	const [isToggleActive, setIsToggleActive] = useState<boolean>(item?.is_active || false);
	const [licenseType, setLicenseType] = useState<TSelectWithActionOptions>(licTypeInitial);
	const [itemBoolState, setItemBoolState] = useState<TOrganizationItemState>(initialState);
	const [organizationName, setOrganizationName] = useState<string>(item?.organisation_name || "");

	const creditsUsedValue =
		creditsUsedData.find((creditsUsedItem) => creditsUsedItem.key === creditsUsedKey)?.label ||
		creditsUsedData[0].label;

	const transformLicenseTypeData = getLicenseTypeSelect?.map((item: TLicenseType) => {
		return {
			label: item?.name,
			value: item?.id,
		};
	});

	const rows = [
		item?.organisation_name,
		item?.is_active,
		item?.created_at,
		item?.credits_used,
		item?.total_users,
	];

	const transformResults = orgUserData?.map((item: TUserDataProp) => {
		return {
			...item,
			full_name: item.first_name,
			user_type: item?.organisation?.type,
			expires_at: item?.license?.expires_at,
		};
	});

	const organizationUsersData = item?.total_users ? transformResults : [];

	const onChange = (e: BaseSyntheticEvent) => {
		setOrganizationName(e.target.value);
	};

	const onToggle = useCallback(
		(key: keyof TOrganizationItemState, value: boolean) => {
			setItemBoolState((prevItemBoolState) => ({
				...prevItemBoolState,
				[key]: value,
			}));
			setOrganizationEmail("");
			setLicenseType(licTypeInitial);
			setIsToggleActive(item?.is_active);
			setOrganizationName(item?.organisation_name as string);
		},
		[item?.is_active, item?.organisation_name],
	);

	const closeDropdownHandler = () => {
		setItemBoolState((prevItemBoolState) => ({
			...prevItemBoolState,
			showSettings: false,
		}));
	};

	const toggleHandler = () => {
		setIsToggleActive(!isToggleActive);
	};

	const deleteHandler = () => {
		dispatch(deleteOrganization(item?.id));
	};

	const showOrganizationUsersListHandler = useCallback(
		async (shouldToggleDropdown = true) => {
			shouldToggleDropdown && onToggle("showPeople", !itemBoolState.showPeople);
			setIsLoading(true);

			dispatch(getInvitedUsers());

			if (!itemBoolState.showPeople && Number(item?.total_users) > 0) {
				const res = await dispatch(getOrganisationUsers(item?.id));
				setOrgUserData(res.results);
				setIsLoading(false);
			}

			setIsLoading(false);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[item?.id, item?.total_users, itemBoolState.showPeople, onToggle],
	);

	const editOrganizationHandler = (e: BaseSyntheticEvent) => {
		e.preventDefault();
		dispatch(
			updateOrganization(item?.id, {
				is_active: isToggleActive,
				name: organizationName,
			}),
		);
	};

	const createUserSubmit = (e: BaseSyntheticEvent, freeTrialValue: number) => {
		e.preventDefault();
		dispatch(createInvitation(organizationEmail, item?.id, licenseType?.value, freeTrialValue));
		setLicenseType(licTypeInitial);
	};

	const changeEmailHandler = (e: BaseSyntheticEvent) => {
		setOrganizationEmail(e.target.value);
	};

	const handleOrgUsersSort = useCallback(
		async (itemKey: string) => {
			const updatedOrdering = getUpdatedOrderingData(orgUserDataOrdering, itemKey);

			setOrgUserDataOrdering(updatedOrdering);

			if (itemKey === "email" || itemKey === "license_type") {
				dispatch(
					getInvitedUsers(
						updatedOrdering.filter(
							(updatedOrderingItem) =>
								updatedOrderingItem.includes("email") ||
								updatedOrderingItem.includes("license_type"),
						),
					),
				);
			}

			if (Number(item?.total_users) > 0) {
				const res = await dispatch(getOrganisationUsers(item?.id, updatedOrdering));

				setOrgUserData(res.results);
				setIsLoading(false);
			}

			setIsLoading(false);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[item?.id, item?.total_users, orgUserDataOrdering],
	);

	const filteredInvitedUsers = invitedUsers?.filter(
		(elem: TInvitedUsers) => elem?.organisation_id === item?.id,
	);

	const removeUserInCompanyHandler = async (stepListId: number) => {
		await dispatch(deleteUser(stepListId, item.id));

		const res = await dispatch(getOrganisationUsers(item?.id));
		setOrgUserData(res.results);
	};

	const renderUsers = useMemo(
		() =>
			!organizationUsersData?.length && !filteredInvitedUsers?.length ? (
				<div className={styles.wrapper__empty}>
					<p className={styles.wrapper__empty__text}>
						There are currently no users in this organisation.
					</p>
					<Button
						LeftIcon={CreatePerson}
						className={styles.wrapper__empty__btn}
						onClick={() => onToggle("showCreateUser", true)}
					>
						Add User
					</Button>
				</div>
			) : (
				<div className={styles.wrapper__content}>
					<SubHeader
						onSort={handleOrgUsersSort}
						creditsUsedKey={creditsUsedKey}
						creditsUsedValue={creditsUsedValue}
						orgUserDataOrdering={orgUserDataOrdering}
					/>

					{/* @TODO: Needs refactoring for types */}
					{/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
					{organizationUsersData?.map((elem: any) => (
						<ChildeItem
							item={item}
							key={elem.id}
							stepList={elem}
							orgUserData={orgUserData}
							usersCount={organizationUsersData?.length}
							closeDropdownHandler={closeDropdownHandler}
							removeUserInCompanyHandler={removeUserInCompanyHandler}
						/>
					))}

					{filteredInvitedUsers?.map((item: TInvitedUsers) => (
						<InvitedUsers
							key={item?.id}
							invitedUsers={item}
							closeDropdownHandler={closeDropdownHandler}
						/>
					))}
				</div>
			),

		// eslint-disable-next-line react-hooks/exhaustive-deps
		[item, organizationUsersData],
	);

	const renderItems = rows?.map((row, idx) => {
		const dateTooltip = idx === 2 ? format(new Date(`${row}`), "yyyy-MM-dd") : "";
		const titleTooltip = idx === 0 ? `${row}` : dateTooltip;

		const variantValue = () => {
			switch (row) {
				case item?.is_active:
					return <Badge status={!!row} /> || "-";

				case item?.created_at:
					return (
						<p className={styles.wrapper__head__btn__text} title={titleTooltip}>
							{format(new Date(`${row}`), "yyyy-MM-dd") || "-"}
						</p>
					);

				default:
					return (
						<p className={styles.wrapper__head__btn__text} title={titleTooltip}>
							{row}
						</p>
					);
			}
		};

		return (
			<div
				key={`${row}-${idx}`}
				className={classNames(styles.wrapper__head__btn, {
					[styles.wrapper__head__btn_left]: idx === 0 || idx === 1 || idx === 2,
					[styles.wrapper__head__btn_right]: idx === 3 || idx === 4,
				})}
			>
				{variantValue()}
			</div>
		);
	});

	const modalData = [
		{
			id: 0,
			Icon: ProjectEditIcon,
			label: "Edit Organization",
			onClick: () => onToggle("showEditOrganization", true),
		},
		{
			id: 1,
			isRemove: true,
			Icon: RemoveOrganization,
			label: "Remove Organization",
			onClick: () => onToggle("showRemoveOrganization", true),
		},
	];

	useOutsideClick(ref, () => onToggle("showSettings", false));

	return (
		<div
			ref={ref}
			className={classNames(styles.wrapper, {
				[styles.wrapper__latest]: !itemBoolState.showPeople,
			})}
		>
			<div
				className={classNames(styles.wrapper__head, {
					[styles.wrapper__latest__head]: !itemBoolState.showPeople,
				})}
			>
				<div
					role="button"
					className={styles.wrapper__head__show}
					onClick={() => showOrganizationUsersListHandler()}
				>
					<ArrowDown
						className={classNames(styles.wrapper__head__show__arrow, {
							[styles.wrapper__head__show__arrow__rotate]: itemBoolState.showPeople,
						})}
					/>
				</div>

				<div className={styles.wrapper__head__container}>{renderItems}</div>

				<div role="button" onClick={() => onToggle("showCreateUser", true)}>
					<CreatePerson className={styles.wrapper__head__create} />
				</div>

				<div
					role="button"
					className={styles.wrapper__head__three}
					onClick={() => onToggle("showSettings", !itemBoolState.showSettings)}
				>
					<ThreeDotsHorizontal className={styles.wrapper__head__three_dot} />

					{itemBoolState.showSettings && (
						<OrganizationActionsDropDown
							data={modalData}
							className={styles.wrapper__head__three_modal}
						/>
					)}
				</div>
			</div>

			{itemBoolState.showPeople &&
				(isLoading ? (
					<div className={styles.wrapper__empty}>
						<OrganizationTableSkeleton />
					</div>
				) : (
					renderUsers
				))}

			<CreateOrganizationModal
				isEditing
				onChange={onChange}
				toggle={isToggleActive}
				label="Organization Name"
				setToggle={toggleHandler}
				title="Edit an Organization"
				btnTitle="Edit Organization"
				onSubmit={editOrganizationHandler}
				organizationName={organizationName}
				loading={updateOrgInProgressSelector}
				isOpen={itemBoolState.showEditOrganization}
				setIsOpen={(bool) => onToggle("showEditOrganization", bool)}
			/>

			<DeleteUserModal
				submitText="Delete"
				deleteHandler={deleteHandler}
				loading={removeOrgProgressSelector}
				isOpen={itemBoolState.showRemoveOrganization}
				setIsOpen={(bool) => onToggle("showRemoveOrganization", bool)}
				titleText={`Would you like to delete ”${item?.organisation_name}”?`}
			/>

			<CreateUserModal
				licenseType={licenseType}
				loading={createUserSuccess}
				onSubmit={createUserSubmit}
				onChange={changeEmailHandler}
				setLicenseType={setLicenseType}
				license={transformLicenseTypeData}
				organizationEmail={organizationEmail}
				isOpen={itemBoolState.showCreateUser}
				organizationName={`${item?.organisation_name}`}
				setIsOpen={(bool) => onToggle("showCreateUser", bool)}
			/>
		</div>
	);
};

export default OrganizationItem;
