import classNames from "classnames";

import {CheckCircleInvertedIcon, StarIcon} from "assets/icons";
import {GeneralButton} from "mapx-components";

import styles from "./PricingCard.module.scss";
import type {TPricingTable} from "./types";

const PricingCard = ({
	buttonAction,
	buttonDisabled = false,
	buttonSecondary = false,
	buttonText,
	description,
	features,
	freeTrialDays = 0,
	loading = false,
	price,
	regularPrice,
	title,
	unit,
}: TPricingTable) => {
	return (
		<div className={styles.wrapper}>
			<div className={styles.title}>{title}</div>
			<div className={styles.description}>{description}</div>
			<div className={styles.discount}>
				{regularPrice && typeof price === "number" && regularPrice > price && (
					<>
						<div className={styles.regularPrice}>
							{regularPrice && `$${regularPrice}`}
						</div>
						<div className={styles.discountPercentage}>
							{Math.round((100 * (regularPrice - price)) / regularPrice)}% Off
						</div>
					</>
				)}
			</div>
			<div className={styles.cost}>
				<div className={styles.price}>
					{typeof price === "number" ? `$${price}` : price}
				</div>
				{unit && (
					<div className={styles.unit}>
						{unit.map((u) => (
							<p key={u}>{u}</p>
						))}
					</div>
				)}
			</div>

			<div className={styles.features}>
				{features.map((feature, index) => (
					<div key={index} className={styles.feature}>
						<CheckCircleInvertedIcon />
						{feature}
					</div>
				))}
			</div>

			<div className={classNames(styles.freeTrial, {[styles.hidden]: !(freeTrialDays > 0)})}>
				<div className={styles.freeTrialText}>
					<StarIcon />
					{freeTrialDays} days free trial
				</div>
			</div>
			<GeneralButton
				customClass={classNames(styles.button, {[styles.secondary]: buttonSecondary})}
				disabled={buttonDisabled}
				loadingSave={loading}
				onClick={buttonAction}
				title={buttonText}
				type="button"
			/>
		</div>
	);
};

export default PricingCard;
