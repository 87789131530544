import {FC, useEffect, useMemo, useState} from "react";

import {
	industryBackgroundReportProgressSelector,
	industryBackgroundReportSelector,
	jobFunctionDistributionInProgressSelector,
	jobFunctionDistributionSelector,
} from "store/mapx/project/projectSelectors";
import {
	getIndustriesBackgroundReport,
	getJobFunctionDistributionReport,
} from "store/mapx/project/projectAsyncActions";
import {SIIndustryData} from "api/filterOptionsApi/IndustryApi/types";
import {useAppDispatch, useAppSelector} from "hooks";
import {projectSelector} from "store/mapx/project-list/projectListSelectors";
import BackgroundAnalysisContainer from "mapx-components/BackgroundAnalysisContainer";
import {getModifiedFunctionalBreakdownData} from "mapx-pages/CompanyDetails/BackgroundAnalysis/utils";

import styles from "./JobFunctionDistributionChart.module.scss";
import {STProject} from "api/projectApi/types";

const JobFunctionDistributionChart: FC = () => {
	const dispatch = useAppDispatch();

	const project: STProject = useAppSelector(projectSelector);

	const jobFunctionDistributionInProgress = useAppSelector(
		jobFunctionDistributionInProgressSelector,
	);

	const industryData = useAppSelector(industryBackgroundReportSelector);

	const functionalData = useAppSelector(jobFunctionDistributionSelector);

	const industryBackgroundReportInProgress = useAppSelector(
		industryBackgroundReportProgressSelector,
	);

	const [isDefaultChart, setIsDefaultChart] = useState<boolean>(true);
	const [currentOption, setCurrentOption] = useState<number>(0);
	const [currentIndustryOption, setCurrentIndustryOption] = useState<number>(0);

	const industryBackgroundData = industryData?.map((industryDataItem: SIIndustryData) => ({
		name: industryDataItem.name,
		candidates: industryDataItem.candidates,
		candidateCount: industryDataItem.candidates_count,
		value: Math.round(industryDataItem.duration_percent),
		frequencyValue: Math.round(industryDataItem.candidates_percent),
	}));

	const functionalBreakdownData = useMemo(
		() =>
			functionalData?.length > 0 ? getModifiedFunctionalBreakdownData(functionalData) : [],
		[functionalData],
	);

	useEffect(() => {
		if (project?.id) {
			dispatch(getIndustriesBackgroundReport(project?.id));
			dispatch(getJobFunctionDistributionReport(project.id));
		}
	}, [dispatch, project?.id]);

	return (
		<div className={styles.chart_container}>
			<BackgroundAnalysisContainer
				isRoundedChartVal
				data={functionalBreakdownData}
				currentOption={currentOption}
				setCurrentOption={setCurrentOption}
				title="Functional Background"
				isDefaultChart={isDefaultChart}
				description={
					!currentOption
						? "The average time people have spent within each function and specialism as their previous roles."
						: "The number of people with experience in each function and specialism as their previous roles."
				}
				setIsDefaultChart={setIsDefaultChart}
				isLoading={jobFunctionDistributionInProgress}
			/>

			<BackgroundAnalysisContainer
				data={industryBackgroundData}
				title="Industry Background"
				currentOption={currentIndustryOption}
				setCurrentOption={setCurrentIndustryOption}
				isDefaultChart={isDefaultChart}
				setIsDefaultChart={setIsDefaultChart}
				isLoading={industryBackgroundReportInProgress}
				description={
					currentIndustryOption
						? "The number of people with experience in each industry."
						: "The average time people have spent within each industry."
				}
			/>
		</div>
	);
};

export default JobFunctionDistributionChart;
