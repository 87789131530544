import classNames from "classnames";
import {Loader, UpgradeNowTooltip} from "components";
import React, {ReactNode} from "react";
import css from "./actionAltButton.module.scss";

interface ActionAltButtonProps {
	handleClick: (isClicked: boolean) => void;
	isClicked?: boolean | null;
	customClass?: string | object;
	title?: string;
	loading?: boolean;
	iconComponent?: ReactNode | null;
	disabled?: boolean;
	showUpgradeTooltip?: boolean;
	tooltipIdentifier?: string;
}

const ActionAltButton: React.FC<ActionAltButtonProps> = ({
	handleClick,
	isClicked = null,
	customClass = {},
	title = "Save",
	loading = false,
	iconComponent = null,
	disabled = false,
	showUpgradeTooltip = false,
	tooltipIdentifier = "default",
}) => {
	const onClick = () => {
		if (!loading && !disabled) {
			handleClick(!isClicked);
		}
	};

	return (
		<>
			<div
				className={classNames({
					[css.buttonContainer]: true,
					[css.disabled]: loading || disabled,
					[customClass as string]: customClass,
				})}
				onClick={onClick}
				data-tooltip-id={tooltipIdentifier}
			>
				{loading ? (
					<>
						<Loader
							height={16}
							width={16}
							customStyle={{paddingLeft: "10px"}}
							type="TailSpin"
							color={"#999"}
						/>
						{title}
					</>
				) : (
					<>
						{iconComponent !== null && iconComponent}
						{title}
					</>
				)}

				{showUpgradeTooltip && <UpgradeNowTooltip id={tooltipIdentifier} />}
			</div>
		</>
	);
};

export default ActionAltButton;
