import type {FC} from "react";
import classNames from "classnames";

import {Button} from "components";
import {OnboardingTooltip} from "mapx-components";
import {ArrowDown, PersonAiIcon} from "assets/icons";
import {EOnboardingTooltipSteps} from "mapx-components/OnboardingTooltip/types";

import type {TStepBoardingType} from "../../types";
import {projectCandidateFakeCardData} from "../../utils";

import FakeFilter from "./FakeFilter";
import ProjectCandidateFakeCard from "./ProjectCandidateFakeCard";
import styles from "./FakeResults.module.scss";

const FakeResults: FC<TStepBoardingType> = ({step, userLicenseTypeName}) => (
	<div className={styles.body}>
		<FakeFilter step={step} />

		<div className={styles.body__container}>
			<p className={styles.body__container__label}>Googles CTO</p>

			<div className={styles.body__container__controllers}>
				<Button
					defaultBtnStyle
					LeftIcon={ArrowDown}
					className={classNames(
						styles.body__container__controllers__expend,
						styles.body__container__controllers_btn,
					)}
				>
					Expand
				</Button>

				<div className={styles.body__container__controllers__get_more}>
					<Button
						defaultBtnStyle
						LeftIcon={PersonAiIcon}
						className={classNames(
							styles.body__container__controllers__get_more__btn,
							styles.body__container__controllers_btn,
							{
								[styles.body__container__controllers__get_more__btn_z_index]:
									step === EOnboardingTooltipSteps.Second,
							},
						)}
					>
						Generate More Results
					</Button>

					<OnboardingTooltip
						step={step === EOnboardingTooltipSteps.Second ? step : null}
						className={classNames(
							styles.body__container__controllers__get_more__boarding,
							{
								[styles.body__container__controllers__get_more__boarding_starter]:
									userLicenseTypeName === "Plus",
							},
						)}
					/>
				</div>
			</div>

			<div className={styles.body__container__wrapper}>
				{projectCandidateFakeCardData?.map((item, idx) => (
					<ProjectCandidateFakeCard
						idx={idx}
						step={step}
						id={item.id}
						key={item.id}
						name={item?.name}
						details={item?.details}
						country={item?.country}
						avatar_url={item?.avatar_url}
						companyName={item?.companyName}
						company_avatar={item?.company_avatar}
					/>
				))}
			</div>
		</div>
	</div>
);

export default FakeResults;
