import React from "react";

const SummaryViewIcon = () => {
	return (
		<svg
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<mask
				id="mask0_1733_174925"
				style={{maskType: "alpha"}}
				maskUnits="userSpaceOnUse"
				x="0"
				y="0"
				width="20"
				height="20"
			>
				<rect width="20" height="20" fill="#D9D9D9" />
			</mask>
			<g mask="url(#mask0_1733_174925)">
				<path
					d="M6.74563 7.5C6.95688 7.5 7.13542 7.42854 7.28125 7.28563C7.42708 7.14271 7.5 6.96563 7.5 6.75438C7.5 6.54313 7.42854 6.36458 7.28562 6.21875C7.14271 6.07292 6.96563 6 6.75437 6C6.54312 6 6.36458 6.07146 6.21875 6.21437C6.07292 6.35729 6 6.53437 6 6.74562C6 6.95687 6.07146 7.13542 6.21438 7.28125C6.35729 7.42708 6.53438 7.5 6.74563 7.5ZM6.74563 10.75C6.95688 10.75 7.13542 10.6785 7.28125 10.5356C7.42708 10.3927 7.5 10.2156 7.5 10.0044C7.5 9.79313 7.42854 9.61458 7.28562 9.46875C7.14271 9.32292 6.96563 9.25 6.75437 9.25C6.54312 9.25 6.36458 9.32146 6.21875 9.46438C6.07292 9.60729 6 9.78438 6 9.99563C6 10.2069 6.07146 10.3854 6.21438 10.5312C6.35729 10.6771 6.53438 10.75 6.74563 10.75ZM6.74563 14C6.95688 14 7.13542 13.9285 7.28125 13.7856C7.42708 13.6427 7.5 13.4656 7.5 13.2544C7.5 13.0431 7.42854 12.8646 7.28562 12.7188C7.14271 12.5729 6.96563 12.5 6.75437 12.5C6.54312 12.5 6.36458 12.5715 6.21875 12.7144C6.07292 12.8573 6 13.0344 6 13.2456C6 13.4569 6.07146 13.6354 6.21438 13.7812C6.35729 13.9271 6.53438 14 6.74563 14ZM4.5 17C4.0875 17 3.73437 16.8531 3.44062 16.5594C3.14687 16.2656 3 15.9125 3 15.5V4.5C3 4.0875 3.14687 3.73438 3.44062 3.44063C3.73437 3.14688 4.0875 3 4.5 3H13L17 7V15.5C17 15.9125 16.8531 16.2656 16.5594 16.5594C16.2656 16.8531 15.9125 17 15.5 17H4.5ZM4.5 15.5H15.5V8H12V4.5H4.5V15.5Z"
					fill="#B0B5B4"
				/>
			</g>
		</svg>
	);
};

export default SummaryViewIcon;
