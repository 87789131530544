import {useEffect} from "react";
import {AutoComplete} from "mapx-components";
import {CompanyItem, ResetFilters, TabSelect} from "components";
import Tags from "./Tags";
import {CompanyFilterWithTlProps} from "mapx-pages/Project/ProjectCandidatesFilter/CompanyFilter/types";

const CompanySearchFilterWithTL = ({
	searchTerm,
	setSearchTerm,
	loading,
	position,
	handlePositionChange,
	searchedCompanies,
	companiesCount,
	handleItemClick,
	handleResetClick,
	searchCompanies,
	handleTagClick,
	tagCompanies,
}: CompanyFilterWithTlProps) => {
	useEffect(() => {
		searchCompanies(searchTerm);
	}, [searchTerm, searchCompanies]);

	const positionOptions = [
		{label: "Current", value: "current"},
		{label: "Previous", value: "previous"},
		{label: "Any", value: "any"},
	];

	return (
		<div>
			<TabSelect.LabelContainer label="Position">
				<TabSelect
					selected={position}
					onTabChange={handlePositionChange}
					options={positionOptions}
				/>
			</TabSelect.LabelContainer>

			<AutoComplete
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				suggestions={searchedCompanies}
				handleItemClick={handleItemClick}
				handleFilterChange={setSearchTerm}
				isLoading={loading}
				notFoundResult="No company found"
				SuggestionListItemComponent={CompanyItem}
			/>

			{companiesCount > 0 && (
				<ResetFilters
					parentStyle={{color: "#5A5A5A", marginRight: 19}}
					onClick={handleResetClick}
					displayIcon={true}
				>
					Clear Selection
				</ResetFilters>
			)}

			<Tags handleClick={handleTagClick} tagCompanies={tagCompanies} />
		</div>
	);
};

export default CompanySearchFilterWithTL;
