import {type FC, useEffect} from "react";
import {Redirect, useParams} from "react-router-dom";
import {useSelector} from "react-redux";

import {MainWrapper} from "mapx-components";
import {isSuperUserSelector} from "store/mapx/user/userSelectors";
import {pricingTableModal} from "store/mapx/payment/paymentActions";
import {useAppDispatch} from "hooks";

import OrganisationInfo from "./OrganisationInfo";
import UserList from "./UserList";

const Organisation: FC = () => {
	const dispatch = useAppDispatch();

	const {modal} = useParams<{modal: "payment-successful" | "pricing"}>();

	const isSuperUser = useSelector(isSuperUserSelector);

	useEffect(() => {
		if (modal === "pricing") {
			dispatch(pricingTableModal(true));
		}
	}, [dispatch, modal]);

	if (isSuperUser) {
		return <Redirect to="/403" />;
	}

	return (
		<MainWrapper>
			<OrganisationInfo />

			<UserList />
		</MainWrapper>
	);
};

export default Organisation;
