import React from "react";
import styles from "mapx-components/Filters/CompanySearchFilter/styles.module.scss";
import {Tag} from "components";
import {TSelectedCompaniesTagProps} from "mapx-components/Filters/CompanySearchFilter/SelectedCompaniesTag/types";

const SelectedCompaniesTag = ({
	selectedCompanies,
	handleRemoveClick,
}: TSelectedCompaniesTagProps) => {
	return (
		<div className={styles.tagsWrapper}>
			{selectedCompanies.map((company) => (
				<Tag
					key={company.id}
					onClick={() => {
						handleRemoveClick(company.id);
					}}
				>
					{company.name}
				</Tag>
			))}
		</div>
	);
};

export default SelectedCompaniesTag;
