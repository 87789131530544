import HttpClient from "api/index";

class MapxCountryApi extends HttpClient {
	async getCountries(config = {}) {
		return this.doGet("/mapx/countries", config);
	}
}

const mapxCountryApi = new MapxCountryApi();

export default mapxCountryApi;
