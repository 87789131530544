import React, {type FC, useMemo} from "react";

import {useAppSelector, useOrgChartFilters} from "hooks";
import {companiesOrgChartFiltersSelector} from "store/mapx/company/companySelectors";
import classNames from "classnames";
import styles from "mapx-pages/Project/SavedPeople/FilterSection/styles.module.scss";
import CompanyOrgChartFilter from "../Filters/index";

const FiltersRow: FC = () => {
	const {onSelectChange, resetFilters} = useOrgChartFilters();

	const filtersInState = useAppSelector(companiesOrgChartFiltersSelector);

	const hasActiveFilter = useMemo(() => {
		return Object.values(filtersInState).some(
			(filter) => Array.isArray(filter) && filter.length !== 0,
		);
	}, [filtersInState]);

	return (
		<CompanyOrgChartFilter
			onSelectChange={onSelectChange}
			className={classNames(styles.filterButton, {
				[styles.hasFilter]: hasActiveFilter,
			})}
			titleClass={styles.filterButtonTitle}
			shouldDisplayResetFilter={hasActiveFilter}
			handleReset={resetFilters}
		/>
	);
};

export default FiltersRow;
