import type {FC} from "react";

import type {IconProps} from "./types";

const TimeSavedIcon: FC<IconProps> = ({className = ""}) => (
	<svg
		className={className}
		width="32"
		height="32"
		fill="none"
		viewBox="0 0 32 32"
		xmlns="http://www.w3.org/2000/svg"
	>
		<mask
			x="0"
			y="0"
			width="32"
			height="32"
			id="mask0_306_9133"
			maskUnits="userSpaceOnUse"
			style={{maskType: "alpha"}}
		>
			<rect width="32" height="32" fill="#D9D9D9" />
		</mask>
		<g mask="url(#mask0_306_9133)">
			<path
				fill="#383838"
				d="M20.3976 22.2668L22.2643 20.4001L17.331 15.4668V9.33342H14.6643V16.5334L20.3976 22.2668ZM15.9976 29.3334C14.1532 29.3334 12.4199 28.9832 10.7976 28.2828C9.17542 27.5832 7.76431 26.6334 6.56431 25.4334C5.36431 24.2334 4.41453 22.8223 3.71497 21.2001C3.01453 19.5779 2.66431 17.8445 2.66431 16.0001C2.66431 14.1556 3.01453 12.4223 3.71497 10.8001C4.41453 9.17786 5.36431 7.76675 6.56431 6.56675C7.76431 5.36675 9.17542 4.41653 10.7976 3.71608C12.4199 3.01653 14.1532 2.66675 15.9976 2.66675C17.8421 2.66675 19.5754 3.01653 21.1976 3.71608C22.8199 4.41653 24.231 5.36675 25.431 6.56675C26.631 7.76675 27.5808 9.17786 28.2803 10.8001C28.9808 12.4223 29.331 14.1556 29.331 16.0001C29.331 17.8445 28.9808 19.5779 28.2803 21.2001C27.5808 22.8223 26.631 24.2334 25.431 25.4334C24.231 26.6334 22.8199 27.5832 21.1976 28.2828C19.5754 28.9832 17.8421 29.3334 15.9976 29.3334ZM15.9976 26.6668C18.9532 26.6668 21.4701 25.6281 23.5483 23.5508C25.6256 21.4725 26.6643 18.9556 26.6643 16.0001C26.6643 13.0445 25.6256 10.5276 23.5483 8.44942C21.4701 6.37208 18.9532 5.33342 15.9976 5.33342C13.0421 5.33342 10.5256 6.37208 8.44831 8.44942C6.37008 10.5276 5.33097 13.0445 5.33097 16.0001C5.33097 18.9556 6.37008 21.4725 8.44831 23.5508C10.5256 25.6281 13.0421 26.6668 15.9976 26.6668Z"
			/>
		</g>
	</svg>
);

export default TimeSavedIcon;
