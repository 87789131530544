import {useAppDispatch, useAppSelector} from "hooks";
import {useCallback} from "react";
import {filterPositionOptionsSelector} from "store/mapx/filter/filterSelectors";
import {TabSelect} from "components";
import CompanyTypeSearchFilter from "mapx-components/Filters/CandidateCompanyTypeSearchFilter";
import {TCompanyTypePosition} from "mapx-components/Filters/CandidateCompanyTypeSearchFilter/types";
import type {TKeywordPosition} from "api/filterOptionsApi/KeywordApi/types";
import {
	apAllCompanyTypesCountSelector,
	apCandidateCompanyTypesByPositionSelector,
	apCompanyTypeCandidatePositionSelector,
	apCompanyTypeFilterConfigSelector,
	apSelectedAnyCompanyTypesSelector,
	apSelectedCurrentCompanyTypesSelector,
	apSelectedPreviousCompanyTypesSelector,
} from "store/mapx/additional-profiles/apCompanyTypeFilterSelectors";
import {
	setAPCompanyTypeFilterConfig,
	setAPMultipleFilters,
} from "store/mapx/additional-profiles/additionalProfilesActions";
import {
	apClearCompanyTypeByPositionForCandidate,
	apMoveCompanyTypeForCandidate,
	apSetCompanyTypeForCandidate,
} from "store/mapx/additional-profiles/apCompanyTypeFilterAsyncActions";

function APCompanyTypeFilter() {
	const dispatch = useAppDispatch();

	const filterConfig = useAppSelector(apCompanyTypeFilterConfigSelector);

	const position = useAppSelector<TCompanyTypePosition>(apCompanyTypeCandidatePositionSelector);

	const filterPositionOptions = useAppSelector(filterPositionOptionsSelector);

	const count = useAppSelector(apAllCompanyTypesCountSelector);

	const anyTags = useAppSelector(apSelectedAnyCompanyTypesSelector);

	const currentTags = useAppSelector(apSelectedCurrentCompanyTypesSelector);

	const previousTags = useAppSelector(apSelectedPreviousCompanyTypesSelector);

	const selectedItems = useAppSelector((state) =>
		apCandidateCompanyTypesByPositionSelector(state, position),
	);

	const handlePositionChange = useCallback(
		(updatedPositionValue: string) => {
			dispatch(
				setAPCompanyTypeFilterConfig({
					...filterConfig,
					active_position: updatedPositionValue,
				}),
			);
		},
		[dispatch, filterConfig],
	);

	const handleChange = useCallback(
		(id: string) => {
			dispatch(apSetCompanyTypeForCandidate({id, position}));
		},
		[dispatch, position],
	);

	const moveTagPosition = useCallback(
		(value: string, fromPosition: TCompanyTypePosition, toPosition: TCompanyTypePosition) => {
			dispatch(
				apMoveCompanyTypeForCandidate({
					from: fromPosition,
					to: toPosition,
					id: value,
				}),
			);
		},
		[dispatch],
	);

	const handleTagClick = useCallback(
		(id: string, position: TKeywordPosition) => {
			dispatch(
				apSetCompanyTypeForCandidate({
					position,
					id,
				}),
			);
		},
		[dispatch],
	);

	const handleResetClick = useCallback(() => {
		dispatch(
			setAPMultipleFilters({
				company_type: [],
				current_company_type: [],
				previous_company_type: [],
			}),
		);
	}, [dispatch]);

	const handleClearTagClickByPosition = useCallback(
		(position: TCompanyTypePosition) => {
			dispatch(apClearCompanyTypeByPositionForCandidate(position));
		},
		[dispatch],
	);

	return (
		<div>
			<TabSelect.LabelContainer label="Position">
				<TabSelect
					selected={position}
					onTabChange={handlePositionChange}
					options={filterPositionOptions}
				/>
			</TabSelect.LabelContainer>

			<CompanyTypeSearchFilter
				selectedCompanyTypes={selectedItems}
				handleResetClick={handleResetClick}
				handleOnChange={handleChange}
				handleTagClick={handleTagClick}
				displayResetFilterOption={count > 0}
				moveCompanyTypeTagPosition={moveTagPosition}
				selectedCompanyTypeTags={{
					current: currentTags,
					previous: previousTags,
					any: anyTags,
				}}
				clearTagsByPosition={handleClearTagClickByPosition}
			/>
		</div>
	);
}

export default APCompanyTypeFilter;
