import React from "react";

const SavePlusIcon = ({color = "#0C5850"}) => {
	return (
		<>
			<svg
				width="16"
				height="16"
				viewBox="0 0 16 16"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<g clipPath="url(#clip0_2959_11599)">
					<path
						d="M7.99967 14.6667C11.6816 14.6667 14.6663 11.6819 14.6663 8.00004C14.6663 4.31814 11.6816 1.33337 7.99967 1.33337C4.31778 1.33337 1.33301 4.31814 1.33301 8.00004C1.33301 11.6819 4.31778 14.6667 7.99967 14.6667Z"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						d="M8 5.33337V10.6667"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						d="M5.33301 8H10.6663"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</g>
				<defs>
					<clipPath id="clip0_2959_11599">
						<rect width="16" height="16" fill="white" />
					</clipPath>
				</defs>
			</svg>
		</>
	);
};

export default SavePlusIcon;
