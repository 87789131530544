import {MouseEvent, useEffect, useState} from "react";
import {BiUser} from "react-icons/bi";

import css from "./similarPeopleCard.module.scss";
import {useSelector} from "react-redux";
import {Loader} from "components";
import {getSimilarCandidatesData} from "store/mapx/candidate/candidateAsyncActions";
import placeHolderImage from "assets/images/avatar-icon.png";
import colors from "styles/themes.module.scss";
import {useAppDispatch, useOpenLink} from "hooks";
import {SICandidate} from "api/candidateApi/types";
import {similarCandidatesSelector} from "store/mapx/candidate/candidateSelectors";

export default function SimilarPeopleCard({candidate}: {candidate: SICandidate}) {
	const dispatch = useAppDispatch();
	const [loading, setLoading] = useState(false);
	const openLink = useOpenLink();

	const similarCandidates = useSelector(similarCandidatesSelector);

	useEffect(() => {
		async function get() {
			setLoading(true);
			await dispatch(getSimilarCandidatesData(`?candidate_id=${candidate.id}`, true));
			setLoading(false);
		}

		get().then((r) => r);
	}, [candidate.id, dispatch]);

	const handleItemClick = (event: MouseEvent<HTMLElement | SVGElement>, candidateId: number) => {
		const link = `/candidate/${candidateId}?recommended=true`;
		openLink(event, link);
	};

	const InlineLoaderComponent = (
		<Loader
			displayAtCenterOfPage={false}
			type={"ThreeDots"}
			height={30}
			width={30}
			color={colors.loaderDotColor}
		/>
	);

	return similarCandidates.length > 0 ? (
		<div className={css.similarPeopleCard}>
			<div className={css.title}>Similar People</div>

			<div className={css.similarPeopleList}>
				{loading && InlineLoaderComponent}

				{!loading &&
					[...similarCandidates].map((similar: SICandidate, i) => {
						const exp = similar.positions || [];
						let lastPosition;
						let previousPosition;

						if (exp && Array.isArray(exp)) {
							lastPosition = exp[0];
							previousPosition = exp.find((exp) => exp.start_date && exp.end_date);
						}

						return (
							<div key={i} className={css.informationContainer}>
								<div className={css.infoFirstRow}>
									<div className={css.cardGrid}>
										{similar.avatar_url !== null ? (
											<img
												alt={candidate && candidate.name}
												src={similar.avatar_url}
												onError={(e) => {
													e.currentTarget.src = placeHolderImage;
												}}
												className={css.avatar}
												onClick={(e) => handleItemClick(e, similar.id)}
											/>
										) : (
											<BiUser
												className={css.logo}
												onClick={(e) => handleItemClick(e, similar.id)}
											/>
										)}

										<div>
											<div
												className={css.infoTitle}
												onClick={(e) => handleItemClick(e, similar.id)}
											>
												<span className={css.candidateName}>
													{similar?.full_name || similar?.name}
												</span>
												<span className={css.country}>
													{similar?.country?.name}
												</span>
											</div>

											<div className={css.positions}>
												<a
													href={`/company/${lastPosition?.company?.id}`}
													target="_blank"
													rel="noreferrer"
												>
													<img
														src={
															lastPosition?.company?.logo_url ||
															"https://storage.googleapis.com/nebula-static/logos100x100/empty_logo.png"
														}
														alt="company"
														className={css.companyLogo}
													/>
													<b className={css.companyName}>
														{lastPosition?.company?.name}
													</b>
												</a>

												<span className={css.companyPosition}>
													{lastPosition?.job_title}
												</span>
											</div>
										</div>
									</div>

									{previousPosition && (
										<div className={css.previous}>
											Previously&nbsp;
											<a
												href={`/company/${previousPosition?.company?.id}`}
												target="_blank"
												rel="noreferrer"
											>
												<b>{previousPosition.company.name}</b>
											</a>
											<span className={css.previousTitle}>
												{previousPosition.job_title} (
												{new Date(
													previousPosition.start_date,
												).getFullYear()}{" "}
												-{" "}
												{previousPosition.end_date
													? new Date(
															previousPosition.end_date,
													  ).getFullYear()
													: ""}
												)
											</span>
										</div>
									)}
								</div>
							</div>
						);
					})}
			</div>
		</div>
	) : (
		<div />
	);
}
