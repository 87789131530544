import {useAppDispatch, useAppSelector} from "hooks";
import {Accordion} from "mapx-components";
import {useCallback} from "react";
import {setMultipleFilterForCompany} from "store/mapx/filter/filterActions";
import {companyTypesSelector} from "store/mapx/filter/companyTypeFilterSelectors";
import {setCompanyTypeFilter} from "store/mapx/filter/companyTypeFilterAsyncActions";
import CompanyTypeSearchFilter from "mapx-components/Filters/CompanyTypeSearchFilter";

function CompanyTypeFilter() {
	const dispatch = useAppDispatch();

	const selectedItems = useAppSelector(companyTypesSelector);

	const handleChange = useCallback(
		(value: string) => {
			dispatch(setCompanyTypeFilter({value}));
		},
		[dispatch],
	);

	const handleResetClick = useCallback(() => {
		dispatch(
			setMultipleFilterForCompany({
				company_type: [],
			}),
		);
	}, [dispatch]);

	return (
		<Accordion title="Company Type" subtitle={selectedItems?.length}>
			<CompanyTypeSearchFilter
				selectedCompanyTypes={selectedItems}
				handleResetClick={handleResetClick}
				handleOnChange={handleChange}
				displayResetFilterOption={selectedItems?.length > 0}
			/>
		</Accordion>
	);
}

export default CompanyTypeFilter;
