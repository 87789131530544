import {useCallback} from "react";
import {Accordion} from "mapx-components";
import {
	setMultipleFilterForCandidates,
	setPeopleSearchCompanyHeadcountFilterConfig,
} from "store/mapx/filter/filterActions";
import {useAppDispatch, useAppSelector} from "hooks";
import {filterPositionOptionsSelector} from "store/mapx/filter/filterSelectors";
import {
	TCompanyHeadcountPosition,
	TCompanyHeadcountRangeItem,
} from "mapx-components/Filters/CompanyHeadcountSearchFilter/types";
import CompanyHeadcountSearchFilter from "mapx-components/Filters/CompanyHeadcountSearchFilter";
import {TabSelect} from "components";
import {THeadcountFilterProps} from "containers/Filters/PeopleFilters/HeadcountFilter/types";
import {
	candidateCompanyHeadcountByPositionSelector,
	candidateCompanyHeadcountFilterActivePositionSelector,
	candidateCompanyHeadcountFilterConfigSelector,
	candidatesAllCompanyHeadcountCountSelector,
	selectedAnyCompanyHeadcountTagsSelector,
	selectedCurrentCompanyHeadcountTagsSelector,
	selectedPreviousCompanyHeadcountTagsSelector,
} from "store/mapx/filter/companyHeadcountFilterSelectors";
import {
	moveHeadcountForCandidate,
	setCompanyHeadcountForCandidate,
} from "store/mapx/filter/companyHeadcountFilterAsyncActions";
import Tags from "mapx-components/Filters/CompanyHeadcountSearchFilter/Tags";

const HeadcountFilter = ({disabled}: THeadcountFilterProps) => {
	const dispatch = useAppDispatch();

	const filterPositionOptions = useAppSelector(filterPositionOptionsSelector);

	const candidateCompanyHeadcountFilterConfig = useAppSelector(
		candidateCompanyHeadcountFilterConfigSelector,
	);

	const position = useAppSelector<TCompanyHeadcountPosition>(
		candidateCompanyHeadcountFilterActivePositionSelector,
	);

	const selectedHeadcounts = useAppSelector((state) =>
		candidateCompanyHeadcountByPositionSelector(state, position),
	);

	const currentHeadcountTags = useAppSelector(selectedCurrentCompanyHeadcountTagsSelector);

	const previousHeadcountTags = useAppSelector(selectedPreviousCompanyHeadcountTagsSelector);

	const anyHeadcountTags = useAppSelector(selectedAnyCompanyHeadcountTagsSelector);

	const headcountCount = useAppSelector(candidatesAllCompanyHeadcountCountSelector);

	const handlePositionChange = useCallback(
		(updatedPositionValue: string) => {
			dispatch(
				setPeopleSearchCompanyHeadcountFilterConfig({
					...candidateCompanyHeadcountFilterConfig,
					active_position: updatedPositionValue,
				}),
			);
		},
		[dispatch, candidateCompanyHeadcountFilterConfig],
	);

	const handleResetClick = useCallback(() => {
		dispatch(
			setMultipleFilterForCandidates({
				company_size: [],
				current_company_size: [],
				previous_company_size: [],
			}),
		);
	}, [dispatch]);

	const clearTagsSelectionByPosition = useCallback(
		(position: TCompanyHeadcountPosition) => {
			if (position === "current") {
				dispatch(
					setMultipleFilterForCandidates({
						current_company_size: [],
					}),
				);
			} else if (position === "previous") {
				dispatch(
					setMultipleFilterForCandidates({
						previous_company_size: [],
					}),
				);
			} else {
				dispatch(
					setMultipleFilterForCandidates({
						company_size: [],
					}),
				);
			}
		},
		[dispatch],
	);

	const handleOnChange = useCallback(
		(headcountItem: TCompanyHeadcountRangeItem) => {
			dispatch(setCompanyHeadcountForCandidate({position, headcountItem}));
		},
		[dispatch, position],
	);

	const moveHeadcountTagPosition = useCallback(
		(id: string, from: TCompanyHeadcountPosition, to: TCompanyHeadcountPosition) => {
			dispatch(
				moveHeadcountForCandidate({
					from,
					to,
					ids: [id],
				}),
			);
		},
		[dispatch],
	);

	return (
		<Accordion title="Company Headcount" subtitle={headcountCount ?? ""} disabled={disabled}>
			<TabSelect.LabelContainer label="Position">
				<TabSelect
					selected={position}
					onTabChange={handlePositionChange}
					options={filterPositionOptions}
				/>
			</TabSelect.LabelContainer>

			<CompanyHeadcountSearchFilter
				handleOnChange={handleOnChange}
				handleResetClick={handleResetClick}
				selectedHeadcounts={selectedHeadcounts}
			/>

			{headcountCount > 0 && (
				<>
					<Tags
						tagLabel={"Current"}
						tagValue={"current"}
						tags={currentHeadcountTags}
						handleTagClick={handleOnChange}
						clearTagsByPosition={clearTagsSelectionByPosition}
						moveHeadcountTagPosition={moveHeadcountTagPosition}
					/>

					<Tags
						tagLabel={"Previous"}
						tagValue={"previous"}
						tags={previousHeadcountTags}
						handleTagClick={handleOnChange}
						clearTagsByPosition={clearTagsSelectionByPosition}
						moveHeadcountTagPosition={moveHeadcountTagPosition}
					/>

					<Tags
						tagLabel={"Any"}
						tagValue={"any"}
						tags={anyHeadcountTags}
						handleTagClick={handleOnChange}
						clearTagsByPosition={clearTagsSelectionByPosition}
						moveHeadcountTagPosition={moveHeadcountTagPosition}
					/>
				</>
			)}
		</Accordion>
	);
};

export default HeadcountFilter;
