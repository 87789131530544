import {useMemo} from "react";
import {SearchInput} from "mapx-components";

import css from "./seniorityFilter.module.scss";
import useFetchListOptions from "hooks/useFetchListOptions";
import {Checkbox, CheckboxSkeletonLoader, ResetFilters} from "components";
import {getName} from "helpers/string";
import CheckboxList from "mapx-components/Inputs/CheckboxList";
import {useAppSelector} from "hooks";
import {TSeniorityItem, type TSenioritySearchFilterProps} from "./types";
import {getSeniorityList} from "store/mapx/search/searchAsyncActions";
import {userLicenseTypeNameSelector} from "store/mapx/user/userSelectors";
import {seniorityOptionSelector} from "store/mapx/search/searchSelectors";

export default function OrgChartSenioritySearchFilter({
	handleResetClick,
	handleOnChange,
	selectedSeniority = [],
}: TSenioritySearchFilterProps) {
	const seniorityOptions = useAppSelector(seniorityOptionSelector);

	const userLicenseTypeName = useAppSelector(userLicenseTypeNameSelector);

	const {filteredData, onFilterChanged, loading} = useFetchListOptions({
		options: seniorityOptions,
		callbackMethod: getSeniorityList,
	});

	const lockFilterForFreemium = useMemo(
		() => userLicenseTypeName === "Starter",
		[userLicenseTypeName],
	);

	return (
		<div>
			<SearchInput
				onChange={onFilterChanged}
				placeholder="Search for a Seniority"
				type="text"
				errorText={undefined}
				errorClass={undefined}
				isLoading={loading}
			/>

			<ResetFilters
				parentStyle={{color: "#5A5A5A", marginRight: 19}}
				onClick={handleResetClick}
				displayIcon={true}
			>
				Clear Selection
			</ResetFilters>

			<CheckboxList data-testid={"seniorityList"}>
				{filteredData.map((item: TSeniorityItem) => (
					<Checkbox
						borderColor="#0C5850"
						containerClass={css.checkboxContainer}
						isChecked={selectedSeniority.includes(item.id)}
						key={item.id}
						label={getName(item.name)}
						onChange={(e) =>
							handleOnChange("current_seniority", item.id, e.target.checked)
						}
						value={getName(item.name)}
						disabled={item?.premium && lockFilterForFreemium}
						isPremiumOption={item?.premium && lockFilterForFreemium}
					/>
				))}
			</CheckboxList>

			{loading && <CheckboxSkeletonLoader />}
		</div>
	);
}
