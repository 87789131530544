import React, {FC, useEffect, useState} from "react";
import classNames from "classnames";
import styles from "./styles.module.scss";
import {InfoCard, LinkedInIcon} from "assets/icons";
import {SkeletonLoaderProps} from "./types";
import {useSelector} from "react-redux";
import {importLinkedInProfileInfoSelector} from "store/mapx/candidate/candidateSelectors";

const CandidatesSkeletonLoader: FC<SkeletonLoaderProps> = ({count = 5, isLinkedIn = false}) => {
	const importLinkedInProfileInfo = useSelector(importLinkedInProfileInfoSelector);

	const [message, setMessage] = useState("Loading profiles");

	useEffect(() => {
		if (isLinkedIn && importLinkedInProfileInfo !== null) {
			setMessage(`Importing ${importLinkedInProfileInfo.linkedin_url} profile`);
		}
	}, [isLinkedIn, importLinkedInProfileInfo]);

	return (
		<div className={classNames(styles.skeletonContainer)} data-tooltip={message}>
			{Array.from({length: count}, (_, idx) => (
				<div
					key={idx}
					className={classNames(styles.skeletonContainer__card, {
						[styles.linkedIn]: isLinkedIn,
					})}
				>
					{isLinkedIn && <LinkedInIcon className={styles.linkedInIcon} />}
					<InfoCard />
				</div>
			))}
		</div>
	);
};

export default CandidatesSkeletonLoader;
