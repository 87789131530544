import {Checkbox, ResetFilters} from "components";
import CheckboxList from "mapx-components/Inputs/CheckboxList";
import css from "containers/Filters/CompanyFilters/IndexFilter/companyIndexFilter.module.scss";
import {useAppSelector} from "hooks";
import {companyRevenueRangeOptionsSelector} from "store/mapx/search/searchSelectors";
import useFetchListOptions from "hooks/useFetchListOptions";
import {getCompanyRevenueRanges} from "store/mapx/search/searchAsyncActions";
import {
	TCompanyHeadcountSearchFilterProps,
	TCompanyRevenueRangeItem,
} from "mapx-components/Filters/CompanyRevenueSearchFilter/types";

const CompanyRevenueSearchFilter = ({
	handleResetClick,
	handleOnChange,
	selectedRevenues,
}: TCompanyHeadcountSearchFilterProps) => {
	const companyRevenueRanges = useAppSelector(companyRevenueRangeOptionsSelector);

	const {filteredData} = useFetchListOptions({
		options: companyRevenueRanges,
		callbackMethod: getCompanyRevenueRanges,
	});

	return (
		<div>
			<ResetFilters
				parentStyle={{color: "#5A5A5A", marginRight: 19, marginTop: 10}}
				onClick={handleResetClick}
				displayIcon={true}
			>
				Clear Selection
			</ResetFilters>

			<CheckboxList style={{maxHeight: 110}}>
				{filteredData.map((item: TCompanyRevenueRangeItem) => (
					<Checkbox
						borderColor="#0C5850"
						containerClass={css.checkboxContainer}
						isChecked={selectedRevenues?.includes(item.id)}
						key={item.id}
						label={item.name}
						onChange={() => handleOnChange(item)}
						value={item.id}
					/>
				))}
			</CheckboxList>
		</div>
	);
};

export default CompanyRevenueSearchFilter;
