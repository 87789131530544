import type {IconProps} from "./types";

const ChevronRightIcon = ({color = "#CBC5C2", ...rest}: IconProps) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" {...rest}>
		<mask
			id="a"
			width={20}
			height={20}
			x={0}
			y={0}
			maskUnits="userSpaceOnUse"
			style={{
				maskType: "alpha",
			}}
		>
			<path fill="#D9D9D9" d="M0 0h20v20H0z" />
		</mask>
		<g mask="url(#a)">
			<path
				fill={color}
				d="M7.833 15.167 6.5 13.833 10.333 10 6.5 6.167l1.333-1.334L13 10l-5.167 5.167Z"
			/>
		</g>
	</svg>
);

export default ChevronRightIcon;
