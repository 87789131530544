import ContentLoader from "react-content-loader";

const RoundChartSkeleton = () => (
	<ContentLoader
		speed={2}
		height={210}
		width={"100%"}
		backgroundColor="#DCE7E7"
		foregroundColor="#F9F9F9"
	>
		<circle cx="120" cy="63" r="60" />
		<rect x="30" y="130" rx="4" width="70%" height="25" />
		<rect x="30" y="175" rx="4" width="70%" height="20" />
	</ContentLoader>
);

export default RoundChartSkeleton;
