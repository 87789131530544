import {FaChevronDown, FaChevronUp} from "react-icons/fa";
import styles from "./styles.module.scss";
import {CheckboxDropdownSectionProps} from "./types";

const DropdownCheckbox = ({
	label,
	isActive,
	toggleActive,
	selectedValues,
	children,
	count,
}: CheckboxDropdownSectionProps) => {
	return (
		<div className={styles.dropdownInputWrapper}>
			<label>{label}</label>
			<section onClick={toggleActive}>
				<h3 className={styles.truncatedContainer}>
					<span className={styles.truncatedText}>{selectedValues.join(", ")}</span>
					{count && count > 0 ? (
						<span className={styles.numberIndicator}>{count}</span>
					) : (
						<span className={styles.titleText}>Select {label.toLowerCase()}</span>
					)}
				</h3>
				{isActive ? <FaChevronUp /> : <FaChevronDown />}
			</section>
			{isActive && <div>{children}</div>}
		</div>
	);
};

export default DropdownCheckbox;
