import React, {useCallback, useMemo} from "react";
import {useSelector} from "react-redux";
import {
	keywordPaginationSelector,
	keywordSearchQuerySelector,
} from "store/mapx/search/searchSelectors";
import {useAppDispatch, useAppSelector} from "hooks";
import {
	TKeywordPosition,
	TKeywordPositionConnectivityLogic,
} from "api/filterOptionsApi/KeywordApi/types";
import {filterPositionOptionsSelector} from "store/mapx/filter/filterSelectors";
import {
	allAPKeywordsCountSelector,
	apAnyKeywordTagsSelector,
	apCurrentKeywordTagsSelector,
	apKeywordAnyPositionConnectivityLogicSelector,
	apKeywordCandidatePositionSelector,
	apKeywordCurrentPositionConnectivityLogicSelector,
	apKeywordFilterConfigSelector,
	apKeywordPreviousPositionConnectivityLogicSelector,
	apKeywordsByPositionSelector,
	apPreviousKeywordTagsSelector,
} from "store/mapx/additional-profiles/apKeywordFilterSelectors";
import {
	setAPKeywordFilterConfig,
	setAPMultipleFilters,
} from "store/mapx/additional-profiles/additionalProfilesActions";
import {
	clearKeywordsByPositionForAPCandidate,
	moveKeywordForAPCandidate,
	setKeywordForAPCandidate,
	switchKeywordConnectorForAPCandidatePosition,
} from "store/mapx/additional-profiles/apKeywordAsyncAction";
import {TabSelect} from "components";
import {setKeywordSearchQuery} from "store/mapx/search/searchActions";
import {getKeywordsList} from "store/mapx/search/searchAsyncActions";
import {KeywordSearchFilter} from "mapx-components";

function KeywordFilterForAP() {
	const keywordSearchQuery = useSelector(keywordSearchQuerySelector);

	const keywordPagination = useSelector(keywordPaginationSelector);

	const dispatch = useAppDispatch();

	const keywordFilterConfig = useAppSelector(apKeywordFilterConfigSelector);

	const position = useAppSelector<TKeywordPosition>(apKeywordCandidatePositionSelector);

	const currentPositionConnectivityLogic = useAppSelector<TKeywordPositionConnectivityLogic>(
		apKeywordCurrentPositionConnectivityLogicSelector,
	);

	const previousPositionConnectivityLogic = useAppSelector<TKeywordPositionConnectivityLogic>(
		apKeywordPreviousPositionConnectivityLogicSelector,
	);

	const anyPositionConnectivityLogic = useAppSelector<TKeywordPositionConnectivityLogic>(
		apKeywordAnyPositionConnectivityLogicSelector,
	);

	const logicByCurrentlySelectedPosition = useMemo(() => {
		if (position === "current") {
			return currentPositionConnectivityLogic;
		} else if (position === "previous") {
			return previousPositionConnectivityLogic;
		} else {
			return anyPositionConnectivityLogic;
		}
	}, [
		anyPositionConnectivityLogic,
		currentPositionConnectivityLogic,
		position,
		previousPositionConnectivityLogic,
	]);

	const filterPositionOptions = useAppSelector(filterPositionOptionsSelector);

	const count = useAppSelector(allAPKeywordsCountSelector);

	const anyTags = useAppSelector(apAnyKeywordTagsSelector);

	const currentTags = useAppSelector(apCurrentKeywordTagsSelector);

	const previousTags = useAppSelector(apPreviousKeywordTagsSelector);

	const selectedKeywords = useAppSelector((state) =>
		apKeywordsByPositionSelector(state, position, logicByCurrentlySelectedPosition),
	);

	const handlePositionChange = useCallback(
		(updatedPositionValue: string) => {
			dispatch(
				setAPKeywordFilterConfig({
					...keywordFilterConfig,
					active_position: updatedPositionValue,
				}),
			);
		},
		[dispatch, keywordFilterConfig],
	);

	const handleChange = useCallback(
		(value: string) => {
			dispatch(
				setKeywordForAPCandidate({
					position: position,
					logic: logicByCurrentlySelectedPosition,
					text: value,
				}),
			);
		},
		[dispatch, logicByCurrentlySelectedPosition, position],
	);

	const handleTagClick = useCallback(
		(value: string, position: TKeywordPosition, logic: TKeywordPositionConnectivityLogic) => {
			dispatch(
				setKeywordForAPCandidate({
					position,
					logic,
					text: value,
				}),
			);
		},
		[dispatch],
	);

	const getLogicConnectorByPosition = useCallback(
		(positionParam: TKeywordPosition) => {
			let logic: TKeywordPositionConnectivityLogic;

			switch (positionParam) {
				case "current":
					logic = currentPositionConnectivityLogic;
					break;
				case "previous":
					logic = previousPositionConnectivityLogic;
					break;
				case "any":
					logic = anyPositionConnectivityLogic;
					break;
				default:
					throw Error("This position doesn't exist");
			}

			return logic;
		},
		[
			anyPositionConnectivityLogic,
			currentPositionConnectivityLogic,
			previousPositionConnectivityLogic,
		],
	);

	const moveKeywordTagPosition = useCallback(
		(value: string, fromPosition: TKeywordPosition, toPosition: TKeywordPosition) => {
			const fromLogic = getLogicConnectorByPosition(fromPosition);
			const toLogic = getLogicConnectorByPosition(toPosition);

			dispatch(
				moveKeywordForAPCandidate({
					from: fromPosition,
					to: toPosition,
					fromLogic: fromLogic,
					toLogic: toLogic,
					text: value,
				}),
			);
		},
		[dispatch, getLogicConnectorByPosition],
	);

	const handleConnectivityOptionChange = useCallback(
		(position: TKeywordPosition, toLogic: TKeywordPositionConnectivityLogic) => {
			let fromLogic: TKeywordPositionConnectivityLogic;

			switch (position) {
				case "current":
					fromLogic = currentPositionConnectivityLogic;

					dispatch(
						setAPKeywordFilterConfig({
							...keywordFilterConfig,
							active_current_position_connect_logic: toLogic,
						}),
					);
					break;
				case "previous":
					fromLogic = previousPositionConnectivityLogic;

					dispatch(
						setAPKeywordFilterConfig({
							...keywordFilterConfig,
							active_previous_position_connect_logic: toLogic,
						}),
					);
					break;
				case "any":
					fromLogic = anyPositionConnectivityLogic;

					dispatch(
						setAPKeywordFilterConfig({
							...keywordFilterConfig,
							active_any_position_connect_logic: toLogic,
						}),
					);
					break;
				default:
					throw Error("This position doesn't exist");
			}

			dispatch(
				switchKeywordConnectorForAPCandidatePosition({
					position,
					fromLogic,
					toLogic,
				}),
			);
		},
		[
			anyPositionConnectivityLogic,
			currentPositionConnectivityLogic,
			dispatch,
			keywordFilterConfig,
			previousPositionConnectivityLogic,
		],
	);

	const handleClearTagClickByPosition = useCallback(
		(position: TKeywordPosition) => {
			dispatch(clearKeywordsByPositionForAPCandidate(position));
		},
		[dispatch],
	);

	const handleResetClick = useCallback(() => {
		dispatch(
			setAPMultipleFilters({
				text_keywords_or: [],
				text_keywords_and: [],
				text_keywords_not: [],
				current_text_keywords_or: [],
				current_text_keywords_and: [],
				current_text_keywords_not: [],
				previous_text_keywords_or: [],
				previous_text_keywords_and: [],
				previous_text_keywords_not: [],
			}),
		);
	}, [dispatch]);

	return (
		<div>
			<TabSelect.LabelContainer label="Position">
				<TabSelect
					selected={position}
					onTabChange={handlePositionChange}
					options={filterPositionOptions}
				/>
			</TabSelect.LabelContainer>

			<KeywordSearchFilter
				selectedKeywords={selectedKeywords}
				handleResetClick={handleResetClick}
				handleOnChange={handleChange}
				handleTagClick={handleTagClick}
				displayResetFilterOption={count > 0}
				moveKeywordTagPosition={moveKeywordTagPosition}
				selectedKeywordTags={{
					current: currentTags,
					previous: previousTags,
					any: anyTags,
				}}
				handleConnectivityOptionChange={handleConnectivityOptionChange}
				connectionLogics={{
					current: currentPositionConnectivityLogic,
					previous: previousPositionConnectivityLogic,
					any: anyPositionConnectivityLogic,
				}}
				clearTagsByPosition={handleClearTagClickByPosition}
				keywordSearchQuery={keywordSearchQuery}
				keywordPagination={keywordPagination}
				setKeywordQueryOnState={setKeywordSearchQuery}
				apiCall={getKeywordsList}
			/>
		</div>
	);
}

export default KeywordFilterForAP;
