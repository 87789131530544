/**
 *
 * @param fixedArray
 * @param inputArray
 * @returns boolean
 * example: fixedArray = [1, 3, 5, 7]; inputArray = [1, 3]; response true
 * example: fixedArray = [1, 3, 5, 7]; inputArray = [1, 4]; response false
 */

import {TRootState} from "../types";

export const arrayIntersected = <T>(fixedArray: T[], inputArray: T[]): boolean => {
	if (!Array.isArray(fixedArray) || !Array.isArray(inputArray)) return false;

	const myMap = new Map<T, boolean>();

	fixedArray.forEach((element) => myMap.set(element, true));

	return inputArray.reduce((acc, el) => {
		return acc && myMap.has(el);
	}, true);
};

/**
 *
 * @param fixedArray
 * Example: [1, 7, 5, 9];
 * @param inputArray
 * Example: [9, 1];
 * @return {boolean} true
 */

export const checkIntersectionWithoutSortedElement = <T>(
	fixedArray: T[],
	inputArray: T[] | undefined,
): boolean => {
	if (!inputArray) return false;

	const fixedSet = new Set(fixedArray);

	for (let i = 0; i < inputArray.length; i++) {
		if (!fixedSet.has(inputArray[i])) {
			return false;
		}
	}

	return true;
};

/**
 * Normalizes the array elements using the function:
 *
 * zi = (xi – min(x)) / (max(x) – min(x)) * 100
 *
 * If the normalized value is lower than "minVal"
 * it sets the value as "minVal". "minVal" should be
 * set to zero for the normal normalization process
 * in which some elements will have 0 value.
 *
 * @param {any[]} array
 * @param {Function} [selector=(arr) => arr]
 * @param [minVal=15] type number
 * @return {number[]}
 */
export const normalize = <T>(
	array: T[],
	selector: (arr: T) => number = (arr) => arr as unknown as number,
	minVal = 15,
): number[] => {
	const values = array.map(selector);

	const max = Math.max(...values);
	const min = Math.min(...values);

	return array.map((arr) => {
		const result = ((selector(arr) - min) / (max - min)) * 100;

		return result < minVal ? minVal : result;
	});
};

/**
 * Sort an array containing objects by key
 *
 * e.g. candidates array sort by first_name alphabetically.
 * NOTE: numeric sort doesn't work
 *
 * @param array of objects
 * @param selector, key attribute of the objects
 * @returns array
 */

type ObjectType = {[key: string]: never};

export const sortArrayOfObjectsAlphabetically = <T extends ObjectType>(
	array: T[],
	selector: string,
): T[] => {
	try {
		return array.sort(function (a, b) {
			if (a[selector] < b[selector]) {
				return -1;
			} else if (a[selector] > b[selector]) {
				return 1;
			} else {
				return 0;
			}
		});
	} catch (e) {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		console.log(`Sorting Failed. Error: ${e.toString()}`);

		return array; // Return the original array in case of an error
	}
};

/**
 *
 * compareFn of Array.sort function. This function is
 * used to sort array of objects by a specific key of
 * the object. This function handles both numbers and
 * strings so.
 *
 * How prioritized value works?
 *
 * array: [{ no: 3 }, { no: 1 }, { no: 4 }, { no: 2 }]
 *
 * If we call function for arrayX:
 *
 *   arrayX.sort(compareValues("no", "asc"));
 *   // [{ no: 1 }, { no: 2 }, { no: 3 }, { no: 4 }]
 *
 *     arrayX.sort(compareValues("no", "asc", 4));
 *   // [{ no: 4 }, { no: 1 }, { no: 2 }, { no: 3 }]
 *
 * @export
 * @param {string} key
 * @param {string} [order="asc"] ascending/descending order
 * @param {*} prioritized prioritize a specific value
 * @return {Function} compareFn
 */
type PrioritizedType = string | number | boolean; // Adjust this type according to your use case

export function compareValues(
	key: string,
	order: "asc" | "desc" = "asc",
	prioritized?: PrioritizedType,
) {
	// eslint-disable-next-line  @typescript-eslint/no-explicit-any
	return function innerSort(a: Record<string, any>, b: Record<string, any>): number {
		if (!Object.prototype.hasOwnProperty.call(a, key)) {
			return 0;
		}
		if (!Object.prototype.hasOwnProperty.call(b, key)) {
			return 0;
		}

		const varA = typeof a[key] === "string" ? a[key].toLowerCase() : a[key];
		const varB = typeof b[key] === "string" ? b[key].toLowerCase() : b[key];

		if (prioritized !== undefined) {
			if (varA === prioritized && varB === prioritized) {
				return 0;
			}
			if (varA === prioritized) {
				return -1;
			}
			if (varB === prioritized) {
				return 1;
			}
		}

		let comparison = 0;
		if (varA > varB) {
			comparison = 1;
		} else if (varA < varB) {
			comparison = -1;
		}

		return order === "desc" ? comparison * -1 : comparison;
	};
}

/**
 * Removes input array of ids from the main array of ids
 * @param inputArray = [1, 2, 3]
 * @param mainArray = [1, 2, 3, 4, 6]
 * @return [4, 6]
 */

export function removeInputArrayIdentifiersFromAnotherArray<T>(
	inputArray: T[],
	mainArray: T[],
): T[] {
	const toRemove = new Set(inputArray);

	return mainArray.filter((x) => !toRemove.has(x));
}

/**
 *    Example usage:
 const array1 = [1, 2, 3, 4, 5];
 const array2 = [5, 2, 1, 4, 3];
 console.log(arraysHaveSameValues(array1, array2)); // Output: true

 const array3 = [1, 2, 3, 4, 5];
 const array4 = [5, 2, 1, 6, 3];
 console.log(arraysHaveSameValues(array3, array4)); // Output: false
 */

export function arraysHaveSameValues<T>(arr1: T[], arr2: T[]): boolean {
	if (arr1.length !== arr2.length) {
		return false;
	}

	const set = new Set(arr1);

	for (let i = 0; i < arr2.length; i++) {
		if (!set.has(arr2[i])) {
			return false;
		}
	}

	return true;
}

/**
 * EXAMPLE USAGE:
 const fixedArray = [1, 7, 5, 9];
 const inputArray = [9, 1];
 console.log(hasCommonItems(inputArray, fixedArray)); // Output: true
 *
 * @param inputArray
 * @param fixedArray
 * @return boolean
 */

export function hasCommonItems<T>(inputArray: T[], fixedArray: T[]): boolean {
	const fixedSet = new Set(fixedArray);

	return inputArray.some((item) => fixedSet.has(item));
}

/**
 * EXAMPLE USAGE:
 const fixedArray = [1, 7, 5, 9];
 const inputArray = [9, 1];
 console.log(hasCommonItems(inputArray, fixedArray)); // Output: true
 *
 * @param toCheckArray = [1, 2, 3, 4]
 * @param fromCheckArray = [1, 4, 5, 6]
 * response [2, 3]
 * @return []
 */

export function getArrayDifference<T>(toCheckArray: T[], fromCheckArray: T[]): T[] {
	const setB = new Set(fromCheckArray);

	return toCheckArray.filter((item) => !setB.has(item));
}

/**
 * Filters out items from the source array that match the specified keys with any item in the comparison array.
 *
 * @param source - The source array of objects to filter.
 * @param comparison - The comparison array of objects to compare against.
 * @param keys - An array of keys to compare for filtering.
 * @returns A filtered array excluding objects that match on the specified keys.
 */
export const filterArrayByKeys = <T>(source: T[], comparison: T[], keys: (keyof T)[]): T[] => {
	return source.filter(
		(sourceItem) =>
			!comparison.some((comparisonItem) =>
				keys.every((key) => sourceItem[key] === comparisonItem[key]),
			),
	);
};

/**
 * EXAMPLE USAGE:
 input array of object with desc mode

 [{ name: "Alice", age: 30 }, { name: "Bob", age: 22 }, { name: "Charlie", age: 25 }, { name: "Dave", age: 35 }]

 output:

 [{ name: "Dave", age: 35 }, { name: "Charlie", age: 25 }, { name: "Bob", age: 22 }, { name: "Alice", age: 30 }]
 *
 * @return []
 */

export function sortObjectsAlphabetically<T>(
	arr: T[],
	key: keyof T,
	order: "asc" | "desc" = "asc",
): T[] {
	return arr.sort((a, b) => {
		const comparison = String(a[key]).localeCompare(String(b[key]));

		return order === "asc" ? comparison : -comparison;
	});
}

/**
 * EXAMPLE USAGE:
 input array of object with desc mode and key is: address.country

 [{ name: "Alice", address: {city:NY, country:USA} }, { name: "Bob", address: {city:Rio, country:Brasil}}]

 output:

 [{ name: "Bob", address: {city:Rio, country:Brasil}},{ name: "Alice", address: {city:NY, country:USA} }, ]
 *
 * @return []
 */
export function sortArrayByNestedKey<T>(
	arr: T[],
	key: string, // Nested key as a string, e.g., "xxx.y"
	order: "asc" | "desc" = "desc",
): T[] {
	return arr.sort((a, b) => {
		const getValue = (obj: TRootState, path: string): TRootState => {
			return path.split(".").reduce((acc, part) => acc && acc[part], obj);
		};

		const valueA = getValue(a, key);
		const valueB = getValue(b, key);

		if (valueA !== undefined && valueB !== undefined) {
			if (order === "asc") {
				return valueA - valueB;
			} else {
				return valueB - valueA;
			}
		}

		return 0;
	});
}
