import {TRootState} from "types";
import {createSelector} from "reselect";
import {targetListCandidatesSelector} from "../target-list/targetListSelectors";

export const generateCandidatesSummariesForAllProjectSelector = (state: TRootState) =>
	state.mapxProject.generateCandidatesSummaryByProject;

export const generateCandidatesSummariesFlattenedForAllProjectSelector = (state: TRootState) => {
	const summaries = state.mapxProject.generateCandidatesSummaryByProject;

	return [...Object.values(summaries).flat()];
};

export const targetListIdSelector = (_: TRootState, id: number) => id;
export const candidateIdSelector = (_: TRootState, __: TRootState, candidateId: number) =>
	candidateId;

export const getAllGenerateSummaryCandidateIdsForCurrentProjectSelector = createSelector(
	generateCandidatesSummariesFlattenedForAllProjectSelector,
	targetListCandidatesSelector,
	targetListIdSelector,
	(generateCandidatesSummariesForAllProject, targetListCandidates, tlId) => {
		if (tlId) {
			// Get candidate IDs that are in both targetListCandidates and generateCandidatesSummariesForAllProject
			return targetListCandidates
				?.filter((candidate: {id: number}) =>
					generateCandidatesSummariesForAllProject?.includes(Number(candidate.id)),
				)
				.map((candidate: {id: number}) => candidate.id);
		}

		return [];
	},
);

export const isSummaryForCurrentCandidateInProgressForCurrentProjectSelector = createSelector(
	generateCandidatesSummariesForAllProjectSelector,
	targetListIdSelector,
	candidateIdSelector,
	(generateCandidatesSummariesForAllProject, tlId, candidateId) => {
		if (tlId && candidateId) {
			const objectKey =
				typeof generateCandidatesSummariesForAllProject === "object"
					? Object.keys(generateCandidatesSummariesForAllProject).find(
							(id) => id.toString() === tlId.toString(),
					  )
					: null;

			if (
				objectKey &&
				generateCandidatesSummariesForAllProject[objectKey] !== undefined &&
				Array.isArray(generateCandidatesSummariesForAllProject[objectKey])
			) {
				return generateCandidatesSummariesForAllProject[objectKey].includes(candidateId);
			}
		}

		return false;
	},
);
