import {useState} from "react";

import type {TCopiedValue, TCopyFn} from "./types";

const useCopyToClipboard = (): [TCopyFn, TCopiedValue] => {
	const [copiedText, setCopiedText] = useState<TCopiedValue>(null);

	const copy: TCopyFn = async (text) => {
		if (!navigator?.clipboard) {
			console.warn("Clipboard not supported");

			return false;
		}

		try {
			await navigator.clipboard.writeText(text);
			setCopiedText(text);

			return true;
		} catch (error) {
			console.warn("Copy failed", error);
			setCopiedText(null);

			return false;
		}
	};

	return [copy, copiedText];
};

export default useCopyToClipboard;
