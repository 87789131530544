import RejectMinusIcon from "assets/icons/RejectMinusIcon";
import SavePlusIcon from "assets/icons/SavePlusIcon";
import {GeneralButton, MoreLikeThisButton} from "mapx-components";
import React, {useMemo, useState} from "react";
import {useHistory} from "react-router-dom";

import {
	approveCandidate,
	recommendCandidate,
	rejectCandidate,
} from "store/mapx/additional-profiles/candidateDecisionAsyncActions";
import {addCandidateToTargetListDone} from "store/mapx/target-list/targetListActions";
import {
	addCandidateToTargetList,
	getTargetListCompanies,
	removeCandidateFromTargetList,
} from "store/mapx/target-list/targetListAsyncActions";
import {
	isCandidateSavedSelector,
	targetListIDSelector,
} from "store/mapx/target-list/targetListSelectors";
import {ActionAltButton} from "../index";
import styles from "./styles.module.scss";
import {TAdditionalProfileActionsProps} from "mapx-components/AdditionalProfileActions/types";
import {useAppDispatch, useAppSelector} from "hooks";
import {useSelector} from "react-redux";
import {RecommendedIcon} from "assets/icons";

const AdditionalProfileActions = ({
	searchType,
	candidate,
	isAdditionalProfileList = false,
	isQuickView = false,
	isRecommended = false,
}: TAdditionalProfileActionsProps) => {
	const dispatch = useAppDispatch();

	const history = useHistory();

	const targetListID = useAppSelector(targetListIDSelector);

	const [saveLoading, setSaveLoading] = useState(false);

	const [recommendLoading, setRecommendLoading] = useState(false);

	const [rejectLoading, setRejectLoading] = useState(false);

	const isSaved = useSelector((state) => isCandidateSavedSelector(state, candidate.id));

	const saveIconComponent = <SavePlusIcon />;

	const rejectIconComponent = <RejectMinusIcon />;

	const recommendedIcon = <RecommendedIcon />;

	const handleSaveClick = async () => {
		setSaveLoading(true);

		if (
			searchType === "People Search" ||
			searchType === "Profile" ||
			searchType === "Quick View"
		) {
			await dispatch(addCandidateToTargetList(candidate.id));
		} else {
			await dispatch(approveCandidate(candidate.id, !isAdditionalProfileList));

			dispatch(addCandidateToTargetListDone(candidate, targetListID));
		}

		setSaveLoading(false);

		if (searchType === "Free Text Input Search") {
			dispatch(getTargetListCompanies());
		}

		if (searchType !== "People Search" && isQuickView) {
			history.goBack();
		}
	};

	const handleRecommendClick = async () => {
		setRecommendLoading(true);

		await dispatch(recommendCandidate(candidate.id, true));

		setRecommendLoading(false);
	};

	const handleRejectClick = async () => {
		setRejectLoading(true);

		if (
			searchType === "People Search" ||
			searchType === "Project" ||
			searchType === "Profile"
		) {
			await dispatch(removeCandidateFromTargetList(candidate.id));
		} else {
			await dispatch(rejectCandidate(candidate));
		}

		setRejectLoading(false);

		if (searchType !== "People Search" && isQuickView) {
			history.goBack();
		}
	};

	const removeRecommendStatus = async () => {
		setRecommendLoading(true);

		await dispatch(recommendCandidate(candidate.id, false));

		setRecommendLoading(false);
	};

	const removeNRejectCandidate = async () => {
		await removeRecommendStatus();
		await handleRejectClick();
	};

	const displayRejectNRemoveButton = useMemo(() => {
		return !!(searchType === "Project" && isRecommended);
	}, [isRecommended, searchType]);

	const displaySaveButton = useMemo(() => {
		if (
			(searchType === "People Search" ||
				searchType === "Project" ||
				searchType === "Profile") &&
			isSaved
		) {
			return false;
		} else if (searchType === "People Search" && !isSaved) {
			return true;
		} else {
			return !isSaved;
		}
	}, [isSaved, searchType]);

	const displayRejectButton = useMemo(() => {
		if (isRecommended) {
			return false;
		} else if (searchType === "People Search") {
			return !!isSaved;
		} else if (searchType === "Project") {
			return true;
		} else if (searchType === "Profile") {
			return !!isSaved;
		} else {
			return !isSaved;
		}
	}, [isSaved, searchType, isRecommended]);

	const displayRecommendedButton = useMemo(() => {
		if (searchType === "Project" && !isRecommended) {
			return true;
		} else if (searchType === "Profile" && !isRecommended) {
			return !!isSaved;
		} else {
			return false;
		}
	}, [isRecommended, isSaved, searchType]);

	const rejectButtonTitle = useMemo(() => {
		if (
			searchType === "People Search" ||
			searchType === "Project" ||
			searchType === "Profile"
		) {
			return rejectLoading ? "Removing" : "Remove";
		} else {
			return rejectLoading ? "Rejecting" : "Reject";
		}
	}, [rejectLoading, searchType]);

	return (
		<div className={styles.buttonRow}>
			<div className={styles.buttonGroup}>
				<MoreLikeThisButton candidate={candidate} />

				{displayRejectButton && (
					<ActionAltButton
						key={"reject"}
						customClass={styles.rejectButton}
						iconComponent={rejectIconComponent}
						handleClick={handleRejectClick}
						loading={rejectLoading}
						title={rejectButtonTitle}
						disabled={saveLoading || rejectLoading}
					/>
				)}

				{displaySaveButton && (
					<ActionAltButton
						key={"approve"}
						customClass={styles.saveButton}
						iconComponent={saveIconComponent}
						handleClick={handleSaveClick}
						loading={saveLoading}
						title={saveLoading ? "Saving" : "Save"}
						disabled={saveLoading || rejectLoading}
					/>
				)}

				{displayRecommendedButton && (
					<ActionAltButton
						key={"approve"}
						customClass={styles.recommendButton}
						iconComponent={recommendedIcon}
						handleClick={handleRecommendClick}
						loading={recommendLoading}
						title={recommendLoading ? "Recommending.." : "Recommend"}
						disabled={recommendLoading}
					/>
				)}

				{displayRejectNRemoveButton && (
					<GeneralButton
						customClass={styles.rejectButton}
						handleClick={removeRecommendStatus}
						loadingSave={recommendLoading || rejectLoading}
						icon={rejectIconComponent}
						title={"Unrecommend"}
						options={[
							{
								label: "Unrecommend & Remove",
								action: removeNRejectCandidate,
							},
						]}
						expandableClass={styles.expandable}
					/>
				)}
			</div>
		</div>
	);
};

export default AdditionalProfileActions;
