import {arraysHaveSameValues, removeInputArrayIdentifiersFromAnotherArray} from "helpers/array";
import {TCountry, TLocationHelperProps} from "./types";
import {removeDuplicatesFromArray} from "helpers/filterHandlers";

const LocationFilterHelper = ({
	selectedCountries,
	selectedCountryRegions,
	flattenCountries,
}: TLocationHelperProps) => {
	const handleCheckChangeForSingleItem = (id: number) => {
		const index = selectedCountries.indexOf(id);

		const alreadySelected = index !== -1;

		let nextCountryIds;
		let toBeSetRegionIds = [...selectedCountryRegions];

		if (alreadySelected) {
			nextCountryIds = [...selectedCountries.filter((itemId) => itemId !== id)];
		} else {
			nextCountryIds = [...selectedCountries, id];

			// if country is newly selected we will have to unselect all the selected region for the country
			const country = flattenCountries.find((item) => item.id === id);

			if (country && country?.regions?.length > 0) {
				const allRegionIdsForCurrentCountry = country.regions.map((i) => i.id);

				toBeSetRegionIds = removeInputArrayIdentifiersFromAnotherArray(
					allRegionIdsForCurrentCountry,
					toBeSetRegionIds,
				);
			}
		}

		return {
			nextCountryIds,
			toBeSetRegionIds,
		};
	};

	const handleCheckChangeForBulkItems = (ids: number[]) => {
		let toBeSetRegionIds = [...selectedCountryRegions];

		for (const countryId of ids) {
			const country = flattenCountries.find((item) => item.id === countryId);

			if (country && country?.regions?.length > 0) {
				const allRegionIdsForCurrentCountry = country.regions.map((i) => i.id);

				toBeSetRegionIds = removeInputArrayIdentifiersFromAnotherArray(
					allRegionIdsForCurrentCountry,
					toBeSetRegionIds,
				);
			}
		}

		return toBeSetRegionIds;
	};

	const handleRegionCheckChange = (regionId: number, country: TCountry) => {
		const allRegionIdsForCurrentCountry = country.regions.map((i) => i.id);
		const isCountrySelected = selectedCountries.includes(country.id);

		let isAllRegionSelected;
		let nextCountryRegionIds;

		if (isCountrySelected) {
			nextCountryRegionIds = allRegionIdsForCurrentCountry;

			isAllRegionSelected = true;
		} else {
			const alreadySelected = selectedCountryRegions.includes(regionId);

			nextCountryRegionIds = alreadySelected
				? [...selectedCountryRegions.filter((i) => i !== regionId)]
				: [...selectedCountryRegions, regionId];

			isAllRegionSelected = arraysHaveSameValues(
				allRegionIdsForCurrentCountry,
				nextCountryRegionIds,
			);
		}

		let nextCountryIds;
		let toBeSetRegionIds = [...selectedCountryRegions];

		if (isAllRegionSelected) {
			// if all region is selected and country is selected
			// and, user clicked on region means, they have unchecked a region
			// if all region is selected but country is not selected
			// means we have selected the entire country, we remove all the region ids from the parameter
			if (isCountrySelected) {
				nextCountryIds = [...selectedCountries.filter((i) => i !== country.id)];

				toBeSetRegionIds = [
					...toBeSetRegionIds,
					...allRegionIdsForCurrentCountry.filter((i) => i !== regionId),
				];
			} else {
				nextCountryIds = [...selectedCountries, country.id];

				toBeSetRegionIds = [
					...toBeSetRegionIds,
					...removeInputArrayIdentifiersFromAnotherArray(
						nextCountryRegionIds,
						allRegionIdsForCurrentCountry,
					),
				];
			}
		} else {
			if (isCountrySelected) {
				nextCountryIds = [...selectedCountries.filter((id) => id !== country.id)];
			} else {
				nextCountryIds = selectedCountries;
			}

			toBeSetRegionIds = nextCountryRegionIds;
		}

		toBeSetRegionIds = removeDuplicatesFromArray(toBeSetRegionIds);

		// console.log(regionId, country)
		// console.log("isAllRegionSelected", isAllRegionSelected)
		// console.log("toBeSetRegionIds", toBeSetRegionIds)
		// console.log("nextCountryIds", nextCountryIds)

		return {
			nextCountryIds,
			toBeSetRegionIds,
		};
	};

	return {
		handleCheckChangeForSingleItem,
		handleCheckChangeForBulkItems,
		handleRegionCheckChange,
	};
};

export default LocationFilterHelper;
