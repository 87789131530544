import {useCallback, useMemo, useState} from "react";
import {useSelector} from "react-redux";
import type {SyntheticEvent} from "react";

import colors from "styles/themes.module.scss";
import {useAppDispatch, useModalHook} from "hooks";
import {Message, ModalHeader, ModalSeparator} from "components";

import type {TImportFromProjectModal} from "./types";
import css from "./importFromProjectModal.module.scss";
import {SelectWithInfiniteScroll} from "components";
import type {TSelectWithActionOptions} from "components/Inputs/SelectWithAction/types";
import GeneralButton from "mapx-components/Buttons/GeneralButton";
import {updateProjectList} from "store/mapx/project-list/projectListAsyncActions";
import {
	loadingProjectsSelector,
	projectPaginationSelector,
	projectsSelector,
} from "store/mapx/project-list/projectListSelectors";
import {STProject} from "api/projectApi/types";

const ImportFromProjectModal = ({
	setCompanies,
	setModalDisplay,
	modalDisplay = true,
}: TImportFromProjectModal) => {
	const dispatch = useAppDispatch();

	const [project, setProject] = useState<TSelectWithActionOptions>({
		label: null,
		value: null,
	});

	const projects = useSelector(projectsSelector);

	const loading = useSelector(loadingProjectsSelector);

	const pagination = useSelector(projectPaginationSelector);

	const {modalIsOpen, customStyles, Modal} = useModalHook(
		{content: {width: "400px", borderRadius: "8px", overflow: "visible"}},
		modalDisplay,
		colors.mainThemeColor,
	);

	const selectedProject = useMemo(
		() => projects.find((p: STProject) => p.id === project?.value),
		[project?.value, projects],
	);

	const max500 = useMemo(
		() => selectedProject?.companies && selectedProject.companies.length > 500,
		[selectedProject],
	);

	const min0 = useMemo(
		() => selectedProject?.companies && selectedProject.companies.length === 0,
		[selectedProject],
	);

	const handleInfiniteScroll = useCallback(() => {
		dispatch(updateProjectList());
	}, [dispatch]);

	const handleSubmit = useCallback(
		(e: SyntheticEvent) => {
			e.preventDefault();
			setCompanies(selectedProject?.companies || []);
			setModalDisplay(false);
		},
		[selectedProject, setCompanies, setModalDisplay],
	);

	return (
		<Modal
			title="Import from Project"
			style={customStyles}
			isOpen={modalIsOpen}
			contentLabel="Modal"
			onRequestClose={() => setModalDisplay(false)}
		>
			<ModalHeader title="Import from Project" onClose={() => setModalDisplay(false)} />
			<ModalSeparator />
			<form className={css.container} onSubmit={handleSubmit}>
				<label className={css.label}>Project</label>
				<SelectWithInfiniteScroll
					isSelected={!project?.value}
					loading={loading}
					onScroll={handleInfiniteScroll}
					options={projects.map((project: STProject) => ({
						label: project?.name,
						value: project?.id,
					}))}
					pagination={pagination}
					placeholder="Select Project"
					selected={project}
					setSelected={setProject}
				/>
				{max500 && (
					<Message
						className={css.message}
						level="error"
						message="You can add max 500 companies per Target List."
					/>
				)}
				{min0 && (
					<Message
						className={css.message}
						level="error"
						message="No company has been added to the project."
					/>
				)}
				<GeneralButton
					disabled={min0 || max500 || project?.value === null}
					customClass={css.button}
					title="Import Project to Target List"
				/>
			</form>
		</Modal>
	);
};

export default ImportFromProjectModal;
