import {toast} from "react-toastify";

import {ToastContent} from "components";
import mapXOrganisationApi from "api/organisationApi";

import {
	createInvitationDone,
	createInvitationFail,
	createInvitationInit,
	createOrganisationDone,
	createOrganisationFail,
	createOrganisationInit,
	getOrganisationsDone,
	getOrganisationsFail,
	getOrganisationsInit,
	getOrganizationListInit,
	getOrganizationListFailed,
	getOrganizationListSuccess,
	getOrganisationUsersInit,
	getOrganisationUsersDone,
	getOrganisationUsersFailed,
	resendInvitationDone,
	resendInvitationFail,
	resendInvitationInit,
	revokeInvitationDone,
	revokeInvitationFail,
	revokeInvitationInit,
	revokeUserDone,
	revokeUserFail,
	revokeUserInit,
	verifyInvitationDone,
	verifyInvitationFail,
	verifyInvitationInit,
	deleteOrganizationInit,
	deleteOrganizationDone,
	deleteOrganizationFail,
	updateOrganizationInit,
	updateOrganizationDone,
	updateOrganizationFail,
	getInvitationsInit,
	getInvitationsDone,
	getInvitationsFail,
	getOrganizationTableDataInit,
	getOrganizationTableDataSuccess,
	getOrganizationTableDataFailed,
	getSeatsInit,
	getSeatsDone,
	getSeatsFail,
} from "./organisationActions";
import {userOrganisationSelector} from "../user/userSelectors";
import {companyLicenseTypesSelector} from "store/mapx/company/companySelectors";

export const getOrganizationsData = () => async (dispatch) => {
	try {
		dispatch(getOrganisationsInit());

		const {
			data: {results: organisations = [], pagination: organisationsPagination},
		} = await mapXOrganisationApi.getOrganisations();
		let {
			data: {results: invitedUsers = []},
		} = await mapXOrganisationApi.getInvitations();
		let {
			data: {results: currentUsers = [], pagination: usersPagination},
		} = await mapXOrganisationApi.getUsers();

		const organizationMap = new Map();
		organisations.forEach(({id, name}) => {
			organizationMap.set(id, name);
		});

		currentUsers = currentUsers.map((user) => ({
			...user,
			organisation: user.organisation.name,
		}));

		invitedUsers = invitedUsers.map((user) => ({
			...user,
			organisation: organizationMap.get(user.organisation_id),
		}));

		const payload = {
			invitedUsers,
			currentUsers,
			organisations,
			pagination: {
				organisationsPagination,
				usersPagination,
			},
		};

		dispatch(getOrganisationsDone(payload));

		//@todo send seen data information to BE
	} catch (error) {
		dispatch(getOrganisationsFail({error}));

		return {error};
	}
};

export const revokeUser = (userID) => async (dispatch) => {
	try {
		dispatch(revokeUserInit(userID));

		await mapXOrganisationApi.deleteUser(userID);

		dispatch(revokeUserDone(userID));
	} catch (error) {
		dispatch(revokeUserFail({error}));

		toast.error(ToastContent, {data: {title: "Something went wrong!"}});

		return {error};
	}
};

export const resendInvitation = (invitationID) => async (dispatch) => {
	try {
		dispatch(resendInvitationInit(invitationID));

		await mapXOrganisationApi.resendInvitation(invitationID);

		dispatch(resendInvitationDone(invitationID));

		toast.success(ToastContent, {data: {title: "Invitation sent successfully."}});
	} catch (error) {
		dispatch(resendInvitationFail({error}));

		toast.error(ToastContent, {data: {title: "Something went wrong!"}});

		return {error};
	}
};

export const verifyInvitationCode = (invitationCode) => async (dispatch) => {
	try {
		dispatch(verifyInvitationInit(invitationCode));

		const response = await mapXOrganisationApi.verifyInvitationCode(invitationCode);

		if (response.status === 200) {
			dispatch(verifyInvitationDone(invitationCode));
		} else if (response?.data?.status === 404) {
			window.location = "/invitation-invalid";

			throw new Error(response.data.detail);
		} else {
			throw new Error(response?.data?.detail);
		}

		return response;
	} catch (error) {
		dispatch(verifyInvitationFail({error}));

		return {error};
	}
};

export const revokeInvitation = (invitationID) => async (dispatch) => {
	try {
		dispatch(revokeInvitationInit(invitationID));

		await mapXOrganisationApi.deleteInvitation(invitationID);

		dispatch(revokeInvitationDone(invitationID));

		toast.success(ToastContent, {data: {title: "Revoke invitation successfully!"}});
	} catch (error) {
		dispatch(revokeInvitationFail({error}));

		toast.error(ToastContent, {data: {title: "Something went wrong!"}});

		return {error};
	}
};

export const getInvitedUsers =
	(orgUserDataOrdering = []) =>
	async (dispatch) => {
		const invitationsParams = new URLSearchParams();

		orgUserDataOrdering.forEach((orgUserDataOrderingItem) => {
			invitationsParams.append("ordering", orgUserDataOrderingItem);
		});

		try {
			dispatch(getInvitationsInit());

			const {data} = await mapXOrganisationApi.getInvitations(invitationsParams);

			dispatch(getInvitationsDone(data));
		} catch (error) {
			dispatch(getInvitationsFail({error}));

			return {error};
		}
	};

export const getInvitedUsersForAdmin =
	(orgUserDataOrdering = []) =>
	async (dispatch, getState) => {
		try {
			const state = getState();

			const organisation = userOrganisationSelector(state);

			const invitationsParams = new URLSearchParams();

			invitationsParams.append("organisation_id", organisation.id);

			orgUserDataOrdering.forEach((orgUserDataOrderingItem) => {
				invitationsParams.append("ordering", orgUserDataOrderingItem);
			});

			dispatch(getInvitationsInit());

			const {data} = await mapXOrganisationApi.getInvitations(invitationsParams);

			dispatch(getInvitationsDone(data));
		} catch (error) {
			dispatch(getInvitationsFail({error}));

			toast.error(ToastContent, {
				autoClose: true,
				closeOnClick: true,
				data: {
					title: "Please refresh the page",
					description: "Couldn't load the invitations data.",
				},
			});

			return {error};
		}
	};

export const updateOrganization = (organization_id, body) => async (dispatch) => {
	try {
		dispatch(updateOrganizationInit());

		const {data} = await mapXOrganisationApi.updateOrganizationStatistics(
			organization_id,
			body,
		);

		dispatch(updateOrganizationDone(data));

		toast.success(ToastContent, {
			data: {title: "The organization's information has been updated successfully!"},
		});
	} catch (error) {
		dispatch(updateOrganizationFail({error}));

		toast.error(ToastContent, {data: {title: "Something went wrong!"}});

		return {error};
	}
};

export const getOrganisationUsers =
	(organization_id, orgUserDataOrdering = []) =>
	async (dispatch) => {
		const organisationUsersParams = new URLSearchParams();

		orgUserDataOrdering.forEach((orgUserDataOrderingItem) => {
			organisationUsersParams.append("ordering", orgUserDataOrderingItem);
		});

		try {
			dispatch(getOrganisationUsersInit());

			const {data} = await mapXOrganisationApi.getOrganisationUsers(
				organization_id,
				organisationUsersParams,
			);

			dispatch(getOrganisationUsersDone(data.results));

			return data;
		} catch (error) {
			dispatch(getOrganisationUsersFailed(error));

			return {error};
		}
	};

export const getOrganisationUsersForAdmin =
	(orgUserDataOrdering = []) =>
	async (dispatch, getState) => {
		try {
			const state = getState();

			const organisation = userOrganisationSelector(state);

			const organisationUsersParams = new URLSearchParams();

			orgUserDataOrdering.forEach((orgUserDataOrderingItem) => {
				organisationUsersParams.append("ordering", orgUserDataOrderingItem);
			});

			await dispatch(getOrganisationUsersInit());

			const {data} = await mapXOrganisationApi.getOrganisationUsersForAdmin(
				organisation.id,
				organisationUsersParams,
			);

			dispatch(getOrganisationUsersDone(data.results));

			return data.results;
		} catch (error) {
			toast.error(ToastContent, {
				autoClose: true,
				closeOnClick: true,
				data: {
					title: "Please refresh the page",
					description: "Couldn't load the users data.",
				},
			});
			dispatch(getOrganisationUsersFailed(error));
		}
	};

export const createInvitation =
	(email, organisation_id, license_type_id, license_duration_days) =>
	async (dispatch, getState) => {
		const state = getState();
		const licenseTypes = companyLicenseTypesSelector(state);

		const invitationBody = {
			email,
			organisation_id,
		};

		if (license_type_id) {
			invitationBody.license_type_id = license_type_id;
			invitationBody.license_duration_days = license_duration_days;
		}

		try {
			dispatch(createInvitationInit());

			const {data} = await mapXOrganisationApi.createInvitation(invitationBody);
			if (data?.status >= 400) {
				dispatch(createInvitationFail({error: data.detail}));
				toast.error(ToastContent, {data: {title: data.detail}});
			} else {
				const starter = licenseTypes.find((licenseType) => licenseType.name === "Starter");
				// @TODO: "Adding license_type as starter for newly invited user" logic should be moved to BE.
				dispatch(createInvitationDone({...data, license_type: starter}));
				toast.success(ToastContent, {data: {title: "Invitation sent successfully."}});
			}
		} catch (error) {
			dispatch(createInvitationFail({error}));

			toast.error(ToastContent, {data: {title: "Something went wrong!"}});

			return {error};
		}
	};

export const getOrganizationTableData = () => async (dispatch, getState) => {
	const organizationListParams = new URLSearchParams();
	const {organizationsListOrdering} = getState().organisation;

	organizationsListOrdering.forEach((organizationsListOrderingItem) => {
		organizationListParams.append("ordering", organizationsListOrderingItem);
	});

	try {
		dispatch(getOrganizationTableDataInit());
		const {data} = await mapXOrganisationApi.getOrganizationList(organizationListParams);

		dispatch(getOrganizationTableDataSuccess(data.results));
	} catch (error) {
		dispatch(getOrganizationTableDataFailed({error}));

		return {error};
	}
};

export const getOrganizationsList = () => async (dispatch) => {
	try {
		dispatch(getOrganizationListInit());
		const {data} = await mapXOrganisationApi.getOrganizationList();

		dispatch(updateOrganizationDone(data));
		dispatch(getOrganizationListSuccess(data.results));
	} catch (error) {
		dispatch(getOrganizationListFailed({error}));

		return {error};
	}
};

export const createOrganisation = (name) => async (dispatch) => {
	try {
		dispatch(createOrganisationInit());

		const {data} = await mapXOrganisationApi.createOrganisation(name);

		await dispatch(getOrganizationTableData());

		dispatch(createOrganisationDone(data));

		toast.success(ToastContent, {data: {title: "Organisation created successfully."}});
	} catch (error) {
		dispatch(createOrganisationFail({error}));

		toast.error(ToastContent, {data: {title: "Something went wrong!"}});

		return {error};
	}
};

export const deleteOrganization = (organization_id) => async (dispatch) => {
	try {
		dispatch(deleteOrganizationInit());

		const data = await mapXOrganisationApi.deleteOrganization(organization_id);

		await dispatch(getOrganizationTableData());

		dispatch(deleteOrganizationDone(data));

		if (data?.status === 409) {
			toast.error(ToastContent, {
				data: {title: data?.data?.detail},
			});
		} else {
			data?.message
				? toast.success(ToastContent, {
						data: {title: data?.message},
				  })
				: toast.error(ToastContent, {
						data: {title: "Something went wrong!"},
				  });
		}
	} catch (error) {
		dispatch(deleteOrganizationFail({error}));

		toast.error(ToastContent, {data: {title: "Something went wrong!"}});

		return {error};
	}
};

export const getSeats = () => async (dispatch, getState) => {
	try {
		dispatch(getSeatsInit());

		const state = getState();

		const organisation = userOrganisationSelector(state);

		const {data} = await mapXOrganisationApi.getSeats(organisation.id);

		dispatch(getSeatsDone(data));
	} catch (error) {
		dispatch(getSeatsFail(error));

		return {error};
	}
};
