import {createSelector} from "reselect";

export const userSelector = (state) => state.user.user;

export const userRoleSelector = (state) => state.user?.user?.role;

export const isTutorialModalOpenedSelector = (state) => state.user?.isTutorialModalOpened;

export const userEmailSelector = (state) => state.user?.user?.email;

export const loadingProfileSelector = (state) => state.user.loadingProfile;

export const isSuperUserSelector = (state) => state.user?.user?.isSuperUser;

export const userOrganisationSelector = (state) => state.user?.user?.organisation;

export const userOrganisationTypeSelector = (state) => state.user?.user?.organisation?.type;

export const userCreditSelector = (state) => state.user?.user?.credits;

export const userCreditRefreshSelector = (state) => state.user?.user?.credit_refresh;

export const resetPassProgressSelector = (state) => state.user?.resetPassProgress;

export const iseEditedUserSelector = (state) => state.user?.iseEditedUser;

export const updatingUsersSelector = (state) => state.user.updatingUsers;

export const deleteUserInProgressSelector = (state) => state.user?.deleteUserInProgress;

export const userTransferProgressSelector = (state) => state.user?.userTransferProgress;

export const userLicenseTypeSelector = (state) => state.user?.user?.license?.type?.id;

export const userLicenseTypeNameSelector = (state) => state.user?.user?.license?.type?.name;

export const registerContactsBlockedByCountryInProgressSelector = (state) =>
	state.user.registerContactsBlockedByCountryInProgress;

export const registerContactsBlockedByCountrySubmittedSelector = (state) =>
	state.user.registerContactsBlockedByCountrySubmitted;

export const locationInProgressSelector = (state) => state.user.locationInProgress;

export const userLocationSelector = (state) => state.user.location;

export const userOrganisationRoleSelector = (state) => state.user?.user?.organisation_role;

export const verificationCodeInProgressSelector = (state) => state.user.verificationCodeInProgress;

export const verificationEmailInProgressSelector = (state) =>
	state.user.verificationEmailInProgress;

export const verificationEmailErrorSelector = (state) => state.user.verificationEmailError;

export const verificationEmailSentSelector = (state) => state.user.verificationEmailSent;

export const verificationEmailSelector = (state) => state.user.verificationEmail;

export const userAvailableCreditSelector = createSelector(
	[userCreditSelector],
	(userUsedCredit) => {
		return userUsedCredit;
	},
);

export const isStarterTypeUserSelector = createSelector(
	[userLicenseTypeNameSelector],
	(userLicenseType) => {
		return userLicenseType === "Starter";
	},
);

export const userAvailableCreditWithLimitSelector = createSelector(
	[userLicenseTypeNameSelector, userCreditSelector],
	(userLicenseType, userUsedCredit) => {
		const maxLimit = userLicenseType === "Starter" ? 500 : 5000;
		const availableCredit =
			userLicenseType === "Prospect" ? maxLimit - userUsedCredit : maxLimit - userUsedCredit;

		return `${availableCredit}/${maxLimit}`;
	},
);

export const hasAccessToExtendedPagesSelector = createSelector(
	[userLicenseTypeNameSelector],
	(userLicenseTypeName) => userLicenseTypeName !== "Starter" && userLicenseTypeName !== "Plus",
);

export const hasAccessToManualSearchSelector = createSelector(
	[userLicenseTypeNameSelector],
	(userLicenseTypeName) => userLicenseTypeName !== "Starter" && userLicenseTypeName !== "Plus",
);

export const hasAccessToMarketMapsSelector = createSelector(
	[userLicenseTypeNameSelector],
	(userLicenseTypeName) => userLicenseTypeName !== "Starter" && userLicenseTypeName !== "Plus",
);
