import {useMemo, useRef, useState} from "react";
import ContentLoader from "react-content-loader";
import {TProjectCandidateContactDetailProps, TProjectCandidateEmailTypes} from "./types";
import {useOutsideClick} from "hooks";
import {STCandidateEmail} from "api/candidateApi/types";
import EmptyContactDetails from "./emptyContactDetails";
import CandidateContactDetailsInfoGourp from "./CandidateContactDetailsInfoGroup";
import ForwardToMailIcon from "mapx-components/Cards/CandidateContactDetails/Icons/ForwardToMailIcon";
import MailIcon from "mapx-components/Cards/CandidateContactDetails/Icons/MailIcon";
import TelephoneIcon from "mapx-components/Cards/CandidateContactDetails/Icons/TelephoneIcon";
import styles from "mapx-components/Cards/ProjectCandidateCard/projectCandidateCard.module.scss";

const CandidateContactDetails = ({
	contactDetails,
	loading,
}: TProjectCandidateContactDetailProps) => {
	const mobileDropdownRef = useRef<HTMLDivElement>(null);
	const personalMailDropdownRef = useRef<HTMLDivElement>(null);
	const professionalMailDropdownRef = useRef<HTMLDivElement>(null);

	const [showPersonalMailMore, setShowPersonalMailMore] = useState(false);
	const [showProfessionalMailMore, setShowProfessionalMailMore] = useState(false);
	const [showPhoneNumberMore, setShowPhoneNumberMore] = useState(false);

	const togglePhoneNumberView = () => {
		setShowPhoneNumberMore(!showPhoneNumberMore);
	};

	const toggleProfessionalMailView = () => {
		setShowProfessionalMailMore(!showProfessionalMailMore);
	};

	const togglePersonalMailView = () => {
		setShowPersonalMailMore(!showPersonalMailMore);
	};

	const filteredEmails: TProjectCandidateEmailTypes = useMemo(() => {
		if (!contactDetails || !contactDetails.emails) return {professional: [], personal: []};

		const professionalEmails: STCandidateEmail[] = [];
		const personalEmails: STCandidateEmail[] = [];

		contactDetails.emails.forEach((email: STCandidateEmail) => {
			if (email.type === "Professional") {
				professionalEmails.push(email);
			} else {
				personalEmails.push(email);
			}
		});

		return {
			professional: professionalEmails,
			personal: personalEmails,
		};
	}, [contactDetails]);

	const dropdownMarginLeftPersonalMail = useMemo(() => {
		if (
			filteredEmails.professional?.length > 0 &&
			contactDetails &&
			contactDetails?.phones.length > 0
		) {
			return "460px";
		} else if (contactDetails && contactDetails?.phones.length > 0) {
			return "180px";
		} else {
			return "300px";
		}
	}, [filteredEmails.professional?.length, contactDetails]);

	const noDetailsFound = useMemo(() => {
		return (
			contactDetails &&
			contactDetails.last_modified_at &&
			contactDetails.phones.length === 0 &&
			contactDetails.emails.length === 0
		);
	}, [contactDetails]);

	useOutsideClick(mobileDropdownRef, () => setShowPhoneNumberMore(false));
	useOutsideClick(personalMailDropdownRef, () => setShowPersonalMailMore(false));
	useOutsideClick(professionalMailDropdownRef, () => setShowProfessionalMailMore(false));

	return (
		<>
			{contactDetails && (
				<>
					{/*Phone group*/}
					<CandidateContactDetailsInfoGourp
						icon={TelephoneIcon}
						items={contactDetails.phones.map((phone) => phone.phone)}
						toggleView={togglePhoneNumberView}
						showMore={showPhoneNumberMore}
						iconSize={"18px"}
						reference={mobileDropdownRef}
						testId="seeMorePhone"
						customMargin={""}
					/>

					{/*Professional mail group*/}
					<CandidateContactDetailsInfoGourp
						icon={MailIcon}
						items={filteredEmails.professional.map((email) => email.email)}
						toggleView={toggleProfessionalMailView}
						showMore={showProfessionalMailMore}
						iconSize={"19px"}
						reference={professionalMailDropdownRef}
						testId="seeMoreProfessionalMail"
						customMargin={
							contactDetails && contactDetails?.phones.length > 0 ? "165px" : ""
						}
					/>

					{/*Personal mails group*/}
					<CandidateContactDetailsInfoGourp
						icon={ForwardToMailIcon}
						items={filteredEmails.personal.map((email) => email.email)}
						toggleView={togglePersonalMailView}
						showMore={showPersonalMailMore}
						iconSize={"16px"}
						reference={personalMailDropdownRef}
						testId="seeMorePersonalMail"
						customMargin={dropdownMarginLeftPersonalMail}
					/>
				</>
			)}

			{loading && (
				<div className={styles.skeleton} data-testid="loading-indicator">
					{Array.from({length: 3}, (_, idx) => (
						<div key={idx}>
							<ContentLoader
								style={{marginTop: 10}}
								speed={2}
								height={30}
								width={"100%"}
								backgroundColor="#EEEDEC"
								foregroundColor="#e6e6e6"
							>
								<rect x="1" y="5.5" width="100%" height="18" />
							</ContentLoader>
						</div>
					))}
				</div>
			)}

			{!contactDetails && !loading && <EmptyContactDetails />}

			{noDetailsFound && !loading && (
				<p style={{color: "#4E5555"}}>No contact information was found... </p>
			)}
		</>
	);
};

export default CandidateContactDetails;
