import type {FC} from "react";
import classNames from "classnames";

import type {TBadgeTableProps} from "./types";
import styles from "./OrganizationItem.module.scss";

const Badge: FC<TBadgeTableProps> = ({status}) => (
	<p
		className={classNames(styles.badge, {
			[styles.badge__active]: status,
			[styles.badge__de_active]: !status,
		})}
	>
		<span
			className={classNames({
				[styles.badge__active_dot]: status,
				[styles.badge__de_active_dot]: !status,
			})}
		/>
		{status ? "Active" : "Deactive"}
	</p>
);

export default Badge;
