import {useAppDispatch, useAppSelector} from "hooks";
import {
	TJobFunctionPosition,
	TJobFunctionPositionConnectivityLogic,
} from "api/filterOptionsApi/JobFunctionApi/types";
import {useCallback, useMemo} from "react";
import {filterPositionOptionsSelector} from "store/mapx/filter/filterSelectors";
import {TRootState} from "types";
import {TabSelect} from "components";
import {FunctionNSpecialismSearchFilter} from "mapx-components";
import {
	apAllSpecialismsCountSelector,
	apAnyJobFunctionTagsSelector,
	apAnyPositionConnectivityLogicSelector,
	apCurrentJobFunctionTagsSelector,
	apCurrentPositionConnectivityLogicSelector,
	apJobFunctionCandidatePositionSelector,
	apJobFunctionFilterConfigSelector,
	apJobFunctionSelectionTreeSelector,
	apPreviousJobFunctionTagsSelector,
	apPreviousPositionConnectivityLogicSelector,
	apSpecialismsByPositionSelector,
} from "store/mapx/additional-profiles/apJobFunctionFilterSelectors";
import {
	setAPJobFunctionFilterConfig,
	setAPMultipleFilters,
} from "store/mapx/additional-profiles/additionalProfilesActions";
import {
	clearSpecialismsByPositionForAPCandidate,
	moveSpecialismForAPCandidate,
	setBulkSpecialismsForAPCandidates,
	setSpecialismForAPCandidate,
	switchSpecialismsConnectorForAPCandidatePosition,
} from "store/mapx/additional-profiles/apJobFunctionFilterAsyncAction";

function JobFunctionFilterForAP() {
	const dispatch = useAppDispatch();

	const jobFunctionFilterConfig = useAppSelector(apJobFunctionFilterConfigSelector);

	const position = useAppSelector<TJobFunctionPosition>(apJobFunctionCandidatePositionSelector);

	const currentPositionConnectivityLogic = useAppSelector<TJobFunctionPositionConnectivityLogic>(
		apCurrentPositionConnectivityLogicSelector,
	);

	const previousPositionConnectivityLogic = useAppSelector<TJobFunctionPositionConnectivityLogic>(
		apPreviousPositionConnectivityLogicSelector,
	);

	const anyPositionConnectivityLogic = useAppSelector<TJobFunctionPositionConnectivityLogic>(
		apAnyPositionConnectivityLogicSelector,
	);

	const logicByCurrentlySelectedPosition = useMemo(() => {
		if (position === "current") {
			return currentPositionConnectivityLogic;
		} else if (position === "previous") {
			return previousPositionConnectivityLogic;
		} else {
			return anyPositionConnectivityLogic;
		}
	}, [
		anyPositionConnectivityLogic,
		currentPositionConnectivityLogic,
		position,
		previousPositionConnectivityLogic,
	]);

	const filterPositionOptions = useAppSelector(filterPositionOptionsSelector);

	const selectionTree = useAppSelector((state: TRootState) =>
		apJobFunctionSelectionTreeSelector(state, position, logicByCurrentlySelectedPosition),
	);

	const anyTags = useAppSelector(apAnyJobFunctionTagsSelector);

	const currentTags = useAppSelector(apCurrentJobFunctionTagsSelector);

	const previousTags = useAppSelector(apPreviousJobFunctionTagsSelector);

	const selectedSpecialismIds = useAppSelector((state) =>
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		apSpecialismsByPositionSelector(state, position, logicByCurrentlySelectedPosition),
	);

	const count = useAppSelector(apAllSpecialismsCountSelector);

	const handlePositionChange = useCallback(
		(updatedPositionValue: string) => {
			dispatch(
				setAPJobFunctionFilterConfig({
					...jobFunctionFilterConfig,
					active_position: updatedPositionValue,
				}),
			);
		},
		[dispatch, jobFunctionFilterConfig],
	);

	const handleSpecialismChange = useCallback(
		(id: number) => {
			dispatch(
				setSpecialismForAPCandidate({
					position: position,
					logic: logicByCurrentlySelectedPosition,
					id: id,
				}),
			);
		},
		[dispatch, logicByCurrentlySelectedPosition, position],
	);

	const handleTagClick = useCallback(
		(
			id: number,
			position: TJobFunctionPosition,
			logic: TJobFunctionPositionConnectivityLogic,
		) => {
			dispatch(
				setSpecialismForAPCandidate({
					position,
					logic,
					id,
				}),
			);
		},
		[dispatch],
	);

	const getLogicConnectorByPosition = useCallback(
		(positionParam: TJobFunctionPosition) => {
			let logic: TJobFunctionPositionConnectivityLogic;

			switch (positionParam) {
				case "current":
					logic = currentPositionConnectivityLogic;
					break;
				case "previous":
					logic = previousPositionConnectivityLogic;
					break;
				case "any":
					logic = anyPositionConnectivityLogic;
					break;
				default:
					throw Error("This position doesn't exist");
			}

			return logic;
		},
		[
			anyPositionConnectivityLogic,
			currentPositionConnectivityLogic,
			previousPositionConnectivityLogic,
		],
	);

	const moveSpecialismTagPosition = useCallback(
		(id: number, fromPosition: TJobFunctionPosition, toPosition: TJobFunctionPosition) => {
			const fromLogic = getLogicConnectorByPosition(fromPosition);
			const toLogic = getLogicConnectorByPosition(toPosition);

			dispatch(
				moveSpecialismForAPCandidate({
					from: fromPosition,
					to: toPosition,
					fromLogic: fromLogic,
					toLogic: toLogic,
					id: id,
				}),
			);
		},
		[dispatch, getLogicConnectorByPosition],
	);

	const handleBulkUpdate = useCallback(
		(ids: number[]) => {
			dispatch(
				setBulkSpecialismsForAPCandidates(position, logicByCurrentlySelectedPosition, ids),
			);
		},
		[dispatch, logicByCurrentlySelectedPosition, position],
	);

	const handleClearTagsClickByPosition = useCallback(
		(position: TJobFunctionPosition) => {
			dispatch(clearSpecialismsByPositionForAPCandidate(position));
		},
		[dispatch],
	);

	const handleResetClick = useCallback(() => {
		dispatch(
			setAPMultipleFilters({
				specialisms_and: [],
				specialisms_or: [],
				specialisms_not: [],
				current_specialisms_and: [],
				current_specialisms_or: [],
				current_specialisms_not: [],
				previous_specialisms_and: [],
				previous_specialisms_or: [],
				previous_specialisms_not: [],
			}),
		);
	}, [dispatch]);

	const handleConnectivityOptionChange = useCallback(
		(position: TJobFunctionPosition, toLogic: TJobFunctionPositionConnectivityLogic) => {
			let fromLogic: TJobFunctionPositionConnectivityLogic;

			switch (position) {
				case "current":
					fromLogic = currentPositionConnectivityLogic;

					dispatch(
						setAPJobFunctionFilterConfig({
							...jobFunctionFilterConfig,
							active_current_position_connect_logic: toLogic,
						}),
					);
					break;
				case "previous":
					fromLogic = previousPositionConnectivityLogic;

					dispatch(
						setAPJobFunctionFilterConfig({
							...jobFunctionFilterConfig,
							active_previous_position_connect_logic: toLogic,
						}),
					);
					break;
				case "any":
					fromLogic = anyPositionConnectivityLogic;

					dispatch(
						setAPJobFunctionFilterConfig({
							...jobFunctionFilterConfig,
							active_any_position_connect_logic: toLogic,
						}),
					);
					break;
				default:
					throw Error("This position doesn't exist");
			}

			dispatch(
				switchSpecialismsConnectorForAPCandidatePosition({
					position,
					fromLogic,
					toLogic,
				}),
			);
		},
		[
			anyPositionConnectivityLogic,
			currentPositionConnectivityLogic,
			dispatch,
			jobFunctionFilterConfig,
			previousPositionConnectivityLogic,
		],
	);

	return (
		<div>
			<TabSelect.LabelContainer label="Position">
				<TabSelect
					selected={position}
					onTabChange={handlePositionChange}
					options={filterPositionOptions}
				/>
			</TabSelect.LabelContainer>

			<FunctionNSpecialismSearchFilter
				selectionTree={selectionTree}
				handleTagClick={handleTagClick}
				handleResetClick={handleResetClick}
				displayResetFilterOption={count > 0}
				handleBulkUpdate={handleBulkUpdate}
				handleSpecialismChange={handleSpecialismChange}
				selectedSpecialismIds={selectedSpecialismIds}
				moveSpecialismTagPosition={moveSpecialismTagPosition}
				selectedSpecialismTags={{
					current: currentTags,
					previous: previousTags,
					any: anyTags,
				}}
				handleConnectivityOptionChange={handleConnectivityOptionChange}
				connectionLogics={{
					current: currentPositionConnectivityLogic,
					previous: previousPositionConnectivityLogic,
					any: anyPositionConnectivityLogic,
				}}
				clearTagsByPosition={handleClearTagsClickByPosition}
			/>
		</div>
	);
}

export default JobFunctionFilterForAP;
