import React, {useCallback, useMemo, useState} from "react";
import {
	activeImportPercentageForProject,
	currentProjectBulkImportURLsSelector,
	isImportInProgressForCurrentProject,
	lastCompletedCandidateImportByLinkedInForProjectSelector,
	showBulkImportReportForProjectSelector,
	showLastBulkImportReportSelector,
} from "store/mapx/linkedin-bulk-import/linkedinBulkImportSelectors";
import {projectSelector} from "store/mapx/project-list/projectListSelectors";
import {useAppDispatch, useAppSelector} from "hooks";
import {
	activeAddPeopleModalForProject,
	removeBulkImportDataForSource,
	resetBulkImportStatesForProject,
	setNonClosedProjectIdForLinkedinBulkImport,
} from "store/mapx/linkedin-bulk-import/linkedInBulkImportActions";
import {toast} from "react-toastify";
import {TState} from "mapx-components/Modals/SearchByLinkedInModal/types";
import {AddPeopleByLinkedIn} from "mapx-components";
import styles from "mapx-components/AddPeopleByLinkedIn/styles.module.scss";
import {isLastBulkImportReportDataLoadingSelector} from "store/mapx/background-tasks/backgroundTaskSelectors";
import {sortUrls} from "mapx-components/AddPeopleByLinkedIn/util";
import {importLinkedInProfileToProject} from "store/mapx/linkedin-bulk-import/linkedInProfilesImportAsyncActions";

const AddPeople = () => {
	const project = useAppSelector(projectSelector);

	const importPercentage = useAppSelector(activeImportPercentageForProject);

	const currentProjectUrls = useAppSelector(currentProjectBulkImportURLsSelector);

	const dispatch = useAppDispatch();

	const inProgress = useAppSelector(isImportInProgressForCurrentProject);

	const isLastBulkImportDataLoading = useAppSelector(isLastBulkImportReportDataLoadingSelector);

	const handleOnSubmit = async (urls: string[]): Promise<TState> => {
		dispatch(setNonClosedProjectIdForLinkedinBulkImport(project.id));

		dispatch(importLinkedInProfileToProject(urls, project, "import"));

		return "link-submit";
	};

	const onSuccessClose = async (projectID: number) => {
		dispatch(resetBulkImportStatesForProject(projectID));

		dispatch(removeBulkImportDataForSource(projectID));

		toast.dismiss(`import_success_toaster${projectID}`);
		toast.dismiss(`import_error_toaster${projectID}`);
	};

	const setInitialState = useMemo((): TState => {
		if (importPercentage === 100) {
			return "success";
		}

		return "link-submit";
	}, [importPercentage]);

	const toggleModalDisplay = useCallback(
		(displayed: boolean) => {
			if (displayed) {
				dispatch(activeAddPeopleModalForProject(project?.id));
			} else {
				dispatch(activeAddPeopleModalForProject(null));
			}
		},
		[dispatch, project?.id],
	);

	const [modalDisplay, setModalDisplay] = useState<boolean>(false);

	const showLastBulkImportReport = useAppSelector(showLastBulkImportReportSelector);

	const shouldOpenReportModal = useAppSelector(showBulkImportReportForProjectSelector);

	const lastCompletedCandidateImportByLinkedInForProject = useAppSelector(
		lastCompletedCandidateImportByLinkedInForProjectSelector,
	);

	const shouldRenderLastLinkedInBulkImportReport = useMemo(() => {
		const lastCompletedImport = lastCompletedCandidateImportByLinkedInForProject[project?.id];

		return lastCompletedImport?.id && showLastBulkImportReport && !inProgress;
	}, [
		showLastBulkImportReport,
		lastCompletedCandidateImportByLinkedInForProject,
		inProgress,
		project?.id,
	]);

	const importHistoryData = useMemo(() => {
		return sortUrls(
			lastCompletedCandidateImportByLinkedInForProject[project?.id]?.importInfo
				.linkedin_candidate_urls,
		);
	}, [project?.id, lastCompletedCandidateImportByLinkedInForProject]);

	return (
		<AddPeopleByLinkedIn
			containerClass={styles.addPeopleByLinkedIn}
			onSubmit={handleOnSubmit}
			source={"project"}
			inProgress={inProgress}
			onSuccessClose={onSuccessClose}
			linkedInUrl={currentProjectUrls}
			isLastBulkImportDataLoading={isLastBulkImportDataLoading}
			initialState={setInitialState}
			toggleModalDisplay={toggleModalDisplay}
			modalDisplay={modalDisplay}
			setModalDisplay={setModalDisplay}
			shouldOpenReportModal={shouldOpenReportModal}
			renderLastCompletedReport={shouldRenderLastLinkedInBulkImportReport}
			lastCompletedReportData={importHistoryData}
			importPercentage={importPercentage}
			lastCompletedImportID={
				lastCompletedCandidateImportByLinkedInForProject[project?.id]?.id
			}
		/>
	);
};

export default AddPeople;
