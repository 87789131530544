import axios, {CanceledError, CancelTokenSource} from "axios";
import {
	getSearchedCompaniesDone,
	getSearchedCompaniesFail,
	getSearchedCompaniesInit,
} from "store/mapx/filter/filterActions";
import mapXCompanyApi from "api/companyApi";
import {TAppDispatch, TRootState} from "types";
import {setSelectedCompaniesDone} from "store/mapx/company/companyActions";
import {selectedCompaniesSelector} from "store/mapx/filter/filterSelectors";
import {successResponse} from "helpers/map";
import {removeDuplicateObjectFromArray} from "helpers/filterHandlers";

const cancelTokens: {[key: string]: CancelTokenSource} = {};
export const getSearchedCompaniesData =
	(name: string, page = 1) =>
	async (dispatch: TAppDispatch) => {
		const requestKey = "SEARCH_COMPANY_BY_NAME";

		if (cancelTokens[requestKey]) {
			cancelTokens[requestKey].cancel("Operation canceled due to new request.");
		}

		cancelTokens[requestKey] = axios.CancelToken.source();

		const config = {
			cancelToken: cancelTokens[requestKey].token,
		};

		dispatch(getSearchedCompaniesInit());

		let response;

		try {
			if (name !== null) {
				const payload = {
					filters: {
						name: name,
					},
					pagination: {
						page: page,
						per_page: 20,
					},
				};

				response = await mapXCompanyApi.fetchCompaniesByFilter(payload, config);

				if (response == null) {
					throw new CanceledError("Operation canceled due to new request.");
				} else if (response.status === 200) {
					dispatch(
						getSearchedCompaniesDone({
							results: response.data.results,
							pagination: response.data.pagination,
						}),
					);
				}
			}
		} catch (error) {
			dispatch(getSearchedCompaniesFail({error}));

			return {error};
		}
	};

export const getSearchedCompaniesDataByDescription =
	(description: string) => async (dispatch: TAppDispatch, getState: TRootState) => {
		const requestKey = "SEARCH_COMPANY_BY_DESCRIPTION";

		if (cancelTokens[requestKey]) {
			cancelTokens[requestKey].cancel("Operation canceled due to new request.");
		}

		cancelTokens[requestKey] = axios.CancelToken.source();

		const config = {
			cancelToken: cancelTokens[requestKey].token,
		};

		const state = getState();

		const selectedCompanies = selectedCompaniesSelector(state);

		dispatch(getSearchedCompaniesInit());

		try {
			if (description !== null) {
				const response = await mapXCompanyApi.getCompanyLLMSuggestions(description, config);

				if (response == null) {
					throw new CanceledError("Operation canceled due to new request.");
				} else if (successResponse(response, 200)) {
					const responseCompanies = response.data?.results || [];

					dispatch(
						setSelectedCompaniesDone(
							removeDuplicateObjectFromArray([
								...selectedCompanies,
								...responseCompanies,
							]),
						),
					);
				}
			}
		} catch (error) {
			dispatch(getSearchedCompaniesFail({error}));

			return {error};
		}
	};
