import React from "react";
import type {FC} from "react";
import classNames from "classnames";

import {ChartLine} from "assets/icons";
import MainPerson from "assets/images/MainPerson.png";

import type {ICardProps} from "./types";
import {randomEmptyLineWidth, personChart} from "./utils";

import styles from "./AuthView.module.scss";

const LoginCard: FC<ICardProps> = ({
	users,
	title,
	chart,
	badge,
	children,
	emptyData,
	chartPerson,
	cardClassNames = "",
}) => {
	const cardClasses = classNames(styles.ui__card, {
		[cardClassNames]: cardClassNames,
	});

	const renderUser = users?.map(({src, name, subName}, idx) => {
		const nameClasses = classNames({
			[styles.ui__card_container__user_item_name]: name,
			[styles.ui__card_container__user_item_empty]: !name,
		});
		const subNameClasses = classNames({
			[styles.ui__card_container__user_item_empty]: !subName,
			[styles.ui__card_container__user_item_sub_name]: subName,
		});

		return (
			<div key={idx} className={styles.ui__card_container__user}>
				<img src={src} className={styles.ui__card_container__user_img} />

				<div className={styles.ui__card_container__user_item}>
					<span
						className={nameClasses}
						style={{width: `${!name ? randomEmptyLineWidth(idx) : 100}%`}}
					>
						{name}
					</span>

					<span
						className={subNameClasses}
						style={{width: `${!subName ? randomEmptyLineWidth(idx) : 100}%`}}
					>
						{subName}
					</span>
				</div>
			</div>
		);
	});

	const renderChartEmptyData = emptyData?.map(({color}, idx) => (
		<div key={idx} className={styles.ui__card__chart__item_el}>
			<span
				style={{backgroundColor: color}}
				className={styles.ui__card__chart__item_el_badge}
			/>
			<span className={styles.ui__card__chart__item_el_empty} />
		</div>
	));

	const renderChart = () => (
		<div className={styles.ui__card__chart}>
			{children && children}
			<div className={styles.ui__card__chart__item}>{emptyData && renderChartEmptyData}</div>
		</div>
	);

	const renderChartPersons = () => (
		<div className={styles.ui__card__relative}>
			<img src={MainPerson} className={styles.ui__card__relative__img} />
			<ChartLine className={styles.ui__card__relative__chart} />
			<div className={styles.ui__card__relative__person_container}>
				{personChart?.map(({src}, idx) => (
					<img key={idx} src={src} />
				))}
			</div>
		</div>
	);

	return (
		<div className={cardClasses}>
			{badge && <span className={styles.ui__card_badge}>{badge}</span>}

			{title && <h3 className={styles.ui__card_title}>{title}</h3>}

			{users && <div className={styles.ui__card_container}>{renderUser}</div>}

			{chart && renderChart()}

			{chartPerson && renderChartPersons()}
		</div>
	);
};

export default LoginCard;
