import type {FC} from "react";

import type {IconProps} from "./types";

const DeleteUserIcon: FC<IconProps> = ({className = "", fill = "#C91515"}) => (
	<svg
		width="52"
		fill="none"
		height="52"
		viewBox="0 0 52 52"
		className={className}
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fill={fill}
			d="M25.9998 38.6C26.5332 38.6 26.9665 38.4334 27.2998 38.1C27.6332 37.7667 27.7998 37.3334 27.7998 36.8C27.7998 36.2667 27.6332 35.8334 27.2998 35.5C26.9665 35.1667 26.5332 35 25.9998 35C25.4665 35 25.0332 35.1667 24.6998 35.5C24.3665 35.8334 24.1998 36.2667 24.1998 36.8C24.1998 37.3334 24.3665 37.7667 24.6998 38.1C25.0332 38.4334 25.4665 38.6 25.9998 38.6ZM24.6665 29.2H27.6665V12.6H24.6665V29.2ZM25.9998 51.3334C22.4887 51.3334 19.1998 50.6667 16.1332 49.3334C13.0665 48 10.3887 46.1889 8.09984 43.9C5.81095 41.6111 3.99984 38.9334 2.6665 35.8667C1.33317 32.8 0.666504 29.5111 0.666504 26C0.666504 22.4889 1.33317 19.1889 2.6665 16.1C3.99984 13.0111 5.81095 10.3334 8.09984 8.06669C10.3887 5.80002 13.0665 4.00002 16.1332 2.66669C19.1998 1.33335 22.4887 0.666687 25.9998 0.666687C29.511 0.666687 32.8109 1.33335 35.8998 2.66669C38.9887 4.00002 41.6665 5.80002 43.9332 8.06669C46.1998 10.3334 47.9998 13.0111 49.3332 16.1C50.6665 19.1889 51.3332 22.4889 51.3332 26C51.3332 29.5111 50.6665 32.8 49.3332 35.8667C47.9998 38.9334 46.1998 41.6111 43.9332 43.9C41.6665 46.1889 38.9887 48 35.8998 49.3334C32.8109 50.6667 29.511 51.3334 25.9998 51.3334ZM26.0665 48.3334C32.2443 48.3334 37.4998 46.1556 41.8332 41.8C46.1665 37.4445 48.3332 32.1556 48.3332 25.9334C48.3332 19.7556 46.1665 14.5 41.8332 10.1667C37.4998 5.83335 32.2221 3.66669 25.9998 3.66669C19.8221 3.66669 14.5554 5.83335 10.1998 10.1667C5.84428 14.5 3.6665 19.7778 3.6665 26C3.6665 32.1778 5.84428 37.4445 10.1998 41.8C14.5554 46.1556 19.8443 48.3334 26.0665 48.3334Z"
		/>
	</svg>
);

export default DeleteUserIcon;
