import {
	UPDATE_CREDITS_USED_KEY,
	GET_ORGANISATIONS_DONE,
	GET_ORGANISATIONS_FAIL,
	GET_ORGANISATIONS_INIT,
	RESEND_INVITATION_DONE,
	RESEND_INVITATION_FAIL,
	RESEND_INVITATION_INIT,
	REVOKE_INVITATION_DONE,
	REVOKE_INVITATION_FAIL,
	REVOKE_INVITATION_INIT,
	REVOKE_USER_DONE,
	REVOKE_USER_FAIL,
	REVOKE_USER_INIT,
	SORT_CURRENT_USERS,
	SORT_INVITED_USERS,
	CREATE_ORGANISATION_INIT,
	CREATE_ORGANISATION_DONE,
	CREATE_ORGANISATION_FAIL,
	CREATE_INVITATION_INIT,
	CREATE_INVITATION_DONE,
	CREATE_INVITATION_FAIL,
	SET_NEW_ORGANISATION_NAME,
	VERIFY_INVITATION_INIT,
	VERIFY_INVITATION_FAIL,
	VERIFY_INVITATION_DONE,
	GET_ORGANIZATION_LIST_INIT,
	GET_ORGANIZATION_LIST_FAIL,
	GET_ORGANIZATION_LIST_DONE,
	GET_ORGANISATION_USERS_INIT,
	GET_ORGANISATION_USERS_FAIL,
	GET_ORGANISATION_USERS_DONE,
	DELETE_ORGANIZATION_INIT,
	DELETE_ORGANIZATION_DONE,
	DELETE_ORGANIZATION_FAIL,
	UPDATE_ORGANIZATION_INIT,
	UPDATE_ORGANIZATION_DONE,
	UPDATE_ORGANIZATION_FAIL,
	GET_INVITATIONS_INIT,
	GET_INVITATIONS_DONE,
	GET_INVITATIONS_FAIL,
	SET_ORGANIZATION_LIST_ORDERING,
	GET_ORGANIZATION_TABLE_DATA_INIT,
	GET_ORGANIZATION_TABLE_DATA_DONE,
	GET_ORGANIZATION_TABLE_DATA_FAIL,
	GET_SEATS_INIT,
	GET_SEATS_DONE,
	GET_SEATS_FAIL,
} from "../mapXActionTypes";

export function getOrganisationsInit() {
	return {type: GET_ORGANISATIONS_INIT};
}

export function getOrganisationsDone(data) {
	return {type: GET_ORGANISATIONS_DONE, payload: data};
}

export function getOrganisationsFail(error) {
	return {type: GET_ORGANISATIONS_FAIL, payload: error};
}

export function revokeUserInit(userID) {
	return {type: REVOKE_USER_INIT, payload: userID};
}

export function revokeUserDone(userID) {
	return {type: REVOKE_USER_DONE, payload: userID};
}

export function revokeUserFail(error, userID) {
	return {type: REVOKE_USER_FAIL, payload: {error, userID}};
}

export function sortCurrentUsers(key) {
	return {type: SORT_CURRENT_USERS, payload: key};
}

export function sortInvitedUsers(key) {
	return {type: SORT_INVITED_USERS, payload: key};
}

export function verifyInvitationInit(invitationCode) {
	return {type: VERIFY_INVITATION_INIT, payload: invitationCode};
}
export function verifyInvitationFail(invitationCode) {
	return {type: VERIFY_INVITATION_FAIL, payload: invitationCode};
}
export function verifyInvitationDone(invitationCode) {
	return {type: VERIFY_INVITATION_DONE, payload: invitationCode};
}

export function resendInvitationInit(invitationID) {
	return {type: RESEND_INVITATION_INIT, payload: invitationID};
}

export function resendInvitationDone(invitationID) {
	return {type: RESEND_INVITATION_DONE, payload: invitationID};
}

export function resendInvitationFail(error, invitationID) {
	return {type: RESEND_INVITATION_FAIL, payload: {error, invitationID}};
}

export function revokeInvitationInit(invitationID) {
	return {type: REVOKE_INVITATION_INIT, payload: invitationID};
}

export function revokeInvitationDone(invitationID) {
	return {type: REVOKE_INVITATION_DONE, payload: invitationID};
}

export function revokeInvitationFail(error, invitationID) {
	return {type: REVOKE_INVITATION_FAIL, payload: {invitationID, error}};
}

export function createOrganisationInit() {
	return {type: CREATE_ORGANISATION_INIT};
}

export function createOrganisationDone(data) {
	return {type: CREATE_ORGANISATION_DONE, payload: data};
}

export function createOrganisationFail(error) {
	return {type: CREATE_ORGANISATION_FAIL, payload: error};
}

export function deleteOrganizationInit() {
	return {type: DELETE_ORGANIZATION_INIT};
}

export function deleteOrganizationDone(data) {
	return {type: DELETE_ORGANIZATION_DONE, payload: data};
}

export function deleteOrganizationFail(error) {
	return {type: DELETE_ORGANIZATION_FAIL, payload: error};
}

export function updateOrganizationInit() {
	return {type: UPDATE_ORGANIZATION_INIT};
}

export function updateOrganizationDone(data) {
	return {type: UPDATE_ORGANIZATION_DONE, payload: data};
}

export function updateOrganizationFail(error) {
	return {type: UPDATE_ORGANIZATION_FAIL, payload: error};
}

export function createInvitationInit() {
	return {type: CREATE_INVITATION_INIT};
}

export function createInvitationDone(data) {
	return {type: CREATE_INVITATION_DONE, payload: data};
}

export function createInvitationFail(error) {
	return {type: CREATE_INVITATION_FAIL, payload: error};
}

export function setNewOrganisationName(name) {
	return {type: SET_NEW_ORGANISATION_NAME, payload: name};
}

export const getOrganizationListInit = () => {
	return {type: GET_ORGANIZATION_LIST_INIT};
};

export const getOrganizationListSuccess = (data) => {
	return {type: GET_ORGANIZATION_LIST_DONE, payload: data};
};

export const getOrganizationListFailed = (error) => {
	return {type: GET_ORGANIZATION_LIST_FAIL, payload: error};
};

export const getOrganizationTableDataInit = () => {
	return {type: GET_ORGANIZATION_TABLE_DATA_INIT};
};

export const getOrganizationTableDataSuccess = (data) => {
	return {type: GET_ORGANIZATION_TABLE_DATA_DONE, payload: data};
};

export const getOrganizationTableDataFailed = (error) => {
	return {type: GET_ORGANIZATION_TABLE_DATA_FAIL, payload: error};
};

export const setOrganizationListOrdering = (data) => {
	return {type: SET_ORGANIZATION_LIST_ORDERING, payload: data};
};

export const getOrganisationUsersInit = () => {
	return {type: GET_ORGANISATION_USERS_INIT};
};

export const getOrganisationUsersDone = (data) => {
	return {type: GET_ORGANISATION_USERS_DONE, payload: data};
};

export const getOrganisationUsersFailed = (error) => {
	return {type: GET_ORGANISATION_USERS_FAIL, payload: error};
};

export const updateCreditsUsedKey = (updatedKey) => {
	return {
		type: UPDATE_CREDITS_USED_KEY,
		payload: {
			updatedKey,
		},
	};
};

export function getInvitationsInit() {
	return {type: GET_INVITATIONS_INIT};
}

export function getInvitationsDone(data) {
	return {type: GET_INVITATIONS_DONE, payload: data};
}

export function getInvitationsFail(error) {
	return {type: GET_INVITATIONS_FAIL, payload: error};
}

export function getSeatsInit() {
	return {type: GET_SEATS_INIT};
}

export function getSeatsDone(data) {
	return {type: GET_SEATS_DONE, payload: data};
}

export function getSeatsFail(error) {
	return {type: GET_SEATS_FAIL, payload: error};
}
