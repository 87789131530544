import {Tag} from "components";
import FilterTags from "mapx-components/FilterTags";
import React, {DragEvent, useCallback} from "react";
import {TCompanyHeadcountTagProps} from "./types";
import {
	TCompanyHeadcountPosition,
	TCompanyHeadcountRangeItem,
} from "mapx-components/Filters/CompanyHeadcountSearchFilter/types";

function Tags({
	tagLabel,
	tagValue,
	tags,
	handleTagClick,
	clearTagsByPosition,
	moveHeadcountTagPosition,
}: TCompanyHeadcountTagProps) {
	const handleTagDragStart = useCallback(
		(e: React.DragEvent, label: string) => {
			if (tagValue) {
				e.dataTransfer.setData("id", label);
				e.dataTransfer.setData("from", tagValue);
			}
		},
		[tagValue],
	);

	const handleDrop = useCallback(
		(e: DragEvent<HTMLDivElement>) => {
			const value = e.dataTransfer.getData("id");
			const from = e.dataTransfer.getData("from") as TCompanyHeadcountPosition;

			moveHeadcountTagPosition(value, from, tagValue);
		},
		[moveHeadcountTagPosition, tagValue],
	);

	return (
		<FilterTags
			onDrop={handleDrop}
			label={tagLabel || ""}
			count={tags?.length}
			moveDisabled={true}
			tags={tags?.map((item: TCompanyHeadcountRangeItem) => (
				<Tag
					key={item.id}
					onClick={() => handleTagClick(item)}
					draggable={true}
					onDragStart={(e) => handleTagDragStart(e, item.id)}
					onDragOver={(e) => e.preventDefault()}
				>
					{`${item.name}` || ""}
				</Tag>
			))}
			onClear={() => clearTagsByPosition(tagValue)}
		/>
	);
}

export default React.memo(Tags);
