import type {FC} from "react";

import type {IconProps} from "./types";

const FourOFourIcon: FC<IconProps> = (props) => {
	return (
		<svg
			width={256}
			height={256}
			viewBox="0 0 256 256"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<g clipPath="url(#prefix__clip0_1511_19442)">
				<path
					d="M128 256c70.692 0 128-57.308 128-128C256 57.308 198.692 0 128 0 57.308 0 0 57.307 0 128c0 70.692 57.307 128 128 128z"
					fill="#F4F4F4"
				/>
				<rect x={36} y={58} width={184} height={140} rx={8} fill="#CBC5C2" />
				<path
					d="M47.906 74a4 4 0 100-8 4 4 0 000 8zM59.906 74a4 4 0 100-8 4 4 0 000 8zM71.906 74a4 4 0 100-8 4 4 0 000 8z"
					fill="#0C5850"
				/>
				<path
					d="M212 72c0 1.1-.975 2-2.167 2H84.167C82.975 74 82 73.1 82 72v-4c0-1.1.975-2 2.167-2h125.666c1.192 0 2.167.9 2.167 2v4zM212 186c0 2.2-1.8 4-4 4H48c-2.2 0-4-1.8-4-4V86c0-2.2 1.8-4 4-4h160c2.2 0 4 1.8 4 4v100z"
					fill="#F5F5F5"
				/>
				<path
					opacity={0.1}
					d="M117.581 121.882c-2.087-10.684 4.886-21.033 15.574-23.115 10.688-2.082 21.043 4.891 23.13 15.576 2.086 10.684-4.887 21.033-15.575 23.115-10.687 2.082-21.043-4.891-23.129-15.576z"
					fill="#F5F5F5"
				/>
				<path
					d="M127.362 108.164c.663.244 1.444.045 1.882-.545a8.71 8.71 0 015.83-3.335c2.321-.295 4.613.328 6.487 1.726.718.574 1.786.437 2.321-.295.573-.717.436-1.785-.296-2.319a12.183 12.183 0 00-8.974-2.422 11.946 11.946 0 00-8.037 4.645c-.574.717-.437 1.785.295 2.32.195.071.337.168.492.225z"
					fill="#E9E9E9"
				/>
				<path
					d="M151.289 99.423c10.338 7.965 12.282 22.743 4.318 33.074-7.951 10.293-22.734 12.232-33.086 4.306-10.299-7.951-12.244-22.729-4.318-33.075 7.965-10.331 22.787-12.256 33.086-4.305zM124.902 133.7c8.62 6.625 21.015 5.044 27.639-3.57 6.624-8.614 5.025-20.966-3.595-27.59-8.62-6.625-20.975-5.03-27.6 3.584-6.663 8.6-5.063 20.952 3.556 27.576z"
					fill="#E9E9E9"
				/>
				<path
					d="M121.363 134.931l3.258 2.506-11.388 14.797-3.258-2.505 11.388-14.798z"
					fill="#E9E9E9"
				/>
				<path
					d="M91.18 177.568c2.064 1.6 5.045 1.195 6.645-.869l17.506-22.779a2.52 2.52 0 00-.468-3.533l-3.515-2.71a2.52 2.52 0 00-3.534.467l-17.505 22.78a4.71 4.71 0 00.87 6.644z"
					fill="#E9E9E9"
				/>
				<path
					d="M89.039 145.886v-4.843l12.145-19.134h4.176v6.705h-2.472l-7.656 12.116v.227h17.258v4.929H89.039zM103.002 151v-6.591l.113-2.145v-20.355h5.767V151h-5.88zm24.993.639c-2.444-.009-4.546-.611-6.307-1.804-1.752-1.193-3.101-2.921-4.048-5.184-.938-2.264-1.402-4.986-1.392-8.168 0-3.172.468-5.876 1.406-8.111.947-2.235 2.296-3.935 4.048-5.099 1.761-1.175 3.859-1.762 6.293-1.762 2.433 0 4.526.587 6.278 1.762 1.761 1.174 3.116 2.879 4.063 5.113.947 2.226 1.415 4.925 1.406 8.097 0 3.191-.474 5.919-1.421 8.182-.937 2.263-2.282 3.991-4.034 5.184-1.752 1.194-3.849 1.79-6.292 1.79zm0-5.099c1.666 0 2.997-.838 3.991-2.514.994-1.677 1.487-4.191 1.477-7.543 0-2.206-.227-4.044-.681-5.511-.445-1.468-1.08-2.571-1.904-3.31-.814-.739-1.775-1.108-2.883-1.108-1.658 0-2.983.829-3.978 2.486-.994 1.657-1.496 4.138-1.505 7.443 0 2.235.222 4.1.667 5.597.455 1.486 1.094 2.604 1.918 3.352a4.206 4.206 0 002.898 1.108zm15.692-.654v-4.843l12.145-19.134h4.176v6.705h-2.471l-7.657 12.116v.227h17.259v4.929h-23.452zM157.65 151v-6.591l.114-2.145v-20.355h5.767V151h-5.881z"
					fill="#0C5850"
				/>
			</g>
			<defs>
				<clipPath id="prefix__clip0_1511_19442">
					<path fill="#fff" d="M0 0h256v256H0z" />
				</clipPath>
			</defs>
		</svg>
	);
};

export default FourOFourIcon;
