import {useState, type FC} from "react";

import {useAppSelector} from "hooks";
import {
	targetListCompaniesSelector,
	targetListCandidatesSelector,
} from "store/mapx/target-list/targetListSelectors";
import diversityChart from "assets/images/diversityStats.png";
import diverseDiagram from "assets/images/diverseDiagram.png";
import BackgroundAnalysisContainer from "mapx-components/BackgroundAnalysisContainer";

import ChartParts from "./ChartParts";
import UpgradeStep from "./UpgradeStep";
import LocationParts from "./LocationParts";
import {data, dataDuplicate} from "./utils";
import styles from "./UserPreloadInsightSkeleton.module.scss";

const UserPreloadInsightSkeleton: FC = () => {
	const companies = useAppSelector(targetListCompaniesSelector);
	const candidates = useAppSelector(targetListCandidatesSelector);
	const [currentOption, setCurrentOption] = useState<number>(0);
	const [currentIndustryOption, setCurrentIndustryOption] = useState<number>(0);

	return (
		<div className={styles.wrapper}>
			<div className={styles.wrapper__tabs}>
				<div className={styles.wrapper__tabs__active}>
					<p className={styles.wrapper__tabs__active_text}>Saved People</p>

					<p className={styles.wrapper__tabs__active_count}>
						{candidates?.length || "-"}
					</p>
				</div>

				<div className={styles.wrapper__tabs__disabled}>
					<p className={styles.wrapper__tabs__disabled_text}>All Results</p>

					<p className={styles.wrapper__tabs__disabled_count}>
						{companies?.length || "-"}
					</p>
				</div>
			</div>

			<div className={styles.wrapper__page}>
				<div className={styles.wrapper__page__diagram}>
					<ChartParts
						title="Diversity"
						tabActive="Gender"
						deActive="Ethnic"
						blurImage={diversityChart}
						blurText="Percentage of gender diversity in the project"
					/>

					<ChartParts
						deActive="By Industry"
						tabActive="By Function"
						blurImage={diverseDiagram}
						title="Most Diverse Pathways"
						blurText="The number of diverse candidates that have come from function"
					/>
				</div>

				<LocationParts />

				<div className={styles.wrapper__page_func_charts}>
					<BackgroundAnalysisContainer
						data={data}
						isDefaultChart
						isRoundedChartVal
						isLoading={false}
						title="Functional Breakdown"
						currentOption={currentOption}
						setCurrentOption={setCurrentOption}
						pieChartClass={styles.wrapper__page_func_charts__wrapper}
						description={
							!currentOption
								? "The average time people have spent within each function and specialism as their previous roles."
								: "The number of people with experience in each function and specialism as their previous roles."
						}
					/>
					<BackgroundAnalysisContainer
						isDefaultChart
						isRoundedChartVal
						isLoading={false}
						data={dataDuplicate}
						title="Industry Breakdown"
						currentOption={currentIndustryOption}
						setCurrentOption={setCurrentIndustryOption}
						pieChartClass={styles.wrapper__page_func_charts__wrapper}
						description={
							currentIndustryOption
								? "The number of people with experience in each industry."
								: "The average time people have spent within each industry."
						}
					/>
				</div>
				<UpgradeStep />
			</div>
		</div>
	);
};

export default UserPreloadInsightSkeleton;
