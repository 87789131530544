import {Fragment, useState, forwardRef, useImperativeHandle} from "react";
import classNames from "classnames";
import usePortal from "react-useportal";

import {PlayIcon} from "assets/icons";
import {VideoTutorialModal} from "mapx-components";
import {useAppDispatch, useAppSelector} from "hooks";
import {isTutorialModalOpenedSelector} from "store/mapx/user/userSelectors";
import {updateToggleModalHandler} from "store/mapx/user/userActions";

import type {TWatchVideoProps, TWatchVideoHandle} from "./types";
import styles from "./WatchVideo.module.scss";

const WatchVideo = forwardRef<TWatchVideoHandle, TWatchVideoProps>(
	({text, hovered, className = ""}, ref) => {
		const {Portal} = usePortal();
		const dispatch = useAppDispatch();

		const isTutorialModalOpened = useAppSelector(isTutorialModalOpenedSelector);

		const [show, setShow] = useState<boolean>(false);

		const showHandler = () => {
			if (!isTutorialModalOpened) {
				setShow(true);
				dispatch(updateToggleModalHandler());
			}
		};

		const closeHandlerProps = () => {
			setShow(false);
			dispatch(updateToggleModalHandler());
		};

		useImperativeHandle(ref, () => ({
			show,
		}));

		return (
			<Fragment>
				<button
					onClick={showHandler}
					className={classNames(styles.watch, {
						[className]: className,
						[styles.watch__white]: text,
						[styles.watch__hovered]: hovered,
					})}
				>
					<div className={styles.watch__icon}>
						<PlayIcon />
					</div>

					{hovered && <p className={styles.watch__text}>Watch Demo</p>}

					{text || null}
				</button>

				{show && (
					<Portal>
						<VideoTutorialModal closeHandlerProps={closeHandlerProps} />
					</Portal>
				)}
			</Fragment>
		);
	},
);

WatchVideo.displayName = "WatchVideo";

export default WatchVideo;
