import {useCallback, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {toast} from "react-toastify";
import completedIcon from "assets/icons/CompletedIcon";
import {candidateMappingForAllCompaniesSelector} from "store/mapx/company/companySelectors";
import {getCompanyMapFunctionStatusById} from "store/mapx/company/companyAsyncActions";
import CompanyMappingProcessCompleteMessage from "mapx-components/Layouts/Header/CompanyMappingCompleteMessage";

let mapIntervalId = {};

const useCompanyMappingProgressWatcher = () => {
	const candidateMappingForAllCompanies = useSelector(candidateMappingForAllCompaniesSelector);

	const dispatch = useDispatch();

	const [alreadyNotified, setAlreadyNotified] = useState(false);

	const mapWatchNotify = useCallback(
		async (response, autoClose = false) => {
			if (!alreadyNotified) {
				if (response?.status === "Completed") {
					toast.success(CompanyMappingProcessCompleteMessage, {
						data: {id: response.company_id},
						autoClose: autoClose,
						icon: completedIcon,
						closeOnClick: false, // because close option available on the notification
					});
				} else if (response?.error) {
					toast.error("CompanyDetails Mapping: Something went wrong!", {
						autoClose: autoClose,
						closeOnClick: true,
					});
				}

				setAlreadyNotified(true);
			}
		},
		[alreadyNotified],
	);

	const isMappingInProgress = (status) => {
		return status === "Created" || status === "In Progress";
	};

	/**
	 * Here on the state we have kept the information by company
	 * We loop through all the found company on the state with active mapping
	 * Then We watch the mapping status
	 * if company mapping is success, then we notify user its complete, and they can click on the notification
	 * if mapping is failed, then we show notification with reason
	 */

	useEffect(() => {
		if (candidateMappingForAllCompanies) {
			for (const [key, value] of Object.entries(candidateMappingForAllCompanies)) {
				const data = key && value ? value : null;

				if (data && isMappingInProgress(data?.status)) {
					mapIntervalId[key] = null;

					const inProgress = isMappingInProgress(data?.status);

					if (inProgress) {
						mapIntervalId[key] = setInterval(async () => {
							const response = await dispatch(
								getCompanyMapFunctionStatusById(data.id),
							);

							if (response?.status === "Completed") {
								clearInterval(mapIntervalId[key]);

								await mapWatchNotify(response, false);
							} else if (
								response?.status === "Cancelled" ||
								response?.status === "Error"
							) {
								await mapWatchNotify(response, true);
							}
						}, 10 * 1000);

						setTimeout(() => {
							clearInterval(mapIntervalId[key]);
						}, 60 * 300 * 1000); // it will clear after 5 hours
					}

					return () => {
						if (mapIntervalId[key]) {
							clearInterval(mapIntervalId[key]);
						}
					};
				}
			}
		}
	}, [candidateMappingForAllCompanies, dispatch, mapWatchNotify]);
};

export default useCompanyMappingProgressWatcher;
