import {createSelector} from "reselect";
import {TRootState} from "types";
import {
	TCompanyRevenuePosition,
	TCompanyRevenueRangeItem,
} from "mapx-components/Filters/CompanyRevenueSearchFilter/types";
import {companyRevenueRangeOptionsSelector} from "store/mapx/search/searchSelectors";

export const candidateAnyCompanyRevenueSelector = (state: TRootState) =>
	state.filter.candidate_filters.revenue;

export const candidateCurrentCompanyRevenueSelector = (state: TRootState) =>
	state.filter.candidate_filters.current_revenue;

export const candidatePreviousCompanyRevenueSelector = (state: TRootState) =>
	state.filter.candidate_filters.previous_revenue;

export const candidateCompanyRevenueFilterConfigSelector = (state: TRootState) =>
	state.filter.candidate_company_revenue_filter_config;

export const candidateCompanyRevenueFilterActivePositionSelector = (state: TRootState) =>
	state.filter.candidate_company_revenue_filter_config.active_position;

const getCompanyRevenuePosition = (_: TRootState, position: TCompanyRevenuePosition) => position;

export const selectedCurrentCompanyRevenueTagsSelector = createSelector(
	[candidateCurrentCompanyRevenueSelector, companyRevenueRangeOptionsSelector],
	(selectedRevenues, options = []) => {
		const current: TCompanyRevenueRangeItem[] = [];

		options.forEach((item: TCompanyRevenueRangeItem) => {
			if (selectedRevenues.includes(item.id)) {
				current.push(item);
			}
		});

		return current;
	},
);

export const selectedAnyCompanyRevenueTagsSelector = createSelector(
	[candidateAnyCompanyRevenueSelector, companyRevenueRangeOptionsSelector],
	(selectedRevenues, options = []) => {
		const current: TCompanyRevenueRangeItem[] = [];

		options.forEach((item: TCompanyRevenueRangeItem) => {
			if (selectedRevenues.includes(item.id)) {
				current.push(item);
			}
		});

		return current;
	},
);

export const selectedPreviousCompanyRevenueTagsSelector = createSelector(
	[candidatePreviousCompanyRevenueSelector, companyRevenueRangeOptionsSelector],
	(selectedRevenues, options = []) => {
		const current: TCompanyRevenueRangeItem[] = [];

		options.forEach((item: TCompanyRevenueRangeItem) => {
			if (selectedRevenues.includes(item.id)) {
				current.push(item);
			}
		});

		return current;
	},
);

export const companyRevenueFilterKeyByPosition = createSelector(
	getCompanyRevenuePosition,
	(position) => {
		switch (position) {
			case "current":
				return "current_revenue";
			case "previous":
				return "previous_revenue";
			case "any":
			default:
				return "revenue";
		}
	},
);

export const candidateCompanyRevenueByPositionSelector = createSelector(
	[
		candidateCurrentCompanyRevenueSelector,
		candidatePreviousCompanyRevenueSelector,
		candidateAnyCompanyRevenueSelector,
		(_, position) => position,
	],
	(currentRevenue, previousRevenue, anyRevenue, position) => {
		switch (position) {
			case "current":
				return currentRevenue || null;
			case "previous":
				return previousRevenue || null;
			case "any":
				return anyRevenue || null;
			default:
				return [];
		}
	},
);

export const candidatesAllCompanyRevenueCountSelector = createSelector(
	[
		candidateCurrentCompanyRevenueSelector,
		candidatePreviousCompanyRevenueSelector,
		candidateAnyCompanyRevenueSelector,
	],
	(current, previous, any) => current.length + previous.length + any.length,
);
