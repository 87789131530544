import {setMultipleFilterForCandidates} from "store/mapx/filter/filterActions";
import {Dispatch} from "redux";
import {TAppDispatch, TRootState} from "types";
import {
	candidateCompanyRevenueByPositionSelector,
	companyRevenueFilterKeyByPosition,
} from "store/mapx/filter/companyRevenueFilterSelectors";
import {
	TCompanyRevenuePosition,
	TCompanyRevenueRangeItem,
} from "mapx-components/Filters/CompanyRevenueSearchFilter/types";

export const setCompanyRevenueForCandidate =
	({
		position,
		revenueItem,
	}: {
		position: TCompanyRevenuePosition;
		revenueItem: TCompanyRevenueRangeItem;
	}) =>
	async (dispatch: Dispatch<TAppDispatch>, getState: () => TRootState) => {
		const state = getState();

		const headCountFilterKey = companyRevenueFilterKeyByPosition(state, position);

		const idsOnState = candidateCompanyRevenueByPositionSelector(state, position);

		const index = idsOnState.indexOf(revenueItem.id);

		const alreadySelected = index !== -1;

		const nextIds = alreadySelected
			? [...idsOnState.slice(0, index), ...idsOnState.slice(index + 1)]
			: [...idsOnState, revenueItem.id];

		dispatch(
			setMultipleFilterForCandidates({
				[headCountFilterKey]: nextIds,
			}),
		);
	};

export const moveRevenueForCandidate =
	({
		from,
		to,
		ids,
	}: {
		from: TCompanyRevenuePosition;
		to: TCompanyRevenuePosition;
		ids: string[];
	}) =>
	async (dispatch: Dispatch<TAppDispatch>, getState: () => TRootState) => {
		const idsSet = new Set(ids);

		const state = getState();

		const fromFilterKey = companyRevenueFilterKeyByPosition(state, from);

		const toFilterKey = companyRevenueFilterKeyByPosition(state, to);

		const fromIds = candidateCompanyRevenueByPositionSelector(state, from);

		const toIds = candidateCompanyRevenueByPositionSelector(state, to);

		dispatch(
			setMultipleFilterForCandidates({
				[fromFilterKey]: fromIds.filter((id: string) => !idsSet.has(id)),
				[toFilterKey]: [
					...toIds.filter((id: string) => !idsSet.has(id)), // prevent duplication
					...fromIds.filter((id: string) => idsSet.has(id)),
				],
			}),
		);
	};
