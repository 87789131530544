import {useCallback, useRef, useState} from "react";
import classNames from "classnames";

import {ChevronIcon} from "assets/icons";
import {useOutsideClick} from "hooks";

import css from "./index.module.scss";
import Checkbox from "../Checkbox";
import {TMultiSelectOptions, IMultiSelectProps, TMultiSelectGroup} from "./types";

const MultiSelectWithAction = ({
	action,
	actionClick,
	className,
	contentEditable = false,
	isSelected = false,
	options,
	placeholder,
	selected,
	setSelected,
}: IMultiSelectProps) => {
	const ref = useRef<HTMLDivElement>(null);

	const expandedPointer = useRef<boolean>(false);

	const [expanded, setExpanded] = useState<boolean>(false);

	const toggleExpand = useCallback(() => {
		expandedPointer.current = !expanded;

		setExpanded(!expanded);
	}, [expanded]);

	const handleSelect = useCallback(
		(option: TMultiSelectOptions) => {
			if (selected.find((item) => item.value === option.value)) {
				setSelected(selected.filter((item) => item.value !== option.value));
			} else {
				setSelected([...selected, option]);
			}
		},
		[selected, setSelected],
	);

	const handleActionClick = useCallback(() => {
		actionClick && actionClick();
		toggleExpand();
	}, [actionClick, toggleExpand]);

	useOutsideClick(ref, () => setExpanded(false));

	return (
		<div className={classNames(css.container, {[css.expanded]: expanded}, className)}>
			<div
				ref={ref}
				role="select"
				className={classNames(css.select, {[css.expanded]: expanded})}
			>
				<div
					className={classNames(css.textbox, {
						[css.expanded]: expanded,
						[css.editable]: contentEditable,
						[css.data]: selected,
					})}
					suppressContentEditableWarning
					contentEditable={contentEditable}
					role="textbox"
					onClick={toggleExpand}
				>
					{placeholder}
					<div className={css.count}>{selected.length}</div>
					<ChevronIcon width={24} height={24} />
				</div>
				<div
					className={classNames(css.dropdown, {
						[css.expanded]: expanded,
						[css.withAction]: action && !isSelected,
					})}
				>
					{options.map((option: TMultiSelectOptions) => (
						<div
							role="button"
							key={option.value}
							className={css.option}
							onClick={() => handleSelect(option)}
						>
							<Checkbox
								borderColor="#0C5850"
								containerClass={css.checkbox}
								isChecked={!!selected.find((item) => item.value === option.value)}
								readOnly
								value={option.value}
							/>
							{option.label}
						</div>
					))}
					{action && !isSelected && (
						<div role="button" className={css.action} onClick={handleActionClick}>
							{action}
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

// To not set z-index for each SelectWithAction, group them together
// in reverse order by wrapping with <SelectWithAction.Group>
const MultiSelectWithActionGroup = ({children, ...rest}: TMultiSelectGroup) => (
	<div className={css.group} {...rest}>
		{children}
	</div>
);

MultiSelectWithAction.Group = MultiSelectWithActionGroup;

export default MultiSelectWithAction;
