import type {IconProps} from "./types";

const NotchArrowRight = ({color = "#404040", ...props}: IconProps) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={20}
		height={20}
		fill="none"
		viewBox="0 0 20 20"
		{...props}
	>
		<mask
			id="a"
			width={20}
			height={20}
			x={0}
			y={0}
			maskUnits="userSpaceOnUse"
			style={{
				maskType: "alpha",
			}}
		>
			<path fill="#D9D9D9" d="M0 0h20v20H0z" />
		</mask>
		<g mask="url(#a)">
			<path fill={color} d="m10 15 2.542-4.25H2v-1.5h10.542L10 5l8 5-8 5Z" />
		</g>
	</svg>
);

export default NotchArrowRight;
