import type {FC} from "react";

import type {IconProps} from "./types";

const MagicUser: FC<IconProps> = ({className = "", ...rest}) => (
	<svg
		width="20"
		height="20"
		viewBox="0 0 20 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		className={className}
		{...rest}
	>
		<mask
			id="mask0_1_24600"
			style={{maskType: "alpha"}}
			maskUnits="userSpaceOnUse"
			x="0"
			y="0"
			width="20"
			height="20"
		>
			<rect width="20" height="20" transform="matrix(-1 0 0 1 20 0)" fill="#D9D9D9" />
		</mask>
		<g mask="url(#mask0_1_24600)">
			<path
				d="M6.16948 8.91533C5.28959 8.91533 4.54138 8.60709 3.92483 7.9906C3.30828 7.3741 3 6.62591 3 5.74602C3 4.86613 3.30825 4.11874 3.92475 3.50386C4.54124 2.88898 5.28942 2.58154 6.16931 2.58154C7.0492 2.58154 7.79742 2.88931 8.41396 3.50483C9.03051 4.12036 9.33879 4.8674 9.33879 5.74594C9.33879 6.62586 9.03054 7.37406 8.41404 7.99056C7.79756 8.60708 7.04937 8.91533 6.16948 8.91533ZM6.17365 7.18615C6.57087 7.18615 6.9095 7.04472 7.18954 6.76186C7.46958 6.47898 7.6096 6.13892 7.6096 5.74169C7.6096 5.34447 7.46817 5.00666 7.18529 4.72827C6.90242 4.4499 6.56237 4.31071 6.16515 4.31071C5.76792 4.31071 5.42929 4.45167 5.14925 4.7336C4.86921 5.01553 4.72919 5.35445 4.72919 5.75035C4.72919 6.14763 4.87063 6.48625 5.1535 6.76621C5.43638 7.04617 5.77642 7.18615 6.17365 7.18615ZM0 15.2541V13.0748C0 12.7002 0.0959443 12.3531 0.287833 12.0336C0.479708 11.7141 0.741708 11.4644 1.07383 11.2844C1.99382 10.7533 2.98997 10.3674 4.06229 10.1266C5.1346 9.88581 6.22646 9.8326 7.33788 9.96696C7.13528 10.2257 6.95445 10.4995 6.7954 10.7884C6.63633 11.0773 6.51567 11.3627 6.43344 11.6445C5.64509 11.6101 4.87063 11.6911 4.11006 11.8875C3.34949 12.0839 2.63273 12.3822 1.95977 12.7824C1.8894 12.8134 1.83337 12.857 1.79169 12.9131C1.75002 12.9692 1.72919 13.0297 1.72919 13.0947V13.5249H6.20517C6.22963 13.8154 6.2756 14.1031 6.34308 14.3879C6.41057 14.6728 6.51813 14.9615 6.66577 15.2541H0Z"
				fill="#EDF2F2"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M8 12L12.1042 13.8958L14 18L15.875 13.8958L20 12L15.875 10.125L14 6L12.1042 10.125L8 12ZM13.2292 11.25L11.5833 12L13.2292 12.7708L14 14.4167L14.75 12.7708L16.3958 12L14.75 11.25L14 9.60417L13.2292 11.25Z"
				fill="#EDF2F2"
			/>
		</g>
	</svg>
);

export default MagicUser;
