import type {FC} from "react";

import type {IconProps} from "./types";

const ChartLine: FC<IconProps> = ({className = ""}) => (
	<svg
		width="218"
		fill="none"
		height="50"
		className={className}
		viewBox="0 0 218 50"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path d="M109 1H9C4.58172 1 1 4.58172 1 9V50" stroke="#CFCFCF" />
		<path d="M109 1H59V50" stroke="#CFCFCF" />
		<path d="M109 1H163V50" stroke="#CFCFCF" />
		<path d="M116 1H209C213.418 1 217 4.58172 217 9V50" stroke="#CFCFCF" />
		<path d="M109 1V50" stroke="#CFCFCF" />
	</svg>
);

export default ChartLine;
