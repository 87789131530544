import type {FC} from "react";
import classNames from "classnames";

import Cross from "assets/icons/Cross";

import type {TTagProps} from "./types";

import styles from "./tag.module.scss";

const Tag: FC<TTagProps> = ({children, onClick, closable = true, disabled = false, ...rest}) => {
	return (
		<span
			className={classNames(styles.tag, {
				[styles.clickable]: !!onClick,
				[styles.disabled]: disabled,
			})}
			onClick={onClick}
			role="button"
			{...rest}
		>
			{children}
			{!!onClick && closable && <Cross data-testid="closable-icon" />}
		</span>
	);
};

export default Tag;
