import React, {FC} from "react";
import classNames from "classnames";
import styles from "mapx-pages/Project/Overview/IndustriesReport/IndustriesReportSkeletonLoader/IndustriesSkeletonLoader.module.scss";
import {TIndustryServicesSkeletonLoaderCardProps} from "mapx-pages/Project/Overview/IndustriesReport/IndustriesReportSkeletonLoader/types";
import Index from "mapx-pages/Project/Overview/IndustriesReport/Loader";

const IndustryServicesSkeletonLoaderCard: FC<TIndustryServicesSkeletonLoaderCardProps> = ({
	count,
	customClass = "",
	rows,
}) => {
	return (
		<div className={classNames(styles.industriesSkeletonBlock, {[customClass]: customClass})}>
			{Array.from({length: count}, (_, idx) => (
				<div key={idx}>
					{Array.from({length: rows}, (_, i) => (
						<Index key={i} />
					))}
				</div>
			))}
		</div>
	);
};

export default IndustryServicesSkeletonLoaderCard;
