import type {TModalContent} from "./types";

const ModalContent = ({padding = 16, children, ...rest}: TModalContent) => {
	return (
		<div style={{padding}} {...rest}>
			{children}
		</div>
	);
};

export default ModalContent;
