import {useState} from "react";
import classNames from "classnames";
import {useSelector} from "react-redux";
import {Annotation, ComposableMap, Geographies, Geography, ZoomableGroup} from "react-simple-maps";

import {Tooltip} from "components";
import {getCountryData} from "helpers";
import {Outsider} from "components/Outsider";
import {SectionWrapper} from "mapx-components";

import css from "./index.module.scss";
import ArrowThin from "assets/icons/ArrowThin";
import "react-tooltip/dist/react-tooltip.css";

const HQDisplay = ({lng, lat, name}) => {
	return (
		<Annotation
			subject={[Number(lng), Number(lat)]}
			dx={-45}
			dy={45}
			connectorProps={{
				stroke: "#000000",
				strokeWidth: 1,
				strokeLinecap: "round",
			}}
		>
			<text
				x="-4"
				y="2"
				textAnchor="end"
				alignmentBaseline="middle"
				fill="#0C5850"
				fontSize={10}
			>
				HQ: {name}
			</text>
		</Annotation>
	);
};

const geographyStyle = (marked, isHeadquearter) => {
	return {
		default: {
			fill: isHeadquearter ? "#0C5850" : marked ? "#4C7876" : "#D6D6DA",
			// stroke: "#9E1030",
			strokeWidth: 0.75,
			outline: "none",
			transition: "all 250ms",
		},
		hover: marked
			? {
					fill: "#619c9a",
					// stroke: "#9E1030",
					strokeWidth: 0.75,
					outline: "none",
					transition: "all 250ms",
			  }
			: {
					fill: "#D6D6DA",
			  },
		pressed: {
			// fill: "#DD4132",
			// stroke: "#9E1030",
			// strokeWidth: 0.75,
			outline: "none",
			transition: "all 250ms",
		},
	};
};

export const CompanyLocations = () => {
	const {currentCompany} = useSelector((state) => state.company);

	const countries =
		currentCompany && currentCompany?.countries
			? currentCompany?.countries?.map((val) => val?.name)
			: [];

	const headquarterCoords = currentCompany?.headquarters_country
		? getCountryData(currentCompany?.headquarters_country?.code)
		: null;

	const [expandedCountries, setExpandedCountries] = useState(false);

	const handleFilter = (event) => {
		return (
			(event.metaKey || event.ctrlKey) &&
			(event?.constructor?.name !== "MouseEvent" || event?.constructor.name !== "WheelEvent")
		);
	};

	const hq = currentCompany?.headquarters_country?.name || currentCompany?.headquarters;

	return (
		<SectionWrapper
			label={"Locations"}
			className={css.sectionWrapper}
			childrenWrapperClassName={css.childrenWrapper}
		>
			<ComposableMap
				projection={"geoMercator"}
				width={800}
				height={800 / 1.25}
				projectionConfig={{
					scale: 110,
					rotate: [-11, 0],
				}}
				style={{display: "flex", flexDirection: "center", width: "100%"}}
				data-tip=""
			>
				<ZoomableGroup
					center={[0, 0]}
					zoom={1}
					maxZoom={2}
					filterZoomEvent={handleFilter}
					translateExtent={[
						[0, -800 / 1.25],
						[800, 800 / 1.25],
					]}
				>
					<Geographies
						geography="/features.json"
						fill="#D6D6DA"
						stroke="#FFFFFF"
						strokeWidth={0.5}
					>
						{({geographies}) =>
							geographies.map((geo) => {
								return (
									<Tooltip key={geo.rsmKey} followMouse>
										<Tooltip.Trigger asChild>
											<Geography
												key={geo.rsmKey}
												geography={geo}
												style={geographyStyle(
													countries?.includes(geo.properties.name),
													geo.properties.name ===
														currentCompany?.headquarters_country?.name,
												)}
											/>
										</Tooltip.Trigger>
										<Tooltip.Content>{geo.properties.name}</Tooltip.Content>
									</Tooltip>
								);
							})
						}
					</Geographies>

					{headquarterCoords && hq && (
						<HQDisplay
							lng={headquarterCoords.center_lng}
							lat={headquarterCoords.center_lat}
							name={hq}
							id={hq}
						/>
					)}
				</ZoomableGroup>
			</ComposableMap>

			<div className={css.legendWrapper}>
				{hq ? (
					<div>
						<div className={classNames(css.dot, css.hqDot)} />
						<p>
							<span>HQ</span> {hq}
						</p>
					</div>
				) : null}
				<div onClick={() => setExpandedCountries((prev) => !prev)} className={css.expander}>
					<div className={classNames(css.dot, css.countryDot)} />
					<div>
						<span>Offices</span>{" "}
						{countries?.length ? countries.length + " Countries " : "N/A"}
						<div
							style={{
								position: "relative",
								transform: `rotate(${!expandedCountries ? "180deg" : "0"})`,
								display: "inline-block",
								top: !expandedCountries ? 1 : "unset",
								bottom: expandedCountries ? 4 : "unset",
								marginLeft: "1.5em",
							}}
						>
							<ArrowThin />
						</div>
					</div>
				</div>
			</div>
			{expandedCountries ? (
				<Outsider onOutside={() => setExpandedCountries(false)}>
					<div
						className={classNames(css.countryList, {
							[css.higher]: !currentCompany.headquarters,
						})}
					>
						{countries.map((name, idx) => (
							<div key={`${name}_${idx}`}>{name}</div>
						))}
					</div>
				</Outsider>
			) : null}
		</SectionWrapper>
	);
};
