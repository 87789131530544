import {useEffect} from "react";

import colors from "styles/themes.module.scss";
import {CheckCircleIcon} from "assets/icons";
import {useAppDispatch, useAppSelector} from "hooks";
import {getLearnToRanks} from "store/mapx/learn-to-rank/learnToRankAsyncFunctions";
import {
	getLearnToRankInProgressSelector,
	learnToRankCountSelector,
	learnToRankTasksSelector,
} from "store/mapx/learn-to-rank/learnToRankSelectors";

import css from "./totalRecords.module.scss";
import {Loader} from "components";

const TotalRecords = () => {
	const dispatch = useAppDispatch();

	const tasks = useAppSelector(learnToRankTasksSelector);
	const count = useAppSelector(learnToRankCountSelector);
	const loading = useAppSelector(getLearnToRankInProgressSelector);

	useEffect(() => {
		if (tasks == null) {
			dispatch(getLearnToRanks());
		}
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<div className={css.container}>
			<div className={css.title}>Total Tests Completed</div>
			<div className={css.count}>
				{loading ? (
					<Loader height={24} width={24} type="TailSpin" color={colors.loaderDotColor} />
				) : (
					<>
						<CheckCircleIcon />
						{count}
					</>
				)}
			</div>
		</div>
	);
};

export default TotalRecords;
