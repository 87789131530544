import {useMemo} from "react";
import classNames from "classnames";
import {format} from "date-fns";

import type {MouseEvent, SyntheticEvent} from "react";

import {EMPTY_COMPANY_LOGO} from "mapx-constants";
import {ArrowButton} from "mapx-components";
import {PersonFilled, StoreIcon} from "assets/icons";

import css from "./candidateCard.module.scss";
import type {TCandidateCardProps} from "./types";
import {CompanyTooltip, Tooltip} from "components";
import {FloatingDelayGroup} from "@floating-ui/react";
import {useOpenLink} from "hooks";
import {formatSizeBand} from "helpers/string";
import {orderIndustriesByDate} from "helpers/experiences";

const CandidateCard = ({
	active,
	buttonText = "Select",
	candidate,
	hide = false,
	highlighted = false,
	loading = false,
	logo,
	onRecord,
	position,
	title,
}: TCandidateCardProps) => {
	const openLink = useOpenLink();

	const industries = useMemo(() => {
		const normalized: {[id: string]: any} = {}; // eslint-disable-line @typescript-eslint/no-explicit-any

		// Array of experiences is sometimes null.
		if (!candidate?.experience) return [];

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		candidate.experience.forEach((exp: any) => {
			if (normalized[exp.company.id.toString()]) {
				const {company, ...rest} = exp;

				normalized[company.id.toString()].experiences.push(rest);
			} else {
				const {company, ...rest} = exp;

				normalized[company.id.toString()] = {experiences: [rest], company};
			}
		});

		return orderIndustriesByDate(normalized);
	}, [candidate]);

	return (
		<section
			className={classNames(css.container, {
				[css.highlighted]: highlighted,
				[css.hide]: hide,
			})}
		>
			<header className={css.candidate}>
				<span className={css.candidateLogo}>{logo}</span>
				<div className={css.candidateName}>{title}</div>
			</header>
			<div className={css.main}>
				{industries.map((industry) => {
					const {experiences, company} = industry;

					return (
						<section key={company.id} className={css.company}>
							{(company?.industry?.name || company.size) && (
								<header>
									{company?.industry?.name && (
										<div
											className={css.industry}
											title={company?.industry?.name}
										>
											<StoreIcon />
											{company?.industry?.name}
										</div>
									)}
									{company?.industry?.name && company.size && (
										<div className={css.verticalLine} />
									)}
									{company.size && (
										<div className={css.size}>
											<PersonFilled width={20} height={20} />
											{formatSizeBand(company.size)}
										</div>
									)}
								</header>
							)}
							<div className={css.main}>
								<div className={css.left}>
									<FloatingDelayGroup delay={{open: 500, close: 0}}>
										<Tooltip allowInteraction showArrow>
											<Tooltip.Trigger asChild>
												<div
													className={css.linkContainer}
													onClick={(e: MouseEvent) =>
														openLink(e, `/company/${company.id}`)
													}
												>
													<img
														className={css.companyLogo}
														alt={company.name}
														onError={(
															e: SyntheticEvent<
																HTMLImageElement,
																Event
															>,
														) => {
															(e.target as HTMLImageElement).src =
																EMPTY_COMPANY_LOGO;
														}}
														src={company.logo_url || EMPTY_COMPANY_LOGO}
													/>
													<span className={css.companyName}>
														{company.name}
													</span>
												</div>
											</Tooltip.Trigger>
											<Tooltip.Content>
												<CompanyTooltip company={company} />
											</Tooltip.Content>
										</Tooltip>
									</FloatingDelayGroup>
								</div>
								<div className={css.right}>
									{/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
									{experiences.map((exp: any, i: number) => {
										return (
											<div key={i} className={css.exp}>
												<div className={css.title}>{exp.job_title}</div>
												<div className={css.date}>
													{`${format(
														new Date(exp.start_date),
														"MMM yyyy",
													)} - ${
														exp.end_date
															? format(
																	new Date(exp.end_date),
																	"MMM yyyy",
															  )
															: "Present"
													}`}
												</div>
											</div>
										);
									})}
								</div>
							</div>
							<div className={css.line} />
						</section>
					);
				})}
			</div>
			<footer className={classNames(css.button, css[position])}>
				<ArrowButton
					position={position}
					active={active || loading}
					onClick={onRecord}
					loading={loading}
				>
					{buttonText}
				</ArrowButton>
			</footer>
		</section>
	);
};

export default CandidateCard;
