import React from "react";
import {MainWrapper} from "mapx-components";
import styles from "./styles.module.scss";
import LayoutHeader from "mapx-pages/Home/LayoutHeader";
import ProjectList from "mapx-pages/Home/ProjectList";

const Home = () => {
	return (
		<MainWrapper className={styles.container}>
			<LayoutHeader />

			<ProjectList />
		</MainWrapper>
	);
};

export default Home;
