import {CloseIcon} from "assets/icons";

export const RemoveSelectedItemIcon = () => <CloseIcon color="#808080" />;

// @TODO: Fake data for testing
export const multiSelectFakeData = [
	{
		id: 3,
		name: "Board",
	},
	{
		id: 4,
		name: "Board P",
		steps: [
			{id: 0, text: "CEO", count: 10},
			{id: 1, text: "Finance", count: 1},
			{id: 2, text: "Finance", count: 1},
			{id: 3, text: "Finance", count: 1},
			{id: 4, text: "Finance", count: 1},
			{id: 5, text: "Finance", count: 1},
			{id: 6, text: "Finance", count: 1},
			{id: 7, text: "Finance", count: 1},
			{id: 8, text: "Finance", count: 1},
		],
	},
	{
		id: 5,
		name: "Board M",
	},
	{
		id: 6,
		name: "Board L",
	},
	{
		id: 7,
		name: "Board )",
	},
	{
		id: 8,
		name: "Board 1",
	},
	{
		id: 9,
		name: "Board 2",
	},
	{
		id: 10,
		name: "Board 3",
	},
	{
		id: 11,
		name: "Board 3",
	},
	{
		id: 12,
		name: "Board 3",
	},
	{
		id: 13,
		name: "Board 3",
	},
	{
		id: 14,
		name: "Board 3",
	},
	{
		id: 15,
		name: "Board 4",
		steps: [
			{id: 0, text: "CEO", count: 1},
			{id: 1, text: "Finance", count: 1},
			{id: 2, text: "Finance", count: 1},
			{id: 3, text: "Finance", count: 1},
			{id: 4, text: "Finance", count: 1},
			{id: 5, text: "Finance", count: 1},
			{id: 6, text: "Finance", count: 1},
			{id: 7, text: "Finance", count: 1},
			{id: 8, text: "Finance", count: 1},
		],
	},
];
