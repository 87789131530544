import type {IconProps} from "./types";

const EnhanceIcon = ({color = "#0C5850", ...rest}: IconProps) => {
	return (
		<svg
			width="16"
			height="16"
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...rest}
		>
			<path
				d="M0.94076 16L5.25509 10.3332L6.1176 11L3.29354 14.7495H4.70557L6.82361 12.0824L9.0199 14.7495H13.3342L5.4098 4.33207H7.05658L16 15.9981H8.31033L6.82005 14.249L5.40803 15.9981H0.935422L0.94076 16ZM8.78693 12.666H10.4337L2.43281 2.33359H6.90541L8.9452 5.16698L11.2998 2.33359H12.6335L9.57297 6.16623L10.4355 7.08236L15.2211 1H10.6702L8.94343 3.08349L7.60965 1H0L8.78693 12.666V12.666Z"
				fill={color}
			/>
		</svg>
	);
};

export default EnhanceIcon;
