import type {FC} from "react";
import {IconProps} from "./types";

export const LeftArrow: FC<IconProps> = ({className = "", fill = "#999999"}) => (
	<svg
		className={className}
		width="7"
		height="10"
		viewBox="0 0 7 10"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M5.66602 10L0.666016 5L5.66602 0L6.83268 1.16667L2.99935 5L6.83268 8.83333L5.66602 10Z"
			fill={fill}
		/>
	</svg>
);
