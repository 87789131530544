import {IconProps} from "./types";

const OverviewIcon = ({className = "", fill = "#D9D9D9"}: IconProps) => (
	<svg
		width="20"
		height="20"
		viewBox="0 0 20 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		className={className}
	>
		<mask
			id="mask0_594_15775"
			style={{maskType: "alpha"}}
			maskUnits="userSpaceOnUse"
			x="0"
			y="0"
			width="20"
			height="20"
		>
			<rect width="20" height="20" fill={fill} />
		</mask>
		<g mask="url(#mask0_594_15775)">
			<path
				d="M4.55927 9.03986C4.09006 9.03986 3.68456 8.8714 3.34277 8.53447C3.00097 8.19755 2.83008 7.78963 2.83008 7.3107V4.46015C2.83008 3.99095 3.00097 3.58545 3.34277 3.24365C3.68456 2.90188 4.09006 2.73099 4.55927 2.73099H15.4397C15.9186 2.73099 16.3265 2.90188 16.6635 3.24365C17.0004 3.58545 17.1689 3.99095 17.1689 4.46015V7.3107C17.1689 7.78963 17.0004 8.19755 16.6635 8.53447C16.3265 8.8714 15.9186 9.03986 15.4397 9.03986H4.55927ZM4.55927 7.3107H15.4397V4.46015H4.55927V7.3107ZM4.55927 17.269C4.09006 17.269 3.68456 17.1006 3.34277 16.7637C3.00097 16.4267 2.83008 16.0188 2.83008 15.5399V12.6893C2.83008 12.2201 3.00097 11.8146 3.34277 11.4728C3.68456 11.1311 4.09006 10.9602 4.55927 10.9602H15.4397C15.9186 10.9602 16.3265 11.1311 16.6635 11.4728C17.0004 11.8146 17.1689 12.2201 17.1689 12.6893V15.5399C17.1689 16.0188 17.0004 16.4267 16.6635 16.7637C16.3265 17.1006 15.9186 17.269 15.4397 17.269H4.55927ZM4.55927 15.5399H15.4397V12.6893H4.55927V15.5399Z"
				fill="#868D8D"
			/>
		</g>
	</svg>
);

export default OverviewIcon;
