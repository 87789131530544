import classNames from "classnames";

import type {FC} from "react";

import type {TSwitch} from "./types";

import styles from "./switch.module.scss";

const Switch: FC<TSwitch> = ({checked, onChange, ...rest}) => {
	return (
		<label className={styles.switch}>
			<input
				className={styles.input}
				type="checkbox"
				checked={checked}
				onChange={onChange}
				role="switch"
				{...rest}
			/>
			<span className={classNames(styles.slider, styles.round)}>
				<span className={styles.dot} />
			</span>
		</label>
	);
};

export default Switch;
