import HttpClient from "api/index";

class MapXPaymentApi extends HttpClient {
	async getProducts() {
		return this.doGet("/payments/products");
	}

	async checkoutUrl(priceID: string) {
		return this.doGet(`/payments/create-checkout-session/${priceID}`);
	}

	async customerPortal() {
		return this.doGet(`/payments/customer_portal`);
	}
}

const mapXPaymentApi = new MapXPaymentApi();

export default mapXPaymentApi;
