import {useEffect} from "react";
import {useHistory, useLocation} from "react-router-dom";

import {ContentTabs} from "components";
import {MainWrapper, PageTitle} from "mapx-components";
import {CompanyLocations} from "mapx-components/CompanyLocations";
import OrganizationalChart from "mapx-pages/CompanyDetails/OrganisationalChart";
import {useAppDispatch, useAppSelector, useCompanyIdParam} from "hooks";
import {
	getCompanyEthnicDiversityData,
	getCompanyEthnicDiversityDataFilter,
	getCompanyGenderDiversityData,
	getCompanyGenderDiversityDataFilter,
	getCompanyOrganizationalChart,
	getCompanyOrganizationalChartFilter,
	getCompanyTrendStats,
	getCompanyTrendStatsFilter,
	getSingleCompanyData,
} from "store/mapx/company/companyAsyncActions";
import {
	clearCompanyOrganizationalChart,
	clearCompanyPageFilterWithAllResponses,
	clearMapThisFunctionDataForCurrentCompany,
} from "store/mapx/company/companyActions";
import {
	companiesInProgressSelector,
	companiesOrgChartFiltersSelector,
	companySelector,
} from "store/mapx/company/companySelectors";
import QuickViewCandidate from "mapx-pages/Project/QuickViewCandidate";

import OverView from "./Overview";
import CompanySkeletons from "./CompanySkeletons";
import {diversityIndustryFrequentlyData} from "./utils";
import styles from "./company.module.scss";
import {useSelector} from "react-redux";
import {hasAccessToExtendedPagesSelector} from "store/mapx/user/userSelectors";
import {candidatesImportForCompanySelector} from "store/mapx/linkedin-bulk-import/linkedinBulkImportSelectors";

const CompanyDetails = () => {
	const history = useHistory();

	const dispatch = useAppDispatch();

	const search = useLocation().search;

	const companyParamId = useCompanyIdParam();

	const filtersInState = useAppSelector(companiesOrgChartFiltersSelector);

	const currentCompany = useSelector(companySelector);

	const companiesInProgress = useSelector(companiesInProgressSelector);

	const candidatesImportForCompany = useSelector(candidatesImportForCompanySelector);

	const hasAccessToExtendedPages = useSelector(hasAccessToExtendedPagesSelector);

	const recommended = new URLSearchParams(search).get("recommended");

	const goCompanyHandler = () => {
		history.push("/companies");
	};

	useEffect(() => {
		async function getCompanyData() {
			dispatch(getSingleCompanyData(companyParamId));

			if (filtersInState) {
				await dispatch(getCompanyOrganizationalChartFilter(companyParamId, filtersInState));

				dispatch(getCompanyEthnicDiversityDataFilter(companyParamId, filtersInState));

				dispatch(getCompanyGenderDiversityDataFilter(companyParamId, filtersInState));

				dispatch(getCompanyTrendStatsFilter(companyParamId, filtersInState));
			} else {
				await dispatch(getCompanyOrganizationalChart(companyParamId));

				dispatch(getCompanyEthnicDiversityData(companyParamId));

				dispatch(getCompanyGenderDiversityData(companyParamId));

				dispatch(getCompanyTrendStats(companyParamId));
			}
		}

		if (companyParamId) {
			getCompanyData();
		}

		return () => {
			if (companyParamId) {
				dispatch(clearCompanyOrganizationalChart());
				dispatch(clearCompanyPageFilterWithAllResponses());
				dispatch(clearMapThisFunctionDataForCurrentCompany(companyParamId));
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [companyParamId, dispatch, candidatesImportForCompany]);

	return (
		<MainWrapper>
			<QuickViewCandidate />

			<div className={styles.container}>
				{companiesInProgress ? (
					<div className={styles.loaderContainer}>
						<CompanySkeletons />
					</div>
				) : (
					companyParamId &&
					currentCompany?.id === companyParamId && (
						<>
							{hasAccessToExtendedPages && (
								<PageTitle
									title="All Companies"
									onClick={goCompanyHandler}
									content={currentCompany.name}
								/>
							)}

							<OverView
								type="profile"
								company={currentCompany}
								isRecommended={!!recommended}
							/>

							<div className={styles.chart}>
								<OrganizationalChart />

								<ContentTabs content={diversityIndustryFrequentlyData} />
							</div>

							<CompanyLocations />
							{/*<Competitors />*/}
						</>
					)
				)}
			</div>
		</MainWrapper>
	);
};

export default CompanyDetails;
