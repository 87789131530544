import {useAppDispatch, useAppSelector} from "hooks";
import {Accordion, KeywordSearchFilter} from "mapx-components";
import {useCallback, useMemo} from "react";
import {useSelector} from "react-redux";
import {
	setKeywordFilterConfig,
	setMultipleFilterForCandidates,
} from "store/mapx/filter/filterActions";
import {filterPositionOptionsSelector} from "store/mapx/filter/filterSelectors";
import {setKeywordSearchQuery} from "store/mapx/search/searchActions";
import {getKeywordsList} from "store/mapx/search/searchAsyncActions";
import {
	keywordPaginationSelector,
	keywordSearchQuerySelector,
} from "store/mapx/search/searchSelectors";
import {TKeywordFilterProps} from "containers/Filters/PeopleFilters/KeywordFilter/types";
import {
	allKeywordsCountSelector,
	anyKeywordTagsSelector,
	currentKeywordTagsSelector,
	keywordAnyPositionConnectivityLogicSelector,
	keywordCandidatePositionSelector,
	keywordCurrentPositionConnectivityLogicSelector,
	keywordFilterConfigSelector,
	keywordPreviousPositionConnectivityLogicSelector,
	keywordsByPositionSelector,
	previousKeywordTagsSelector,
} from "store/mapx/filter/keywordFilterSelectors";

import type {
	TKeywordPosition,
	TKeywordPositionConnectivityLogic,
} from "api/filterOptionsApi/KeywordApi/types";

import {
	clearKeywordsByPositionForCandidate,
	moveKeywordForCandidate,
	setKeywordForCandidate,
	switchKeywordConnectorForCandidatePosition,
} from "store/mapx/filter/keywordAsyncAction";
import {TabSelect} from "components";

function KeywordFilter({disabled, displayLock = false}: TKeywordFilterProps) {
	const keywordSearchQuery = useSelector(keywordSearchQuerySelector);

	const keywordPagination = useSelector(keywordPaginationSelector);

	const dispatch = useAppDispatch();

	const keywordFilterConfig = useAppSelector(keywordFilterConfigSelector);

	const position = useAppSelector<TKeywordPosition>(keywordCandidatePositionSelector);

	const currentPositionConnectivityLogic = useAppSelector<TKeywordPositionConnectivityLogic>(
		keywordCurrentPositionConnectivityLogicSelector,
	);

	const previousPositionConnectivityLogic = useAppSelector<TKeywordPositionConnectivityLogic>(
		keywordPreviousPositionConnectivityLogicSelector,
	);

	const anyPositionConnectivityLogic = useAppSelector<TKeywordPositionConnectivityLogic>(
		keywordAnyPositionConnectivityLogicSelector,
	);

	const logicByCurrentlySelectedPosition = useMemo(() => {
		if (position === "current") {
			return currentPositionConnectivityLogic;
		} else if (position === "previous") {
			return previousPositionConnectivityLogic;
		} else {
			return anyPositionConnectivityLogic;
		}
	}, [
		anyPositionConnectivityLogic,
		currentPositionConnectivityLogic,
		position,
		previousPositionConnectivityLogic,
	]);

	const filterPositionOptions = useAppSelector(filterPositionOptionsSelector);

	const count = useAppSelector(allKeywordsCountSelector);

	const anyTags = useAppSelector(anyKeywordTagsSelector);

	const currentTags = useAppSelector(currentKeywordTagsSelector);

	const previousTags = useAppSelector(previousKeywordTagsSelector);

	const selectedKeywords = useAppSelector((state) =>
		keywordsByPositionSelector(state, position, logicByCurrentlySelectedPosition),
	);

	const handlePositionChange = useCallback(
		(updatedPositionValue: string) => {
			dispatch(
				setKeywordFilterConfig({
					...keywordFilterConfig,
					active_position: updatedPositionValue,
				}),
			);
		},
		[dispatch, keywordFilterConfig],
	);

	const handleChange = useCallback(
		(value: string) => {
			dispatch(
				setKeywordForCandidate({
					position: position,
					logic: logicByCurrentlySelectedPosition,
					text: value,
				}),
			);
		},
		[dispatch, logicByCurrentlySelectedPosition, position],
	);

	const handleTagClick = useCallback(
		(value: string, position: TKeywordPosition, logic: TKeywordPositionConnectivityLogic) => {
			dispatch(
				setKeywordForCandidate({
					position,
					logic,
					text: value,
				}),
			);
		},
		[dispatch],
	);

	const getLogicConnectorByPosition = useCallback(
		(positionParam: TKeywordPosition) => {
			let logic: TKeywordPositionConnectivityLogic;

			switch (positionParam) {
				case "current":
					logic = currentPositionConnectivityLogic;
					break;
				case "previous":
					logic = previousPositionConnectivityLogic;
					break;
				case "any":
					logic = anyPositionConnectivityLogic;
					break;
				default:
					throw Error("This position doesn't exist");
			}

			return logic;
		},
		[
			anyPositionConnectivityLogic,
			currentPositionConnectivityLogic,
			previousPositionConnectivityLogic,
		],
	);

	const moveKeywordTagPosition = useCallback(
		(value: string, fromPosition: TKeywordPosition, toPosition: TKeywordPosition) => {
			const fromLogic = getLogicConnectorByPosition(fromPosition);
			const toLogic = getLogicConnectorByPosition(toPosition);

			dispatch(
				moveKeywordForCandidate({
					from: fromPosition,
					to: toPosition,
					fromLogic: fromLogic,
					toLogic: toLogic,
					text: value,
				}),
			);
		},
		[dispatch, getLogicConnectorByPosition],
	);

	const handleConnectivityOptionChange = useCallback(
		(position: TKeywordPosition, toLogic: TKeywordPositionConnectivityLogic) => {
			let fromLogic: TKeywordPositionConnectivityLogic;

			switch (position) {
				case "current":
					fromLogic = currentPositionConnectivityLogic;

					dispatch(
						setKeywordFilterConfig({
							...keywordFilterConfig,
							active_current_position_connect_logic: toLogic,
						}),
					);
					break;
				case "previous":
					fromLogic = previousPositionConnectivityLogic;

					dispatch(
						setKeywordFilterConfig({
							...keywordFilterConfig,
							active_previous_position_connect_logic: toLogic,
						}),
					);
					break;
				case "any":
					fromLogic = anyPositionConnectivityLogic;

					dispatch(
						setKeywordFilterConfig({
							...keywordFilterConfig,
							active_any_position_connect_logic: toLogic,
						}),
					);
					break;
				default:
					throw Error("This position doesn't exist");
			}

			dispatch(
				switchKeywordConnectorForCandidatePosition({
					position,
					fromLogic,
					toLogic,
				}),
			);
		},
		[
			anyPositionConnectivityLogic,
			currentPositionConnectivityLogic,
			dispatch,
			keywordFilterConfig,
			previousPositionConnectivityLogic,
		],
	);

	const handleClearTagClickByPosition = useCallback(
		(position: TKeywordPosition) => {
			dispatch(clearKeywordsByPositionForCandidate(position));
		},
		[dispatch],
	);

	const handleResetClick = useCallback(() => {
		dispatch(
			setMultipleFilterForCandidates({
				text_keywords_or: [],
				text_keywords_and: [],
				text_keywords_not: [],
				current_text_keywords_or: [],
				current_text_keywords_and: [],
				current_text_keywords_not: [],
				previous_text_keywords_or: [],
				previous_text_keywords_and: [],
				previous_text_keywords_not: [],
			}),
		);
	}, [dispatch]);

	return (
		<Accordion title="Keyword" subtitle={count} disabled={disabled} displayLock={displayLock}>
			<TabSelect.LabelContainer label="Position">
				<TabSelect
					selected={position}
					onTabChange={handlePositionChange}
					options={filterPositionOptions}
				/>
			</TabSelect.LabelContainer>

			<KeywordSearchFilter
				selectedKeywords={selectedKeywords}
				handleResetClick={handleResetClick}
				handleOnChange={handleChange}
				handleTagClick={handleTagClick}
				displayResetFilterOption={count > 0}
				moveKeywordTagPosition={moveKeywordTagPosition}
				selectedKeywordTags={{
					current: currentTags,
					previous: previousTags,
					any: anyTags,
				}}
				handleConnectivityOptionChange={handleConnectivityOptionChange}
				connectionLogics={{
					current: currentPositionConnectivityLogic,
					previous: previousPositionConnectivityLogic,
					any: anyPositionConnectivityLogic,
				}}
				clearTagsByPosition={handleClearTagClickByPosition}
				keywordSearchQuery={keywordSearchQuery}
				keywordPagination={keywordPagination}
				setKeywordQueryOnState={setKeywordSearchQuery}
				apiCall={getKeywordsList}
			/>
		</Accordion>
	);
}

export default KeywordFilter;
