import React, {useCallback, useState} from "react";
import {format, parse} from "date-fns";
import classNames from "classnames";
import styles from "./experience.module.scss";
import {useOpenLink} from "hooks";
import {TCandidateExperienceProps} from "mapx-components/Cards/ExperienceCard/Experience/types";

function Experience({
	experience,
	showAll = false,
	index,
	isExpanded,
	printRequested = false,
}: Readonly<TCandidateExperienceProps>) {
	const openLink = useOpenLink();

	const [showAllExperiences, setShowAllExperiences] = useState(false);

	const calculateStart = useCallback(
		(startDate: string) => (startDate ? parse(startDate, "yyyy-MM-dd", new Date()) : null),
		[],
	);

	const calculateEnd = useCallback(
		(endDate: Nullable<string>) =>
			endDate ? parse(endDate, "yyyy-MM-dd", new Date()) : new Date(),
		[],
	);

	const handleCompanyClick = (
		event: React.MouseEvent<HTMLImageElement | HTMLSpanElement, MouseEvent>,
	) => {
		const link = `/company/${experience.company.id}`;
		openLink(event, link, true);
	};

	return (
		<div
			key={experience.company.id}
			className={classNames(styles.experience, {[styles.hidden]: !showAll && index > 3})}
		>
			<div
				className={classNames(styles.companyName, {
					[styles.printRequested]: printRequested,
				})}
			>
				<img
					onClick={handleCompanyClick}
					alt={experience.company.name}
					loading="lazy"
					src={
						experience.company.logo_url ??
						"https://storage.googleapis.com/nebula-static/logos100x100/empty_logo.png"
					}
				/>
				<span onClick={handleCompanyClick}>{experience.company.name}</span>
			</div>

			<div className={styles.experienceList}>
				{experience.details.map((d, expIndex) => {
					const startDate = calculateStart(d.start_date);
					const endDate = calculateEnd(d.end_date);

					return (
						<div
							key={`${d.position_id}${expIndex}`}
							className={classNames(styles.detail, {
								[styles.hidden]: !showAllExperiences && expIndex > 1,
								[styles.printRequested]: printRequested,
							})}
						>
							<div>
								<div className={styles.jobInfo}>
									<div className={styles.dates}>
										{`${startDate ? format(startDate, "MMM yyyy") : "n/a"}`} -{" "}
										{startDate
											? d.end_date
												? format(endDate, "MMM yyyy")
												: "Present"
											: "n/a"}
									</div>
									<div className={styles.jobTitle}>
										{d.job_title}
										{/*{d.town && (*/}
										{/*	<span className={styles.location}>{d.location}</span>*/}
										{/*)}*/}
									</div>
								</div>
								{isExpanded && d.description ? (
									<span className={styles.description}>{d.description}</span>
								) : null}
							</div>
						</div>
					);
				})}

				{!printRequested && experience.details.length > 2 && (
					<div className={styles.center}>
						<div
							className={styles.showMore}
							onClick={() => setShowAllExperiences(!showAllExperiences)}
						>
							{printRequested || showAllExperiences
								? "Show less"
								: `Show more (${experience.details.length - 2})`}
						</div>
					</div>
				)}
			</div>
		</div>
	);
}

export default Experience;
