import type {IconProps} from "./types";

const ThreeDotsVerticalIcon = (props: IconProps) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={24}
			height={24}
			viewBox="0 0 24 24"
			fill="none"
			{...props}
		>
			<mask
				id="a"
				width={24}
				height={24}
				x={0}
				y={0}
				maskUnits="userSpaceOnUse"
				style={{
					maskType: "alpha",
				}}
			>
				<path fill="#D9D9D9" d="M0 0h24v24H0z" />
			</mask>
			<g mask="url(#a)">
				<path
					fill="#1C1B1F"
					d="M12 19.275c-.417 0-.77-.146-1.062-.438a1.444 1.444 0 0 1-.438-1.062c0-.417.146-.771.438-1.063A1.445 1.445 0 0 1 12 16.275c.417 0 .77.146 1.062.437.292.292.438.646.438 1.063 0 .417-.146.77-.438 1.062a1.444 1.444 0 0 1-1.062.438Zm0-5.775c-.417 0-.77-.146-1.062-.438A1.444 1.444 0 0 1 10.5 12c0-.417.146-.77.438-1.062A1.444 1.444 0 0 1 12 10.5c.417 0 .77.146 1.062.438.292.291.438.645.438 1.062 0 .417-.146.77-.438 1.062A1.444 1.444 0 0 1 12 13.5Zm0-5.775c-.417 0-.77-.146-1.062-.438a1.444 1.444 0 0 1-.438-1.062c0-.417.146-.771.438-1.063A1.446 1.446 0 0 1 12 4.725c.417 0 .77.146 1.062.437.292.292.438.646.438 1.063 0 .417-.146.77-.438 1.062A1.444 1.444 0 0 1 12 7.725Z"
				/>
			</g>
		</svg>
	);
};

export default ThreeDotsVerticalIcon;
