import React from "react";
import styles from "./styles.module.scss";
import classNames from "classnames";
import {TRadioInputProps} from "components/Inputs/RadioInput/types";

const RadioInput = ({
	inputName,
	checked,
	label,
	description,
	className,
	containerClass,
	labelClassName,
	descriptionClass,
	onClick,
	disabled = false,
	readOnly = false,
	isFeedback = false,
	...rest
}: TRadioInputProps) => {
	return (
		<div
			data-testid={"radio-input-container"}
			className={classNames(styles.radioInputContainer, containerClass && containerClass)}
		>
			<input
				data-testid={"radio-input"}
				type="radio"
				name={inputName}
				checked={checked}
				className={classNames(styles.radioInput, className && className)}
				onChange={(event) => {
					if (!disabled && onClick) {
						onClick(event.target.checked);
					}
				}}
				disabled={disabled}
				{...rest}
				readOnly={readOnly}
			/>

			<label
				htmlFor={inputName}
				data-testid={"radio-input-label"}
				className={classNames(styles.label, labelClassName && labelClassName, {
					[styles.feedback]: isFeedback,
				})}
				onClick={() => {
					if (!disabled && onClick) {
						onClick(!checked);
					}
				}}
			>
				<span>{label}</span>

				{description && (
					<div
						className={classNames(
							styles.description,
							descriptionClass && descriptionClass,
						)}
						data-testid={"radio-input-description"}
					>
						{description}
					</div>
				)}
			</label>
		</div>
	);
};

export default RadioInput;
