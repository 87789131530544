import type {FC} from "react";
import ContentLoader from "react-content-loader";

const skeletonItemCount = new Array(4).fill("");

const SuggestionListSkeleton: FC = () => {
	const renderSkeletonItem = skeletonItemCount.map((_, idx) => (
		<ContentLoader
			key={idx}
			speed={4}
			height={65}
			width={"100%"}
			backgroundColor="#DCE7E7"
			foregroundColor="#F9F9F9"
		>
			<circle cx="40.2" cy="30" r="20" />
			<rect x="21%" y="12" rx="5" ry="5" width="20%" height="14" />
			<rect x="21%" y="32" rx="5" ry="5" width="30%" height="12" />
			<rect x="21%" y="52" rx="5" ry="5" width="50%" height="12" />
		</ContentLoader>
	));

	return <div>{renderSkeletonItem}</div>;
};

export default SuggestionListSkeleton;
