import React from "react";

const StartWithJobTitleIcon = () => {
	return (
		<svg
			width="64"
			height="64"
			viewBox="0 0 64 64"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect width="64" height="64" rx="8" fill="#EDF2F2" />
			<path
				d="M50.24 40.64V17.6C50.24 16.0118 48.9481 14.72 47.36 14.72H10.88C9.29188 14.72 8 16.0119 8 17.6V40.64C8 42.2281 9.29188 43.52 10.88 43.52H47.36C48.9481 43.52 50.24 42.2281 50.24 40.64ZM9.92 40.64V17.6C9.92 17.0708 10.3508 16.64 10.88 16.64H47.36C47.8892 16.64 48.32 17.0708 48.32 17.6V40.64C48.32 41.1692 47.8892 41.6 47.36 41.6H10.88C10.3508 41.6 9.92 41.1692 9.92 40.64Z"
				fill="#0C5850"
			/>
			<path
				d="M53.12 20.48C52.5899 20.48 52.16 20.9098 52.16 21.44C52.16 21.9701 52.5899 22.4 53.12 22.4C53.6492 22.4 54.08 22.8308 54.08 23.36V46.4C54.08 46.9292 53.6492 47.36 53.12 47.36H16.64C16.1108 47.36 15.68 46.9292 15.68 46.4C15.68 45.8698 15.2502 45.44 14.72 45.44C14.1898 45.44 13.76 45.8698 13.76 46.4C13.76 47.9881 15.0519 49.28 16.64 49.28H53.12C54.7081 49.28 56 47.9881 56 46.4V23.36C56 21.7719 54.7081 20.48 53.12 20.48Z"
				fill="#0C5850"
			/>
			<path
				d="M22.9441 28.6342C23.7927 27.768 24.3201 26.5855 24.3201 25.28C24.3201 22.6334 22.1666 20.48 19.5201 20.48C16.8735 20.48 14.7201 22.6334 14.7201 25.28C14.7201 26.5855 15.2474 27.768 16.096 28.6342C13.6073 29.6196 11.8401 32.0449 11.8401 34.88V36.8C11.8401 37.3301 12.2699 37.76 12.8001 37.76H26.2401C26.7702 37.76 27.2001 37.3301 27.2001 36.8V34.88C27.2001 32.0449 25.4328 29.6196 22.9441 28.6342ZM19.5201 22.4C21.1082 22.4 22.4001 23.6919 22.4001 25.28C22.4001 26.8681 21.1082 28.16 19.5201 28.16C17.932 28.16 16.6401 26.8681 16.6401 25.28C16.6401 23.6919 17.932 22.4 19.5201 22.4ZM25.2801 35.84H13.7601V34.88C13.7601 32.2334 15.9135 30.08 18.5601 30.08H20.4801C23.1266 30.08 25.2801 32.2334 25.2801 34.88V35.84Z"
				fill="#0C5850"
			/>
			<path
				d="M43.5201 21.4399H32.9601C31.372 21.4399 30.0801 22.7318 30.0801 24.3199C30.0801 25.9081 31.372 27.1999 32.9601 27.1999H43.5201C45.1082 27.1999 46.4001 25.9081 46.4001 24.3199C46.4001 22.7318 45.1082 21.4399 43.5201 21.4399ZM43.5201 25.2799H32.9601C32.4309 25.2799 32.0001 24.8492 32.0001 24.3199C32.0001 23.7907 32.4309 23.3599 32.9601 23.3599H43.5201C44.0493 23.3599 44.4801 23.7907 44.4801 24.3199C44.4801 24.8492 44.0493 25.2799 43.5201 25.2799Z"
				fill="#0C5850"
			/>
			<path
				d="M43.5201 31.04H32.9601C31.372 31.04 30.0801 32.3319 30.0801 33.92C30.0801 35.5082 31.372 36.8 32.9601 36.8H43.5201C45.1082 36.8 46.4001 35.5082 46.4001 33.92C46.4001 32.3319 45.1082 31.04 43.5201 31.04ZM43.5201 34.88H32.9601C32.4309 34.88 32.0001 34.4493 32.0001 33.92C32.0001 33.3908 32.4309 32.96 32.9601 32.96H43.5201C44.0493 32.96 44.4801 33.3908 44.4801 33.92C44.4801 34.4493 44.0493 34.88 43.5201 34.88Z"
				fill="#0C5850"
			/>
		</svg>
	);
};

export default StartWithJobTitleIcon;
