import React, {FunctionComponent, useEffect, useState} from "react";
import styles from "./accordion.module.scss";
import ExpandIcon from "mapx-components/AccordionAlt/Icons/expandIcon";
import CollapseIcon from "mapx-components/AccordionAlt/Icons/collapseIcon";
import {AccordionAltComponent, AccordionItemProps} from "./types";

const AccordionItem: FunctionComponent<AccordionItemProps> = ({
	title,
	children,
	expandIcon,
	collapseIcon,
	titlePostFixChildren,
	expanded = false,
}) => {
	const [isOpen, setOpen] = useState(false);

	const onToggle = () => {
		setOpen((prev) => !prev);
	};

	useEffect(() => {
		setOpen(expanded);
	}, [expanded]);

	return (
		<div
			className={`${styles.accordionItem} ${isOpen ? styles.open : ""}`}
			aria-expanded={isOpen}
		>
			<div className={styles.header} onClick={onToggle}>
				<div className={styles.titleWithIcon}>
					{!isOpen && (expandIcon ? expandIcon : <ExpandIcon />)}
					{isOpen && (collapseIcon ? collapseIcon : <CollapseIcon />)}
					<div className={styles.title}>{title}</div>
				</div>
				{titlePostFixChildren && <div>{titlePostFixChildren}</div>}
			</div>
			<div className={`${styles.content} ${isOpen ? styles.contentOpen : ""}`}>
				{children}
			</div>
		</div>
	);
};

AccordionItem.displayName = "AccordionItem";

const AccordionAlt: AccordionAltComponent = ({children, ...rest}) => (
	<div className={styles.accordion} {...rest}>
		{children}
	</div>
);

AccordionAlt.AccordionItem = AccordionItem;
AccordionAlt.displayName = "AccordionAlt";

export default AccordionAlt;
