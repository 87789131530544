import type {FC} from "react";

import {noop} from "lodash";
import classNames from "classnames";

import {ArrowDown} from "assets/icons";

import type {ITableProps} from "./types";

import styles from "./index.module.scss";

const Table: FC<ITableProps> = ({
	headers,
	rows,
	tableCustomClass = "",
	tableHeaderCustomClass = "",
	tableRowCustomClass = "",
	SortIconComponent = undefined,
}) => (
	// eslint-disable-next-line react/no-unknown-property
	<table className={classNames(styles.table, tableCustomClass)} rules="rows">
		<thead>
			<tr className={classNames(styles.element, styles.selector, tableHeaderCustomClass)}>
				{headers.map((header, index) => (
					<th
						key={index}
						align="left"
						onClick={header.sort ? header.sort : noop}
						className={classNames([{[styles.sortable]: header.sort}])}
						style={header.style && header.style}
					>
						{header.element}
						{header.sort &&
							(SortIconComponent ? (
								SortIconComponent
							) : (
								<ArrowDown
									className={classNames(styles.arrow, [
										{
											[styles.asc]: header.order === "asc",
											[styles.desc]: header.order === "desc",
										},
									])}
								/>
							))}
					</th>
				))}
			</tr>
		</thead>
		<tbody>
			{rows.map(({id, cells}) => (
				<tr key={id} className={classNames(styles.element, tableRowCustomClass)}>
					{cells.map((cell, index) => (
						<td key={index}>
							<div className={styles.cell}>{cell || "-"}</div>
						</td>
					))}
				</tr>
			))}
		</tbody>
	</table>
);

export default Table;
