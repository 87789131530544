import {TFilterCompany} from "../api/companyApi/types";
import {TCandidateCompanyEventItem} from "../mapx-components/Filters/DealExperienceSearchFilter/types";

export function isEmptyObject(obj: Record<string, unknown>): boolean {
	return Object.keys(obj).length === 0;
}

export const removeDuplicatesFromArray = <T>(arr: T[]): T[] => {
	const newArray = arr.filter((item, index) => arr.indexOf(item) === index);

	return newArray.sort((a, b) => {
		if (parseInt(a as string, 10) < parseInt(b as string, 10)) {
			return -1;
		}
		if (parseInt(a as string, 10) > parseInt(b as string, 10)) {
			return 1;
		}

		return 0;
	});
};

export const removeDuplicateObjectFromArray = <T>(array: T[], matchAttribute = "id"): T[] => {
	try {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		const ids = array.map((o) => o[matchAttribute]);

		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		return array.filter((item, index) => !ids.includes(item[matchAttribute], index + 1));
	} catch (e) {
		console.log(e);

		return array;
	}
};

export const removeDuplicateObjectFromArrayByKeepingTheFirstIndex = <T>(
	array: T[],
	uniqueIdentifier: keyof T,
): T[] => {
	const seen = new Set();

	return array.filter((item) => {
		const identifier = item[uniqueIdentifier];

		return seen.has(identifier) ? false : seen.add(identifier);
	});
};

interface DataSet {
	// eslint-disable-next-line  @typescript-eslint/no-explicit-any
	[key: string]: any;
}

const mergeSearchedCompanyWithTargetListCompaniesForAPI = (
	key: string,
	// eslint-disable-next-line  @typescript-eslint/no-explicit-any
	value: any[],
	dataSet: DataSet,
) => {
	let relatedValue = [];

	switch (key) {
		case "companies":
			relatedValue = dataSet.tl_companies;
			break;
		case "current_companies":
			relatedValue = dataSet.current_tl_companies;
			break;
		case "previous_companies":
			relatedValue = dataSet.previous_tl_companies;
			break;
		default:
	}

	if (value instanceof Array && relatedValue instanceof Array) {
		value = [...value, ...relatedValue];
	}

	return value;
};

export const buildQueryParameter = (dataSet: DataSet, prefix = "?"): string | null => {
	let newURLWithParameters = "";

	for (const key in dataSet) {
		let value = dataSet[key];

		if (
			key === "tl_companies" ||
			key === "current_tl_companies" ||
			key === "previous_tl_companies"
		) {
			continue;
		}

		if (key === "companies" || key === "current_companies" || key === "previous_companies") {
			value = mergeSearchedCompanyWithTargetListCompaniesForAPI(key, value, dataSet);

			if (value instanceof Array && value.length > 0) {
				value = removeDuplicatesFromArray(
					value.map((item) => {
						const {id} = item as unknown as {id: number};

						// if item is not object
						if (id === undefined) {
							return item;
						}

						return id;
					}),
				).join(",");

				newURLWithParameters = `${newURLWithParameters}${key}=${value}&`;
			}
		}

		if (value instanceof Array && value.length > 0) {
			value = value.join(",");
			newURLWithParameters = `${newURLWithParameters}${key}=${value}&`;
		} else if (
			value !== null &&
			(typeof value === "number" ||
				key === "gender_diversity_importance" ||
				key === "ethnic_diversity_importance")
		) {
			newURLWithParameters = `${newURLWithParameters}${key}=${value}&`;
		} else if (
			value &&
			(key === "max_revenue" ||
				key === "min_revenue" ||
				key === "max_size" ||
				key === "min_size" ||
				key === "company_max_size" ||
				key === "company_min_size" ||
				key === "current_company_max_size" ||
				key === "current_company_min_size" ||
				key === "previous_company_max_size" ||
				key === "previous_company_min_size")
		) {
			newURLWithParameters = `${newURLWithParameters}${key}=${value}&`;
		}
	}

	if (newURLWithParameters !== "") {
		const lastCharacter = newURLWithParameters.slice(-1);

		if (lastCharacter === "&") {
			newURLWithParameters = newURLWithParameters.slice(0, -1);
		}

		return `${prefix}${newURLWithParameters}`;
	} else {
		return null;
	}
};

export const buildCompanyFilterApiPayload = (
	dataSet: DataSet,
): {
	filters: TFilterCompany;
} | null => {
	const filters: TFilterCompany = {};

	for (const key in dataSet) {
		let value = dataSet[key];

		if (
			key === "tl_companies" ||
			key === "current_tl_companies" ||
			key === "previous_tl_companies"
		) {
			continue;
		}

		if (key === "companies" || key === "current_companies" || key === "previous_companies") {
			value = mergeSearchedCompanyWithTargetListCompaniesForAPI(key, value, dataSet);

			if (value instanceof Array && value.length > 0) {
				value = removeDuplicatesFromArray(
					value.map((item) => {
						const {id} = item as unknown as {id: number};

						// if item is not object
						if (id === undefined) {
							return item;
						}

						return id;
					}),
				);

				filters[key] = value;
			}
		} else if (value instanceof Array && value.length > 0) {
			filters[key] = value;
		} else if (
			value !== null &&
			(typeof value === "number" ||
				key === "gender_diversity_importance" ||
				key === "ethnic_diversity_importance")
		) {
			filters[key] = value;
		} else if (
			value &&
			(key === "max_revenue" ||
				key === "min_revenue" ||
				key === "max_size" ||
				key === "min_size" ||
				key === "company_max_size" ||
				key === "company_min_size" ||
				key === "current_company_max_size" ||
				key === "current_company_min_size" ||
				key === "previous_company_max_size" ||
				key === "previous_company_min_size")
		) {
			filters[key] = value;
		}
	}

	if (Object.keys(filters).length > 0) {
		return {filters};
	} else {
		return null;
	}
};

export const buildCandidateApiRequestPayload = (dataSet: DataSet, recommendedSort = false) => {
	let filters = {};
	let sortingFilters = {};

	for (const key in dataSet) {
		let value = dataSet[key];

		if (
			key === "tl_companies" ||
			key === "current_tl_companies" ||
			key === "previous_tl_companies"
		) {
			continue;
		}

		if (key === "companies" || key === "current_companies" || key === "previous_companies") {
			value = mergeSearchedCompanyWithTargetListCompaniesForAPI(key, value, dataSet);
		}

		if (value instanceof Array && value.length > 0) {
			value = value
				? removeDuplicatesFromArray(
						value.map((item) => {
							const {id} = item as unknown as {id: number};

							// if item is not object
							if (id === undefined) {
								return item;
							}

							return id;
						}),
				  )
				: value;

			filters = {...filters, [key]: value};
		} else if (
			typeof value === "string" &&
			(key === "gender_diversity_importance" ||
				key === "ethnic_diversity_importance" ||
				(recommendedSort && key === "ordering"))
		) {
			sortingFilters = {...sortingFilters, [key]: value};
		} else if (
			typeof value === "number" &&
			(key === "max_revenue" ||
				key === "min_revenue" ||
				key === "max_size" ||
				key === "min_size" ||
				key === "company_max_size" ||
				key === "company_min_size" ||
				key === "current_company_max_size" ||
				key === "current_company_min_size" ||
				key === "previous_company_max_size" ||
				key === "previous_company_max_size" ||
				key === "company_events")
		) {
			filters = {...filters, [key]: value};
		} else if (key === "company_events") {
			const {types, size, dates} = value as TCandidateCompanyEventItem;

			const filteredCompanyEvents: Partial<TCandidateCompanyEventItem> = {};

			if (types?.length > 0) {
				filteredCompanyEvents.types = types;
			}

			if (size?.length > 0) {
				filteredCompanyEvents.size = size;
			}

			if (dates?.length > 0) {
				filteredCompanyEvents.dates = dates;
			}

			if (Object.keys(filteredCompanyEvents).length > 0) {
				filters = {...filters, [key]: filteredCompanyEvents};
			}
		}
	}

	if (isEmptyObject(filters) && isEmptyObject(sortingFilters)) {
		return null;
	} else {
		return {
			filters,
			sorting_options: sortingFilters,
		};
	}
};
