import React, {useMemo} from "react";
import {useSelector} from "react-redux";
import css from "mapx-components/Filters/SpecialismSearchFilter/specialismFilter.module.scss";
import {SearchInput} from "mapx-components/index";
import {getJobFunctions} from "store/mapx/search/searchAsyncActions";
import {Checkbox, ResetFilters} from "components";
import {specialismOptionsSelector} from "store/mapx/search/searchSelectors";
import useFetchListOptions from "hooks/useFetchListOptions";
import CheckboxList from "mapx-components/Inputs/CheckboxList";

function SpecialismFilter({handleOnChange, handleResetClick, selectedSpecialisms = []}) {
	const specialismOptions = useSelector(specialismOptionsSelector);

	const specialismSearchQuery = useSelector((state) => state.search.specialismSearchQuery);

	const {filteredData, onFilterChanged} = useFetchListOptions({
		options: specialismOptions,
		callbackMethod: getJobFunctions,
	});

	const sortedFilteredData = useMemo(() => {
		const set = new Set([...selectedSpecialisms]);

		return filteredData.sort((a, b) => {
			const isASelected = set.has(a.id);

			const isBSelected = set.has(b.id);

			if (isASelected) {
				return -1;
			}

			if (isBSelected) {
				return 1;
			}

			return 0;
		});
	}, [filteredData, selectedSpecialisms]);

	return (
		<div>
			<SearchInput
				defaultValue={specialismSearchQuery}
				onChange={onFilterChanged}
				placeholder="Search for a Specialism"
				type="text"
			/>

			<ResetFilters onClick={handleResetClick} />

			<CheckboxList>
				{sortedFilteredData.map((s) => (
					<Checkbox
						borderColor="#0C5850"
						containerClass={css.checkboxContainer}
						isChecked={selectedSpecialisms.includes(s.id)}
						key={s.id}
						label={s.name}
						onChange={() => handleOnChange(s.id)}
						value={s.id}
					/>
				))}
			</CheckboxList>
		</div>
	);
}

export default SpecialismFilter;
